import { $shopWithSensory } from '$stores/$shopWithSensory';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { WillyPrompt } from './types/willyTypes';
import { getPaginatedPrompts, getPromptCategories, getPromptTopics } from './utils/willyUtils';
import { colors, IconName, NumRange } from '@tw/ui-components';
import useDebounce from 'utils/useDebounce';
import { $userId } from '$stores/$user';
import {
  $customizePromptsPopup,
  $userShopCustomMobyTags,
  resetSavedNewTags,
} from '$stores/willy/$customizePromptPopup';

const FOR_YOU_CATEGORY = 'yyOklD38AVsEmEfHkyos';

export type PromptCategory = {
  name: string;
  icon: IconName;
  topic: string;
  id: string;
};

export type Topic = {
  id: string;
  name: string;
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
};

export const otherColorSets: {
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
}[] = [
  { color: 'one.5', labelColor: 'one.1', badgeColor: 'one.8' },
  { color: 'orange.4', labelColor: 'orange.1', badgeColor: 'orange.6' },
  { color: 'purple.5', labelColor: 'purple.2', badgeColor: 'purple.8' },
  { color: 'teal.5', labelColor: 'green.1', badgeColor: 'green.8' },
];

export const useSamplePrompts = ({ isDeepSearch = false }: { isDeepSearch?: boolean }) => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const userId = useStoreValue($userId);
  const savedNewTags = useComputedValue($customizePromptsPopup, (popup) => popup.savedNewTags);
  const userHasCustomTags = useComputedValue($userShopCustomMobyTags, (tags) => !!tags.length);
  const [activePromptCategory, setActivePromptCategory] = useState<string | null>(
    userHasCustomTags ? FOR_YOU_CATEGORY : null,
  );
  const [rawPrompts, setRawPrompts] = useState<WillyPrompt[]>([]);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchPrompt, 1000);
  const [hasMore, setHasMore] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);

  useEffect(() => {
    if (userHasCustomTags) {
      setActivePromptCategory(FOR_YOU_CATEGORY);
    }
  }, [userHasCustomTags]);

  const [topics, setTopics] = useState<Topic[]>([]);
  const [categories, setCategories] = useState<PromptCategory[]>([]);

  const loadPrompts = useCallback(
    async (category: string | null, searchTerm: string, page) => {
      setLoadingPrompts(true);
      try {
        let { prompts, pagination } = await getPaginatedPrompts(
          currentShopId,
          userId,
          category,
          searchTerm,
          page,
          isDeepSearch,
        );

        if (page === 0) {
          setRawPrompts(prompts);
        } else {
          setRawPrompts((prev) => [...prev, ...prompts]);
        }
        setHasMore(pagination.hasNextPage);
        setTotalItemCount(pagination.totalItems);
      } catch (e) {
        console.error(e);
      }
      setLoadingPrompts(false);
    },
    [currentShopId, userId, isDeepSearch],
  );

  useEffect(() => {
    const loadTopics = async () => {
      const topics = await getPromptTopics();
      const coloredTopics = topics.map((topic, index) => {
        return {
          name: topic.name,
          id: topic.id,
          color: otherColorSets[index % otherColorSets.length].color,
          labelColor: otherColorSets[index % otherColorSets.length].labelColor,
          badgeColor: otherColorSets[index % otherColorSets.length].badgeColor,
        };
      });
      setTopics(coloredTopics);
    };
    const loadCategories = async () => {
      const categories = await getPromptCategories();
      setCategories(categories);
    };

    loadTopics();
    loadCategories();
  }, []);

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
    loadPrompts(activePromptCategory ?? null, debouncedSearchTerm, currentPage + 1);
  };

  useEffect(() => {
    if (userId) {
      const fetchPrompts = async () => {
        await loadPrompts(activePromptCategory, debouncedSearchTerm, 0);
      };
      fetchPrompts();
      setCurrentPage(0);
    }
  }, [activePromptCategory, debouncedSearchTerm, loadPrompts, userId]);

  useEffect(() => {
    //only if they are on for you category
    if (savedNewTags && activePromptCategory === 'yyOklD38AVsEmEfHkyos') {
      const fetchPrompts = async () => {
        await loadPrompts(activePromptCategory, debouncedSearchTerm, 0);
        resetSavedNewTags();
      };
      fetchPrompts();
    }
  }, [savedNewTags, activePromptCategory, debouncedSearchTerm, loadPrompts]);

  return {
    currentPage,
    promptsToShow: rawPrompts,
    loadingPrompts,
    activePromptCategory,
    setActivePromptCategory,
    searchPrompt,
    setSearchPrompt,
    hasMore,
    loadNextPage,
    totalItemCount,
    topics,
    categories,
    loadingPromptsForCategory: loadingPrompts && currentPage === 0,
  };
};
