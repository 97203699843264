import FacebookVideoPlayer from 'components/FacebookVideoPlayer';
import { useState } from 'react';
import ReactPlayer from 'react-player';

type WillyGenericVideoPlayerProps = {
  provider: 'facebook' | 'youtube' | 'tiktok';
  source: string;
  className?: string;
  dimensionsChanged: (width: number, height: number) => void;
};

export const WillyGenericVideoPlayer: React.FC<WillyGenericVideoPlayerProps> = ({
  provider,
  source,
  className,
  dimensionsChanged,
}) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(260);
  const [height, setHeight] = useState<number>(260);
  const isSelfHosted = source.includes('storage.googleapis.com') || source.includes('fbcdn.net');
  if (isSelfHosted) {
    return <ReactPlayer url={source} controls={true} width="100%" height="100%" />;
  }
  if (provider === 'facebook') {
    const facebookVideoId = source.split('/').pop();
    return (
      <FacebookVideoPlayer
        videoId={facebookVideoId}
        onVideoReady={({ width, height }) => {
          setWidth(width);
          setHeight(height);
          dimensionsChanged(width, height);
          setIsReady(true);
        }}
      />
    );
  }
};
