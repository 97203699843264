import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $brandGuidelinesPopup, closeBrandGuidelinesPopup } from './$brandGuidelinesPopup';
import { Button, Modal, Popover, Text, Textarea, TextInput } from '@tw/ui-components';
import { ColorPickerPopover } from './ColorPicker';
import { useCallback, useEffect, useState } from 'react';
import _db from 'utils/DB';
import { $shop } from '$stores/$shop';
import { BrandGuidelines } from '@tw/types/module/types/BrandGuidelines';

const defaultBrandGuidelines: BrandGuidelines = {
  primaryColor: '#000000',
  secondaryColor: '#000000',
  fontFamily: 'Inter, sans-serif',
  toneVoice: '',
} as BrandGuidelines;

export const BrandGuidelinesPopup = () => {
  const { opened } = useStoreValue($brandGuidelinesPopup);
  const shopBrandGuidlines = useComputedValue($shop, (shop) => shop.brandGuidelines);

  const [brandGuidelines, setBrandGuidelines] = useState<BrandGuidelines>(defaultBrandGuidelines);

  const onSave = useCallback(async () => {
    await _db().set({ brandGuidelines: brandGuidelines }, { merge: true });
  }, [brandGuidelines]);

  useEffect(() => {
    if (shopBrandGuidlines) {
      setBrandGuidelines(shopBrandGuidlines);
    }
  }, [shopBrandGuidlines, opened]);

  const onClose = () => {
    closeBrandGuidelinesPopup();
  };

  return (
    <Modal
      opened={opened}
      onClose={() => closeBrandGuidelinesPopup()}
      zIndex={300}
      title={
        <Text fz={18} fw={600}>
          Brand Guidelines
        </Text>
      }
    >
      <div className="flex flex-col gap-6 p-2">
        <div className="flex flex-col gap-2">
          <Text fw={500}>Primary Color</Text>
          <div className="flex gap-2">
            <ColorPickerPopover
              activatorClassName="w-[32px] h-[32px]"
              color={brandGuidelines.primaryColor ?? ''}
              onChange={(color) => {
                setBrandGuidelines((prev) => ({ ...prev, primaryColor: color }));
              }}
              saveOnChange={true}
            />
            <TextInput
              value={brandGuidelines.primaryColor ?? ''}
              onChange={(e) => {
                setBrandGuidelines((prev) => ({ ...prev, primaryColor: e }));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Text fw={500}>Secondary Color</Text>
          <div className="flex gap-2">
            <ColorPickerPopover
              activatorClassName="w-[32px] h-[32px]"
              color={brandGuidelines.secondaryColor ?? ''}
              onChange={(color) => {
                setBrandGuidelines((prev) => ({ ...prev, secondaryColor: color }));
              }}
              saveOnChange={true}
            />
            <TextInput
              value={brandGuidelines.secondaryColor ?? ''}
              onChange={(e) => {
                setBrandGuidelines((prev) => ({ ...prev, secondaryColor: e }));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Text fw={500}>Font Family</Text>
          <div className="flex gap-2">
            <FontSelector
              selectedFont={brandGuidelines?.fontFamily}
              onChange={(f) =>
                setBrandGuidelines((prev) => {
                  return { ...prev, fontFamily: f };
                })
              }
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Text fw={500}>Tone and Voice</Text>
          <Textarea
            value={brandGuidelines.toneVoice ?? ''}
            onChange={(e) =>
              setBrandGuidelines((prev) => {
                return { ...prev, toneVoice: e.target.value };
              })
            }
          />
        </div>
        <div className="flex gap-5 justify-end">
          <Button variant="white" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave();
              onClose();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const FontSelector = ({ selectedFont, onChange }) => {
  const fonts = [
    { label: 'Inter', value: 'Inter, sans-serif' },
    { label: 'Roboto', value: 'Roboto, sans-serif' },
    { label: 'Open Sans', value: 'Open Sans, sans-serif' },
    { label: 'Montserrat', value: 'Montserrat, sans-serif' },
    { label: 'Playfair Display', value: 'Playfair Display, serif' },
    { label: 'Merriweather', value: 'Merriweather, serif' },
    { label: 'Courier New', value: 'Courier New, monospace' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
  ];

  const [opened, setOpened] = useState(false);

  const selectedOption = fonts.find((f) => f.value === selectedFont);

  const onClickOption = (option) => {
    onChange(option);
    setOpened(false);
  };

  return (
    <Popover opened={opened} onChange={setOpened} position="bottom" width="target" withArrow>
      <Popover.Target>
        <div className="w-[200px]">
          <Button
            variant="white"
            onClick={() => setOpened((x) => !x)}
            rightSection="caret-down"
            w="full"
          >
            <span style={{ fontFamily: selectedOption?.value }}>{selectedOption?.label}</span>
          </Button>
        </div>
      </Popover.Target>

      <Popover.Dropdown>
        <div className="flex flex-col gap-2">
          {fonts.map((font) => (
            <div
              style={{ fontFamily: font.value }}
              className="cursor-pointer"
              onClick={() => onClickOption(font.value)}
            >
              {font.label}
            </div>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
