import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ActionIcon, Skeleton, Text, Loader } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $userDisplayName } from '$stores/$user';
import { WillyPrompt } from './types/willyTypes';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { PromptCategorySelect } from './PromptCategorySelect';
import { PromptTile } from './PromptTile';
import { useSamplePrompts } from './useSamplePrompts';

type WillyChatLPProps = {
  onSuggestionClick: (suggestion?: string) => void;
  generatedPrompts: WillyPrompt[];
  showGeneratedPrompts?: boolean;
  loadingGeneratedPrompts?: boolean;
  chatWithSequence?: boolean;
  isBetaChat?: boolean;
  isDeepSearch?: boolean;
};

export const WillyChatLP: React.FC<WillyChatLPProps> = ({
  onSuggestionClick,
  generatedPrompts,
  showGeneratedPrompts,
  loadingGeneratedPrompts,
  chatWithSequence,
  isBetaChat = false,
  isDeepSearch = false,
}) => {
  const userName = useStoreValue($userDisplayName);
  const isSmall = useIsSmall();
  const [currentDisplayPage, setCurrentDisplayPage] = useState(0);
  const {
    promptsToShow,
    loadNextPage,
    totalItemCount,
    activePromptCategory,
    setActivePromptCategory,
    loadingPrompts,
    topics,
    categories,
    loadingPromptsForCategory,
  } = useSamplePrompts({ isDeepSearch });

  const itemsPerDisplayPage = (isDeepSearch && isBetaChat) || isSmall ? 48 : 6;

  const pages = Math.ceil(
    (showGeneratedPrompts ? generatedPrompts.length : totalItemCount) / itemsPerDisplayPage,
  );

  const shouldLoadNextPageAhead =
    (currentDisplayPage + 2) * itemsPerDisplayPage >= promptsToShow.length;
  const nextPageIsLoaded = (currentDisplayPage + 1) * itemsPerDisplayPage <= promptsToShow.length;

  const onNextPage = () => {
    if (shouldLoadNextPageAhead) {
      // If the current page reaches the end of the available data, load more data from the server
      loadNextPage();
    }
    setCurrentDisplayPage((prev) => prev + 1);
  };

  const displayedPrompts =
    generatedPrompts.length > 0
      ? generatedPrompts
      : promptsToShow.slice(
          currentDisplayPage * itemsPerDisplayPage,
          (currentDisplayPage + 1) * itemsPerDisplayPage,
        );

  return (
    <div
      className={`flex flex-col items-center h-full p-4 ${isBetaChat ? 'max-w-[800px] m-auto' : 'md:pt-20 justify-end'}`}
    >
      {!showGeneratedPrompts && !chatWithSequence && !isDeepSearch && (
        <>
          {!isBetaChat && (
            <Text fw={600} fz={isSmall ? 18 : 26} pb={28} align="center">
              Hi {userName}! How can I help you today?
            </Text>
          )}
          <div className={`${isBetaChat ? 'w-full pb-12' : 'pb-[25px] md:pb-[40px]'}`}>
            <PromptCategorySelect
              isBetaChat={isBetaChat}
              activePromptCategory={activePromptCategory}
              setActivePromptCategory={(cat) => {
                setActivePromptCategory(cat);
                setCurrentDisplayPage(0);
              }}
              mainPage
              promptTopics={topics}
              categories={categories}
            />
          </div>
        </>
      )}
      {displayedPrompts.length === 0 &&
        promptsToShow.length === 0 &&
        !loadingPrompts &&
        !loadingGeneratedPrompts && (
          <div className="flex justify-center">
            <Text color="gray.4">Nothing here yet</Text>
          </div>
        )}
      {isBetaChat || isDeepSearch ? (
        <div className="w-full h-full overflow-y-auto" id="scrollableDiv">
          <InfiniteScroll
            dataLength={promptsToShow.length}
            className="h-full"
            next={loadNextPage}
            loader={
              <div className="flex items-center justify-center h-12">
                <Loader size="sm" />
              </div>
            }
            hasMore={promptsToShow.length < totalItemCount}
            scrollThreshold={0.95}
            scrollableTarget="scrollableDiv"
            endMessage={
              <>
                {loadingPromptsForCategory ? (
                  <div className="flex items-center justify-center h-12">
                    <Loader size="sm" />
                  </div>
                ) : (
                  <p className="flex-container center no-more-orders-msg">
                    {promptsToShow.length && '🐳'}
                  </p>
                )}
              </>
            }
          >
            <div className="w-full overflow-hidden">
              <div
                className={`flex flex-col gap-6.5 mb-6.5 w-full ${isBetaChat ? '' : '@6xl:!px-90'}`}
              >
                {promptsToShow.map((prompt, i) => {
                  return (
                    <span key={`prompt.prompt-${i}`} className="w-full h-full">
                      <PromptTile
                        prompt={prompt}
                        onSuggestionClick={(prompt) => onSuggestionClick(prompt.prompt)}
                        promptTopics={topics}
                        promptCategories={categories}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <>
          {(showGeneratedPrompts ? loadingGeneratedPrompts : loadingPromptsForCategory) ? (
            <>
              <div className="flex justify-center">
                <div
                  className={`flex items-stretch auto-rows-min h-full md:grid grid-cols-1 md:grid-cols-3 gap-4 pb-[30px] @3xl:max-w-[800px] @3xl:mx-auto @6xl:!max-w-none overflow-scroll md:overflow-hidden no-scrollbar ${
                    isBetaChat ? '' : '@6xl:!px-90'
                  }`}
                >
                  {[...Array(6)].map((_, i) => {
                    return (
                      <span
                        key={`prompt.prompt-${i}`}
                        className="w-[85vw] min-w-[85vw] md:w-auto md:min-w-[auto] h-full"
                      >
                        <div className="border border-solid border-gray-200 rounded bg-white p-6 flex flex-col justify-between gap-4 cursor-pointer w-full h-full hover:bg-gray-50 overflow-hidden">
                          <div className="rounded overflow-hidden w-full">
                            <Skeleton
                              width="2000px"
                              height={showGeneratedPrompts ? '15.5px' : '16px'}
                            />
                          </div>
                          <div className="rounded overflow-hidden w-full">
                            <Skeleton
                              width="2000px"
                              height={showGeneratedPrompts ? '15px' : '16px'}
                            />
                          </div>
                          {!showGeneratedPrompts && <Skeleton width="100px" height="18px" />}
                        </div>
                      </span>
                    );
                  })}
                </div>
              </div>
              {!showGeneratedPrompts && (
                <div>
                  <Skeleton width="100px" height="23px" />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="max-w-full overflow-hidden">
                <div
                  className={`flex items-stretch auto-rows-min h-full md:grid grid-cols-1 md:grid-cols-3 gap-4 pb-[30px] @3xl:max-w-[800px] @3xl:mx-auto @6xl:!max-w-none overflow-scroll md:overflow-hidden no-scrollbar ${
                    isBetaChat ? '' : '@6xl:!px-90'
                  }`}
                >
                  {displayedPrompts.map((prompt, i) => {
                    return (
                      <span
                        key={`prompt.prompt-${i}`}
                        className="w-[85vw] min-w-[85vw] md:w-auto md:min-w-[auto] h-full"
                      >
                        <PromptTile
                          prompt={prompt}
                          onSuggestionClick={(prompt) => onSuggestionClick(prompt.prompt)}
                          promptTopics={topics}
                          promptCategories={categories}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
              {!isSmall && pages > 1 && !chatWithSequence && (
                <div className="flex gap-4 items-center">
                  <span className={`${currentDisplayPage === 0 ? 'opacity-0' : 'opacity-100'}`}>
                    <ActionIcon
                      variant="activator"
                      icon="chevron-left-minor"
                      iconSize={9}
                      color="gray.4"
                      size="sm"
                      disabled={currentDisplayPage === 0}
                      onClick={() => {
                        setCurrentDisplayPage((prev) => prev - 1);
                      }}
                    />
                  </span>
                  <Text fw={500} fz={14}>
                    <span className="flex gap-[1px]">
                      <span>{currentDisplayPage + 1}</span>
                      <span>/</span>
                      <span>{pages}</span>
                    </span>
                  </Text>
                  <span className={`${currentDisplayPage === pages ? 'opacity-0' : 'opacity-100'}`}>
                    <ActionIcon
                      variant="activator"
                      icon="chevron-right-minor"
                      iconSize={9}
                      color="gray.4"
                      size="sm"
                      disabled={!nextPageIsLoaded}
                      onClick={() => {
                        onNextPage();
                      }}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
