import FacebookAdsAccountsConfig from '../components/FacebookAdsAccountsConfig';
import GoogleAdsAccountsConfig from '../components/Gads/GAdsAccountsConfig';
import TiktokAccountsConfig from '../components/TiktokAccountsConfig';
import { TwitterAccountsConfig } from '../components/TwitterAccountsConfig';
import GorgiasConfig from '../components/GorgiasConfig';
import KlaviyoConfig from '../components/KlaviyoConfig';
import SnapchatOrganizationsConfig from '../components/SnapchatOrganizationsConfig';
import { ManageConnectionModal } from '../routes/integrations/components/ManageConnectionModal/ManageConnectionModal';
import { SensoryAuthConfig } from '../components/SensoryAuthConfig';
import { AuthRedirectNotice } from '../components/AuthRedirectNotice';
import KnoConfig from '../components/KnoConfig';
import AmazonConfig from '../components/AmazonConfig';
import BingConfig from '../components/BingConfig';
import EnquirelabsConfig from '../components/EnquirelabsConfig';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import { TikTokSettings } from '../components/TikTokSettings';
import GoogleSheetsSettings from '../components/GoogleSheetsSettings';
import { IntegrationConnectedErrorMsg } from '../components/IntegrationConnectionErrorMsg';
import { ConfirmModal } from '@tw/ui-components';

export const MiniPopupsAndConfigs = () => {
  return (
    <>
      <FacebookAdsAccountsConfig />
      <GoogleAdsAccountsConfig />
      <TiktokAccountsConfig />
      <TwitterAccountsConfig />
      <GorgiasConfig isLoading={undefined} />
      <KlaviyoConfig isLoading={undefined} />
      <SnapchatOrganizationsConfig isLoading={undefined} />
      {/* <SensoryAccountConfig /> */}
      <ManageConnectionModal />
      <SensoryAuthConfig />
      <AuthRedirectNotice />
      <KnoConfig isLoading={undefined} />
      <AmazonConfig />
      <BingConfig />
      <EnquirelabsConfig />
      <ConfirmationModal />
      <TikTokSettings done={() => {}} />
      <GoogleSheetsSettings done={() => {}} />
      <IntegrationConnectedErrorMsg />
      <ConfirmModal />
    </>
  );
};
