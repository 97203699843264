import { Flex, Icon, Popover, Text } from '@tw/ui-components';
import { FC } from 'react';
import { IconProps, FormattedColor } from '@tw/ui-components';

type InfoPopoverProps = {
  description: string | JSX.Element;
  iconProps?: Partial<Omit<IconProps, 'name'>>;
};

const defaultIconProps: IconProps = { name: 'info', size: 12, color: 'gray.5' as FormattedColor };

export const InfoPopover: FC<InfoPopoverProps> = ({
  description,
  iconProps = defaultIconProps,
}) => {
  return (
    <Popover position="top" shadow="md">
      <Popover.Target>
        <Flex cursor="pointer">
          <Icon {...defaultIconProps} {...iconProps} />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size={typeof description === 'string' ? 'xs' : 'sm'} maw={200}>
          {description}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};
