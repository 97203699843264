// client/SALES_PLATFORMS.ts

import {
  SALES_PLATFORMS as COMMON_SALES_PLATFORMS,
  SalesPlatform,
  SalesPlatformItem,
} from '@tw/types/module/services';
import shopifyIcon from '../icons/shopify.svg';
import bigCommerceIcon from '../icons/bigCommerce.svg';
import wooCommerceIcon from '../icons/wooCommerce.svg';
import magentoIcon from '../icons/magento.svg';
import customIcon from '../icons/custom-msp.svg';
import salesforceIcon from '../icons/salesforce.svg';
import stripeIcon from '../icons/stripe.svg';
import { SalesPlatformDictionary } from '@tw/types/src/services/general/SalesPlatform';

// 3) A map of platform => icon
const platformIcons: Record<SalesPlatform, string> = {
  shopify: shopifyIcon,
  bigcommerce: bigCommerceIcon,
  woocommerce: wooCommerceIcon,
  // magento: magentoIcon,
  stripe: stripeIcon,
  salesforce: salesforceIcon,
  'salesforce-cc': salesforceIcon,
  'custom-msp': customIcon,
};

export const SALES_PLATFORMS: SalesPlatformDictionary = (
  Object.entries(COMMON_SALES_PLATFORMS) as [SalesPlatform, SalesPlatformItem<SalesPlatform>][]
).reduce((acc, [platform, baseValues]) => {
  acc[platform] = {
    ...baseValues,
    icon: platformIcons[platform],
  } as any;
  return acc;
}, {} as SalesPlatformDictionary);
