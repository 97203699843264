import { $store } from '@tw/snipestate';

type chatsToWatch = { conversationId: string; startTime: number; endTime: null | number };

export const $chatsToWatch = $store<chatsToWatch[]>([]);

export const addChatToWatch = (conversationId: string) => {
  $chatsToWatch.set((prev) => [
    ...prev,
    { conversationId: conversationId, startTime: Date.now(), endTime: null },
  ]);
};

export const updateEndTimeOnChatToWatch = (conversationId: string) => {
  $chatsToWatch.set((prev) =>
    prev.map((a) => {
      if (a.conversationId === conversationId) {
        return { ...a, endTime: Date.now() };
      }
      return a;
    }),
  );
};

export const removeChatToWatch = (conversationId: string) => {
  $chatsToWatch.set((prev) => prev.filter((a) => a.conversationId !== conversationId));
};
export const clearChatsToWatch = () => {
  $chatsToWatch.set([]);
};
