import { Button, Collapse, Icon, Loader, Modal, SegmentedControl, Text } from '@tw/ui-components';
import { WillyMessageTemplate } from '../WillyMessageTemplate';
import { convertDataToJson, emptyArray, simplifyParsedData } from '../utils/willyUtils';
import { Component, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useWillySocket } from '../WillySocket';
import axiosInstance from 'utils/axiosInstance';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { $isAdminClaim } from '$stores/$user';
import { useChatGenUiStreamSocket } from '../hooks/useChatGenUiStreamSocket';
import { WillySimpleText } from '../WillySimpleText';
import ScrollToBottom from 'react-scroll-to-bottom';
import { ChatInput } from '../ChatInput';
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import { WillyUserAvatar } from '../WillyUserAvatar';
import { ReactComponent as AlanIcon } from 'components/Icons/alan-icon.svg';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { openBrandGuidelinesPopup } from '../$brandGuidelinesPopup';
import { DataWithName, GenUiEditDiffsType, Message } from '../types/willyTypes';
import { User } from 'components/UserProfileManagment/User/constants';
import { $currentDateRange } from '$stores/willy/$dateRange';
import moment from 'moment-timezone';
import { useGenUiDateRange } from '../hooks/useGenUiDateRange';

export type GenUiChatMessage = {
  id: string;
  role: string;
  text: string;
  output?: string;
  versionId?: string;
  loading?: boolean;
  streamingAssistantResponse?: boolean;
  streamingOutput?: boolean;
  //need to fix this!!
  diffs?: GenUiEditDiffsType;
  lastUpdatedTime?: number;
};

type GenUiVersion = {
  id: string;
  name: string;
  output: string;
  fromVersion?: string;
  streaming?: boolean;
  diffs?: GenUiEditDiffsType;
};

type FullAnswerModalProps = {
  fullAnswerPopupOpen?: boolean;
  setFullAnswerPopupOpen?: (open: boolean) => void;
  messages: Message[];
  lastMessageFromUser?: Message;
  loadingAnswers: boolean;
  user: Partial<User>;
  isGenUiStep: boolean;
  setNewGenUiOutput?: (output: string) => void;
  stepId?: string;
  sequenceId?: string;
  runId?: string;
  dataForGenUi?: DataWithName[];
  isFromMainChat?: boolean;
};

export const FullAnswerModal: React.FC<FullAnswerModalProps> = ({
  fullAnswerPopupOpen,
  setFullAnswerPopupOpen,
  messages,
  lastMessageFromUser,
  loadingAnswers,
  user,
  isGenUiStep,
  setNewGenUiOutput,
  stepId,
  sequenceId,
  runId,
  dataForGenUi,
  isFromMainChat,
}) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const isSmall = useIsSmall();
  const shopId = useStoreValue($currentShopId);

  const inputRef = useRef<Component<MentionsInputProps, any, any>>(null);
  const isChatWithGenUi = useMemo(() => {
    return isAdmin && isGenUiStep;
  }, [isAdmin, isGenUiStep]);

  const { socket } = useWillySocket();

  const [conversationId, setConversationId] = useState<string>('');
  const [chatInput, setChatInput] = useState<string>('');
  const [batteryLevel, setBatteryLevel] = useState(89);
  const [viewType, setViewType] = useState<'Code' | 'Preview'>('Preview');
  //only relevant for phone
  const [expandChat, setExpandChat] = useState<boolean>(false);
  const [showMobileVersion, setShowMobileVersion] = useState<boolean>(false);
  const [fixedGenUi, setFixedGenUi] = useState<{ versionId: string; output: string }[]>([]);

  const { dataToUse, isLoadingSavedQuery } = useGenUiDateRange({
    initialData: dataForGenUi || null,
  });

  useEffect(() => {
    setConversationId(uuidV4());
  }, []);

  const [chatMessages, setChatMessages] = useState<GenUiChatMessage[]>([]);

  useChatGenUiStreamSocket({
    conversationId,
    setMessages: setChatMessages,
  });

  const isLoadingResponse = useMemo(() => {
    //check if any of the chat messages is loading, or streaming
    return chatMessages.some((m) => m.loading || m.streamingAssistantResponse || m.streamingOutput);
  }, [chatMessages]);

  //versions
  const versions: GenUiVersion[] = useMemo(() => {
    const originalVersion: GenUiVersion = {
      id: '0',
      name: 'Version 1',
      output: messages[0]?.text || '',
    };
    const assistantMessages = chatMessages.filter((m) => m.role === 'assistant');
    const versions = assistantMessages.map((m, index) => {
      return {
        id: m.versionId || '',
        name: `Version ${index + 2}`,
        output: m.output ?? '',
        diffs: m.diffs ?? [],
        lastUpdatedTime: m.lastUpdatedTime ?? 0,
        streaming: m.streamingOutput ?? false,
      };
    });
    return [originalVersion, ...versions];
  }, [chatMessages, messages]);

  const previousVersionCount = useRef(versions.length);

  const [selectedVersionIndex, setSelectedVersionIndex] = useState<number>(0);

  const skipToVersionIndex = useCallback(
    (versionId: string) => {
      const index = versions.findIndex((v) => v.id === versionId);
      setSelectedVersionIndex(index);
    },
    [versions],
  );

  useEffect(() => {
    if (versions.length > previousVersionCount.current) {
      skipToVersionIndex(versions[versions.length - 1].id);
    }
    previousVersionCount.current = versions.length;
  }, [versions, skipToVersionIndex]);

  const selectedVersionData = useMemo(() => {
    return versions?.[selectedVersionIndex];
  }, [selectedVersionIndex, versions]);

  const selectedVersionOutput = useMemo(() => {
    const versionToLookFor = selectedVersionIndex > -1 ? selectedVersionData.id : 'ORIGINAL';
    const fixedGenUiForVersion = fixedGenUi.find((f) => f.versionId === versionToLookFor);
    if (!!fixedGenUiForVersion) {
      return fixedGenUiForVersion.output;
    }
    if (versionToLookFor === 'ORIGINAL') {
      return messages[0]?.text;
    }
    return selectedVersionData.output;
  }, [selectedVersionData, fixedGenUi, messages, selectedVersionIndex]);

  const onFixedGenUi = (output: string, versionId: string) => {
    setFixedGenUi((prev) => {
      const newFixeds = [...prev];
      const versionIndex = newFixeds.findIndex((f) => f.versionId === versionId);
      if (versionIndex > -1) {
        newFixeds[versionIndex] = { versionId: versionId, output: output };
      } else {
        newFixeds.push({ versionId, output });
      }
      return newFixeds;
    });
    if (versionId === 'ORIGINAL') {
      setNewGenUiOutput?.(output);
    }
  };

  useEffect(() => {
    if (selectedVersionData?.streaming) {
      setViewType('Code');
    } else {
      setViewType('Preview');
    }
  }, [selectedVersionData?.streaming]);

  const goToPrevVersion = useCallback(() => {
    setSelectedVersionIndex((prev) => {
      return prev > 0 ? prev - 1 : prev;
    });
  }, []);

  const goToNextVersion = useCallback(() => {
    setSelectedVersionIndex((prev) => {
      return prev < versions.length - 1 ? prev + 1 : prev;
    });
  }, [versions]);

  useEffect(() => {
    if (selectedVersionData?.streaming) {
      setViewType('Code');
    }
  }, [selectedVersionData]);

  const sendNewMessage = useCallback(async () => {
    setExpandChat(true);
    const newMessage: GenUiChatMessage = {
      id: uuidV4(),
      role: 'user',
      text: chatInput,
    };

    setChatMessages((prev) => [...prev, newMessage]);

    const answerId = uuidV4();
    const newVersionId = uuidV4();

    const answerMessage: GenUiChatMessage = {
      id: answerId,
      role: 'assistant',
      loading: true,
      text: '',
      versionId: newVersionId,
    };

    setChatMessages((prev) => [...prev, answerMessage]);
    setChatInput('');

    socket.emit('edit-genui', {
      conversationId,
      userPrompt: chatInput,
      basedOnOutput: !!selectedVersionData ? selectedVersionData.output : messages[0]?.text || '',
      basedOnVersionId: !!selectedVersionData ? selectedVersionData.id : '',
      messageId: answerId,
      userId: user.uid || '',
    });
  }, [chatInput, conversationId, user.uid, messages, selectedVersionData, socket]);

  useEffect(() => {
    (async () => {
      if ('getBattery' in navigator && typeof navigator.getBattery === 'function') {
        const fromNavigator = await navigator.getBattery();
        const level = fromNavigator.level * 100;
        setBatteryLevel(level);
      }
    })();
  }, []);

  const saveDesignAsDefault = useCallback(async () => {
    if (selectedVersionData) {
      setNewGenUiOutput?.(selectedVersionData.output);

      if (!isFromMainChat) {
        await axiosInstance.post('/v2/sequences/save-new-genui-output', {
          sequenceId: sequenceId,
          shopId: shopId,
          runId: runId,
          stepId: stepId,
          newOutput: selectedVersionData.output,
          loopIndex: 1,
        });
      }

      setFullAnswerPopupOpen?.(false);
    }
  }, [
    selectedVersionData,
    setNewGenUiOutput,
    isFromMainChat,
    shopId,
    setFullAnswerPopupOpen,
    sequenceId,
    runId,
    stepId,
  ]);

  return (
    <Modal
      fullScreen
      opened={!!fullAnswerPopupOpen}
      onClose={() => setFullAnswerPopupOpen?.(false)}
      padding={0}
      withCloseButton={!isChatWithGenUi}
    >
      {isChatWithGenUi && (
        <div className="flex w-full justify-between border-0 border-solid border-b-[1px] border-gray-200">
          <div className="p-4 flex gap-4 items-center bg-gray-50 h-[60px]">
            <Icon name="rename" size={20} />
            <Text>Edit Output Design</Text>
          </div>
          <div className="flex items-center gap-4">
            <Button size="xs" variant="white" onClick={() => openBrandGuidelinesPopup()}>
              Set Brand Guidelines
            </Button>
            <Modal.CloseButton />
          </div>
        </div>
      )}
      <div
        className={`flex flex-col-reverse md:flex-row w-full h-[calc(100vh-60px)] bg-white overflow-hidden ${isChatWithGenUi ? 'py-10' : ''}`}
      >
        {isChatWithGenUi && (
          <div
            className="w-auto md:w-1/3 flex flex-col md:mx-auto
          "
          >
            {!isSmall && (
              <ChatMessages
                user={user}
                chatMessages={chatMessages}
                versions={versions}
                skipToVersionIndex={skipToVersionIndex}
                setExpandChat={setExpandChat}
              />
            )}
            {isSmall && !!chatMessages.length && (
              <div className="flex flex-col">
                <div
                  className="flex items-center justify-between w-full gap-4 cursor-pointer h-[30px] px-6 py-10 border-0 border-t border-b border-gray-200 border-solid"
                  onClick={() => setExpandChat((prev) => !prev)}
                >
                  <Text size="md" weight={500} color="gray.8">
                    Chat
                  </Text>
                  <div className={`flex transition-transform ${expandChat ? 'rotate-180' : ''}`}>
                    <Icon color="gray.5" name={'chevron-down'} size={10} />
                  </div>
                </div>
                <Collapse
                  in={expandChat}
                  id="chat-collapse"
                  transitionDuration={500}
                  transitionTimingFunction="ease-in-out"
                >
                  <div className="h-[300px] flex">
                    <ChatMessages
                      user={user}
                      chatMessages={chatMessages}
                      versions={versions}
                      skipToVersionIndex={skipToVersionIndex}
                      setExpandChat={setExpandChat}
                    />
                  </div>
                </Collapse>
              </div>
            )}
            <div className="p-4 border-t border-gray-200">
              <div className=" items-center w-full justify-between gap-4">
                <ChatInput
                  value={chatInput}
                  context="chatWithGenUi"
                  inputRef={inputRef}
                  willyLoading={false}
                  isReadyForChat={true}
                  currentAnalyticsEvent=""
                  setValue={setChatInput}
                  onSubmit={sendNewMessage}
                  onStopChat={() => {}}
                  toggleOpenFileDialog={() => {}}
                  removeFile={() => {}}
                  disabled={isLoadingResponse}
                  hideUpload
                  placeholder="Your message..."
                >
                  <Mention data={[]} trigger="@" markup='@"__display__"' />
                </ChatInput>
              </div>
            </div>
          </div>
        )}
        <div
          id="answer-wrapper"
          className={`${isChatWithGenUi ? 'w-auto md:w-2/3  rounded border border-solid border-gray-200' : 'w-full'} flex flex-col h-full overflow-scroll no-scrollbar ${viewType === 'Code' ? 'bg-[#1f2937]' : 'bg-gray-50'}`}
        >
          {isChatWithGenUi && (
            <div className="flex justify-between p-4 bg-white items-center ">
              <div className="flex items-center gap-4 pl-2">
                <Icon name="insights" size={11} />
                <Text>{selectedVersionData?.name}</Text>
              </div>
              <SegmentedControl
                data={[
                  { label: 'Code', value: 'Code' },
                  { label: 'Preview', value: 'Preview', disabled: selectedVersionData?.streaming },
                ]}
                value={viewType}
                onChange={(v) => setViewType(v as 'Code' | 'Preview')}
              />
            </div>
          )}

          <div className="w-full h-full overflow-auto relative">
            {isLoadingSavedQuery && (
              <div className="fixed inset-0 bg-gray-50 bg-opacity-70 z-50 flex items-center justify-center">
                <Loader size="md" />
              </div>
            )}
            {viewType === 'Preview' && (
              <>
                {!isSmall && isChatWithGenUi && (
                  <div className="w-full justify-end flex">
                    <SegmentedControl
                      data={[
                        {
                          label: (
                            <div className="flex items-center">
                              <DesktopMajor width={17} />
                            </div>
                          ),
                          value: 'desktop',
                        },
                        {
                          label: (
                            <div className="flex items-center">
                              <MobileMajor width={17} />
                            </div>
                          ),
                          value: 'mobile',
                        },
                      ]}
                      value={showMobileVersion ? 'mobile' : 'desktop'}
                      onChange={(v) => setShowMobileVersion(v === 'mobile')}
                    />
                  </div>
                )}
                <div
                  className={`${showMobileVersion ? 'max-w-sm mx-auto border-solid border border-t-0 border-gray-200 rounded-2xl h-[650px] overflow-y-auto relative' : ''}`}
                >
                  {showMobileVersion && (
                    <div className="ios-statusbar !sticky top-0 z-10">
                      <div className="time">{moment().format('hh:mm')}</div>

                      <div className="right-items flex items-center gap-4">
                        <div className="cellular-icon">
                          <div className="cellular-bar cellular-bar-1"></div>
                          <div className="cellular-bar cellular-bar-2"></div>
                          <div className="cellular-bar cellular-bar-3"></div>
                          <div className="cellular-bar cellular-bar-4"></div>
                        </div>

                        <span className="battery-percentage">{batteryLevel}%</span>
                        <div className="battery-icon">
                          <div
                            className="battery-level"
                            style={{ width: `${batteryLevel}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {messages?.map((message, i, arr) => {
                    return isChatWithGenUi ? (
                      <WillySimpleText
                        key={message.id + '_element'}
                        text={selectedVersionOutput || ''}
                        dataForGenUi={dataToUse}
                        sequenceId={sequenceId}
                        runId={runId}
                        stepId={stepId}
                        updateToFixedGenUi={(output) =>
                          onFixedGenUi(output, selectedVersionData?.id ?? 'ORIGINAL')
                        }
                      />
                    ) : (
                      <WillyMessageTemplate
                        message={
                          selectedVersionData
                            ? { ...message, text: selectedVersionData?.output }
                            : message
                        }
                        firstOfAssistant={false}
                        lastOfAssistant={
                          (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                          i === arr.length - 1
                        }
                        lastMessageFromUser={lastMessageFromUser}
                        canEdit={false}
                        userName={''}
                        handleSubmit={() => {}}
                        key={message.id + '_element'}
                        loading={loadingAnswers}
                        codeActions={emptyArray()}
                        isLast={i === messages.length - 1}
                        conversationUser={user.uid || ''}
                        conversationMessages={messages}
                        dataForGenUi={dataToUse}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {viewType === 'Code' && (
              <ScrollToBottom className="h-full overflow-auto">
                <div className={`gap-6 flex flex-col @3xl:flex-row m-auto p-5 mx-auto`}>
                  {messages?.map((message) => {
                    return (
                      <Fragment key={message.id + '_element'}>
                        <WillySimpleText
                          forceShowCodeBlock={true}
                          text={
                            selectedVersionData ? selectedVersionData?.output : message?.text || ''
                          }
                          dataForGenUi={dataToUse}
                          diffs={selectedVersionData ? selectedVersionData?.diffs : []}
                          streamingOutput={
                            selectedVersionData ? selectedVersionData?.streaming : false
                          }
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </ScrollToBottom>
            )}
          </div>

          {isChatWithGenUi && (
            <div className="flex justify-between p-4 bg-white h-[60px]">
              {true && (
                <div className="flex gap-4 items-center pl-2">
                  {selectedVersionIndex !== 0 && (
                    <div onClick={goToPrevVersion} className="cursor-pointer">
                      <Icon name="chevron-left-minor" size={12} />
                    </div>
                  )}

                  <Text>
                    Version {selectedVersionIndex + 1} of {versions.length}
                  </Text>
                  {selectedVersionIndex + 1 < versions.length && (
                    <div onClick={goToNextVersion} className="cursor-pointer">
                      <Icon name="chevron-right-minor" size={12} />
                    </div>
                  )}
                </div>
              )}
              <Button
                variant="white"
                size="xs"
                onClick={saveDesignAsDefault}
                disabled={loadingAnswers}
              >
                Save Design as Default
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

type ChatMessagesProps = {
  chatMessages: GenUiChatMessage[];
  versions: GenUiVersion[];
  user: Partial<User>;
  skipToVersionIndex: (versionId: string) => void;
  setExpandChat: (expand: boolean) => void;
};

export const ChatMessages: React.FC<ChatMessagesProps> = (props) => {
  const { chatMessages, versions, user, skipToVersionIndex, setExpandChat } = props;

  return (
    <div className="flex-1 overflow-auto p-4 w-full">
      <div className="space-y-4">
        {chatMessages.map((message) => {
          const versionData = versions.find((v) => v.id === message.versionId);

          return (
            <div
              key={message.id + '_element'}
              id={message.id}
              className="group @container w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50"
            >
              <div className={`gap-6 flex flex-col @3xl:flex-row m-auto p-4 @3xl:max-w-[800px]`}>
                <div className="relative flex @3xl:items-start">
                  {message.role === 'user' && (
                    <div className="flex gap-2 items-center">
                      <div className="w-10 h-10 rounded-sm overflow-hidden flex items-center">
                        <WillyUserAvatar userId={user.uid} userName={user.firstName} />
                      </div>
                      <div className="flex @3xl:hidden font-semibold leading-none">
                        {user.firstName}
                      </div>
                    </div>
                  )}
                  {message.role !== 'user' && (
                    <div className="flex gap-2 items-center">
                      <div className="w-10 h-10 rounded-sm flex items-center">
                        <AlanIcon className="w-full h-auto fill-[white] text-[#0C70F2]" />
                      </div>
                      <p className="flex @3xl:hidden font-semibold leading-none">Moby</p>
                    </div>
                  )}
                </div>

                <div className={`min-h-[20px] flex flex-col items-start max-w-full flex-auto`}>
                  <div className="group/message flex items-start gap-2 w-full">
                    {message.loading && (
                      <div className={`flex-auto flex flex-col w-full h-full gap-4`}>
                        <div className="relative blinking-cursor"></div>
                      </div>
                    )}
                    <div className="flex-auto h-full w-full relative flex gap-4">
                      <div
                        className="w-full h-full flex-auto flex flex-col outline-none overflow-auto"
                        tabIndex={1}
                        suppressContentEditableWarning={true}
                        contentEditable={false}
                      >
                        <WillySimpleText text={message.text || ''} />
                        {message.role !== 'user' && !message.loading && (
                          <div className="flex items-stretch rounded-lg overflow-hidden border border-gray-200 bg-gray-50 mt-4">
                            <div className="flex items-center justify-center p-6 bg-gray-100">
                              <div className="w-8 h-8 border-4 border-gray-300 rounded-full">
                                {message.streamingOutput ||
                                message.loading ||
                                message.streamingAssistantResponse ? (
                                  <Loader size="sm" color="gray.4" />
                                ) : (
                                  <Icon name="insights" />
                                )}
                              </div>
                            </div>

                            <div
                              className="flex-1 p-4 pl-6 cursor-pointer"
                              onClick={() => {
                                skipToVersionIndex(message.versionId || '');
                                setExpandChat(false);
                              }}
                            >
                              <Text fz={14} fw={500}>
                                {message.streamingOutput ||
                                message.loading ||
                                message.streamingAssistantResponse
                                  ? 'Generating...'
                                  : versionData?.name}
                              </Text>
                              <Text fz={12}>Click to open</Text>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
