export enum BannerClickTargetModal {
  GROWTH_PLAN = 'GROWTH PLAN',
  ENTERPRISE_PLAN = 'ENTERPRISE PLAN',
  ENTERPRISE_PLUS_PLAN = 'ENTERPRISE PLUS PLAN',
  PRO_PLAN = 'PRO PLAN',
  PREMIUM_PLAN = 'PREMIUM PLAN',
  PREMIUM_PLUS_PLAN = 'PREMIUM PLUS PLAN',
}

export enum NotificationType {
  SPECIAL_OFFER = 'SPECIAL_OFFER',
  SUGGEST_UPGRADE = 'SUGGEST_UPGRADE',
  TRIAL_COUNTDOWN = 'TRIAL_COUNTDOWN',
  ALERT = 'ALERT',
}
