import { Button, Icon, Text, Menu, Flex, Collapse } from '@tw/ui-components';
import { WILLY_SEQUENCE_STEP_CATEGORIES, WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { useEffect, useMemo, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $user, $isAdminClaim } from '$stores/$user';
import { Dialect, DialectWithBoth, Message, StepWsStatus, WorkflowStep } from '../types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { WillyMessageTemplate } from '../WillyMessageTemplate';
import { TreeVerticalLine } from './TreeVerticalLine';
import { FlowSubWorkflowStep } from './FlowSubWorkflowStep.tsx';
import { FlowLoadDataStep } from './FlowLoadDataStep';
import { FlowSendToDashboardStep } from './FlowSendToDashboardStep';
import { FlowSendToEmailStep } from './FlowSendToEmailStep';
import { FlowSendToSlackStep } from './FlowSendToSlackStep';
import { FlowRuleStep } from './FlowRuleStep';
import { FlowStepWithText } from './FlowStepWithText';
import {
  extractDataFromResponse,
  getMessagesFromWorkflowResponse,
  isStepInvalid,
  validateSequenceSteps,
} from '../utils/sequences';
import { FlowSendToWarehouse } from './FlowSendToWarehouse';
import { FlowSendToWebhookStep } from './FlowSendToWebhookStep';
import { FlowSendToGoogleSheetStep } from './FlowSendToGoogleSheetStep';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { FlowLoadRunsStep } from './FlowLoadRunsStep';
import { SequenceFlowStepHeader } from './SequenceFlowStepHeader';
import { FlowInsightsStep } from './FlowInsightsStep';
import { FlowPreloadGoogleSheetStep } from './FlowPreloadGoogleSheetStep';
import { FlowPreloadGoogleCSV } from './FlowPreloadCSVStep';
import { FlowLoopStep } from './FlowLoopStep';
import { FlowToolStep } from './FlowToolStep';
import { FlowVisionStep } from './FlowVisionStep';
import { FlowStructuredInsightsStep } from './FlowStructuredInsightsStep';
import { SequenceStepFeedback } from './SequenceStepFeedback';
import { FlowSendToPushNotification } from './FlowSendToPushNotification';
import { FlowSendToFeed } from './FlowSendToFeed';
import { emptyArray } from '../utils/willyUtils';
import { FullAnswerModal } from './FullAnswerModal';
import { FlowEmptyStep } from './FlowEmptyStep';

type SequenceFlowStepProps = {
  sequenceId: string;
  currentRunId?: string;
  step: WorkflowStep;
  stepNumber: number;
  deleteStep: (messageId: string) => void;
  stepChange: (step: WorkflowStep) => void;
  allowRegenerateAnswers: boolean;
  onGenerateAnswer: (upToStepId?: string, onlyStepId?: string) => void;
  loadingAnswers: boolean;
  depth: number;
  runInfo: Record<string, StepWsStatus>;
  saveSequences: (sequences: string[], messageId: string) => void;
  readOnly: boolean;
  isLastStep?: boolean;
  noMoreStepsAllowed?: boolean;
  parentStepId?: string;
  fromRule?: boolean;
  dialect: DialectWithBoth;
  useParentWidth?: boolean;
  viewOnly?: boolean;
  siblingSteps: WorkflowStep[];
  isGlobal: boolean;
  stepsUntilNow?: WorkflowStep[];
};

export const SequenceFlowStep: React.FC<SequenceFlowStepProps> = ({
  sequenceId,
  currentRunId,
  step,
  stepNumber,
  deleteStep,
  stepChange,
  allowRegenerateAnswers,
  runInfo,
  onGenerateAnswer,
  loadingAnswers,
  depth,
  saveSequences,
  readOnly,
  isLastStep,
  fromRule,
  dialect,
  noMoreStepsAllowed,
  useParentWidth,
  viewOnly,
  siblingSteps,
  isGlobal,
  stepsUntilNow,
}) => {
  const user = useStoreValue($user);
  const isAdmin = useStoreValue($isAdminClaim);
  const [answerHidden, setAnswerHidden] = useState<boolean>(true);
  const [fullAnswerPopupOpen, setFullAnswerPopupOpen] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  const [newGenUiOutput, setNewGenUiOutput] = useState<string | null>(null);

  const isGenUiStep = useMemo(() => {
    return !!(
      (step.stepType === 'genUiReport' || step.stepType === 'genUiPresentation') &&
      isAdmin
    );
  }, [step, isAdmin]);

  useEffect(() => {
    if (!!isLastStep) {
      setAnswerHidden(false);
    }
  }, [isLastStep]);

  const stepRunInfo = runInfo[step.id];
  const generatedAnswer = stepRunInfo?.response;
  const isRunning = stepRunInfo?.status === 'running';
  const isDone = stepRunInfo?.status === 'done';
  const MAX_ANSWER_BOX_HEIGHT = 300;

  const setAnswerBoxRef = useResizeObserver((entries) => {
    if (!entries[0]) return;
    const { blockSize: height } = entries[0].contentBoxSize[0];
    setIsOverflowing(height > MAX_ANSWER_BOX_HEIGHT);
  });

  const hasAnswer = useMemo(() => {
    return !!generatedAnswer;
  }, [generatedAnswer]);

  const canRegenerateUntilNow = useMemo(() => {
    if (!allowRegenerateAnswers) {
      return false;
    }
    return validateSequenceSteps(stepsUntilNow || []);
  }, [allowRegenerateAnswers, stepsUntilNow]);

  const canRegenerate = useMemo(() => {
    if (!allowRegenerateAnswers) {
      return false;
    }

    const olderSteps = siblingSteps.slice(0, siblingSteps.indexOf(step));

    return !isStepInvalid(step, olderSteps || []);
  }, [allowRegenerateAnswers, step, siblingSteps]);

  const stepError = useMemo(() => {
    return isStepInvalid(step, siblingSteps);
  }, [step, siblingSteps]);

  const categoryConfig = useMemo(() => {
    let { category } =
      WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === step.stepType) || {};
    if (step.stepType === 'emptyStep') {
      category = step.category;
    }
    const toolCategory = WILLY_SEQUENCE_STEP_CATEGORIES.find((option) => option.id === category);
    return toolCategory;
  }, [step]);

  const toolConfig = useMemo(() => {
    const tool = WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === step.stepType);
    return tool;
  }, [step]);

  const messages: Message[] = useMemo(() => {
    if (!generatedAnswer) {
      return [];
    }
    const messagesFromResponse = getMessagesFromWorkflowResponse(
      generatedAnswer,
      dialect as Dialect,
    );
    if (newGenUiOutput && isGenUiStep) {
      messagesFromResponse[messagesFromResponse.length - 1].text = newGenUiOutput;
    }
    return messagesFromResponse;
  }, [generatedAnswer, dialect, newGenUiOutput, isGenUiStep]);

  const allDataFromResponses = useMemo(() => {
    if (!generatedAnswer) {
      return [];
    }
    return Object.values(runInfo)
      .filter((x) => x.response !== null)
      .flatMap((x, index) => extractDataFromResponse(x.response, index));
  }, [generatedAnswer, runInfo]);

  const lastMessageFromUser = useMemo(() => {
    return messages?.findLast((message) => message.role === 'user');
  }, [messages]);

  const showFeedback = useMemo(() => {
    if (!isAdmin || !hasAnswer || !canRegenerate || isRunning || !currentRunId) return false;

    if (
      step.stepType === 'runQuery' ||
      step.stepType === 'insights' ||
      step.stepType === 'genUiReport' ||
      step.stepType === 'genUiPresentation'
    ) {
      return true;
    }

    return false;
  }, [isAdmin, hasAnswer, canRegenerate, isRunning, step, currentRunId]);

  const _onShowAnswer = async (upToStepId?: string, onlyStepId?: string) => {
    if (canRegenerate) {
      await onGenerateAnswer(upToStepId, onlyStepId);
    }
  };

  return (
    <div
      key={step.id}
      className="mx-5"
      style={{
        width: useParentWidth ? '100%' : `calc(700px - ${depth * 50}px)`,
      }}
    >
      {!(depth === 0 && stepNumber === 0) && !viewOnly && <TreeVerticalLine />}

      <div
        className={`flex flex-col bg-white p-[6px]`}
        style={{
          border: `4px solid var(--mantine-color-${categoryConfig?.color ?? 'one'}-${['one', 'two'].includes(categoryConfig?.color || '') ? '2' : '3'})`,
          borderRadius: '10px',
        }}
      >
        <SequenceFlowStepHeader
          step={step}
          isRunning={isRunning}
          isDone={isDone}
          categoryConfig={categoryConfig}
          toolConfig={toolConfig}
          stepRunInfo={stepRunInfo}
          loadingAnswers={loadingAnswers}
          stepNumber={stepNumber}
          stepChange={stepChange}
          deleteStep={deleteStep}
          readOnly={readOnly}
          viewOnly={viewOnly}
          stepsUntilNow={stepsUntilNow}
          depth={depth}
          sequenceId={sequenceId}
        />
        <div className={`flex flex-col p-5 gap-6.5 min-h-[232px]`}>
          <div className="flex justify-between relative">
            <div className="flex-1 min-h-[75px]">
              <div className="w-full relative">
                {(step.stepType === 'runQuery' ||
                  step.stepType === 'runForecasting' ||
                  step.stepType === 'search' ||
                  step.stepType === 'runMarketingMixModel' ||
                  step.stepType === 'runPython') && (
                  <FlowToolStep
                    readOnly={readOnly}
                    step={step}
                    stepChange={stepChange}
                    setIsPristine={setIsPristine}
                    toolConfig={toolConfig}
                    depth={depth}
                    steps={siblingSteps}
                  />
                )}
                {step.stepType === 'emptyStep' && (
                  <FlowEmptyStep
                    step={step}
                    sequenceId={sequenceId}
                    stepsUntilNow={stepsUntilNow}
                    depth={depth}
                    stepChange={stepChange}
                  />
                )}
                {step.stepType === 'rule' && (
                  <FlowStepWithText
                    readOnly={readOnly}
                    text={step.text || ''}
                    textChange={(text) => stepChange({ ...step, text })}
                    variableChange={(variable) => {
                      const variables = step.variables || [];
                      const variableIndex = variables.findIndex((v) => v.key === variable.key);
                      if (variableIndex !== -1) {
                        variables[variableIndex] = variable;
                      } else {
                        variables.push(variable);
                      }
                      stepChange({
                        ...step,
                        variables,
                      });
                    }}
                    toolConfig={toolConfig}
                    setIsPristine={setIsPristine}
                    variables={step.variables || []}
                    depth={depth}
                    allowComments
                  />
                )}
                {(step.stepType === 'insights' ||
                  step.stepType === 'genUiReport' ||
                  step.stepType === 'genUiPresentation') && (
                  <FlowInsightsStep
                    readOnly={readOnly}
                    step={step}
                    stepChange={stepChange}
                    setIsPristine={setIsPristine}
                    depth={depth}
                    toolConfig={toolConfig}
                    workflowId={sequenceId}
                  />
                )}
                {step.stepType === 'structuredInsights' && (
                  <FlowStructuredInsightsStep toolConfig={toolConfig} depth={depth} />
                )}
                {step.stepType === 'vision' && (
                  <FlowVisionStep
                    step={step}
                    stepChange={stepChange}
                    setIsPristine={setIsPristine}
                    depth={depth}
                    toolConfig={toolConfig}
                    disabled={!canRegenerate}
                  />
                )}
                {step.stepType === 'preloadData' && (
                  <div>
                    <FlowLoadDataStep
                      readOnly={readOnly}
                      step={step}
                      stepChange={stepChange}
                      setIsPristine={setIsPristine}
                      workflowDialect={dialect}
                      isGlobal={isGlobal}
                    />
                  </div>
                )}
                {step.stepType === 'preloadRuns' && (
                  <div>
                    <FlowLoadRunsStep
                      readOnly={readOnly}
                      step={step}
                      stepChange={stepChange}
                      setIsPristine={setIsPristine}
                      sequenceId={sequenceId}
                    />
                  </div>
                )}
                {step.stepType === 'preloadGoogleSheet' && (
                  <FlowPreloadGoogleSheetStep
                    readOnly={readOnly}
                    onSave={(args) => {
                      setIsPristine(false);
                      return stepChange({
                        ...step,
                        ...args,
                      });
                    }}
                    sheetsAccount={step.sheetsAccount}
                    spreadsheetId={step.spreadsheetId}
                    spreadsheetName={step.spreadsheetName}
                    worksheetId={step.worksheetId}
                    worksheetName={step.worksheetName}
                  />
                )}
                {step.stepType === 'preloadCSV' && (
                  <FlowPreloadGoogleCSV
                    readOnly={readOnly}
                    onSave={(args) => {
                      setIsPristine(false);
                      return stepChange({
                        ...step,
                        ...args,
                      });
                    }}
                    {...step}
                  />
                )}

                {step.stepType === 'subSequence' && (
                  <FlowSubWorkflowStep
                    readOnly={readOnly}
                    onSave={(sequences) => saveSequences(sequences, step.id)}
                    sequenceIds={step.sequenceIds}
                    limitToOne={fromRule}
                    setIsPristine={setIsPristine}
                    parentSequenceId={sequenceId}
                    isGlobal={isGlobal}
                  />
                )}
                {step.stepType === 'sendToDashboard' && (
                  <FlowSendToDashboardStep
                    readOnly={readOnly}
                    onSave={(dashboardId) => stepChange({ ...step, dashboardId })}
                    dashboardId={step.dashboardId}
                    setIsPristine={setIsPristine}
                  />
                )}
                {step.stepType === 'sendToEmail' && (
                  <FlowSendToEmailStep
                    readOnly={readOnly}
                    email={step.email}
                    setEmail={(email) => stepChange({ ...step, email })}
                    formats={step.formats}
                    setFormats={(formats) => {
                      setIsPristine(false);
                      stepChange({ ...step, formats });
                    }}
                    setIsPristine={setIsPristine}
                  />
                )}
                {step.stepType === 'sendToSlack' && (
                  <FlowSendToSlackStep
                    readOnly={readOnly}
                    setIsPristine={setIsPristine}
                    formats={step.formats}
                    setFormats={(formats) => stepChange({ ...step, formats })}
                  />
                )}
                {step.stepType === 'sendToGoogleSheet' && (
                  <FlowSendToGoogleSheetStep
                    readOnly={readOnly}
                    onSave={(args) => {
                      setIsPristine(false);
                      return stepChange({
                        ...step,
                        ...args,
                      });
                    }}
                    {...step}
                  />
                )}
                {step.stepType === 'sendToWarehouse' && (
                  <FlowSendToWarehouse
                    readOnly={readOnly}
                    depth={depth}
                    onSave={(
                      query,
                      params,
                      useDedicatedQuery,
                      providerId,
                      integrationId,
                      tableId,
                    ) =>
                      stepChange({
                        ...step,
                        query,
                        queryParams: params,
                        useDedicatedQuery: useDedicatedQuery,
                        providerId,
                        integrationId,
                        tableId,
                      })
                    }
                    variableChange={(variable) => {
                      const variables = step.variables || [];
                      const variableIndex = variables.findIndex((v) => v.key === variable.key);
                      if (variableIndex !== -1) {
                        variables[variableIndex] = variable;
                      } else {
                        variables.push(variable);
                      }
                      stepChange({
                        ...step,
                        variables,
                      });
                    }}
                    providerId={step.providerId}
                    integrationId={step.integrationId}
                    tableId={step.tableId}
                    setIsPristine={setIsPristine}
                    query={step.query}
                    useDedicatedQuery={step.useDedicatedQuery}
                    toolConfig={toolConfig}
                    step={step}
                  />
                )}
                {step.stepType === 'sendToPushNotification' && (
                  <FlowSendToPushNotification
                    readOnly={readOnly}
                    text={step.text || ''}
                    variables={step.variables || []}
                    userId={step.userId}
                    textChange={(text) => stepChange({ ...step, text })}
                    variablesChange={(variables) => stepChange({ ...step, variables })}
                    setIsPristine={setIsPristine}
                    depth={depth}
                    toolConfig={toolConfig}
                  />
                )}
                {step.stepType === 'sendToFeed' && <FlowSendToFeed />}
                {step.stepType === 'condition' && (
                  <FlowRuleStep
                    step={step}
                    stepChange={stepChange}
                    readOnly={readOnly}
                    setIsPristine={setIsPristine}
                  />
                )}
                {step.stepType === 'sendToWebhook' && (
                  <FlowSendToWebhookStep
                    step={step}
                    sequenceId={sequenceId}
                    stepChange={(url, headers) => stepChange({ ...step, url, headers })}
                    readOnly={readOnly}
                    setIsPristine={setIsPristine}
                  />
                )}
                {step.stepType === 'loop' && (
                  <FlowLoopStep
                    readOnly={readOnly}
                    setIsPristine={setIsPristine}
                    toolConfig={toolConfig}
                    step={step}
                    onSave={() => stepChange({ ...step })}
                    dialect={dialect}
                    depth={depth}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-auto">
            {!!stepError && !isPristine && (
              <Text size="sm" fw="bold" color="red.4">
                {stepError}
              </Text>
            )}
          </div>
          {step.stepType !== 'emptyStep' && (
            <Flex>
              <Menu position="bottom-end">
                <Menu.Target>
                  <Button.Group>
                    <Button
                      variant="activator"
                      disabled={!canRegenerateUntilNow || !canRegenerate || readOnly}
                      onClick={(e) => {
                        e.stopPropagation();
                        _onShowAnswer(step.id);
                      }}
                    >
                      Run until here
                    </Button>
                    <Button variant="activator" p="xs" disabled={!canRegenerate || readOnly}>
                      <Icon name="chevron-down" size={12} />
                    </Button>
                  </Button.Group>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => _onShowAnswer(step.id, step.id)}
                    disabled={!canRegenerate || readOnly}
                  >
                    Run only this step
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
          )}
          {hasAnswer && <div className="w-full h-[1px] bg-[var(--mantine-color-gray-2)]" />}
          {hasAnswer && (
            <Flex align="center" justify="space-between">
              <div
                onClick={() => {
                  setAnswerHidden((x) => !x);
                }}
                className="cursor-pointer"
              >
                <Text size="sm" color="gray.6" fw={500}>
                  {answerHidden ? 'Show' : 'Collapse'} Response
                </Text>
              </div>
              {showFeedback && (
                <SequenceStepFeedback
                  generatedAnswer={generatedAnswer}
                  sequenceId={sequenceId}
                  currentRunId={currentRunId}
                  step={step}
                  dialect={dialect}
                />
              )}
            </Flex>
          )}
          {hasAnswer && (
            <Collapse in={!answerHidden} transitionDuration={300}>
              <div
                className={`flex flex-col gap-4 transition-[height] duration-300 ease-in-out ${!answerHidden ? 'h-full' : 'h-0'}`}
              >
                {isRunning && (
                  <div className="flex items-center w-full justify-center">
                    <span className="scale-50">
                      <AlanLoaderGray />
                    </span>
                  </div>
                )}
                {!isRunning && !!messages?.length && (
                  <div className="relative">
                    <div
                      className={`max-h-[${MAX_ANSWER_BOX_HEIGHT}px] overflow-hidden`}
                      style={{ maxHeight: `${MAX_ANSWER_BOX_HEIGHT}px` }}
                    >
                      <div ref={setAnswerBoxRef} id="answer-wrapper">
                        {messages?.map((message, i, arr) => {
                          return (
                            <WillyMessageTemplate
                              message={message}
                              dataForGenUi={allDataFromResponses}
                              firstOfAssistant={
                                message.role !== 'user' && arr[i - 1]?.role === 'user'
                              }
                              lastOfAssistant={
                                (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                                i === arr.length - 1
                              }
                              lastMessageFromUser={lastMessageFromUser}
                              canEdit={false}
                              userName={''}
                              handleSubmit={() => {}}
                              key={message.id + '_element'}
                              loading={loadingAnswers}
                              codeActions={emptyArray()}
                              isLast={i === messages.length - 1}
                              conversationUser={user.uid || ''}
                              //   TODO:notsure
                              conversationMessages={messages}
                              showSourcesFirst={true}
                              hideDetails={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                    {isOverflowing && (
                      <div
                        onClick={() => setFullAnswerPopupOpen(true)}
                        className="bg-gradient-to-t from-white via-white to-transparent absolute bottom-0 h-[150px] items-end justify-center w-full flex cursor-pointer z-10"
                      >
                        <Text fz={14} fw={600} color="one.6">
                          Open Full Answer
                        </Text>
                      </div>
                    )}
                  </div>
                )}
                {!!stepRunInfo?.comments?.length && (
                  <div className="flex flex-col gap-2">
                    {stepRunInfo.comments.map((comment) => (
                      <Text fz={12} key={comment}>
                        {comment}
                      </Text>
                    ))}
                  </div>
                )}
              </div>
            </Collapse>
          )}
          {!!stepRunInfo?.progress && (
            <div className="flex items-center gap-2">
              <Text fz={12} fw={500}>
                {stepRunInfo.progress}
              </Text>
            </div>
          )}
        </div>
      </div>

      {!noMoreStepsAllowed && !viewOnly && <TreeVerticalLine />}

      {fullAnswerPopupOpen && (
        <FullAnswerModal
          fullAnswerPopupOpen={fullAnswerPopupOpen}
          setFullAnswerPopupOpen={setFullAnswerPopupOpen}
          messages={messages}
          lastMessageFromUser={lastMessageFromUser}
          loadingAnswers={loadingAnswers}
          user={user}
          isGenUiStep={isGenUiStep}
          setNewGenUiOutput={setNewGenUiOutput}
          sequenceId={sequenceId}
          stepId={step.id}
          runId={currentRunId}
          dataForGenUi={allDataFromResponses}
        />
      )}
    </div>
  );
};
