import moment from '@tw/moment-cached';
import { TwNumberFormatOptions } from '@tw/types';

// convert number of seconds to duration in format 00:00:00
export const formatDuration = (seconds: number): string => {
  if (seconds < 0) {
    return '00:00:00';
  }
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secs = duration.seconds();
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    secs < 10 ? '0' : ''
  }${secs}`;
};

export const formatNumber = (value: number, options: TwNumberFormatOptions): string => {
  try {
    // we want moment format and not Intl format, for backward compatibility
    if (options.style === 'date') {
      return moment(value).format(options.dateFormat);
    }
    if (options.style === 'phone') {
      return formatPhoneNumber(value);
    }
    if (options.style === 'duration') {
      return formatDuration(value);
    }
    // if you explicitly want a string, you get a string
    if (options.style === 'string') {
      return value.toString();
    }
    if (!value || Number.isNaN(+value) || !Number.isFinite(+value)) {
      value = 0;
    }
    if ((options.minimumFractionDigits || 0) > (options.maximumFractionDigits || 0)) {
      options.maximumFractionDigits = options.minimumFractionDigits || 0;
    }
    return value.toLocaleString(undefined, options as Intl.NumberFormatOptions);
  } catch (e) {
    console.error('formatNumber went wrong', options, value, e);
    return value.toString();
  }
};

export const formatToFixed = (value: any, decimals = 2): string => {
  if (typeof value === 'number') {
    return value.toFixed(decimals);
  }
  return value ?? '';
};

export const formatPhoneNumber = (input: number): string => {
  // Convert input to a string and remove non-numeric characters
  let cleaned = ('' + input).replace(/\D/g, '');

  // Israeli local format (0XX-XXX-XXXX)
  if (cleaned.length === 10 && cleaned.startsWith('0')) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // Israeli international format (+972-X-XXX-XXXX)
  if (cleaned.length === 11 && cleaned.startsWith('972')) {
    return `+972-${cleaned.slice(3, 4)}-${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }

  // US format (XXX) XXX-XXXX
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // US format with country code +1 (XXX) XXX-XXXX
  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }

  // UK format +44 XXXX XXXXXX
  if (cleaned.length === 12 && cleaned.startsWith('44')) {
    return `+44 ${cleaned.slice(2, 6)} ${cleaned.slice(6)}`;
  }

  // Indian format +91 XXXXX-XXXXX
  if (cleaned.length === 13 && cleaned.startsWith('91')) {
    return `+91 ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
  }

  // Some European formats XXX-XXX-XXX
  if (cleaned.length === 9) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // If input does not match any known pattern, return it as a string
  return String(input);
};
