import { Flex, Text } from '@tw/ui-components';
import {
  BqColumn,
  BuilderTable,
  WillyParameter,
} from '@tw/willy-data-dictionary/module/columns/types';
import { FC, useState } from 'react';
import { AddMetricPopover } from './TableBuilderTab';
import { valueIsDatePickerTimePeriod } from 'components/Willy/utils/willyUtils';
import { BuilderBadge } from './BuilderBadge';
import { FilterFieldModal } from './FilterFieldModal';
import { WillyMetric } from 'components/Willy/types/willyTypes';
import { BqTable } from '@tw/willy-data-dictionary';
import { getDatePickerOptionsDictionary } from 'components/useDatePickerSelectedOptions';

type AddFiltersSectionProps = {
  builder: BuilderTable;
  setBuilder: (builder: BuilderTable) => void;
  columnsAsMetrics: WillyMetric[];
  columns: BqColumn[];
  currentTable: BqTable | undefined;
  disabled?: boolean;
};

const datePickerOptions = getDatePickerOptionsDictionary();

export const AddFiltersSection: FC<AddFiltersSectionProps> = ({
  builder,
  setBuilder,
  columnsAsMetrics,
  columns,
  currentTable,
  disabled,
}) => {
  const [filterToEdit, setFilterToEdit] = useState<WillyParameter | undefined>();

  return (
    <Flex direction="column" gap="sm">
      <Flex justify="space-between" align="center">
        <Text weight={500} color="gray.8" size="sm">
          Add Filters
        </Text>
        <AddMetricPopover
          disabled={disabled}
          metrics={columnsAsMetrics.map((m) => ({
            ...m,
            active: builder?.filters.some((f) => f.column === m.key),
          }))}
          onAdd={(metric) =>
            setBuilder({
              ...builder!,
              filters: metric.active
                ? [
                    ...builder.filters,
                    {
                      column: metric.key,
                      operator: '=',
                      value: '',
                      visible: true,
                    },
                  ]
                : builder!.filters.filter((c) => c.column !== metric.key),
            })
          }
          readmeUrl={currentTable?.readmeUrl}
          tableName={currentTable?.name}
          offset={10}
        />
        {/* <Button variant="white" size="xs" onClick={() => setFiltersOpen(true)}>
              Add
            </Button> */}
      </Flex>

      {builder?.filters.map((filter) => {
        const isDatePeriod =
          typeof filter.value === 'string' && valueIsDatePickerTimePeriod(filter.value);
        return (
          <BuilderBadge
            key={`filter-${filter.column}`}
            text={`${columnsAsMetrics.find((m) => filter.column === m.key)?.name} ${
              filter.operator
            } ${isDatePeriod ? datePickerOptions[filter.value as string].label : filter.value}`}
            type="filter"
            onClick={() => setFilterToEdit(filter)}
            onRemove={() =>
              setBuilder({
                ...builder!,
                filters: builder!.filters.filter((f) => f.column !== filter.column),
              })
            }
          />
        );
      })}

      <FilterFieldModal
        isOpen={!!filterToEdit}
        onClose={() => setFilterToEdit(undefined)}
        parameter={filterToEdit}
        parameters={builder.filters}
        column={columns.find((c) => c.id === filterToEdit?.column)}
        columns={columns}
        parametersChange={(parameters) => {
          setBuilder({
            ...builder,
            filters: parameters,
          });
        }}
      />
    </Flex>
  );
};
