import { ActionIcon, Modal } from '@tw/ui-components';
import { useState, useRef, useEffect } from 'react';

type HtmlViewerActionProps = {
  html: string;
  title?: string;
};

export const HtmlViewerAction: React.FC<HtmlViewerActionProps> = ({
  html,
  title = 'HTML Viewer',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [srcdoc, setSrcdoc] = useState<string>('');

  // We'll use height: auto instead of setting a fixed height
  const containerRef = useRef<HTMLDivElement>(null);

  // Listen for messages from the iframe
  useEffect(() => {
    if (!isOpen) return;

    // Handler for messages from iframe
    const handleMessage = (event: MessageEvent) => {
      if (event.data && typeof event.data === 'object' && 'height' in event.data) {
        const iframe = document.querySelector('iframe');
        if (iframe && event.data.height > 0) {
          iframe.style.height = `${event.data.height}px`;
        }
      }
    };

    // Add message listener
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isOpen]);

  // Prepare the HTML content when modal opens
  useEffect(() => {
    if (!isOpen) return;

    try {
      // Try to parse as URL
      new URL(html);
      // If we get here, it's a valid URL, we'll use an iframe with src
      setSrcdoc('');
    } catch (e) {
      // It's HTML content, let's add the height adjustment script
      const resizeScript = `
        <script>
          document.addEventListener('DOMContentLoaded', function() {
            // Send document height to parent
            function sendHeight() {
              var height = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
              );
              window.parent.postMessage({ height: height }, '*');
            }
            
            // Send height on load and periodically
            window.addEventListener('load', sendHeight);
            setInterval(sendHeight, 200);
            
            // Send height when DOM changes
            var observer = new MutationObserver(sendHeight);
            observer.observe(document.body, { 
              childList: true, 
              subtree: true,
              attributes: true 
            });
            
            // Initial send
            sendHeight();
          });
        </script>
      `;

      // Prepare the HTML with the script
      let modifiedHtml = html;
      if (modifiedHtml.includes('<head>')) {
        modifiedHtml = modifiedHtml.replace('</head>', `${resizeScript}</head>`);
      } else {
        modifiedHtml = `<html><head>${resizeScript}</head><body>${modifiedHtml}</body></html>`;
      }

      setSrcdoc(modifiedHtml);
    }
  }, [isOpen, html]);

  return (
    <div>
      <ActionIcon icon="html" onClick={() => setIsOpen(true)} />

      {!!isOpen && (
        <Modal.Root
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          closeOnEscape
          // h="100vh"
          w="90%"
        >
          <Modal.Overlay opacity={0.7} />
          <Modal.Content className="!overflow-hidden">
            <Modal.Header className="border-b border-solid border-gray-200 border-t-0 border-l-0 border-r-0">
              <Modal.Title>{title}</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body className="overflow-hidden !p-0">
              <div ref={containerRef} style={{ width: '100%', height: 'auto', overflow: 'hidden' }}>
                {isOpen &&
                  (srcdoc ? (
                    <iframe
                      srcDoc={srcdoc}
                      style={{
                        width: '100%',
                        border: 'none',
                        height: '80vh', // Start tall and let it resize
                      }}
                      sandbox="allow-scripts allow-same-origin"
                      onLoad={(e) => {
                        // Try to adjust height on load
                        const iframe = e.currentTarget;
                        try {
                          const height = iframe.contentDocument?.body?.scrollHeight;
                          if (height && height > 0) {
                            iframe.style.height = `${height}px`;
                          }
                        } catch (err) {
                          console.error('Failed to adjust height on load', err);
                        }
                      }}
                    />
                  ) : (
                    <iframe
                      src={html}
                      style={{
                        width: '100%',
                        height: '80vh',
                        border: 'none',
                      }}
                      sandbox="allow-scripts allow-same-origin"
                    />
                  ))}
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
    </div>
  );
};
