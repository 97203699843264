import TWImage from 'components/library/TWImage/TWImage';
import {
  DISABLE_SHOW_IN_CHART_MESSAGE,
  getServicesToShowStatusColumn,
  LOADING_ROW_ID,
  MAX_SELECTED_ATTRIBUTIONS,
  UNMATCHED_ROWS_ID,
} from 'constants/attribution';
import { metrics } from 'constants/metrics/metrics';
import allServices from 'constants/services';
import { PixelColumn, PixelColumnsKeys } from 'types/attribution';
import { AFFLUENCER, ALL_SOURCES_ID } from 'constants/types';
import { getSourceExternalLink } from 'utils/getSourceExternalLink';
import { calculateDelta } from 'utils/calculate-delta';
import { formatNumber } from 'utils/formatNumber';

import { SkeletonBodyText, SkeletonDisplayText, Tooltip } from '@shopify/polaris';
import {
  ChevronDownMinor,
  ChevronUpMinor,
  ExternalSmallMinor,
  InfoMinor,
  LinkMinor,
  QuestionMarkMinor,
} from '@shopify/polaris-icons';

import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import { OrdersButton } from './OrdersButton';
import ToggleStatus from './toggleStatus';
import React, { useContext } from 'react';

import UpdatableBudget from './UpdatableBudget';
import { TRIPLE_SURVEY_DEFAULT_CHANNELS } from 'components/PostPurchaseSurvey/SurveyBuilder/attribution';
import UpdatableBidAmount from './UpdatableBidAmount';
import { TableCellLoader } from 'components/library/Loaders/TableCellLoader';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { MetricsKeys } from '@tw/types/module/metrics/metrics';
import { SourceTypes } from 'types/services';
import { savedPreset } from 'components/library/TWTable/types';
import { Checkbox, ActionIcon, Title, Icon, Tooltip as TwTooltip } from '@tw/ui-components';
import { InfluencersContext } from 'components/Influencers/context';
import copyToClipboard from 'utils/copyToClipboard';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as InfluencerIcon } from '../../icons/affluencer-hub.svg';
import { MetricClientData } from 'types/metrics';
import {
  analyticsEvents,
  attributionActions,
  genericEventLogger,
  upgradeActions,
} from 'utils/dataLayer';
import { SubscriptionsButton } from './SubscriptionsButton';
import { upgradePixelModalOpened } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { $isFreeShop } from '$stores/willy/$subscription';
import { $mergedAiColumns } from '$stores/willy/$sequences';
import { $derived, useStoreValue } from '@tw/snipestate';
import { ExperimentButton } from './ExperimentButton';
import { $ffStore } from 'feature-flag-system';
import { ConversionsButton } from './ConversionsButton';
import { AiColumnHeader } from 'components/AiColumnHeader';
import { entityRowBackground } from 'components/attribution/utils';
import { AiColumnCell } from 'components/AiColumnCell';
import { ServicesIds } from '@tw/types/module/services';
import { links } from 'types/Influencers';

const SurveyServiceIds = Object.values(allServices)
  .filter((s) => s.type === 'survey')
  .map((s) => s.id);

const ellipsisText =
  'whitespace-nowrap text-ellipsis overflow-hidden max-w-[150px] sm:max-w-[300px]';

const summaryPixelWidgetColumns: PixelColumn[] = [
  {
    key: 'showInGraph',
    name: 'Show In Graph',
    dataType: 'text',
    sortable: false,
    isDefault: true,
    isFixed: true,
    Heading: () => (
      <div className="flex items-center justify-center py-1" id="att-chart-header-show-in-graph">
        <Icon name="combo-chart" />{' '}
      </div>
    ),
    Value: (attribution, metadata) => {
      const {
        selectedAttributions = [],
        toggleAttributionSelection,
        toggleChartOpen = () => {},
      } = metadata || {};
      const showInChartCheckbox = (
        <Checkbox
          disabled={
            !attribution.selected &&
            (selectedAttributions?.length || 0) >= MAX_SELECTED_ATTRIBUTIONS
          }
          checked={!!selectedAttributions.find((x) => x.id === attribution.id)}
          onChange={(newChecked) => {
            toggleAttributionSelection!(attribution);
            if (newChecked) {
              toggleChartOpen(true);
            } else if (!newChecked && selectedAttributions.length === 1) {
              toggleChartOpen(false);
            }
          }}
        />
      );
      if (attribution.id === UNMATCHED_ROWS_ID) {
        return <></>;
      }
      return !attribution.selected &&
        (selectedAttributions?.length || 0) >= MAX_SELECTED_ATTRIBUTIONS ? (
        <div
          className={`p-4 flex justify-center items-center h-full ${entityRowBackground(
            attribution.entity!,
          )}`}
        >
          <Tooltip content={DISABLE_SHOW_IN_CHART_MESSAGE}>{showInChartCheckbox}</Tooltip>
        </div>
      ) : attribution.selected ||
        (selectedAttributions?.length || 0) < MAX_SELECTED_ATTRIBUTIONS ? (
        <div
          className={`p-4 flex justify-center items-center h-full ${entityRowBackground(
            attribution.entity!,
          )}`}
        >
          {showInChartCheckbox}
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: 'status',
    name: 'Status',
    dataType: 'text',
    sortable: true,
    isDefault: true,
    showInSources: getServicesToShowStatusColumn(),
    excludeFromAllLevel: true,
    isFixed: true,
    Heading: () => <div className="flex items-center w-full justify-center py-1">Status</div>,
    Value: (attribution, metadata) => {
      const { id, entity, status, adsetId, adId } = attribution;
      const { sourceId, sourceCategory, updateAdsAllowed, setData, setIntegrationsPopUpOpened } =
        metadata || {};
      const isSocial = sourceId === 'tw_referrer';
      const isPpSurvey = entity === 'campaign' && SurveyServiceIds.includes(id as any);

      return (
        <div
          className={`p-4 flex justify-center items-center h-full ${entityRowBackground(
            attribution.entity!,
          )}`}
        >
          {entity !== 'channel' &&
          !isPpSurvey &&
          attribution.status &&
          allServices[sourceId!]?.updatableFields?.['status'] ? (
            <Tooltip
              content={
                updateAdsAllowed ? (
                  status
                ) : (
                  <div className="flex flex-col gap-4">
                    <p>{status}</p>
                    <p className="text-secondary-text text-xl">
                      Contact the Customer Experience team to enable this feature for your account
                    </p>
                  </div>
                )
              }
            >
              <ToggleStatus
                attribution={attribution}
                setData={setData}
                setIntegrationsPopUpOpened={setIntegrationsPopUpOpened}
              />
            </Tooltip>
          ) : (
            <span
              className={`w-4 h-4 rounded-full flex-shrink-0 ${
                status === 'ACTIVE' ? 'bg-[#3acf00]' : 'bg-gray-400'
              }`}
            >
              <Tooltip content={status}>
                <div className="w-full h-full"></div>
              </Tooltip>
            </span>
          )}
          {entity !== 'channel' &&
            allServices[sourceId!] &&
            !isPpSurvey &&
            !isSocial &&
            sourceCategory === 'ads' &&
            adsetId !== LOADING_ROW_ID &&
            adId !== LOADING_ROW_ID &&
            id !== UNMATCHED_ROWS_ID &&
            !attribution.status && (
              <Tooltip
                content={`We couldn't match this ${entity} to any data in ${
                  allServices[sourceId!]?.title || sourceId
                }, please check your UTMs`}
              >
                <span className="relative after:content-[''] after:absolute after:bg-light-primary after:h-full after:w-0.5 after:left-[7px] after:top-[-2px] after:-rotate-45">
                  <LinkMinor className="fill-light-primary" width={14} height={14} />
                </span>
              </Tooltip>
            )}
        </div>
      );
    },
  },
  {
    key: 'name',
    name: 'Name',
    dataType: 'text',
    sortable: true,
    isDefault: true,
    isFixed: true,
    Heading: (metadata) => {
      const { sourceId, sourceCategory } = metadata || {};
      return (
        <>
          <div
            className="flex items-center gap-4 font-medium w-full"
            id="att-chart-source-campaign"
          >
            {metadata?.activeModel === 'ppsViews' && (
              <Tooltip
                content={
                  <span>
                    We've enhanced the{' '}
                    <a href="https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model">
                      {' '}
                      Total Impact Attribution Model{' '}
                    </a>{' '}
                    to provide even more accurate insights into which channels drive the most
                    revenue. As of January 2025, historical data has been updated with the new
                    algorithm for consistent and reliable comparisons.
                  </span>
                }
                dismissOnMouseOut
              >
                <span>
                  <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                </span>
              </Tooltip>
            )}

            <span>
              {sourceId === 'all' || (sourceCategory === 'all' && sourceId === ALL_SOURCES_ID)
                ? 'Source'
                : sourceId === AFFLUENCER
                  ? 'Affluencer'
                  : 'Campaign'}
            </span>
          </div>
        </>
      );
    },
    Value: (d, metadata) => {
      let {
        entity,
        campaignId,
        adsetId,
        adId,
        id,
        accountId,
        metricsBreakdown,
        survey,
        serviceId,
      } = d;
      const parentValues = metricsBreakdown?.map((x) => ({
        date: x.date,
        pixelPurchases: x.metrics.pixelPurchases - (x.metrics.metaPurchases || 0),
        pixelConversionValue:
          x.metrics.pixelConversionValue -
          (x.metrics.metaConversionValue || x.metrics.tiktokShopConversionValue || 0),
        pixelNcPurchases: x.metrics.pixelNcPurchases,
        pixelNcConversionValue: x.metrics.pixelNcConversionValue,
        ppsWeightedPurchases: x.metrics.ppsWeightedPurchases,
        ppsWeightedConversionValue: x.metrics.ppsWeightedConversionValue,
        ppsWeightedNcPurchases: x.metrics.ppsWeightedNcPurchases,
        ppsWeightedNcConversionValue: x.metrics.ppsWeightedNcConversionValue,
      }));
      const {
        fetchData,
        setData,
        setAttributionInModal,
        setWorkflowInModal,
        setShowUnmatchedRows,
        sourceId,
        sourceCategory,
        wrapTableLinesMode,
        mainDatePickerSelectionRange,
        hasWorkflowPixelIntegrated,
        activeModel,
        navigate,
        location,
        skipChannelMapping = [],
        pixelWorkflows,
        featureFlagComputer,
      } = metadata || {};
      let service = allServices[d.id!];
      if (entity === 'channel' && skipChannelMapping.includes(d.id!)) {
        service = null;
      }

      const storageKey = `TW_${sourceCategory}_page_attribution_source`;
      const isSocial = sourceId === 'tw_referrer';
      const isPpSurvey = entity === 'campaign' && SurveyServiceIds.includes(id as any);
      const showAsSurvey =
        survey && (!allServices[d.id!] || TRIPLE_SURVEY_DEFAULT_CHANNELS.includes(d.id!));
      const noneOfTheAbove = sourceId === 'None of the above';
      const isPixelLimitedToSource =
        featureFlagComputer?.getConfigById(FeatureFlag.LIMIT_PIXEL_SOURCE_ONLY_FF)
          ?.shouldNotBeSeen === false;

      const isNotClickable =
        !isExtanded(serviceId!, entity!) ||
        isPpSurvey ||
        isSocial ||
        showAsSurvey ||
        noneOfTheAbove ||
        // isPixelLimitedToSource ||
        (sourceId === AFFLUENCER &&
          (d.id === UNMATCHED_ROWS_ID ||
            (d.metrics?.pixelVisitors === undefined && d.metrics?.pixelPurchases === undefined)));

      const link = getSourceExternalLink(sourceId!, entity!, accountId!, campaignId, adsetId, adId);
      const dispatch = useAppDispatch();
      const navigateOrExpand = async (e: React.SyntheticEvent) => {
        if ((e.target as HTMLDivElement)?.className?.includes?.('react-switch-handle')) {
          return;
        }

        if (id === UNMATCHED_ROWS_ID) {
          setShowUnmatchedRows!((show) => !show);
          setTimeout(() => {
            const table = document.querySelector(
              '.triple-table .Polaris-DataTable__ScrollContainer',
            );
            table!.scrollTop = (table?.scrollTop || 0) + 100;
          }, 100);
          return;
        }
        if (isPixelLimitedToSource) {
          dispatch(upgradePixelModalOpened('Campaign Attribution'));
          genericEventLogger(analyticsEvents.UPGRADE, {
            action: upgradeActions.UPGRADE_CLICKED,
            pathname: location?.pathname,
            upgrade_path: 'PIXEL_LIMITED_TO_SOURCE',
            targetToUnlock: 'campaignAttribution',
            isFoundersDash: $isFreeShop.get(),
          });
          return;
        }
        if (isNotClickable) {
          return;
        }
        //else if (id === allServices.influencers?.id) {
        //   navigate?.({
        //     pathname: `${
        //       location?.pathname.includes('dashboards') ? '/dashboards' : ''
        //     }/attribution/ads/affluencer`,
        //     search: location?.search,
        //   });
        // }
        else if (entity === 'channel') {
          let channelType = allServices[id!]?.type || ALL_SOURCES_ID;
          let url = '/attribution';
          if (location?.pathname.includes('dashboards')) {
            url = '/dashboards/attribution';
          }
          navigate?.(
            {
              pathname: `${url}/${channelType}/${id}`,
              search: location?.search,
            },
            {
              state: {
                parentValues: activeModel === 'ppsViews' ? parentValues : undefined,
              },
            },
          );
          if (sourceCategory !== 'all') {
            localStorage.setItem(storageKey, id || ALL_SOURCES_ID);
          }
          return;
        } else if (entity === 'campaign') {
          setData?.((oldCampaigns) => {
            return oldCampaigns.map((x) => ({
              ...x,
              isExpanded: id === x.id ? !x.isExpanded : x.isExpanded,
            }));
          });
        } else if (entity === 'adset') {
          setData?.((oldCampaigns) => {
            return oldCampaigns.map((campaign) => {
              return {
                ...campaign,
                adsets: campaign.adsets?.map((adset) => ({
                  ...adset,
                  isExpanded: id === adset.id ? !adset.isExpanded : adset.isExpanded,
                })),
              };
            });
          });
        }
      };

      const entityHasWorkflows = !!pixelWorkflows?.filter(
        (s) =>
          entity &&
          s.pixelSettings &&
          s.pixelSettings?.[entity] &&
          s.pixelSettings?.[entity]?.use &&
          (s.pixelSettings?.[entity]?.providers?.length === 0 ||
            s.pixelSettings?.[entity]?.providers?.includes(service?.id)),
      )?.length;

      const nameToDisplay =
        entity === 'channel' || isPpSurvey
          ? service?.title || d.name || d.id
          : String(d.name || d.id);
      if (id === UNMATCHED_ROWS_ID) {
        return (
          <p
            className={`${
              isNotClickable ? 'font-medium' : 'text-logo hover:underline cursor-pointer font-bold'
            } px-4 py-4`}
            {...(isNotClickable ? {} : { onClick: navigateOrExpand })}
          >
            Unmatched Events
          </p>
        );
      }

      if (adsetId === 'no_data' || adId === 'no_data') {
        return <p className="text-center">{d.name}</p>;
      }

      return (
        <div
          data-testid={`att-attribution-row-${entity}-${d.isExpanded ? 'expanded' : 'collapsed'}`}
          className={`px-4 py-0 flex items-center h-full group ${entityRowBackground(entity!)} ${
            entity === 'adset' ? '' : entity === 'ad' ? 'pl-0' : ''
          }`}
        >
          {adsetId === 'loading_row_not_real' || adId === 'loading_row_not_real' ? (
            <div className="w-32">
              <SkeletonDisplayText size="small" />
            </div>
          ) : (
            <div className="flex gap-4 items-center min-w-[150px] sm:min-w-[300px] w-full flex-auto">
              {(entity === 'channel' || isPpSurvey) &&
                !showAsSurvey &&
                typeof service?.icon === 'function' && (
                  <span className="flex items-center">{service?.icon?.({ small: true })}</span>
                )}
              {showAsSurvey && (
                <span className="flex items-center">
                  <Tooltip content="Post-purchase survey attributed">
                    {service?.icon?.({ small: true })}
                  </Tooltip>
                </span>
              )}
              {entity === 'adset' && (
                <span className="cursor-pointer" onClick={navigateOrExpand}>
                  {d.isExpanded ? (
                    <ChevronUpMinor width={14} height={14} />
                  ) : (
                    <ChevronDownMinor width={14} height={14} />
                  )}
                </span>
              )}
              <Tooltip content={nameToDisplay} dismissOnMouseOut active={false}>
                <div
                  onClick={navigateOrExpand}
                  className="flex-auto"
                  id={`tr-pixel-click-source-${nameToDisplay.toString().toLowerCase().replace(/ /g, '-')}`}
                >
                  <span
                    className={`flex items-center gap-4 ${
                      d.adId || isNotClickable ? 'font-medium' : 'hover:underline cursor-pointer'
                    }
                    ${
                      entity === 'ad' || isNotClickable
                        ? 'hover:no-underline'
                        : entity !== 'channel'
                          ? 'text-[var(--mantine-color-one-5)]'
                          : 'font-bold'
                    }`}
                  >
                    {entity === 'ad' && d.imageUrl && (
                      <TWImage
                        width={15}
                        height={15}
                        src={d.imageUrl ? d.imageUrl : ''}
                        className="w-full h-full object-cover rounded-md cursor-pointer p-1"
                        wrapperClass="w-16 h-16 shrink-0"
                        onClick={() => {
                          setAttributionInModal?.(d as any);
                        }}
                      />
                    )}
                    {entity === 'ad' && !d.imageUrl && (
                      <span className="flex items-center w-16 h-16 justify-center shrink-0">
                        {service?.icon?.({ small: false })}
                      </span>
                    )}
                    <div className="flex items-center gap-4 flex-auto">
                      <span className={wrapTableLinesMode ? 'break-all' : ellipsisText}>
                        <span>{nameToDisplay}</span>
                      </span>
                      {link && (
                        <a
                          className="ml-auto"
                          href={link}
                          title="external link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ExternalSmallMinor className="w-8 h-8 fill-[var(--mantine-color-one-5)] cursor-pointer flex items-center opacity-0 group-hover:opacity-80 transition-opacity duration-300" />
                        </a>
                      )}
                    </div>
                  </span>
                </div>
              </Tooltip>
              {!!hasWorkflowPixelIntegrated && entityHasWorkflows && (
                <div className="scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-300 mr-2">
                  <ActionIcon
                    color="one.6"
                    icon="star-plus"
                    onClick={() => {
                      setWorkflowInModal?.(d);
                      genericEventLogger(analyticsEvents.ATTRIBUTION, {
                        action: attributionActions.OPEN_PIXEL_WORKFLOWS_POPUP,
                        source: 'pixel',
                        sourceDetails: { entity: entity || 'campaign', id: d.id || '' },
                      });
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      );
    },
    Total: (totals, metadata) => {
      const { data, sourceId, sourceCategory } = metadata || {};
      return typeof data?.length === 'number' ? (
        <div className="px-4 py-2 flex items-center">
          (
          {
            data.filter((d) => {
              if (
                sourceId === ALL_SOURCES_ID ||
                (sourceCategory === 'all' && sourceId === ALL_SOURCES_ID)
              ) {
                return d.entity === 'channel';
              }
              return d.entity === 'campaign';
            }).length
          }{' '}
          {sourceId === ALL_SOURCES_ID || (sourceCategory === 'all' && sourceId === ALL_SOURCES_ID)
            ? 'Sources'
            : 'Campaigns'}
          )
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: 'budget',
    name: 'Budget',
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: [
      'facebook-ads',
      'snapchat-ads',
      'pinterest-ads',
      'google-ads',
      'tiktok-ads',
      'twitter-ads',
    ],
    excludeFromAllLevel: true,
    isFixed: false,
    Heading: () => (
      <div className="flex items-center justify-center gap-4 font-medium w-full">Budget</div>
    ),
    Value: (attribution, metadata) => {
      if (
        attribution.adsetId === 'loading_row_not_real' ||
        attribution.adId === 'loading_row_not_real'
      ) {
        return (
          <div className="flex gap-4 items-center w-full justify-center">
            <div className="w-52 justify-center">
              <SkeletonDisplayText size="small" />
            </div>
          </div>
        );
      }
      return (
        <div
          className={`flex gap-4 font-medium min-w-[150px] w-full px-4 py-2 text-left h-full ${entityRowBackground(
            attribution.entity!,
          )}`}
        >
          <UpdatableBudget attribution={attribution} metadata={metadata} />
        </div>
      );
    },
  },
  {
    key: 'destinationUrl',
    name: 'Destination Link',
    dataType: 'text',
    sortable: false,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: true,
    isFixed: false,
    Heading: () => (
      <div className="flex items-center justify-center gap-4 font-medium w-full">
        Destination Link
      </div>
    ),
    Value: (attribution, metadata) => {
      const { wrapTableLinesMode } = metadata || {};
      if (
        attribution.adsetId === 'loading_row_not_real' ||
        attribution.adId === 'loading_row_not_real'
      ) {
        return (
          <div className="flex gap-4 items-center w-full justify-center">
            <div className="w-52 justify-center">
              <SkeletonDisplayText size="small" />
            </div>
          </div>
        );
      }
      return (
        <div
          className={`flex gap-4 font-medium min-w-[150px] w-full px-4 py-2 text-left h-full ${entityRowBackground(
            attribution.entity!,
          )}`}
        >
          {attribution.destinationUrl && (
            <>
              <Tooltip content="Copy to Clipboard">
                <span className="flex pt-2">
                  <ActionIcon
                    variant="transparent"
                    onClick={() => copyToClipboard(attribution.destinationUrl)}
                    icon={'copy-2'}
                    size="xs"
                  />
                </span>
              </Tooltip>

              <a
                className="mt-1.5"
                href={attribution.destinationUrl}
                title={attribution.destinationUrl}
                target="_blank"
                rel="noreferrer"
                style={{
                  fontSize: '14px',
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: `${wrapTableLinesMode ? 'normal' : 'nowrap'}`,
                }}
              >
                {attribution.destinationUrl}
              </a>
            </>
          )}
        </div>
      );
    },
  },
  {
    key: 'overlap',
    name: 'Overlap',
    index: 15,
    dataType: 'text',
    sortable: false,
    isDefault: false,
    Heading: () => {
      return (
        <div
          className="flex items-center gap-4 font-medium w-full"
          id="att-chart-header-orders-overlap"
        >
          <span>
            <Icon name="tw-blue-logo" />
          </span>
          <span>Orders Overlap</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      const isColumnLocked = metadata?.featureFlagComputer?.getConfigById(
        FeatureFlag.PIXEL_CHANNEL_OVERLAP_FF,
      ).shouldNotBeSeen;

      // TODO: This only hides the data, but there isn't any CTA to get user to upgrade to see column. Need to create mask component to hide column with CTA eventually
      if (isColumnLocked) return <TableCellLoader />;

      const { loadingOverlap } = metadata || {};
      const { breakdown, total_orders = 0 } = item.overlaps || {};
      const total_percentage = parseInt(item.overlaps?.total_orders || '0') / item.pixelPurchases;

      const isSupportedModel = metadata?.activeModel === 'lastPlatformClick-v2';
      if (item.id === UNMATCHED_ROWS_ID || !isSupportedModel) {
        return <></>;
      }
      return (
        <div
          className={`flex items-center justify-center w-full gap-4 px-4 py-2 h-full ${entityRowBackground(
            item.entity!,
          )}`}
        >
          {loadingOverlap ? (
            <div className="w-6 h-6 flex items-center">
              <div className="flex-auto">
                <SkeletonBodyText lines={1} />
              </div>
            </div>
          ) : (
            !!+total_orders &&
            !!+total_percentage && (
              <>
                <div className="bg-[#d2e4fd] rounded-2xl py-[1px] px-4">
                  {formatNumber(+total_percentage, {
                    style: 'percent',
                    maximumFractionDigits: 0,
                  })}
                </div>
                <Tooltip
                  content={
                    <div className="flex flex-col gap-2">
                      {Object.entries(breakdown || {})
                        .sort((a: any, b: any) => b[1] - a[1])
                        .map(([sourceId, percent], i) => {
                          sourceId = sourceId.replace('ref__', 'Organic: ');
                          return (
                            <div
                              key={i}
                              className="flex items-center gap-8 justify-between last-of-type:border-b-0 :border-b border-[#d2e4fd] border-solid border-t-0 border-l-0 border-r-0 pb-2 last-of-type:pb-0"
                            >
                              <span className="font-medium">
                                {allServices[sourceId]?.title || sourceId}
                              </span>
                              <span className="shrink-0">
                                {formatNumber(+percent, {
                                  style: 'percent',
                                  maximumFractionDigits: 1,
                                })}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  }
                >
                  <div className="bg-[#e4e7ec] rounded-2xl py-[1px] px-4">{total_orders}</div>
                </Tooltip>
              </>
            )
          )}
        </div>
      );
    },
  },
  {
    key: 'urlParams',
    name: 'Ad URL',
    index: 20,
    dataType: 'text',
    isDefault: false,
    sortable: false,
    Heading: (metadata) => {
      const { sourceId } = metadata || {};
      return (
        <div className="flex items-center gap-4 font-medium w-full" id="att-chart-header-ad-url">
          {sourceId === 'google-ads' && (
            <div className=" absolute top-0 left-0">
              <Tooltip
                content={`The tracking settings found at the campaign, adgroup, & ad level of your Google ads. Displayed as {tracking template} | {final url suffix}.`}
                dismissOnMouseOut
              >
                <span>
                  <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                </span>
              </Tooltip>
            </div>
          )}
          <span>Ad URL</span>
        </div>
      );
    },
    Value: (d, metadata) => {
      const { wrapTableLinesMode } = metadata || {};
      if (d.id === UNMATCHED_ROWS_ID) {
        return <></>;
      }
      return (
        <div
          className={`flex items-center gap-4 font-medium w-full h-full ${entityRowBackground(
            d.entity!,
          )}`}
        >
          <span className={`p-2 ${wrapTableLinesMode ? '' : ellipsisText}`}>{d.urlParams}</span>
        </div>
      );
    },
  },
];

const isExtanded = (serviceId: string, entity: string): boolean => {
  const service = allServices[serviceId];
  if (!service || !service.maxPixelLevels) return true;

  switch (entity) {
    case 'campaign':
      return service.maxPixelLevels > 1;
    case 'adset':
      return service.maxPixelLevels > 2;
    default:
      return true;
  }
  return true;
};

const warningTooltips: Partial<Record<MetricsKeys, string>> = {};

// ['pixelCogs', 'pixelNcCogs', 'pixelProfit'].forEach((metric) => {
//   warningTooltips[metric] =
//     "Due to an internal update, Pixel COGS and Profit data may be inaccurate from October 7th - 14th. We're actively working to correct the data for this period.";
// });

const pixelColumns: PixelColumn[] = Object.values(metrics)
  .filter((m) => !m.hideInPixel)
  .map<PixelColumn>((m) => ({
    key: m.key,
    name: m.label,
    index: m.pixelIndex || Number.MAX_VALUE,
    dataType: 'text',
    isDefault: m.showInPixelByDefault,
    sortable: true,
    showInSources: m.showInServices,
    dependOnActiveIntegrations: m.dependOnActiveIntegrations,
    Heading: (metadata) => {
      const { sourceCategory, sourceId, sources } = metadata || {};
      const ChannelIcon = !m.dependOnActiveIntegrations ? allServices[sourceId!]?.icon : null;

      let channelIcons: React.FC<{ small: boolean }>[] = [];

      if (m.dependOnActiveIntegrations) {
        channelIcons = m.dependOnActiveIntegrations
          ?.map((sourceId) => allServices[sourceId]?.icon)
          .filter((x) => x) as React.FC<{ small: boolean }>[];
      }

      return (
        <div
          className="relative flex items-center gap-4 font-medium justify-center w-full"
          id={`att-chart-header-pixel-${m.key}`}
        >
          {sourceId === 'all' || (sourceCategory === 'all' && sourceId === ALL_SOURCES_ID) ? (
            // When "all" is selected, show a single icon:
            m.type === 'pixel' ? (
              <span className="flex items-center gap-4">
                <Icon name="tw-blue-logo" />
                <Tooltip content={m.Tip({ sourceId: '' })} dismissOnMouseOut>
                  <span>{m.shortLabel}</span>
                </Tooltip>
              </span>
            ) : (
              <span className="flex items-center gap-4">
                <Tooltip content={m.Tip({ sourceId: '' })} dismissOnMouseOut>
                  <span>{m.shortLabel}</span>
                </Tooltip>
              </span>
            )
          ) : (
            // Otherwise, use your original logic:
            <span className="flex items-center 10 gap-4 ">
              {m.type === 'pixel' && <Icon name="tw-blue-logo" />}
              {m.type === 'ads' && sourceCategory !== 'all' && ChannelIcon && (
                <ChannelIcon small={true} />
              )}
              <Tooltip content={m.Tip({ sourceId })} dismissOnMouseOut>
                <span>{m.shortLabel}</span>
              </Tooltip>
            </span>
          )}

          <span className="absolute left-0 top-0">
            <Tooltip
              content={m.Tip({ sourceId: sourceId === 'all' ? '' : sourceId })}
              dismissOnMouseOut
            ></Tooltip>

            {Object.keys(warningTooltips).includes(m.key) && (
              <Tooltip content={warningTooltips[m.key]}>
                <span>
                  <InfoMinor width={14} height={14} className="opacity-50 !fill-gray-400" />
                </span>
              </Tooltip>
            )}
          </span>
        </div>
      );
    },
    Value: (d, metadata) => {
      const { entity } = d;
      const {
        currency = 'USD',
        showComparisons,
        loadingAttributionComparisons,
        activeModel,
        sourceId,
      } = metadata || {};

      const metricValue = d[m.key] ?? d?.metrics?.[m.key];
      const valueStr =
        m?.key === 'adsInventory' ? (
          <></>
        ) : (
          formatNumber(metricValue, {
            style: m.format,
            currency,
            minimumFractionDigits:
              typeof m.minimumFractionDigits !== 'undefined' ? m.minimumFractionDigits : m.toFixed,
            maximumFractionDigits: m.toFixed,
          })
        );
      const prevPeriodItem = d.comparisons;
      const prevPeriodItemValue = prevPeriodItem?.[m.key];

      const valueColor =
        m.key !== 'pixelProfit' && m.key !== 'pixelCvDelta'
          ? 'text-inherit'
          : metricValue < 0
            ? 'text-negative'
            : metricValue > 0
              ? 'text-green'
              : 'text-inherit';

      const prevValueStr = (
        <MetricComparison
          isLoading={!!loadingAttributionComparisons}
          metric={m}
          value={metricValue}
          prevValue={prevPeriodItemValue}
          currency={currency}
        />
      );

      let res = <></>;

      if (d.adsetId === 'loading_row_not_real' || d.adId === 'loading_row_not_real') {
        res = (
          <div className="w-32">
            <SkeletonDisplayText size="small" />
          </div>
        );
      } else if (m.key === 'bidAmount') {
        res = (
          <div className={`h-full pt-1 w-52 min-w-full ${entityRowBackground(d.entity!)}`}>
            <UpdatableBidAmount attribution={d} metadata={metadata} />
          </div>
        );
      } else if (!metricValue) {
        res = <p className="text-center flex-auto">-</p>;
      } else if (m.key === 'subscriptionQuantity') {
        res = (
          <div>
            <SubscriptionsButton
              item={d}
              metricKey={m.key}
              showSubscriptionsButton={activeModel !== 'ppsViews'}
            />
          </div>
        );
      } else if (
        [
          'pixelPurchases',
          'pixelConversionValue',
          'pixelRoas',
          'pixelNcPurchases',
          'pixelNcRoas',
        ].includes(m.key)
      ) {
        res = (
          <div>
            <OrdersButton
              item={d}
              metricKey={m.key}
              showRoasButton={
                (m.key === 'pixelRoas' || m.key === 'pixelNcRoas') && sourceId !== AFFLUENCER
              } //temp, to do
              showOrdersButton={activeModel !== 'ppsViews'}
            />
          </div>
        );
      } else {
        res = <div className={valueColor}>{valueStr}</div>;
      }

      if (d.id === UNMATCHED_ROWS_ID && sourceId !== AFFLUENCER) {
        return <></>;
      }

      return d.adsetId === 'no_data' || d.adId === 'no_data' ? (
        <p className="text-center">-</p>
      ) : (
        <div
          className={`px-4 py-2 flex items-center h-full ${entityRowBackground(entity!)} ${
            m.key === 'pixelRoas' || m.key === 'pixelNcRoas'
              ? 'triple-roas-row py-2 min-h-[40px]'
              : ''
          }`}
        >
          <div className="flex gap-4 items-center w-full justify-center">
            <span>{res}</span>
            {showComparisons && m.key !== 'adsInventory' && <span>{prevValueStr}</span>}
          </div>
        </div>
      );
    },
    Total: (totals, metadata) => {
      if (m.key === 'adsInventory') return <></>;
      const {
        currency = 'USD',
        mainDatePickerSelectionRange,
        loadingAttributionComparisons,
        filteredRows,
        activeModel,
        sourceId,
        showComparisons,
      } = metadata || {};
      let value = m?.calculateSum?.(filteredRows || []) || 0;
      let comparisons = filteredRows?.map((x) => x?.comparisons).filter((x) => x);
      let prevPeriodItemValue = m?.calculateSum?.(comparisons || []) || 0;

      const prevValueStr = (
        <MetricComparison
          isLoading={!!loadingAttributionComparisons}
          metric={m}
          value={value}
          prevValue={prevPeriodItemValue}
          currency={currency}
        />
      );

      let val = (
        <>
          {formatNumber(value, {
            currency,
            style: m.format,
            minimumFractionDigits:
              typeof m.minimumFractionDigits !== 'undefined' ? m.minimumFractionDigits : m.toFixed,
            maximumFractionDigits: m.toFixed,
          })}
        </>
      );

      const valueColor =
        (m.key !== 'pixelProfit' && m.key !== 'pixelCvDelta') || !value
          ? 'text-inherit'
          : value < 0
            ? 'text-negative'
            : value > 0
              ? 'text-green'
              : 'text-inherit';

      if (
        sourceId === ALL_SOURCES_ID &&
        (activeModel === 'lastPlatformClick' || activeModel === 'lastPlatformClick-v2') &&
        m.type === 'pixel' &&
        !m.hideTotalDisclaimer
      ) {
        val = (
          <Tooltip
            content={`With TA, the last click from each ad platform is assigned credit.
            Therefore, the ${m.shortLabel} credit can be duplicated and may result in the bottom-line appearing higher than external resources like Shopify.
            Try Total Impact or Linear models for a closer bottom-line matchup.`}
          >
            <span className="flex items-center gap-2">
              <span className="flex">
                <InfoMinor width={14} height={14} className="opacity-50 fill-gray-400" />
              </span>
              <span className="flex">{val}</span>
            </span>
          </Tooltip>
        );
      }
      return (
        <div className={'px-4 py-2 flex items-center justify-center gap-4 ' + valueColor}>
          <span>{val}</span>
          {showComparisons && sourceId !== 'all' && (
            <Tooltip content="For comparison data, please visit the attribution>all page">
              <span>
                <InfoMinor width={14} height={14} className="opacity-70 fill-gray-400" />
              </span>
            </Tooltip>
          )}
          {showComparisons && sourceId === 'all' && <span>{prevValueStr}</span>}
        </div>
      );
    },
  }));

const affluencerColumns: PixelColumn[] = [
  {
    key: 'discount_code',
    name: 'Discount Code',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    showInSources: [AFFLUENCER],
    isForSpecificChannel: true,
    Heading: () => (
      <div
        className="flex items-center gap-4 font-medium justify-center w-full"
        id={`att-chart-header-pixel-url`}
      >
        {' '}
        <InfluencerIcon width={18} height={18} />
        {'Discount code'}
      </div>
    ),
    Value: (influencer) => {
      return (
        <div className="flex items-center justify-center">
          <p style={{ wordBreak: 'break-word' }}>{influencer.discount_code}</p>
        </div>
      );
    },
  },
  {
    key: 'url',
    name: 'URL',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isForSpecificChannel: true,
    showInSources: [AFFLUENCER],
    Heading: () => (
      <div
        className="flex items-center gap-4 font-medium justify-center w-full"
        id={`att-chart-header-pixel-url`}
      >
        <span>
          <InfluencerIcon width={18} height={18} />
        </span>
        <span>Links</span>
      </div>
    ),
    Value: (influencer) => {
      const { copiedToClipboard, toggleCopiedToClipboard, setCopiedToClipboard } =
        useContext(InfluencersContext);
      if (influencer.links?.length) {
        return (
          <div className="">
            {(influencer.links as links[])?.map((link, i) => {
              return (
                <div style={{ marginLeft: '10px' }}>
                  <div className="flex gap-5 align-middle items-center">
                    <Title size={'medium'} style={{ whiteSpace: 'nowrap' }}>
                      {link.campaign || 'Default'} | {link.source}
                    </Title>
                    <TwTooltip label={copiedToClipboard ? 'Copied!' : 'Copy to Clipboard'}>
                      <div className="flex items-center justify-center">
                        <LinkIcon
                          width={14}
                          height={14}
                          className="cursor-pointer text-light-primary"
                          onMouseLeave={() => setCopiedToClipboard!(false)}
                          onClick={() => {
                            copyToClipboard(link.link);
                            toggleCopiedToClipboard!();
                          }}
                        ></LinkIcon>
                      </div>
                    </TwTooltip>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
      return <></>;
    },
  },
  {
    key: 'affluencerSpend',
    name: 'Affluencer Spend',
    sortable: false,
    showInSources: [AFFLUENCER],
    isForSpecificChannel: true,
    dataType: 'numeric',
    isDefault: true,
    Heading: () => (
      <div
        className="flex items-center gap-4 font-medium justify-center w-full"
        id={`att-chart-header-pixel-spend`}
      >
        {' '}
        <InfluencerIcon width={18} height={18} />
        Spend
      </div>
    ),
    Value: (influencer) => {
      const { currency } = useContext(InfluencersContext);
      const spend = influencer.metrics
        ? Number(influencer.metrics?.['spend'])
        : influencer?.spend || 0;
      const valueStr = formatNumber(spend, {
        style: 'decimal',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      return <div className="flex items-center justify-center">{valueStr}</div>;
    },
    Total: (totals, metadata) => {
      const { currency = 'USD', filteredRows } = metadata || {};
      const m = metrics.spend;
      let value = m?.calculateSum?.(filteredRows || []) || 0;
      let val = (
        <>
          {formatNumber(value, {
            currency,
            style: m?.format || 'decimal',
            minimumFractionDigits:
              typeof m?.minimumFractionDigits !== 'undefined'
                ? m.minimumFractionDigits
                : m?.toFixed,
            maximumFractionDigits: m?.toFixed,
          })}
        </>
      );
      return <div className={'px-4 py-2 flex items-center justify-center '}>{val}</div>;
    },
  },
];

const mmmColumns: PixelColumn[] = [
  {
    key: 'suggestedBudget',
    name: 'MMM Suggested Daily Spend',
    sortable: false,
    dataType: 'numeric',
    isDefault: false,
    dependsOnFeatureFlag: FeatureFlag.MMM_FF,
    Heading: () => {
      return (
        <Tooltip content="Average daily spend from our MMM model, to be used through this Sunday">
          <div className="flex items-center gap-2">MMM Suggested Daily Spend</div>
        </Tooltip>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.suggestedBudget ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },

  {
    key: 'nciRoas',
    name: 'MMM Marginal MER',
    sortable: false,
    dataType: 'numeric',
    isDefault: false,
    dependsOnFeatureFlag: FeatureFlag.MMM_FF,
    Heading: () => {
      return (
        <Tooltip content="New Customer incremental ROAS measures how much additional revenue is generated per dollar of ad spend beyond what would have occurred otherwise.">
          <div className="flex items-center gap-2">MMM Marginal MER</div>
        </Tooltip>
      );
    },
    Value: (item) => {
      return (
        <div>
          {formatNumber(item.nciRoas ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
        </div>
      );
    },
  },
];

const $aiColumns = $derived<PixelColumn[]>((get) => {
  const aiColumns = get($mergedAiColumns).columns;
  return aiColumns.map<PixelColumn>((column) => {
    return {
      key: column.key,
      name: column.label,
      sortable: true,
      dataType: column.valueType === 'number' ? 'numeric' : 'text',
      isDefault: false,
      dependsOnFeatureFlag: FeatureFlag.AGENT_SUPPORT_FF,
      Heading: (metadata) => {
        return <AiColumnHeader column={column} serviceId={metadata?.sourceId as ServicesIds} />;
      },
      Value: (item) => {
        if (item.adsetId === 'loading_row_not_real' || item.adId === 'loading_row_not_real') {
          return (
            <div className="flex gap-4 items-center w-full justify-center">
              <div className="w-52 justify-center">
                <SkeletonDisplayText size="small" />
              </div>
            </div>
          );
        }
        return (
          <div className={`${entityRowBackground(item.entity!)} h-full`}>
            <AiColumnCell column={column} item={item} />
          </div>
        );
      },
    };
  });
});

const experimentColumns: PixelColumn[] = [
  {
    key: 'experiment',
    name: 'Experiment',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'text',
    sortable: false,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: true,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip content="Run a Conversion Lift Test on Meta" dismissOnMouseOut>
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>Experiment</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      const { entity } = item;
      const experimentButton = entity === 'campaign' || entity === 'channel';
      const { setData, experimentsData, sourceId, filteredRows } = metadata || {};
      const campaignsList =
        filteredRows
          ?.filter(
            (campaign) =>
              campaign.status === 'ACTIVE' &&
              experimentsData?.[campaign.campaignId!]?.is_experiment_enabled,
          )
          .map((item) => ({ label: item.campaignName || '', value: item.campaignId || '' })) || [];
      return (
        <div className="flex items-center justify-center">
          {experimentButton && (
            <ExperimentButton
              item={item}
              campaignsList={campaignsList}
              setData={setData}
              sourceId={sourceId!}
            />
          )}
        </div>
      );
    },
  },
  {
    key: 'ad_study_name',
    name: 'Ad Study Name',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'text',
    sortable: false,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    Heading: () => (
      <div className="flex items-center w-full justify-center py-1">Ad Study Name</div>
    ),
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          <p style={{ wordBreak: 'break-word' }}>{item.ad_study_name}</p>
        </div>
      );
    },
  },
  {
    key: 'ad_study_start_date',
    name: 'Ad Study Start Date',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'text',
    sortable: false,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    Heading: () => (
      <div className="flex items-center w-full justify-center py-1">Ad Study Start Date</div>
    ),
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          <p style={{ wordBreak: 'break-word' }}>
            {item.ad_study_start_date &&
              new Date(item.ad_study_start_date).toISOString().split('T')[0]}
          </p>
        </div>
      );
    },
  },
  {
    key: 'ad_study_end_date',
    name: 'Ad Study End Date',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'text',
    sortable: false,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    Heading: () => (
      <div className="flex items-center w-full justify-center py-1">Ad Study End Date</div>
    ),
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          <p style={{ wordBreak: 'break-word' }}>
            {item.ad_study_end_date && new Date(item.ad_study_end_date).toISOString().split('T')[0]}
          </p>
        </div>
      );
    },
  },
  {
    key: 'experiment_spend',
    name: 'Ad Study Spend',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => (
      <div className="flex items-center w-full justify-center py-1">Ad Study Spend</div>
    ),
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_spend ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_i_roas',
    name: 'Incremental ROAS',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Incremental Return on Ad Spend - measures the additional revenue generated for each dollar spent on advertising."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iROAS</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_i_roas ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_i_roas_lower_bound',
    name: 'Incremental ROAS Lower Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Lower bound of the confidence interval for Incremental Return on Ad Spend."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iROAS Lower</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_i_roas_lower_bound ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_i_roas_upper_bound',
    name: 'Incremental ROAS Upper Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Upper bound of the confidence interval for Incremental Return on Ad Spend."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iROAS Upper</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_i_roas_upper_bound ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_revenue',
    name: 'Incremental Revenue',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Additional revenue generated attributable to the advertising campaign."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iRevenue</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_revenue ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_revenue_lower_bound',
    name: 'Incremental Revenue Lower Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    sortable: true,
    isDefault: false,
    dataType: 'numeric',
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Lower bound of the confidence interval for incremental revenue."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iRevenue Lower</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_revenue_lower_bound ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_revenue_upper_bound',
    name: 'Incremental Revenue Upper Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Upper bound of the confidence interval for incremental revenue."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iRevenue Upper</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_revenue_upper_bound ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_revenue_confidence_percent',
    name: 'Incremental Revenue Confidence',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Confidence level of the incremental revenue estimation."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iRevenue Confidence</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_revenue_confidence_percent ?? 0, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_revenue_share_percent',
    name: 'Incremental Revenue Share',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Measures the proportion of total revenue that is considered incremental—i.e., revenue that is directly attributed to advertising efforts rather than organic or baseline sales."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iRevenue Share</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_revenue_share_percent ?? 0, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_cpic',
    name: 'Cost Per Incremental Conversion',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Cost Per Incremental Conversion - the average cost required to generate one additional conversion through advertising efforts."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>CPiC</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_cpic ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_cpic_lower_bound',
    name: 'Cost Per Incremental Conversion Lower Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Lower bound of the confidence interval for Cost Per Incremental Conversion."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>CPiC Lower</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_cpic_lower_bound ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_cpic_upper_bound',
    name: 'Cost Per Incremental Conversion Upper Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Upper bound of the confidence interval for Cost Per Incremental Conversion."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>CPiC Upper</span>
        </div>
      );
    },
    Value: (item, metadata) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_cpic_upper_bound ?? 0, {
            style: 'currency',
            currency: metadata?.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_conversions',
    name: 'Incremental Conversions',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Additional conversions generated attributable to the advertising campaign."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iConversions</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_conversions ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_conversions_lower_bound',
    name: 'Incremental Conversions Lower Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Lower bound of the confidence interval for incremental conversions."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iConversions Lower</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_conversions_lower_bound ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_conversions_upper_bound',
    name: 'Incremental Conversions Upper Bound',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Upper bound of the confidence interval for incremental conversions."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iConversions Upper</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_conversions_upper_bound ?? 0, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_conversions_confidence_percent',
    name: 'Incremental Conversions Confidence',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Confidence level of the incremental conversions estimation."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iConversions Confidence</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_conversions_confidence_percent ?? 0, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    key: 'experiment_incremental_conversions_share_percent',
    name: 'Incremental Conversions Share',
    dependsOnFeatureFlag: FeatureFlag.PIXEL_EXPERIMENTS_FF,
    dataType: 'numeric',
    sortable: true,
    isDefault: false,
    showInSources: ['facebook-ads'],
    excludeFromAllLevel: false,
    isFixed: false,
    Heading: () => {
      return (
        <div className="flex items-center gap-4 font-medium w-full justify-center">
          <div className=" absolute top-0 left-0">
            <Tooltip
              content="Measures the proportion of total conversions that is considered incremental—i.e., conversions that are directly attributed to advertising efforts rather than organic or baseline conversions."
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </div>
          <span>iConversions Share</span>
        </div>
      );
    },
    Value: (item) => {
      return (
        <div className="flex items-center justify-center">
          {formatNumber(item.experiment_incremental_conversions_share_percent ?? 0, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
];

export const customEventsColumns = (events) => {
  return (events || [])
    .map((customEvent: any) => {
      return (
        customEvent?.fields?.some((field) => field?.name?.toLowerCase() === 'value')
          ? [true, false]
          : [false]
      ).map((isConversionValue) => {
        const metricKey = `${customEvent.eventName}_${isConversionValue ? 'conversion_value' : 'conversion'}`;
        const name = `${customEvent.eventName} ${isConversionValue ? 'conversion value' : 'conversion'}`;
        return {
          id: metricKey,
          metricId: metricKey,
          key: metricKey,
          name,
          dataType: 'numeric',
          sortable: true,
          isDefault: false,
          Heading: () => (
            <div className="flex items-center gap-4 font-medium justify-center w-full">
              <span>{name}</span>
              <span className="absolute left-0 top-0">
                {customEvent.description && (
                  <Tooltip content={customEvent.description}>
                    <span>
                      <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                    </span>
                  </Tooltip>
                )}
              </span>
            </div>
          ),
          Value: (row, metadata) => {
            const rowData = { ...row, ...(row.metrics || {}) };
            const { currency = 'USD', loadingAttributionComparisons, showComparisons } = metadata;
            const value = rowData[metricKey];
            const prevPeriod = rowData.comparisons || {};
            const prevValue = prevPeriod[metricKey];
            const formatedValue = formatNumber(Number(value) || 0, {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            });
            return (
              <div className="px-4 py-2 flex items-center h-full attribution-table-campaign-row">
                <div className="flex gap-4 items-center justify-center w-full">
                  <ConversionsButton
                    item={row}
                    metricKey={metricKey}
                    showConversionsButton={true}
                    format={isConversionValue ? 'currency' : 'decimal'}
                    conversionName={customEvent.eventName}
                  />
                </div>
              </div>
            );
          },
          Total: (totals, metadata) => {
            const { currency } = metadata;
            const totalRowsData = (totals || []).map((row) => ({
              ...row,
              ...(row.metrics || {}),
            }));
            const sumValue = totalRowsData.reduce((acc, curr) => acc + (curr[metricKey] || 0), 0);

            const formatedValue = formatNumber(Number(sumValue) || 0, {
              style: isConversionValue ? 'currency' : 'decimal',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });

            return (
              <div className={'px-4 py-2 flex items-center justify-center'}>{formatedValue}</div>
            );
          },
        };
      });
    })
    .flat();
};
const allPixelColumns = [
  ...summaryPixelWidgetColumns,
  ...pixelColumns,
  ...affluencerColumns,
  ...mmmColumns,
  ...experimentColumns,
  // ...aiColumns,
];

export const $allPixelColumns = $derived<PixelColumn[]>((get) => {
  const aiColumns = get($aiColumns);
  const ffComputer = get($ffStore);
  const filteredPixelColumns = allPixelColumns.filter((c) => {
    if (c.dependsOnFeatureFlag) {
      const { shouldNotBeSeen } = ffComputer.getConfigById(c.dependsOnFeatureFlag);
      return !shouldNotBeSeen;
    }

    return true;
  });
  return [...filteredPixelColumns, ...aiColumns];
});

export default function useAllPixelColumns() {
  return useStoreValue($allPixelColumns);
}

export const $defaultPresets = $derived<savedPreset[]>((get) => {
  const allPixelColumnsDict = get($allPixelColumns).reduce<Record<PixelColumnsKeys, PixelColumn>>(
    (acc, column) => {
      acc[column.key] = column;
      return acc;
    },
    {} as Record<PixelColumnsKeys, PixelColumn>,
  );
  const defaultPresets: savedPreset[] = [
    {
      name: 'Fast',
      description: 'View your most important metrics with high performance',
      id: 'fast',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.ai_recommendation,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.roas,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelCpa,
        allPixelColumnsDict.pixelNcCpa,
        allPixelColumnsDict.cpm,
        allPixelColumnsDict.ctr,
        allPixelColumnsDict.impressions,
        allPixelColumnsDict.clicks,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelConversionValue,
        allPixelColumnsDict.pixelNcConversionValue,
      ],
    },
    {
      name: 'Default',
      description: 'Our default recommended preset',
      id: 'default',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.roas,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.purchases,
        allPixelColumnsDict.pixelConversionValue,
        allPixelColumnsDict.pixelNcConversionValue,
        allPixelColumnsDict.conversionValue,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelCpa,
        allPixelColumnsDict.pixelNcCpa,
        allPixelColumnsDict.pixelAov,
        allPixelColumnsDict.pixelProfit,
        allPixelColumnsDict.overlap,
        allPixelColumnsDict.pixelVisitors,
        allPixelColumnsDict.pixelUniqueVisitors,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelEmailSignup,
        allPixelColumnsDict.pixelUniqueAtc,
        allPixelColumnsDict.pixelUniqueCheckout,
        allPixelColumnsDict.pixelUniqueAddress,
        allPixelColumnsDict.pixelUniqueShipping,
        allPixelColumnsDict.pixelUniqueContact,
        allPixelColumnsDict.pixelUniquePayment,
        allPixelColumnsDict.pixelConversionRate,
        allPixelColumnsDict.pixelBounceRate,
      ],
    },
    {
      name: 'All Page (Pixel Only)',
      description: 'View common performance metrics across all paid, social & organic channels.',
      id: 'all-page-pixel-only',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.pixelConversionValue,
        allPixelColumnsDict.pixelNcConversionValue,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelCpa,
        allPixelColumnsDict.pixelNcCpa,
        allPixelColumnsDict.pixelAov,
        allPixelColumnsDict.pixelProfit,
        allPixelColumnsDict.overlap,
        allPixelColumnsDict.pixelVisitors,
        allPixelColumnsDict.pixelUniqueVisitors,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelEmailSignup,
        allPixelColumnsDict.pixelUniqueAtc,
        allPixelColumnsDict.pixelUniqueCheckout,
        allPixelColumnsDict.pixelUniqueAddress,
        allPixelColumnsDict.pixelUniqueShipping,
        allPixelColumnsDict.pixelUniqueContact,
        allPixelColumnsDict.pixelUniquePayment,
        allPixelColumnsDict.pixelConversionRate,
        allPixelColumnsDict.pixelBounceRate,
      ],
    },
    {
      name: 'Paid Performance',
      description: 'View common performance metrics, such as reach and cost per result.',
      id: 'paid-performance',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.budget,
        allPixelColumnsDict.bidAmount,
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.roas,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.purchases,
        allPixelColumnsDict.pixelConversionValue,
        allPixelColumnsDict.pixelNcConversionValue,
        allPixelColumnsDict.conversionValue,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelCpa,
        allPixelColumnsDict.pixelNcCpa,
        allPixelColumnsDict.pixelAov,
        allPixelColumnsDict.pixelProfit,
        allPixelColumnsDict.impressions,
        allPixelColumnsDict.outboundCtr,
        allPixelColumnsDict.ctr,
        allPixelColumnsDict.cpc,
        allPixelColumnsDict.pixelVisitors,
        allPixelColumnsDict.pixelUniqueVisitors,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelEmailSignup,
        allPixelColumnsDict.pixelUniqueAtc,
        allPixelColumnsDict.pixelUniqueCheckout,
        allPixelColumnsDict.pixelUniqueAddress,
        allPixelColumnsDict.pixelUniqueShipping,
        allPixelColumnsDict.pixelUniqueContact,
        allPixelColumnsDict.pixelUniquePayment,
        allPixelColumnsDict.pixelConversionRate,
        allPixelColumnsDict.pixelBounceRate,
      ],
    },
    {
      name: 'Paid Performance (Pixel Only)',
      description: 'View metrics about traffic and conversions from your ads.',
      id: 'paid-performance-pixel-only',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.pixelConversionValue,
        allPixelColumnsDict.pixelNcConversionValue,
        allPixelColumnsDict.conversionValue,
        allPixelColumnsDict.pixelAov,
        allPixelColumnsDict.pixelProfit,
        allPixelColumnsDict.pixelVisitors,
        allPixelColumnsDict.pixelUniqueVisitors,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelEmailSignup,
        allPixelColumnsDict.pixelUniqueAtc,
        allPixelColumnsDict.pixelUniqueCheckout,
        allPixelColumnsDict.pixelUniqueAddress,
        allPixelColumnsDict.pixelUniqueShipping,
        allPixelColumnsDict.pixelUniqueContact,
        allPixelColumnsDict.pixelUniquePayment,
        allPixelColumnsDict.pixelConversionRate,
        allPixelColumnsDict.pixelBounceRate,
      ],
    },
    {
      name: 'Bidding & Optimization',
      description: 'View details about bid strategy and the cost of delivering your ads.',
      id: 'facebook-bidding-optimization',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.budget,
        allPixelColumnsDict.bidAmount,
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelCpa,
        allPixelColumnsDict.pixelNcCpa,
        allPixelColumnsDict.impressions,
        allPixelColumnsDict.cpc,
        allPixelColumnsDict.outboundCtr,
      ],
    },
    {
      name: 'Traffic',
      description:
        'View metrics about site visitors and activities, such as add-to-carts and bounce rate.',
      id: 'traffic',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelVisitors,
        allPixelColumnsDict.pixelUniqueVisitors,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelEmailSignup,
        allPixelColumnsDict.pixelUniqueAtc,
        allPixelColumnsDict.pixelUniqueCheckout,
        allPixelColumnsDict.pixelUniqueAddress,
        allPixelColumnsDict.pixelUniqueShipping,
        allPixelColumnsDict.pixelUniqueContact,
        allPixelColumnsDict.pixelUniquePayment,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelConversionRate,
        allPixelColumnsDict.pixelBounceRate,
        allPixelColumnsDict.pixelAvgTimeOnSite,
        allPixelColumnsDict.pixelAvgPageViews,
      ],
    },
    {
      name: 'New Customers',
      description: 'View new customer metrics such as revenue, ROAS, and conversion rate.',
      id: 'new-customers',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelNewVisitors,
        allPixelColumnsDict.pixelCostPerNewVisitor,
        allPixelColumnsDict.pixelNewVisitorPerc,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.pixelNcConversionValue,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.pixelNcAov,
        allPixelColumnsDict.pixelNcPurchasesPerc,
        allPixelColumnsDict.pixelNcConversionRate,
      ],
    },
    {
      name: 'Purchases',
      description: 'View metrics about new and returning customer purchases.',
      id: 'purchases',
      isDefault: true,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.pixelPurchases,
        allPixelColumnsDict.pixelNcPurchases,
        allPixelColumnsDict.pixelNcPurchasesPerc,
        allPixelColumnsDict.purchases,
      ],
    },
    {
      name: 'Experiments',
      description: 'View metrics about Experiments data.',
      id: 'experiments',
      isDefault: true,
      columns: [
        allPixelColumnsDict.experiment,
        allPixelColumnsDict.ad_study_name,
        allPixelColumnsDict.ad_study_start_date,
        allPixelColumnsDict.ad_study_end_date,
        allPixelColumnsDict.experiment_spend,
        allPixelColumnsDict.experiment_i_roas,
        allPixelColumnsDict.experiment_i_roas_lower_bound,
        allPixelColumnsDict.experiment_i_roas_upper_bound,
        allPixelColumnsDict.experiment_incremental_revenue,
        allPixelColumnsDict.experiment_incremental_revenue_lower_bound,
        allPixelColumnsDict.experiment_incremental_revenue_upper_bound,
        allPixelColumnsDict.experiment_incremental_revenue_confidence_percent,
        allPixelColumnsDict.experiment_incremental_revenue_share_percent,
        allPixelColumnsDict.experiment_cpic,
        allPixelColumnsDict.experiment_cpic_lower_bound,
        allPixelColumnsDict.experiment_cpic_upper_bound,
        allPixelColumnsDict.experiment_incremental_conversions,
        allPixelColumnsDict.experiment_incremental_conversions_lower_bound,
        allPixelColumnsDict.experiment_incremental_conversions_upper_bound,
        allPixelColumnsDict.experiment_incremental_conversions_confidence_percent,
        allPixelColumnsDict.experiment_incremental_conversions_share_percent,
      ],
    },
    {
      name: 'AI Agents Columns',
      description: 'View metrics that generated by AI agents',
      id: 'ai-agents',
      isDefault: true,
      dependsOnFeatureFlag: FeatureFlag.AGENT_SUPPORT_FF,
      columns: [
        ...allPixelColumns.filter((c) => c.isFixed),
        allPixelColumnsDict.spend,
        allPixelColumnsDict.ai_recommendation,
        allPixelColumnsDict.pixelRoas,
        allPixelColumnsDict.pixelNcRoas,
        allPixelColumnsDict.budget,
        allPixelColumnsDict.cpm,
        allPixelColumnsDict.ctr,
      ],
    },
  ];
  const ffComputer = get($ffStore);
  const config = ffComputer.getConfigById(FeatureFlag.PIXEL_EXPERIMENTS_FF) || {};
  const shouldNotBeSeen = config.shouldNotBeSeen ?? false; // Default to false if undefined

  // TODO: make the following line better
  const filteredPresets = defaultPresets?.filter(
    (preset) => preset.id !== 'experiments' || !shouldNotBeSeen,
  );

  const filteredByFeatureFlag = filteredPresets.filter((p) => {
    if (p.dependsOnFeatureFlag) {
      const { shouldNotBeSeen } = ffComputer.getConfigById(p.dependsOnFeatureFlag);
      return !shouldNotBeSeen;
    }
    return true;
  });

  return (
    filteredByFeatureFlag?.map((p) => {
      return {
        ...p,
        columns: p.columns.filter((c) => !!c),
      };
    }) || []
  );
});

export const MetricComparison: React.FC<{
  metric: MetricClientData<any>;
  value: number;
  prevValue: number;
  currency: string;
  isLoading: boolean;
}> = ({ metric, value, prevValue, currency, isLoading }) => {
  if (isLoading) {
    return (
      <div className="w-6 h-6 flex items-center">
        <div className="flex-auto">
          <SkeletonBodyText lines={1} />
        </div>
      </div>
    );
  }

  if (!prevValue) {
    return <></>;
  }

  const percentageChange = !value || !prevValue ? null : calculateDelta(value, prevValue);
  const deltaIsPositive =
    (percentageChange && percentageChange > 0 && !metric.valueIsNegative) ||
    (percentageChange! < 0 && metric.valueIsNegative);

  return (
    <span className="text-secondary-text text-lg flex items-center gap-2">
      <span>
        {formatNumber(prevValue, {
          style: metric.format,
          currency,
          minimumFractionDigits:
            typeof metric.minimumFractionDigits !== 'undefined'
              ? metric.minimumFractionDigits
              : metric.toFixed,
          maximumFractionDigits: metric.toFixed,
        })}
      </span>
      <Arrow
        width={14}
        height={14}
        className={`arrow-icon ${deltaIsPositive ? '!text-green' : '!text-negative'} ${
          !percentageChange ? 'hidden' : percentageChange < 0 ? 'rotate-180' : ''
        }`}
      />
      {typeof percentageChange === 'number' && (
        <span className={`${deltaIsPositive ? '!text-green' : '!text-negative'}`}>
          {formatNumber(percentageChange, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).replace('-', '')}
        </span>
      )}
    </span>
  );
};
