import { useEffect } from 'react';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { analyticsEvents, bannerNotificationActions } from 'utils/dataLayer/constants';
import { NotificationType, BannerClickTargetModal } from '../types';
import { genericEventLogger } from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '$stores/willy/$subscription';

interface BannerTrackingProps {
  notification_id: string;
  notification_type: NotificationType;
  placement_url: string;
  score: number;
  header_text: string;
  body_text: string;
  cta: string;
  click_target_url?: string;
  click_target_modal?: BannerClickTargetModal;
  isFoundersDash: boolean;
  isSmall: boolean;
}

const trackBannerEvent = (
  action: (typeof bannerNotificationActions)[keyof typeof bannerNotificationActions],
  props: BannerTrackingProps,
) => {
  genericEventLogger(analyticsEvents.BANNER_NOTIFICATION, {
    action,
    ...props,
    device_type: props.isSmall ? 'Mobile' : 'Desktop',
  });
};

export const useBannerTracking = (banner, isVisible: boolean, shouldShow: boolean) => {
  const isSmall = useIsSmall();
  const isFreeShop = useStoreValue($isFreeShop);
  const bannerData = Object.values(banner || {})[0] as BannerTrackingProps;

  useEffect(() => {
    if (shouldShow && bannerData && isVisible) {
      trackBannerEvent(bannerNotificationActions.IMPRESSION, {
        notification_id: bannerData.notification_id,
        notification_type: bannerData.notification_type,
        placement_url: window.location.pathname,
        score: bannerData.score,
        header_text: bannerData.header_text,
        body_text: bannerData.body_text,
        cta: bannerData.cta,
        click_target_url: bannerData.click_target_url,
        click_target_modal: bannerData.click_target_modal,
        isFoundersDash: isFreeShop,
        isSmall,
      });
    }
  }, [shouldShow, bannerData, isVisible, isFreeShop, isSmall]);

  const handleClick = () => {
    trackBannerEvent(bannerNotificationActions.CLICK, {
      notification_id: bannerData.notification_id,
      notification_type: bannerData.notification_type,
      placement_url: window.location.pathname,
      score: bannerData.score,
      header_text: bannerData.header_text,
      body_text: bannerData.body_text,
      cta: bannerData.cta,
      click_target_url: bannerData.click_target_url,
      click_target_modal: bannerData.click_target_modal,
      isFoundersDash: isFreeShop,
      isSmall,
    });
  };

  return { handleClick };
};
