import { useActiveRoute } from '$stores/nav-config-stores';
import { Button, Flex, Menu, Switch, Text } from '@tw/ui-components';
import {
  applyGridDesktop,
  applyGridDesktopColumns,
  applySummaryFullWidth,
  applyTwoColumnMobile,
  summarySettingsButtonOnPress,
} from 'ducks/summary';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAppDispatch } from 'index';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import {
  analyticsEvents,
  genericEventLogger,
  summaryActions,
  summaryMessages,
} from 'utils/dataLayer';

type LayoutSectionProps = {
  closePopover?: () => void;
};
export const LayoutSection: FC<LayoutSectionProps> = ({ closePopover }) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const twoColumnMobile = useSelector((state: RootState) => state.twoColumnMobile);
  const gridDesktop = useSelector((state: RootState) => state.gridDesktop);
  const gridDesktopColumns = useSelector((state: RootState) => state.gridDesktopColumns);
  const summaryFullWidth = useSelector((state: RootState) => state.summaryFullWidth);
  const { activeRoute } = useActiveRoute();
  const isBenchmarks = activeRoute?.label === 'Benchmarks';
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();

  const onGridLayoutChange = (value: string) => {
    if (value === 'auto') {
      dispatch(applyGridDesktop(true));
      genericEventLogger(analyticsEvents.SUMMARY, {
        action: summaryActions.DESKTOP_GRID_CHANGE,
        shop: currentShopId,
        grid: gridDesktop ? 'grid' : 'flex',
        description: `Desktop Layout: Changed to ${gridDesktop ? 'grid' : 'flex'}`,
      });
    } else {
      dispatch(applyGridDesktopColumns(parseInt(value)));
      dispatch(applyGridDesktop(false));
      genericEventLogger(analyticsEvents.SUMMARY, {
        action: summaryActions.DESKTOP_GRID_CHANGE,
        shop: currentShopId,
        grid: gridDesktop ? 'grid' : 'flex',
        columns: gridDesktopColumns,
        description: `Desktop Layout: Changed to grid with ${value} columns`,
      });
    }
  };

  return (
    <>
      <Menu.Label tt="uppercase" fw={500} color="gray.5">
        Layout
      </Menu.Label>
      {!isBenchmarks && (
        <Menu.Item
          data-marketing-target="market-target-manage-sections"
          onClick={() => {
            dispatch(summarySettingsButtonOnPress());
            closePopover?.();
          }}
        >
          <Text color="named2.0" size="sm" weight={500}>
            Customize Sections
          </Text>
        </Menu.Item>
      )}
      {!isSmall && (
        <Menu.Item data-marketing-target="market-target-grid-layout">
          <Flex align="center" gap="sm">
            <Text fw={500} color="named2.0" size="sm">
              Grid Layout
            </Text>
            <Button.Group>
              {[
                { label: 'Auto', value: 'auto' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
              ].map((item) => {
                const value = gridDesktop ? `${gridDesktopColumns}` : 'auto';
                const selected = item.value === value;
                const color = selected ? 'named.7' : undefined;
                const variant = selected ? 'primary' : 'activator';
                return (
                  <Button
                    bg={color}
                    variant={variant}
                    key={item.value}
                    focusNone
                    onClick={() => onGridLayoutChange(item.value)}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </Button.Group>
          </Flex>
        </Menu.Item>
      )}
      {!isSmall && (
        <Menu.Item>
          <Switch
            checked={summaryFullWidth}
            onChange={() => {
              dispatch(applySummaryFullWidth(summaryFullWidth));
              genericEventLogger(analyticsEvents.SUMMARY, {
                action: summaryActions.DESKTOP_FULL_WIDTH_CHANGE,
                shop: currentShopId,
                isFullWidth: summaryFullWidth,
                columns: gridDesktopColumns,
                description: `Desktop Full Width: Changed to ${
                  summaryFullWidth ? 'full width' : 'max width (constrained)'
                }`,
              });
            }}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Full Width
              </Text>
            }
            labelPosition="left"
            size="sm"
            variant="short"
          />
        </Menu.Item>
      )}
      {isSmall && (
        <Menu.Item>
          <Switch
            checked={twoColumnMobile}
            onChange={() => {
              dispatch(applyTwoColumnMobile(twoColumnMobile));
              genericEventLogger(analyticsEvents.SUMMARY, {
                action: summaryActions.MOBILE_COLUMN_CHANGE,
                shop: currentShopId,
                columns: twoColumnMobile ? 2 : 1,
                description: summaryMessages.MOBILE_COLUMN_CHANGE(twoColumnMobile),
              });
            }}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Two Columns
              </Text>
            }
            labelPosition="left"
            size="sm"
            variant="short"
          />
        </Menu.Item>
      )}
    </>
  );
};
