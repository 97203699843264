import React, { FC } from 'react';
import { Text, Switch, ActionIcon } from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import { ChartData } from 'constants/attribution';

interface ChartSettingsBaseProps {
  showRightAxis: boolean;
  setShowRightAxis: (value: boolean) => void;
}

interface FullChartSettingsProps extends ChartSettingsBaseProps {
  onlyRightAxis?: false;
  chartData: ChartData;
  setChartData: (value: ChartData) => void;
}

interface RightAxisOnlyChartSettingsProps extends ChartSettingsBaseProps {
  onlyRightAxis: true;
}

type ChartSettingsProps = FullChartSettingsProps | RightAxisOnlyChartSettingsProps;

const ChartSettings: FC<ChartSettingsProps> = (props) => {
  const { showRightAxis, setShowRightAxis } = props;

  const handleRightAxisToggle = () => {
    if (!showRightAxis) {
      setShowRightAxis(true);
      if (!props.onlyRightAxis && props.chartData === 'periods') {
        props.setChartData('metrics');
      }
    } else {
      setShowRightAxis(false);
    }
  };

  const handlePreviousPeriodToggle = () => {
    if (!props.onlyRightAxis) {
      if (props.chartData !== 'periods') {
        props.setChartData('periods');
        if (showRightAxis) {
          setShowRightAxis(false);
        }
      } else {
        props.setChartData('metrics');
      }
    }
  };

  return (
    <DropDown
      options={[]}
      value={null}
      handleSelect={() => {}}
      size="xs"
      customActivator={<ActionIcon size="md" radius="sm" variant="activator" icon="three-dots" />}
      extraElements={[
        <div className="flex flex-col" key="chart-settings-extra">
          {/* Show the right axis */}
          <div style={{ marginBottom: '16px' }}>
            <div className="flex items-center justify-between">
              <Text fw={500} size="sm" c="gray.7">
                Show the right axis
              </Text>
              <Switch size="sm" checked={showRightAxis} onChange={handleRightAxisToggle} />
            </div>
            <Text c="gray.6" maw={200} mt={8} size="xs" lh="16px">
              The right axis is used for showing the secondary metric. Enable this option to compare
              different types of metrics like CTR (%) vs CPA ($). Use one axis to compare similar
              metrics (ROAS vs NC ROAS).
            </Text>
          </div>

          {!props.onlyRightAxis && (
            <>
              <div
                style={{
                  borderBottom: '1px solid var(--Gray-Light-Mode-200, #E5E7EB)',
                }}
              />
              <div style={{ marginTop: '16px' }}>
                <div className="flex items-center justify-between">
                  <Text fw={500} size="sm" c="gray.7">
                    Show previous period
                  </Text>
                  <Switch
                    size="sm"
                    checked={!props.onlyRightAxis && props.chartData === 'periods'}
                    onChange={handlePreviousPeriodToggle}
                  />
                </div>
                <Text c="gray.6" maw={200} mt={8} size="xs" lh="16px">
                  If you select the previous period in the date picker, the graph will display a
                  comparison.
                </Text>
              </div>
            </>
          )}
        </div>,
      ]}
    />
  );
};

export default ChartSettings;
