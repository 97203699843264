import ActivityPortalHeader from 'components/headers/ActivityPortalHeader';
import SummaryHeader from 'components/headers/SummaryHeader/SummaryHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';
import { META_SUMMARY_ROUTES } from '@tw/shared-types/module/frontend-config/constants';

export const SUMMARY_SECTION: NavSection = {
  title: 'Summary',
  icon: (props) => <NavSectionIcon src="summary" {...props} />,
  routes: [
    {
      url: '/summary',
      ...META_SUMMARY_ROUTES['/summary'],
      label: 'Summary',
      header: () => <SummaryHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.summaryLastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      ...META_SUMMARY_ROUTES['/activity-feed'],
      url: '/activity-feed',
      label: 'Activity Feed',
      header: () => <ActivityPortalHeader />,
    },
  ],
};
