/**
 * Switch component with Material UI styling using only TailwindCSS classes
 * @param {Object} props
 * @param {string} props.id - Unique ID for the switch
 * @param {string} props.entity - Related entity name
 * @param {string} props.channel - ID of the related service
 * @param {boolean} [props.checked=false] - Whether the switch is checked
 * @param {Function} [props.onChange] - Callback function when switch value changes
 */
export const ToggleStatusEmitterString = /*jsx*/ `
const ToggleStatusEmitter = ({ 
  id, 
  entity, 
  channel, 
  checked = true,
  disabled = false
}) => {
  const [isChecked, setIsChecked] = React.useState(checked);

  const onChange = React.useCallback((newValue) => {
  // Only pass primitive values, not the entire event object
  console.log('ToggleStatusEmitter', id, channel, entity);
    window.parent.postMessage({
      type: 'OPEN_ACTION_MODAL',
      action: 'TOGGLE_STATUS',
      data: { id, channel, entity, oldStatus: !newValue, newStatus: newValue }
    }, '*');
  }, [id, channel, entity]);
  
  // Update internal state when props change
  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  
  const handleToggle = () => {    
    const newValue = !isChecked;
    // setIsChecked(newValue);
    
    // Call the onChange handler if provided
    onChange(newValue);
  };
  
  // Material UI-inspired switch styles using Tailwind classes
  return (
    <div className="flex items-center gap-2">
      <button
        id={id}
        type="button"
        role="switch"
        aria-checked={isChecked}
        onClick={handleToggle}
        className={\`relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 \${
          disabled 
            ? 'opacity-50 cursor-not-allowed'
            : ''
        } \${
          isChecked 
            ? 'bg-blue-600' 
            : 'bg-gray-200'
        }\`}
      >
        <span 
          className={\`pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 \${
            isChecked ? 'translate-x-6' : 'translate-x-0'
          }\`} 
        />
      </button>
    </div>
  );
};`;
