import { useCallback, useMemo, useState } from 'react';
import { copyGlobalColumnToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import { useStoreValue } from '@tw/snipestate';
import { $shopAiColumns, $shopSequences } from '$stores/willy/$sequences';
import { $currentShopId } from '$stores/$shop';
import { sleep } from 'utils/sleep';
import { Column, ColumnName } from '../types/willyTypes';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';

type UseAiColumnProps = {
  column: Column<ColumnName> | null;
};

export function useAiColumn(props: UseAiColumnProps) {
  const { column } = props;
  const shopColumns = useStoreValue($shopAiColumns);
  const [copyLoading, setCopyLoading] = useState(false);
  const shopSequences = useStoreValue($shopSequences);
  const currentShopId = useStoreValue($currentShopId);

  const shopSequencesIds = useMemo(() => {
    return shopSequences.map((s) => s.id);
  }, [shopSequences]);

  const memoizedShopSequencesIds = useDeepCompareMemoize(shopSequencesIds);
  const memoizedShopColumns = useDeepCompareMemoize(shopColumns);

  const existInShop = useMemo(() => {
    if (!column) return undefined;
    const shopSequencesIdsDict = memoizedShopSequencesIds.reduce<Record<string, string>>(
      (acc, sequence) => {
        acc[sequence] = sequence;
        return acc;
      },
      {},
    );

    const columnEntityIsInShop = memoizedShopColumns.data?.find((c) => c.key === column?.key);

    if (!columnEntityIsInShop) {
      return false;
    }

    return (
      !column.sequences?.length ||
      column.sequences.every((s) => !s.sequenceId || !!shopSequencesIdsDict[s.sequenceId])
    );
  }, [memoizedShopSequencesIds, column, memoizedShopColumns]);

  const copyToShop = useCallback(
    async (update?: boolean) => {
      if (!currentShopId || !column) {
        return;
      }
      setCopyLoading(true);
      await copyGlobalColumnToShop(column);
      genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
        action: templateLibraryActions.ADD_COLUMN_TO_WORKSPACE,
        column_id: column?.key,
        column_name: column?.label,
      });
      setCopyLoading(false);
      toast.success(`Column ${update ? 'updated' : 'added'}`, {
        autoClose: 1000,
      });
    },
    [column, currentShopId],
  );

  const columnInShopHasLatestVersion = useMemo(() => {
    if (!column) {
      return;
    }
    const columnInShop = shopColumns.data?.find((c) => c.key === column?.key);
    if (!columnInShop) {
      return true;
    }
    if (!columnInShop?.version && !column?.version) {
      return true;
    }
    if (!columnInShop?.version) {
      return false;
    }
    return !!columnInShop?.version && columnInShop?.version >= column.version;
  }, [shopColumns, column]);

  const updateColumn = useCallback(async () => {
    if (columnInShopHasLatestVersion || !currentShopId || !column) {
      return;
    }
    setCopyLoading(true);
    await copyToShop(true);
    await sleep(300);
    setCopyLoading(false);
  }, [column, columnInShopHasLatestVersion, currentShopId, copyToShop]);

  return {
    copyLoading,
    onCopy: copyToShop,
    existInShop,
    needsUpdate: !!existInShop && !columnInShopHasLatestVersion,
    onUpdate: updateColumn,
  };
}
