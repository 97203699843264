import { $tables } from '$stores/willy/$tables';
import { useStoreValue } from '@tw/snipestate';
import { Button, Flex, Grow, Text } from '@tw/ui-components';
import { BqColumn, BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { createWillyMetricFromColumn, extractProvidersFromFilters } from '../../utils/willyUtils';
import { WillyMetric, WillyParameter } from '../../types/willyTypes';
import { ChooseTableSection } from './ChooseTableSection';
import { SelectWithSearchInput } from './SelectWithSearchInput';
import { generateShortDescription } from './ColumnTooltip';
import { AddMetricsSections } from './AddMetricsSections';
import { AddFiltersSection } from './AddFiltersSection';

type TableBuilderTabProps = {
  queryId?: string;
  builder: BuilderTable;
  setBuilder: Dispatch<SetStateAction<BuilderTable | undefined>>;
  updateEditedMetrics: (id: string, data: WillyMetric[]) => Promise<void>;
  editedMetrics: WillyMetric[];
};

const DefaultFiltersMetrics: WillyParameter[] = [
  { column: 'channel', operator: '=', value: '', visible: true },
  { column: 'platform', operator: '=', value: '', visible: true },
  { column: 'attribution_window', operator: '=', value: 'lifetime', visible: true },
  { column: 'model', operator: '=', value: 'Triple Attribution', visible: true },
];

export const TableBuilderTab: FC<TableBuilderTabProps> = ({
  queryId,
  builder,
  setBuilder,
  updateEditedMetrics,
  editedMetrics,
}) => {
  const tables = useStoreValue($tables);

  const currentTable = useMemo(() => {
    return tables.find((t) => t.id === builder?.table);
  }, [tables, builder]);

  const columns = useMemo(() => {
    return currentTable?.columns ?? [];
  }, [currentTable]);

  const columnsByType: WillyMetric[] = useMemo(() => {
    const cols = columns
      .map((c) => {
        return createWillyMetricFromColumn(c, currentTable);
      })
      .map((m) => ({
        ...m,
        active: builder?.columns.some((b) => b.columnId === m.key),
      }));

    return cols;
  }, [builder?.columns, columns, currentTable]);

  const defaultFilters: WillyParameter[] = useMemo(() => {
    return columns.reduce((acc, c) => {
      const filter = DefaultFiltersMetrics.find((f) => f.column === c.id);
      if (filter) {
        acc.push(filter);
      }
      return acc;
    }, [] as WillyParameter[]);
  }, [columns]);

  useEffect(() => {
    setBuilder((prev: BuilderTable | undefined) =>
      prev !== undefined
        ? {
            ...{ filters: defaultFilters },
            ...prev,
          }
        : undefined,
    );
  }, [defaultFilters, setBuilder]);

  const noAnyDimension = useMemo(() => {
    const apiColumns = builder?.columns
      .map((c) => {
        return columns.find((col) => col.id === c.columnId);
      })
      .filter((c) => c !== undefined) as BqColumn[];
    return apiColumns.every((c) => c.type === 'numeric' || c.type === 'formula');
  }, [builder?.columns, columns]);

  const onHideMetricChanged = useCallback(
    (id: string) => {
      updateEditedMetrics(
        queryId!,
        editedMetrics.map((m) => (m.key === id ? { ...m, hidden: !m.hidden } : m)),
      );
    },
    [editedMetrics, queryId, updateEditedMetrics],
  );

  const removeColumnFromMetrics = useCallback(
    (columnId: string) => {
      updateEditedMetrics(
        queryId!,
        editedMetrics.filter((m) => m.key !== columnId),
      );
    },
    [editedMetrics, queryId, updateEditedMetrics],
  );

  const createMetricFromColumnId = useCallback(
    (columnId: string) => {
      const services = extractProvidersFromFilters(builder.filters);
      const column = currentTable?.columns.find((c) => c.id === columnId);
      if (!column) {
        return;
      }
      return createWillyMetricFromColumn(column, currentTable, builder.columns.length, services);
    },
    [builder.columns.length, builder.filters, currentTable],
  );

  const addColumnToMetrics = useCallback(
    (columnId: string, needDimension?: boolean) => {
      const metric = createMetricFromColumnId(columnId);
      const newMetrics = [...editedMetrics];
      if (metric) {
        metric.active = true;
        newMetrics.push(metric);
      }

      if (needDimension && noAnyDimension) {
        const dateMetric = createMetricFromColumnId('event_date');
        if (dateMetric) {
          dateMetric.active = true;
          newMetrics.push(dateMetric);
        }
      }

      updateEditedMetrics(queryId!, newMetrics);
    },
    [createMetricFromColumnId, editedMetrics, noAnyDimension, updateEditedMetrics, queryId],
  );

  return (
    <Flex direction="column">
      <TableBuilderSection number={1}>
        <ChooseTableSection
          value={builder?.table}
          data={tables.map((t) => ({
            label: t.name,
            value: t.id,
            description: generateShortDescription(t.description),
            readmeUrl: t.readmeUrl,
          }))}
          onChange={(val) => {
            if (!val) {
              return;
            }
            setBuilder({
              ...builder!,
              table: val,
              filters: [],
              columns: [],
            });
            updateEditedMetrics(val, []);
          }}
        />
      </TableBuilderSection>
      <TableBuilderSection number={2}>
        <AddMetricsSections
          builder={builder}
          setBuilder={setBuilder}
          columnsAsMetrics={columnsByType}
          columns={columns}
          addColumnToMetrics={(columnId) => addColumnToMetrics(columnId, true)}
          removeColumnFromMetrics={removeColumnFromMetrics}
          onHideMetricChanged={onHideMetricChanged}
        />
      </TableBuilderSection>
      <TableBuilderSection number={3}>
        <AddFiltersSection
          builder={builder}
          setBuilder={setBuilder}
          columnsAsMetrics={columnsByType}
          columns={columns}
          currentTable={currentTable}
        />
      </TableBuilderSection>
    </Flex>
  );
};

type TableBuilderSectionProps = {
  number: number;
  children: React.ReactNode;
};
const TableBuilderSection: FC<TableBuilderSectionProps> = ({ number, children }) => {
  return (
    <Flex borderBottom="1px solid var(--mantine-color-gray-2)">
      <Flex
        direction="column"
        align="center"
        justify="center"
        w={40}
        borderRight="1px solid var(--mantine-color-gray-2)"
      >
        <Text weight={500} color="gray.5" size="lg">
          {number}
        </Text>
      </Flex>
      <Grow p="md">{children}</Grow>
    </Flex>
  );
};

type AddMetricPopoverProps = {
  metrics: WillyMetric[];
  onAdd: (metric: WillyMetric) => void;
  readmeUrl?: string;
  tableName?: string;
  offset?: number;
  disabled?: boolean;
};

export const AddMetricPopover: FC<AddMetricPopoverProps> = ({
  metrics,
  onAdd,
  readmeUrl,
  tableName,
  offset = 25,
  disabled,
}) => {
  return (
    <SelectWithSearchInput
      value={metrics.filter((m) => m.active).map((m) => m.key)}
      data={metrics.map((m) => ({
        label: m.name,
        value: m.key,
        description: m.description,
        readmeUrl: readmeUrl,
      }))}
      onChange={(val) => {
        const metric = metrics.find((m) => m.key === val);
        if (metric) {
          metric.active = !metric.active;
          onAdd(metric);
        }
      }}
      activator={(onClick) => (
        <div>
          <Button disabled={disabled} variant="white" size="xs" onClick={onClick}>
            Add
          </Button>
        </div>
      )}
      position="right-start"
      offset={offset}
      closeOnSelect={false}
      tableName={tableName}
    />
  );
};
