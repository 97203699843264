import { Text } from '@tw/ui-components';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState, Component } from 'react';
import { AgentCollection, WillyDataSequence } from '../types/willyTypes';
import { firestoreRef, toArray } from 'utils/DB';
import { genericEventLogger, analyticsEvents, sequencesActions } from 'utils/dataLayer';
import axiosInstance from 'utils/axiosInstance';
import { AgentLibraryCard, AgentLibraryCardSkeleton } from './AgentLibraryCard';
import { Mention, MentionsInputProps } from 'react-mentions';
import { $currentShopId } from '$stores/$shop';
import { ChatInput } from '../ChatInput';

export const AgentsLibrary = () => {
  const navigate = useNavigate();
  const inputRef = useRef<Component<MentionsInputProps, any, any>>(null);
  const currentShopId = $currentShopId.get();

  const [loadingCollections, setLoadingCollections] = useState(false);
  const [agentCollections, setAgentCollections] = useState<AgentCollection[]>([]);
  const [loadingVectorAgents, setLoadingVectorAgents] = useState(false);
  const [vectorAgents, setVectorAgents] = useState<WillyDataSequence[]>([]);
  const [loadingFeaturedAgents, setLoadingFeaturedAgents] = useState(false);
  const [featuredAgents, setFeaturedAgents] = useState<WillyDataSequence[]>([]);
  const [searchPrompt, setSearchPrompt] = useState<string>('');

  const getAgentCollections = useCallback(async () => {
    setLoadingCollections(true);
    const collections = await firestoreRef().collection('agent_collections').get();
    const asArray = toArray(collections).filter((collection) => !collection.hidden);
    setAgentCollections(asArray.sort((a, b) => a.order - b.order) as AgentCollection[]);
    setLoadingCollections(false);
  }, []);

  const getFeaturedAgents = useCallback(async () => {
    if (!currentShopId) return;

    try {
      setLoadingFeaturedAgents(true);
      const { data } = await axiosInstance.post(`/v2/sequences/collections/get-paginated-agents`, {
        collection: 'featured-agents',
        shopId: currentShopId,
      });

      setFeaturedAgents(data.agents ?? []);
      setLoadingFeaturedAgents(false);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setLoadingFeaturedAgents(false);
    }
  }, [currentShopId]);

  useEffect(() => {
    getAgentCollections();
    getFeaturedAgents();
    genericEventLogger(analyticsEvents.SEQUENCES, {
      action: sequencesActions.VIEW_AGENT_TEMPLATE_LIBRARY,
    });
  }, [getAgentCollections, getFeaturedAgents]);

  const getVectorAgents = useCallback(async () => {
    if (!searchPrompt.trim() || loadingVectorAgents) return;

    setLoadingVectorAgents(true);
    setVectorAgents([]);
    try {
      const { data } = await axiosInstance.post('/v2/willy/get-workflows-based-on-question', {
        question: searchPrompt.trim(),
      });

      setVectorAgents(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingVectorAgents(false);
    }
  }, [loadingVectorAgents, searchPrompt]);

  return (
    <div className="flex flex-col w-full z-10 items-center h-full">
      <div className="flex flex-col w-[95%]  py-12 gap-7">
        <div className="w-full flex flex-col mx-auto gap-7">
          <Text fw={600} fz={21}>
            Agent Collections
          </Text>

          <div className="flex flex-col flex-wrap md:grid grid-cols-2 2xl:grid-cols-4 gap-6 2xl:gap-10">
            {loadingCollections && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="relative flex">
                    <div className="rounded h-full w-full relative bg-center overflow-hidden bg-gray-100">
                      <div className="relative z-10 p-10 py-12 flex flex-col gap-6">
                        <div className="h-[24px] w-40 bg-gray-300 rounded"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {!loadingCollections &&
              agentCollections.map((folder) => {
                const bgUrl = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${folder.color}_collection_bubbles.png`;
                return (
                  <div className="relative flex" key={folder.id}>
                    {folder.comingSoon && (
                      <div className="absolute top-[-10px] right-[20px] rounded bg-blue-200 px-3 py-1 z-10">
                        <Text fw={500} fz={12} color="one.8">
                          Coming Soon
                        </Text>
                      </div>
                    )}
                    <div
                      className="rounded h-full w-full relative bg-center overflow-hidden bg-gray-100 cursor-pointer"
                      onClick={() => navigate(`/chat/agents/collections/${folder.id}`)}
                    >
                      <img
                        src={bgUrl}
                        alt={folder.name}
                        className="absolute inset-0 w-[110%] h-[110%] object-cover -top-[5%] -left-[5%] overflow-hidden"
                      />

                      <div className="relative z-10 p-10 flex flex-col gap-6">
                        <Text fw={700} fz={22} color="white">
                          {folder.name}
                        </Text>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col mx-auto w-full gap-5 pt-7">
            <Text fz={18} fw={600}>
              Ask Moby for an Agent
            </Text>
            <div className="w-full mb-2">
              <ChatInput
                value={searchPrompt}
                context="chatWithGenUi"
                inputRef={inputRef}
                willyLoading={false}
                isReadyForChat={!loadingVectorAgents}
                currentAnalyticsEvent=""
                setValue={setSearchPrompt}
                onSubmit={getVectorAgents}
                onStopChat={() => {}}
                toggleOpenFileDialog={() => {}}
                removeFile={() => {}}
                hideUpload
                placeholder="What're you looking for in an agent?"
              >
                <Mention data={[]} trigger="@" markup='@"__display__"' />
              </ChatInput>
            </div>
            <div className="grid sm:grid-cols-2 2xl:grid-cols-4 gap-6">
              {loadingVectorAgents && (
                <>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <AgentLibraryCardSkeleton key={index} />
                  ))}
                </>
              )}
              {!loadingVectorAgents &&
                vectorAgents.map((agent) => {
                  return <AgentLibraryCard key={agent.id} agent={agent} />;
                })}
            </div>
          </div>

          <div className="flex flex-col mx-auto w-full gap-5 pt-7">
            <Text fz={18} fw={600}>
              Featured Agents
            </Text>
            <div className="grid sm:grid-cols-2 2xl:grid-cols-4 gap-6">
              {loadingFeaturedAgents && (
                <>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <AgentLibraryCardSkeleton key={index} />
                  ))}
                </>
              )}
              {!loadingFeaturedAgents &&
                featuredAgents.slice(0, 4).map((agent) => {
                  return <AgentLibraryCard key={agent.id} agent={agent} />;
                })}
              {!loadingFeaturedAgents && featuredAgents.length === 0 && (
                <div className="col-span-4">
                  <Text>No featured agents found</Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
