import 'firebase/compat/firestore';
import './InfluencersPage.scss';

import amplitude from 'amplitude-js';
import AddEditInfluencer from 'components/Influencers/AddEditInfluencer/AddEditInfluencer';
import {
  influencerCreativePrefix,
  influencerProfilePicturePrefix,
  TW_INFLUENCER_COLUMNS,
} from 'components/Influencers/constants';
import { InfluencersContext } from 'components/Influencers/context';
import InfluencersChart from 'components/Influencers/InfluencersChart/InfluencersChart';
import InfluencersDashboard from 'components/Influencers/InfluencersDashboard/InfluencersDashboard';
import { columns } from 'components/Influencers/InfluencersTable/columns';
import { BaseColumn } from 'components/library/TWTable/types';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import { lightboxOpen } from 'ducks/actions';
import firebase from 'firebase/compat/app';
import { useAppDispatch } from 'index';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import {
  creativeType,
  DisplayMode,
  influencer,
  InfluencerAttributionRequest,
  INFLUENCERS_COLORS,
  InfluencersContextType,
  MAX_ACTIVE_INFLUENCERS,
  SelectableInfluencer,
} from 'types/Influencers';
import { MetricsKeys } from 'types/metrics';
import axiosInstance from 'utils/axiosInstance';
import { createDiscountCode, saveShopDiscountCode } from 'utils/Discounts';
import {
  createInfluencer,
  deleteCreative,
  emptyInfluencer,
  influencerEdit,
  updateCreatives,
  uploadCreative,
} from 'utils/influencers';

import { AccountConnection, Card, Collapsible, Page, SortDirection } from '@shopify/polaris';
import { InfluencerAttributionResponse } from '@tw/types';
import { influencerReducer } from './reducers';
import {
  AddAffluencerModalOpenChanged,
  AffluencerHubChartOpenChanged,
  AffluencerHubImportModalOpenChanged,
} from 'ducks/affluencerHub/actions';
import moment from '@tw/moment-cached/module/timezone';
import { connectToShopify } from 'utils/connectToShopify';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import useDebounce from 'utils/useDebounce';
import InfluencerImportModal from 'components/Influencers/InfluencersImportModal';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '$stores/$shop';
import LockedPageTemplate from 'pages/Attribution/LockedPageTemplate';
import firstImage from '../../components/attribution-new/assets/reports.svg';
import secondSectionImage from '../../components/attribution-new/assets/dropdown.png';
import quateImage from '../../components/attribution-new/assets/quote.png';
import logo from '../../components/attribution-new/assets/logo.png';

const firestore = firebase.firestore;

const getDefaultColumns = (): BaseColumn<any, any>[] => {
  let defaultColumns: BaseColumn<any, any>[] = [];
  const columnsFromLocalStorage = localStorage.getItem(TW_INFLUENCER_COLUMNS);
  if (columnsFromLocalStorage) {
    const columnIds: string[] = JSON.parse(columnsFromLocalStorage);
    defaultColumns = columnIds
      .map((x) => columns.find((column) => column.key === x)!)
      .filter((x) => x);
  } else {
    defaultColumns = columns.filter((x) => x.isDefault);
  }
  return defaultColumns;
};

const InfluencersPageComponent = () => {
  const [influencers, influencersDispatch] = useReducer(influencerReducer, []);
  const {
    addAffluencerHubModalOpen,
    affluencerHubChartOpen,
    affluencerHubImportModalOpen,
    freeSearch,
  } = useSelector((state: RootState) => state.affluencerHub);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [influencerToEdit, setInfluencerToEdit] = useState<influencer>(emptyInfluencer());
  const [selectedInfluencers, setSelectedInfluencers] = useState<SelectableInfluencer[]>([]);
  const [sortBy, setSortBy] = useState<MetricsKeys>('pixelPurchases');
  const [sortDirection, setSortDirection] = useState<SortDirection>('descending');

  const shopId = useSelector((state: RootState) => state.currentShopId);
  const { shopifyAccessToken } = useSelector((state: RootState) => state.shop);

  const shopifyScopes = useSelector((state: RootState) => state.shopifyScopesFromApi);

  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const activeCurrency = useStoreValue($currency);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const toggleCopiedToClipboard = useCallback(() => setCopiedToClipboard((active) => !active), []);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [profileUploadProgress, setProfileUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadError, setUploadError] = useState('');
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [loadedDiscountCodes, setDiscountCodes] = useState<Array<any>>([]);
  const [tableCreativeFilterBy, setTableCreativeFilterBy] = useState('all');
  const [loading, setLoading] = useState(false);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const mainDatePickerSelectionRange = useSelector(
    (state: any) => state.mainDatePickerSelectionRange,
  );
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalInfluencers, setTotalInfluencers] = useState<number>(0);

  const integrations = useSelector(shopIntegrations);
  const { attributionModel, dateModel, attributionWindow } = useSelector(
    (state: RootState) => state.attribution,
  );
  const [selectedColumns, setSelectedColumns] =
    useState<BaseColumn<any, any>[]>(getDefaultColumns());
  const [displayMode, setDisplayMode] = useState<DisplayMode>('table');

  const dispatch = useAppDispatch();

  const debouncedSearch = useDebounce(freeSearch, 500);

  const searchDiscountCodes = async (strSearch: string) => {
    let strLength = strSearch.length;
    let strFrontCode = strSearch.slice(0, strLength - 1);
    let strEndCode = strSearch.slice(strLength - 1, strSearch.length);

    const startCode = strSearch;
    const endCode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    const lowerCodeDocsPromise = firestore()
      .collection('shops')
      .doc(shopId)
      .collection('discount_codes')
      .where('id', '>=', startCode.toLowerCase())
      .where('id', '<', endCode.toLowerCase())
      .get();

    const upperCodeDocsPromise = firestore()
      .collection('shops')
      .doc(shopId)
      .collection('discount_codes')
      .where('id', '>=', startCode.toUpperCase())
      .where('id', '<', endCode.toUpperCase())
      .get();

    const [lowerCodeDocs, upperCodeDocs] = await Promise.all([
      lowerCodeDocsPromise,
      upperCodeDocsPromise,
    ]);
    let codes: Array<any> = [];
    codes =
      lowerCodeDocs.size > 0
        ? lowerCodeDocs.docs.map((doc) => {
            return { ...doc.data() };
          })
        : codes;
    codes =
      upperCodeDocs.size > 0
        ? upperCodeDocs.docs.map((doc) => {
            return { ...doc.data() };
          })
        : codes;

    if (!codes.length) {
      const url = `/v2/insights/filters/filter-options`;
      const request = {
        shopId,
        filterProperty: 'discount_code',
        term: strSearch,
      };
      const response = await axiosInstance.post<null, { data: { options: [] } }>(url, request);
      codes = response.data.options.map((c) => {
        return { code: c };
      });
    }
    let exactMatch = codes.find((c) => c.code === strSearch);
    if (!exactMatch && shopifyAccessToken) {
      const codeRes = await axiosInstance.post(`v2/shopify/shopify-discount-lookup`, {
        data: {
          discountCode: strSearch,
          shopDomain: shopId,
          shopData: { shopifyAccessToken: shopifyAccessToken, timezone: shopTimezone },
        },
      });
      if (codeRes.data && codeRes.data.priceRule) {
        const value = Math.ceil(Math.abs(codeRes.data.priceRule.value));
        if (!codes.find((c) => c.code === codeRes.data.code)) {
          codes = [...codes, { code: codeRes.data.code, amount: value, fromShopify: true }];
        }
      }
    }

    setDiscountCodes(codes);
    return codes;
  };

  const loadDefaultDiscountCodes = async () => {
    if (!loadedDiscountCodes.length) {
      const codeDocs = await firestore()
        .collection('shops')
        .doc(shopId)
        .collection('discount_codes')
        .limit(20)
        .get();

      const codes = codeDocs.docs.map((doc) => {
        return { ...doc.data() };
      });

      setDiscountCodes(codes);
    }
  };

  const createCode = async (influencer, newCodes) => {
    if (needsPermission()) {
      setDiscountCodeError('permission');
      return false;
    }

    if (newCodes.length) {
      for (const discountCode of newCodes) {
        // if (!discountCode || !discountCode.fromShopify) {
        if (!discountCode.code) {
          continue;
        }
        const value = discountCode.value || '0';
        const code = discountCode.code;
        const title = `Triple Whale - Influencer ${influencer.name || code}`;
        const data = {
          shopDomain: shopId,
          shopData: { shopifyAccessToken: shopifyAccessToken, timezone: shopTimezone },
          discountCode: code,
          price_rule: {
            title,
            value: `-${value}`,
            value_type: discountCode.value_type || 'percentage',
          },
        };
        try {
          setLoading(true);
          if (shopifyAccessToken) {
            const createdDiscountCode = await createDiscountCode(data);
            if (createdDiscountCode!.error) {
              setDiscountCodeError(createdDiscountCode!.error);
              return false;
            }
            amplitude.getInstance().logEvent('Affluencer Hub: Discount Code Created');
          }
        } catch (e) {
          setDiscountCodeError('There was an error creating the discount in shopify');
          setLoading(false);
          return false;
        }
        try {
          //save to fb collection
          await saveShopDiscountCode(
            {
              id: discountCode.code,
              code: discountCode.code,
              amount: value,
              version: 2,
              type: discountCode?.value_type || 'percentage',
              priceRuleId: discountCode.id || '',
            },
            shopId,
          );
          setLoading(false);
        } catch (e) {
          setDiscountCodeError('There was an error creating the discount');
          setLoading(false);
          return false;
        }
      }
    }
    return true;
  };

  const toggleActive = (influencer: SelectableInfluencer) => {
    influencersDispatch({ type: 'toggle_active', influencer: influencer });
  };

  const toggleSelected = (influencer: SelectableInfluencer) => {
    if (selectedInfluencers.length >= MAX_ACTIVE_INFLUENCERS) {
      return;
    }
    // wasn't selected before
    if (!influencer.selected && !affluencerHubChartOpen) {
      dispatch(AffluencerHubChartOpenChanged(true));
    }
    influencersDispatch({ type: 'toggle_select', influencer: influencer });
  };

  const editSubmit = async (influencer: SelectableInfluencer) => {
    setLoading(true);
    let success = true;
    let newCodes = (influencer.discountCodes || []).filter((dc) => dc.isNew);

    if (newCodes.length) {
      success = await createCode(influencer, newCodes);
    }
    if (success) {
      let editedInfluencer = await influencerEdit({ ...influencer }, shopId, activeCurrency);
      influencer = { ...influencer, ...editedInfluencer };
      resetModes();
    } else {
      setLoading(false);
    }
    influencersDispatch({ type: 'replace', influencer: influencer });
  };

  const addInfluencer = async (newInfluencer: SelectableInfluencer) => {
    const newCodes = (newInfluencer.discountCodes || []).filter((dc) => dc.isNew);

    setLoading(true);
    const files = newInfluencer.files;
    const profilePic = newInfluencer.profile_file;

    let success = true;
    let addedInfluencer = await createInfluencer(
      { ...newInfluencer },
      shopId,
      activeCurrency,
      influencers,
    );
    if (!addedInfluencer) {
      success = false;
    } else {
      newInfluencer = { ...newInfluencer, ...addedInfluencer };

      await handleCreativeUpload(newInfluencer, files);
      if (profilePic) {
        uploadProfilePhoto(newInfluencer, profilePic);
      }

      if (newCodes.length > 0) {
        success = await createCode(newInfluencer, newCodes);
        if (success) {
          await influencerEdit({ ...newInfluencer }, shopId, activeCurrency);
        }
      }
    }

    if (success) {
      influencersDispatch({
        type: 'add',
        influencers: [{ ...newInfluencer }],
      });
      resetModes();
    } else {
      setLoading(false);
    }
    return success;
  };

  const uploadProfilePhoto = async (influencer: SelectableInfluencer, file: File[]) => {
    setProfileUploadProgress((prevProgress) => ({ ...prevProgress, ...{ [file[0].name]: 1 } }));
    try {
      const fileData = await uploadCreative(
        file![0],
        `${influencerProfilePicturePrefix}${shopId}/${influencer.id}`,
        { shopId, influencerId: influencer.id },
        (progress, name) => {
          setProfileUploadProgress((prevProgress) => {
            return { ...prevProgress, ...{ [name]: progress } };
          });
        },
      );
      influencer.profile_photo = fileData?.data;

      editSubmit(influencer);
    } catch (e) {
      handleUploadException(e, file![0].name);
    }
  };

  const handleCreativeUpload = async (
    influencer: SelectableInfluencer,
    files: Array<File> | undefined,
  ) => {
    setUploadError('');
    const creatives = files;
    let promises: Array<Promise<any>> = [];

    if (creatives?.length) {
      setUploadingInProgress(true);
      creatives.map(async (file) => {
        setUploadProgress((prevProgress) => ({ ...prevProgress, ...{ [file.name]: 0 } }));
        let typeFolder = file.type.startsWith('image') ? 'images' : 'videos';
        try {
          let promise = uploadCreative(
            file,
            `${influencerCreativePrefix}${typeFolder}/${influencer.id}`, //`${influencerCreativePrefix}${typeFolder}/${shopId}/${influencer.id}`,
            { shopId, influencerId: influencer.id },
            (progress, name) => {
              setUploadProgress((prevProgress) => {
                return { ...prevProgress, ...{ [name]: progress } };
              });
            },
          );

          promises.push(promise);
        } catch (e) {
          handleUploadException(e, file.name);
        }
      });

      let allSettled = await Promise.allSettled(promises);
      let error = false;

      setUploadingInProgress(false);
      allSettled.forEach((fileData) => {
        if (fileData.status === 'fulfilled') {
          if (!influencer.creatives) {
            influencer.creatives = [];
          }
          if (fileData.value.data) {
            influencer.creatives.push({ ...fileData.value.data });
            updateCreatives(influencer, shopId, fileData.value.data);
          }
        } else {
          handleUploadException(fileData.reason, fileData.reason.fileName);
          error = true;
        }
      });
      if (editMode && !error) {
        resetModes();
      }

      return allSettled;
    }
  };

  const handleCreativeDeleted = async (
    influencer: SelectableInfluencer,
    creativeName: string | undefined,
    creativeSrc: string,
  ) => {
    const newCreatives = await deleteCreative(influencer, creativeName, creativeSrc);
    influencer.creatives = newCreatives;
    resetModes();
  };

  const handleUploadException = (error: { message: string }, fileName: string) => {
    setUploadingInProgress(false);
    const message =
      error.message.indexOf('Firebase Storage:') !== -1
        ? 'Only files that are less than 5MB are allowed, please check the file size.'
        : error.message;
    setUploadError(message);
    if (fileName) {
      setUploadProgress((prevProgress) => {
        let prev = { ...prevProgress };
        delete prev[fileName];
        return { ...prev };
      });
    }
  };

  const editInfluencer = async (influencer: SelectableInfluencer) => {
    setEditMode(true);
    dispatch(AddAffluencerModalOpenChanged(true));
    let editInfluencer: SelectableInfluencer = influencers.find(
      (infl) => infl.id === influencer.id,
    );
    if (!editInfluencer.discountCodes || !editInfluencer.discountCodes.length) {
      editInfluencer.discountCodes = [{ code: '', value_type: 'percentage' }];
    }
    setInfluencerToEdit({ ...editInfluencer });
  };

  const deleteInfluencer = async (influencer: influencer) => {
    setLoading(true);
    await firestore()
      .collection('shops')
      .doc(shopId)
      .collection('influencers')
      .doc(influencer.id)
      .delete();

    influencersDispatch({ type: 'delete', influencer: influencer });
    try {
      await axiosInstance.post('/v2/attribution/affluencer-update', {
        influencer,
        shopDomain: shopId,
        source: 'client',
        activeCurrency,
        isDelete: true,
      });
    } catch (e) {
      console.log('update: error', e);
    }
    setLoading(false);
  };

  const showLightbox = (creatives: creativeType[], index = 0) => {
    //influencer: SelectablInfluencer, index = 0) => {
    const data = creatives.map((creative) => {
      return {
        url: creative.url,
        type: creative.type,
        thumbnail: creative.thumbnail || '',
        altTag: '',
        name: creative.name || '',
        downloadLink: creative.downloadLink,
      };
    });

    dispatch(lightboxOpen({ resources: data, startIndex: index }));
  };

  const resetModes = () => {
    setEditMode(false);
    dispatch(AddAffluencerModalOpenChanged(false));
    setInfluencerToEdit(emptyInfluencer());
    setUploadError('');
    setDiscountCodeError('');
    setUploadProgress({});
    setProfileUploadProgress({});
    setLoading(false);
  };

  const loadInfluencers = useCallback(
    async (pageIndex, initial = false) => {
      if (!mainDatePickerSelectionRange) return;
      const startDate = moment(mainDatePickerSelectionRange.start).tz(shopTimezone).format();
      const endDate = moment(mainDatePickerSelectionRange.end).tz(shopTimezone).format();
      const accounts = Object.entries(integrations)
        .filter(([serviceId, accounts]) => accounts?.length)
        .reduce(
          (acc, [service, accounts]) => ({
            ...acc,
            [service]: accounts?.map((acc) => acc.id) || [],
          }),
          {},
        );

      const params: InfluencerAttributionRequest = {
        startDate,
        endDate,
        freeSearch: debouncedSearch,
        page: pageIndex,
        model: attributionModel,
        breakdown: 'campaignId',
        dateModel: dateModel,
        shopDomain: shopId,
        timezone: shopTimezone,
        sort_by: sortBy,
        sort_direction: sortDirection,
        isAffluencerHub: true,
        attributionWindow,
        accountIds: accounts,
        currency: activeCurrency || 'USD',
      };

      setLoading(true);
      const { data } = await axiosInstance.post(
        '/v2/attribution/get-influencer-attribution',
        params,
      );
      const influencerStats: InfluencerAttributionResponse[] = data?.data || [];
      if (initial) {
        setTotalInfluencers(data.total);
        influencersDispatch({ type: 'replace_all', influencers: influencerStats });
      } else {
        influencersDispatch({ type: 'add', influencers: influencerStats });
      }

      setLoading(false);
    },
    [
      mainDatePickerSelectionRange,
      shopTimezone,
      integrations,
      debouncedSearch,
      attributionModel,
      dateModel,
      shopId,
      sortBy,
      sortDirection,
      attributionWindow,
      activeCurrency,
    ],
  );

  useEffect(() => {
    if (shopTimezone) {
      loadInfluencers(0, true);
    }
  }, [shopifyAccessToken, shopTimezone, loadInfluencers]);

  useEffect(() => {
    if (pageIndex > 0 && shopTimezone) {
      loadInfluencers(pageIndex, false);
    }
    // Don't do it on initial or any other change than pageIndex
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    setPageIndex(0);
  }, [displayMode]);

  useEffect(() => {
    const selected = influencers.filter((i) => i.selected);
    selected.forEach((influencer, i) => (influencer.color = INFLUENCERS_COLORS[i]));
    setSelectedInfluencers(selected);

    if (selected.length === 0 && affluencerHubChartOpen) {
      dispatch(AffluencerHubChartOpenChanged(false));
    }
  }, [influencers, affluencerHubChartOpen, dispatch]);

  const needsPermission = () => {
    if (!shopifyScopes) return false;
    return !['read_price_rules', 'write_price_rules'].every((y) => shopifyScopes.includes(y));
  };

  const context: InfluencersContextType = {
    influencers,
    tableCreativeFilterBy,
    copiedToClipboard,
    sortBy,
    sortDirection,
    columns,
    uploadProgress,
    profileUploadProgress,
    uploadError,
    discountCodeError,
    loading,
    shopId,
    loadedDiscountCodes: loadedDiscountCodes,
    selectedInfluencers,
    currency: activeCurrency,
    selectedColumns,
    pageIndex,
    totalInfluencers,
    displayMode,
    uploadingInProgress,
    setTotalInfluencers,
    setPageIndex,
    setSelectedColumns,
    editInfluencer,
    deleteInfluencer,
    toggleSelected,
    toggleActive,
    setSortBy,
    setSortDirection,
    toggleCopiedToClipboard,
    setCopiedToClipboard,
    handleCreativeUpload,
    handleCreativeDeleted,
    showLightbox,
    setUploadError,
    setTableCreativeFilterBy,
    setLoading,
    searchDiscountCodes,
    uploadProfilePhoto,
    setDisplayMode,
    influencersDispatch,
    loadDefaultDiscountCodes,
    loadInfluencers,
    setSelectedInfluencers,
  };

  return (
    <InfluencersContext.Provider value={context}>
      <Page fullWidth>
        <div>
          {needsPermission() && (
            <AccountConnection
              accountName=""
              connected={false}
              action={{
                onAction: () => {
                  connectToShopify(shopId, true);
                },
                content: 'Connect to Shopify',
              }}
              title={
                <div className="flex gap-6.5 items-center">
                  <ShopifyIconBase64 small={true} />
                  Please reconnect Shopify to enable discount code creation
                </div>
              }
            />
          )}
          <Card>
            <Collapsible open={affluencerHubChartOpen!} id="influencers-collapsible">
              <Card.Section flush={true}>
                <InfluencersChart />
              </Card.Section>
            </Collapsible>
            <Card.Section flush={true}>
              <InfluencersDashboard />
            </Card.Section>
          </Card>
        </div>
      </Page>

      <AddEditInfluencer
        addInfluencer={addInfluencer}
        isOpen={addAffluencerHubModalOpen}
        onClose={resetModes}
        editMode={editMode}
        influencerEdit={influencerToEdit}
        editSubmit={editSubmit}
      />
      <InfluencerImportModal
        isOpen={affluencerHubImportModalOpen}
        onClose={() => dispatch(AffluencerHubImportModalOpenChanged(false))}
      />
    </InfluencersContext.Provider>
  );
};

const InfluencersPage = computeFeatureFlags(
  FeatureFlag.PIXEL_AFFLUENCER_HUB_FF,
  InfluencersPageComponent,
  () => (
    <LockedPageTemplate
      bannerPageName="attribution"
      featureName="Pixel"
      mainDescription="Harness the power of first-party data with the Triple Pixel — providing more accurate attribution to fuel profitable growth."
      descriptionMaxWidth="400px"
      firstImage={firstImage}
      secondTitle="Models for every strategy"
      secondSectionImage={secondSectionImage}
      featureFlags={[FeatureFlag.PIXEL_AFFLUENCER_HUB_FF]}
      sections={[
        {
          subtitle: 'End-to-end customer journey tracking',
          description:
            'Capture every customer interaction with our advanced tracking technology, ensuring no opportunity is missed.',
        },
        {
          subtitle: 'Recognize new vs returning customers',
          description:
            'Triple Whale’s pixel makes it simple to identify new versus returning visitors and optimize campaigns for each.',
        },
        {
          subtitle: 'Understand the Total Impact of your marketing',
          description:
            'Combine multi-touch attribution and post-purchase survey data using our Total Impact attribution model — for a holistic understanding of your marketing mix.',
        },
      ]}
      secondSectionTitleMargin="40px"
      quote="At MaryRuth’s, we are big advocates of Total Impact. Not just because it made the numbers look better, but because it really aligned - I can’t say that enough - with everything we were intuitively and subconsciously knowing. We knew things the data wasn’t showing. We knew the reason it wasn’t showing is because the data is so complex and the way we measured was too simple. It really helped us."
      quoteAuthor="Wyatt Lowe"
      quoteAuthorTitle="Director of Digital Growth, MaryRuth’s"
      logo={logo}
      quateImage={quateImage}
    />

    // <UpgradePageFallBack
    //   InAppContextBannerEnabled={true}
    //   InAppContextBannerPage={'attribution'}
    //   featureFlags={[FeatureFlag.PIXEL_AFFLUENCER_HUB_FF]}
    // />
  ),
);

export default InfluencersPage;
