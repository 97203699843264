import { AgentEntity, WillyDataSequence } from 'components/Willy/types/willyTypes';
import { executeCustomQuery } from 'components/Willy/utils/willyUtils';
import moment from 'moment-timezone';

// Export a standalone function to calculate entity count
export async function calculateEntityCount(
  sequence: WillyDataSequence | undefined,
  shopId: string | null,
  activeAccounts: any,
  entity?: AgentEntity | undefined,
  providers?: string[],
): Promise<number> {
  if (!sequence || !shopId || !activeAccounts) {
    console.log('no sequence or shopId or activeAccounts');
    return 1;
  }

  try {
    const weekAgo = moment().subtract(1, 'week').format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');

    const field = fieldByEntity(entity);
    if (!field) {
      return 1;
    }

    let query = '';
    if (entity && ['campaign', 'adset', 'ad'].includes(entity)) {
      query = `--sql
      SELECT 
        COUNT(DISTINCT ${field}) AS count
      FROM ads_table 
      WHERE event_date BETWEEN '${weekAgo}' AND '${yesterday}'
      ${providers ? `AND channel IN (${providers.map((p) => `'${p}'`).join(',')})` : ''}
      and spend > 1
    `;
    } else if (entity === 'landing_page') {
      query = `--sql
        select
          count()
        from
          (
            select
              landing_page,
              sum(sessions) s
            from
              web_analytics_table wa
            where
              event_date BETWEEN '${weekAgo}' AND '${yesterday}'
            group by
              all
            having
              s > 100
          )
    `;
    }
    if (!query) {
      return 1;
    }

    const res = await executeCustomQuery({
      query,
      activeAccounts,
      currency: 'USD',
      dialect: 'clickhouse',
      shopId: shopId,
      timezone: 'America/New_York',
    });

    const count = res.data.find((r) => r.name === 'count')?.value?.[0];
    if (!count || typeof count !== 'number' || isNaN(Number(count))) {
      return 1;
    }

    return count;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

function fieldByEntity(
  entity?: AgentEntity,
): 'campaign_id' | 'adset_id' | 'ad_id' | 'landing_page' | null {
  if (!entity) {
    return null;
  }
  switch (entity) {
    case 'campaign':
      return 'campaign_id';
    case 'adset':
      return 'adset_id';
    case 'ad':
      return 'ad_id';
    case 'landing_page':
      return 'landing_page';
    default:
      return null;
  }
}
