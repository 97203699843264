import amplitude from 'amplitude-js';
import { toggleEnquirelabsConfigScreenOpen } from 'ducks/enquirelabs';
import { toggleGoogleAdsConfigScreenOpen } from 'ducks/googleAds';
import { toggleGorgiasConfigScreenOpen } from 'ducks/gorgias';
import { toggleKlaviyoConfigScreenOpen } from 'ducks/klaviyo';
import { toggleKnoConfigScreenOpen } from 'ducks/kno';
// import { toggleFacebookConfigScreenOpen } from 'ducks/facebook';
import { toggleSnapchatOrganizationsConfigScreenOpen } from 'ducks/snapchat';
import { toggleTiktokConfigScreenOpen } from 'ducks/tiktok';
import { useAppDispatch } from 'index';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { SummarySection, SummarySectionTypes } from 'types/SummarySection';
import { selectConnectedServices } from 'utils/selectors';

import { Icon, Modal, TextField } from '@shopify/polaris';

import SECTIONS from '../constants/defaultTileSections';
import {
  isSectionHide,
  minimizeSectionOnPress,
  selectSummarySectionsLayout,
  summarySectionsOrderChanged,
} from '../ducks/summary';
import ConnectedLists from './ConnectedLists';
import allServices from 'constants/services';
import { toggleAmazonConfigScreenOpen } from 'ducks/amazon';
import { facebookConnectOnPress } from '../ducks/facebook';
import { useFeatureFlagSummarySectionFilter } from 'hooks/useFeatureFlagSummarySectionFilter';
import { buildWillyDynamicSectionWidget } from './Willy/utils/willyUtils';
import { notReleasedProviders } from '../ducks/sensory';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../$stores/$shop';
import ConnectedListsNew from './ConnectedListsNew';
import { Text } from '@tw/ui-components';
import { SearchMinor } from '@shopify/polaris-icons';

type SummarySettingsProps = {
  type: SummarySectionTypes;
  open: boolean;
  onClose: () => void;
};

const SummarySettings: React.FC<SummarySettingsProps> = ({ type, open, onClose }) => {
  const dispatch = useAppDispatch();
  const { summarySectionsFilter } = useFeatureFlagSummarySectionFilter();

  const customSections = useSelector((state: RootState) => state.customSections);
  const msp = useSelector((state: RootState) => state.msp);
  const externalSections = useSelector((state: RootState) => state.externalApps.externalSections);
  const selectWillyWidgets = useSelector((state: RootState) => state.willySummaryWidget);
  const providers = useSelector((state: RootState) => state.sensory.providers);
  const currency = useStoreValue($currency);
  const willyWidgetsSections = useMemo(
    () => buildWillyDynamicSectionWidget(selectWillyWidgets),
    [selectWillyWidgets],
  );
  const summarySectionsLayout = useSelector(selectSummarySectionsLayout);
  const notReleasedSensoryProviders = useSelector(notReleasedProviders);

  const [summarySections, setSummarySections] = useState<SummarySection[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const onSearchValueChange = (value) => setSearchValue(value);

  const [hiddenSummarySections, setHiddenSummarySections] = useState<SummarySection[]>([]);
  const connectedServices = useSelector(selectConnectedServices);
  const isBetaShop = useSelector((state: RootState) => state.isBetaShop);
  const minimizeSection = (sectionId) => dispatch(minimizeSectionOnPress(sectionId, true));

  const filteredSummarySections = useMemo(() => {
    if (!searchValue) return summarySections;

    return summarySections.filter((section) => {
      return section.title.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [summarySections, searchValue]);

  const filteredHiddenSummarySections = useMemo(() => {
    if (!searchValue) return hiddenSummarySections;

    return hiddenSummarySections.filter((section) => {
      return section.title.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [hiddenSummarySections, searchValue]);

  const maximizeSectionOnPress = (sectionId) => {
    const section = SECTIONS.find((s) => s.id === sectionId);

    if (section?.isHideByDefault) {
      const index = summarySectionsLayout[section.id]?.index;
      if (!index) {
        dispatch(summarySectionsOrderChanged([section, ...summarySections]));
      }
    }
    dispatch(minimizeSectionOnPress(sectionId, false));
  };

  const orderOnChange = (sections) => {
    return dispatch(summarySectionsOrderChanged(sections));
  };

  useEffect(() => {
    var summarySections: SummarySection[] = [];
    var hiddenSummarySections: SummarySection[] = [];

    const externalApps = Object.keys(externalSections).map((key) => ({
      ...externalSections[key],
      title: `🐙 ${externalSections[key].title}`,
    }));

    let allSections = SECTIONS.concat(
      customSections,
      externalApps as any,
      willyWidgetsSections,
    ).filter(
      (s) => s.isExternalApp || s.types?.includes(type) || (type === 'summary' && !s.types?.length),
    );

    allSections = allSections
      .filter((sec) => !sec.beta || isBetaShop)
      .filter((s) =>
        !s.services ? true : !s.services.some((x) => notReleasedSensoryProviders.includes(x)),
      )
      .filter((sec) => !sec.msp || sec.msp.includes(msp))
      .filter(
        (sec) =>
          !sec.services?.length ||
          sec.services?.filter((service) => !allServices[service]?.isBeta).length,
      )
      .filter(summarySectionsFilter)
      .filter((sec) => {
        if (sec.isSensoryAssetType) {
          const providerId = sec?.services?.[0];
          const assetType = sec.dataType;
          const providerData = providers.find(
            (p) => p.id === providerId && !!p.asset_types?.find((x) => x.id === assetType),
          );
          return !!providerData?.integrations?.some((int) =>
            int.extra_params?.asset_types?.includes(assetType),
          );
        } else return true;
      });

    for (const sec of allSections) {
      const isHide = isSectionHide(summarySectionsLayout, connectedServices, {
        sectionId: sec.id,
        sections: allSections,
      });
      if ((sec.id === 'pacing' || sec.id === 'forwardView') && !connectedServices?.forecasting) {
      } else if (isHide) {
        hiddenSummarySections.push(sec);
      } else {
        summarySections.push(sec);
      }
    }

    summarySections = summarySections.sort((s1, s2) => {
      var h1 = (summarySectionsLayout[s1.id] && summarySectionsLayout[s1.id].index) || 0;
      var h2 = (summarySectionsLayout[s2.id] && summarySectionsLayout[s2.id].index) || 0;
      return h1 - h2;
    });
    setSummarySections(summarySections);
    setHiddenSummarySections(hiddenSummarySections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customSections,
    summarySectionsLayout,
    isBetaShop,
    connectedServices,
    externalSections,
    notReleasedSensoryProviders,
    // willyWidgetsSections,
    summarySectionsFilter,
  ]);

  const toggleSectionDispatcher = {
    // facebookAds: toggleFacebookConfigScreenOpen(),
    facebookAds: () => {
      facebookConnectOnPress();
    },
    klaviyo: toggleKlaviyoConfigScreenOpen(),
    gorgias: toggleGorgiasConfigScreenOpen(),
    tiktok: toggleTiktokConfigScreenOpen(),
    snapchat: toggleSnapchatOrganizationsConfigScreenOpen(),
    GOOGLE_ADS: toggleGoogleAdsConfigScreenOpen(),
    enquirelabs: toggleEnquirelabsConfigScreenOpen(),
    kno: toggleKnoConfigScreenOpen(),
    amazon: toggleAmazonConfigScreenOpen(),
  };

  return (
    <Modal
      //activator={activator}
      open={open}
      onClose={onClose}
      title={
        <div className="flex flex-col gap-2">
          <Text fz={18} fw={500}>
            Manage Sections
          </Text>
          <Text fz={16} color="gray.5">
            Add, remove, and rearrange the sections to your desired configuration.
          </Text>
        </div>
      }
      primaryAction={{
        content: 'Done',
        onAction: onClose,
      }}
    >
      <Modal.Section>
        <div className="flex flex-col gap-8 h-full md:min-h-[500px]">
          <TextField
            label=""
            placeholder="Search"
            value={searchValue}
            onChange={onSearchValueChange}
            prefix={<Icon source={SearchMinor} color="base" />}
            inputMode="search"
            autoComplete="off"
          />
          <ConnectedListsNew
            currentSectionId={undefined}
            items1={filteredSummarySections}
            items2={filteredHiddenSummarySections}
            title1="Shown Sections"
            title2="More sections"
            items1orderOnChange={orderOnChange}
            itemMoveFrom1to2onPress={(section) => {
              amplitude.getInstance().logEvent('Summary: toggle section to hide', {
                section: section.id,
              });
              minimizeSection(section.id);
            }}
            itemMoveFrom2to1onPress={(section) => {
              if (connectedServices[section.services?.[0]]) {
                maximizeSectionOnPress(section.id);
              } else if (connectedServices[section.services?.[0]] === false) {
                onClose();
                dispatch(toggleSectionDispatcher[section.id]);
              } else {
                maximizeSectionOnPress(section.id);
              }
              amplitude.getInstance().logEvent('Summary: toggle section to show', {
                section: section.id,
              });
            }}
          />
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default SummarySettings;
