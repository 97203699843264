import React from 'react';
import { WizardData, WizardStep } from 'types/WizardData';

export const WizardContext = React.createContext<{
  wizardShopData: WizardData;
  wizardConfig: { steps: ReadonlyArray<WizardStep> };
  nextStep: () => void;
  skipStep: () => void;
  jumpToStep: (index: number) => void;
  completeWizard: () => void;
  membersData: string[];
  setMembersData: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
}>({
  nextStep: () => {},
  skipStep: () => {},
  completeWizard: () => {},
  jumpToStep: (index: number) => {},
  wizardShopData: {} as WizardData,
  wizardConfig: { steps: [] },
  membersData: [],
  setMembersData: () => {},
  isLoading: false,
});
