import { ActionIcon, Flex, FormattedColor, Size, Text } from '@tw/ui-components';
import { FC, useMemo } from 'react';
import { ColumnTooltip } from './ColumnTooltip';
import { analyticsEvents, genericEventLogger, metricBuilderActions } from 'utils/dataLayer';
import { useDelayedDisclosure } from 'hooks/useDelayedDisclosure';

type BuilderBadgeProps = {
  text: string;
  type: 'table' | 'metric' | 'dimension' | 'filter';
  onRemove?: () => void;
  onClick?: () => void;
  recommended?: boolean;
  onHideChange?: () => void;
  subText?: string;
  description?: string;
  readmeUrl?: string | null;
};

export const BuilderBadge: FC<BuilderBadgeProps> = ({
  text,
  type,
  onRemove,
  onClick,
  recommended,
  onHideChange,
  subText,
  description,
  readmeUrl,
}) => {
  const {
    opened: tooltipOpened,
    open,
    close,
  } = useDelayedDisclosure({
    secondsToWait: 0.15,
    waitBeforeClose: true,
    waitBeforeOpen: true,
  });
  const bgColors: { bg: FormattedColor; hoverBg: FormattedColor } = useMemo(() => {
    switch (type) {
      case 'table':
        return { bg: 'purple.1', hoverBg: 'purple.2' };
      case 'metric':
        return { bg: 'two.1', hoverBg: 'two.2' };
      case 'dimension':
        return { bg: 'teal.1', hoverBg: 'teal.2' };
      case 'filter':
        return { bg: 'pink.1', hoverBg: 'pink.2' };
    }
  }, [type]);
  return (
    <div className="mr-auto" onMouseEnter={() => open()} onMouseLeave={() => close()}>
      <ColumnTooltip
        tooltipOpened={tooltipOpened && !!subText}
        title={text}
        subTitle={subText || type}
        description={description || text}
        readmeUrl={readmeUrl}
        tracking={() => {
          genericEventLogger(analyticsEvents.METRIC_BUILDER, {
            action: metricBuilderActions.GO_TO_DATA_DICTIONARY,
            table_name: type === 'table' ? text : undefined,
            metric_name: type === 'table' ? undefined : text,
            url: readmeUrl,
          });
        }}
        withinPortal={true}
      >
        <Flex
          justify="space-between"
          align="center"
          mr="auto"
          p="xs"
          borderRadius="12px"
          bg={tooltipOpened ? bgColors.hoverBg : bgColors.bg}
          onClick={onClick}
          cursor={onClick ? 'pointer' : 'default'}
          w={200}
        >
          <Text size="sm" weight={500} color="gray.8" lh="16px" truncate>
            {text}
          </Text>
          {onRemove && tooltipOpened && (
            <Flex gap="xs">
              {onHideChange && (
                <ActionIcon
                  variant="transparent"
                  icon="eye"
                  iconSize={16}
                  size={16 as Size}
                  onClick={() => onHideChange()}
                />
              )}
              <ActionIcon
                variant="transparent"
                icon="close"
                iconSize={10}
                size={16 as Size}
                onClick={(e) => {
                  onRemove();
                  e.stopPropagation();
                }}
              />
            </Flex>
          )}
          {recommended && (
            <Text size="xs" weight={500} color="purple.8" lh="16px">
              Recommended
            </Text>
          )}
        </Flex>
      </ColumnTooltip>
    </div>
  );
};
