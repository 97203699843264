import { AffluencerHubHeader } from 'components/headers/AffluencerHubHeader';
import AttributionHeader from 'components/headers/AttributionHeader/AttributionHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';
import CreativeCockpitHeader from 'components/headers/CreativeCockpitHeader';
import { META_PIXEL_ROUTES } from '@tw/shared-types/module/frontend-config';

export const PIXEL_SECTION: NavSection = {
  title: 'Pixel',
  icon: (props) => <NavSectionIcon src="pixel" {...props} />,
  routes: [
    {
      url: '/attribution/all',
      ...META_PIXEL_ROUTES['/attribution/all'],
      label: 'All',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/ads',
      ...META_PIXEL_ROUTES['/attribution/ads'],
      label: 'Ads',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/email',
      ...META_PIXEL_ROUTES['/attribution/email'],
      label: 'Email / SMS',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/social',
      ...META_PIXEL_ROUTES['/attribution/social'],
      label: 'Organic',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/affluencer-hub',
      label: 'Affluencer Hub',
      ...META_PIXEL_ROUTES['/attribution/affluencer-hub'],
      header: () => <AffluencerHubHeader />,
    },
    // {
    //   url: '/attribution/customer-journeys-new',
    //   dependsOnFeature: FeatureFlag.PIXEL_ALL_FF,
    //   label: 'Customer Journeys',
    //   header: () => <CustomerJourneysHeaderNew />,
    // },
    // {
    //   url: '/attribution/customer-journeys',
    //   dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
    //   label: 'Customer Journeys',
    //   header: () => <CustomerJourneysHeader />,
    // },
    {
      url: '/creative-cockpit',
      label: 'Creative Cockpit',
      ...META_PIXEL_ROUTES['/creative-cockpit'],
      header: () => <CreativeCockpitHeader />,
    },
  ],
};
