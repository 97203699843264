import { $store } from '@tw/snipestate';

export const $brandGuidelinesPopup = $store<{
  opened: boolean;
}>({
  opened: false,
});

export const openBrandGuidelinesPopup = () => {
  $brandGuidelinesPopup.set({ opened: true });
};

export const closeBrandGuidelinesPopup = () => {
  $brandGuidelinesPopup.set({ opened: false });
};
