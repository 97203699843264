import { $currentRevenue, $isFreeShop, $shopSubscription } from '$stores/willy/$subscription';
import { $bookDemoModal, closeBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { toast } from 'react-toastify';
import { useStoreValue } from '@tw/snipestate';
import { Modal } from '@tw/ui-components';
import { MeetingPlanner } from 'components/WizardWalkthrough/steps/MeetingPlanner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'reducers/RootType';

export const BookDemoModal: React.FC = () => {
  const bookDemoModal = useStoreValue($bookDemoModal);
  const isFreeShop = useStoreValue($isFreeShop);
  const { hubSpotCompany }: any = useSelector((state: RootState) => state.hubspot);
  const { primary_support_team, primary_sales_team, expansion_ae_owner } =
    hubSpotCompany.properties || {};
  const [routerId, setRouterId] = useState<string>('');
  const revenue = useStoreValue($currentRevenue);
  const subscription = useStoreValue($shopSubscription);
  const navigate = useNavigate();

  useEffect(() => {
    if (bookDemoModal.opened) {
      let newRouterId = '';

      switch (bookDemoModal.source) {
        case 'pricing':
          if (revenue > 1000000) {
            if (isFreeShop) {
              newRouterId = 'app-router';
            } else if (expansion_ae_owner) {
              newRouterId = 'expansion-ae-router';
            } else {
              newRouterId = 'app-router';
            }
          } else {
            closeBookDemoModal();
            navigate('/store-settings/plans');
          }
          break;

        case 'moby':
          if (revenue > 1000000) {
            if (isFreeShop) {
              newRouterId = 'app-router';
            }
            newRouterId = 'eae-moby-agents';
          } else {
            closeBookDemoModal();
            navigate('/store-settings/plans');
          }
          break;

        case 'sonar':
          if (isFreeShop) {
            newRouterId = 'app-router';
          } else if (revenue > 1000000) {
            if (revenue > 2500000) {
              newRouterId = 'sonar-setup';
            } else {
              const lowSupportOptions = ['Customer Success Office Hours', 'L1 Support'];
              if (!lowSupportOptions.includes(primary_support_team)) {
                newRouterId = 'sonar-setup';
              } else {
                setRouterId('');
                window.location.replace(
                  'https://try.triplewhale.com/customer-success-office-hours',
                );
                closeBookDemoModal(); // Close modal after navigation
              }
            }
          } else {
            toast.info('Please reach out to us via the chat icon below!');
            closeBookDemoModal();
          }
          break;

        case 'churn-router':
          const isWithin60Days = (() => {
            if (!subscription?.created_at) return false;
            const createdDate = new Date(subscription.created_at);
            const daysSinceCreation = Math.floor(
              (Date.now() - createdDate.getTime()) / (1000 * 60 * 60 * 24),
            );
            return daysSinceCreation <= 60;
          })();

          if (isWithin60Days) {
            newRouterId = 'churn-router---implementation';
          } else {
            newRouterId = 'churn-router';
          }
          break;

        default:
          break;
      }

      // Update the router ID if a new one was set and is different from the current
      if (newRouterId) {
        setRouterId(newRouterId);
      }
    }
  }, [
    bookDemoModal.opened,
    bookDemoModal.source,
    primary_sales_team,
    primary_support_team,
    isFreeShop,
    routerId,
    revenue,
    navigate,
    expansion_ae_owner,
    subscription,
  ]);

  return (
    <Modal
      opened={bookDemoModal.opened}
      onClose={() => {
        closeBookDemoModal();
      }}
    >
      {routerId ? (
        <MeetingPlanner routerId={routerId} />
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
      )}
    </Modal>
  );
};
