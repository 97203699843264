import { Flex, Text } from '@tw/ui-components';

import {
  stepActionType,
  WorkflowStepBase,
  WorkflowStepSendToPushNotification,
} from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $shopUsers } from '$stores/$users';
import { useMemo, useState } from 'react';
import { FlowStepWithText } from './FlowStepWithText';

type FlowSendToPushNotificationProps = {};

export const FlowSendToFeed = ({}: FlowSendToPushNotificationProps) => {
  return (
    <Flex direction="column" gap="sm">
      <Text>Send output to feed</Text>
    </Flex>
  );
};
