import ActiveCurrencyButton from 'components/ActiveCurrencyButton';
import AttributionModelPicker from './AttributionModelPicker';
import AttributionDateModelPicker from './AttributionDateModelPicker';
import { SourceTypes } from 'types/services';
import AttributionMoreSettings, { TagsOptions } from './AttributionMoreSettings';
import { useDarkMode } from 'dark-mode-control';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useMemo } from 'react';
import { ActionIcon, Flex, Icon, Menu, Text, Tooltip } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useAppDispatch } from 'index';
import { $shopSequences } from '$stores/willy/$sequences';
import { chooseWorkflowToRun } from 'ducks/attribution/actions';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
interface AttributionSettingsProps {
  isOpen: boolean;
  type: SourceTypes;
  onOpen: () => any;
  disabled?: boolean;
  isShareMode?: boolean;
  hideCurrencyButton?: boolean;
  hideOneDayConversionValue?: boolean;
  hideDateModel?: boolean;
  hideAttributionModel?: boolean;
  hideMoreAttributionSettings?: boolean;
  hideExportCsv?: boolean;
  hideWrapLines?: boolean;
  hideCustomAdSpend?: boolean;
  hideNexus?: boolean;
}

const AttributionSettings: React.FC<AttributionSettingsProps> = (props) => {
  const {
    isOpen,
    onOpen,
    type,
    isShareMode = false,
    hideCurrencyButton,
    hideDateModel,
    hideAttributionModel,
    hideMoreAttributionSettings,
    hideWrapLines,
    hideExportCsv,
    hideCustomAdSpend,
    hideNexus,
  } = props;

  const dispatch = useAppDispatch();

  const activeAppVersion = useStoreValue($activeAppVersion);
  const { attributionSubscription } = useSelector((state: RootState) => state.attribution);
  const tagsToCheck: TagsOptions[] = useMemo<TagsOptions[]>(
    () => ['oneTime', 'subscriptionFirstOrder', 'subscriptionRecurringOrder'],
    [],
  );
  const subscriptionFilterOn = useMemo<boolean>(() => {
    const allTagsPresent = tagsToCheck.every((tag) => attributionSubscription?.includes(tag));
    return !allTagsPresent && !!attributionSubscription.length;
  }, [attributionSubscription, tagsToCheck]);
  const isThreePointOh = useMemo(() => activeAppVersion === '3.0', [activeAppVersion]);

  const { workflowToRun } = useSelector((state: RootState) => state.attribution);
  const workflows = useStoreValue($shopSequences);

  return (
    <>
      <Menu opened={isOpen} closeOnItemClick={false} onClose={() => onOpen()} shadow="md">
        <Menu.Target>
          <div className="relative">
            <ConditionalWrapper
              condition={!!subscriptionFilterOn}
              wrapper={(x) => (
                <Tooltip label={'Table is currently displaying subscription filtered data'}>
                  {x}
                </Tooltip>
              )}
            >
              <div>
                {isThreePointOh ? (
                  <ActionIcon
                    variant="activator"
                    icon="three-dots"
                    onClick={onOpen}
                    disabled={isShareMode}
                    id="attribution-settings"
                  />
                ) : (
                  <ActionIcon
                    id="attribution-settings"
                    size="lg"
                    radius="sm"
                    onClick={onOpen}
                    disabled={isShareMode}
                    variant="activator"
                    icon="horizontal-slider"
                  />
                )}
                {subscriptionFilterOn && !isThreePointOh && (
                  <div className={'turn-on-indication z-10 ml-2'} />
                )}
              </div>
            </ConditionalWrapper>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {!hideDateModel && (
            <Menu.Item>
              <AttributionDateModelPicker theme="none" />
            </Menu.Item>
          )}

          {!hideAttributionModel && (
            <Menu.Item>
              <AttributionModelPicker maxHeight="50%" zIndex={100000} type={type} />
            </Menu.Item>
          )}

          {!hideMoreAttributionSettings && (
            <AttributionMoreSettings
              hideExportCsv={hideExportCsv}
              hideWrapLines={hideWrapLines}
              hideCustomAdSpend={hideCustomAdSpend}
              hideNexus={hideNexus}
            />
          )}
          <Menu.Divider />

          {!hideCurrencyButton && (
            <Menu.Item>
              <ActiveCurrencyButton
                theme="none"
                activator={(statsCurrency) => (
                  <Flex align="center" justify="space-between">
                    <Text size="sm" weight={500} color="named2.0">
                      Currency: {statsCurrency}
                    </Text>
                    <Icon name="chevron-right-minor" size={12} color="named.8" />
                  </Flex>
                )}
                dropdownPosition={'right-start'}
                dropDownOffset={16}
                withinPortal={false}
              />
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default AttributionSettings;
