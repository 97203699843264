import { ActionIcon, Button } from '@tw/ui-components';
import {
  WorkflowStepBase,
  WorkflowStepRunQuery,
  WorkflowStepRunForecasting,
  WorkflowStepRunMarketingMixModel,
  WorkflowStepRunPython,
  WorkflowStepSearch,
  WorkflowStep,
} from '../types/willyTypes';
import { stepActionType } from '../types/willyTypes';
import { FlowStepWithText } from './FlowStepWithText';
import { useState, useMemo, useEffect } from 'react';
import { Editor } from '@monaco-editor/react';
import { cleanQuestion } from '../utils/willyUtils';
import copyToClipboard from 'utils/copyToClipboard';
import { filterStepsWithDataOutput } from '../utils/sequences';
import { FlowStepWithTableBuilder } from './FlowStepWithTableBuilder';

type AllPossibleSteps =
  | WorkflowStepSearch
  | WorkflowStepRunQuery
  | WorkflowStepRunForecasting
  | WorkflowStepRunMarketingMixModel
  | WorkflowStepRunPython;

type FlowToolStepProps = {
  step: AllPossibleSteps & WorkflowStepBase;
  stepChange: (newStep: AllPossibleSteps & WorkflowStepBase) => void;
  setIsPristine: (isPristine: boolean) => void;
  readOnly: boolean;
  depth: number;
  toolConfig?: stepActionType;
  steps: WorkflowStep[];
};

type FlowToolStepMode = 'text' | 'code' | 'builder';

export const FlowToolStep: React.FC<FlowToolStepProps> = ({
  step,
  steps,
  stepChange,
  setIsPristine,
  toolConfig,
  readOnly,
  depth,
}) => {
  const [mode, setMode] = useState<FlowToolStepMode>('text');

  useEffect(() => {
    if (step.stepType === 'runPython') {
      setMode(step.code && !step.prompt ? 'code' : 'text');
    } else if (
      step.stepType === 'runQuery' ||
      step.stepType === 'runForecasting' ||
      step.stepType === 'runMarketingMixModel'
    ) {
      if (step.builder) {
        setMode('builder');
        return;
      }
      setMode(step.query && !step.prompt ? 'code' : 'text');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.stepType]);

  const possibleInput = useMemo(() => {
    const currentStepIndex = steps.findIndex((s) => s.id === step.id);
    const previousSteps = steps.slice(0, currentStepIndex);
    return filterStepsWithDataOutput(previousSteps).map((step, index) => {
      return {
        fileName: `input/${cleanQuestion(`${step.stepType}_${index}`)}.json`,
      };
    });
  }, [steps, step.id]);

  const language = useMemo(() => {
    if (step.stepType === 'runPython') {
      return 'python';
    } else if (step.stepType === 'runQuery') {
      return 'sql';
    }
    return 'plaintext';
  }, [step.stepType]);

  const defaultCode = useMemo(() => {
    if (step.stepType === 'runPython') {
      return step.code || '';
    } else if (
      step.stepType === 'runQuery' ||
      step.stepType === 'runForecasting' ||
      step.stepType === 'runMarketingMixModel'
    ) {
      return step.query || '';
    }
    return null;
  }, [step]);

  const defaultBuilder = useMemo(() => {
    if (step.stepType === 'runQuery') {
      return step.builder;
    }
    return null;
  }, [step]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex">
        {['runQuery', 'runPython'].includes(step.stepType || '') && (
          <Button.Group>
            {[
              { label: 'Text', value: 'text' },
              { label: step.stepType === 'runQuery' ? 'SQL' : 'Python', value: 'code' },
              ...(step.stepType === 'runQuery' ? [{ label: 'Builder', value: 'builder' }] : []),
            ].map((item) => (
              <Button
                key={item.value}
                variant={'activator'}
                bg={mode === item.value ? 'gray.2' : undefined}
                onClick={() => {
                  setMode(item.value as FlowToolStepMode);
                }}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
        )}
      </div>
      {step.stepType === 'runPython' && mode === 'code' && (
        <div className="flex flex-col">
          <div>
            The following files will be included in the execution environment, each with data that
            can be used as input for the python code:
          </div>
          {possibleInput.map((input) => (
            <div className="flex items-center" key={input.fileName}>
              <ActionIcon
                icon="copy-2"
                iconSize={12}
                onClick={async () => {
                  await copyToClipboard(input.fileName);
                }}
              />
              {input.fileName}
            </div>
          ))}
        </div>
      )}
      {mode === 'text' && (
        <FlowStepWithText
          readOnly={readOnly}
          text={step.prompt || ''}
          textChange={(text) => {
            if (step.stepType === 'runPython') {
              stepChange({ ...step, prompt: text, code: '' });
            } else if (
              step.stepType === 'runQuery' ||
              step.stepType === 'runForecasting' ||
              step.stepType === 'runMarketingMixModel'
            ) {
              stepChange({ ...step, prompt: text, query: '' });
            } else if (step.stepType === 'search') {
              stepChange({ ...step, prompt: text });
            }
          }}
          toolConfig={toolConfig}
          setIsPristine={setIsPristine}
          variables={step.variables || []}
          depth={depth}
          allowComments
          variableChange={(variable) => {
            const variables = step.variables || [];
            const variableIndex = variables.findIndex((v) => v.key === variable.key);
            if (variableIndex !== -1) {
              variables[variableIndex] = variable;
            } else {
              variables.push(variable);
            }
            stepChange({
              ...step,
              variables,
            });
          }}
        />
      )}
      {mode === 'code' && (
        <Editor
          language={language}
          height="400px"
          className="rounded overflow-hidden"
          defaultLanguage={language}
          defaultValue={defaultCode || ''}
          theme="light"
          value={defaultCode || ''}
          options={{
            readOnly: readOnly,
            minimap: {
              enabled: false,
            },
          }}
          onChange={(value) => {
            if (step.stepType === 'runPython') {
              stepChange({
                ...step,
                code: value || '',
              });
            } else if (step.stepType === 'runQuery') {
              stepChange({
                ...step,
                query: value || '',
              });
            }
          }}
          onMount={(editor, monaco) => {}}
        />
      )}
      {mode === 'builder' && step.stepType === 'runQuery' && (
        <div className="flex flex-col gap-4">
          <FlowStepWithTableBuilder
            builder={defaultBuilder ?? null}
            setBuilder={(builder) => {
              if (step.stepType === 'runQuery') {
                stepChange({
                  ...step,
                  builder,
                });
              }
            }}
          />
          {!!defaultBuilder && (
            <div>
              <Button
                variant="secondary"
                onClick={() => {
                  stepChange({
                    ...step,
                    builder: null,
                  });
                }}
              >
                Clear Builder
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
