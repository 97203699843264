import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Container,
  Flex,
  Size,
  Skeleton,
  Text,
  Title,
} from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { upgradePlanClicked } from 'ducks/subscription';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'reducers/RootType';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useMutableQueryParams } from 'hooks/useMutableQueryParams';
import InAppVideoBanner from 'components/InAppContextBanner/InAppVideoBanner';
import { IN_APP_CONTEXT_BANNERS_DATA } from 'utils/InAppContextBanners';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { $isAdminClaim, $unblockBlockedByPlan } from '$stores/$user';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';
import { $activeAppVersion } from '$stores/nav-config-stores';
import {
  $availablePlans,
  $currentRevenue,
  $initBlockFeatureNextPackage,
  $getMinimumPlanForFeatureFlags,
} from '$stores/willy/$subscription';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

interface LockedPageTemplateProps {
  badgeText?: string;
  bannerPageName: string;
  featureName: string;
  mainDescription: string;
  descriptionMaxWidth?: string;
  firstImage: string;
  firstImageSize?: string;
  centerFirstImage?: number;
  firstSectionMarginLeft?: string;
  secondTitle: string;
  secondSectionImage: string;
  secondImageWidth?: string;
  secondImageMarginLeft?: Size;
  sections: Array<{
    subtitle?: string;
    description: string;
  }>;
  secondSectionTitleMargin?: string;
  quote?: string;
  quoteAuthor?: string;
  quoteAuthorTitle?: string;
  quoteAuthorTitleBreak?: boolean;
  quatePadding?: string;
  quateBottomPadding?: string;
  logo?: string;
  quateImage?: string;
  sonarKlaviyoText?: string;
  sonarMetaText?: string;
  marginTopForMobile?: boolean;
  featureFlags: FeatureFlag[];
}
const LockedPageTemplate: React.FC<LockedPageTemplateProps> = ({
  badgeText,
  bannerPageName,
  featureName,
  mainDescription,
  firstImage,
  firstImageSize = '60%',
  centerFirstImage,
  firstSectionMarginLeft = '10%',
  secondTitle,
  secondSectionImage,
  secondImageWidth = '50%',
  secondImageMarginLeft = 0,
  sections,
  secondSectionTitleMargin,
  quote,
  quoteAuthor,
  quoteAuthorTitle,
  quoteAuthorTitleBreak,
  quatePadding = 'px-10',
  quateBottomPadding,
  logo,
  quateImage,
  descriptionMaxWidth = '400px',
  sonarKlaviyoText,
  sonarMetaText,
  marginTopForMobile = false,
  featureFlags,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [revenue, setRevenue] = useState<number | null>(null);
  const [canSeeUpgradeButton, setCanSeeUpgradeButton] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useMutableQueryParams();
  const isSmall = useIsSmall();
  const shop = useAppSelector((state) => state.shop);
  const bannerData = IN_APP_CONTEXT_BANNERS_DATA[bannerPageName];
  const howItWorksLink = bannerData ? bannerData.howItWorksLink : '';
  const [unblockBlockedByPlan, setUnblockBlockedByPlan] = useWritableStore($unblockBlockedByPlan);
  const isAdmin = useStoreValue($isAdminClaim);
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';
  const shopRevenue = useStoreValue($currentRevenue);
  const allPackages = useComputedValue($availablePlans, (x) => x.data?.packages);

  $initBlockFeatureNextPackage.set(true);

  const getMinimumPlanForFeatureFlags = useStoreValue($getMinimumPlanForFeatureFlags);
  const minimumRequirement = useMemo(
    () => getMinimumPlanForFeatureFlags(featureFlags),
    [getMinimumPlanForFeatureFlags, featureFlags],
  );
  const requirementText =
    minimumRequirement?.type === 'package'
      ? `Available in ${minimumRequirement?.name} Plan`
      : `Available with ${minimumRequirement?.name}`;

  useEffect(() => {
    const { revenue } = shop?.annualRevenue || {};
    if (!revenue) return;
    setRevenue(revenue);
    const adminForced = params['gt1-self-upgrade'] === 'true';
    setCanSeeUpgradeButton(adminForced || revenue < 1000000);
  }, [params, shop]);

  const currentPlan = useMemo(
    () => allPackages?.find((pack) => pack.upgradeData?.current_plan),
    [allPackages],
  );

  const logEvent = useCallback(
    (action: string, callToAction?: string) => {
      const pathname = window.location.pathname;

      const eventPayload: Record<string, any> = {
        action,
        gmv: shopRevenue,
        plan: currentPlan?.product_name || 'unknown',
        call_to_action: canSeeUpgradeButton ? 'Upgrade' : 'Book A Demo',
        pathname,
      };

      if (callToAction) {
        eventPayload.location = 'in_page';
        eventPayload.call_to_action = callToAction;
      }
      genericEventLogger(analyticsEvents.UPGRADE, eventPayload);
    },
    [shopRevenue, currentPlan?.product_name, canSeeUpgradeButton],
  );

  useEffect(() => {
    logEvent(upgradeCtaActions.HIT_LOCK_SCREEN);
  }, [logEvent]);

  const bookDemo = async () => {
    setIsLoading(true);
    // if (!revenue) {
    //   logEvent(upgradeCtaActions.HIT_CALL_TO_ACTION_NO_REVENUE, 'Upgrade');
    //   toast.info('We are still calculating your revenue. Please try again in a few minutes.', {
    //     position: 'top-center',
    //     autoClose: false,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    logEvent(upgradeCtaActions.HIT_CALL_TO_ACTION, 'Book A Demo');
    await dispatch(upgradePlanClicked(navigate));
    setIsLoading(false);
  };

  const upgradeClicked = async () => {
    setIsLoading(true);
    // if (!revenue) {
    //   logEvent(upgradeCtaActions.HIT_CALL_TO_ACTION_NO_REVENUE, 'Upgrade');
    //   toast.info('We are still calculating your revenue. Please try again in a few minutes.', {
    //     position: 'top-center',
    //     autoClose: false,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    logEvent(upgradeCtaActions.HIT_CALL_TO_ACTION, 'Upgrade');
    await dispatch(upgradePlanClicked(navigate));
    setIsLoading(false);
  };

  const FirstSection = () => (
    <div className="flex flex-col md:flex-row justify-between w-full">
      <div
        style={{
          marginTop: isSmall ? '40px' : '105px',
          width: isSmall ? '100%' : '50%',
          marginLeft: isSmall ? '0' : firstSectionMarginLeft,
          paddingLeft: isSmall ? '31px' : '0',
          paddingRight: isSmall ? '30px' : '0',
        }}
        className="pl-6 md:pl-0"
      >
        <Flex direction="column" justify="center" gap="sm" mr="md">
          {!!minimumRequirement?.name ? (
            <Badge color="gray.3" bg="white" size="xl" uppercase={false} variant="outline">
              <Text fw={600} size="xs" as="div">
                {requirementText}
              </Text>
            </Badge>
          ) : (
            <div
              style={{
                border: '1px solid var(--tw-color-gray-3)',
                borderRadius: 'var(--tw-radius-md)',
              }}
            >
              <Skeleton height={32} width={193} radius="30px" />
            </div>
          )}
          <Title
            order={isSmall ? 4 : 1}
            fw={isSmall ? 600 : 700}
            color="black"
            lh={isSmall ? '34px' : undefined}
            size="28px"
            maw="500px"
          >
            Unlock {featureName}
          </Title>
          <span style={{ maxWidth: descriptionMaxWidth }}>
            <Text size="m">{mainDescription}</Text>
          </span>
          <div className="mt-6">
            <Flex gap="lg" justify="start">
              {canSeeUpgradeButton ? (
                <Button loading={isLoading} onClick={upgradeClicked} variant="primary">
                  Upgrade
                </Button>
              ) : (
                <Button loading={isLoading} onClick={bookDemo} variant="primary">
                  Book a demo
                </Button>
              )}
            </Flex>
          </div>
        </Flex>
      </div>
      <div
        style={{
          marginTop: '77px',
          width: isSmall ? '100%' : firstImageSize,
          marginRight: centerFirstImage ? `${centerFirstImage}px` : undefined,
        }}
      >
        <img
          src={firstImage}
          alt="Main Section Image"
          className={`w-full ${isSmall ? 'rounded' : ''}`}
        />
      </div>
    </div>
  );
  const SecondSection = () => {
    const isShortContent = sections.length === 1 && sections[0].description.length < 100;
    return (
      <Container
        size="xl"
        className="flex flex-col md:flex-row justify-center w-full p-0"
        style={{ gap: '5vw', flexDirection: isSmall ? 'column' : 'row' }}
      >
        <div
          style={{
            alignItems: isShortContent ? 'flex-start' : 'center',
            paddingLeft: isSmall ? '31px' : '0',
            paddingRight: isSmall ? '30px' : '0',
            order: isSmall ? 1 : 2,
          }}
        >
          <Flex direction="column" w="100%" justify="center" gap={isSmall ? 'sm' : 'lg'}>
            <Title
              order={isSmall ? 4 : 1}
              fw={isSmall ? 600 : 700}
              color="black"
              size={isSmall ? '24px' : '28px'}
              mt={isSmall ? 'xs' : isShortContent ? '20px' : secondSectionTitleMargin}
              mb={isSmall ? '5px' : '-9px'}
              maw="500px"
            >
              {secondTitle}
            </Title>

            {sections.map((section, index) => (
              <div key={index} style={{ maxWidth: '500px' }}>
                <Flex direction="column" mb={isSmall ? 'md' : undefined}>
                  <Text size={isSmall ? 'md' : 'lg'} fw={600} color="black">
                    {section.subtitle}
                  </Text>
                  <Text size="m">
                    {section.description.split('\n').map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Text>
                </Flex>
              </div>
            ))}

            <Flex gap="xs" justify="start">
              {canSeeUpgradeButton ? (
                <Button loading={isLoading} onClick={upgradeClicked} variant="primary">
                  Upgrade
                </Button>
              ) : (
                <Button loading={isLoading} onClick={bookDemo} variant="primary">
                  Book a demo
                </Button>
              )}
            </Flex>
          </Flex>
        </div>

        <div
          style={{
            order: isSmall ? 2 : 1,
            width: isSmall ? '100%' : secondImageWidth,
            marginRight: isSmall ? '0' : secondImageMarginLeft,
          }}
        >
          <Flex w="100%">
            <img src={secondSectionImage} alt="Second Section Image" className="w-full rounded" />
          </Flex>
        </div>
      </Container>
    );
  };

  const SonarSection = () => (
    <div className="flex justify-center gap-11 mt-10">
      <div
        className="bg-white rounded-lg"
        style={{
          padding: '0 0 20px 0',
          width: '590px',
          paddingLeft: isSmall ? '31px' : '0',
          paddingRight: isSmall ? '30px' : '0',
        }}
      >
        <Text size="sm" c="gray.7" fw={500} px="lg" py="xs">
          Sonar for Klaviyo
        </Text>
        <div
          style={{ borderBottom: '1px solid var(--Gray-Light-Mode-100, #F1F3F6)', width: '100%' }}
        />
        <Text size="md" c="black" fw={600} px="lg" pt="xs" pb={0}>
          {sonarKlaviyoText}
        </Text>
      </div>

      <div
        className="bg-white rounded-lg"
        style={{
          padding: '0 0 20px 0',
          width: '590px',
          paddingLeft: isSmall ? '31px' : '0',
          paddingRight: isSmall ? '30px' : '0',
        }}
      >
        <Text size="sm" c="gray.7" fw={500} px="lg" py="xs">
          Sonar for Meta
        </Text>
        <div
          style={{ borderBottom: '1px solid var(--Gray-Light-Mode-100, #F1F3F6)', width: '100%' }}
        />
        <Text size="md" c="black" fw={600} px="lg" pt="xs" pb={0}>
          {sonarMetaText}
        </Text>
      </div>
    </div>
  );

  return (
    <div className="-m-6">
      <div
        style={{
          backgroundColor: '#F9FAFB',
          position: 'relative',
          overflow: 'hidden',
          marginTop: marginTopForMobile && isSmall ? '18px' : '0',
        }}
      >
        <div
          style={{
            background: 'radial-gradient(98.12% 102.99% at 50.04% 0%, #96CCF5 0.71%, #F9FAFB 100%)',
            height: '352px',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundRepeat: 'no-repeat',
            boxSizing: 'border-box',
          }}
        />

        <div
          className="content"
          style={{
            position: 'relative',
            zIndex: 2,
            marginTop: isSmall && !isThreePointOh ? '90px' : 0,
          }}
        >
          {badgeText && howItWorksLink && (
            <InAppVideoBanner title={featureName} videoUrl={howItWorksLink} />
          )}
          {isAdmin && (
            <div className="mt-6 ml-12">
              <Checkbox
                checked={unblockBlockedByPlan}
                onChange={setUnblockBlockedByPlan}
                label="Unblock (tw admin only)"
              />
            </div>
          )}

          <div
            className="flex flex-col gap-14 px-0 mx-0 max-w-full overflow-hidden"
            style={{
              paddingBottom: quateBottomPadding && (isSmall ? '6rem' : '3rem'),
              paddingTop: isSmall ? '5px' : '2rem',
            }}
          >
            <FirstSection />
            <SecondSection />
            {badgeText === 'Sonar' && <SonarSection />}

            <div
              className={`flex flex-col gap-14 ${quatePadding} mx-auto max-w-screen-xl overflow-hidden mb-8`}
            >
              {quote && (
                <div
                  className="bg-white shadow-lg rounded-lg border border-gray-100 w-full"
                  style={{
                    borderRadius: '20px',
                    padding: '4px 60px 30px',
                    background: '#FFF',
                    boxShadow:
                      '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <div className="flex justify-center">
                    <img src={quateImage} alt="Quote Icon" className="mb-6 mt-10" />
                  </div>
                  <div className="max-w-[1100px] mx-auto text-left px-4">
                    <Text size="md" color="gray.8" fs="italic">
                      {quote}
                    </Text>
                  </div>
                  <div className="text-center mt-6 mb-1">
                    <Text size="sm" color="black" fw={500}>
                      {quoteAuthor}
                    </Text>
                  </div>
                  <div className="text-center">
                    <Text size="sm" as="div">
                      {quoteAuthorTitleBreak ? (
                        <>
                          {quoteAuthorTitle?.split(',')[0]}
                          <br />
                          {quoteAuthorTitle?.split(',')[1]}
                        </>
                      ) : (
                        <span style={{ color: 'var(--Neutral-500, #737373)' }}>
                          {quoteAuthorTitle}
                        </span>
                      )}
                    </Text>
                  </div>
                  <div className="flex justify-center mt-6 mb-8">
                    <img src={logo} alt="Company Logo" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedPageTemplate;
