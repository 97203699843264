import { Button, HoverCard, Icon, Menu, Text } from '@tw/ui-components';
import React, { FC, PropsWithChildren } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { useDashContext } from '../dashContext';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';

type WillyEditDashboardMenuProps = {
  addNewWidget: () => Promise<void>;
  onClickImage: () => void;
  onClickText: () => void;
  onClickVideo: () => void;
  activator?: React.ReactNode;
  dropdownPosition?: any;
  dropDownOffset?: number;
  withinPortal?: boolean;
};

type editReportOption = {
  content: string | React.ReactNode;
  onClick: () => void;
  leftSection?: React.ReactNode;
  limited?: boolean;
};

export function MaxSectionsReachedTooltip({ children }: PropsWithChildren) {
  const { $maxWidgetCount } = useDashContext();
  const maxWidgetCount = useStoreValue($maxWidgetCount);

  return (
    <HoverCard shadow="xl" width={400} styles={{ dropdown: { border: 'none' } }} position="right">
      <HoverCard.Target>
        <div>{children}</div>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text fz="sm" c="gray.8" fw={500}>
          You have reached the maximum limit of {maxWidgetCount} sections in this dashboard. Add a
          template or create new dashboards to add sections.{' '}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

export const WillyEditDashboardMenu: FC<WillyEditDashboardMenuProps> = ({
  addNewWidget,
  onClickImage,
  onClickText,
  onClickVideo,
  activator,
  dropdownPosition,
  dropDownOffset,
  withinPortal,
}) => {
  const { $widgetsLeft } = useDashContext();
  const widgetsLeft = useStoreValue($widgetsLeft);

  const menuSections: editReportOption[] = [
    {
      content: 'Add Data Visualization',
      onClick: addNewWidget,
      leftSection: <Icon name="combo-chart" size={16} />,
      limited: true,
    },
    {
      content: 'Add Text',
      onClick: onClickText,
      leftSection: <Icon name="text" size={16} />,
    },
    {
      content: 'Add Image',
      onClick: onClickImage,
      leftSection: <Icon name="image" size={16} />,
    },
    {
      content: 'Add Video',
      onClick: onClickVideo,
      leftSection: <Icon name="play-arrow" size={16} />,
    },
  ];

  return (
    <Menu
      shadow="md"
      withinPortal={withinPortal}
      position={dropdownPosition}
      offset={dropDownOffset}
    >
      <Menu.Target>
        {activator ?? (
          <Button size="xs" leftSection="plus">
            Add Section
          </Button>
        )}
      </Menu.Target>
      <Menu.Dropdown>
        <div>
          {menuSections.map((section, i) => {
            const { content, limited, ...rest } = section;

            return (
              <ConditionalWrapper
                condition={!widgetsLeft && !!limited}
                wrapper={(x) => <MaxSectionsReachedTooltip>{x}</MaxSectionsReachedTooltip>}
              >
                <Menu.Item
                  key={`edit-report-item-${i}`}
                  color="#1F2737"
                  {...rest}
                  disabled={!widgetsLeft && !!limited}
                >
                  {content}
                </Menu.Item>
              </ConditionalWrapper>
            );
          })}
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};
