import { $currentShopId, $msp } from '$stores/$shop';
import { $isSaas } from '$stores/$shop';
import { $dialect, $userId } from '$stores/$user';
import { $derived, $mutableDerived, $mutableObserver, $observer, $store } from '@tw/snipestate';
import { BqColumn, EVENT_DATE } from 'pages/FreeQuery/dataStuff/columns/types';
import { BqTable, CustomColumn } from 'pages/FreeQuery/dataStuff/tables';
import {
  addEventDateColumns,
  formatCustomViews,
  getCustomTables,
  getCustomViews,
} from 'pages/FreeQuery/dataStuff/utils';
import _db, { firestoreRef, toArray } from 'utils/DB';
import {
  SAVED_QUERIES_COLLECTION,
  SAVED_SNIPPETS_COLLECTION,
} from '../../pages/FreeQuery/constants';
import {
  RelatedSchema,
  SavedQuery,
  SqlSnippet,
  type EditorInstance,
} from 'components/Willy/types/willyTypes';
import axiosInstance from 'utils/axiosInstance';
import { DEFAULT_DIALECT } from 'components/Willy/constants';
import { SALES_PLATFORMS } from 'constants/SalesPlatform';

export const $loadingSchema = $store<boolean>(false);
export const $errorSchema = $store<string | null>(null);

export const $regularTables = $observer<BqTable[]>([], async (get, set) => {
  try {
    const currentShopId = get($currentShopId);
    const shopMSP = get($msp);
    const uid = get($userId);
    if (!currentShopId || !uid) return;

    $loadingSchema.set(true);
    const isSaas = get($isSaas);
    const { data } = await axiosInstance.post(
      '/v2/willy/get-tables-schema',
      {
        shopId: currentShopId,
        isSaas,
        includeSnippets: true,
      },
      {},
    );
    const MSP_TEXT = shopMSP ? SALES_PLATFORMS[shopMSP]?.title : shopMSP;

    const tablesWithMSP = data.tables.map((table) => {
      let tablewithMSP = { ...table };
      if (!!table.description) {
        tablewithMSP.description = table.description.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
      }
      const columnsWithMSP = table.columns.map((column) => {
        let columnWithMSP = { ...column };
        if (!!column.description) {
          columnWithMSP.description = column.description.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
        }
        if (!!column.title) {
          columnWithMSP.title = column.title.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
        }
        if (!!column.subTitle) {
          columnWithMSP.subTitle = column.subTitle.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
        }
        if (!!column.name) {
          columnWithMSP.name = column.name.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
        }
        if (!!column.columns) {
          columnWithMSP.columns = column.columns.map((subColumn) => {
            let subColumnWithMSP = { ...subColumn };
            if (!!subColumn.description) {
              subColumnWithMSP.description = subColumn.description.replace(
                /\#\#SHOP\#\#/gm,
                MSP_TEXT,
              );
            }
            if (!!subColumn.title) {
              subColumnWithMSP.title = subColumn.title.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
            }
            if (!!subColumn.subTitle) {
              subColumnWithMSP.subTitle = subColumn.subTitle.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
            }
            if (!!subColumn.name) {
              subColumnWithMSP.name = subColumn.name.replace(/\#\#SHOP\#\#/gm, MSP_TEXT);
            }
            return subColumnWithMSP;
          });
        }

        return columnWithMSP;
      });
      tablewithMSP.columns = columnsWithMSP;
      return tablewithMSP;
    });
    set(tablesWithMSP);
    $errorSchema.set(null);
  } catch (e) {
    $errorSchema.set(e.message || 'Error loading schema.');
  } finally {
    $loadingSchema.set(false);
  }
});

export const $customColumns = $observer<CustomColumn[]>([], (get, set) => {
  if (!get($userId) || !get($currentShopId)) return;

  return _db()
    .collection('willy_custom_metrics_sql')
    .onSnapshot(async (snapshot) => {
      const columns = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          docId: doc.id,
        } as CustomColumn;
      });
      set(columns);
    });
});

export const $customTables = $observer<BqTable[]>([], (get, set) => {
  if (!get($userId)) return;

  const currentShopId = get($currentShopId);
  if (!currentShopId) return;

  return _db()
    .collection('custom_bq_tables')
    .onSnapshot(async (snapshot) => {
      const tables = await getCustomTables(toArray(snapshot), currentShopId);
      set(tables);
    });
});

export const $globalSnippets = $observer<SqlSnippet[]>([], (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;

  return firestoreRef()
    .collection('global_sql_snippets')
    .onSnapshot(async (snapshot) => {
      const snippets = toArray(snapshot);
      set(snippets);
    });
});

export const $snippets = $observer<SqlSnippet[]>([], (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;

  return _db()
    .collection(SAVED_SNIPPETS_COLLECTION)
    .onSnapshot(async (snapshot) => {
      const snippets = toArray(snapshot);
      set(snippets);
    });
});

export const $allSnippets = $mutableDerived((get) => {
  const snippets = get($snippets);
  const globalSnippets = get($globalSnippets);
  const dialect = get($dialect);

  const allSnippets = snippets.concat(globalSnippets);
  // filter by dialect
  const dialectSnippets = allSnippets.filter((s) => {
    if (!s.dialect) {
      return dialect === DEFAULT_DIALECT;
    }
    return s.dialect === dialect;
  });
  //uniq by name
  return dialectSnippets.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
});

export const $customViews = $mutableObserver<BqTable[]>([], (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;

  return _db()
    .collection(SAVED_QUERIES_COLLECTION)
    .onSnapshot((snapshot) => {
      const rawViews = toArray<SavedQuery>(snapshot);
      const viewsWithSchema = getCustomViews(rawViews);
      const customViews = formatCustomViews(viewsWithSchema);
      set(customViews);
    });
});

export const $customTablesAndViews = $derived((get) => {
  const customTables = get($customTables);
  const customViews = get($customViews);
  return customTables.concat(customViews);
});

export function addCustomColumnsToTables(tables: BqTable[], customColumns: CustomColumn[]) {
  const customFieldsFormatted: CustomColumn[] = customColumns.map((customCol) => {
    const col: CustomColumn = {
      clickhouseFormula: customCol.clickhouseFormula,
      // deprecated
      name: customCol.name,
      type: customCol.type,
      title: customCol.title,
      id: customCol.id,
      docId: customCol.docId,
      tableId: customCol.tableId,
      dialect: customCol.dialect || $dialect.get(),
      isCustomColumn: true,
    };

    return col;
  });

  const tablesWithCustomFields: BqTable[] = tables.map((table) => {
    const customFieldsForTable = customFieldsFormatted.filter(
      (customField) => customField.tableId === table.id,
    );
    return {
      ...table,
      columns: table.columns.concat(customFieldsForTable),
    };
  });

  return tablesWithCustomFields;
}

export const $tables = $derived((get): BqTable[] => {
  const regularTables = get($regularTables);
  const customTablesAndViews = get($customTablesAndViews);
  const customColumns = get($customColumns);

  const regularTablesWithCustomColumns = addCustomColumnsToTables(regularTables, customColumns);
  const customTablesWithCustomColumns = addCustomColumnsToTables(
    customTablesAndViews,
    customColumns,
  );
  return regularTablesWithCustomColumns
    .map((t) => {
      return {
        ...t,
        columns: t.columns.map((c) => {
          if (c.id === EVENT_DATE) {
            return {
              ...c,
              columns: addEventDateColumns(),
            };
          }
          return c;
        }),
      };
    })
    .concat(customTablesWithCustomColumns);
});

export const $tabs = $store<EditorInstance[]>([]);
export const $schemaInModal = $store<string>('');

export const $activeQuery = $derived((get) => {
  return get($tabs).find((t) => t.active)?.query;
});

export const $relatedSchema = $derived((get): RelatedSchema[] => {
  return get($tables).map((t) => {
    let columns = [] as string[];
    if (get($activeQuery)?.includes(t.id)) {
      columns = t.columns.map((c) => c.id);
    }
    return {
      table: t.id,
      columns,
    };
  });
});

export const $betaTables = $observer<BqTable[]>([], async (get, set) => {
  const currentShopId = get($currentShopId);
  const uid = get($userId);
  if (!currentShopId || !uid) return;

  const { data } = await axiosInstance.post('/v2/willy/get-beta-tables-schema', {
    shopId: currentShopId,
  });

  set(data.tables);
});

export const $tablesWithBeta = $derived((get): BqTable[] => {
  return get($tables).concat(get($betaTables));
});
