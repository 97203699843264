import { DatePickerHeader } from 'components/headers/DatePickerHeader';
import PodFilterBar from 'routes/allShopsPinnedSections/PodFilterBar/PodFilterBar';
import { ReactComponent as RocketIcon } from 'icons/rocket.svg';
import { NavSection } from '../types';
import { Icon } from '@tw/ui-components';
import { META_HIDDEN_ROUTES } from '@tw/shared-types/module/frontend-config/constants';

export const HIDDEN_SECTION: NavSection = {
  routes: [
    {
      url: '/pods-view',
      label: 'Pods View',
      ...META_HIDDEN_ROUTES['/pods-view'],
      icon: () => (
        <div className="flex">
          <img src={'/pod-icon-wrapper.svg'} width={30} alt={''} />
        </div>
      ),
      header: () => <PodFilterBar />,
      hidden: true,
    },
    {
      url: '/pod-settings',
      label: 'Pod Settings',
      ...META_HIDDEN_ROUTES['/pod-settings'],
      icon: () => <Icon name="settings" />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/all-shops-admin',
      label: 'Pods View',
      ...META_HIDDEN_ROUTES['/all-shops-admin'],
      icon: () => <img src={'/pod-icon-wrapper.svg'} width={30} alt={''} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/upgrade',
      label: 'Upgrade',
      ...META_HIDDEN_ROUTES['/upgrade'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/activities-feed',
      ...META_HIDDEN_ROUTES['/activities-feed'],
      label: 'Activities Feed',
      icon: () => <RocketIcon width={30} />,
      header: () => <DatePickerHeader showGroupByButton={true} showCompareButton={true} />,
      hidden: true,
    },
    {
      url: '/optimizations',
      label: 'Optimizations',
      ...META_HIDDEN_ROUTES['/optimizations'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/user-profile',
      label: 'User Profile',
      ...META_HIDDEN_ROUTES['/user-profile'],
      icon: () => <RocketIcon width={30} />,
      hidden: true,
    },
    {
      url: '/signin',
      label: 'Sign in',
      ...META_HIDDEN_ROUTES['/signin'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup',
      label: 'Sign up',
      ...META_HIDDEN_ROUTES['/signup'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-free',
      label: 'Sign Up Free',
      ...META_HIDDEN_ROUTES['/signup-free'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/custom-signup-free',
      label: 'Sign Up Free',
      ...META_HIDDEN_ROUTES['/custom-signup-free'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-free-new',
      label: 'Sign Up Free New',
      ...META_HIDDEN_ROUTES['/signup-free-new'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-invitation',
      label: 'Signup Invitation',
      ...META_HIDDEN_ROUTES['/signup-invitation'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/oauth2',
      label: 'oauth2',
      ...META_HIDDEN_ROUTES['/oauth2'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/payment',
      label: 'Payment',
      ...META_HIDDEN_ROUTES['/payment'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/welcome',
      label: 'Welcome',
      ...META_HIDDEN_ROUTES['/welcome'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/user-actions',
      label: 'user-actions',
      ...META_HIDDEN_ROUTES['/user-actions'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/share-agent-output',
      label: 'share-agent-output',
      ...META_HIDDEN_ROUTES['/share-agent-output'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/deep-link-rerouter',
      label: 'deep-link-rerouter',
      ...META_HIDDEN_ROUTES['/deep-link-rerouter'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/share-section',
      label: 'Shared Section',
      ...META_HIDDEN_ROUTES['/share-section'],
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/orders',
      label: 'Orders',
      ...META_HIDDEN_ROUTES['/orders'],
      header: () => <DatePickerHeader />,
      hidden: true,
    },
    {
      url: '/workflows',
      label: 'Agents',
      ...META_HIDDEN_ROUTES['/workflows'],
      rawIconName: 'global',
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/connect-account',
      label: 'Connect Account',
      ...META_HIDDEN_ROUTES['/connect-account'],
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/tw-orders',
      label: 'Orders',
      ...META_HIDDEN_ROUTES['/tw-orders'],
      header: () => <DatePickerHeader />,
      hidden: true,
    },
    {
      url: '/playground/moshe' as any,
      label: 'Playground',
      isHybridPage: true,
      isShoplessPage: true,
      header: () => <></>,
      hidden: true,
    },
  ],
};
