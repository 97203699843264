import React, { useState } from 'react';
import { Button } from '@shopify/polaris';
import {
  Icon,
  Anchor,
  Button as TWButton,
  Flex,
  Modal,
  Text,
  useSelectByWindowResize,
} from '@tw/ui-components';
import { ReactComponent as UserPlus } from 'components/Icons/user-plus.svg';
import { analyticsEvents, genericEventLogger, sqwhaleActions } from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';
import { NoCreditsLeftAlert } from './dashboardManagment/template-management/NoCreditsLeftAlert';
import { CreditsUseAlert } from './dashboardManagment/CreditsUseAlert';
import { $currentRevenue, $isFreeShop, $isPackagePlanPreview } from '$stores/willy/$subscription';
import { useNavigate } from 'react-router-dom';
import { useUpgradeButton } from 'components/UpgradePlan/components/useUpgradeButton';
import { $dashboardCredits, $sqlTeamReportCredit } from '$stores/willy/$credits';

type IAskAnSQWhaleExpert = {
  asLink?: boolean;
  activator?: React.ReactNode;
};

export const AskAnSQWhaleExpert: React.FC<IAskAnSQWhaleExpert> = ({
  asLink = false,
  activator,
}) => {
  const { credits, creditsLeft, creditsUsed } = useStoreValue($dashboardCredits);

  const sqlTeamReportCredits = useStoreValue($sqlTeamReportCredit);
  const isPlanPreview = useStoreValue($isPackagePlanPreview);
  const GMV = useStoreValue($currentRevenue);

  const [askModalOpen, setAskModalOpen] = useState(false);
  const isSmall = useSelectByWindowResize(({ width }) => width <= 1024);
  const googleForm = 'https://try.triplewhale.com/ask-an-sql-expert';
  const navigate = useNavigate();
  const isFreeShop = useStoreValue($isFreeShop);
  const upgradeButton = useUpgradeButton('sqlwhale');

  // force disabled state
  const paused = false;

  const handleAskModalOpen = (x: boolean) => {
    setAskModalOpen(x);
    if (x) {
      genericEventLogger(analyticsEvents.SQWHALE, {
        action: sqwhaleActions.ASK_AN_EXPERT,
      });
    }
  };

  return isPlanPreview || GMV < 5e6 ? null : (
    <>
      {asLink ? (
        <div
          className="text-gray-600 cursor-pointer underline text-3xl"
          onClick={() => {
            handleAskModalOpen(!askModalOpen);
          }}
        >
          Build your board with an on-demand SQL Expert{' '}
        </div>
      ) : (
        <span
          className="whitespace-nowrap cursor-pointer"
          onClick={() => {
            handleAskModalOpen(!askModalOpen);
          }}
        >
          {activator ? (
            activator
          ) : (
            <Button icon={UserPlus}>{isSmall ? undefined : 'Ask an SQL Expert'}</Button>
          )}
        </span>
      )}
      <Modal
        size="md"
        padding="lg"
        portalProps={{
          style: {
            zIndex: 99999,
            position: 'relative',
          },
        }}
        opened={askModalOpen}
        title={
          <Flex align="center" justify="start">
            <div className="bg-[#E0F7FF] text-white w-[40px] h-[40px] flex items-center justify-center rounded-full mr-4 shrink-0">
              <UserPlus width="23" height="23" fill="#0B9ED4" />
            </div>
            <Text fz="xl" fw={600}>
              Build with an SQL Expert
            </Text>
          </Flex>
        }
        onClose={() => handleAskModalOpen(false)}
      >
        {paused ? (
          <>
            <div className="flex items-center gap-4 bg-[#f6f9ff] p-6 rounded-md mb-6">
              <Icon name="info" color="one.4" />
              <Text color="one.6" fw="500">
                This service is paused for the next two weeks.
              </Text>
            </div>

            <Text mb="lg">
              Stuck? Pressed for time? Build a Board with one of our SQL experts. Start from
              scratch, troubleshoot issues, or scope your perfect Dashboard and we'll build it for
              you. Here's how it works.
            </Text>
            <Flex align="start" justify="start">
              <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
                <Text size="sm" color="white">
                  1
                </Text>
              </div>
              <Text mb="lg">
                Click below to tell us about your request and upload any supporting files
              </Text>
            </Flex>
            <Flex align="start" justify="start">
              <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
                <Text size="sm" color="white">
                  2
                </Text>
              </div>
              <Text mb="lg">
                We'll review, send you a time estimate and a quote for project completion within 24
                hours
              </Text>
            </Flex>
            <Text mb="lg">Ready to get started?</Text>

            <Flex gap="sm" justify="flex-end" mt="xl">
              <TWButton variant="white" onClick={() => setAskModalOpen(false)}>
                Cancel
              </TWButton>
              <TWButton disabled={true}>Continue</TWButton>
            </Flex>
          </>
        ) : (
          <>
            <Text mb="lg">
              Build your board with top SQL experts. Get your project built, code reviewed, or
              problems solved.
            </Text>
            <Flex align="start" justify="start">
              <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
                <Text size="sm" color="white">
                  1
                </Text>
              </div>
              <Text mb="lg">
                You will be redirected to a Form where you can specify your request, and upload any
                supporting files.
              </Text>
            </Flex>
            <Flex align="start" justify="start">
              <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
                <Text size="sm" color="white">
                  2
                </Text>
              </div>
              <Text mb="lg">
                We will review your request within 24 hours, and give you a time estimate for
                project completion.
              </Text>
            </Flex>
            {credits > 0 && credits !== Infinity ? (
              <div className="mt-4">
                {creditsLeft < sqlTeamReportCredits ? (
                  <NoCreditsLeftAlert creditsLeft={creditsLeft} />
                ) : (
                  <CreditsUseAlert creditsNumber={sqlTeamReportCredits} isCustomReport={true} />
                )}
              </div>
            ) : null}
            <Flex gap="sm" justify="flex-end" mt="xl">
              <TWButton variant="white" onClick={() => setAskModalOpen(false)}>
                Cancel
              </TWButton>
              {credits !== 0 && (
                <Anchor href={googleForm} target="_blank">
                  <TWButton
                    onClick={() => {
                      setAskModalOpen(false);
                    }}
                    disabled={creditsLeft < sqlTeamReportCredits}
                  >
                    Continue
                  </TWButton>
                </Anchor>
              )}
              {credits === 0 && (
                <>
                  <TWButton
                    onClick={() => {
                      upgradeButton.action();
                      handleAskModalOpen(false);
                    }}
                  >
                    {upgradeButton.buttonText}
                  </TWButton>
                </>
              )}
            </Flex>
          </>
        )}
      </Modal>
    </>
  );
};

export const GoogleFormBQDash = ({ storeUrl, dashLink, contactEmail }) => {
  const formId = '1FAIpQLSfVThIXJnYgFDFtfzl-AgT19LcbqgCIpd_s9G2NOv5ZdXpPdw';

  const formData = {
    'entry.45463384': storeUrl,
    'entry.113495514': dashLink,
    'entry.2127686862': `Please help me to migrate this dashboard to the new system, I'm using it a lot, Thanks!`,
    'entry.87858991': contactEmail,
  };

  const baseURL = `https://docs.google.com/forms/d/e/${formId}/viewform`;

  // Construct the prefilled URL
  const prefillURL = `${baseURL}?${new URLSearchParams(formData).toString()}`;

  return (
    <a href={prefillURL} target="_blank" rel="noopener noreferrer">
      click here
    </a>
  );
};
