import { useShowFreeTrialBanner } from 'components/Willy/UpgradePlan/FreeTrialBanner';
import {
  $bannerState,
  useShowNotificationBanner,
} from 'components/bannerNotifications/hooks/useShowNotificationBanner';
import { useIsSmall } from './useDefaultWindowSizes';

export function useShowGlobalBanner() {
  const { shouldShowBanner, globalBanner } = useShowNotificationBanner();
  const isGlobalBannerVisible = $bannerState.get().isGlobalBannerVisible;

  return shouldShowBanner && globalBanner && isGlobalBannerVisible;
}

export function useGlobalBannerHeight() {
  const showFreeTrialBanner = useShowFreeTrialBanner();
  const { shouldShowBanner, globalBanner } = useShowNotificationBanner();
  const isGlobalBannerVisible = $bannerState.get().isGlobalBannerVisible;
  const isSmall = useIsSmall();

  if (!isGlobalBannerVisible) {
    return 0;
  }

  if (showFreeTrialBanner) {
    return isSmall ? 130 : 48;
  }

  if (shouldShowBanner && globalBanner) {
    return isSmall ? 130 : 48;
  }

  return 0;
}
