import { $unreadMobyFeedItemCount } from '$stores/willy/$mobyFeed';
import { useStoreValue } from '@tw/snipestate';
import { NavSectionRoute } from 'constants/routes/types';

export const UnreadAgentMessages = ({
  route,
  selected,
  hideOnSelected,
  absolute,
}: {
  selected?: boolean;
  route?: NavSectionRoute;
  hideOnSelected?: boolean;
  absolute?: boolean;
}) => {
  const unread = useStoreValue($unreadMobyFeedItemCount);

  return (
    !!unread &&
    (route ? route.label === 'Moby' : true) &&
    (hideOnSelected ? !selected : true) && (
      <div
        className={`${absolute ? 'absolute top-1 right-1' : 'relative'} text-white text-[8px] font-semibold w-[15px] h-[15px] flex items-center justify-center rounded-full bg-[var(--mantine-color-one-4)]`}
      >
        {unread}
      </div>
    )
  );
};
