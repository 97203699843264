import { type AnalyticsObjectType } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';

export const getSourceExternalLink = (
  sourceId: string,
  entity: AnalyticsObjectType,
  adAccountId: string,
  campaignId?: string,
  adsetId?: string,
  adId?: string,
): string | null => {
  if (sourceId === 'facebook-ads') {
    adAccountId = adAccountId?.replace('act_', '');
  }
  const sourcesMap: Partial<Record<ServicesIds, Partial<Record<AnalyticsObjectType, string>>>> = {
    'facebook-ads': {
      adAccount: `https://www.facebook.com/adsmanager/manage/campaigns?act=${adAccountId}`,
      campaign: `https://www.facebook.com/adsmanager/manage/campaigns?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E%22${campaignId}%22&selected_campaign_ids=${campaignId}`,
      adset: `https://www.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E%22${adsetId}%22&selected_adset_ids=${adsetId}`,
      ad: `https://www.facebook.com/adsmanager/manage/ads?act=${adAccountId}&filter_set=SEARCH_BY_ADGROUP_IDS-STRING_SET%1EANY%1E%5B%22${adId}%22%5D&selected_ad_ids=${adId}`,
    },
    'google-ads': {
      adAccount: `https://adwords.google.com/aw/overview?__e=${adAccountId}`,
      campaign: `https://adwords.google.com/aw/overview?__e=${adAccountId}&campaignId=${campaignId}`,
    },
    'tiktok-ads': {
      adAccount: `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${adAccountId}`,
      campaign: `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${adAccountId}&keyword=${campaignId}`,
      adset: `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${adAccountId}&keyword=${adsetId}&search_type=2`,
      ad: `https://ads.tiktok.com/i18n/perf/creative?aadvid=${adAccountId}&keyword=${adId}&search_type=3`,
    },
    'pinterest-ads': {
      adAccount: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/campaigns`,
      campaign: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/adgroups/?campaignIds=[${campaignId}]`,
      adset: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/ads/?adGroupIds=[${adsetId}]`,
      ad: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/ads/?pinPromotionIds=[${adId}]`,
    },
    'snapchat-ads': {
      adAccount: `https://ads.snapchat.com/${adAccountId}`,
      campaign: `https://ads.snapchat.com/${adAccountId}/campaigns/${campaignId}`,
      adset: `https://ads.snapchat.com/${adAccountId}/adsets/${adsetId}`,
      ad: `https://ads.snapchat.com/${adAccountId}/ads/${adId}`,
    },
  };

  if (!sourceId || !entity || !adAccountId) {
    return null;
  }

  if (entity === 'ad' && !adId) {
    return null;
  }

  if (entity === 'adset' && !adsetId) {
    return null;
  }

  if (entity === 'campaign' && !campaignId) {
    return null;
  }

  if (entity === 'adAccount' && !adAccountId) {
    return null;
  }

  return sourcesMap[sourceId]?.[entity];
};
