import { ActionIcon, Icon, Loader, Popover, Text } from '@tw/ui-components';
import { AgentEntityPropsOptions, Column, ColumnName } from './Willy/types/willyTypes';
import { Divider, Tooltip } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { INTERVAL_OPTIONS } from './Willy/constants';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  $aiColumnsActiveWorkflows,
  $aiColumnsLoading,
  cancelRunningWorkflow,
  runAiColumnWorkflow,
  $selectedAiColumn,
  $shopSequences,
} from '$stores/willy/$sequences';
import { $currentShopId } from '$stores/$shop';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { ServicesIds } from '@tw/types/module/services';

type AiColumnHeaderProps = {
  column: Column<ColumnName>;
  serviceId: ServicesIds;
};

export const AiColumnHeader: React.FC<AiColumnHeaderProps> = ({ column, serviceId }) => {
  const [selectedColumn, setSelectedColumn] = useWritableStore($selectedAiColumn);
  const activeWorkflowIds = useStoreValue($aiColumnsActiveWorkflows);
  const [loadingColumns, setLoadingColumns] = useWritableStore($aiColumnsLoading);
  const currentShopId = useStoreValue($currentShopId);

  const shopSequences = useStoreValue($shopSequences);

  const sequenceIds = column.sequences
    ?.filter((s) => s.serviceId === serviceId || s.serviceId === 'all')
    ?.map((s) => s.sequenceId);

  const currentSequences = shopSequences.filter((x) => sequenceIds?.includes(x.id));

  const { description } = column;

  return (
    <div className="overflow-hidden flex-auto">
      <div className="flex items-center justify-start gap-2 group">
        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-75 flex items-center justify-center">
          <div
            onClick={() => {
              setSelectedColumn?.(column.key);
              genericEventLogger(analyticsEvents.SEQUENCES, {
                action: sequencesActions.EDIT_COLUMN_AGENT,
                column_id: column.key,
                column_name: column.label,
                column_category: column.category,
              });
            }}
          >
            <div className="flex items-center justify-center">
              <ActionIcon icon="new-edit" iconSize={10} />
            </div>
          </div>
        </div>
        <Popover>
          <Popover.Target>
            <div
              className="flex items-center justify-center"
              onClick={() => {
                genericEventLogger(analyticsEvents.SEQUENCES, {
                  action: sequencesActions.VIEW_COLUMN_AGENT_DESCRIPTION,
                  column_id: column.key,
                  column_name: column.label,
                  column_category: column.category,
                });
              }}
            >
              <ActionIcon icon="star-plus" iconSize={10} color="one.6" />
            </div>
          </Popover.Target>
          <Popover.Dropdown maw="300px">
            <div className="flex flex-col gap-2">
              {!!description && (
                <>
                  <Text size="xs">{description}</Text>
                  <Divider />
                </>
              )}
              {!!currentSequences.length && (
                <div className="flex flex-col gap-2">
                  <Text size="xs" fw="bold">
                    <div className="flex flex-col gap-2">
                      Agents:{' '}
                      {currentSequences.map((s) => {
                        const relation = column.sequences?.find((x) => x.sequenceId === s.id);
                        const forEntity = AgentEntityPropsOptions[relation?.entity || 'all'];
                        const suffix = forEntity.value === 'all' ? '' : 'entity';
                        return (
                          <div className="flex flex-col" key={s.id}>
                            {loadingColumns?.[column.key]?.[s.id] && <Loader size="xs" />}
                            <Link
                              target="_blank"
                              to={`/workflows/${s.id}`}
                              className="break-words text-primary "
                            >
                              <span>{s.name}</span>
                            </Link>
                            <span>
                              for {forEntity.label} {suffix} (Last Run:{' '}
                              {s.lastRunAt?.toDate?.().toLocaleString()})
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Text>
                  <Divider />
                  {currentSequences.map((s) => (
                    <div className="flex flex-col gap-2" key={s.id}>
                      {!!s.schedule?.days?.length && !!s.schedule?.hours?.length && (
                        <Text size="xs">
                          This column will update on:{' '}
                          <span className="text-wrap break-words">
                            <span className="font-bold">
                              {s.schedule?.days?.map((d) => capitalize(d)).join(', ')}{' '}
                            </span>
                            <br />
                            <span>At</span>{' '}
                            <span className="font-bold">
                              {s.schedule?.hours
                                ?.map((h) => h.toString().padStart(2, '0') + ':00')
                                .join(', ')}
                            </span>
                          </span>
                        </Text>
                      )}
                      {!!s.schedule?.interval && (
                        <>
                          <Text size="xs">
                            This column will update every:{' '}
                            {INTERVAL_OPTIONS[s.schedule?.interval].label || s.schedule?.interval}
                          </Text>
                        </>
                      )}
                    </div>
                  ))}
                  {!currentSequences.length && (
                    <Text size="xs">This column is not scheduled yet</Text>
                  )}
                </div>
              )}
            </div>
          </Popover.Dropdown>
        </Popover>
        <div className="flex-auto">{column.label}</div>
        <div className="flex items-center justify-center overflow-hidden">
          <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-75 flex items-center justify-center">
            {!!sequenceIds?.length &&
              !!loadingColumns?.[column.key]?.[sequenceIds?.[0]] &&
              !activeWorkflowIds?.[column.key]?.length && <Loader size="xs" />}
            {!!sequenceIds?.length && (
              <ActionIcon
                icon={loadingColumns?.[column.key]?.[sequenceIds?.[0]] ? 'pause' : 'button-play'}
                iconSize={10}
                onClick={async () => {
                  if (!sequenceIds?.length || !currentShopId) {
                    return;
                  }
                  if (loadingColumns?.[column.key]?.[sequenceIds?.[0]]) {
                    cancelRunningWorkflow?.(column.key, currentShopId);
                    setLoadingColumns?.((old) => ({
                      ...old,
                      [column.key]: {
                        ...old[column.key],
                        [sequenceIds?.[0]]: false,
                      },
                    }));
                    return;
                  }
                  setLoadingColumns?.((old) => ({
                    ...old,
                    [column.key]: {
                      ...old[column.key],
                      [sequenceIds?.[0]]: true,
                    },
                  }));
                  genericEventLogger(analyticsEvents.SEQUENCES, {
                    action: sequencesActions.RUN_COLUMN_AGENT,
                    column_id: column.key,
                    column_name: column.label,
                    column_category: column.category,
                  });
                  await runAiColumnWorkflow(column);
                }}
              />
            )}
          </div>
        </div>
        {!!column.error && (
          <Tooltip content={column.error}>
            <div className="flex items-center justify-center">
              <Icon color="red.4" name="info" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
