import { Message } from '../types/willyTypes';

export const deepSearchChatMessages = (messages: Message[]) => {
  // Find all StepsPlanner messages and categorize them
  const plannerMessages: Array<{
    index: number;
    isCompleted: boolean;
  }> = [];
  const finalReportIndices: number[] = [];

  messages.forEach((message, index) => {
    if (message.toolResults?.name === 'StepsPlanner') {
      const allStepsCompleted =
        message.toolResults?.stepsPlan?.steps?.length > 0 &&
        message.toolResults?.stepsPlan?.steps.every((s) => s.step_status === 'completed');

      plannerMessages.push({
        index,
        isCompleted: allStepsCompleted,
      });
    }

    if (message.toolResults?.name === 'FinalReport') {
      finalReportIndices.push(index);
    }
  });

  // If no StepsPlanner messages found, return all messages
  if (plannerMessages.length === 0) {
    return messages;
  }

  // Sort planners by their index
  plannerMessages.sort((a, b) => a.index - b.index);

  // Find regions to filter out - between in-progress planners and completed ones
  const regionsToFilter: Array<{ start: number; end: number }> = [];

  for (let i = 0; i < plannerMessages.length; i++) {
    const current = plannerMessages[i];

    // If this is an in-progress planner
    if (!current.isCompleted) {
      let endIndex: number | null = null;

      // Look for next completed planner
      for (let j = i + 1; j < plannerMessages.length; j++) {
        if (plannerMessages[j].isCompleted) {
          endIndex = plannerMessages[j].index;
          break;
        }
      }

      // If no completed planner found, check final reports
      if (endIndex === null) {
        const nextFinalReport = finalReportIndices.find((idx) => idx > current.index);
        if (nextFinalReport !== undefined) {
          endIndex = nextFinalReport;
        } else {
          // If still no endpoint found, hide everything from this point to the end
          endIndex = messages.length;
        }
      }

      // If we found an endpoint, add this region to filter
      if (endIndex !== null) {
        regionsToFilter.push({
          start: current.index,
          end: endIndex,
        });
      }
    }
  }

  // Find the first StepsPlanner that has any non-pending steps (execution has started)
  const firstExecutingPlannerIndex = messages.findIndex(
    (message) =>
      message.toolResults?.name === 'StepsPlanner' &&
      message.toolResults?.stepsPlan?.steps?.length > 0 &&
      message.toolResults?.stepsPlan?.steps.some((s) => s.step_status !== 'pending'),
  );

  // Now filter the messages
  return messages.filter((message, index) => {
    // Always keep user messages
    if (message.role === 'user') {
      return true;
    }

    // Always hide FinalReport messages
    if (message.toolResults?.name === 'FinalReport') {
      return false;
    }

    // Check if this is a StepsPlanner before a FinalReport
    if (message.toolResults?.name === 'StepsPlanner') {
      // Find the next FinalReport after this StepsPlanner
      const nextFinalReportIndex = finalReportIndices.find((idx) => idx > index);

      // If there's a FinalReport after this StepsPlanner, hide this StepsPlanner
      // unless it's the very last StepsPlanner in the entire conversation
      if (
        nextFinalReportIndex !== undefined &&
        index !== plannerMessages[plannerMessages.length - 1].index
      ) {
        return false;
      }

      // Only show initial planners (all pending), completed planners, or the very last one
      const isLastPlanner = index === plannerMessages[plannerMessages.length - 1].index;

      const isCompleted =
        message.toolResults?.stepsPlan?.steps?.length > 0 &&
        message.toolResults?.stepsPlan?.steps.every((s) => s.step_status === 'completed');

      // Check if this is an initial planner with all pending steps
      const isInitialPending =
        message.toolResults?.stepsPlan?.steps?.length > 0 &&
        message.toolResults?.stepsPlan?.steps.every((s) => s.step_status === 'pending');

      return isLastPlanner || isCompleted || isInitialPending;
    }

    // Find the first in-progress StepsPlanner (not completed and not all pending)
    const firstInProgressPlannerIndex = plannerMessages.find((p) => {
      // Get the message at this index
      const plannerMessage = messages[p.index];

      // Check if it's not completed and not all pending
      const isAllPending =
        plannerMessage.toolResults?.name === 'StepsPlanner' &&
        plannerMessage.toolResults?.stepsPlan?.steps?.length > 0 &&
        plannerMessage.toolResults?.stepsPlan?.steps.every((s) => s.step_status === 'pending');

      return !p.isCompleted && !isAllPending;
    })?.index;

    // Show all messages before the first in-progress StepsPlanner
    if (firstInProgressPlannerIndex !== undefined && index < firstInProgressPlannerIndex) {
      return true;
    }

    // If we haven't found a planner that's started executing, or this message is before it, show it
    if (firstExecutingPlannerIndex === -1 || index <= firstExecutingPlannerIndex) {
      return true;
    }

    // Check if this message falls within any filtered region
    for (const region of regionsToFilter) {
      if (index > region.start && index < region.end) {
        return false;
      }
    }

    // By default, show the message
    return true;
  });
};

export const deepSearchReasoningMessages = (messages: Message[]) => {
  if (!messages || !messages.length) {
    return [];
  }

  // Find all StepsPlanner and FinalReport indices
  const stepsPlannerIndices: number[] = [];
  const finalReportIndices: number[] = [];

  messages.forEach((message, index) => {
    // Find StepsPlanner messages with in-progress or completed steps
    if (
      message.toolResults?.name === 'StepsPlanner' &&
      message.toolResults?.stepsPlan?.steps.find(
        (s) => s.step_status === 'in-progress' || s.step_status === 'completed',
      )
    ) {
      stepsPlannerIndices.push(index);
    }
    // Find FinalReport messages
    if (message.toolResults?.name === 'FinalReport') {
      finalReportIndices.push(index);
    }
  });

  if (stepsPlannerIndices.length === 0) {
    return [];
  }

  // Pair each StepsPlanner with its corresponding FinalReport
  const sequencePairs: { start: number; end: number }[] = [];
  for (let i = 0; i < stepsPlannerIndices.length; i++) {
    const stepsPlannerIndex = stepsPlannerIndices[i];
    // Find the first FinalReport that comes after this StepsPlanner
    const correspondingFinalReport = finalReportIndices.find((index) => index > stepsPlannerIndex);

    // If no FinalReport found, use the end of the array
    const endIndex =
      correspondingFinalReport !== undefined ? correspondingFinalReport : messages.length - 1;

    // Only add the sequence if it doesn't overlap with the previous one
    if (
      sequencePairs.length === 0 ||
      stepsPlannerIndex > sequencePairs[sequencePairs.length - 1].end
    ) {
      sequencePairs.push({
        start: stepsPlannerIndex,
        end: endIndex,
      });
    }
  }

  // Collect all messages that fall within any of the sequence pairs
  // but filter out StepsPlanner, FinalReport, and user messages
  const resultMessages: Message[] = [];

  sequencePairs.forEach((pair) => {
    const messagesInSequence = messages
      .slice(pair.start, pair.end + 1)
      .filter(
        (m) =>
          m.toolResults?.name !== 'StepsPlanner' &&
          m.toolResults?.name !== 'FinalReport' &&
          m.role !== 'user',
      )
      .reduce((acc: Message[], message: Message) => {
        if (acc.find((m) => m.id === message.id)) {
          return acc;
        }

        return [...acc, message];
      }, []);

    resultMessages.push(...messagesInSequence);
  });

  return resultMessages;
};

export const mostRecentFinalReport = (messages: Message[]) => {
  return messages.findLast((m) => m.toolResults?.name === 'FinalReport') ?? null;
};
