import { $currentShopId } from '$stores/$shop';
import {
  $availablePlans,
  $currentPlanName,
  $currentRevenue,
  $isFreeShop,
  $isPackagePlanPreview,
} from '$stores/willy/$subscription';
import { openBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { setUpgradeClickSource } from 'ducks/signupJourney';
import { upgradePixelClosed } from 'ducks/subscription';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAppDispatch } from 'index';
import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';
import formatPrice from 'utils/formatPrice';
import { toast } from 'react-toastify';

import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Flex, Group, Icon, List, Modal, Size, Text } from '@tw/ui-components';

import upgradeAttributionImg from '../../../images/upgradeAttribution.png';
import { NotificationType } from '../../bannerNotifications/types';
import moment from 'moment';
import { subscriptionTypes } from '@tw/types/module/services/subscription-manager';
import {
  $bannerState,
  triggerBannerRefetch,
} from 'components/bannerNotifications/hooks/useShowNotificationBanner';

interface UpgradeSource {
  source: string;
  notificationType?: NotificationType;
}

export const UpgradeGrowthModal = () => {
  const [availablePrices, setAvailablePrices] = useState<string[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const isSmall = useIsSmall();
  const [loading, setLoading] = useState(false);
  const { isModalOpen, source } = useSelector((state: RootState) => state.upgradePixelModalOpen);
  const dispatch = useAppDispatch();
  const GMV = useStoreValue($currentRevenue);
  const navigate = useNavigate();
  const isProd = useSelector((state: RootState) => state.isProd);
  const GROWTH_PLAN = isProd ? 'prod_NwQaYvRzJywYyL' : 'prod_Nr7LOirfdEJMXs';
  const allPackages = useComputedValue($availablePlans, (x) => x.data?.packages);
  const growthPackage = allPackages?.find(
    (x) => x.product_id === GROWTH_PLAN && x.billing_interval === 'month',
  );
  const isSpecialOffer =
    typeof source === 'object' &&
    (source as UpgradeSource)?.notificationType === NotificationType.SPECIAL_OFFER;

  const isFreeShop = useStoreValue($isFreeShop);
  const shopPlan = useStoreValue($currentPlanName);
  const globalBanner = useStoreValue($bannerState);
  const isPackagePlanPreview = useStoreValue($isPackagePlanPreview);

  const growthPrice = growthPackage?.price ? formatPrice(Number(growthPackage.price)) : null;

  const getPlans = async () => {
    try {
      const url = `v2/subscription-manager/plans`;
      const { data } = await axiosInstance.get(url);
      const shopUrl = `/v2/subscription-manager/subscriptions/shop/${$currentShopId.get()}`;
      const subscription = (await axiosInstance.get(shopUrl)).data;
      const previewItem = subscription.items?.find(
        (item) => item.plan_preview_start_date && item.product_id && item.product_name,
      );
      if (previewItem) {
        setAvailablePrices([previewItem.price_id]);
        setSelectedProductId(previewItem.product_id);
      } else {
        const matchingPlans = data.filter(
          (plan) =>
            plan.product_type === 'package' &&
            plan.billing_interval === 'month' &&
            +plan.price == 0,
        );

        const targetPlanName =
          typeof source === 'object' && 'source' in source
            ? formatPlanName((source as UpgradeSource).source)
            : formatPlanName(source as string);
        const targetPackage = matchingPlans.find(
          (plan) => plan.product_name?.toLowerCase() === targetPlanName?.toLowerCase(),
        );

        if (targetPackage) {
          setAvailablePrices([targetPackage.price_id]);
          setSelectedProductId(targetPackage.product_id);
        } else {
          console.log('No matching plan found for target plan:', targetPlanName);
        }
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getPlans();
    }
  }, [isModalOpen]);

  const handleStartTrial = async () => {
    setLoading(true);
    try {
      if (isPackagePlanPreview) {
        toast.error('You already have an active trial');
        dispatch(upgradePixelClosed());
        return;
      }
      genericEventLogger(analyticsEvents.UPGRADE, {
        action: upgradeCtaActions.HIT_CALL_TO_ACTION,
        pathname: location.pathname,
        plan: shopPlan,
        offer: formatPlanName(source),
        call_to_action: upgradeCtaActions.START_FREE_TRIAL,
        referring_upgrade_click: {
          notificationType: (source as UpgradeSource)?.notificationType,
          headerText: globalBanner?.globalBanner?.header_text,
          notificationId: globalBanner?.globalBanner?.notification_id,
        },
        isFoundersDash: isFreeShop,
        gmv: GMV,
      });
      if (!availablePrices.length) {
        toast.error('No package found for your revenue tier');
        return;
      }

      await axiosInstance.post('/v2/subscription-manager/subscriptions/start-trial-preview', {
        shopId: $currentShopId.get(),
        priceId: availablePrices[0],
        metadata: { source: 'banner_trial_offer' },
      });

      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 14);

      toast.success(
        `Congratulations! Your free trial started today (${startDate.toLocaleDateString()}) and will end on ${endDate.toLocaleDateString()}`,
      );

      dispatch(upgradePixelClosed());
      $bannerState.set({
        globalBanner: null,
        inPageBanner: null,
        hasFetched: true,
        isGlobalBannerVisible: true,
        needsRefetch: false,
      });
    } catch (error) {
      console.error('Error starting trial:', error);
      toast.error('Failed to start trial');
    } finally {
      setLoading(false);
    }
  };

  const upgrade = useCallback(async () => {
    genericEventLogger(analyticsEvents.UPGRADE, {
      action: upgradeCtaActions.HIT_CALL_TO_ACTION,
      pathname: location.pathname,
      plan: 'Founders Dash',
      offer: 'Growth Plan',
      call_to_action: GMV > 1000000 ? upgradeCtaActions.BOOK_DEMO : analyticsEvents.UPGRADE,
      referring_upgrade_click: source,
      isFoundersDash: isFreeShop,
      gmv: GMV,
    });

    if (GMV < 1000000) {
      dispatch(upgradePixelClosed());
      dispatch(setUpgradeClickSource(source));
      navigate({
        pathname: `/store-settings/plans`,
        search: `&selectedProduct=${selectedProductId}`,
      });
    } else {
      dispatch(upgradePixelClosed());
      openBookDemoModal('pricing');
      await axiosInstance.post('/v2/bi/hubspot-update', {
        source: 'UPDATE_COMPANY',
        shopDomain: $currentShopId.get(),
        demo_source: source,
      });
    }
  }, [GMV, selectedProductId, dispatch, isFreeShop, navigate, source]);

  const formatPlanName = (planType: string | UpgradeSource) => {
    if (!planType) {
      return 'Growth';
    }

    if (typeof planType === 'object') {
      return formatPlanName(planType.source);
    }

    if (planType.includes('_')) {
      return (
        planType.split('_')[0].charAt(0).toUpperCase() +
        planType.split('_')[0].slice(1).toLowerCase()
      );
    }

    if (planType.includes('PLAN')) {
      return planType
        .split(' PLAN')[0]
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    return 'Growth';
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => dispatch(upgradePixelClosed())}
      size={isSmall ? ('95%' as Size) : ('65%' as Size)}
      padding={0}
      withCloseButton={false}
    >
      <Modal.Header
        bg="green"
        mih={250}
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div className="absolute top-[10px] right-[10px]">
          <Modal.CloseButton />
        </div>
        <Flex justify="space-between" align="flex-start" gap="xl" w="100%">
          <Flex direction="column" gap="md" ml={'xl'} w={`${isSmall ? '60%' : '40%'}`}>
            <Text fw={800} color="white" size={'xl'} mt={15}>
              {isSpecialOffer
                ? `14-Day Free Trial on Our ${formatPlanName(source)} Plan!`
                : typeof source === 'object'
                  ? String((source as UpgradeSource).source).includes('PLAN')
                    ? `Upgrade to ${formatPlanName((source as UpgradeSource).source)} Plan to Unlock More Features`
                    : `Upgrade to ${formatPlanName((source as UpgradeSource).source)} Plan to Unlock ${
                        (source as UpgradeSource).source === 'Campaign Attribution'
                          ? 'Campaign and Ad Level Data'
                          : `More ${(source as UpgradeSource).source}`
                      }`
                  : String(source).includes('PLAN')
                    ? `Upgrade to ${formatPlanName(source)} Plan to Unlock More Features`
                    : `Upgrade to ${formatPlanName(source)} Plan to Unlock ${
                        source === 'Campaign Attribution'
                          ? 'Campaign and Ad Level Data'
                          : `More ${source}`
                      }`}
            </Text>
            <Text size={'sm'} color="white">
              Gain the tools you need to track profitability and optimize marketing performance
              across all your key channels.
            </Text>
            {!!GMV && GMV < 1000000 && growthPrice && formatPlanName(source) === 'Growth' && (
              <Flex gap={'xs'} align={'center'}>
                <Text size={'xl'} fw={800} color="white">
                  ${growthPrice}
                </Text>
                <Text size={'sm'} color="white">
                  /month
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Modal.Header>

      <Flex justify="space-between" mt={'xl'}>
        <div className="ml-5">
          <Flex direction="column" gap="md" ml={'lg'}>
            <div>
              <Text size="lg" fw={500}>
                Attribution
              </Text>
              <List>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Multi-touch attribution via Triple Pixel
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  7 attribution models
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Campaign and ad level analysis
                </List.Item>
              </List>
            </div>

            <div>
              <Text size="lg" fw={500}>
                Business Intelligence
              </Text>
              <List>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Core Dashboards
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Automated Reports
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Custom Metric Builder
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  And much more...
                </List.Item>
              </List>
            </div>

            <Group justify="left" pt={20} pb={20}>
              <Button
                onClick={() => {
                  if (isSpecialOffer) {
                    handleStartTrial();
                  } else {
                    upgrade();
                  }
                }}
                loading={loading}
                disabled={loading}
              >
                {isSpecialOffer
                  ? 'Start Free Trial'
                  : GMV < 1000000
                    ? `Get ${formatPlanName(source)}`
                    : 'Book a Demo'}
              </Button>
            </Group>
          </Flex>
        </div>
        {!isSmall && (
          <div
            className="mt-[-60px]"
            style={{
              transform: 'translateY(-100px)',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <img
              src={upgradeAttributionImg}
              alt="Upgrade Attribution"
              className="max-w-[80%] ml-40"
            />
          </div>
        )}
      </Flex>
    </Modal>
  );
};
