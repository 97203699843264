import React from 'react';
import { SALES_PLATFORMS } from 'constants/SalesPlatform';
import SalesPlatformIcon from 'components/SalesPlatform/SalesPlatformIcon';
import { Role } from './constants';
import { Modal } from '@shopify/polaris';

const ShopUI = ({ shop }) => (
  <div
    className="rounded-md items-center p-4 flex-container gap-1 m-2"
    style={{ backgroundColor: `${SALES_PLATFORMS[shop.msp].backgroundColor}30` }}
  >
    <SalesPlatformIcon mspName={shop.msp} small />
    <a
      href={`https://${shop.shopId}/${SALES_PLATFORMS[shop.msp].adminPath ?? ''}`}
      target="_blank"
      rel="noreferrer"
      className="overflow-hidden text-ellipsis"
    >
      <span>{shop.shopName ?? shop.shopId}</span>
    </a>
  </div>
);
export const ROLE_OPTIONS: Array<{ label: string; value: Role }> = [
  {
    label: 'Admin',
    value: Role.Admin,
  },
  {
    label: 'User',
    value: Role.User,
  },
];

interface InvitePodModalProps {
  email: string;
  entityName: string;
  selectedPod: any;
  shopsNotBelongToWorkspace: any[];
  isOpen: boolean;
  onClose: () => void;
  onInvite: () => void;
  isInviteLoading: boolean;
}

const InvitePodModal: React.FC<InvitePodModalProps> = ({
  email,
  entityName,
  selectedPod,
  shopsNotBelongToWorkspace,
  isOpen,
  onClose,
  onInvite,
  isInviteLoading,
}) => {
  return (
    <Modal
      title={`Invite ${email} to Pod ${entityName}`}
      open={isOpen}
      primaryAction={{
        loading: isInviteLoading,
        content: 'Invite',
        onAction: onInvite,
      }}
      onClose={onClose}
    >
      <Modal.Section>
        <p>
          <div>
            You are inviting <a href={`mailto:${email}`}>{email}</a> to the following Pod:{' '}
            <b>{entityName}</b>
          </div>
        </p>
        <br />
        <p>{`This will give access to the following shops:`}</p>
        <br />
        {(selectedPod.isAgancyPod
          ? selectedPod.shops
          : selectedPod.shops?.filter((x) => x.isBelongToWorkspace)
        )?.map((shop, index) => (
          <div key={`shopui1-${index}`}>
            <ShopUI shop={shop} />
          </div>
        ))}
        {!!shopsNotBelongToWorkspace?.length && (
          <div className={'my-10'}>
            <div>
              The following shops will not be shared, because they were not originally created
              within this Pod.
            </div>
            <br />
            {shopsNotBelongToWorkspace.map((shop, index) => (
              <div key={`shopui2-${index}`}>
                <ShopUI shop={shop} />
              </div>
            ))}
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default InvitePodModal;
