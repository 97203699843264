import { $currentShopId } from '$stores/$shop';
import { $socket } from '$stores/$socket';
import { $agentsToWatch, removeAgentToWatch } from '$stores/willy/$agentsToWatch';
import { $mobyFeed } from '$stores/willy/$mobyFeed';
import { $shopSequences } from '$stores/willy/$sequences';
import { addToNewlyRunAgentRuns } from '$stores/willy/$newlyRunAgents';
import { useStoreValue } from '@tw/snipestate';
import { Text } from '@tw/ui-components';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';

//DO NOT IMPORT THIS HOOK, IT IS IMPORTABLE ONLY IN THE AGENT TOAST COMPONENT
export const useAgentSocketWatch = (): void => {
  const location = useLocation();

  const socket = useStoreValue($socket);
  const currentShopId = useStoreValue($currentShopId);
  const shopSequences = useStoreValue($shopSequences);
  const agentsToWatch = useStoreValue($agentsToWatch);
  const mobyFeedItems = useStoreValue($mobyFeed);
  const { userNotificationSettings, shopNotificationSettings, loadingSettings } = useSelector(
    (state: RootState) => state.notificationSettings,
  );
  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();

  const onAgentFinishedRunning = useCallback(
    (data) => {
      const { sequenceId, runId } = data;
      const sequence = shopSequences.find((s) => s.id === sequenceId);
      if (!sequence) {
        return;
      } else {
        if (mobyFeedItems.some((item) => item.sequenceId === sequenceId)) {
          addToNewlyRunAgentRuns(sequenceId);
        }
        if (
          agentsToWatch.some(
            (item) => item.sequenceId === sequenceId && item.source !== location.pathname,
          )
        ) {
          const showToast = () => {
            const toastId = toast(
              <div
                className="cursor-pointer"
                onClick={() => {
                  toast.dismiss(toastId);
                  navigate(`/workflows/view/${sequenceId}/${runId}`);
                }}
              >
                <Text fw={600} fz={14}>
                  New Agent Run
                </Text>
                <Text fz={14}>*{sequence.name}*</Text>
              </div>,
              {
                icon: false, // No icon
                autoClose: false, // Stay until clicked
                closeOnClick: true, // Prevent default closing

                onClose: () => {
                  toast.dismiss(toastId);
                },
              },
            );
          };
          const sendPushNotification = async () => {
            await axiosInstance.post(
              'v2/push-notifications/receive-push-notification-from-client',
              {
                shopId: currentShopId,
                notification: {
                  userId: user.uid,
                  platform: 'mobile',
                  priority: 'normal',
                  service_id: 'sequences',
                  notificationData: {
                    title: `New Agent Run`,
                    body: sequence.name,
                    icon: 'https://app.triplewhale.com/lighthouse-icons/apple-touch-icon.png',
                  },
                  sourceId: sequenceId,
                  shopId: currentShopId,
                  customOpenParams: `navigateUrl=workflows/view/${sequenceId}/${runId}`,
                },
              },
            );
          };
          showToast();
          if (
            userNotificationSettings?.['moby']?.topics?.['manually_run_agent_finished']?.mobile !==
              false &&
            user.expoPushToken
          ) {
            sendPushNotification();
          }
          removeAgentToWatch(sequenceId);
          return;
        }
        return;
      }
    },
    [
      navigate,
      shopSequences,
      agentsToWatch,
      location.pathname,
      currentShopId,
      user,
      userNotificationSettings,
      mobyFeedItems,
    ],
  );

  useEffect(() => {
    const func = (msg) => {
      if (
        msg.eventType !== 'workflow-progress' ||
        msg.data.type !== 'sequence-done' ||
        msg.account !== currentShopId
      ) {
        return;
      }
      onAgentFinishedRunning(msg.data);
    };
    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [socket, onAgentFinishedRunning, currentShopId]);
};
