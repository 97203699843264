import { useMemo, useState, useEffect } from 'react';
import { Allotment } from 'allotment';
import { useSelectByWindowResize, Text, SegmentedControl } from '@tw/ui-components';
import { Message } from './types/willyTypes';
import { WillyMainChat } from './WillyMainChat';
import { MENU_SIZE } from './constants';
import { deepSearchReasoningMessages, mostRecentFinalReport } from './utils/deepSearchMessages';

export const WillyDeepSearch: React.FC<{
  messages: Message[];
  conversationId: string;
}> = ({ messages, conversationId }) => {
  const [viewType, setViewType] = useState<'Reasoning' | 'Final Report'>('Reasoning');
  const windowWidth = useSelectByWindowResize(({ width }) => width);

  const reasoningMessages = useMemo(() => {
    return deepSearchReasoningMessages(messages);
  }, [messages]);

  const finalReportMessage = useMemo(() => {
    return mostRecentFinalReport(messages);
  }, [messages]);

  const messageStepsPlan = useMemo(() => {
    if (!messages || !messages.length) {
      return null;
    }

    // Find the last StepsPlanner message with in-progress, or completed
    const stepsMessage = messages.findLast(
      (m) =>
        m.toolResults?.name === 'StepsPlanner' &&
        m.toolResults?.stepsPlan?.steps.find(
          (s) => s.step_status === 'in-progress' || s.step_status === 'completed',
        ),
    );

    if (stepsMessage?.toolResults?.name === 'StepsPlanner') {
      return (stepsMessage.toolResults as any).stepsPlan;
    }

    return null;
  }, [messages]);

  const messagesToDisplay = useMemo(() => {
    if (viewType === 'Reasoning') {
      return reasoningMessages ? reasoningMessages : [];
    }

    return finalReportMessage ? [finalReportMessage] : [];
  }, [viewType, reasoningMessages, finalReportMessage]);

  // if final report is available/updated, force the tab to show it by default
  useEffect(() => {
    if (finalReportMessage) {
      setViewType('Final Report');
    }
  }, [finalReportMessage]);

  return (
    <Allotment.Pane
      snap
      className="transition-[width] duration-300 ease-in-out sm:transition-none h-full"
      preferredSize={windowWidth / 3}
    >
      <Allotment vertical>
        <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE} className="flex justify-between">
          <div className="willy-dash-header flex gap-4 justify-between flex-row items-center lg:w-full grow overflow-hidden overflow-x-auto px-6 py-4 lg:px-[24px]">
            <Text fw="bold">
              Deep Dive Reasoning{' '}
              {messageStepsPlan?.steps.length && `(${messageStepsPlan?.steps.length} steps)`}
            </Text>
            {finalReportMessage && (
              <SegmentedControl
                data={[
                  { label: 'Reasoning', value: 'Reasoning' },
                  { label: 'Final Report', value: 'Final Report' },
                ]}
                value={viewType}
                onChange={(v) => setViewType(v as 'Reasoning' | 'Final Report')}
              />
            )}
          </div>
        </Allotment.Pane>

        <Allotment>
          <Allotment.Pane className="dash-pane-wrapper !overflow-auto willy-main-pane relative @container w-full h-full">
            {messagesToDisplay.length > 0 ? (
              <WillyMainChat
                messages={messagesToDisplay}
                source="deepSearch"
                conversationId={conversationId}
                setConversationId={() => {}}
                setMessages={() => {}}
              />
            ) : (
              <div className="flex flex-col justify-center h-full text-center">
                <p className="text-gray-400 p-4">
                  {messages?.length > 0
                    ? 'No deep dive initiated, yet...'
                    : 'Start chatting with Moby, or select an existing conversation to view deep dive'}
                </p>
              </div>
            )}
          </Allotment.Pane>
        </Allotment>
      </Allotment>
    </Allotment.Pane>
  );
};
