export const AccountNotificationPlatorms = ['browser', 'mobile'];
export const ShopNotificationPlatorms = ['email'];
export const NotificationPlatorms = [...AccountNotificationPlatorms, ...ShopNotificationPlatorms];

export const notificationsTypes: any[] = [
  {
    id: 'moby',
    name: 'Moby',
    availablePlatforms: ['mobile'],
    topics: [
      {
        id: 'deep_search_finished',
        name: 'Deep Dive Finished',
      },
      {
        id: 'manually_run_agent_finished',
        name: 'Manually Run Agent Finished',
      },
    ],
  },
];
