import { BillingInterval, subscriptionType } from '@tw/types/module/services/subscription-manager';
import { Text } from '@tw/ui-components';
import { FC } from 'react';

export type ContractTypeAndBillingInterval = {
  contractType: subscriptionType;
  billingInterval: BillingInterval;
};

const contractTypeLabelMap: Partial<
  Record<subscriptionType, (isSelected: boolean) => JSX.Element>
> = {
  '12 Month Contract': (isSelected: boolean) => <>Annual, billed monthly</>,
  Annual: (isSelected: boolean) => (
    <>
      Annual, prepaid{' '}
      <span style={{ color: isSelected ? 'white' : 'var(--primary-500-Brand, #2281FB)' }}>
        (-15%)
      </span>
    </>
  ),
};

const label = (contractType: subscriptionType, isSelected: boolean): JSX.Element => {
  return contractTypeLabelMap[contractType] ? (
    contractTypeLabelMap[contractType]!(isSelected)
  ) : (
    <>{contractType}</>
  );
};

type BillingIntervalSwitcherProps = {
  contractTypes: ContractTypeAndBillingInterval[];
  selectedType: ContractTypeAndBillingInterval;
  setSelectedType: (selectedType: ContractTypeAndBillingInterval) => void;
};

export const BillingIntervalSwitcher: FC<BillingIntervalSwitcherProps> = ({
  contractTypes,
  selectedType,
  setSelectedType,
}) => {
  if (contractTypes.length !== 2) return null;
  const handleSelect = (selected: ContractTypeAndBillingInterval) => {
    setSelectedType(selected);
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        style={{
          display: 'flex',
          borderRadius: '100px',
          border: '1px solid var(--Gray-Light-Mode-100, #F1F3F6)',
          background: 'var(--mantine-color-gray-1)',
          padding: '4px',
        }}
      >
        {contractTypes.map((contract, index) => {
          const isSelected = selectedType.contractType === contract.contractType;
          const marginStyle =
            index === 0
              ? { marginRight: '4px' }
              : index === contractTypes.length - 1
                ? { marginLeft: '4px' }
                : {};
          return (
            <div
              key={index}
              onClick={() => handleSelect(contract)}
              style={{
                flex: 1,
                padding: '7px 11px',
                cursor: 'pointer',
                background: isSelected ? 'var(--primary-600-Brand, #0C70F2)' : 'transparent',
                borderRadius: '30px',
                transition: 'background 0.3s ease',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                ...marginStyle,
              }}
            >
              <div style={{ fontSize: '12px' }}>
                <Text weight={500} color={isSelected ? 'white' : 'gray.6'}>
                  {label(contract.contractType, isSelected)}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
