import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { AlanLoaderGray } from 'components/AlanLoader';
import { CopyToClipboard } from './CopyToClipboard';
import React, { useEffect, useMemo, useRef } from 'react';
import { Components, ExtraProps } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';
import { DynamicReactRenderer } from 'components/DynamicReactRenderer/DynamicReactRenderer';
import { DataWithName, GenUiEditDiffsType } from './types/willyTypes';

export type CodeAction = {
  onAction: (code: string) => void;
  text: string;
  supportedLanguages?: string[];
};

type WillySimpleTextProps = {
  text: string;
  loading?: boolean;
  loadingText?: boolean;
  classForCodeBlock?: string;
  error?: boolean;
  info?: boolean;
  prose?: boolean;
  smallText?: boolean;
  codeActions?: CodeAction[];
  forceShowCodeBlock?: boolean;
  dataForGenUi?: DataWithName[];
  diffs?: GenUiEditDiffsType;
  streamingOutput?: boolean;
  // Additional props for GenUI saving functionality
  sequenceId?: string;
  runId?: string;
  stepId?: string;
  updateToFixedGenUi?: (output) => void;
};

export const WillySimpleText: React.FC<WillySimpleTextProps> = ({
  text,
  loading,
  loadingText,
  codeActions,
  classForCodeBlock = '',
  error,
  info,
  smallText,
  forceShowCodeBlock,
  dataForGenUi,
  diffs,
  streamingOutput,
  sequenceId,
  runId,
  stepId,
  updateToFixedGenUi,
}) => {
  const components: Components = useMemo(() => {
    return {
      table: ({ node, ...props }) => (
        <div className="max-w-full overflow-auto">
          <table
            {...props}
            className="w-full table-auto border-collapse border border-gray-300 dark:border-slate-700 m-0"
          />
        </div>
      ),
      thead: ({ node, ...props }) => <thead {...props} className="bg-gray-100 dark:bg-slate-900" />,
      tbody: ({ node, ...props }) => <tbody {...props} className="bg-white dark:bg-slate-800" />,
      th: ({ node, ...props }) => (
        <th
          {...props}
          className="border-b dark:border-slate-600 font-medium p-4 !dark:text-slate-200 text-left"
        />
      ),
      td: ({ node, ...props }) => (
        <td
          {...props}
          className="border-b border-slate-100 dark:border-slate-700 p-4 !dark:text-slate-400"
        />
      ),
      p: ({ node, ...props }) => (
        <p
          {...props}
          className={`${info ? 'font-medium' : ''} leading-relaxed whitespace-pre-wrap`}
        />
      ),
      pre: ({ node, ...props }) => {
        return (
          <CodeBlock
            node={node}
            {...props}
            codeActions={codeActions}
            classForCodeBlock={classForCodeBlock}
            forceShowCodeBlock={forceShowCodeBlock}
            dataForGenUi={dataForGenUi}
            diffs={diffs}
            streamingOutput={streamingOutput}
          />
        );
      },
      a: ({ node, ...props }) => <a {...props} target="_blank" className="text-[#0C70F2]" />,
      ul: ({ node, ...props }) => (
        <ul {...props} className={`${info ? 'marker:text-inherit' : ''} m-0`} />
      ),
      ol: ({ node, ...props }) => <ol {...props} className="m-0" />,
      li: ({ node, ...props }) => <li {...props} className="m-0" />,
      img: ({ node, ...props }) => (
        <img {...props} className="max-w-96 h-auto m-0 align-text-top rounded-md" />
      ),
    };
  }, [
    classForCodeBlock,
    codeActions,
    info,
    forceShowCodeBlock,
    dataForGenUi,
    diffs,
    streamingOutput,
  ]);

  const shouldRenderDynamicReact = useMemo(() => {
    return text.includes('```jsx') && !forceShowCodeBlock;
  }, [text, forceShowCodeBlock]);

  if (shouldRenderDynamicReact) {
    // extract the code from the text
    const code = text.match(/```jsx([\s\S]*?)```/)?.[1] || '';
    // console.log('code', code);
    // console.log('data', dataForGenUi);
    return (
      <DynamicReactRenderer
        componentCode={code}
        componentData={dataForGenUi}
        sequenceId={sequenceId}
        runId={runId}
        stepId={stepId}
        updateToFixedGenUi={updateToFixedGenUi}
      />
    );
  }

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <AlanLoaderGray />
      </div>
    );
  }

  if (typeof text !== 'string') {
    return null;
  }

  return (
    <div
      className={`w-full flex-auto prose dark:prose-invert max-w-full leading-relaxed text-[${
        smallText ? '14px' : '16px'
      }] md:text-[1rem] translate-x-0 ${
        info
          ? '!text-blue-800 font-medium'
          : error
            ? '!text-red-600'
            : 'text-gray-800 !dark:text-slate-200'
      }`}
    >
      <Markdown
        children={text}
        components={components}
        remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
      />
      {loadingText && <div className="blinking-cursor"></div>}
    </div>
  );
};

type CodeBlockProps = {
  codeActions?: CodeAction[];
  classForCodeBlock?: string;
  node?: ExtraProps['node'];
  forceShowCodeBlock?: boolean;
  dataForGenUi?: DataWithName[];
  diffs?: GenUiEditDiffsType;
  streamingOutput?: boolean;
};

/**
 * CodeBlock component
 * The idea is this:
 * The first node is a `pre` tag, came from the markdown parser with the additional props
 * The first child of the `pre` tag is a `code` tag.
 * If it's not a code tag, we just render a normal `pre` tag with a `code` tag inside
 * If it's a code tag, we get the language from the className, and we use the `react-syntax-highlighter` to render the code
 */

// CSS for code streaming animations
const CodeStreamingStyles = `
@keyframes codeInsertFadeIn {
  from { opacity: 0; background-color: rgba(0, 255, 0, 0.3); }
  to { opacity: 1; background-color: rgba(0, 255, 0, 0.1); }
}

@keyframes codeDeleteFadeOut {
  from { opacity: 0.5; }
  to { opacity: 0; display: none; }
}

.code-with-diffs {
  padding-top: 2rem;
  white-space: pre-wrap;
  position: relative;
}

.code-insert {
  background-color: rgba(0, 255, 0, 0.1);
}

.code-delete {
  background-color: rgba(255, 0, 0, 0.1);
}
`;

export const CodeBlock: React.FC<CodeBlockProps> = React.memo(
  ({
    classForCodeBlock,
    codeActions,
    node,
    forceShowCodeBlock,
    dataForGenUi,
    diffs,
    streamingOutput,
    ...props
  }) => {
    // Create refs for scrolling
    const containerRef = useRef<HTMLDivElement>(null);
    const editingElementsRef = useRef<Map<number, HTMLElement>>(new Map());
    const lastActiveElementIndexRef = useRef<number | null>(null);

    // Inject styles when component mounts
    useEffect(() => {
      if (streamingOutput && diffs) {
        const styleId = 'code-streaming-styles';
        if (!document.getElementById(styleId)) {
          const styleElement = document.createElement('style');
          styleElement.id = styleId;
          styleElement.textContent = CodeStreamingStyles;
          document.head.appendChild(styleElement);

          return () => {
            const existingStyle = document.getElementById(styleId);
            if (existingStyle && document.head.contains(existingStyle)) {
              document.head.removeChild(existingStyle);
            }
          };
        }
      }
    }, [streamingOutput, diffs]);

    // Handle scrolling to the latest edit
    useEffect(() => {
      if (!streamingOutput || !diffs || diffs.length === 0) return;

      // Find the last edit operation (insert or delete) in the diffs
      let lastEditIndex = -1;
      for (let i = diffs.length - 1; i >= 0; i--) {
        // Operation code 1 = insert, -1 = delete
        if ((diffs[i][0] === 1 || diffs[i][0] === -1) && diffs[i][1].trim().length > 0) {
          lastEditIndex = i;
          break;
        }
      }

      // If we found an edit and it's different from the last one we scrolled to
      if (lastEditIndex >= 0 && lastEditIndex !== lastActiveElementIndexRef.current) {
        const editElement = editingElementsRef.current.get(lastEditIndex);
        if (editElement && containerRef.current) {
          console.log('scrolling to edit');
          // Scroll the element into view with smooth behavior
          editElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          // Update the last active element index
          lastActiveElementIndexRef.current = lastEditIndex;
        }
      }
    }, [diffs, streamingOutput]);

    const codeElement = node?.children[0];
    if (codeElement?.type !== 'element' || codeElement.tagName !== 'code') {
      // some weird edge case that maybe will be one day, not supposed to happen, but just in case
      return (
        <pre {...props}>
          <code className={classForCodeBlock} />
        </pre>
      );
    }
    const textElement = codeElement.children[0];
    if (textElement?.type !== 'text') {
      // maybe the first child of the code tag is not a text node, also not supposed to happen
      return (
        <pre {...props}>
          <code className={classForCodeBlock} />
        </pre>
      );
    }
    const className = codeElement.properties.className as string;

    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : 'text';

    const code = textElement.value;

    // Store references to edit elements for scrolling
    const setEditElementRef = (index: number, element: HTMLDivElement | null) => {
      if (element) {
        editingElementsRef.current.set(index, element);
      } else {
        editingElementsRef.current.delete(index);
      }
    };

    // Render code with streaming diffs when available
    const renderCode = () => {
      if (streamingOutput && diffs) {
        return (
          <div className="code-with-diffs" ref={containerRef}>
            {diffs.map((diff, index) => {
              const [operation, text] = diff;

              // Skip empty text
              if (!text) return null;

              if (operation === 0) {
                // EQUAL - unchanged text
                return (
                  <SyntaxHighlighter
                    key={index}
                    language={match ? match[1] : 'text'}
                    style={vscDarkPlus}
                    wrapLongLines={!!match}
                    customStyle={{
                      background: 'transparent',
                      margin: 0,
                      padding: 0,
                      display: 'inline',
                    }}
                  >
                    {text}
                  </SyntaxHighlighter>
                );
              } else if (operation === 1) {
                // INSERT - added text
                return (
                  <div
                    key={index}
                    ref={(el) => setEditElementRef(index, el)}
                    className="code-insert"
                    style={{
                      animation: `codeInsertFadeIn 0.5s ${index * 0.05}s both`,
                      display: 'inline',
                    }}
                  >
                    <SyntaxHighlighter
                      language={match ? match[1] : 'text'}
                      style={vscDarkPlus}
                      wrapLongLines={!!match}
                      customStyle={{
                        background: 'rgba(0, 255, 0, 0.1)',
                        margin: 0,
                        padding: 0,
                        display: 'inline',
                      }}
                    >
                      {text}
                    </SyntaxHighlighter>
                  </div>
                );
              } else {
                // DELETE - removed text
                return (
                  <div
                    key={index}
                    ref={(el) => setEditElementRef(index, el)}
                    className="code-delete"
                    style={{
                      animation: `codeDeleteFadeOut 0.5s ${index * 0.05}s both`,
                      textDecoration: 'line-through',
                      opacity: 0.5,
                      display: 'inline',
                    }}
                  >
                    <SyntaxHighlighter
                      language={match ? match[1] : 'text'}
                      style={vscDarkPlus}
                      wrapLongLines={!!match}
                      customStyle={{
                        background: 'rgba(255, 0, 0, 0.1)',
                        margin: 0,
                        padding: 0,
                        display: 'inline',
                      }}
                    >
                      {text}
                    </SyntaxHighlighter>
                  </div>
                );
              }
            })}
          </div>
        );
      }

      // Standard syntax highlighting for non-streaming code
      return (
        <SyntaxHighlighter
          language={match ? match[1] : 'text'}
          style={vscDarkPlus}
          wrapLongLines={!!match}
          customStyle={{ background: 'transparent', marginTop: '2rem', paddingTop: '0' }}
        >
          {code}
        </SyntaxHighlighter>
      );
    };

    return (
      <pre {...props}>
        <span className="flex flex-col gap-2 relative">
          <span className="flex gap-2 items-center fixed left-0 w-full px-6.5">
            {!!match && (
              <span className="text-gray-400 dark:text-slate-400 text-[12px]">
                {match[1].toUpperCase()}
              </span>
            )}
            <div className="ml-auto flex gap-2 items-center">
              {codeActions
                ?.filter((x) => x.supportedLanguages?.includes(language))
                .map((x, i) => (
                  <span
                    key={`code-action-${i}`}
                    className={`cursor-pointer rounded-md border-gray-400 border border-solid text-gray-400 px-2`}
                    onClick={() => {
                      x.onAction(code);
                    }}
                  >
                    {x.text}
                  </span>
                ))}
            </div>
            <span>
              <CopyToClipboard text={code} className="!fill-gray-400" />
            </span>
          </span>
          {renderCode()}
        </span>
      </pre>
    );
  },
);

// CSS for streaming animations
export const StreamingStyles = `
@keyframes fadeIn {
  from { opacity: 0; background-color: rgba(0, 255, 0, 0.2); }
  to { opacity: 1; background-color: transparent; }
}

@keyframes fadeOut {
  from { opacity: 0.5; }
  to { opacity: 0; display: none; }
}

.inserted-text {
  background-color: rgba(0, 255, 0, 0.1);
}

.deleted-text {
  background-color: rgba(255, 0, 0, 0.1);
}

.streaming-text {
  white-space: pre-wrap;
}
`;

// CSS Component to include in your app
export const StreamingStylesComponent: React.FC = () => <style>{StreamingStyles}</style>;
