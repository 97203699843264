import { useSelector } from 'react-redux';
import { ActivityProperties, CustomerActivityType } from '@tw/types/module/services/insights';
import { Text, Grid, Badge, Anchor } from '@tw/ui-components';
import { RootState } from 'reducers/RootType';
import { ActivityDetail } from './ActivityDetail';
import { useMemo } from 'react';
import { ProductInfo } from './ProductInfo';
import { computeFeatureFlags, useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../../../$stores/$shop';

export const PlacedOrderContent: React.FC<{
  properties: ActivityProperties[CustomerActivityType.PlacedOrder];
}> = ({ properties }) => {
  const currency = useStoreValue($shopCurrency);

  const fulfillmentStatusColor = useMemo(() => {
    // TODO: background color
    switch (properties.fulfillmentStatus) {
      case 'fulfilled':
        return 'green.9';
      case 'partial':
        return 'yellow.8';
      default:
        return 'gray.8';
    }
  }, [properties.fulfillmentStatus]);

  return (
    <>
      <ActivityDetail label="Total order price">
        {Number(properties.orderPrice).toLocaleString(undefined, {
          style: 'currency',
          currency,
        })}
      </ActivityDetail>
      {properties.discount > 0 && (
        <ActivityDetail label="Total discounts">
          {Number(properties.discount).toLocaleString(undefined, {
            style: 'currency',
            currency,
          })}
        </ActivityDetail>
      )}
      {properties.discountCode?.length && (
        <ActivityDetail label="Discount code">{properties.discountCode.join()}</ActivityDetail>
      )}
      {properties.orderTags?.length && (
        <ActivityDetail label="Order Tags">{properties.orderTags.join()}</ActivityDetail>
      )}
      <ActivityDetail label="Status">
        <Badge uppercase color={fulfillmentStatusColor}>
          {properties.fulfillmentStatus}
        </Badge>
      </ActivityDetail>

      {properties.itemsPurchased?.length > 0 && (
        <>
          <Text weight={500} py="sm">
            Items purchased
          </Text>
          <Grid>
            {properties.itemsPurchased.map((item, index) => (
              <Grid.Col key={index}>
                <ProductInfo properties={item} />
              </Grid.Col>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export const PlacedOrderTitle: React.FC<{
  properties: ActivityProperties[CustomerActivityType.PlacedOrder];
}> = ({ properties }) => {
  const { result: hasStoreDataAccess } = useFeatureFlag(FeatureFlag.STORE_DATA_FF);

  return <>Placed order: {properties.orderName}</>;
};
