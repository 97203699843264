import { useEffect, useMemo, useState } from 'react';
import { isProd } from '@tw/constants';
import {
  FeatureFlag,
  FeatureFlagPackagesConfig,
  FeatureFlagMetaData,
  FeatureFlagConfigKey,
  NullableFeatureFlagConfigValue,
  PackageMetaData,
  FeatureFlagValueType,
  FeatureFlagCombinationMethod,
  FeatureFlagResult,
  NullableFeatureFlagValueWithMetaDataMap,
} from '@tw/feature-flag-system/module/types';
import { PACKAGE_CONFIG_RANKS } from '@tw/feature-flag-system/module/constants';
import {
  computePackageConfigKeys,
  deserializeFeatureFlagObject,
} from '@tw/feature-flag-system/module/utils';
import { FullShopFFSystemData, isFullShopFFSystemData } from './types';
import {
  getComputedFFValues,
  getPackageMetaData,
  getFFMetaData,
  getFFPackagesConfig,
} from './services';
import { isEqual } from 'lodash';
import { isFeatureFlagRankedControlList } from './validators';
import { isDefined } from 'utils/isDefined';
import { $notifier } from '@tw/snipestate';

type GenericCallback = (...args: any) => any;

class FFConfigComputer {
  private _packageConfigKeys = new Array<FeatureFlagConfigKey>();
  private _ffPackagesMetaData: PackageMetaData[] = [];
  private _ffMetadata: Partial<FeatureFlagMetaData> | null = null;
  private _ffAllPackagesConfig: Partial<FeatureFlagPackagesConfig> | null = null;
  private _ffValues: NullableFeatureFlagValueWithMetaDataMap = {};
  private _callbacks = new Set<GenericCallback>();
  private _updateSwitch = false;
  private _ready = false;
  private _computedValuesReady = false;

  public get ffPackageConfigKeys(): ReadonlyArray<FeatureFlagConfigKey> {
    return this._packageConfigKeys;
  }

  public get ffMetadata(): Readonly<Partial<FeatureFlagMetaData>> | null {
    return this._ffMetadata;
  }

  public get ffPackagesConfig(): Readonly<Partial<FeatureFlagPackagesConfig>> | null {
    return this._ffAllPackagesConfig;
  }

  public get ffValues(): Readonly<NullableFeatureFlagValueWithMetaDataMap> {
    return this._ffValues;
  }

  public get ffPackagesMetaData(): ReadonlyArray<PackageMetaData> {
    return this._ffPackagesMetaData;
  }

  /**
   * @description Returns the meta data of the highest current package the user has.
   */
  public get highestCurrentPackageMetaData(): Partial<PackageMetaData> {
    const highestCurrentPackage = this._packageConfigKeys[this._packageConfigKeys.length - 1];
    if (!highestCurrentPackage) return {};

    const packageConfig = this._ffPackagesMetaData.find((p) => p.id === highestCurrentPackage);
    if (!packageConfig) return {};

    return packageConfig;
  }

  public get updateToggled(): boolean {
    return this._updateSwitch;
  }

  public get isReady(): boolean {
    return this._ready;
  }

  public get computedValuesReady() {
    return this._computedValuesReady;
  }

  private emitUpdate(): void {
    this._updateSwitch = !this._updateSwitch;
    this._ready = true;
    this._callbacks.forEach((cb) => cb());
  }

  public addUpdateListener(cb: GenericCallback) {
    this._callbacks.add(cb);

    return () => {
      this._callbacks.delete(cb);
    };
  }

  public removeUpdateListener(cb: GenericCallback): void {
    this._callbacks.delete(cb);
  }

  /**
   * @description Utility function to get all general data for the feature flag system
   */
  private async getGeneralFFData() {
    const [packageMetaData, metaData, packagesConfig] = await Promise.all([
      getPackageMetaData(),
      getFFMetaData(),
      getFFPackagesConfig(),
    ]);

    return {
      packagesConfig,
      packageMetaData,
      metaData,
    };
  }

  /**
   * @description Attempts to get ff config data from SSR - if non existant or issue with data, try fetching from API
   * @param shopId
   */
  private getFFSystemData(shopId: string): FullShopFFSystemData | Promise<FullShopFFSystemData> {
    if ('tw-ff-config' in window) {
      if (typeof window['tw-ff-config'] === 'object') {
        const deserialized = deserializeFeatureFlagObject(JSON.stringify(window['tw-ff-config']));
        if (isFullShopFFSystemData(deserialized) && deserialized.shopId === shopId) {
          delete window['tw-ff-config']; // we only use it on first render
          return deserialized;
        }
      }
    }

    return Promise.all([this.getGeneralFFData(), getComputedFFValues(shopId)]).then(
      ([{ packageMetaData, metaData, packagesConfig }, computedFFValues]) => {
        return {
          shopId,
          computedFFValues,
          metaData,
          packagesConfig,
          packageMetaData,
        };
      },
    );
  }

  /**
   * @description Fetches and sets the config and metadata for the packages user is currently subscribed to.
   * @param featureKeys A list of feature flags attached to the user's subscription. Yes, turns out you can have a null one in there...
   * @param shopId
   */
  public init(shopId: string, confKeys?: (string | null)[]): void | Promise<void> {
    try {
      if (confKeys) this.setConfKeys(confKeys, false);

      const res = this.getFFSystemData(shopId);

      if (res instanceof Promise) {
        return res.then((res) => {
          this._ffAllPackagesConfig = res.packagesConfig;
          this._ffPackagesMetaData = res.packageMetaData;
          this._ffMetadata = res.metaData;
          this._ffValues = res.computedFFValues;
          this._computedValuesReady = true;
          this.emitUpdate();
        });
      } else {
        this._ffAllPackagesConfig = res.packagesConfig;
        this._ffPackagesMetaData = res.packageMetaData;
        this._ffMetadata = res.metaData;
        this._ffValues = res.computedFFValues;
        this._computedValuesReady = true;
        this.emitUpdate();
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @description Initialize general data like metadata and configs. That way, if
   * `isEntityLocked` needs to be called, we can still use it by provided an explicit
   * value for the `value` field.
   */
  public async initGeneralData(): Promise<void> {
    const { packageMetaData, metaData, packagesConfig } = await this.getGeneralFFData();

    this._ffAllPackagesConfig = packagesConfig;
    this._ffPackagesMetaData = packageMetaData;
    this._ffMetadata = metaData;

    this.emitUpdate();
  }

  /**
   * @description We might need to get feature keys from various sources after the system is initialized.
   * This method allows us to do so and notify all listeners to the ffComputer of this update. Main reason to
   * have these up to date is so that we always have all the features to compute what conf the user/shop might
   * need to upgrade to to access a specific feature.
   * @param newConfKeys List of new confKeys to add to the list.
   */
  public setConfKeys(newConfKeys: (string | null)[], emitUpdate = true): void {
    if (isEqual(this._packageConfigKeys, newConfKeys)) return;

    this._packageConfigKeys = computePackageConfigKeys([
      ...this._packageConfigKeys,
      ...newConfKeys,
    ]);

    if (emitUpdate) this.emitUpdate();
  }

  /**
   * @description Gets and computes the configuration and metadata for a specific feature
   * based on the user's plan(s) and the metadata for that feature.  The only way to know
   * the type of the result value for the metric is to see the metadata for it in admin.
   * @param featureFlagId FeatureFlag we want to get info for.
   * @returns Metadata and config for the provided feature flag id.
   */
  public getConfigById(featureFlagId?: FeatureFlag): FeatureFlagResult {
    if (!featureFlagId || !this._ffMetadata || !this._ffValues) return new FeatureFlagResult();

    return new FeatureFlagResult({
      metadata: this._ffMetadata[featureFlagId],
      result: this._ffValues[featureFlagId],
    });
  }

  /**
   * @description Dynamic function that checks if some entity is locked depending on the metadata of the feature flag.
   * Feature flag configs that use arrays look for a `targetUnlockValue`.  Returns "null" if unclear to lock or unlock.
   * @param featureFlag
   * @param value
   * @param targetUnlockValue
   */
  public isEntityLocked({
    featureFlag,
    value,
    targetUnlockValue,
  }: {
    featureFlag?: FeatureFlag;
    value?: NullableFeatureFlagConfigValue;
    targetUnlockValue?: any;
  }): boolean | null {
    if (!featureFlag || !this._ffMetadata?.[featureFlag]) return null;

    value ??= this.getConfigById(featureFlag)?.result;
    if (!isDefined(value)) return null;

    const { valueType, combinationMethod } = this._ffMetadata[
      featureFlag
    ] as FeatureFlagMetaData[FeatureFlag];
    const { MAX, MIN, SUM } = FeatureFlagCombinationMethod;

    if (valueType === FeatureFlagValueType.Boolean) return !Boolean(value);

    if (valueType === FeatureFlagValueType.Number) {
      const coercedResult = typeof value === 'number' ? value : 0;

      if (combinationMethod === MAX) return coercedResult < targetUnlockValue;
      if (combinationMethod === MIN) return coercedResult > targetUnlockValue;
      if (combinationMethod === SUM) return coercedResult !== targetUnlockValue;

      return false;
    }

    if (!Array.isArray(value)) return false;

    if (!isFeatureFlagRankedControlList(value)) {
      if (valueType === FeatureFlagValueType.AllowList) return !value.includes(targetUnlockValue);
      if (valueType === FeatureFlagValueType.BlockList) return value.includes(targetUnlockValue);
    } else {
      if (valueType === FeatureFlagValueType.RankedControlList) {
        return value.some((item) => item.id === targetUnlockValue && item.type === 'block');
      }
    }

    return false;
  }

  /**
   * @description Get the id and name of the closest highest package above the current one to offer a blocked service.
   * @param featureFlagId
   */
  public getRequiredPlanToUnhideFeature(
    featureFlagId?: FeatureFlag,
    targetUnlockValue?: any,
  ): Partial<PackageMetaData> {
    if (!featureFlagId || !this._ffAllPackagesConfig || !this._ffMetadata?.[featureFlagId])
      return {};

    const highestCurrentRankValue = (() => {
      const highestCurrentPackage = this._packageConfigKeys[this._packageConfigKeys.length - 1];
      return highestCurrentPackage ? PACKAGE_CONFIG_RANKS[highestCurrentPackage] : 0;
    })();

    // find the first plan higher than user's current highest plan that has the feature user wants to upgrade for
    const requiredPlan = Object.keys(PACKAGE_CONFIG_RANKS).find(
      (key): key is FeatureFlagConfigKey =>
        PACKAGE_CONFIG_RANKS[key] > highestCurrentRankValue &&
        false ===
          this.isEntityLocked({
            featureFlag: featureFlagId,
            value: this._ffAllPackagesConfig?.[key]?.[featureFlagId]?.value,
            targetUnlockValue,
          }),
    );

    const requiredMetaData = this._ffPackagesMetaData.find((m) => m.id === requiredPlan);

    return {
      id: requiredPlan,
      name: requiredMetaData?.name,
    };
  }
}

// ------------------------- FEATURE FLAG SYSTEM SINGLETON -------------------------
// ALL HAIL THE SINGLETON 🙌
const featureFlagComputer = new FFConfigComputer();

// exposing just for development purposes
if (!isProd) {
  window['ffComputer'] = featureFlagComputer;
}

// We only want a single ff computer in our whole app - but in rare occassions,
// we might need to pass the computer as a prop, so just exporting the type - not the class!!
export type FeatureFlagComputer = FFConfigComputer;
// ---------------------------------------------------------------------------------

// --------------------- FEATURE FLAG SYSTEM UTILITY FUNCTIONS ---------------------
// by exporting, we don't allow any file to have direct access to the singleton
export const initFeatureFlagComputer = async (shopId: string, confKeys?: (string | null)[]) => {
  await featureFlagComputer.init(shopId, confKeys);
};

export const updateFFComputerConfKeys = (confKeys: (string | null)[]) => {
  featureFlagComputer.setConfKeys(confKeys);
};

/**
 * @description Initializing general stuff like metadata and configs.
 */
export const initFeatureFlagComputerGeneralData = async () => {
  await featureFlagComputer.initGeneralData();
};
// ---------------------------------------------------------------------------------

// --------------------------- FEATURE FLAG SYSTEM HOOKS ---------------------------
/**
 * @description This hook will be able to listen to changes in the featureFlagComputer
 * and rerender components consuming this hook whenever there's an update. This hook
 * always returns the singleton of the featureFlagComputer.  To listen for updates in a useEffect,
 * use `featureFlagComputer.updateToggled` in the dependency array or the `addUpdateListener` method.
 */
export function useFeatureFlagComputer(): FFConfigComputer {
  const [_, setState] = useState<number>(0);

  useEffect(() => {
    const forceUpdate = () => setState((x) => (1 + x) % Number.MAX_SAFE_INTEGER);
    featureFlagComputer.addUpdateListener(forceUpdate);
    return () => featureFlagComputer.removeUpdateListener(forceUpdate);
  }, []);

  return featureFlagComputer;
}

/**
 * @description same as useFeatureFlagComputer - just to access a single feature flag result
 */
export function useFeatureFlag(id?: FeatureFlag): FeatureFlagResult {
  return useFeatureFlagComputer().getConfigById(id);
}

export function useSelectByFFComputer<T = any>(cb: (ff: FeatureFlagComputer) => T): T {
  const ffComputer = useFeatureFlagComputer();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => cb(ffComputer), [cb, ffComputer.updateToggled]);
}

/**
 * @description Lets you compute a memoized value based on a callback result.
 */
export function useFeatureFlagSelector<T = any>(
  featureFlag: FeatureFlag | undefined,
  cb: (res: FeatureFlagResult) => T,
): T {
  const res = useFeatureFlag(featureFlag);
  return useMemo<T>(() => cb(res), [res, cb]);
}

/**
 * @description Allows us to get a value from a feature flag config for a specific feature flag
 * that only updates when the feature flag computer emits an update.
 * @param featureFlag
 * @param memoizedGetter
 */
export function useFeatureFlagValue<K extends keyof FeatureFlagResult>(
  featureFlag: FeatureFlag,
  memoizedGetter: K,
): FeatureFlagResult[K] {
  const ffComputer = useFeatureFlagComputer();

  return useMemo(() => {
    const res = ffComputer.getConfigById(featureFlag);
    return res[memoizedGetter];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlag, memoizedGetter, ffComputer.updateToggled]);
}

export function useIsRouteBlockedByFF(route: string = window.location.pathname): boolean {
  const allowedRoutes = useFeatureFlagValue(FeatureFlag.LIMIT_ROUTES_FF, 'allowList');

  return useMemo(
    () => !!allowedRoutes.length && !allowedRoutes.some((r) => route.includes(r)),
    [allowedRoutes, route],
  );
}
// ---------------------------------------------------------------------------------

// ----------------------------- FEATURE FLAG OBSERVER -----------------------------
/**
 * @description Sometimes (in VERY RARE OCCASSIONS) we need to hook the feature flag system
 * into data we're getting from redux - directly in a "thunked" action.  This is because we
 * might want what's rendered to change if data in redux or in the feature flag system changes.
 * In order to listen to updates from both entities, we can use an instance of this class to
 * delegate when dispatches have to be made, whether by redux or by the feature flag system.
 * By the way, yes - instead of doing this, if we were to export the featureFlagComputer directly,
 * we could use featureFlagComputer.addUpdateListener directly, but the point is that we don't
 * want to export directly, so we have this class. 😀
 *
 * @example
 * ```ts
 * const ffObserver = new FFObserver();
 * ```
 *
 * In some action, instead of making a dispatch directly, we make it by calling the
 * fireAndSetFFListener method of the ffObserver:
 *
 * ```ts
 * export const someAction = (x: number) => (dispatch: AppDispatch) => {
 *   ffObserver.fireAndSetFFListener((ffComputer: FeatureFlagComputer) => {
 *     const { shouldNotBeSeen } = ffComputer.getConfigById(FeatureFlag.SOME_FLAG_FF)
 *     const num = shouldNotBeSeen ? 1 : 0;
 *     dispatch({ type: 'MY_ACTION', payload: num + x })
 *   }
 * })
 * ```
 *
 * The above callback gives you access to the **updated** feature flag computer as well!
 * This means that whenever the action gets called, the callback with updated data is set
 * and called by the ffObserver, and if the feature flag computer updates, that update
 * will be tracked as well. By setting the callback property of the ffObserver, we're
 * delegating the responsibility of dispatching the action to the ffObserver.
 */
export class FFObserver {
  private _callback: GenericCallback = () => {};

  public constructor() {
    featureFlagComputer.addUpdateListener(this._callback);
  }

  /**
   * @description Removes previous listener to changes in featureFlagComputer,
   * runs the callback provided, and creates a new featureFlagComputer update
   * listener, so if the featureFlagComputer has an update, the callback will be
   * run as well.
   */
  public fireAndSetFFListener(newCallback: FFObservableCallback): void {
    featureFlagComputer.removeUpdateListener(this._callback);
    this._callback = () => newCallback(featureFlagComputer);
    this._callback();
    featureFlagComputer.addUpdateListener(this._callback);
  }
}

export type FFObservableCallback = (ffComputer: FeatureFlagComputer) => any;
// ---------------------------------------------------------------------------------

/** Temporary way to access the feature flag computer through a snipestate store */
export const $ffStore = $notifier(featureFlagComputer, (_, notify) => {
  featureFlagComputer.addUpdateListener(() => notify());
});
