import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $allowedShopAndDefaultDashboards } from '$stores/willy/$customViews';
import { useStoreValue } from '@tw/snipestate';
import { Select } from '@tw/ui-components';
import { useMemo } from 'react';
import { WillyDashboardElement } from './types/willyTypes';
import { uniqBy } from 'lodash';
import { $shopDashboards } from '../../$stores/willy/$shopDashboards';
import { $globalDashboards } from '../../$stores/willy/$globalDashboards';

type DashboardDropDownProps = {
  label?: string;
  labelHidden?: boolean;
  disabled?: boolean;
  selected?: string;
  dashboardTypes: 'shop' | 'global' | 'shopAndGlobal';
  withNone?: boolean;
  onSelect: (id: string, dashboard?: WillyDashboardElement) => void;
  onDashboardsLoaded?: (dashboards: WillyDashboardElement[]) => void;
};

export const DashboardsDropDown: React.FC<DashboardDropDownProps> = ({
  label,
  labelHidden,
  selected,
  disabled,
  withNone,
  dashboardTypes,
  onSelect,
  onDashboardsLoaded,
}) => {
  const globalDashboard = useStoreValue($globalDashboards);
  const shopDashboards = useStoreValue($shopDashboards);
  const allowedShopAndDefaultDashboards = useStoreValue($allowedShopAndDefaultDashboards);

  const dashboardsToShow = useMemo(() => {
    let reports: WillyDashboardElement[] = [];

    if (dashboardTypes === 'global') {
      reports = globalDashboard;
    } else if (dashboardTypes === 'shop') {
      reports = shopDashboards;
    } else {
      reports = [...allowedShopAndDefaultDashboards, ...globalDashboard];
    }

    reports = uniqBy(reports, (r) => r.id);
    onDashboardsLoaded?.(reports);

    return [
      ...(withNone ? [{ name: 'None', id: '' } as WillyDashboardElement] : []),
      ...reports.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
    ];
  }, [
    dashboardTypes,
    onDashboardsLoaded,
    withNone,
    globalDashboard,
    shopDashboards,
    allowedShopAndDefaultDashboards,
  ]);

  return (
    <Select
      searchable
      label={labelHidden ? undefined : label || 'Select a Dashboard'}
      disabled={disabled}
      value={selected}
      allowDeselect
      data={dashboardsToShow
        .filter((d) => d.name)
        .map((d) => {
          let label = d.name;
          if (d.isStandardDashboard) {
            label += ' (Standard)';
          } else if (d.isGlobal) {
            if (d.isForAgent) {
              label += ' (Global - For Agent)';
            } else {
              label += ' (Global)';
            }
          }
          return {
            label,
            value: d.id,
          };
        })}
      onChange={(v) => {
        const selectedDashboard = dashboardsToShow.find((d) => d.id === v);
        if (v === null) {
          return;
        }
        onSelect(v, selectedDashboard);
      }}
    />
  );
};
