import { ActionIcon, Button, Drawer, Image, Skeleton, Text } from '@tw/ui-components';
import { GenUiAction, GenUiActionWithAttribution } from './types';
import { AttributionData } from 'types/attribution';
import { isActionOutdated } from './utils';

type ActionsDrawerProps = {
  opened: boolean;
  onClose: () => void;
  actions: GenUiActionWithAttribution[];
  onDismiss: (id: string[]) => void;
  errorActions: Record<string, string | null>;
  loadingActions: Record<string, boolean>;
  onConfirm: () => void;
};

export const ActionsDrawer: React.FC<ActionsDrawerProps> = ({
  opened,
  onClose,
  actions,
  onDismiss,
  errorActions,
  loadingActions,
  onConfirm,
}) => {
  return (
    <div>
      <Drawer.Root
        opened={!!opened}
        onClose={() => onClose()}
        position="right"
        closeOnClickOutside={true}
        withinPortal
        h="100%"
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header h="60px">
            <Drawer.Title fw={700} fz={20}>
              {actions.length > 0
                ? `Pending Approval: ${actions.length} Change${actions.length > 1 ? 's' : ''}`
                : 'No Pending Approval'}
            </Drawer.Title>
            <Drawer.CloseButton />
          </Drawer.Header>
          <Drawer.Body className="h-[calc(100vh-60px)] overflow-y-auto flex flex-col gap-4">
            {actions.map((item, index) => {
              const error = errorActions[item.genUiAction.id];
              const loading = loadingActions[item.genUiAction.id];
              return (
                <ActionItem
                  key={item.genUiAction.id}
                  item={item}
                  error={error}
                  loading={loading}
                  index={index}
                  onDismiss={() => onDismiss([item.genUiAction.id])}
                />
              );
            })}
            <div className="sticky bottom-0 bg-white p-6 flex justify-end gap-2 mt-auto">
              <Button
                variant="white"
                color="gray.7"
                onClick={() => {
                  onDismiss(actions.map((action) => action.genUiAction.id));
                }}
                disabled={actions.length === 0}
              >
                Discard
              </Button>
              <Button variant="primary" onClick={onConfirm} disabled={actions.length === 0}>
                Confirm
              </Button>
            </div>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </div>
  );
};

type ActionItemProps = {
  item: {
    genUiAction: GenUiAction;
    attribution: Partial<AttributionData>;
  };
  error: string | null;
  loading: boolean;
  index: number;
  onDismiss: (id: string[]) => void;
};

const ActionItem: React.FC<ActionItemProps> = ({ item, error, loading, index, onDismiss }) => {
  return (
    <div className="flex flex-col gap-6.5">
      {index === 0 && (
        <div className="flex items-center justify-between p-6">
          <Text size="md" color="gray.4" fw={700}>
            Name
          </Text>
          <Text size="md" color="gray.4" fw={700}>
            Action
          </Text>
        </div>
      )}
      {isActionOutdated(item) && (
        <div className="flex items-center gap-4 shadow-md rounded-lg p-6 bg-red-500">
          <ActionIcon
            icon="close"
            color="red.8"
            size="xs"
            onClick={() => onDismiss([item.genUiAction.id])}
          />
          <Text color="red.8" size="md" fw={700} truncate maw="300px">
            This action is outdated and can't be applied.
          </Text>
        </div>
      )}
      {!isActionOutdated(item) && (
        <div className="flex items-center justify-between shadow-md rounded-lg p-6">
          {!!error && (
            <Text color="red.8" size="md" fw={700} truncate maw="300px">
              {error}
            </Text>
          )}
          <div className="flex flex-col gap-2">
            {!loading && !error && item.attribution.imageUrl && (
              <Image src={item.attribution.imageUrl} alt={item.attribution.name} />
            )}
            {loading && <Skeleton height="30px" width="200px" />}
            {!loading && !error && item.attribution.name && (
              <div className="flex items-center gap-2">
                <ActionIcon
                  icon="delete"
                  color="red.8"
                  onClick={() => {
                    onDismiss([item.genUiAction.id]);
                  }}
                />
                <Text size="md" color="gray.7" fw={700} truncate maw="250px">
                  {item.attribution.name}
                </Text>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 justify-end mt-auto">
            {loading && <Skeleton height="30px" width="120px" />}
            {!loading && !error && item.genUiAction.action && (
              <Text size="md" color="gray.7" fw={700}>
                {getActionTitle(item.genUiAction)}
              </Text>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export function getActionTitle(action: GenUiAction) {
  if (action.action === 'TOGGLE_STATUS') {
    return action.newStatus ? 'Turned On' : 'Turned Off';
  } else if (action.action === 'CHANGE_BUDGET') {
    return `Changed Budget to ${action.newBudget}`;
  } else if (action.action === 'CHANGE_BID') {
    return `Changed Bid to ${action.newBidAmount}`;
  }
  return 'Unknown Action';
}
