import CDPHeader from 'components/headers/CDPHeader';
import { DatePickerHeader } from 'components/headers/DatePickerHeader';
import { DurationFiltersHeader } from 'components/headers/DurationFiltersHeader';
import { InsightsHeader } from 'components/headers/InsightsHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';
import { META_RETENTION_ROUTES } from '@tw/shared-types/module/frontend-config';

export const RETENTION_SECTION: NavSection = {
  title: 'Retention',
  icon: (props) => <NavSectionIcon src="insights" {...props} />,
  routes: [
    {
      url: '/cdp',
      label: 'SCDP',
      ...META_RETENTION_ROUTES['/cdp'],
      header: () => <CDPHeader />,
    },
    {
      url: '/insights/cohorts',
      label: 'Cohorts',
      ...META_RETENTION_ROUTES['/insights/cohorts'],
      header: () => <InsightsHeader />,
    },
    {
      url: '/ltv/bar-view',
      label: 'LTV 60/90 Days',
      ...META_RETENTION_ROUTES['/ltv/bar-view'],
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/ltv/time-between-orders',
      label: 'Sales Cycle',
      ...META_RETENTION_ROUTES['/ltv/time-between-orders'],
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/aov',
      label: 'AOV',
      ...META_RETENTION_ROUTES['/aov'],
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/post-purchase-survey/report',
      label: 'Post-Purchase Survey',
      ...META_RETENTION_ROUTES['/post-purchase-survey/report'],
      header: () => <DatePickerHeader showGroupByButton={false} />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.postPurchaseSurvey.lastUpdate);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/post-purchase-survey/builder',
      label: 'Post-Purchase Survey',
      ...META_RETENTION_ROUTES['/post-purchase-survey/builder'],
      hidden: true,
      header: () => <></>,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.postPurchaseSurvey.lastUpdate);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/store-data',
      label: 'Store Data',
      ...META_RETENTION_ROUTES['/store-data'],
      header: () => <DatePickerHeader />,
      hidden: true,
    },
    {
      url: '/customers',
      label: 'Customers',
      ...META_RETENTION_ROUTES['/customers'],
      header: () => <DatePickerHeader />,
      hidden: true,
    },
    {
      url: '/orders',
      label: 'Orders',
      ...META_RETENTION_ROUTES['/orders'],
      header: () => <DatePickerHeader />,
      hidden: true,
    },
  ],
};
