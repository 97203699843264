import { useCallback, useContext, useMemo, useState } from 'react';
import { WizardContext } from '../WizardContext';
import { Button, TagsInput } from '@tw/ui-components';
import { Role } from 'components/UserProfileManagment/MemberSettings/constants';
import DropDown from 'components/ltv/DropDown';
import { ROLE_OPTIONS } from 'components/UserProfileManagment/MemberSettings/InvitePodModal';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { StepCompletionInfo } from 'types/WizardData';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';

export function WelcomeInviteMembers() {
  const { nextStep, membersData, setMembersData } = useContext(WizardContext);
  const [emails, setEmails] = useState<string[]>([]);
  const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,100}$/;
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [role, setRole] = useState<Role>(Role.Admin);
  const shopId = useStoreValue($currentShopId);
  const [showError, setShowError] = useState(false);
  const { numericLimit: userLimitation } = useFeatureFlag(FeatureFlag.INVITE_USER_LIMIT_FF);

  const isShopUsersLimitReached = useMemo(
    () => !!userLimitation && membersData.length >= userLimitation,
    [membersData, userLimitation],
  );

  const remainingInvites = useMemo(
    () => (userLimitation || 10000000000) - membersData.length,
    [membersData, userLimitation],
  );

  const hasInvitedUsers = useMemo(() => membersData.length > 0, [membersData]);

  const isValidEmails = useMemo(
    () => emails?.length > 0 && emails.every((email) => emailRegex.test(email)),
    [emails, emailRegex],
  );

  const inviteMembers = useCallback(async () => {
    setIsInviteLoading(true);

    if (!isValidEmails) {
      setShowError(true);
      setIsInviteLoading(false);
      return;
    }

    try {
      const uniqueEmails = emails.filter((email) => !membersData.includes(email));
      if (uniqueEmails.length < emails.length) {
        toast.info(
          `Skipping ${emails.length - uniqueEmails.length} emails that have already been invited to your shop.`,
        );
      }

      const { data } = await axiosInstance.post(
        `/v2/account-manager/users/send-batch-invitation-shop`,
        {
          entityId: shopId,
          shopId: shopId,
          emails: uniqueEmails,
          roles: [role],
          invitationType: 'SHOP',
          entityName: shopId,
          baseUrl: window.location.origin,
        },
      );

      const { totalSuccessfull, failedEmails, existingEmails, successfulEmails } = data;

      setMembersData([...membersData, ...successfulEmails]);

      if (!!totalSuccessfull) {
        toast.success(`${totalSuccessfull}/${uniqueEmails.length} Invites sent successfully`);
        const emailEvents = successfulEmails?.map((email) => ({ email, role }));
        setEmails([]);
        genericEventLogger(analyticsEvents.INVITE, {
          action: sequencesActions.SEND_SHOP_INVITATION,
          emails_invited: emailEvents,
          pathname: window.location.pathname,
          shop: shopId,
        });
      }

      if (!!failedEmails?.length) {
        toast.error(`${failedEmails.length}/${uniqueEmails.length} Invites failed`);
      }

      if (!!existingEmails?.length) {
        toast.error(`${existingEmails.length}/${uniqueEmails.length} Invites are already members`);
      }
    } catch (e) {
      console.error(e.message);
      toast.error(`An error has occurred, please refresh the page or try again later`);
    }
    setIsInviteLoading(false);
  }, [role, shopId, membersData, setMembersData, emails]);

  const handleSetEmails = useCallback((emails: string[]) => {
    setEmails(emails);
    setShowError(false);
  }, []);

  return (
    <div className="main-utm page pt-36 flex flex-col gap-8 w-full mx-12 sm:p-16 pb-[80px] sm:pb-0 items-center">
      <div className="copy flex flex-col justify-center items-center">
        <h1 className="step-title">Add Team Members </h1>
        <p className="step-description">
          Easily collaborate by adding team members. Share insights, manage tasks, and automate your
          workflows.
        </p>
      </div>

      <div className="border border-solid border-gray-200 rounded-lg w-full p-8 sm:p-16 max-w-[950px]">
        <div className={'flex-container flex-wrap gap-3'}>
          {/* Email input */}
          <div className={'flex-1 min-w-[90px]'}>
            <TagsInput
              placeholder={emails?.length > 0 ? '' : 'example@gmail.com'}
              label="Email Addresses"
              value={emails}
              onChange={handleSetEmails}
              type="email"
              error={showError && 'Please ensure that all email adresses are valid'}
              maxTags={Math.min(remainingInvites, 50)}
              disabled={isShopUsersLimitReached}
              onFocus={() => setShowError(false)}
            />
          </div>

          {/* Role dropdown */}
          <div className={'control max-[470px]:w-full min-[470px]:mt-[21px]'}>
            <DropDown
              options={ROLE_OPTIONS}
              value={role}
              handleSelect={setRole}
              disabled={isShopUsersLimitReached}
            />
          </div>

          {/* Invite button */}
          <div
            className={`save rounded-button w-full min-[500px]:w-fit md:w-full min-[950px]:w-fit mt-0 min-[500px]:mt-[21px] md:mt-0 min-[950px]:mt-[21px] ${
              !isValidEmails || isInviteLoading ? 'bg-btn-gray' : 'bg-btn-blue'
            }`}
          >
            <Button
              w="100%"
              loading={isInviteLoading}
              onClick={inviteMembers}
              disabled={isInviteLoading || isShopUsersLimitReached}
            >
              Send Invite
            </Button>
          </div>
        </div>

        <div className="text-[0.75rem] text-[#5d5c5c] w-full self-end justify-self-end p-2 border-l mt-8 border-gray-300">
          &bull;{' '}
          {role === Role.Admin || role === Role.Owner
            ? 'Admins can add or remove users, manage integrations, and upgrade plans.'
            : 'Users have read-only access to your store. They cannot add other users or manage integrations.'}
        </div>

        {/* show list of members */}
        {hasInvitedUsers && (
          <div className="mt-8">
            <div className="flex flex-col gap-3">
              <h1 className="text-xxl text-[#536b84]">Pending Invites:</h1>
              {membersData.map((email: any) => (
                <div key={email} className="text-ellipsis overflow-hidden whitespace-nowrap">
                  {email}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
}

export function useMembersInvitedInfo(): StepCompletionInfo {
  const { membersData } = useContext(WizardContext);
  const inviteCount = membersData.length;

  return {
    status: inviteCount ? 'complete' : 'not-started',
    text: inviteCount ? `${membersData.length} Members Invited` : 'No Members Added',
  };
}

export const InvitesIndication: React.FC<any> = () => {
  const { status, text } = useMembersInvitedInfo();

  return (
    <div className={status === 'complete' ? 'text-green' : 'text-red-400'}>
      <span>{text}</span>
    </div>
  );
};
