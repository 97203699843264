import { useEffect } from 'react';
import { Icon, Text, Button } from '@tw/ui-components';
import {
  $bannerState,
  setGlobalBannerVisible,
  useShowNotificationBanner,
} from './hooks/useShowNotificationBanner';
import { notificationStyles } from './notificationStyles';
import { upgradePixelModalOpened } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { useGlobalBannerHeight } from 'hooks/useGlobalBanner';
import { NotificationType } from './types';
import { useBannerTracking } from './utils/tracking';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';

export const BANNER_HEIGHT_VAR = '--banner-height';
export const bannerHeight = 48;
export const mobileBannerHeight = 130;

export const GlobalNotificationBanner = () => {
  const globalBannerHeight = useGlobalBannerHeight();
  const { shouldShowBanner, globalBanner } = useShowNotificationBanner();
  const isGlobalBannerVisible = $bannerState.get().isGlobalBannerVisible;
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const shopId = $currentShopId.get();

  const { handleClick } = useBannerTracking(globalBanner, true, shouldShowBanner);

  if (!shouldShowBanner || !globalBanner || !isGlobalBannerVisible) {
    return null;
  }
  const textColor = notificationStyles[globalBanner.notification_type].color;

  const handleClose = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!shopId || !globalBanner?.notification_id) {
      console.error('Missing required data for banner closure');
      return;
    }
    setGlobalBannerVisible(false);
    try {
      await axiosInstance.post('/v2/banner/banner-management/close-notification', {
        shopId,
        notificationId: globalBanner.notification_id,
      });
    } catch (error) {
      console.error('Failed to close banner:', error);
    }
  };

  return (
    <div
      style={{
        minHeight: isSmall ? `${mobileBannerHeight}px` : `${globalBannerHeight}px`,
        height: isSmall ? `${mobileBannerHeight}px` : `${globalBannerHeight}px`,
        ...notificationStyles[globalBanner.notification_type],
      }}
      className={`w-full z-[200] ${isSmall ? 'relative' : ''} ${
        isSmall ? 'flex flex-col justify-center px-[15px] py-[12px]' : 'flex items-center px-5'
      }`}
    >
      <div className={`flex ${isSmall ? 'flex-col gap-2 pr-[30px]' : 'items-center gap-1'}`}>
        <Text c={textColor} fz={isSmall ? 14 : 16} fw={500}>
          {globalBanner.notification_type === NotificationType.SPECIAL_OFFER
            ? `Limited Time: ${globalBanner.header_text}`
            : globalBanner.header_text}
          {!isSmall && !globalBanner.header_text.match(/[.!?]$/) && '.'}
        </Text>

        <div className="flex items-center gap-4">
          <Text c={textColor} fz={isSmall ? 14 : 16}>
            {globalBanner.body_text}
            {!isSmall && !globalBanner.body_text.match(/[.!?]$/) && '.'}{' '}
            {globalBanner.click_target_modal && (
              <span
                onClick={(e) => {
                  if (isSmall) e.stopPropagation();
                  handleClick();
                  dispatch(
                    upgradePixelModalOpened({
                      source: globalBanner.click_target_modal,
                      notificationType: globalBanner.notification_type,
                    }),
                  );
                }}
                className={`underline cursor-pointer font-medium ${isSmall ? 'z-10 block mt-1' : ''}`}
                style={{
                  color: textColor,
                  fontSize: isSmall ? '14px' : '16px',
                }}
              >
                {globalBanner.cta}
              </span>
            )}
          </Text>
        </div>
      </div>

      <div
        onClick={handleClose}
        className={`cursor-pointer absolute ${isSmall ? 'top-8 right-4' : 'right-[15px]'}`}
      >
        <Icon
          name="close"
          color={(() => {
            const style = notificationStyles[globalBanner.notification_type];
            const color = style.headerColor || style.color;
            return color.startsWith('primary.') ? color.replace('primary.', 'one.') : color;
          })()}
          size={12}
        />
      </div>
    </div>
  );
};

export function useComputeBannerHeight() {
  const shouldShowBanner = useShowNotificationBanner();
  useEffect(() => {
    document.documentElement.style.setProperty(
      BANNER_HEIGHT_VAR,
      (shouldShowBanner ? bannerHeight : 0) + 'px',
    );
  }, [shouldShowBanner]);

  useEffect(() => {
    return () => {
      document.documentElement.style.setProperty(BANNER_HEIGHT_VAR, '0px');
    };
  }, []);
}
