import { useState } from 'react';
import { Allotment } from 'allotment';
import { useStoreValue, useComputedValue } from '@tw/snipestate';
import { Button, Menu, Text } from '@tw/ui-components';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { MENU_SIZE } from './constants';
import { genericEventLogger, analyticsEvents, sequencesActions } from 'utils/dataLayer';

import { Message } from './types/willyTypes';
import { WillyPageWrapper } from './WillyPageWrapper';
import { ChatHistory } from './ChatHistory';
import { WillyBuilder } from './WillyBuilder';
import { WillyDeepSearch } from './WillyDeepSearch';
import { WillySidePanel } from './WillySidePanel';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { Sequences } from './WillySequences';
import { AgentsLibraryWrapper } from './AgentsLibrary/AgentsLibraryWrapper';
import { AgentTemplate } from './AgentsLibrary/AgentTemplate';
import { AiTemplate } from './AgentsLibrary/AiTemplate';

import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { $isAgentBuilderSupported } from '$stores/willy/$sequences';
import { $mainChatStore } from '$stores/willy/$mainChat';

type WillyChatWrapperProps = {
  children: React.ReactNode;
  asPage: boolean;
  messages: Message[];
  conversationId: string;
  clearConversation: () => void;
  currentAnalyticsEvent: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};
export const WillyMainChatWrapper: React.FC<WillyChatWrapperProps> = ({
  children,
  asPage,
  messages,
  clearConversation,
  conversationId,
  currentAnalyticsEvent,
}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const isAgentBuilderSupported = useStoreValue($isAgentBuilderSupported);
  const isAdminAgentCreator = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const buildMode = useComputedValue($mainChatStore, (r) => r.buildMode);
  const deepSearch = useComputedValue($mainChatStore, (r) => r.deepSearch);

  const [newAgentOptionsOpen, setNewAgentOptionsOpen] = useState(false);

  const currentPage =
    WILLY_NAV_ITEMS.find((item) => {
      const pathSegments = pathname.split('/');
      const linkSegments = item.link.split('/');
      if (pathSegments.length !== linkSegments.length) {
        return false;
      }
      return pathSegments.every((seg, index) => {
        const linkSegment = linkSegments[index];
        return linkSegment.startsWith(':') || seg === linkSegment;
      });
    }) || WILLY_NAV_ITEMS[0];

  return (
    <>
      {asPage ? (
        <WillyPageWrapper
          className="willy-page"
          showResizer={true}
          mainContent={
            <>
              <Allotment className="sm:h-dvh sm:max-h-dvh z-0 bg-white" vertical>
                {currentPage.withHeader && (
                  <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE}>
                    <div className="willy-dash-header bg-white flex flex-row items-center lg:w-full h-full grow overflow-hidden overflow-x-auto  px-6 py-4 lg:px-8">
                      <div className="flex items-center justify-between flex-grow">
                        <Text size="lg" weight={500}>
                          {currentPage?.label}
                        </Text>
                      </div>
                      {!!isAgentBuilderSupported && (
                        <>
                          {!isAdminAgentCreator ? (
                            <Button
                              onClick={() => {
                                navigate({ pathname: '/workflows/create', search });
                              }}
                              leftSection="plus-1"
                              iconSize={20}
                            >
                              New Agent
                            </Button>
                          ) : (
                            <Menu
                              shadow="md"
                              width={150}
                              opened={newAgentOptionsOpen}
                              onChange={setNewAgentOptionsOpen}
                            >
                              <Menu.Target>
                                <Button
                                  onClick={() => setNewAgentOptionsOpen((o) => !o)}
                                  leftSection="plus-1"
                                  iconSize={20}
                                >
                                  New Agent
                                </Button>
                              </Menu.Target>

                              <Menu.Dropdown>
                                <Menu.Item
                                  onClick={() => {
                                    genericEventLogger(analyticsEvents.SEQUENCES, {
                                      action: sequencesActions.NEW_AGENT,
                                      type: 'global',
                                    });
                                    navigate({
                                      pathname: '/workflows/create-global',
                                    });
                                  }}
                                >
                                  Global Agent
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    genericEventLogger(analyticsEvents.SEQUENCES, {
                                      action: sequencesActions.NEW_AGENT,
                                      type: 'shop',
                                    });
                                    navigate({ pathname: '/workflows/create', search });
                                  }}
                                >
                                  Shop Agent
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          )}
                        </>
                      )}
                    </div>
                  </Allotment.Pane>
                )}
                <Allotment.Pane>
                  <Allotment>
                    <Allotment.Pane>
                      {currentPage.isWorkflowPage ? (
                        <Sequences />
                      ) : currentPage.id === 'agents_library' ? (
                        <AgentsLibraryWrapper />
                      ) : currentPage.id === 'agent_template' ? (
                        <AgentTemplate />
                      ) : currentPage.id === 'aicolumn_template' ? (
                        <AiTemplate />
                      ) : (
                        <div className="m-auto h-full pb-6 sm:py-12">
                          {currentPage.id === 'history' && (
                            <div className="h-full overflow-auto" id="chat-history">
                              <ChatHistory />
                            </div>
                          )}
                          {(currentPage.id === 'chat' ||
                            currentPage.id === 'chat_with_sequence_run') &&
                            children}
                        </div>
                      )}
                    </Allotment.Pane>

                    {deepSearch && currentPage.id === 'chat' && (
                      <WillyDeepSearch messages={messages} conversationId={conversationId} />
                    )}

                    {buildMode && (
                      <WillyBuilder messages={messages} conversationId={conversationId} />
                    )}
                  </Allotment>
                </Allotment.Pane>
              </Allotment>
            </>
          }
          hideSidePanel={false}
          sidePanelContent={
            <WillySidePanel
              currentPage={currentPage.id}
              clearConversation={clearConversation}
              conversationId={conversationId}
              currentAnalyticsEvent={currentAnalyticsEvent}
            />
          }
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
