import { Button } from '@shopify/polaris';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { useEffect, useMemo, useState } from 'react';
import FilterComparator from 'components/Insights/Filters/FilterComparator';
import DropDown from '../../ltv/DropDown';
import { DeleteMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import {
  storeBuilderDataToSave,
  updateAndApplyInsightsFilter,
  clearFilterBuilderData,
  clearAppliedFilters,
  applyAnonymousFilter,
  clearAnonymousFilter,
  reloadData,
  FiltersContext,
} from 'ducks/insights/filterReducers';
import { useAppDispatch } from 'index';
import {
  FilterProperty,
  FilterPropertyCategory,
  InsightsFilterQuery,
} from '@tw/types/module/services/insights';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { getUniqueId, isFilterDirty } from './utils';
import { TWTooltip } from 'components/library/TWTooltip/TWTooltip';
import { DEFAULT_COMPARATORS, getPropertyCategory, getPropertyType } from './constants';
import { uniq } from 'lodash';
import { getFilterQueryLabel } from './queryTranslation';
import CircleLockIcon from 'components/library/LockedFeatures/CircleLockIcon';
import { useFeatureFlag } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';

import './filters-component.scss';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

const addIdToArr = (arr: any[]) => {
  return arr.map((x) => ({ ...x, id: getUniqueId() }));
};

const HrTagSpacer = ({ relation }: { relation?: 'OR' | 'AND' }) => {
  return (
    <div className={`bg-[#F2F2F2] w-full my-[15px] scale-x-110 relative h-[1px]`}>
      {relation && (
        <p className="px-2 text-[#cfd1d5] bg-white absolute left-24 top-[-10px] font-medium">
          {relation}
        </p>
      )}
    </div>
  );
};

type Props = {
  filterProperties: FilterProperty[];
  filtersContext: FiltersContext;
  onClose: () => void;
  onSave: () => void;
  filterDescription?: {
    tooltipText: string;
    titleText: string;
  };
};
export default function FilterModal({
  filterProperties,
  filtersContext,
  onClose,
  onSave,
  filterDescription,
}: Props) {
  const isSmall = useIsSmall();
  const dispatch = useAppDispatch();
  const { currentFilterToEdit } = useSelector((state: RootState) => state.insightsFilter);
  const { [filtersContext]: appliedAnonymousFilter } = useSelector(
    (state: RootState) => state.insightsFilter.appliedAnonymousFilter,
  );
  const [filter, setFilter] = useState<InsightsFilterQuery>([]);
  const categories: FilterPropertyCategory[] = useMemo(() => {
    return uniq(filterProperties.map((prop) => getPropertyCategory(prop)));
  }, [filterProperties]);

  const [selectedTab, setSelectedTab] = useState<string>(categories[0]);
  const { shouldNotBeSeen } = useFeatureFlag(FeatureFlag.PIXEL_ALL_FF);
  const isPixelEnabeld = !shouldNotBeSeen;

  const currentTabProperties: FilterProperty[] = useMemo(() => {
    return filterProperties.filter((prop) => getPropertyCategory(prop) === selectedTab);
  }, [filterProperties, selectedTab]);

  useEffect(() => {
    // navigate to the first tab with a dirty filter
    const firstProperty = currentFilterToEdit.query[0]?.property;
    if (firstProperty) {
      setSelectedTab(getPropertyCategory(firstProperty));
    }

    if (appliedAnonymousFilter) {
      for (const tab of categories) {
        if (appliedAnonymousFilter.some((x) => getPropertyCategory(x.property) === tab)) {
          setSelectedTab(tab);
          break;
        }
      }
    }

    const initial = currentFilterToEdit.query.length
      ? addIdToArr(currentFilterToEdit.query)
      : appliedAnonymousFilter
        ? addIdToArr(appliedAnonymousFilter)
        : [];
    setFilter(initial);
  }, []);

  useEffect(() => {
    if (!selectedTab) return;

    setFilter((prev) => {
      const copy = [...prev];
      if (copy.some((f) => !isFilterDirty(f))) {
        const pristine = [...copy].reverse().find((f) => !isFilterDirty(f)); // findLast
        pristine!.property = currentTabProperties[0];
      }

      if (
        copy.every((f) => isFilterDirty(f)) &&
        !copy.some((f) => getPropertyCategory(f.property) == selectedTab)
      ) {
        const newFilterCondition = {
          property: currentTabProperties[0],
          value: '',
          comparator: DEFAULT_COMPARATORS[getPropertyType(currentTabProperties[0])],
        };

        newFilterCondition['local_id'] = getUniqueId();
        copy.push(newFilterCondition);
      }
      return [...copy];
    });
  }, [selectedTab, currentTabProperties]);

  const addFilter = () => {
    const newFilterCondition = {
      property: currentTabProperties[0],
      value: '',
      comparator: DEFAULT_COMPARATORS[getPropertyType(currentTabProperties[0])],
    };

    newFilterCondition['local_id'] = getUniqueId();
    setFilter([...filter, newFilterCondition]);
  };

  const resetFilter = () => {
    const newFilterCondition = {
      property: currentTabProperties[0],
      value: '',
      comparator: DEFAULT_COMPARATORS[getPropertyType(currentTabProperties[0])],
    };

    newFilterCondition['local_id'] = getUniqueId();

    setFilter([newFilterCondition]);
  };

  const applyFilter = async () => {
    dispatch(clearAppliedFilters(filtersContext));
    dispatch(applyAnonymousFilter(filter, filtersContext));
    onClose();
  };

  const editFilter = () => {
    dispatch(
      updateAndApplyInsightsFilter(
        { ...currentFilterToEdit, query: filter.filter((f) => isFilterDirty(f)) },
        filtersContext,
      ),
    );
    dispatch(reloadData());
    onClose();
  };

  const createFilter = async () => {
    try {
      dispatch(
        storeBuilderDataToSave({
          ...currentFilterToEdit,
          query: filter,
        }),
      );
      onSave();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="filters-component">
      <DraggableModal
        open={true}
        onClose={() => {
          if (!applyAnonymousFilter.length) {
            dispatch(clearAnonymousFilter(filtersContext));
          }
          if (currentFilterToEdit.query) {
            dispatch(clearFilterBuilderData());
          }
          onClose();
        }}
        width="wide"
        title="Cohorts Filter"
        initialPosition="center center"
        style={{ background: 'white', maxWidth: '900px', overflowX: 'hidden' }}
        usingDragger={true}
      >
        <div className="flex-col w-full h-max sm:w-[65vw] sm:min-w-[350px] sm:h-[47vh] sm:min-h-[350px] sm:flex sm:flex-row">
          {isSmall && (
            <FilterTitle
              title={currentFilterToEdit.name || 'Custom Filter'}
              filterDescription={filterDescription}
            />
          )}
          <div className="w-full sm:w-1/4 sm:max-w-[150px] sm:h-full bg-[#F2F2F2] flex flex-col px-6 py-1 ms:p-6">
            <ul className="overflow-auto list-none pl-2 sm:mt-20 flex sm:flex-col my-1">
              {categories.map((cat) => (
                <div
                  key={cat}
                  onClick={() => setSelectedTab(cat)}
                  className={`flex items-center justify-between py-2 pl-4  pr-3 cursor-pointer hover:bg-white rounded ${
                    cat === selectedTab ? 'bg-white font-semibold' : ''
                  }`}
                >
                  <li>{cat.charAt(0).toUpperCase() + cat.slice(1)}</li>
                  {filter.some(
                    (f) => getPropertyCategory(f.property) === cat && isFilterDirty(f),
                  ) && <span className="rounded-full bg-red-500 w-2 h-2 ml-2 sm:ml-0"></span>}
                  <div className="w-6 h-6">
                    {!isPixelEnabeld && ['attribution'].includes(cat) && (
                      <CircleLockIcon size={15} />
                    )}
                  </div>
                </div>
              ))}
            </ul>
          </div>

          <div className="w-full h-full p-[15px] flex flex-col justify-between overflow-x-hidden">
            {!isSmall && (
              <FilterTitle
                title={currentFilterToEdit.name || 'Custom Filter'}
                filterDescription={filterDescription}
              />
            )}
            <HrTagSpacer />
            {(isPixelEnabeld || !['attribution'].includes(selectedTab)) && (
              <div className="scrollable grow overflow-auto">
                {filter
                  .filter((flter) => getPropertyCategory(flter.property) == selectedTab)
                  .map((fltr, index) => (
                    <div
                      className="filter-group relative"
                      key={`filter-comparator-${fltr['local_id']}`}
                    >
                      <div className="filters py-3 flex flex-wrap gap-4">
                        <DropDown
                          options={currentTabProperties.map((prop) => ({
                            label: getFilterQueryLabel(prop),
                            value: prop,
                          }))}
                          value={fltr.property}
                          handleSelect={(value) => {
                            setFilter((prev) => {
                              const copy = [...prev];
                              const curr = copy.find((f) => f['local_id'] === fltr['local_id']);
                              curr!.property = value;
                              curr!.comparator = DEFAULT_COMPARATORS[getPropertyType(value)];
                              curr!.value = '';
                              return [...copy];
                            });
                          }}
                        />
                        <div className="flex flex-wrap gap-[10px] items-center">
                          <FilterComparator
                            key={`${index}-${fltr.property}`}
                            comparatorType={getPropertyType(fltr.property)}
                            comparator={fltr.comparator}
                            value={fltr.value}
                            value1={fltr.value1}
                            value2={fltr.value2}
                            unit={fltr.unit}
                            property={fltr.property}
                            onChanged={({ comparator, value, value1, value2, unit }) => {
                              setFilter((prev) => {
                                const copy = [...prev];
                                const curr = copy.find((f) => f['local_id'] === fltr['local_id']);
                                curr!.comparator = comparator;
                                curr!.value = value ?? '';
                                curr!.value1 = value1 ?? '';
                                curr!.value2 = value2 ?? '';
                                curr!.unit = unit ?? '';
                                curr!.property = fltr.property;
                                return [...copy];
                              });
                            }}
                          ></FilterComparator>
                          <div className="flex p-[8px]">
                            <Button
                              disabled={filter.length === 1}
                              plain
                              onClick={() => {
                                setFilter((prev) => {
                                  const copy = [...prev];
                                  const newArry = copy.filter(
                                    (f) => f['local_id'] !== fltr['local_id'],
                                  );
                                  return [...newArry];
                                });
                              }}
                              icon={DeleteMinor}
                            />
                          </div>
                        </div>
                      </div>
                      {filter.filter((flter) => getPropertyCategory(flter.property) == selectedTab)
                        .length -
                        1 !==
                        index && <HrTagSpacer relation="OR" />}
                    </div>
                  ))}
                <div className="flex items-center m-4 ml-0 ">
                  <div
                    className="text-blue-500 cursor-pointer pl-4 flex justify-start items-center "
                    onClick={addFilter}
                  >
                    <span className="pl-2 text-[16px]"> + Add filter</span>
                  </div>
                </div>
              </div>
            )}
            {!isPixelEnabeld && ['attribution'].includes(selectedTab) && (
              <UpgradePageFallBack
                InAppContextBannerEnabled={false}
                InAppContextBannerPage={'attribution'}
                featureFlags={[FeatureFlag.PIXEL_ALL_FF]}
              />
            )}
            <HrTagSpacer />
            <footer className="flex justify-end items-center gap-7 w-full">
              <span
                className="py-3 px-4 font-medium bg-white text-blue-500 hover:underline cursor-pointer"
                onClick={resetFilter}
              >
                Reset Filter
              </span>

              {!currentFilterToEdit.id && (
                <span
                  onClick={filter.some((f) => isFilterDirty(f)) ? createFilter : () => {}}
                  className={`py-3 px-10 font-medium border-solid bg-white border rounded ${
                    filter.every((f) => !isFilterDirty(f))
                      ? 'border-gray-300 text-gray-400  cursor-not-allowed'
                      : ' border-blue-500 text-blue-500  hover:bg-blue-500 hover:text-white cursor-pointer'
                  }`}
                >
                  Save
                </span>
              )}
              {!currentFilterToEdit.id && (
                <span
                  onClick={filter.some((f) => isFilterDirty(f)) ? applyFilter : () => {}}
                  className={`py-3 px-10 font-medium rounded ${
                    filter.every((f) => !isFilterDirty(f))
                      ? ' bg-gray-300 border border-gray-300 border-solid text-white cursor-not-allowed'
                      : ' bg-blue-500 border border-blue-500 border-solid text-white hover:text-blue-500 hover:bg-white cursor-pointer'
                  }`}
                >
                  Apply
                </span>
              )}
              {currentFilterToEdit.id && (
                <span
                  onClick={filter.some((f) => isFilterDirty(f)) ? editFilter : () => {}}
                  className={`py-3 px-7 font-medium border border-solid rounded ${
                    filter.every((f) => !isFilterDirty(f))
                      ? ' bg-gray-300  border-gray-300 text-white cursor-not-allowed'
                      : 'bg-blue-500  border-blue-500 text-white hover:text-blue-500 hover:bg-white cursor-pointer'
                  }`}
                >
                  Save and Apply
                </span>
              )}
            </footer>
          </div>
        </div>
      </DraggableModal>
    </div>
  );
}

const FilterTitle = ({
  title,
  filterDescription,
}: {
  title: string;
  filterDescription?: {
    tooltipText: string;
    titleText: string;
  };
}) => {
  const isSmall = useIsSmall();

  return (
    <div className={`${isSmall ? 'pl-3 py-1' : ''}`}>
      <h1 className="text-3xl mb-1 pl-3 pt-5">
        {title} {''}
        {filterDescription?.tooltipText && (
          <TWTooltip content={filterDescription?.tooltipText}>
            <InfoIcon width={15} fill="#374151" />
          </TWTooltip>
        )}
        <p className="text-lg mt-1 -mb-2">{filterDescription?.titleText}</p>
      </h1>
    </div>
  );
};
