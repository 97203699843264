import { Card, Flex, Text, Icon, Accordion, Box, Loader } from '@tw/ui-components';
import { StepsPlan, WillyToolName } from './types/willyTypes';
import { WillySimpleText } from './WillySimpleText';
import { willyToolMap } from './constants';

const StepBlock = ({ step }: { step: StepsPlan['steps'][number] }) => {
  const currentTool = willyToolMap[step.step_type as WillyToolName];
  const title = step.step_title;
  const name = currentTool?.title || step.step_type;
  const type = step.step_type;
  const agentName = step.agent_name;
  const icon = currentTool?.icon || 'triple-whale-book';
  const color = currentTool?.color || 'one.5';
  const statusTitle = step.step_status.replace('-', ' ');
  const statusColor =
    step.step_status === 'pending'
      ? 'gray'
      : step.step_status === 'in-progress'
        ? 'one'
        : step.step_status === 'completed'
          ? 'green'
          : 'red';
  const implementationDetails =
    step.implementation_details && Object.keys(step.implementation_details).length > 0
      ? step.implementation_details
      : null;
  const outputData = step.output_data && step.output_data.length > 0 ? step.output_data : null;
  const outputType = implementationDetails?.output_type;
  const inProgress = step.step_status === 'in-progress';

  return (
    <Card key={step.step_id} withBorder p={0}>
      <div className="overflow-hidden rounded-[5px]">
        <Flex direction="column" gap="xs">
          <Accordion>
            <Accordion.Item value="step-details">
              <Accordion.Control>
                <Flex justify="space-between" align="center" w="100%" gap="xs">
                  <Flex align="center" gap="xs">
                    <div
                      className={`w-12 h-12 rounded-full flex items-center justify-center font-bold flex-shrink-0`}
                      style={{
                        backgroundColor: `var(--mantine-color-${statusColor}-1)`,
                        color: `var(--mantine-color-${statusColor}-6)`,
                      }}
                    >
                      {step.step_id}
                    </div>
                    <Flex direction="column">
                      <Text fw="bold" size="sm">
                        {title}
                      </Text>
                      <div className="flex items-center gap-2">
                        {inProgress && <Loader size={12} />}
                        <Icon name={icon} color={color} size={12} />
                        <Text size="xs" c="gray.5">
                          {name}
                        </Text>
                      </div>
                    </Flex>
                  </Flex>
                  <div
                    className={`text-[12px] capitalize px-4 py-1 mr-4 rounded-full whitespace-nowrap`}
                    style={{
                      backgroundColor: `var(--mantine-color-${statusColor}-1)`,
                      color: `var(--mantine-color-${statusColor}-6)`,
                    }}
                  >
                    {statusTitle}
                  </div>
                </Flex>
              </Accordion.Control>
              {implementationDetails && (
                <Accordion.Panel>
                  <Flex direction="column" gap="xs" pt="xs">
                    {agentName && (
                      <Text fw="bold" tt="capitalize" mb="xs">
                        {agentName}
                      </Text>
                    )}
                    {Object.entries(implementationDetails).map(([key, value]) => (
                      <div key={key}>
                        <Text size="xs" fw="bold" tt="capitalize">
                          {key.replace(/_/g, ' ')}
                        </Text>
                        <Card withBorder p="xs" bg="gray.0">
                          <Text>
                            {typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                          </Text>
                        </Card>
                      </div>
                    ))}
                    {outputData && (
                      <Box mt="xs">
                        <Text size="xs" fw="bold" tt="capitalize">
                          Output
                        </Text>
                        <Card withBorder p="xs" bg="gray.0">
                          {/* for now we are not showing the output data for visualization due to rendering issues */}
                          {outputType?.includes('visualization') ? (
                            <Text>Final report visualization generated</Text>
                          ) : (
                            <WillySimpleText text={outputData} />
                          )}
                        </Card>
                      </Box>
                    )}
                  </Flex>
                </Accordion.Panel>
              )}
            </Accordion.Item>
          </Accordion>
        </Flex>
      </div>
    </Card>
  );
};

export const WillyStepsPlanner = ({ stepsPlan }: { stepsPlan: StepsPlan }) => {
  const { plan_title, plan_objective, total_steps, steps } = stepsPlan;

  return (
    <div className="w-full flex-auto dark:bg-transparent bg-white rounded shadow">
      <div className=" border-0 border-solid border-b-[1px] border-black/10 p-4">
        <Flex align="center" justify="space-between">
          <Text fw="bold" size="lg">
            Deep Dive Plan
          </Text>
          <Text size="sm" c="gray.5">
            {total_steps} Steps
          </Text>
        </Flex>
      </div>

      <div className="flex flex-col p-4 gap-4">
        <div className="border-0">
          <Text fw="bold" size="md">
            {plan_title}
          </Text>
          <Text size="sm" c="gray.5" mt="xs">
            {plan_objective}
          </Text>
        </div>
        {steps.map((step) => {
          return <StepBlock key={step.step_id} step={step} />;
        })}
      </div>
    </div>
  );
};
