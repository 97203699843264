import React, {
  Component,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AnswerNlqQuestionParams,
  Conversation,
  Message,
  FormattedDashboardDataElement,
  PreloadSequenceMessageData,
  MobyUploadedFile,
} from './types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { v4 as uuidV4 } from 'uuid';
import {
  analyticsEvents,
  genericEventLogger,
  chatActions,
  dashboardsActions,
  sqwhaleActions,
} from 'utils/dataLayer';
import {
  answerNlqQuestion,
  createMessageFromDb,
  getColumnsFromMessage,
  getMetricsDictionary,
  getQueryIdFromMessage,
  getSqlFromMessage,
} from './utils/willyUtils';
import { useAppSelector } from 'reducers/RootType';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDarkMode } from 'dark-mode-control';
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons';
import { useWindowSize } from 'utils/useWindowSize';
import { Mention, MentionsInputProps } from 'react-mentions';
import axiosInstance from 'utils/axiosInstance';
import { User } from 'components/UserProfileManagment/User/constants';
import _db from 'utils/DB';
import { useWillySocket } from './WillySocket';
import { WillyMessageTemplate } from './WillyMessageTemplate';
import { isMobileApp } from 'utils/Device';
import ScrollToBottom, { useAtEnd } from 'react-scroll-to-bottom';
import { WillyScrollToBottom, WillyScrollToBottomRef } from './WillyScrollToBottom';
import { WillySuggestQuestions } from './WillySuggestQuestions';
import {
  Tooltip,
  Text,
  Anchor,
  NumRange,
  colors,
  Menu,
  ActionIcon,
  Loader,
  Modal,
} from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { ChatIcon } from 'components/Icons/ChatIcon';
import { $activeAccounts, $currency, $industry } from '../../$stores/$shop';
import { $dialect, $isAdminClaim } from '$stores/$user';
import { fromPairs } from 'lodash';
import { useChatSocket } from './hooks/useChatSocket';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import {
  MentionedUser,
  MetricDict,
  WillyMainChatProps,
  WillyMainChatRef,
} from 'components/Willy/types/willyTypes';
import { emptyArray } from 'utils/emptyArray';
import {
  $fromPremiumPlusToMobyModal,
  $shouldShowMobyUpgradeCtas,
  $showFromPremiumPlusToMobyModal,
  $showMobyPopup,
  openUnlockMobyModal,
} from '$stores/willy/$upgradePopupManager';
import { ForecastModel, FORECAST_MODELS } from './constants';
import { WillyMainChatModelSelectors } from './WillyMainChatModelSelectors';
import { WillyMainChatForecastModelSelector } from './WillyMainChatForecastModelSelector';
import { WillyMainChatLandingPageSelector } from './WillyMainChatLandingPageSelector';
import { useUpgradeButton } from '../UpgradePlan/components/useUpgradeButton';
import { ChatInput } from './ChatInput';
import { WillyChatLP } from './WillyChatLP';
import { $mainChatStore } from '$stores/willy/$mainChat';
import { WillyMainChatWrapper } from './WillyMainChatWrapper';
import {
  $showCustomizeMobyPopup,
  openCustomizePromptsPopup,
} from '$stores/willy/$customizePromptPopup';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { DropZone } from '@shopify/polaris';
import moment from 'moment';
import { WillySimpleText } from './WillySimpleText';
import { SequencesAllList } from './SequencesAllList';
import { WillyBetaChatLP } from './WillyBetaChatLP';
import { addChatToWatch } from '$stores/willy/$chatsToWatch';
import { ChatTimer } from './ChatTimer';
import { ChatSourcesUsed } from './ChatSourcesUsed';
import { $hasMobyFeedPopulated, $loadingMobyFeed } from '$stores/willy/$mobyFeed';
import { deepSearchChatMessages } from './utils/deepSearchMessages';

const chatTitleCarouselOptions = [
  'What can we do for you today?',
  'Analyze your data',
  'Discover insights',
  'Get recommendations',
  'Write new copy',
];

const carouselHeight = 30;

const mainChatPillPrompts = [
  {
    name: 'Prompt Guide',
    link: 'https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
    prompt:
      'Give me tips and best practices on how to prompt Moby, including:\n1) What categories of information should I include in my prompts?\n2) How can I specify metrics from the Triple Whale Metric Dictionary?\n3) What sources are available for getting inspiration for prompts?\n4) What should I do if I get a bad response?\n5) When should I start a new conversation?\n6) What’s the best way to ask for analysis, insights, or recommendations?\n7) Can I ask questions about Triple Whale, the pixel, or attribution?\n8) How can I get forecasts?\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
  },
  {
    name: 'Getting Started',
    link: 'https://kb.triplewhale.com/en/articles/9118137-getting-started',
    prompt:
      'Give me guidance on how to start using Moby, including:\n1) What to expect\n2) How to ask for data\n3) How to validate/check the data\n4) How to make visualizations\n5) How to add widgets to boards\n6) How to get analysis, insights, and recommendations\n7) What are sequences, why are they so valuable, how to run them and how to save them\n8) How to generate forecasts\n9) Where to find inspiration for prompts.\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9118137-getting-started',
  },
];

export type PromptTopic = {
  topic: string;
  cats: string[];
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
};

export const WillyMainChat = forwardRef<WillyMainChatRef, WillyMainChatProps>(
  (
    {
      asPage = false,
      showLogo = true,
      messagesWrapperClassName = '',
      messages,
      conversationId,
      initialQuery,
      isReadyForChat = true,
      returnQueryOnly = false,
      codeActions,
      withoutInputBackground,
      withoutInputShadow,
      source,
      hideSuggestions,
      isDashboardPage,
      setConversationId,
      setMessages,
      chatSourceIds,
      activeVersion,
      hidePills = false,
      dashboardId,
      dashboardName,
      dashboardData,
      summaryData,
      pixelData,
      sequenceName,
      sequenceRunData,
      promptsToUse,
      chatWithSequenceId,
      loadingGeneratedPrompts,
      sequenceLastRunAt,
      lastInsightStep,
      rerunSequnece,
      workflowRunning,
      loadingInsightsStep,
    },
    ref,
  ) => {
    const isAgentSupported = useStoreValue($isAgentSupported);
    const hasFeedPopulated = useStoreValue($hasMobyFeedPopulated);
    const loadingFeed = useStoreValue($loadingMobyFeed);
    const activePage = useComputedValue($mainChatStore, (x) => x.activePage);
    const inputRef = useRef<Component<MentionsInputProps, any, any>>(null);
    const scrollToBottomRef = useRef<WillyScrollToBottomRef>(null);
    const shouldSetConversationRef = useRef(true);
    const isMounted = useRef(false);
    const isScrolledToMessage = useRef(false);
    const atEnd = useAtEnd();
    const { search, hash } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const navigate = useNavigate();
    const doDarkMode = useDarkMode();
    const windowSize = useWindowSize();
    const dispatch = useAppDispatch();

    const dialect = useStoreValue($dialect);
    const industry = useStoreValue($industry);
    const activeAccounts = useStoreValue($activeAccounts);
    const { linkText, action } = useUpgradeButton('chat', true);
    const currentShopId = useAppSelector((state) => state.currentShopId);
    const user = useAppSelector((state) => state.user);
    const currency = useStoreValue($currency);
    const shopTimezone = useAppSelector((state) => state.shopTimezone);
    const isUserAdmin = useStoreValue($isAdminClaim);
    const shouldShowMobyUpgradeCtas = useStoreValue($shouldShowMobyUpgradeCtas);
    const [activeChatTitleCarouselIndex, setActiveChatTitleCarouselIndex] = useState(0);
    const [value, setValue] = useState<string>('');
    const {
      sqlGeneratingModel,
      customSqlModel,
      customModelToolsName,
      modelToolsName,
      deepSearch,
      deepSearchModel,
      customDeepSearchModel,
      buildMode,
    } = useStoreValue($mainChatStore);
    const [forecastModel, setForecastModel] = useState<ForecastModel>(FORECAST_MODELS[0]);
    const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
    const [showFollowUpQuestions, setShowFollowUpQuestions] = useState(true);
    const [conversationUser, setConversationUser] = useState<string>(user.uid || '');
    const [shopUsers, setShopUsers] = useState<MentionedUser[]>([
      {
        id: user.uid!,
        display: user.email!,
      },
    ]);
    const [loadingShopUsers, setLoadingShopUsers] = useState(false);
    const [mentionedUsers, setMentionedUsers] = useState<MentionedUser[]>([]);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [conversationNotFound, setConversationNotFound] = useState(false);
    const [userName, setUserName] = useState<string>('');
    const [lastMessageId, setLastMessageId] = useState<string>('');
    const [willyLoading, setWillyLoading] = useState(false);
    const [currentConversation, setCurrentConversation] = useState<Conversation>();
    const [metricsDict, setMetricsDict] = useState<Record<string, MetricDict>>();
    const [selectedImage, setSelectedImage] = useState<MobyUploadedFile | null>(null);

    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<MobyUploadedFile[]>([]);

    const conversationMembers = useMemo(() => {
      const mentionedUsers = currentConversation?.users || [];
      return [conversationUser, ...mentionedUsers];
    }, [conversationUser, currentConversation]);

    const lastMessageWithData = useMemo(() => {
      return messages?.findLast(
        (x) => !!x.toolResults?.name && ['TextToSQL'].includes(x.toolResults.name),
      );
    }, [messages]);

    const deepSearchSource = useMemo(() => {
      return source === 'deepSearch' ? true : false;
    }, [source]);

    const currentAnalyticsEvent = useMemo(() => {
      return source === 'chat' || deepSearchSource
        ? analyticsEvents.CHAT
        : source === 'editor'
          ? analyticsEvents.SQWHALE
          : analyticsEvents.DASHBOARDS;
    }, [source, deepSearchSource]);

    const currentAnalyticsActionSet = useMemo(() => {
      return source === 'chat' || source === 'sequence' || source === 'deepSearch'
        ? chatActions
        : source === 'editor'
          ? sqwhaleActions
          : dashboardsActions;
    }, [source]);

    const ExpandOrCollapse = showFollowUpQuestions ? ChevronDownMinor : ChevronUpMinor;

    const isEmptyState = useMemo(() => messages.length === 0, [messages]);

    const showingAgentFeed = useMemo(() => {
      return activePage === 'Questions'
        ? false
        : isAgentSupported &&
            isEmptyState &&
            !chatWithSequenceId &&
            !isDashboardPage &&
            (hasFeedPopulated || loadingFeed);
    }, [
      isAgentSupported,
      isEmptyState,
      chatWithSequenceId,
      isDashboardPage,
      activePage,
      hasFeedPopulated,
      loadingFeed,
    ]);

    useEffect(() => {
      if (!!chatWithSequenceId && !isEmptyState && !showingAgentFeed) {
        scrollToBottomRef;
      }
    }, [isEmptyState, chatWithSequenceId, showingAgentFeed]);

    const showNewLP = useMemo(() => {
      return (
        (!isDashboardPage || (isDashboardPage && (!!promptsToUse || !!loadingGeneratedPrompts))) &&
        !initialQuery &&
        isEmptyState &&
        !loadingConversation
      );
    }, [
      initialQuery,
      isDashboardPage,
      isEmptyState,
      loadingConversation,
      promptsToUse,
      loadingGeneratedPrompts,
    ]);

    const showBetaLP = useMemo(() => {
      return showNewLP && activePage === 'Beta';
    }, [showNewLP, activePage]);

    const { socket, isConnected } = useWillySocket();

    const showMobyPopup = useStoreValue($showMobyPopup);
    const showFromPremiumPlusToMobyModal = useStoreValue($showFromPremiumPlusToMobyModal);
    const showCustomizeMobyPopup = useStoreValue($showCustomizeMobyPopup);

    useEffect(() => {
      let timeoutId1, timeoutId2, timeoutId3;
      if (showMobyPopup && !showFromPremiumPlusToMobyModal && source === 'chat') {
        timeoutId1 = setTimeout(() => {
          openUnlockMobyModal(true);
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      if (showFromPremiumPlusToMobyModal && source === 'chat') {
        timeoutId2 = setTimeout(() => {
          $fromPremiumPlusToMobyModal.set({
            show: true,
          });
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      return () => {
        if (timeoutId1) {
          clearTimeout(timeoutId1);
        }
        if (timeoutId2) {
          clearTimeout(timeoutId2);
        }
      };
    }, [showFromPremiumPlusToMobyModal, showMobyPopup, source]);

    useEffect(() => {
      let timeoutId1;
      if (showCustomizeMobyPopup && source === 'chat') {
        timeoutId1 = setTimeout(() => {
          openCustomizePromptsPopup(true);
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      return () => {
        if (timeoutId1) {
          clearTimeout(timeoutId1);
        }
      };
    }, [showCustomizeMobyPopup, source]);

    const streamingMode = useMemo(() => {
      return isConnected;
    }, [isConnected]);

    const lastMessageFromUser = useMemo(() => {
      return messages.findLast((message) => message.role === 'user');
    }, [messages]);

    const inputPlaceholder = useMemo(() => {
      if (!conversationMembers.includes(user.uid!) && !isUserAdmin) {
        return `You are not part of this conversation`;
      }

      if (showBetaLP) {
        return 'Ask anything';
      }
      if (asPage) {
        return 'How can I help you today?';
      }

      return undefined;
    }, [conversationMembers, user.uid, isUserAdmin, showBetaLP, asPage]);

    const metricsData = useMemo(() => {
      if (!metricsDict) {
        return [];
      }
      return Object.values(metricsDict)
        .map((colInfo) => {
          return {
            id: colInfo.Name,
            display: colInfo.Name,
          };
        })
        .sort((a, b) => a.display.localeCompare(b.display));
    }, [metricsDict]);

    const addMessage = useCallback(
      (message: Message, appendText?: boolean) => {
        setMessages((messages) => {
          if (messages.some((m) => m.id === message.id)) {
            return messages.map((m) => {
              if (m.id === message.id) {
                return {
                  ...m,
                  ...message,
                  text: appendText ? `${m.text}${message.text}` : message.text || m.text,
                  uploadedFiles: message.uploadedFiles || m.uploadedFiles,
                };
              }
              return m;
            });
          } else {
            return messages.concat(message);
          }
        });
        // scrollToBottomRef.current?.scrollToBottom();
      },
      [setMessages],
    );

    useChatSocket({
      setMessages,
      setWillyLoading,
      source,
      currentConversationId: conversationId,
    });

    const resetLoading = useCallback(() => {
      setWillyLoading(false);
    }, []);

    const uniqueMentioned = useMemo(() => {
      const mentioned = mentionedUsers.filter((x) => value.includes(`${x.display}`));
      const uniqueById = mentioned.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, emptyArray<MentionedUser>());

      return uniqueById;
    }, [mentionedUsers, value]);

    const showSuggestions = useMemo(() => {
      if (!isEmptyState) {
        return false;
      }

      if (loadingConversation) {
        return false;
      }

      if (hideSuggestions) {
        return false;
      }

      return true;
    }, [isEmptyState, loadingConversation, hideSuggestions]);

    const computedBackground = useMemo(() => {
      return {
        background: withoutInputBackground ? 'transparent' : '#ffffff',
      };
    }, [withoutInputBackground]);

    const chatTitleCarouselStyles = useMemo(() => {
      return {
        transform: `translateY(-${carouselHeight * activeChatTitleCarouselIndex}px)`,
      };
    }, [activeChatTitleCarouselIndex]);

    const conversationIdFromQS = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('conversationId');
    }, [search]);

    const messageIdFromHash: string | undefined = useMemo(() => {
      return hash.split('#')[1];
    }, [hash]);

    const promptFromLibrary: string | null = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('prompt');
    }, [search]);

    useEffect(() => {
      if (!!promptFromLibrary) {
        setValue(promptFromLibrary);
      }
    }, [promptFromLibrary]);

    const formattedSequenceRunData = useMemo(() => {
      const dd = {};
      for (let key in sequenceRunData) {
        const message = sequenceRunData[key] as PreloadSequenceMessageData;
        dd[key] = message;
      }
      return {
        name: sequenceName ?? '',
        data: dd,
      };
    }, [sequenceRunData, sequenceName]);

    const filteredMessages = useMemo(() => {
      if (deepSearch) {
        return deepSearchChatMessages(messages);
      } else if (!buildMode) {
        const lastStepsPlannerIndex = [...messages]
          .reverse()
          .findIndex((message) => message.toolResults?.name === 'StepsPlanner');

        if (lastStepsPlannerIndex === -1) return messages;

        const lastStepsPlannerActualIndex = messages.length - 1 - lastStepsPlannerIndex;

        return messages.filter(
          (message, index) =>
            message.toolResults?.name !== 'StepsPlanner' || index === lastStepsPlannerActualIndex,
        );
      } else {
        return messages.filter((message) => message.toolResults?.name !== 'StepsPlanner');
      }
    }, [messages, buildMode, deepSearch]);

    const handleSubmit = useCallback(
      async (text: string, skipUserMessage?: boolean, userMessage?: string) => {
        const v = text;
        if (!v?.trim()?.length) {
          return;
        }
        if (willyLoading || !dialect) {
          return;
        }
        if (!activeAccounts) {
          return;
        }
        let newConversationId = conversationId || uuidV4();
        if (!conversationId) {
          shouldSetConversationRef.current = false;

          setConversationId(newConversationId);

          if (!isDashboardPage) {
            const params = new URLSearchParams(search);
            params.set('conversationId', newConversationId);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
            if (!!deepSearch) {
              addChatToWatch(newConversationId);
            }
          }

          setTimeout(() => {
            shouldSetConversationRef.current = true;
          }, 500);
        }

        setWillyLoading(true);
        setShowFollowUpQuestions(false);

        let currentMessageId = uuidV4();

        setLastMessageId(currentMessageId);
        setValue('');

        if (!skipUserMessage) {
          // add the message user submitted
          addMessage({
            id: uuidV4(),
            text: userMessage || v,
            userId: user.uid,
            role: 'user',
            conversationId: newConversationId,
            uploadedFiles,
          });
        }

        genericEventLogger(currentAnalyticsEvent, {
          action: currentAnalyticsActionSet.SEND_MESSAGE,
          prompt_text: v,
          is_first_message: !messages.length,
          conversationId: newConversationId,
        });

        try {
          if (streamingMode) {
            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
              loading: true,
            });
          } else {
            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
            });
          }

          const requestParams: AnswerNlqQuestionParams = {
            source: source === 'summary' || source === 'pixel' ? 'dashboard' : source,
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
            messageId: currentMessageId,
            question: v,
            conversationId: newConversationId,
            generateInsights: true,
            stream: true,
            returnQueryOnly,
            dialect,
            modelToolsName:
              modelToolsName === 'custom'
                ? customModelToolsName
                : modelToolsName === 'default'
                  ? undefined
                  : modelToolsName,
            sqlGeneratingModel:
              sqlGeneratingModel === 'custom'
                ? customSqlModel
                : sqlGeneratingModel === 'default'
                  ? ''
                  : sqlGeneratingModel,
            deepSearch,
            deepSearchModel:
              deepSearchModel === 'custom'
                ? customDeepSearchModel
                : deepSearchModel === 'default'
                  ? ''
                  : deepSearchModel,
            query: initialQuery || getSqlFromMessage(lastMessageWithData) || undefined,
            metrics: getColumnsFromMessage(lastMessageWithData),
            widgetId: getQueryIdFromMessage(lastMessageWithData) || undefined,
            dashboardId: 'chat',
            mentionedUsers: uniqueMentioned.map((x) => x.id),
            currency,
            timezone: shopTimezone,
            industry: industry || 'other',
            dashboardData:
              source === 'chat' || source === 'sequence' || source === 'editor'
                ? undefined
                : JSON.stringify(
                    (source === 'pixel'
                      ? pixelData
                      : source === 'summary'
                        ? summaryData
                        : dashboardData) as FormattedDashboardDataElement,
                  ),
            forecastModel,
            sequenceName: sequenceName,
            sequenceRunData: sequenceRunData ? JSON.stringify(formattedSequenceRunData) : undefined,
            uploadedFiles: uploadedFiles ? uploadedFiles : undefined,
          };

          setUploadedFiles([]);

          if (streamingMode) {
            await answerNlqQuestion({ ...requestParams, stream: true });
          } else {
            const response = await answerNlqQuestion({ ...requestParams, stream: false });

            setWillyLoading(false);

            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
              text: response.text,
              loading: false,
            });
          }
        } catch (error) {
          console.error('ERROR', error);
        }
      },
      [
        willyLoading,
        dialect,
        activeAccounts,
        conversationId,
        currentAnalyticsEvent,
        currentAnalyticsActionSet.SEND_MESSAGE,
        messages.length,
        setConversationId,
        isDashboardPage,
        search,
        navigate,
        addMessage,
        user.uid,
        streamingMode,
        source,
        currentShopId,
        returnQueryOnly,
        modelToolsName,
        customModelToolsName,
        initialQuery,
        lastMessageWithData,
        uniqueMentioned,
        currency,
        shopTimezone,
        industry,
        sqlGeneratingModel,
        deepSearch,
        customSqlModel,
        deepSearchModel,
        customDeepSearchModel,
        forecastModel,
        sequenceName,
        sequenceRunData,
        formattedSequenceRunData,
        dashboardData,
        summaryData,
        pixelData,
        uploadedFiles,
      ],
    );

    const clearConversation = useCallback(() => {
      setMessages([]);
      setLastMessageId('');
      setUploadedFiles([]);
      setShowFollowUpQuestions(false);
      setFollowUpQuestions([]);

      setWillyLoading(false);
      setConversationNotFound(false);
      setLoadingConversation(false);

      setUserName(user.firstName || user.lastName || user.email || user.uid!);
      setConversationUser(user.uid || '');

      if (currentAnalyticsEvent !== 'dashboards') {
        const params = new URLSearchParams(search);
        params.delete('conversationId');
        params.delete('prompt');
        navigate({
          pathname: location.pathname,
          search: params.toString(),
          hash: '',
        });
      }

      setValue('');
      genericEventLogger(currentAnalyticsEvent, {
        action: currentAnalyticsActionSet.CLEAR_CHAT,
        conversationId,
      });
    }, [
      setMessages,
      user.firstName,
      user.lastName,
      user.email,
      user.uid,
      search,
      navigate,
      currentAnalyticsEvent,
      currentAnalyticsActionSet.CLEAR_CHAT,
      conversationId,
    ]);

    const onSuggestionClick = useCallback(
      (q) => {
        if (!q) {
          return;
        }
        setValue(q);
        (inputRef?.current as any)?.inputElement?.focus();
        genericEventLogger(currentAnalyticsEvent, {
          action: currentAnalyticsActionSet.CHOOSE_SUGGESTED_MESSAGE,
          conversationId,
          suggested_prompt: q,
        });
      },
      [conversationId, currentAnalyticsActionSet.CHOOSE_SUGGESTED_MESSAGE, currentAnalyticsEvent],
    );

    const focusTextInput = useCallback(() => {
      (inputRef as any)?.current?.inputElement?.focus();
    }, []);

    useEffect(() => {
      (async () => {
        if (!conversationId) {
          return;
        }
        const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
        const conversation = conversationDoc.data() as Conversation;
        if (conversationDoc.exists) {
          setCurrentConversation({ ...conversation, id: conversationDoc.id });
        } else {
          setCurrentConversation(undefined);
        }
      })();
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        const metricsDict: MetricDict[] = await getMetricsDictionary();

        const asObject = fromPairs(
          metricsDict.map((x) => {
            return [x.Name, x];
          }),
        );
        setMetricsDict(asObject);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        const conversationId = conversationIdFromQS;

        if (!shouldSetConversationRef.current) {
          return;
        }

        if (!conversationId) {
          setConversationId('');
          setCurrentConversation(undefined);
        }

        if (conversationId && conversationId != currentConversation?.id) {
          setLoadingConversation(true);
          setUserName('');
          const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
          if (!conversationDoc.exists) {
            setMessages([]);
            setConversationId('');
            setLoadingConversation(false);
            setConversationNotFound(true);
            return;
          }
          const conversation = conversationDoc.data() as Conversation;
          if (conversation?.history) {
            const messages: Message[] = createMessageFromDb(conversation, conversationId);
            setMessages(messages);
            setConversationId(conversationId);
          }
          if (conversation?.user) {
            setConversationUser(conversation.user);
            try {
              const { data } = await axiosInstance.get<User>(
                `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${conversation.user}`,
              );
              const { firstName, lastName, email } = data;
              setUserName(firstName || lastName || email || conversation.user);
            } catch (e) {
              console.error('Could not fetch user name', e);
            }
          }
          setLoadingConversation(false);
        }
      })();
    }, [
      currentShopId,
      conversationIdFromQS,
      user,
      currentConversation?.id,
      setConversationId,
      setMessages,
    ]);

    useEffect(() => {
      setUserName(user.firstName || user.lastName || user.email || user.uid!);
    }, [user]);

    useEffect(() => {
      setFollowUpQuestions([]);
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        setLoadingShopUsers(true);
        const res = (
          await axiosInstance.get(`/v2/account-manager/shops/users/${currentShopId}?noFilter=true`)
        ).data;

        setLoadingShopUsers(false);

        setShopUsers(
          res.map((x) => {
            return {
              id: x.id,
              display: x.email,
            };
          }),
        );
      })();
    }, [currentShopId]);

    useEffect(() => {
      // clear the conversationId query param when user navigates away
      setTimeout(() => {
        isMounted.current = true;
      }, 3000);

      return () => {
        if (!isMounted.current) {
          return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('conversationId');
        searchParams.delete('prompt');

        navigate(
          {
            pathname: window.location.pathname,
            search: searchParams.toString(),
            hash: window.location.hash,
          },
          { replace: true },
        );
      };
    }, [navigate]);

    useImperativeHandle(ref, () => ({
      clearConversation,
      handleSubmit,
      setLastMessageId,
      setLoadingConversation,
      lastMessageId,
      focusTextInput,
      shouldSetConversationRef,
    }));

    const clearConversationButton = (
      <div
        className="cursor-pointer"
        onClick={() => {
          genericEventLogger(currentAnalyticsEvent, {
            action: chatActions.CLEAR_CONVERSATION,
            conversationId,
          });
          clearConversation();
        }}
      >
        <span className="text-black/50 dark:text-white/50 font-semibold [text-shadow:_0_0_0_white]">
          Clear conversation
        </span>
      </div>
    );

    useLayoutEffect(() => {
      if (messageIdFromHash && atEnd?.[0] && !isScrolledToMessage.current) {
        // scroll to message
        const messageElement = document.getElementById(messageIdFromHash);
        if (messageElement) {
          setTimeout(() => {
            messageElement.scrollIntoView({ behavior: 'smooth' });
            isScrolledToMessage.current = true;
          }, 2000);
        }
      }
    }, [messageIdFromHash, atEnd]);

    useEffect(() => {
      let timer = setTimeout(
        () =>
          setActiveChatTitleCarouselIndex(
            activeChatTitleCarouselIndex + 1 >= chatTitleCarouselOptions.length
              ? 0
              : activeChatTitleCarouselIndex + 1,
          ),
        4000,
      );

      return () => {
        clearTimeout(timer);
      };
    }, [activeChatTitleCarouselIndex]);

    useEffect(() => {
      setMessages([]);
      resetLoading();
      setLastMessageId('');
    }, [chatWithSequenceId, setMessages, resetLoading, setLastMessageId]);

    const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => {
      const newFileContents: MobyUploadedFile[] = [];
      const newFiles: File[] = [];

      acceptedFiles.forEach((file) => {
        newFiles.push(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          const content = reader.result ? (reader.result as string) : '';
          newFileContents.push({ name: file.name, content, type: file.type });
          // Update state only after all files are read
          if (newFileContents.length === acceptedFiles.length) {
            setUploadedFiles((prevContents) => [...prevContents, ...newFileContents]);
          }
        };

        reader.readAsDataURL(file);
      });
    }, []);

    const toggleOpenFileDialog = useCallback(
      () => setOpenFileDialog((openFileDialog) => !openFileDialog),
      [],
    );

    const removeFile = useCallback(
      (index: number) => setUploadedFiles((files) => files.filter((_, i) => i !== index)),
      [],
    );

    const scrollerKey = `${chatWithSequenceId ?? 'no-sequence'}-${isEmptyState}`;

    const ChatInputWrapper = useMemo(() => {
      return (
        <div className="flex items-center w-full">
          <div className={`relative flex-auto ${doDarkMode ? ' tw-nice-dark' : ''}`}>
            {!streamingMode && (
              <Tooltip label="Streaming mode is temporarily disabled, responses might take longer than usual">
                <div className="absolute flex-shrink-0 top-1/2 -left-12 translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-red-500 rounded-full" />
              </Tooltip>
            )}
            <ChatInput
              value={value}
              setValue={setValue}
              onSubmit={handleSubmit}
              disabled={
                (!isUserAdmin && !conversationMembers.includes(user.uid!)) ||
                (!!activeVersion && activeVersion < 6)
              }
              onStopChat={() => {
                resetLoading();
                setLastMessageId('');
                socket?.emit('stop-answer-nlq-question', {
                  shopId: currentShopId,
                  messageId: lastMessageId,
                  conversationId,
                  question: value,
                });
              }}
              context={source}
              inputRef={inputRef}
              placeholder={inputPlaceholder}
              willyLoading={willyLoading}
              isReadyForChat={isReadyForChat}
              currentAnalyticsEvent={currentAnalyticsEvent}
              toggleOpenFileDialog={toggleOpenFileDialog}
              uploadedFiles={uploadedFiles}
              removeFile={removeFile}
              expandedDesign={showBetaLP}
            >
              <Mention
                trigger="@"
                // style={{}}
                isLoading={loadingShopUsers}
                data={shopUsers}
                // displayTransform={(id, display) => {
                //   return display.split('@')[0].trim();
                // }}
                markup='@"__display__"'
                onAdd={(id, display) => {
                  setMentionedUsers((old) => {
                    return old.concat({ id: id.toString(), display });
                  });
                }}
              />
              <Mention
                trigger="#"
                appendSpaceOnAdd
                data={metricsData}
                markup='@"__display__"'
                renderSuggestion={(suggestion, search) => {
                  const metric = metricsDict?.[suggestion.id];
                  if (!metric) {
                    return <Text fw="bold">{suggestion.display}</Text>;
                  }
                  return (
                    <div className="flex flex-col gap-1">
                      <Text fw="bold">{metric.Name}</Text>
                      <Text size="sm">{metric.About}</Text>
                      {!!metric.Formula && (
                        <Text size="sm" color="gray.5" fs="italic">
                          <Text display="inline-flex" fw="bold">
                            Formula:
                          </Text>{' '}
                          <Text display="inline-flex">{metric.Formula}</Text>
                        </Text>
                      )}
                      {!!metric['Learn More'] && (
                        <Text size="sm" color="gray.5">
                          <a target="_blank" href={metric['Learn More']}>
                            Learn more
                          </a>
                        </Text>
                      )}
                    </div>
                  );
                }}
              />
            </ChatInput>
          </div>
        </div>
      );
    }, [
      activeVersion,
      conversationId,
      conversationMembers,
      currentAnalyticsEvent,
      currentShopId,
      doDarkMode,
      handleSubmit,
      inputPlaceholder,
      isReadyForChat,
      isUserAdmin,
      lastMessageId,
      loadingShopUsers,
      metricsData,
      metricsDict,
      removeFile,
      resetLoading,
      shopUsers,
      showBetaLP,
      socket,
      source,
      streamingMode,
      toggleOpenFileDialog,
      uploadedFiles,
      user.uid,
      value,
      willyLoading,
    ]);

    return (
      <WillyMainChatWrapper
        asPage={asPage}
        messages={messages}
        conversationId={conversationId}
        clearConversation={clearConversation}
        currentAnalyticsEvent={currentAnalyticsEvent}
        setValue={setValue}
      >
        <ScrollToBottom
          key={scrollerKey}
          debug={false}
          followButtonClassName="hidden"
          className={`h-full w-full ${showingAgentFeed ? '!overflow-hidden' : 'overflow-auto'} flex flex-col scroll-p-40 ${messagesWrapperClassName}`}
          scrollViewClassName={`h-full w-full ${showingAgentFeed ? '!overflow-hidden !overflow-y-hidden' : 'overflow-auto'} flex flex-col`}
          scroller={
            (!!chatWithSequenceId && isEmptyState) || showingAgentFeed
              ? (values) => {
                  return 0;
                }
              : undefined
          }
        >
          <WillyScrollToBottom ref={scrollToBottomRef} showArrow={!showingAgentFeed}>
            <div
              className={`w-full flex flex-col flex-auto @container ${showingAgentFeed ? 'h-full overflow-hidden' : ''}`}
              style={computedBackground}
            >
              {!hidePills && isUserAdmin && !deepSearchSource && (
                <div className="sticky top-0 bg-white z-10 p-4 items-center gap-4 hidden sm:grid">
                  <div className="flex flex-wrap mr-auto gap-4 items-center justify-between w-full ">
                    <div className="flex mr-auto gap-4 items-center">
                      <WillyMainChatModelSelectors />
                      {isUserAdmin && (
                        <>
                          <WillyMainChatForecastModelSelector
                            activeForecastModel={forecastModel}
                            setActiveForecastModel={setForecastModel}
                          />
                          <WillyMainChatLandingPageSelector />
                        </>
                      )}
                    </div>
                    <div></div>
                    <div>{conversationId && <ChatTimer conversationId={conversationId} />}</div>
                    <div>
                      {conversationId && (
                        <ChatSourcesUsed
                          conversationId={conversationId}
                          conversation={currentConversation}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!!chatWithSequenceId && (
                <>
                  <div className="sticky top-0 bg-white z-10 grid items-center gap-4">
                    <div className="p-4 @3xl:max-w-[800px] flex justify-between">
                      <Text fw={600}>{sequenceName}</Text>
                      <span className="flex gap-4 items-center">
                        <Text fw={500}>
                          {sequenceLastRunAt
                            ? moment(sequenceLastRunAt?.toDate()).format('MMM DD, YYYY [at] h:mmA')
                            : ''}
                        </Text>
                        <Menu closeOnItemClick={true} withinPortal={false}>
                          <Menu.Target>
                            <ActionIcon icon="three-dots" iconSize={18} />
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() => {
                                navigate(`/workflows/view/${chatWithSequenceId}`);
                              }}
                            >
                              <Text size="sm" weight={500}>
                                View Details
                              </Text>
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                rerunSequnece?.(chatWithSequenceId);
                              }}
                            >
                              <Text size="sm" weight={500}>
                                Run Agent
                              </Text>
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                navigate(`/workflows/create/${chatWithSequenceId}`);
                              }}
                            >
                              <Text size="sm" weight={500}>
                                Edit Agent
                              </Text>
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </span>
                    </div>
                  </div>
                  {isEmptyState && (
                    <div className="flex flex-col  items-center h-full justify-end"></div>
                  )}
                  <div className="flex-auto flex flex-col p-4 @3xl:max-w-[800px] justify-between">
                    {(windowSize.isSmall || isMobileApp) && <div className="mt-auto"></div>}
                    {(workflowRunning || loadingInsightsStep) && (
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <Loader size="xs" />
                        {workflowRunning && (
                          <Text size="sm" fw={400} color={'gray.4'}>
                            Running agent...
                          </Text>
                        )}
                      </div>
                    )}
                    {lastInsightStep?.text && !workflowRunning && !loadingInsightsStep && (
                      <WillySimpleText text={lastInsightStep?.text ?? '[No Content]'} />
                    )}
                  </div>
                </>
              )}
              {showBetaLP ? (
                <WillyBetaChatLP
                  onRunItem={(sequenceId) => rerunSequnece?.(sequenceId)}
                  chatInput={ChatInputWrapper}
                  generatedPrompts={promptsToUse ?? []}
                  loadingGeneratedPrompts={loadingGeneratedPrompts}
                  onSuggestionClick={onSuggestionClick}
                />
              ) : showNewLP ? (
                <>
                  {showingAgentFeed && (
                    <SequencesAllList onRunItem={(sequenceId) => rerunSequnece?.(sequenceId)} />
                  )}
                  {!workflowRunning && !loadingInsightsStep && !showingAgentFeed && (
                    <WillyChatLP
                      onSuggestionClick={onSuggestionClick}
                      generatedPrompts={promptsToUse ?? []}
                      loadingGeneratedPrompts={loadingGeneratedPrompts}
                      showGeneratedPrompts={
                        isDashboardPage
                          ? !!chatWithSequenceId ||
                            !!promptsToUse ||
                            !!loadingGeneratedPrompts ||
                            !!isReadyForChat
                          : !!chatWithSequenceId
                            ? !!loadingGeneratedPrompts
                            : false
                      }
                      chatWithSequence={!!chatWithSequenceId}
                    />
                  )}
                </>
              ) : (
                <>
                  {showLogo && isEmptyState && !loadingConversation && !chatWithSequenceId && (
                    <div className="w-full flex flex-col justify-center items-center m-auto mb-auto md:mb-auto p-">
                      <div
                        className={`text-gray-800 w-full md:h-full md:flex md:flex-col p-4 dark:text-gray-100 overflow-hidden ${isDashboardPage && 'mt-8'}`}
                      >
                        <h1 className="text-4xl font-semibold md:text-center ml-auto mr-auto mb-5 md:mb-8 flex gap-4 items-center justify-center flex-col">
                          {/* <Alan className=" @[600px]:w-[150px] w-20 max-w-[100%] h-auto" /> */}
                          <ChatIcon
                            className="@[600px]:w-[150px] w-20 max-w-[100%] h-auto"
                            onClick={(e) => {
                              dispatch({
                                type: 'FLOPPY_WHALE_MODAL_OPENED',
                              });
                            }}
                          />
                        </h1>
                      </div>
                      <div
                        className="overflow-hidden shrink-0"
                        style={{ height: carouselHeight + (windowSize.isSmall ? 0 : 7) }}
                      >
                        <div
                          className="transition ease-in-out duration-300 flex flex-col items-center justify-start text-center shrink-0"
                          style={{
                            ...chatTitleCarouselStyles,
                            height: carouselHeight + (windowSize.isSmall ? 0 : 7),
                          }}
                        >
                          {chatTitleCarouselOptions.map((option, index) => (
                            <div
                              key={index}
                              className="font-medium text-3xl flex items-center justify-center shrink-0"
                              style={{ lineHeight: '30px' }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>

                      {!hidePills && !windowSize.isSmall && (
                        <div className="flex gap-4 mt-8">
                          {mainChatPillPrompts.map(({ prompt, name }) => (
                            <div
                              role="button"
                              className="willy-suggest-question px-6 py-2 flex flex-col overflow-hidden cursor-pointer animateFadeToRight rounded-2xl border border-solid border-[#D1D4DB] hover:bg-[#e5e7eb] transition-colors"
                              onClick={() => {
                                handleSubmit?.(prompt);
                                genericEventLogger(analyticsEvents.CHAT, {
                                  action: chatActions.SELECT_DEFAULT_PILL,
                                  prompt,
                                });
                              }}
                              key={name}
                            >
                              {name}
                            </div>
                          ))}
                        </div>
                      )}

                      {conversationNotFound && (
                        <div className="text-gray-800 dark:text-gray-100 font-semibold text-lg mt-4">
                          Conversation not found, you can start a new one or change the shop
                        </div>
                      )}
                    </div>
                  )}
                  {loadingConversation && showLogo && !chatWithSequenceId && (
                    <div className="flex flex-col gap-2 w-full h-full justify-center items-center">
                      <AlanLoaderGray />
                    </div>
                  )}
                  {!isEmptyState && !loadingConversation && (
                    <div className="flex-auto flex flex-col">
                      {(windowSize.isSmall || isMobileApp) && <div className="mt-auto"></div>}
                      {filteredMessages.map((message, i, arr) => (
                        <WillyMessageTemplate
                          message={message}
                          firstOfAssistant={message.role !== 'user' && arr[i - 1]?.role === 'user'}
                          lastOfAssistant={
                            (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                            i === arr.length - 1
                          }
                          lastMessageFromUser={lastMessageFromUser}
                          canEdit={conversationMembers.includes(user.uid!)}
                          userName={userName}
                          handleSubmit={handleSubmit}
                          key={`${message.id}_element_${i}`}
                          loading={willyLoading}
                          codeActions={codeActions}
                          isLast={i === messages.length - 1}
                          conversationUser={conversationUser}
                          chatSourceIds={chatSourceIds}
                          conversationId={conversationId}
                          conversationMessages={messages}
                          setMessages={setMessages}
                          buildMode={buildMode}
                          deepSearch={deepSearchSource}
                          setSelectedImage={setSelectedImage}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {!showBetaLP && !deepSearchSource && (
                <div
                  className={`sticky bottom-0 z-[200] w-full flex flex-col  ${asPage ? 'p-4 @3xl:!max-w-[800px] @3xl:!mx-auto @3xl:!pb-10' : ' p-6 @3xl:!px-90 @3xl:!pb-16'}`}
                  style={computedBackground}
                >
                  {!showNewLP && showSuggestions && (
                    <div
                      className={`${
                        source === 'dashboard' &&
                        !willyLoading &&
                        (!!conversationId || messages?.length > 0)
                          ? ' mb-8'
                          : ''
                      }`}
                    >
                      <WillySuggestQuestions
                        onSuggestionClick={onSuggestionClick}
                        source={source}
                        inputValue={value}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-1 items-end relative">
                    <div className="w-full relative sm:flex items-end gap-4 justify-between pb-2 sm:pb-4">
                      {followUpQuestions?.length > 0 && (
                        <div
                          className={`max-w-full self-start fadein overflow-hidden transition-[height] duration-300 ${
                            showFollowUpQuestions ? 'h-64' : 'h-10'
                          }`}
                        >
                          <div
                            className={`rounded-lg overflow-hidden ${
                              showFollowUpQuestions ? 'bg-white dark:bg-gray-800' : 'bg-transparent'
                            }`}
                          >
                            <div
                              className="sm:px-4 py-2 flex gap-1 items-center cursor-pointer"
                              onClick={() => {
                                setShowFollowUpQuestions((x) => !x);
                              }}
                            >
                              <ExpandOrCollapse className="w-6 h-6" />
                              <h1 className="text-gray-900 dark:text-white font-semibold text-lg">
                                Follow-up questions
                              </h1>
                            </div>
                            <div
                              className={`flex flex-col gap-2 p-4 pt-0 ${
                                showFollowUpQuestions ? 'opacity-100' : 'opacity-0'
                              }`}
                            >
                              {followUpQuestions.map((q) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setValue(q);
                                      handleSubmit(q);
                                      genericEventLogger(currentAnalyticsEvent, {
                                        action: currentAnalyticsActionSet.CLICK_FOLLOWUP,
                                        conversationId,
                                        followup_prompt: q,
                                      });
                                    }}
                                    className="flex flex-col gap-2 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                    key={q}
                                  >
                                    <div className="text-gray-900 dark:text-white font-semibold text-lg line-clamp-1">
                                      {q}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {source !== 'deepSearch' &&
                        !willyLoading &&
                        (!!conversationId || messages?.length > 0) && (
                          <div
                            className={`whitespace-nowrap absolute right-1 bottom-2 sm:relative ${
                              showFollowUpQuestions ? 'hidden sm:block' : ''
                            }`}
                          >
                            {clearConversationButton}
                          </div>
                        )}
                    </div>
                    {source !== 'deepSearch' && (
                      <>
                        {showBetaLP
                          ? messages.length > 0
                            ? ChatInputWrapper
                            : null
                          : ChatInputWrapper}

                        {shouldShowMobyUpgradeCtas && (
                          <div className="flex items-center w-full">
                            <div className="w-full text-center pt-[40px]">
                              {
                                <Anchor underline="always" fz="sm" fw={500} onClick={action}>
                                  {linkText}
                                </Anchor>
                              }
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </WillyScrollToBottom>
          <Modal
            title={selectedImage?.name}
            opened={!!selectedImage}
            onClose={() => setSelectedImage(null)}
            size="lg"
          >
            <div className="w-full h-full">
              <img
                src={selectedImage?.path ?? selectedImage?.content}
                alt="Selected"
                className="block w-full h-full object-contain"
              />
            </div>
          </Modal>
          <div className="hidden">
            <DropZone
              openFileDialog={openFileDialog}
              onDrop={(files) => {
                const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
                const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);
                const rejectedFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

                if (validFiles.length < files.length) {
                  // Some files were rejected
                  alert('Files must be smaller than 1MB');
                }

                if (validFiles.length > 0) {
                  handleDropZoneDrop(files, validFiles, rejectedFiles);
                }
              }}
              onFileDialogClose={toggleOpenFileDialog}
              type="file"
              accept="text/csv,image/*,text/plain,application/json"
              errorOverlayText="Only .csv, .jpg, .png, .svg, .json, and .txt files under 1MB are supported"
            />
          </div>
        </ScrollToBottom>
      </WillyMainChatWrapper>
    );
  },
);
