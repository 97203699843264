import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { gradualReleaseFeatures as gradualReleaseFeaturesSelector } from 'ducks/shop';
import { $ffStore, FeatureFlagComputer } from 'feature-flag-system';
import { NavSection, NavSectionRoute } from 'constants/routes/types';
import { useStoreValue } from '@tw/snipestate';
import { $navSectionCustomization } from '../components/navs/AlanNav/api/NavSectionCustomizer';
import { $moreRoutes } from 'constants/routes/configs/more';
import { $isAdminClaim } from '$stores/$user';

export type NavArea = 'main' | 'settings' | 'alan';

export const useFilterMenu = (
  menu: ReadonlyArray<NavSection>,
  area: NavArea = 'main',
): NavSection[] => {
  const isAdmin = useStoreValue($isAdminClaim);
  const moreRoutes = useStoreValue($moreRoutes);
  const navSectionCustomization = useStoreValue($navSectionCustomization);
  const isBetaShop = useSelector((state: RootState) => state.isBetaShop);
  const roles = useSelector((state: RootState) => state.roles);
  const gradualReleaseFeatures = useSelector(gradualReleaseFeaturesSelector);
  const ffComputer = useStoreValue($ffStore);

  return useMemo(
    () =>
      filterMenu(
        menu,
        moreRoutes,
        navSectionCustomization,
        gradualReleaseFeatures,
        isBetaShop,
        roles,
        ffComputer,
        isAdmin,
        area,
      ),
    [
      menu,
      area,
      gradualReleaseFeatures,
      isBetaShop,
      roles,
      ffComputer,
      isAdmin,
      navSectionCustomization,
      moreRoutes,
    ],
  );
};

function filterRoutes(
  r: NavSectionRoute,
  moreRoutes: Set<string>,
  navSectionCustomization: ReturnType<typeof $navSectionCustomization.get>,
  gradReleaseFeatures: any,
  isBetaShop: boolean,
  roles: string[],
  ffComputer: FeatureFlagComputer,
  isAdmin: boolean,
  area: NavArea = 'main',
) {
  if (r.onlyAdmin && !isAdmin) return false;
  if (r.footerSection || r.hidden) return false;
  if (area !== 'settings' && r.isSettingsPage) return false;
  if (area === 'alan') {
    if (!(r.isWillyPage || r.isHybridPage)) return false;
    if (moreRoutes.has(r.url) && !navSectionCustomization[r.url]?.open) return false;
  }
  if (area === 'settings' && !r.isSettingsPage) return false;
  if (r.beta && !isBetaShop) return false;
  if (r.roles && !roles.some((role) => r.roles?.includes(role))) return false;
  if (r.dependsOnFFSystemCallback?.((ff) => ffComputer.getConfigById(ff)) === false) return false;
  if (r.gradualReleaseFeatureKey && !gradReleaseFeatures[r.gradualReleaseFeatureKey]) return false;
  if (r.removeInReleaseKey && gradReleaseFeatures[r.removeInReleaseKey]) return false;
  return true;
}

function filterMenu(
  sections: readonly NavSection[],
  moreRoutes: Set<string>,
  navSectionCustomization: ReturnType<typeof $navSectionCustomization.get>,
  gradReleaseFeatures: any,
  isBetaShop: boolean,
  roles: string[],
  ffComputer: FeatureFlagComputer,
  isAdmin: boolean,
  area: NavArea = 'main',
) {
  const filteredSections = sections.reduce((acc, s) => {
    const routes = s.routes.filter((r) =>
      filterRoutes(
        r,
        moreRoutes,
        navSectionCustomization,
        gradReleaseFeatures,
        isBetaShop,
        roles,
        ffComputer,
        isAdmin,
        area,
      ),
    );

    if (!routes.length) return acc;

    acc.push({ ...s, routes });

    return acc;
  }, [] as NavSection[]);

  return filteredSections;
}
