import { Stack, Button, TextContainer } from '@shopify/polaris';
import { MinusMinor, MobilePlusMajor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Button as TwButton } from 'components/BaseComponents/Button';
import { useAppDispatch } from 'index';
import { upgradePlanClicked } from 'ducks/subscription';
import LockedFeatureIndicator from './library/LockedFeatures/LockedFeatureIndicator';
import { snakeCase } from 'lodash';
import { ConnectedListItem } from './library/TWTable/types';
import { useNavigate } from 'react-router-dom';
import { Icon, Text } from '@tw/ui-components';
import ReorderListNew from './ReorderListNew';
import React, { Fragment } from 'react';

// Map of column id to feature flag id
const BLOCK_MAP = new Map([['overlap', FeatureFlag.PIXEL_CHANNEL_OVERLAP_FF]]);
const NEW_CUSTOMER_REVENUE_TRACKING = 'market-target-store-add-customer-revenue';

type ConnectedListsProps<T = any> = {
  items1: ConnectedListItem[];
  items2: ConnectedListItem[];
  title1?: string;
  title2?: string;
  items1orderOnChange: (...props: any) => void;
  itemMoveFrom1to2onPress: (item: T) => void;
  itemMoveFrom2to1onPress: (item: T) => void;
  currentSectionId?: string;
};

const ConnectedListsNew: React.FC<ConnectedListsProps> = ({
  items1,
  items2,
  title1,
  title2,
  items1orderOnChange,
  itemMoveFrom1to2onPress,
  itemMoveFrom2to1onPress,
  currentSectionId,
}) => {
  const ffComputer = useFeatureFlagComputer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4">
      {title1 && (
        <Text fz={14} color="gray.5">
          {title1}
        </Text>
      )}
      <ReorderListNew
        currentSectionId={currentSectionId}
        items={items1}
        orderOnChange={items1orderOnChange}
        itemHasLeftButton={true}
        itemLeftButtonIcon={MinusMinor}
        itemLeftButtonClick={itemMoveFrom1to2onPress}
      />
      {title2 && (
        <div className="pt-4">
          <Text fz={14} color="gray.5">
            {title2}
          </Text>
        </div>
      )}
      {items2.map((i) => {
        const featureFlag = BLOCK_MAP.get(i.id);

        if (ffComputer.getConfigById(featureFlag).shouldNotBeSeen) {
          return (
            <div
              className="flex justify-between items-center gap-7 w-full border-0 border-b-[1px] border-solid border-gray-200 py-4"
              key={i.id}
            >
              <div className="flex justify-start items-center gap-7 whitespace-nowrap w-8">
                <LockedFeatureIndicator
                  styles={{ position: 'relative', left: '2px' }}
                  onlyIndicate
                  iconOnly
                  iconSize={25}
                  featureFlag={FeatureFlag.CUSTOM_METRICS_FF}
                />
                <Text fw={500}>{i.title}</Text>
              </div>
              <TwButton
                id={typeof i.title === 'string' ? snakeCase(i.title) : undefined}
                onClick={() => dispatch(upgradePlanClicked(navigate))}
                primary
              >
                Upgrade
              </TwButton>
            </div>
          );
        }

        return (
          <div
            data-marketing-target={
              i.id === 'newCustomerSales' ? NEW_CUSTOMER_REVENUE_TRACKING : undefined
            }
          >
            <div
              key={i.id}
              className="flex justify-between items-center gap-7 w-full border-0 border-b-[1px] border-solid border-gray-200 py-4"
            >
              <div className="flex items-center gap-3">
                <div>
                  <div className="cursor-pointer p-3" onClick={() => itemMoveFrom2to1onPress(i)}>
                    <Icon name="union" color="green.5" size={14} />
                  </div>
                </div>
                {/* <Button
                    plain
                    size="large"
                    icon={PlusMinor}
                    onClick={() => {
                      itemMoveFrom2to1onPress(i);
                    }}
                  /> */}
                {!!i.icons?.length && (
                  <div className="w-[24px] flex items-center overflow-hidden">
                    {(i.icons || []).map((I, i) => (
                      <Fragment key={`section_icon_${i}`}>
                        <I key={i} />
                      </Fragment>
                    ))}
                  </div>
                )}
                <Text fw={500}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    id={typeof i.title === 'string' ? snakeCase(i.title) : undefined}
                  >
                    {i.title}
                    {i.subTitle}
                  </div>
                </Text>
                {!!i.onEdit && (
                  <EditMinor
                    width={12}
                    height={12}
                    className="fill-logo cursor-pointer"
                    onClick={() => dispatch(i.onEdit?.())}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectedListsNew;
