import { Button, Checkbox, Loader, Select, Table, Text, TextInput } from '@tw/ui-components';
import { AgentCheckoutItem } from './AgentsLibraryCollection';
import { useStoreValue } from '@tw/snipestate';
import {
  $defaultAiColumns,
  $globalAndShopSequences,
  $globalSequences,
  $shopSequences,
} from '$stores/willy/$sequences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $combineShopAndCustomViewDashboards } from '$stores/willy/$customViews';
import { Interval, INTERVAL_OPTIONS } from '../constants';
import { calculateAgentUsage } from 'pages/Usage/AgentUsageCalculator';
import { calculateEntityCount } from 'pages/Usage/entityCountCalculator';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { useNavigate } from 'react-router-dom';
import { DayOfWeek } from '../types/willyTypes';
import { CustomScheduleModal } from './CustomScheduleModal';
import { $activeAccounts, $currentShopId } from '$stores/$shop';

type AgentScheduleCalculate = {
  agentId: string;
  interval: Interval | null;
  entities: string;
  hours: number[];
  days: DayOfWeek[];
};

export const Checkout: React.FC<{ agentIds: AgentCheckoutItem[] }> = ({ agentIds }) => {
  const navigate = useNavigate();
  const costPerQuery = 0.1;

  const globalSequences = useStoreValue($globalAndShopSequences);
  const shopSequences = useStoreValue($shopSequences);
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);

  const [selectedAgentIds, setSeletedAgentIds] = useState<AgentCheckoutItem[]>(agentIds);
  const [loadingAddingAgents, setLoadingAddingAgents] = useState(false);
  const [loadingEntityCounts, setLoadingEntityCounts] = useState<Set<string>>(new Set());

  const [agentSchedules, setAgentSchedules] = useState<AgentScheduleCalculate[]>([]);
  const [customScheduleOpen, setCustomScheduleOpen] = useState(false);

  const [customSchedule, setCustomSchedule] = useState<{
    hours: number[];
    days: DayOfWeek[];
    agentId: string;
  }>({
    days: [],
    hours: [],
    agentId: '',
  });

  const shopId = useStoreValue($currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);

  useEffect(() => {
    if (!agentSchedules.length) {
      // Initialize with default values first
      const schedules: AgentScheduleCalculate[] = agentIds.map((agent) => {
        return {
          agentId: agent.id,
          interval: '1d' as Interval, // Explicitly cast to Interval type
          entities: '1', // Default value that will be used if calculation fails
          days: [],
          hours: [],
        };
      });
      setAgentSchedules(schedules);

      // Attempt to calculate better values asynchronously
      const updateWithCalculatedValues = async () => {
        if (!shopId || !activeAccounts) return;

        // Set loading state for all agents
        const loadingAgentIds = new Set(agentIds.map((agent) => agent.id));
        setLoadingEntityCounts(loadingAgentIds);

        try {
          const updatedSchedules = await Promise.all(
            agentIds.map(async (agent) => {
              const agentData = globalSequences.find((seq) => seq.id === agent.id);
              let entityCount = 1; // Default

              if (agentData?.entity) {
                try {
                  // Use entity type from agent if available, or default to a sensible value
                  entityCount = await calculateEntityCount(
                    agentData,
                    shopId,
                    activeAccounts,
                    agentData.entity,
                    agentData.providers,
                  );
                } catch (error) {
                  console.error('Error calculating entity count:', error);
                }
              }

              // Remove this agent from loading state
              setLoadingEntityCounts((prev) => {
                const updated = new Set(prev);
                updated.delete(agent.id);
                return updated;
              });

              return {
                agentId: agent.id,
                interval: '1d' as Interval, // Explicitly cast to Interval type
                entities: entityCount.toString(),
                days: [],
                hours: [],
              };
            }),
          );

          setAgentSchedules(updatedSchedules);
        } catch (error) {
          console.error('Error calculating entity counts:', error);
          // Clear all loading states on error
          setLoadingEntityCounts(new Set());
        }
      };

      updateWithCalculatedValues();
    }
  }, [agentIds, agentSchedules.length, globalSequences, shopId, activeAccounts]);

  const agentPrices = useMemo(() => {
    return agentIds.map((agent) => {
      const agentData = globalSequences.find((seq) => seq.id === agent.id);
      const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
      const price =
        agentData && schedule
          ? calculateAgentUsage(
              agentData,
              [...dashboards, ...shopAndViewDashboards],
              [...globalSequences, ...shopSequences],
              parseInt(!!schedule.entities ? schedule.entities : '0'),
              { interval: schedule.interval, hours: schedule.hours, days: schedule.days },
            )
          : 0;
      return { agentId: agent.id, price: price };
    });
  }, [agentSchedules, globalSequences, dashboards, shopAndViewDashboards, shopSequences, agentIds]);

  const toggleFromSelected = (id) => {
    setSeletedAgentIds((prev) => {
      const exists = prev.some((item) => item.id === id);
      return exists ? prev.filter((item) => item.id !== id) : [...prev, { id, type: 'agent' }];
    });
  };

  const totalPrice = useMemo(
    () =>
      selectedAgentIds.reduce((total, agentId) => {
        const agentPrice =
          (agentPrices.find((sch) => sch.agentId === agentId.id)?.price ?? 0) * costPerQuery;
        return total + agentPrice;
      }, 0),
    [selectedAgentIds, agentPrices],
  );

  const addSelectedAgents = useCallback(async () => {
    setLoadingAddingAgents(true);
    await Promise.all(
      selectedAgentIds.map(async (agent) => {
        const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
        const newId = await copyGlobalSequenceToShop(agent.id, schedule);
        genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
          action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
          sequence_id: agent.id,
        });
      }),
    );
    setLoadingAddingAgents(false);
    navigate(`/workflows`);
  }, [agentSchedules, selectedAgentIds, navigate]);

  const onSelectInterval = (value, agentId, schedule) => {
    if (value === 'custom') {
      setCustomScheduleOpen(true);
      setCustomSchedule({ days: schedule.days, hours: schedule.hours, agentId: agentId });
    } else {
      setAgentSchedules((prev) => {
        const newSchedules = [...prev];
        const sheduleToChangeIndex = newSchedules.findIndex((sch) => sch.agentId === agentId);
        if (sheduleToChangeIndex === -1) {
          return prev;
        }
        newSchedules[sheduleToChangeIndex] = {
          ...newSchedules[sheduleToChangeIndex],
          interval: value as Interval,
          hours: [],
          days: [],
        };
        return newSchedules;
      });
    }
  };

  const onSaveCustomSchedule = (hours, days) => {
    setAgentSchedules((prev) => {
      const newSchedules = [...prev];
      const sheduleToChangeIndex = newSchedules.findIndex(
        (sch) => sch.agentId === customSchedule.agentId,
      );
      if (sheduleToChangeIndex === -1) {
        return prev;
      }
      newSchedules[sheduleToChangeIndex] = {
        ...newSchedules[sheduleToChangeIndex],
        hours: hours,
        days: days,
        interval: null,
      };
      return newSchedules;
    });
    closeCustomScheduleModal();
  };

  const closeCustomScheduleModal = () => {
    setCustomScheduleOpen(false);
    setCustomSchedule({ days: [], hours: [], agentId: '' });
  };

  return (
    <div className="flex flex-col gap-10">
      <Text fz={22} fw={600}>
        Agent Estimated Costs
      </Text>
      <table className="min-w-full  rounded-lg">
        <thead className="py-5">
          <tr>
            <th className="px-4 py-2 text-left text-gray-600"></th>
            <th className="px-4 py-2 text-left">
              <Text fw={600}>Agent Name</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Frequency</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Entities</Text>
            </th>
            <th className="px-4 py-2 text-left text-gray-600">
              <Text fw={600}>Price</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {agentIds.map((agent, index) => {
            const includedInTotal = selectedAgentIds.some((item) => item.id === agent.id);
            const agentData = globalSequences.find((seq) => seq.id === agent.id);
            const schedule = agentSchedules.find((sch) => sch.agentId === agent.id);
            const price = agentPrices.find((sch) => sch.agentId === agent.id)?.price ?? 0;

            return agentData && schedule ? (
              <tr key={agent.id} className={`py-5`}>
                <td>
                  <Checkbox
                    checked={includedInTotal}
                    onChange={() => toggleFromSelected(agent.id)}
                  />
                </td>
                <td className="px-4 py-2">
                  <Text fw={500}>{agentData.name}</Text>
                </td>
                <td className="px-4 py-2">
                  <Select
                    data={[
                      {
                        label: '-',
                        value: '',
                      },
                      ...Object.values(INTERVAL_OPTIONS).map((option) => ({
                        label: option.label,
                        value: option.value,
                      })),
                      ...(schedule.hours.length && schedule.days.length
                        ? [
                            {
                              label: schedule.days
                                .map(
                                  (day) => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(),
                                )
                                .join(', '),
                              value: 'setCustom',
                            },
                          ]
                        : []),
                      {
                        label: 'Custom...',
                        value: 'custom',
                      },
                    ]}
                    onChange={(value) => {
                      onSelectInterval(value, agent.id, schedule);
                    }}
                    value={
                      !!schedule.hours.length && !!schedule.days.length
                        ? 'setCustom'
                        : schedule.interval
                    }
                  />
                </td>
                <td className="px-4 py-2">
                  {loadingEntityCounts.has(agent.id) ? (
                    <div className="flex items-center justify-center w-full">
                      <Loader size="sm" />
                    </div>
                  ) : (
                    <TextInput
                      value={schedule.entities}
                      disabled
                      onChange={() => {}} // No-op onChange handler since it's required
                    />
                  )}
                </td>
                <td className="px-4 py-2">
                  {(price * costPerQuery).toLocaleString(undefined, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
      <div className="flex justify-end">
        <div className="flex flex-col items-end gap-0">
          <Text fw="700" fz="28">
            Estimated Cost:{' '}
            {totalPrice.toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
          </Text>
          {/* <div className="font-medium text-[16px] text-gray-700">(approx.)</div> */}
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={() => addSelectedAgents()} disabled={loadingAddingAgents}>
          Add All
        </Button>
        {loadingAddingAgents && <Loader />}
      </div>
      <CustomScheduleModal
        open={customScheduleOpen}
        onClose={() => closeCustomScheduleModal()}
        days={customSchedule.days}
        hours={customSchedule.hours}
        onSave={(hours, days) => onSaveCustomSchedule(hours, days)}
      />
    </div>
  );
};
