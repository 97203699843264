import { SegmentType } from 'components/CreateEditSegmentationModal';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Divider, Icon } from '@shopify/polaris';
import { DeleteMinor, FilterMajor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';
import { useAppDispatch } from 'index';
import {
  openModalMetricFilter,
  openModalSegmentation,
} from 'ducks/creativesCockpit/creativesCockpit';
import { openModalSegmentation as openModalAuditLogsSegmentation } from 'components/RulesEngine/Audit/utils';
import { CreativesCockpitContext } from 'components/CreativesCockpit/context';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { LockDecoratorWithTooltip } from 'feature-flag-system/components';
import {
  ActionIcon,
  Anchor,
  Switch,
  Text,
  Tooltip,
  Icon as UiIcon,
  Popover,
} from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';

interface SegmentationFilter {
  segmentsData: any[];
  segmentsApplyFilter: any[];
  segmentOnDelete;
  editSegmentOpenModal; //LOCAL
  updateSegment?: (segment: SegmentType) => any;
  updateFilter?: (filter: SegmentType) => any;
  serviceId: ServicesIds;
  onApply: (serviceId: ServicesIds) => void;
  plain: boolean;
  buttonClasses?: string;
  activatorType?: 'button' | 'icon';
  isFilter?: boolean;
  includeAdd?: boolean;
  openOnEmptyList?: boolean;
  onAddClick?: () => void;
  isShareMode?: boolean;
  type?: 'ck' | 'audit';
}

export const SegmentationPopup: React.FC<SegmentationFilter> = ({
  segmentsData = [],
  segmentOnDelete,
  editSegmentOpenModal,
  updateSegment,
  updateFilter,
  serviceId,
  onApply,
  plain,
  buttonClasses,
  activatorType = 'button',
  isFilter = false,
  onAddClick,
  openOnEmptyList = false,
  includeAdd,
  type = 'ck',
}) => {
  const darkMode = useDarkMode();
  const dispatch = useAppDispatch();
  const { isShareMode } = useContext(CreativesCockpitContext);
  const openFilter = useCallback(() => {
    setIsOpen(!isShareMode);
  }, [isShareMode]);

  const toggleFilter = useCallback(
    isFilter
      ? (filter) => () => {
          updateFilter?.({ ...filter, enabled: !filter.enabled });
          // ugly hack to get the new stats after the segment is ready in redux
          setTimeout(() => {
            onApply(serviceId);
          }, 1000);
        }
      : (segment) => () => {
          updateSegment?.({ ...segment, enabled: !segment.enabled });
          // ugly hack to get the new stats after the segment is ready in redux
          setTimeout(() => {
            onApply(serviceId);
          }, 1000);
        },
    isFilter ? [onApply, updateFilter] : [onApply, updateSegment],
  );

  const closeFilter = useCallback(() => {
    setIsOpen(false);
  }, []);

  const deleteSegment = useCallback(
    (id) => async () => {
      await segmentOnDelete(id);
      closeFilter();
    },
    [segmentOnDelete, closeFilter],
  );

  const [isOpen, setIsOpen] = useState(false);
  const isFiltered = useMemo(() => segmentsData?.some((s) => s.enabled), [segmentsData]);
  const enabledFiltersCount = useMemo(
    () => segmentsData.filter((s) => s.enabled).length,
    [segmentsData],
  );

  return (
    <LockDecoratorWithTooltip
      blockedFeatureText="Filter"
      featureFlag={FeatureFlag.SEGMENTATION_FF}
      lockPosition={{ top: -6, left: 10 }}
      FallbackComponent={
        <div className={'relative cursor-pointer'}>
          {activatorType === 'icon' && <ActionIcon disabled icon="filter" variant="transparent" />}
          {activatorType === 'button' && (
            <ActionIcon
              disabled
              icon={isFilter ? 'categories-major' : 'filter'}
              variant="activator"
              size="lg"
              radius="sm"
              iconSize={20}
            />
          )}
        </div>
      }
    >
      <Popover width={287} position="bottom">
        <Popover.Target>
          <div>
            <Tooltip
              label={
                <Text color="gray.6" size="sm" w={440}>
                  <Text span weight={500} color="gray.8">
                    Global filters
                  </Text>
                  {` allow you to apply filters throughout the entire platform, simultaneously updating all charts and reports.`}
                </Text>
              }
              multiline
            >
              <div className="relative">
                {activatorType === 'icon' && (
                  <ActionIcon
                    icon="filter"
                    onClick={() =>
                      segmentsData?.length > 0 || openOnEmptyList ? openFilter() : {}
                    }
                    iconSize={14}
                  />
                )}
                {isFiltered && (
                  <div
                    className={'turn-on-indication flex items-center justify-center z-10 top-[7px]'}
                  >
                    <Text weight={500} size="xs" color="gray.0">
                      {enabledFiltersCount}
                    </Text>
                  </div>
                )}
                {activatorType === 'button' && (
                  <ActionIcon
                    onClick={openFilter}
                    disabled={isShareMode || (segmentsData.length === 0 && !includeAdd)}
                    icon={isFilter ? 'categories-major' : 'filter'}
                    variant="activator"
                    size="lg"
                    radius="sm"
                    iconSize={20}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </Popover.Target>
        <Popover.Dropdown
          bg={darkMode ? ('var(--nsbBackground)' as any) : undefined}
          border={darkMode ? 'var(--nsbBorder)' : undefined}
        >
          <div className="flex flex-col justify-between">
            <Text size="xs" tt="uppercase" color={!darkMode ? 'gray.5' : undefined} pb="sm">
              Global Filters
            </Text>
            <div className="max-h-[215px] overflow-y-scroll no-scrollbar pb-7">
              {segmentsData.map((s, index) => (
                <div className="flex justify-between items-center">
                  <div className="flex gap-[12px] items-center">
                    <Switch size="xs" key={s.id} checked={s.enabled} onChange={toggleFilter(s)} />
                    <Text size="sm" color={!darkMode ? 'gray.7' : undefined} fw={500}>
                      {s.segmentTitle}
                    </Text>
                  </div>
                  <div className="flex gap-[8px] items-center">
                    <Anchor onClick={() => editSegmentOpenModal(s.id)}>
                      <UiIcon
                        name="filled-pencil"
                        color={!darkMode ? 'gray.5' : undefined}
                        size={13}
                      />
                    </Anchor>
                    <Anchor onClick={deleteSegment(s.id)}>
                      <Icon source={DeleteMinor} color="critical" />
                    </Anchor>
                  </div>
                </div>
              ))}
            </div>
            {segmentsData.length === 0 && (
              <div className="pb-[20px]">
                <Text size="sm" color="gray.7" fw={500} align="center">
                  You don't have any filters yet.
                </Text>
              </div>
            )}
            {includeAdd && (
              <div className="">
                <div className="mx-[-16px]">
                  <Divider />
                </div>
                <div
                  className="pt-[20px] pb-[10px] flex items-center gap-5 cursor-pointer"
                  onClick={() =>
                    onAddClick
                      ? onAddClick()
                      : isFilter
                        ? dispatch(openModalMetricFilter(serviceId!, ''))
                        : dispatch(
                            type === 'audit'
                              ? openModalAuditLogsSegmentation(serviceId!, '')
                              : openModalSegmentation(serviceId!, ''),
                          )
                  }
                >
                  <UiIcon name="union" size={14} color="one.4" />
                  <Text fw={500} color="one.4" size="sm">
                    {'Add Global Filter'}
                  </Text>
                </div>
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </LockDecoratorWithTooltip>
  );
};
