import { $isAdminClaim } from '$stores/$user';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { ActionIcon, Icon, Menu, Tooltip, Button } from '@tw/ui-components';
import { Component, useCallback, useMemo } from 'react';
import { MentionsInput, MentionsInputProps } from 'react-mentions';
import { genericEventLogger } from 'utils/dataLayer';
import { isMobileApp } from 'utils/Device';
import { useWindowSize } from 'utils/useWindowSize';
import { getCurrentAnalyticsActionSet } from './utils/willyUtils';
import { ChatSources, MobyUploadedFile } from './types/willyTypes';
import { ChatInputFile } from './ChatInputFile';
import { $mainChatStore, setDeepSearch, setBuildMode } from '$stores/willy/$mainChat';

type ChatInputProps = {
  value: string;
  context: ChatSources;
  inputRef: React.RefObject<Component<MentionsInputProps, any, any>>;
  children: MentionsInputProps['children'];
  withoutInputShadow?: boolean;
  willyLoading: boolean;
  isReadyForChat: boolean;
  currentAnalyticsEvent: string;
  placeholder?: string;
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (value: string) => void;
  onStopChat: () => void;
  toggleOpenFileDialog: () => void;
  uploadedFiles?: MobyUploadedFile[];
  removeFile: (index) => void;
  hideUpload?: boolean;
  expandedDesign?: boolean;
};

// DO NOT add dashContext or any specific context to this component.
export const ChatInput: React.FC<ChatInputProps> = ({
  value,
  setValue,
  context,
  inputRef,
  placeholder,
  disabled,
  children,
  onSubmit,
  withoutInputShadow,
  willyLoading,
  isReadyForChat,
  onStopChat,
  currentAnalyticsEvent,
  toggleOpenFileDialog,
  uploadedFiles,
  removeFile,
  hideUpload,
  expandedDesign,
}) => {
  const isUserAdmin = useStoreValue($isAdminClaim);
  const windowSize = useWindowSize();
  const deepSearch = useComputedValue($mainChatStore, (r) => r.deepSearch);
  const buildMode = useComputedValue($mainChatStore, (r) => r.buildMode);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { shiftKey, metaKey, key } = e;
      if (key === 'Enter' && !(metaKey || shiftKey)) {
        e.preventDefault();
        isReadyForChat && onSubmit(value);
        return;
      } else if (key === 'Enter' && (metaKey || shiftKey)) {
        e.preventDefault();
        const cursorPosition = (e.target as HTMLInputElement).selectionStart;
        if (cursorPosition !== null) {
          setValue((prev) => `${prev.slice(0, cursorPosition)}\n${prev.slice(cursorPosition)}`);
        } else {
          setValue((prev) => `${prev}\n`);
        }
        // move cursor to the place of the inserted \n
        setTimeout(() => {
          (e.target as HTMLInputElement).selectionStart = (cursorPosition ?? 0) + 1;
          (e.target as HTMLInputElement).selectionEnd = (cursorPosition ?? 0) + 1;
        }, 0);
        return;
      }
    },
    [onSubmit, isReadyForChat, value, setValue],
  );

  const UploadComponent = () => {
    if (!isUserAdmin || hideUpload) {
      return null;
    }

    return (
      <Menu shadow="md" width={300}>
        <Menu.Target>
          {expandedDesign ? (
            <Button variant="white" size="xs" px="xs">
              <Icon name="union" color="gray.5" />
            </Button>
          ) : (
            <ActionIcon icon="union" variant="transparent" />
          )}
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<Icon name="group-2" />} onClick={toggleOpenFileDialog}>
            Upload from Computer
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <div
      style={{
        boxShadow: withoutInputShadow ? 'none' : '0px 4px 6px -1px #0000001A',
        borderRadius: '10px',
        border: withoutInputShadow ? 'none' : '1.26px solid #D1D4DB',
        padding: 16,
        paddingRight: 80,
        paddingLeft: 16,
      }}
      className={`relative h-full `}
      onKeyDown={onInputKeyDown}
    >
      {!!uploadedFiles?.length && (
        <div className="flex gap-2 pb-4">
          {uploadedFiles?.map((file, i) => {
            return (
              <div className="w-[200px]" key={i}>
                <ChatInputFile file={file} index={i} removeFile={removeFile} />
              </div>
            );
          })}
        </div>
      )}
      <MentionsInput
        ref={inputRef}
        id="willy-input"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="w-full h-full"
        style={{
          control: {
            backgroundColor: '#fff',
            fontSize: 14,
            fontWeight: 'normal',
            border: 'none',
            // boxShadow: withoutInputShadow ? 'none' : '0px 0px 15px var(--p-dark-border)',
            // borderRadius: '10px',
            height: '100%',
          },
          highlighter: {
            // padding: 16,
            // paddingRight: 50,
            // paddingLeft: 40,
            border: 'none',
            fontSize: '16px',
            fontWeight: 600,
          },
          input: {
            // padding: 16,
            // paddingRight: 50,
            // paddingLeft: 16,
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            fontSize: '16px',
            fontWeight: 500,
            outline: 'none',
            height: '100%',
          },
          suggestions: {
            list: {
              backgroundColor: 'white',
              // border: '1px solid rgba(0,0,0,0.15)',
              fontSize: 14,
              maxHeight: '200px',
              overflow: 'auto',
            },
            item: {
              padding: '5px 15px',
              '&focused': {
                backgroundColor: '#cee4e5',
              },
            },
          },
        }}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={!windowSize.isSmall && !isMobileApp}
        // inputRef={inputRef}
        forceSuggestionsAboveCursor={true}
      >
        {children}
      </MentionsInput>
      <div className="absolute flex-shrink-0 bottom-[13px] right-[16px]">
        {willyLoading && (
          <ActionIcon
            icon="pause"
            variant="transparent"
            onClick={() => {
              onStopChat();
            }}
          />
        )}

        {!willyLoading && (
          <div className="flex gap-3">
            {!expandedDesign && <UploadComponent />}
            <Tooltip
              label={
                !isReadyForChat ? 'Warming up chat...' : !value?.trim() ? 'Please enter value' : ''
              }
            >
              <ActionIcon
                size="md"
                disabled={!value?.trim() || !isReadyForChat}
                variant={!value?.trim() || !isReadyForChat ? 'light' : 'filled'}
                radius="sm"
                color="white"
                onClick={(e) => {
                  onSubmit(value);
                  genericEventLogger(currentAnalyticsEvent, {
                    action: getCurrentAnalyticsActionSet(context).SEND_MESSAGE,
                    prompt: value,
                  });
                }}
                icon="send-chat"
                iconSize={16}
              />
            </Tooltip>
          </div>
        )}
      </div>

      {expandedDesign && (
        <div className="flex gap-4 items-center mt-8">
          <UploadComponent />
          <Button
            onClick={() => setDeepSearch(!deepSearch)}
            variant={deepSearch ? 'primary' : 'white'}
            size="xs"
            leftSection={<Icon name="search-major" color={deepSearch ? 'white' : 'gray.5'} />}
          >
            Deep Dive
          </Button>
          <Button
            onClick={() => setBuildMode(!buildMode)}
            variant={buildMode ? 'primary' : 'white'}
            size="xs"
            leftSection={<Icon name="build" color={buildMode ? 'white' : 'gray.5'} />}
          >
            Build
          </Button>
        </div>
      )}
    </div>
  );
};
