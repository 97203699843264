import { Button, colors, Icon, Tooltip, Text, Flex, Badge } from '@tw/ui-components';
import { stepActionType, WorkflowStep } from '../types/willyTypes';
import { useFeatureFlag } from 'feature-flag-system';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { useState } from 'react';

type StepSelectorProps = {
  action: stepActionType;
  shouldBeDisabled: boolean;
  disabledReason?: string;
  color?: keyof typeof colors;
  step: WorkflowStep;
  stepChange: (step: WorkflowStep) => void;
};

export const StepSelector: React.FC<StepSelectorProps> = ({
  action,
  shouldBeDisabled,
  disabledReason,
  color,
  step,
  stepChange,
}) => {
  const { shouldNotBeSeen: isLocked } = useFeatureFlag(action.ff);
  const disabled = isLocked || shouldBeDisabled;
  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip withArrow w={250} multiline label={disabledReason} key={action.type}>
      <div>
        <Button
          disabled={disabled}
          variant="activatorWithHover"
          bg={hovered ? 'gray.1' : 'gray.0'}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          justify="start"
          h={82}
          p="md"
          fullWidth
          onClick={() => {
            if (shouldBeDisabled) {
              return;
            }
            let newStep: WorkflowStep = {
              ...step,
              stepType: action.type as any,
            };

            stepChange(newStep);
          }}
        >
          <Flex direction="column">
            <Flex gap="xs" align="center">
              {isLocked ? (
                <LockedFeatureIndicator featureFlag={action.ff} iconOnly iconSize={18} />
              ) : (
                <Icon
                  name={action.icon}
                  size={18}
                  color={disabled ? 'gray.4' : `${color || 'green'}.5`}
                />
              )}
              <Text color={disabled ? 'gray.4' : 'gray.8'} weight={500} size="sm">
                {action.name}
              </Text>
              {action.isDefault && (
                <Badge bg="one.0" pos="absolute" top={16} right={16}>
                  <Text size="sm" tt="capitalize" color="one.8">
                    Default
                  </Text>
                </Badge>
              )}
            </Flex>
            {action.tooltip && (
              <Text size="sm" lh="30px" color={disabled ? 'gray.4' : 'gray.6'}>
                {action.tooltip}
              </Text>
            )}
          </Flex>
        </Button>
      </div>
    </Tooltip>
  );
};
