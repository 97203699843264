import { useDarkMode } from 'dark-mode-control';
import './BaseChart.scss';

import React, { FC } from 'react';
import { CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Props as xProps } from 'recharts/types/cartesian/XAxis';
import { Props as yProps } from 'recharts/types/cartesian/YAxis';
import { Text } from '@tw/ui-components';

interface BaseChartProps<D> {
  data?: D;
  ChartType: typeof React.Component;
  showTooltip?: boolean;
  height?: number;
  wrapperStyle?: React.CSSProperties;
  xAxis?: xProps[];
  yAxis?: yProps[];
  verticalLines?: boolean;
  horizontalLines?: boolean;
  margin?: { top?: number; left?: number; bottom?: number; right?: number };
  layout?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
  barCategoryGap?: number;
  barGap?: number;
  grid?: boolean;
  leftYAxisLabel?: string;
  rightYAxisLabel?: string;
  xAxisLabel?: string;
}

const BaseChart: FC<BaseChartProps<any>> = (props) => {
  const {
    data,
    ChartType,
    children,
    height = 400,
    xAxis = [],
    yAxis = [],
    wrapperStyle = {},
    verticalLines = false,
    horizontalLines = true,
    margin = {},
    layout = 'horizontal',
    barCategoryGap,
    barGap,
    grid = true,
    leftYAxisLabel,
    rightYAxisLabel,
  } = props;

  const darkMode = useDarkMode();

  return (
    <div
      className="BaseChart"
      data-testid="BaseChart"
      style={{
        ...{
          background: !darkMode ? 'white' : 'var(--gray-dark-mode-600)',
        },
        ...wrapperStyle,
      }}
    >
      <ResponsiveContainer width={'100%'} height={height}>
        <ChartType
          padding={{ top: 0, right: 0, left: 0, bottom: 0 }}
          data={data}
          margin={{ ...{ top: 30, right: -30, left: -15, bottom: 30 }, ...margin }}
          layout={layout}
          {...(barCategoryGap ? { barCategoryGap } : {})}
          {...(barGap ? { barGap } : {})}
        >
          {grid && (
            <CartesianGrid
              vertical={verticalLines}
              horizontal={horizontalLines}
              stroke={'var(--text-color-transparent-20)'}
            />
          )}
          {xAxis.map((axis, i) => {
            const {
              tickLine = false,
              axisLine = false,
              dataKey: xDataKey,
              tickFormatter: xTickFormatter,
              dy,
              ...restXAxis
            } = axis || {};
            return (
              <XAxis
                dy={typeof dy !== 'undefined' ? dy : 30}
                key={'xAxis-' + i}
                stroke={darkMode ? 'white' : 'black'}
                tickLine={tickLine}
                axisLine={axisLine}
                dataKey={xDataKey}
                tickFormatter={xTickFormatter}
                {...(restXAxis as any)}
              />
            );
          })}
          {yAxis.map((axis, i) => {
            const {
              tickLine = false,
              axisLine = false,
              type = 'number',
              yAxisId,
              tickFormatter: yTickFormatter,
              orientation = 'left',
              dx,
              ...rest
            } = axis;
            return (
              <YAxis
                key={i}
                dx={typeof dx !== 'undefined' ? dx : i === 0 ? 30 : -30}
                stroke={darkMode ? 'white' : 'black'}
                tickLine={tickLine}
                axisLine={axisLine}
                yAxisId={yAxisId}
                type={type}
                tickFormatter={yTickFormatter}
                orientation={orientation}
                label={(props) => {
                  const { viewBox } = props;
                  return (
                    <YAxisLabel
                      viewBox={viewBox}
                      text={orientation === 'left' ? leftYAxisLabel || '' : rightYAxisLabel || ''}
                      orientation={orientation}
                    />
                  );
                }}
                {...(rest as any)}
              />
            );
          })}
          {children}
        </ChartType>
      </ResponsiveContainer>
    </div>
  );
};

const YAxisLabel: FC<{ viewBox: any; text: string; orientation: 'left' | 'right' }> = ({
  viewBox,
  text,
  orientation,
}) => {
  return (
    <foreignObject
      x={viewBox.x}
      y={viewBox.y}
      width={viewBox.width}
      height={viewBox.height}
      className="relative"
    >
      <div
        style={{
          writingMode: 'vertical-rl',
          textAlign: 'center',
          transform: orientation === 'left' ? 'rotate(180deg)' : '',
          cursor: 'pointer',
        }}
        className="flex items-center justify-center w-full h-full"
      >
        <Text size="xs" weight={500} color="gray.6" truncate>
          {text}
        </Text>
      </div>
    </foreignObject>
  );
};

export default BaseChart;
