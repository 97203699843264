import { useState } from 'react';
import { FinalReportResponse, Message } from './types/willyTypes';
import { Flex, Icon, Text } from '@tw/ui-components';
import { WillySimpleText } from './WillySimpleText';
import { FullAnswerModal } from './sequenceBuilder/FullAnswerModal';
import { $user } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import _db from 'utils/DB';
import { v4 as uuidv4 } from 'uuid';

export const WillyFinalReport = ({
  finalReportMessage,
  conversationId,
  onUpdateFinalReport,
}: {
  finalReportMessage: Message;
  conversationId?: string;
  onUpdateFinalReport?: (messages: Message[]) => void;
}) => {
  const finalReport = (finalReportMessage.toolResults as FinalReportResponse)?.finalReport;
  const reportOutputType = (finalReportMessage.toolResults as FinalReportResponse)?.outputType;
  const user = useStoreValue($user);
  const [editOutput, setEditOutput] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    {
      id: uuidv4(),
      role: 'assistant',
      text: finalReport || '',
    },
  ]);

  const updateFinalReport = async (output: string) => {
    if (conversationId) {
      const convo = await _db().collection('conversations').doc(conversationId).get();

      if (convo) {
        const updatedHistory = convo.data()?.history.map((message) => {
          if (message.messageId === finalReportMessage.id) {
            return {
              ...message,
              toolResults: {
                ...message.toolResults,
                finalReport: output,
              },
            };
          }
          return message;
        });

        setMessages([
          {
            id: uuidv4(),
            role: 'assistant',
            text: output,
          },
        ]);

        // propagate messages up to the parent
        onUpdateFinalReport?.(updatedHistory);

        await _db().collection('conversations').doc(conversationId).set(
          {
            history: updatedHistory,
          },
          {
            merge: true,
          },
        );
      }
    }
  };

  return (
    <div className="w-full flex-auto dark:bg-transparent bg-white rounded shadow">
      <div className="border-0 border-solid border-b-[1px] border-black/10 p-4">
        <Flex align="center" justify="space-between">
          <Text fw="bold" size="lg">
            Final Report
          </Text>
          {reportOutputType !== 'text' && (
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => {
                setEditOutput(true);
              }}
            >
              <Icon name="edit" size={12} color="one.5" />
              <Text size="sm" color="one.5">
                Edit Output
              </Text>
            </div>
          )}
        </Flex>
      </div>

      {finalReport ? (
        <div className={`${reportOutputType === 'text' ? 'p-4' : ''}`}>
          <WillySimpleText text={finalReport} />
        </div>
      ) : (
        <div className="p-4">
          <Text>No final report found</Text>
        </div>
      )}

      {editOutput && (
        <FullAnswerModal
          fullAnswerPopupOpen={editOutput}
          setFullAnswerPopupOpen={setEditOutput}
          messages={messages}
          loadingAnswers={false}
          user={user}
          isGenUiStep={true}
          setNewGenUiOutput={updateFinalReport}
          isFromMainChat={true}
        />
      )}
    </div>
  );
};
