import { $currentShopId } from '$stores/$shop';
import { $socket } from '$stores/$socket';
import { $user } from '$stores/$user';
import {
  $chatsToWatch,
  removeChatToWatch,
  updateEndTimeOnChatToWatch,
} from '$stores/willy/$chatsToWatch';
import { useStoreValue } from '@tw/snipestate';
import { Text } from '@tw/ui-components';
import { useCallback, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';

//DO NOT IMPORT THIS HOOK, IT IS IMPORTABLE ONLY IN THE AGENT TOAST COMPONENT
export const useChatSocketWatch = (): void => {
  const navigate = useNavigate();
  const socket = useStoreValue($socket);
  const currentShopId = useStoreValue($currentShopId);
  const chatsToWatch = useStoreValue($chatsToWatch);
  const location = useLocation();
  const { userNotificationSettings, shopNotificationSettings, loadingSettings } = useSelector(
    (state: RootState) => state.notificationSettings,
  );
  const user = useSelector((state: RootState) => state.user);

  const onChatFinished = useCallback(
    (data) => {
      const { conversationId } = data;
      if (!chatsToWatch.some((chat) => chat.conversationId === conversationId)) {
        return;
      }
      updateEndTimeOnChatToWatch(conversationId);
      const showToast = () => {
        let params = new URLSearchParams(location.search);
        params.set('conversationId', conversationId);
        const toastId = toast(
          <div
            className="cursor-pointer"
            onClick={() => {
              toast.dismiss(toastId);
              navigate({ pathname: '/chat', search: params.toString() });
            }}
          >
            <Text fw={600} fz={14}>
              * Deep Dive Finished *
            </Text>
            <Text fz={14}>See Results</Text>
          </div>,
          {
            icon: false, // No icon
            autoClose: false, // Stay until clicked
            closeOnClick: true, // Prevent default closing

            onClose: () => {
              toast.dismiss(toastId);
            },
          },
        );
      };
      const sendPushNotification = async () => {
        await axiosInstance.post('v2/push-notifications/receive-push-notification-from-client', {
          shopId: currentShopId,
          notification: {
            userId: user.uid,
            platform: 'mobile',
            priority: 'normal',
            service_id: 'sequences',
            notificationData: {
              title: `Deep Dive Finished`,
              body: 'See Results',
              icon: 'https://app.triplewhale.com/lighthouse-icons/apple-touch-icon.png',
            },
            sourceId: conversationId,
            shopId: currentShopId,
            customOpenParams: `navigateUrl=chat/history`,
          },
        });
      };
      const params = new URLSearchParams(location.search);
      const paramsConversationId = params.get('conversationId');
      if (location.pathname !== '/chat' || paramsConversationId !== conversationId) {
        showToast();
        if (
          userNotificationSettings?.['moby']?.topics?.['deep_search_finished']?.mobile !== false &&
          user.expoPushToken
        ) {
          sendPushNotification();
        }
      }

      // removeChatToWatch(conversationId);
    },
    [
      navigate,
      location.search,
      chatsToWatch,
      location.pathname,
      userNotificationSettings,
      user.expoPushToken,
      user.uid,
      currentShopId,
    ],
  );

  const onChatStarted = useCallback(() => {}, []);

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'chat-progress' || msg.account !== currentShopId) {
        return;
      }
      if (msg.data.type === 'done') {
        onChatFinished(msg.data);
      }
    };
    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [socket, currentShopId, onChatFinished]);
};
