import { AppExtensionMinor, ReportMinor } from '@shopify/polaris-icons';
import { ReactComponent as SettingsIcon } from 'components/Icons/settings-topBar.svg';
import { NavSection } from '../types';
import { META_STORE_SETTINGS_ROUTES } from '@tw/shared-types/module/frontend-config/constants/routes';
import { $isFreeShop } from '$stores/willy/$subscription';
import { $isAdminClaim } from '$stores/$user';

export const STORE_SETTINGS_SECTION: NavSection = {
  routes: [
    {
      url: `/store-settings/general`,
      ...META_STORE_SETTINGS_ROUTES['/store-settings/general'],
      uiGroupName: 'General Settings',
      label: 'Store',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      url: `/store-settings/team`,
      ...META_STORE_SETTINGS_ROUTES[`/store-settings/team`],
      uiGroupName: 'General Settings',
      label: 'Team',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      url: `/store-settings/delete-pii`,
      ...META_STORE_SETTINGS_ROUTES[`/store-settings/delete-pii`],
      uiGroupName: 'General Settings',
      label: 'Customer Data Removal',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
      dependsOnFFSystemCallback() {
        return $isAdminClaim.get();
      },
    },
    {
      url: `/store-settings/plans`,
      ...META_STORE_SETTINGS_ROUTES[`/store-settings/plans`],
      uiGroupName: 'Plans and Payment',
      label: 'Plans',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      url: `/store-settings/billing`,
      ...META_STORE_SETTINGS_ROUTES[`/store-settings/billing`],
      uiGroupName: 'Plans and Payment',
      label: 'Billing',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
      dependsOnFFSystemCallback() {
        return !$isFreeShop.get();
      },
    },
    {
      url: `/store-settings/orders-invoices`,
      ...META_STORE_SETTINGS_ROUTES[`/store-settings/orders-invoices`],
      uiGroupName: 'Plans and Payment',
      label: 'Invoices',
      header: () => <></>,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      url: '/reports',
      ...META_STORE_SETTINGS_ROUTES['/reports'],
      uiGroupName: 'Store Configurations',
      label: 'Reports',
      header: () => <></>,
      icon: () => <ReportMinor className="flex items-center w-12 h-12 fill-logo" />,
    },
    {
      url: '/integrations',
      ...META_STORE_SETTINGS_ROUTES['/integrations'],
      uiGroupName: 'Store Configurations',
      label: 'Integrations',
      icon: () => (
        <img
          className="flex items-center w-full h-auto"
          src="/integration/header.svg"
          alt="integration"
        />
      ),
      header: () => <></>,
    },
    // {
    //   url: '/apps',
    //   ...META_STORE_SETTINGS_ROUTES['/apps'],
    //   uiGroupName: 'Store Configurations',
    //   label: 'Apps & Extensions',
    //   header: () => <></>,
    //   icon: () => <AppExtensionMinor className="flex items-center w-12 h-12 fill-logo" />,
    // },
    {
      url: '/api-keys',
      ...META_STORE_SETTINGS_ROUTES['/api-keys'],
      uiGroupName: 'Store Configurations',
      label: 'API Keys',
      header: () => <></>,
      icon: () => <AppExtensionMinor className="flex items-center w-12 h-12 fill-logo" />,
    },
    {
      url: '/cost-settings',
      ...META_STORE_SETTINGS_ROUTES['/cost-settings'],
      uiGroupName: 'Store Configurations',
      label: 'Cost Settings',
      icon: () => <SettingsIcon width={30} />,
      header: () => <></>,
    },
    {
      url: '/usage',
      ...META_STORE_SETTINGS_ROUTES['/usage'],
      uiGroupName: 'Store Configurations',
      label: 'Usage',
      header: () => <></>,
    },
    {
      url: '/attribution/settings',
      ...META_STORE_SETTINGS_ROUTES['/attribution/settings'],
      uiGroupName: 'Store Configurations',
      label: 'Pixel Settings',
      header: () => <></>,
    },
    {
      url: '/sonar-settings',
      ...META_STORE_SETTINGS_ROUTES['/sonar-settings'],
      uiGroupName: 'Store Configurations',
      label: 'Sonar Settings',
      header: () => <></>,
    },
    {
      url: '/utm-builder',
      ...META_STORE_SETTINGS_ROUTES['/utm-builder'],
      uiGroupName: 'Store Configurations',
      label: 'Tracking Settings',
      header: () => <></>,
    },
    {
      url: '/attribution/traffic-rules',
      uiGroupName: 'Store Configurations',
      label: 'Traffic Rules',
      ...META_STORE_SETTINGS_ROUTES['/attribution/traffic-rules'],
      header: () => <></>,
    },
    {
      url: `/global-filters`,
      label: 'Global Filters',
      ...META_STORE_SETTINGS_ROUTES[`/global-filters`],
      header: () => <></>,
    },
    {
      url: `/mmm-settings`,
      label: 'MMM Settings',
      ...META_STORE_SETTINGS_ROUTES[`/mmm-settings`],
      header: () => <></>,
    },
  ],
};
