import './TWTable.scss';

import { FC, useMemo } from 'react';

import { DataTable, DataTableProps } from '@shopify/polaris';

import { SortDirection } from '@tw/types';

import { BaseColumn } from './types';
import { Skeleton } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { $isAdminClaim } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';

interface TWTableProps<D extends {}, C extends string | number, M extends {} = any> {
  id?: string;
  className?: 'table-light-style';
  columns: BaseColumn<D, C>[];
  data: D;
  metadata?: M;
  type?: string;
  height?: number | string;
  sortBy?: D;
  sortDirection?: SortDirection;
  headerIconPosition?: 'left' | 'right';
  stickyHeader?: boolean;
  stickyColumnIndex?: number;
  stickyRightColumnIndex?: number;
  loading?: boolean;
  totals?: any;
  padding?: string;
  pagination?: boolean;
  itemsPerPage?: number;
  page?: number;
  dark?: boolean;
  hideScrollIndicator?: boolean;
  itemToConsiderInPagination?: D[];
  totalsName?: DataTableProps['totalsName'];
  skeletonRows?: number;
  noDataNode?: JSX.Element[][];
  rounded?: boolean;
  onSort?: (headingIndex: number, direction: SortDirection, columnKey?: C) => void;
  noShadow?: boolean;
}

const TWTable: FC<TWTableProps<any, any>> = (props) => {
  const {
    id = 'tw-table',
    className,
    columns = [],
    data,
    metadata,
    height = 1000,
    sortDirection,
    headerIconPosition = 'left',
    sortBy,
    stickyHeader = false,
    stickyColumnIndex,
    stickyRightColumnIndex,
    hideScrollIndicator = true,
    loading,
    totals,
    padding,
    dark,
    totalsName,
    skeletonRows = 5,
    rounded = true,
    noDataNode = [[<div className="flex items-center justify-center p-4">No Data</div>]],
    onSort,
    noShadow,
  } = props;
  const darkMode = useDarkMode();
  const isUsingDarkStyles = dark ?? darkMode;
  const maxHeight = typeof height === 'number' ? `${height}px` : height;
  const isAdmin = useStoreValue($isAdminClaim);

  const columnsToShow = useMemo(() => {
    return columns.filter((c) => !c.adminOnly || isAdmin);
  }, [columns, isAdmin]);

  const stickyRightIndex = stickyRightColumnIndex
    ? columnsToShow?.length + 1 - stickyRightColumnIndex
    : 0;

  const showTotals = useMemo(() => {
    if (!data?.length) return false;

    return columnsToShow.some((c) => c.Total);
  }, [columnsToShow, data?.length]);

  return (
    <div className={`h-full ${isUsingDarkStyles ? 'dark-tw-table' : ''}`}>
      <div
        className={`triple-table ${id} ${className} overflow-auto ${stickyHeader ? 'sticky-header' : ''} 
        ${typeof stickyColumnIndex !== 'undefined' ? 'sticky-left-column' : ''}
        ${typeof stickyRightColumnIndex !== 'undefined' ? 'sticky-right-column' : ''}`}
      >
        {!loading && data && (
          <DataTable
            hideScrollIndicator={hideScrollIndicator}
            verticalAlign="middle"
            hoverable={false}
            defaultSortDirection={sortDirection}
            initialSortColumnIndex={columnsToShow.findIndex((c) => c.key === sortBy)}
            columnContentTypes={columnsToShow.map((c) => c.dataType)}
            headings={columnsToShow.map((c) => c.Heading(metadata))}
            sortable={columnsToShow.map((c) => c.sortable)}
            rows={
              !data.length
                ? noDataNode
                : data.map((element: unknown) =>
                    columnsToShow.map((col) => col.Value(element, metadata)),
                  )
            }
            onSort={(headerIndex, direction) => {
              if (onSort) {
                onSort(headerIndex, direction, columnsToShow[headerIndex]?.key);
              }
            }}
            totals={showTotals ? columnsToShow.map((c) => c.Total?.(totals, metadata)) : undefined}
            totalsName={totalsName || { singular: '', plural: '' }}
            showTotalsInFooter={showTotals}
          />
        )}
        {loading && (
          <DataTable
            hideScrollIndicator={true}
            verticalAlign="middle"
            hoverable={false}
            columnContentTypes={columnsToShow.map((c) => c.dataType)}
            headings={columnsToShow.map((c) => c.Heading(metadata))}
            rows={Array(skeletonRows).fill(
              columnsToShow.map((c) => (
                <div className="p-4">
                  <Skeleton width="100%" height={28} />
                </div>
              )),
            )}
          />
        )}
      </div>
      <style>
        {`
          .triple-table.${id} {
            border-radius: ${rounded ? '0.5rem' : '0'};
            box-shadow:${noShadow ? 'none' : 'rgba(0, 0, 0, 0.08) 0px 0px 8px'};
          }
          .triple-table.${id}.sticky-left-column tbody tr td:nth-child(${stickyColumnIndex}),
          .triple-table.${id}.sticky-left-column tbody tr th:nth-child(${stickyColumnIndex}),
          .triple-table.${id}.sticky-left-column tfoot tr td:nth-child(${stickyColumnIndex}),
          .triple-table.${id}.sticky-left-column tfoot tr th:nth-child(${stickyColumnIndex})
          {
            position: sticky;
            left: 0;
            z-index: 12 !important;
          }
          .triple-table.${id}.sticky-left-column thead tr th:nth-of-type(${stickyColumnIndex}),
          .triple-table.${id}.sticky-left-column tfoot tr th:nth-of-type(${stickyColumnIndex}) {
            position: sticky;
            left: 0;
            z-index: 101 !important;
          }

          .triple-table.${id}.sticky-right-column tbody tr td:nth-child(${stickyRightIndex}),
          .triple-table.${id}.sticky-right-column tbody tr th:nth-child(${stickyRightIndex}),
          .triple-table.${id}.sticky-right-column tfoot tr td:nth-child(${stickyRightIndex}),
          .triple-table.${id}.sticky-right-column tfoot tr th:nth-child(${stickyRightIndex}),
          .triple-table.${id}.sticky-right-column thead tr th:nth-of-type(${stickyRightIndex}),
          .triple-table.${id}.sticky-right-column tfoot tr th:nth-of-type(${stickyRightIndex}) {
            position: sticky;
            right: 0;
            z-index: 101 !important;
            border-left: 1px solid var(--border-color);
          }

          .triple-table.${id}.sticky-right-column tbody tr td:nth-child(${stickyRightIndex - 1}),
          .triple-table.${id}.sticky-right-column tbody tr th:nth-child(${stickyRightIndex - 1}),
          .triple-table.${id}.sticky-right-column tfoot tr td:nth-child(${stickyRightIndex - 1}),
          .triple-table.${id}.sticky-right-column tfoot tr th:nth-child(${stickyRightIndex - 1}),
          .triple-table.${id}.sticky-right-column thead tr th:nth-of-type(${stickyRightIndex - 1}),
          .triple-table.${id}.sticky-right-column tfoot tr th:nth-of-type(${stickyRightIndex - 1}) {
            border-right: none;
          }

          .triple-table.${id}.sticky-header .Polaris-DataTable__ScrollContainer {
            max-height: ${maxHeight};
          }
          
          .triple-table.${id} tbody .Polaris-DataTable__Cell {
            padding: ${padding || '0.625rem 0.9375rem'};
          }
          .triple-table.${id} tfoot .Polaris-DataTable__Cell {
            padding: ${padding || '0.625rem 0.9375rem'};
          }
          .triple-table.${id} table thead tr th .Polaris-DataTable__Icon {
            position: ${headerIconPosition ? 'absolute' : 'relative'};
            left: ${headerIconPosition === 'left' ? '0' : 'auto'};
          }
        `}
      </style>
    </div>
  );
};

export default TWTable;
