import {
  Flex,
  FormattedColor,
  IconName,
  Text,
  Tooltip,
  Group,
  Checkbox,
  Container,
} from '@tw/ui-components';
import allServices from 'constants/services';
import { FC, useMemo, CSSProperties } from 'react';
type LegendProps = {
  label?: string;
  color: string;
  active?: boolean;
  tooltipText?: string;
  content?: IconName | React.ReactNode;
  onClick?: () => void;
  onRemove?: () => void;
  circle?: boolean;
  labelRightIcon?: React.ReactNode;
  labelMaxWidth?: number;
  maxCharacters?: number;
  serviceId?: string;
};
export const BaseLegend: FC<LegendProps> = ({
  label,
  color,
  tooltipText,
  content,
  active = true,
  onClick,
  labelMaxWidth = 150,
}) => {
  return (
    <Tooltip label={<Text c={color as FormattedColor}>{tooltipText || label}</Text>}>
      <Flex gap={'md'} align="center" opacity={active ? 1 : 0.4} cursor="pointer" onClick={onClick}>
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
        <Flex align="center" gap={'xs'} maw={labelMaxWidth}>
          {content}
          <Text size="sm" tt="capitalize" truncate ml={2} fw={500}>
            {tooltipText || label}
          </Text>
        </Flex>
      </Flex>
    </Tooltip>
  );
};
