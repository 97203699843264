import { useState, useEffect } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { useDeepCompareMemoize } from './useDeepCompareMemoize';

export type UserBasicSafeInfo = {
  id: string;
  displayName: string;
  photoURL: string;
};

export const useUsersByIds = (ids: string[]) => {
  const [users, setUsers] = useState<UserBasicSafeInfo[]>([]);
  const [loading, setLoading] = useState(true);

  const stableIds = useDeepCompareMemoize(ids);

  useEffect(() => {
    const fetchUsers = async () => {
      if (stableIds.length === 0) {
        setUsers([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const { users, notFound } = await getUsersByIds(stableIds);
        const notFoundUsers = notFound.map((id) => ({
          id,
          displayName: 'Unknown User',
          photoURL: '',
        }));
        setUsers(users.concat(notFoundUsers));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [stableIds]);

  return { users, loading };
};

export async function getUsersByIds(ids: string[]) {
  const { data } = await axiosInstance.post('/v2/sequences/get-users-by-ids', { ids });
  return {
    users: data.users,
    notFound: data.notFound,
  };
}
