import { services } from '@tw/types/module/services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/RootType';

export const useCanUpdateAd = (serviceId: string | null) => {
  const canUpdateAd = useSelector((state: RootState) => state.canUpdateAd);
  const canUpdateChannelAds = useSelector((state: RootState) => state.canUpdateChannelAds);
  const facebookAdsScopes = useSelector((state: RootState) => state.shop?.facebookAdsScopes);
  const sensoryIntegrations = useAppSelector((state) => state.sensory?.sensoryForShop);

  const [updateAdsAllowed, setUpdateAdsAllowed] = useState(false);
  const [hasUpdateScope, setHasUpdateScope] = useState(false);

  const checkUpdateAdsAllowed = useCallback(
    (serviceId: string) => {
      switch (serviceId) {
        case 'facebook-ads':
          return canUpdateAd;
        default:
          return serviceId ? !!canUpdateChannelAds[serviceId] : false;
      }
    },
    [canUpdateAd, canUpdateChannelAds],
  );

  const checkHasUpdateScope = useCallback(
    (serviceId: string) => {
      if (!serviceId) {
        return false;
      }
      if (serviceId === 'facebook-ads') {
        return !!facebookAdsScopes?.includes('ads_management');
      }

      if (
        services?.[serviceId]?.hasUpdateEntityScope?.(sensoryIntegrations?.[serviceId]) === false
      ) {
        return false;
      }

      return true;
    },
    [facebookAdsScopes, sensoryIntegrations],
  );

  const canUpdateAdForAllServices = useMemo(() => {
    return Object.keys(services).map((serviceId) => {
      return {
        serviceId,
        updateAdsAllowed: checkUpdateAdsAllowed(serviceId),
        hasUpdateScope: checkHasUpdateScope(serviceId),
      };
    });
  }, [checkUpdateAdsAllowed, checkHasUpdateScope]);

  useEffect(() => {
    if (serviceId) {
      const allowedToUpdateAds = checkUpdateAdsAllowed(serviceId);
      const hasUpdateScope = checkHasUpdateScope(serviceId);

      setUpdateAdsAllowed(allowedToUpdateAds);
      setHasUpdateScope(hasUpdateScope);
    }
  }, [checkUpdateAdsAllowed, checkHasUpdateScope, serviceId]);

  return { updateAdsAllowed, hasUpdateScope, canUpdateAdForAllServices };
};
