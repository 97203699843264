import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { $derived, $mutableObserver, $store } from '@tw/snipestate';
import _db, { toArray } from 'utils/DB';
import { $shopSequences } from './$sequences';
import { RunDoc } from 'components/Willy/types/willyTypes';

const $unreadAgentsRunsRaw = $mutableObserver<string[]>([], async (get, set) => {
  if (!get($userId)) return;
  if (!get($currentShopId)) return;
  if (!get($shopSequences).length) return;

  const shopSequences = get($shopSequences);
  const cleanShopSequences = shopSequences.filter((sequence) => !!sequence.lastRunAt);

  const runsPromises = cleanShopSequences.map(async (workflow) => {
    const runs = await _db()
      .collection('data_sequences')
      .doc(workflow.id)
      .collection('runs')
      .orderBy('finishedAt', 'desc')
      .limit(1)
      .get();

    const runsArray = toArray(runs) as RunDoc[];
    const lastRun: RunDoc = {
      ...runsArray?.[0],
    };
    return lastRun;
  });
  const runs = await Promise.all(runsPromises);
  const unreadAgents = runs.filter((run) => !run.read).map((run) => run.sequenceId);
  set(unreadAgents);
  return;
});

export const $newlyRunWorkflows = $store<string[]>([]);

export const $unreadAgentRunsCount = $derived((get) => {
  const unreadAgentsRunsRaw = get($unreadAgentsRunsRaw);
  return unreadAgentsRunsRaw.length;
});

export const addToNewlyRunAgentRuns = (seqId) => {
  $newlyRunWorkflows.set((prev) => {
    const filtered = prev.filter((id) => id !== seqId);
    return [seqId, ...filtered];
  });
};
