import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, RadioButton, Button } from '@shopify/polaris';
import { setUseShippingCharges } from '../ducks/actions';
import { CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons';
import { type RootState } from '../reducers/RootType';
import { useAppDispatch } from 'index';
import { computeFeatureFlags } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { Text } from '@tw/ui-components';
import { useNavigate } from 'react-router';
import { KB_BASE_URL } from 'constants/knowledgeBaseLinks';
import { IntegrationWidget } from 'routes/integrations/components/IntegrationWidget';
import { useIntegrationsConfig } from 'routes/integrations/hooks/useIntegrationsConfig';
import DefaultShippingSection from './DefaultShippingSection';
import { ReactComponent as ShippingIcon } from './onboarding/icons/shipping.svg';

const ShippingComponent = ({ isWizard, setNextButton }) => {
  const activeShippingProvider: string = useSelector(
    (state: RootState) => state.activeShippingProvider,
  );
  const msp = useSelector((state: RootState) => state.msp);
  const dispatch = useAppDispatch();

  const useShippingChargesAsCost = useSelector(
    (state: RootState) => state.useShippingChargesAsCost,
  );
  const navigate = useNavigate();
  const [showEstimatedCosts, setShowEstimatedCosts] = useState<boolean>(true);

  const integrationsWidgets = useIntegrationsConfig();
  const shippingWidget = integrationsWidgets?.find((w) => w?.id === activeShippingProvider);
  return (
    <div className="p-6.5">
      <Card>
        <Card.Section>
          {isWizard ? (
            <div className="flex">
              <div>
                <ShippingIcon />
              </div>{' '}
              <div className="ml-5">Shipping</div>
            </div>
          ) : (
            <div>
              {' '}
              <Button
                plain
                onClick={() =>
                  window.open(
                    KB_BASE_URL + 'articles/6119764-tracking-profits-setting-up-your-expenses',
                  )
                }
              >
                Learn more about shipping costs
              </Button>
            </div>
          )}
        </Card.Section>
        <Card.Section>
          <RadioButton
            label="Use Shipping Charges for Shipping Costs"
            name="shipping"
            checked={useShippingChargesAsCost === true}
            onChange={(checked) => {
              setNextButton("Let's go!");
              dispatch(setUseShippingCharges(checked));
            }}
          />

          <div className="text-gray-500 pt-5">
            Enable this setting if your shipping costs are equal to what your customers have been
            charged for shipping
          </div>
        </Card.Section>
        {!isWizard && (
          <Card.Section>
            <div className="flex space-between align-items-center">
              <RadioButton
                label="Use Shipping Integration for Shipping Costs"
                name="shipping"
                checked={activeShippingProvider !== msp && useShippingChargesAsCost === false}
                onChange={() => {
                  if (activeShippingProvider === msp) {
                    navigate('/integrations');
                  } else dispatch(setUseShippingCharges(false));
                }}
              />
              <Button onClick={() => navigate('/integrations')}>Go to Integrations</Button>
            </div>
            <div className="text-gray-500 py-5">
              Connect and manage shipping integrations to add shipping cost directly from your
              providers.
            </div>

            {shippingWidget && (
              <div className="flex">
                <IntegrationWidget {...shippingWidget} showButtons={false} />
              </div>
            )}
            {activeShippingProvider !== msp && useShippingChargesAsCost === false && (
              <>
                <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
                <div className="flex" onClick={() => setShowEstimatedCosts(!showEstimatedCosts)}>
                  <Text weight={600}>
                    Add costs to calculate an estimated shipping cost for orders not yet processed
                    by your shipping provider
                  </Text>
                  <Button plain icon={showEstimatedCosts ? CaretUpMinor : CaretDownMinor} />
                </div>
                {showEstimatedCosts && <DefaultShippingSection />}
              </>
            )}
          </Card.Section>
        )}
        {activeShippingProvider === msp ? (
          <Card.Section>
            <RadioButton
              label="Default Shipping Costs"
              name="shipping"
              checked={useShippingChargesAsCost === false && activeShippingProvider === msp}
              onChange={() => {
                setNextButton("Let's go!");
                dispatch(setUseShippingCharges(false));
              }}
              disabled={activeShippingProvider !== msp}
            />
            {!useShippingChargesAsCost && <DefaultShippingSection />}
          </Card.Section>
        ) : (
          isWizard &&
          activeShippingProvider !== msp &&
          useShippingChargesAsCost === false && (
            <div className="p-5">
              <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
              <div className="flex" onClick={() => setShowEstimatedCosts(!showEstimatedCosts)}>
                <Text weight={600}>
                  Add costs to calculate an estimated shipping cost for orders not yet processed by
                  your shipping provider
                </Text>
                <Button plain icon={showEstimatedCosts ? CaretUpMinor : CaretDownMinor} />
              </div>
              {showEstimatedCosts && <DefaultShippingSection />}
            </div>
          )
        )}
      </Card>
    </div>
  );
};

export const Shipping = computeFeatureFlags(FeatureFlag.SHIPPING_PAGE_FF, ShippingComponent, () => (
  <UpgradePageFallBack
    featureFlags={[FeatureFlag.SHIPPING_PAGE_FF]}
    InAppContextBannerEnabled={false}
    title="Shipping"
  />
));
