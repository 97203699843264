import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Text, ActionIcon, Menu, Loader, confirm } from '@tw/ui-components';
import { WorkflowWithRun } from 'components/Willy/types/willyTypes';
import { sequencesActions, analyticsEvents, genericEventLogger } from 'utils/dataLayer';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { Link } from 'react-router-dom';
import { formatSequenceItemDate, markFeedItemAsRead } from './utils/sequences';
import { UserAndDate } from './UserAndDate';
import { UserBasicSafeInfo } from 'hooks/useUsersByIds';

type SequenceListCardProps = {
  item: WorkflowWithRun;
  user?: UserBasicSafeInfo;
  loadingUsers?: boolean;
  index: number;
  onItemClick: (item: any) => void;
  onSubscribeClicked: () => void;
  fromDashboard?: boolean;
  todaysDesign?: boolean;
  read: boolean;
  fromFeed?: boolean;
  onRunItem?: (sequenceId: string) => void;
  markAsRead?: (sequenceId: string) => void;
  dismissFromFeed: (sequenceId: string) => void;
};

export const SequenceListCard: React.FC<SequenceListCardProps> = ({
  item,
  user,
  loadingUsers,
  index,
  onItemClick,
  onSubscribeClicked,
  fromDashboard,
  todaysDesign,
  read,
  fromFeed,
  onRunItem,
  markAsRead,
  dismissFromFeed,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);

  const isSmall = useIsSmall();
  const showSmallVersion = isSmall || fromDashboard;

  const menuOptions = useMemo(() => {
    const actionSection = {
      items: [
        {
          content: 'View Details',
          onAction: async () => {
            navigate({
              pathname: `/workflows/${item.id}`,
              search: window.location.search,
            });
            setOpened(false);
          },
        },
        {
          content: 'Edit Agent',
          onAction: async () => {
            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.EDIT_AGENT,
              sequence_id: item.id,
              sequence_name: item.name,
              sequence_type: item.globalDashboardId ? 'global' : 'custom',
              source: fromFeed ? 'feed' : 'dashboard',
            });

            navigate({
              pathname: `/workflows/create/${item.id}`,
              search: window.location.search,
            });
            setOpened(false);
          },
        },
        {
          content: 'Run Agent',
          onAction: async () => {
            if (!!onRunItem) {
              onRunItem(item.id);
            } else {
              const params = new URLSearchParams(search);
              params.set('run', 'true');
              navigate({
                pathname: `/workflows/create/${item.id}`,
                search: params.toString(),
              });
            }
            setOpened(false);

            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.RUN_SEQUENCE,
              sequence_id: item.id,
              sequence_name: item.name,
              sequence_type: item.globalDashboardId ? 'global' : 'custom',
              source: fromFeed ? 'feed' : 'dashboard',
            });
          },
        },
        ...(item.read
          ? []
          : [
              {
                content: 'Mark as read',
                onAction: async () => {
                  markFeedItemAsRead(item.id);
                  if (!!markAsRead) {
                    markAsRead(item.id);
                  }
                  setOpened(false);
                },
              },
            ]),
        {
          content: 'Dismiss from Feed',
          onAction: async () => {
            const confirmed = await confirm({
              title: 'Dismiss from Feed',
              message: `Are you sure you want to remove the step "Send to Feed" from this agent?`,
              confirmText: 'Dismiss',
              cancelText: 'Cancel',
            });
            if (confirmed) {
              dismissFromFeed(item.id);
              setOpened(false);
            }
          },
        },
      ],
    };

    return [actionSection];
  }, [navigate, item, fromFeed, onRunItem, search, markAsRead, dismissFromFeed]);

  return (
    <>
      <SequenceListCardWrapper
        fromDashboard={fromDashboard}
        item={item}
        onItemClick={onItemClick}
        key={item.id + index}
        index={index}
        fromFeed={fromFeed}
      >
        <div
          className={`flex justify-between w-full border-b no-underline group relative ${todaysDesign ? 'items-center' : ''}`}
        >
          <div className={`${showSmallVersion ? 'p-4' : 'p-6.5'} flex flex-col gap-3 w-full`}>
            <div className="gap-2">
              <div className="flex justify-between gap-2">
                <div className="flex items-center gap-2">
                  {item.running && <Loader size="xs" />}
                  {!item.running && !read && (
                    <div className="w-3 h-3 bg-blue-500 rounded-full mr-2" />
                  )}
                  <p
                    className={`${showSmallVersion ? 'text-xl' : 'text-[18px]'} ${todaysDesign ? 'line-clamp-2' : ''} font-semibold text-black `}
                  >
                    {item.name}
                  </p>
                </div>
                <div className={`flex gap-2 items-center ${showSmallVersion ? 'hidden' : 'block'}`}>
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    <ActionIcon
                      icon="share-minor"
                      iconSize={16}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onSubscribeClicked();
                      }}
                    />
                  </div>
                  <Menu opened={opened} onClose={() => setOpened(false)} withinPortal={true}>
                    <Menu.Target>
                      <div
                        className={`${opened ? 'opacity-100' : 'sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-200'}`}
                      >
                        <ActionIcon
                          icon="three-dots"
                          iconSize={16}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpened(!opened);

                            if (!opened) {
                              genericEventLogger(analyticsEvents.SEQUENCES, {
                                action: sequencesActions.VIEW_FEED_ARTICLE_MENU,
                                sequence_id: item.id,
                                sequence_name: item.name,
                                sequence_type: item.globalDashboardId ? 'global' : 'custom',
                                source: fromFeed ? 'feed' : 'dashboard',
                              });
                            }
                          }}
                        />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {menuOptions.map((section, i) => (
                        <div key={i}>
                          {section.items.map((item, j) => {
                            const { content, onAction, ...rest } = item;

                            return (
                              <Menu.Item
                                key={j}
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  await onAction();
                                }}
                                {...rest}
                              >
                                <Text size="sm" weight={500}>
                                  {content}
                                </Text>
                              </Menu.Item>
                            );
                          })}
                        </div>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
            </div>
            <div>
              <Text size="md">
                <span className="line-clamp-2 text-[13px]">
                  {item?.run?.outputTitle ?? item?.run?.outputDescription ?? item.description}
                </span>
              </Text>
            </div>
            <div
              className={`flex justify-between w-full ${showSmallVersion ? '' : 'items-center'} gap-2 mt-auto`}
            >
              <div className="flex items-end gap-4 overflow-hidden">
                <div>
                  <UserAndDate
                    forceSmallVersion={showSmallVersion}
                    user={user?.displayName}
                    loadingUser={loadingUsers}
                    date={
                      todaysDesign
                        ? null
                        : formatSequenceItemDate(item?.lastRunAt || null, showSmallVersion)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SequenceListCardWrapper>
    </>
  );
};

type SequenceListCardWrapperProps = {
  children: React.ReactNode;
  item: WorkflowWithRun;
  onItemClick: (item: WorkflowWithRun) => void;
  index: number;
  fromDashboard?: boolean;
  fromFeed?: boolean;
};

const SequenceListCardWrapper: React.FC<SequenceListCardWrapperProps> = (props) => {
  const { children, fromDashboard, item, onItemClick, index, fromFeed } = props;

  if (!fromDashboard) {
    return (
      <Link
        className={`no-underline group rounded-lg overflow-hidden bg-white sm:hover:bg-gray-100 ${fromFeed ? 'shadow' : ''}`}
        to={{
          pathname: `/workflows/view/${item.id}/${item.run?.id}`,
          search: window.location.search,
        }}
        onClick={() => onItemClick(item)}
        key={item.id + index}
      >
        {children}
      </Link>
    );
  }
  return (
    <div
      className="no-underline group rounded-lg overflow-hidden cursor-pointer"
      onClick={() => onItemClick(item)}
    >
      {children}
    </div>
  );
};
