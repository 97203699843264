import { ActionIcon, Button, Modal, Select, Text, Textarea, TextInput } from '@tw/ui-components';
import {
  Column,
  ColumnName,
  AgentEntity,
  AgentEntityOptions,
  PossibleValue,
  RetentionPeriod,
  retentionPeriodItems,
} from './types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import {
  $defaultAiColumns,
  $mergedAiColumns,
  $selectedAiColumn,
  $shopAiColumns,
  $shopSequences,
  updateShopColumn,
} from '$stores/willy/$sequences';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { ColorPickerPopover } from './ColorPicker';
import { copyGlobalColumnToShop } from './utils/willyUtils';
import { ServicesIds } from '@tw/types/module/services';
import allServices from 'constants/services';
import { sequencesActions, genericEventLogger, analyticsEvents } from 'utils/dataLayer';
import { capitalize } from 'lodash';
import { IconPicker } from './IconPicker';
import { useAiColumn } from './AgentsLibrary/useAiColumn';

type WorkflowAiColumnsProps = {
  inModal?: boolean;
  specificColumn?: ColumnName;
};

export const WorkflowAiColumns: React.FC<WorkflowAiColumnsProps> = ({
  specificColumn,
  inModal,
}) => {
  const [searchParams, setSearchParams] = useSearchParams(
    specificColumn ? { column: specificColumn } : undefined,
  );

  const currentShopId = useStoreValue($currentShopId);
  const defaultAiColumns = useStoreValue($defaultAiColumns);
  const mergedColumns = useStoreValue($mergedAiColumns);
  const [_, setShopColumns] = useWritableStore($shopAiColumns);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const columnToEdit = specificColumn ?? (searchParams.get('column') as ColumnName | null);

  const column = useMemo(() => {
    return mergedColumns.columns.find((c) => c.key === columnToEdit) || null;
  }, [mergedColumns.columns, columnToEdit]);

  const { existInShop, onCopy, copyLoading, needsUpdate, onUpdate } = useAiColumn({
    column: column,
  });

  const saveColumn = useCallback(
    async (props: Partial<Column<ColumnName>>) => {
      if (!column || !currentShopId) {
        return;
      }

      genericEventLogger(analyticsEvents.SEQUENCES, {
        action: sequencesActions.SAVE_COLUMN_AGENT,
        column_id: column.key,
        column_name: column.label,
        column_category: column.category,
      });

      try {
        setLoading(true);
        setError(null);
        await updateShopColumn(currentShopId, column.key, {
          ...props,
        });
        toast.success('Saved', { autoClose: 1000 });
      } catch (error) {
        setError(error.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    },
    [column, currentShopId],
  );

  if (!column) {
    return (
      <div className="h-full flex flex-col gap-2 p-4">
        <Text>Column not found</Text>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col gap-2 p-4">
      <div className="flex flex-row items-center justify-between">
        {!inModal && <Text as="h1">AI Columns Configuration</Text>}
      </div>

      {!existInShop && (
        <div className="flex flex-col gap-2">
          <Text>
            This column is not configured for this shop. Please configure it first in order to edit
            it.
          </Text>
          <div>
            <Button
              loading={copyLoading}
              variant="secondary"
              onClick={async () => {
                await onCopy();
                setShopColumns((prev) => {
                  return {
                    ...prev,
                    data: [
                      ...(prev.data || []),
                      {
                        ...column,
                        sequences: [],
                      },
                    ],
                  };
                });
              }}
            >
              Configure Column
            </Button>
          </div>
        </div>
      )}
      {!!existInShop && (
        <div className="flex flex-col gap-2">
          {!!needsUpdate && (
            <div className="flex flex-row gap-2 items-center">
              <Text>This column is not up to date with the default column.</Text>
              <Button loading={copyLoading} variant="secondary" onClick={onUpdate}>
                Update Column
              </Button>
            </div>
          )}
          <Select
            disabled={!!specificColumn}
            searchable
            my="sm"
            label="Select A Column"
            data={[
              {
                label: 'None',
                value: '',
              },
            ].concat(
              mergedColumns.columns.map((column) => ({
                label: column.label,
                value: column.key,
              })),
            )}
            onChange={(id) => {
              if (id === null) {
                return;
              }

              setSearchParams((old) => {
                return {
                  ...old,
                  column: id,
                };
              });
            }}
            value={columnToEdit || ''}
          />

          {!!columnToEdit && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-6.5">
                <Textarea
                  label="Description"
                  value={column?.description}
                  onChange={(e) => {
                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          return { ...c, description: e.target.value };
                        }),
                      };
                    });
                  }}
                />
                {!!column && <AiColumnToSequence column={column} />}
              </div>
              <Select
                my="sm"
                label="Retention Period"
                data={Object.values(retentionPeriodItems)}
                onChange={(id) => {
                  setShopColumns((prev) => {
                    if (!column) {
                      return prev;
                    }

                    return {
                      ...prev,
                      data: prev.data?.map((c) => {
                        if (c.key === column.key) {
                          return { ...c, retentionPeriod: id as RetentionPeriod };
                        }
                        return c;
                      }),
                    };
                  });
                }}
                value={column?.retentionPeriod || 'forever'}
              />
              <Select
                my="sm"
                label="Possible Values"
                data={[
                  {
                    label: 'Any Value',
                    value: 'any',
                  },
                  {
                    label: 'Number',
                    value: 'number',
                  },
                  {
                    label: 'Predefined Values',
                    value: 'enum',
                  },
                ]}
                onChange={(id) => {
                  if (!id) {
                    return;
                  }

                  setShopColumns((prev) => {
                    if (!column) {
                      return prev;
                    }

                    return {
                      ...prev,
                      data: prev.data?.map((c) => {
                        if (c.key === column.key) {
                          return { ...c, valueType: id as 'number' | 'enum' | 'any' };
                        }
                        return c;
                      }),
                    };
                  });
                }}
                value={column?.valueType || 'number'}
              />

              {column?.valueType === 'enum' && (
                <EnumValues
                  values={column?.possibleValues || []}
                  disabled={false}
                  onChange={(values) => {
                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return { ...c, possibleValues: values };
                          }
                          return c;
                        }),
                      };
                    });
                  }}
                />
              )}

              <div>
                <Textarea
                  label="Prompt"
                  value={column?.defaultPrompt}
                  onChange={(e) =>
                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return { ...c, defaultPrompt: e.target.value };
                          }
                          return c;
                        }),
                      };
                    })
                  }
                  autosize
                />
              </div>
              {!!error && <Text color="red.4">{error}</Text>}
              <div
                className={`flex flex-row gap-2 items-center border-t border-solid border-t-gray-200 pt-4 pb-4 border-b-0 border-l-0 border-r-0 ${
                  inModal ? 'sticky bottom-0 bg-white z-10' : ''
                }`}
              >
                <Button
                  loading={loading}
                  disabled={
                    column?.sequences?.length === 0 &&
                    JSON.stringify(column) ===
                      JSON.stringify(defaultAiColumns.data?.find((c) => c.key === column.key))
                  }
                  onClick={async () => {
                    if (!column) {
                      return;
                    }

                    await saveColumn(column);
                  }}
                >
                  Save
                </Button>
                <Button
                  loading={loading}
                  variant="secondary"
                  onClick={async () => {
                    if (!currentShopId || !column) {
                      return;
                    }

                    const defaultColumn = defaultAiColumns.data?.find((c) => c.key === column.key);

                    if (!defaultColumn) {
                      return;
                    }

                    setShopColumns((prev) => {
                      if (!column) {
                        return prev;
                      }

                      return {
                        ...prev,
                        data: prev.data?.map((c) => {
                          if (c.key === column.key) {
                            return defaultColumn;
                          }
                          return c;
                        }),
                      };
                    });

                    await copyGlobalColumnToShop(column);
                  }}
                >
                  Reset to default
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type EnumValuesProps = {
  values: PossibleValue[];
  disabled: boolean;
  onChange: (values: PossibleValue[]) => void;
};

export const EnumValues: React.FC<EnumValuesProps> = ({ values, onChange, disabled }) => {
  return (
    <div className="flex flex-col gap-2">
      {values.map((value, index) => (
        <div key={index} className="flex flex-row gap-2 items-center">
          <ColorPickerPopover
            color={value.color}
            onChange={(color) => {
              const newValues = [...values];
              newValues[index] = { ...value, color };
              onChange(newValues);
            }}
            saveOnChange={false}
            onReset={() => {
              const newValues = [...values];
              newValues[index] = { ...value, color: '' };
              onChange(newValues);
            }}
          />
          <IconPicker
            selectedIcon={value.icon}
            onChange={(icon) => {
              const newValues = [...values];
              newValues[index] = { ...value, icon };
              onChange(newValues);
            }}
          />
          <TextInput
            value={value.value}
            onChange={(e) => {
              const newValues = [...values];
              newValues[index] = { ...value, value: e };
              onChange(newValues);
            }}
            disabled={disabled}
          />
          <ActionIcon
            icon="delete"
            onClick={() => {
              const newValues = [...values];
              newValues.splice(index, 1);
              onChange(newValues);
            }}
            disabled={disabled}
          />
        </div>
      ))}
      <div>
        <Button
          onClick={() => {
            onChange([...values, { value: '', color: '', icon: null }]);
          }}
          disabled={disabled}
        >
          Add Value
        </Button>
      </div>
    </div>
  );
};

type AiColumnModalProps = {};

export const AiColumnModal: React.FC<AiColumnModalProps> = () => {
  const [selectedColumn, setSelectedColumn] = useWritableStore($selectedAiColumn);

  const close = useCallback(() => {
    setSelectedColumn(null);
  }, [setSelectedColumn]);

  if (!selectedColumn) {
    return null;
  }

  return (
    <Modal.Root opened={!!selectedColumn} onClose={close} size="xl" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Column</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h="500px" p={0}>
          <WorkflowAiColumns specificColumn={selectedColumn} inModal />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

type AiColumnToSequenceProps = {
  column: Column<ColumnName>;
};

export const AiColumnToSequence: React.FC<AiColumnToSequenceProps> = ({ column }) => {
  const shopSequences = useStoreValue($shopSequences);
  const [shopColumns, setShopColumns] = useWritableStore($shopAiColumns);

  const agentRelations = column.sequences;

  return (
    <div className="flex flex-col gap-2">
      {agentRelations?.map(({ serviceId, sequenceId, entity }, index) => {
        return (
          <div className="flex items-center gap-2" key={index}>
            <Select
              searchable
              placeholder="Select Service"
              data={[
                {
                  label: 'All Services',
                  value: 'all',
                },
                ...Object.values(allServices).map((service) => ({
                  label: service.name,
                  value: service.id,
                })),
              ]}
              onChange={(id) => {
                if (id === null) {
                  return;
                }

                setShopColumns((prev) => {
                  return {
                    ...prev,
                    data: prev.data?.map((col) => {
                      if (col.key === column.key) {
                        return {
                          ...col,
                          sequences: col.sequences?.map((seq, i) => {
                            if (i === index) {
                              return {
                                serviceId: id as ServicesIds,
                                sequenceId: seq.sequenceId,
                                entity: seq.entity || null,
                              };
                            }
                            return seq;
                          }),
                        };
                      }
                      return col;
                    }),
                  };
                });
              }}
              value={serviceId}
            />
            <Select
              searchable
              disabled={!serviceId}
              placeholder="Select Entity"
              data={[
                {
                  label: 'All',
                  value: '',
                },
                ...AgentEntityOptions.map((entity) => ({
                  label: capitalize(entity),
                  value: entity,
                })),
              ]}
              value={entity}
              onChange={(id) => {
                if (id === null) {
                  return;
                }
                let newEntity: AgentEntity | null = id as AgentEntity;

                if (id === '') {
                  newEntity = null;
                }

                setShopColumns((prev) => {
                  return {
                    ...prev,
                    data: prev.data?.map((col) => {
                      if (col.key === column.key) {
                        return {
                          ...col,
                          sequences: col.sequences?.map((seq, i) => {
                            if (i === index) {
                              return { ...seq, entity: newEntity };
                            }
                            return seq;
                          }),
                        };
                      }
                      return col;
                    }),
                  };
                });
              }}
            />
            <Select
              searchable
              disabled={!serviceId}
              placeholder="Select Agent"
              data={[
                {
                  label: 'None',
                  value: '',
                },
              ].concat(
                shopSequences.map((seq) => ({
                  label: seq.name,
                  value: seq.id,
                })),
              )}
              onChange={(id) => {
                if (id === null) {
                  return;
                }

                setShopColumns((prev) => {
                  return {
                    ...prev,
                    data: prev.data?.map((col) => {
                      if (col.key === column.key) {
                        return {
                          ...col,
                          sequences: col.sequences?.map((seq, i) => {
                            if (i === index) {
                              return {
                                serviceId: seq.serviceId,
                                sequenceId: id,
                                entity: seq.entity || null,
                              };
                            }
                            return seq;
                          }),
                        };
                      }
                      return col;
                    }),
                  };
                });
              }}
              value={sequenceId}
            />
            <ActionIcon
              icon="delete"
              onClick={() => {
                setShopColumns((prev) => {
                  return {
                    ...prev,
                    data: prev.data?.map((col) => {
                      if (col.key === column.key) {
                        return {
                          ...col,
                          sequences: col.sequences?.filter((_, i) => i !== index),
                        };
                      }
                      return col;
                    }),
                  };
                });
              }}
            />
          </div>
        );
      })}
      <div>
        <Button
          variant="secondary"
          leftSection="plus"
          onClick={() => {
            setShopColumns((prev) => {
              const isColumnExists = prev.data?.some((col) => col.key === column.key);
              if (!isColumnExists) {
                return {
                  ...prev,
                  data: [
                    ...(prev.data || []),
                    {
                      ...column,
                      sequences: [
                        ...(column.sequences || []),
                        { serviceId: 'all', sequenceId: '', entity: 'all' },
                      ],
                    },
                  ],
                };
              }

              return {
                ...prev,
                data: prev.data?.map((col) => {
                  if (col.key === column.key) {
                    return {
                      ...col,
                      sequences: [
                        ...(col.sequences || []),
                        { serviceId: 'all', sequenceId: '', entity: 'all' },
                      ],
                    };
                  }
                  return col;
                }),
              };
            });
          }}
        >
          Add relation to Agent
        </Button>
      </div>
    </div>
  );
};
