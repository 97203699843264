import { Skeleton } from '@tw/ui-components';
import { WillyDataSequence } from '../types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { isGlobalDashboardExistsInShop } from '../dashContext';
import { copyGlobalSequenceToShop } from '../utils/willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TemplateLibraryCard } from './TemplateLibraryCard';

export const AgentLibraryCard: React.FC<{
  agent: WillyDataSequence;
  onAddToCart?: (id) => void;
  itemInCart?: boolean;
}> = ({ agent, onAddToCart, itemInCart }) => {
  const navigate = useNavigate();

  const existInShop = useMemo(() => {
    if (agent) {
      return isGlobalDashboardExistsInShop(agent);
    }
  }, [agent]);

  const agentColor = agent?.themeColor ?? 'blue';

  const [copyLoading, setCopyLoading] = useState(false);

  const copyToShop = useCallback(async () => {
    setCopyLoading(true);
    const newId = await copyGlobalSequenceToShop(agent.id);
    setCopyLoading(false);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
      sequence_id: agent?.id,
      sequence_name: agent?.name,
    });
    toast.success(`Agent added to your shop`);
    navigate({
      pathname: `/workflows/create/${newId}`,
      search: 'fromTemplateLibrary=true',
    });
  }, [agent, navigate]);

  return (
    <TemplateLibraryCard
      exists={!!existInShop}
      themeColor={agentColor}
      onCopy={copyToShop}
      cardKey={agent?.id}
      isLocked={false}
      onPreviewUrl={`/chat/agents/templates/${agent.id}`}
      onPreviewInBuilderUrl={`/workflows/create-preview/${agent.id}`}
      name={agent?.name ?? ''}
      description={agent?.description ?? ''}
      providers={agent?.providers ?? []}
      category={agent?.category ?? ''}
      copyLoading={copyLoading}
      needsUpdate={false}
      onUpdate={() => {}}
      iconSrc={
        !!agent?.iconSrc
          ? `https://storage.googleapis.com/file-hosting-bucket-shofifi/global-agent-image/${agent.id}.png`
          : undefined
      }
      onAddToCart={onAddToCart ? () => onAddToCart?.(agent.id) : undefined}
      itemInCart={itemInCart}
    />
  );
};

export const AgentLibraryCardSkeleton: React.FC = () => {
  return (
    <div
      className={`relative w-full h-full flex flex-col gap-2 border border-solid border-gray-200 rounded-lg shadow-sm bg-white overflow-hidden`}
    >
      <div className="flex flex-col gap-7 p-[20px]">
        {/* Title skeleton */}
        <Skeleton height={29} width="70%" radius="sm" />

        {/* Description skeleton - 3 lines */}
        <div className="flex flex-col gap-2">
          <Skeleton height={18} width="100%" radius="sm" />
          <Skeleton height={18} width="90%" radius="sm" />
          <Skeleton height={18} width="80%" radius="sm" />
        </div>

        {/* Provider icons skeleton */}
        <div className="flex gap-6">
          <Skeleton height={24} width={24} circle />
          <Skeleton height={24} width={24} circle />
          <Skeleton height={24} width={24} circle />
        </div>
      </div>
    </div>
  );
};
