import { $derived } from '@tw/snipestate';
import { $globalDashboards } from './$globalDashboards';
import { $shopDashboards } from './$shopDashboards';
import { $customViews } from './$customViews';
import { WillyDashboardElement } from 'components/Willy/types/willyTypes';

export const $combinedDashboard = $derived((get) => [
  ...get($globalDashboards),
  ...get($shopDashboards),
]);

export const $combinedDashboardsMap = $derived((get) =>
  get($combinedDashboard).reduce(
    (acc, x) => (acc.set(x.id, x), acc),
    new Map<string, WillyDashboardElement>(),
  ),
);
