import _db, { Timestamp, firestoreRef } from 'utils/DB';
import {
  WorkflowStep,
  WillyBaseMainElement,
  WillyDataSequence,
  DialectWithBoth,
  WorkflowResponse,
  Message,
  WorkflowStepSendToGoogleSheet,
  HistoryItem,
  WillyDataSequenceTaggedMetric,
  WillyDataSequencePixelSettings,
  WorkflowStepVariable,
  ScheduleItem,
  RunSequenceRequest,
  WillyElementType,
  AgentEntity,
  DataWithName,
  WorkflowStepTypes,
  WorkflowStepTypesDict,
} from '../types/willyTypes';
import axiosInstance from 'utils/axiosInstance';
import {
  detectDynamicParameters,
  extractVariablesFromWhereClause,
  takeSampleOfData,
  removeUndefinedFields,
  convertDataToJson,
  simplifyParsedData,
} from './willyUtils';
import { Dialect } from '@tw/types';
import { DEFAULT_DIALECT, DEFAULT_MODEL, MAX_ITEMS_PER_PAGE } from '../constants';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isValidUrl } from './isValidUrl';
import { v4 as uuidV4 } from 'uuid';
import { WorkflowStepBase } from '../types/willyTypes';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import moment from 'moment-timezone';
import firebase from 'firebase/compat/app';
import { emptyEmoji } from '../dashboardManagment/WillyDashDescription';
import { WillyEmoji } from '../types/emojiTypes';
import { User } from 'components/UserProfileManagment/User/constants';

export type EditNewSequenceProps = {
  baseMainElement: Partial<WillyBaseMainElement>;
  messages: WorkflowStep[];
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
  schedule: ScheduleItem | null;
  entity?: AgentEntity | null;
  sendEmailOnFailure?: boolean;
};

export type CreateNewSequenceProps = {
  baseMainElement: WillyBaseMainElement;
  shopId: string;
  userId: string;
  userName: string;
  messages: WorkflowStep[];
  sendEmailOnFailure?: boolean;
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
  schedule: ScheduleItem | null;
  entity?: AgentEntity | null;
};

export async function createNewSequence(props: CreateNewSequenceProps) {
  const {
    baseMainElement,
    shopId,
    userId,
    userName,
    dialect,
    messages,
    sendEmailOnFailure,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
    schedule,
    entity,
  } = props;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
    tier,
  } = baseMainElement;

  let steps: WorkflowStep[] | undefined = messages;

  let newSequence: WillyDataSequence = {
    id: id,
    name: name,
    description: description ?? '',
    updatedAt,
    isGlobal: isGlobal,
    type: 'sequence',
    canEdit: false,
    steps,
    createdAt,
    emoji: emoji,
    user: userId,
    userName: userName ?? '',
    image: image ?? '',
    v: 6,
    dialect: dialect ?? DEFAULT_DIALECT,
    additionalShopIds: additionalShopIds,
    providers,
    roles,
    schedule: schedule ?? null,
    sendEmailOnFailure: sendEmailOnFailure ?? false,
    tier: tier ?? 2,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      entity: entity ?? null,
      providersBlockingCombination,
      category,
      isBeta,
      isHide,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }

  try {
    const { data: res } = await axiosInstance.post<WillyDataSequence>(
      '/v2/sequences/create-workflow',
      {
        shopId,
        sequence: newSequence,
        isGlobal,
      },
    );

    return {
      success: true,
      message: 'Agent created successfully',
      sequence: newSequence,
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not save agent',
    };
  }
}

export const duplicateSequence = async (sequence: WillyDataSequence, user: string) => {
  const newSequence: WillyDataSequence = {
    ...sequence,
    id: uuidV4(),
    name: `(Copy) ${sequence.name}`,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    user: user,
    schedule: null,
  };

  let sanitizedSequence = removeUndefinedFields(newSequence);

  const stepsToUpdate: { oldParentRuleId: string; newParentRuleId: string }[] = [];

  let newSteps = newSequence.steps.map((step) => {
    const oldId = step.id;
    const newId = uuidV4();

    const regex = new RegExp(`\\b${oldId}_(passed|failed)\\b`);
    const stepsWithOldIdAsParent = sequence.steps.filter(
      (s) => s.parentRuleId && regex.test(s.parentRuleId),
    );

    stepsToUpdate.push(
      ...stepsWithOldIdAsParent.map((s) => ({
        oldParentRuleId: s.parentRuleId!,
        newParentRuleId: s.parentRuleId!.replace(regex, `${newId}_$1`),
      })),
    );

    return {
      ...step,
      id: newId,
    };
  });

  newSteps = newSteps.map((step) => {
    const stepToUpdate = stepsToUpdate.find((s) => s.oldParentRuleId === step.parentRuleId);
    if (stepToUpdate) {
      const newParentRuleId = step.parentRuleId?.replace(
        stepToUpdate.oldParentRuleId,
        stepToUpdate.newParentRuleId,
      );
      return { ...step, parentRuleId: newParentRuleId };
    }
    return step;
  });

  sanitizedSequence = {
    ...sanitizedSequence,
    steps: newSteps,
  };

  try {
    await _db()
      .collection('data_sequences')
      .doc(sanitizedSequence.id)
      .set(sanitizedSequence, { merge: true });

    return {
      id: sanitizedSequence.id,
      conversationData: sanitizedSequence,
      success: true,
      message: 'Agent duplicated successfully',
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not duplicate agent',
    };
  }
};

// You can't change this function's payload or logic before talking to me, the old one below became a true mess
export async function simpleEditSequence(seq: WillyDataSequence) {
  const { actionsMenuOpen, renameMode, ...rest } = seq;
  const isGlobal = seq.isGlobal;
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  await ref
    .doc(seq.id)
    .set(
      { ...rest, updatedAt: Timestamp.now(), actionsMenuOpen: false, renameMode: false },
      { merge: true },
    );
}

export const editSequence = async (seq: EditNewSequenceProps) => {
  const {
    baseMainElement,
    dialect,
    messages,
    featureFlagConfigs,
    ffConfigsToRemoveDashId,
    featureFlagDefaultCopyConfigs,
    ffConfigsDefaultToRemoveDashId,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
    schedule,
    entity,
    sendEmailOnFailure,
  } = seq;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    msps,
    tier,
  } = baseMainElement;
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  let newSequence: Partial<WillyDataSequence> = {
    description: description ? description : '',
    updatedAt: Timestamp.now(),
    name: name,
    emoji: emoji,
    image: image ?? '',
    steps: messages,
    dialect: dialect ?? 'clickhouse',
    reportPrompt: '',
    additionalShopIds,
    providers,
    roles,
    schedule: schedule ?? null,
    tier: tier ?? 2,
    sendEmailOnFailure: sendEmailOnFailure ?? false,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      entity: entity ?? null,
      providersBlockingCombination: providersBlockingCombination,
      category: category,
      isBeta: isBeta,
      isHide: isHide,
      dialect: dialect,
      msps: msps,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }
  try {
    await ref.doc(id).set(newSequence, { merge: true });
    if (isGlobal) {
      await Promise.all([
        updateDashboardForFFConfigs(
          {
            configs: featureFlagConfigs,
            mergeStrategy: 'merge',
            dashboardId: id!,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: id!,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: seq.featureFlagDefaultCopyConfigs,
            mergeStrategy: 'merge',
            dashboardId: id!,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsDefaultToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: id!,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
        generatePdpContent(id!, !newSequence.aboutPoints?.length),
      ]);
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const validateSequenceSteps = (sequenceSteps: WorkflowStep[]) => {
  const stepValid = !sequenceSteps.some((step, i) => {
    const olderSteps = sequenceSteps.slice(0, i);
    return isStepInvalid(step, olderSteps, false);
  });
  return stepValid;
};

export function getMessagesFromHistory(history: HistoryItem[]): Message[] {
  return history?.map((h) => {
    return {
      id: h.messageId,
      question: h.originalQuestion,
      ...h,
    };
  });
}

export function getMessagesFromWorkflowResponse(
  response: WorkflowResponse,
  dialect: Dialect,
): Message[] {
  if (response.stepType === 'runQuery') {
    return [
      {
        id: response.res?.queryId ?? uuidV4(),
        dialect: dialect,
        question: response.res?.prompt,
        role: 'tool',
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: {
            twTotalCount: response.res?.twTotalCount,
            visualizationType: response.res?.visualizationType,
            parameters: response.res?.parameters,
            question: response.res?.prompt,
            data: response.res?.data || [],
            dataColumns: response.res?.dataColumns || { x: [], y: [] },
            error: response.res?.error || undefined,
            generatedQuery: response.res?.generatedQuery,
            queryId: response.res?.queryId,
            queries: [
              {
                id: response.res?.queryId ?? '',
                question: response.res?.prompt ?? '',
                query: response.res?.generatedQuery ?? '',
              },
            ],
          },
        },
      },
    ];
  } else if (response.stepType === 'runPython') {
    return [
      {
        id: uuidV4(),
        role: 'tool',
        error: response.res?.error ?? undefined,
        toolResults: {
          name: 'TextToPython',
          pythonCode: response.res?.code ?? '',
          error: response.res?.error ?? undefined,
          data: {
            data: response.res?.data ?? [],
            dataColumns: response.res?.dataColumns ?? { x: [], y: [] },
          },
          files: response.res?.files ?? [],
          queries: response.res?.queries ?? [],
        },
      },
    ];
  } else if (
    response.stepType === 'runForecasting' ||
    response.stepType === 'runMarketingMixModel'
  ) {
    return [
      {
        id: uuidV4(),
        role: 'tool',
        error: response.res?.error ?? undefined,
        toolResults: {
          name:
            response.stepType === 'runForecasting' ? 'Forecasting' : ('MarketingMixModel' as any),
          message: {
            question: response.res?.prompt ?? '',
            data: response.res?.data ?? [],
            dataColumns: response.res?.dataColumns ?? { x: [], y: [] },
            error: response.res?.error ?? undefined,
            generatedQuery: response.res?.generatedQuery,
            queryId: response.res?.queryId,
            queries: [
              {
                id: response.res?.queryId ?? '',
                question: response.res?.prompt ?? '',
                query: response.res?.generatedQuery ?? '',
              },
            ],
          },
        },
      },
    ];
  } else if (
    response.stepType === 'insights' ||
    response.stepType === 'genUiReport' ||
    response.stepType === 'genUiPresentation'
  ) {
    const modelUsed = response.modelUsed;
    const modelRequested = response.modelRequested;
    if (modelUsed && modelRequested && modelUsed !== modelRequested) {
      response.text = response.text ? `(Model used: ${modelUsed})\n\n${response.text}` : '';
    }

    const res: Message[] = [];
    res.push({
      id: uuidV4(),
      role: 'assistant',
      text: response.text,
      error: response.error ?? undefined,
    });
    return res;
  } else if (response.stepType === 'preloadData') {
    const all = response.data?.flatMap((c) => c) ?? [];

    return all.map<Message>((m) => {
      if (m.dataType === 'forecast' || m.dataType === 'mmm') {
        return {
          id: uuidV4(),
          role: 'assistant',
          toolResults: {
            name: 'Forecasting',
            message: m,
          },
        };
      }

      return {
        id: uuidV4(),
        role: 'assistant',
        question: m.question,
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: m,
          isSqlOnly: false,
          error: !!m.error,
          errorForInterface: m.error,
          sqlGenerated: m.generatedQuery,
        },
      };
    });
  } else if (response.stepType === 'structuredInsights') {
    return [
      {
        id: uuidV4(),
        role: 'tool',
        text: response.status === 'error' ? response.error ?? '' : response.text ?? '',
        error: response.error ?? undefined,
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: response.res,
          errorMessages: response.error ? [response.error] : undefined,
        },
      },
    ];
  } else if (response.stepType === 'preloadCSV' || response.stepType === 'preloadGoogleSheet') {
    return [
      {
        id: uuidV4(),
        role: 'tool',
        text: response.status === 'error' ? response.error ?? '' : response.text ?? '',
        error: response.error ?? undefined,
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: response.res,
        },
      },
    ];
  } else if (response.stepType === 'search') {
    const res = response.res;
    return [
      {
        id: response.stepId,
        role: 'assistant',
        text: response.status === 'error' ? res?.error ?? '' : res?.text ?? '',
        error: response.error ?? undefined,
        toolResults: {
          name: 'Searching',
          text: res?.text ?? '',
          sources: res?.sources ?? [],
          imgs: res?.imgs ?? [],
          videos: res?.videos ?? [],
        },
      },
    ];
  } else {
    return [
      {
        id: response.stepId,
        role: 'assistant',
        text: response.status === 'error' ? response.error ?? '' : response.text ?? '',
        error: response.error ?? undefined,
      },
    ];
  }
}

export function isStepInvalid(
  step: WorkflowStep,
  olderSteps: WorkflowStep[],
  validateDynamicParams = true,
): string | null {
  const insideLoop = olderSteps.some((s) => s.stepType === 'loop');
  if (step.stepType === 'subSequence') {
    if (!step.sequenceIds || step.sequenceIds.length === 0) {
      return 'Sub-agent must have at least one parent agent';
    }
    return null;
  } else if (step.stepType === 'preloadData') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Preload data step must have a report id';
    }
  } else if (
    step.stepType === 'runQuery' ||
    step.stepType === 'runPython' ||
    step.stepType === 'runForecasting' ||
    step.stepType === 'runMarketingMixModel'
  ) {
    const dynamicParams = detectDynamicParametersInStep(step, insideLoop);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (step.stepType === 'runQuery') {
      const params = extractVariablesFromWhereClause(step.query);
      if (params?.length && !Object.keys(step.queryParams || {}).length) {
        return `${params.join(', ')} declared in query but not provided in query params`;
      }
      if (!step.prompt && !step.query && !step.builder) {
        return 'Prompt or query or bulilder is required in query step';
      }
    } else if (step.stepType === 'runPython') {
      if (!step.code && !step.prompt) {
        return 'Code or prompt is required in python step';
      }
    } else if (step.stepType === 'runForecasting' || step.stepType === 'runMarketingMixModel') {
      if (!step.query && !step.prompt) {
        return 'Query or prompt is required in forecasting or marketing mix model step';
      }
    }
  } else if (step.stepType === 'search') {
    if (!step.prompt || step.prompt.length === 0) {
      return `Prompt is required in step "${step.title || step.stepType}"`;
    }
  } else if (
    step.stepType === 'insights' ||
    step.stepType === 'rule' ||
    step.stepType === 'genUiReport' ||
    step.stepType === 'genUiPresentation'
  ) {
    const dynamicParams = detectDynamicParametersInStep(step, insideLoop);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.text || step.text.length === 0) {
      return `Step text is required in step "${step.title || step.stepType}"`;
    }
  } else if (step.stepType === 'sendToDashboard') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Send to report step must have a report id';
    }
  } else if (step.stepType === 'sendToEmail') {
    const dynamicParams = detectDynamicParametersInStep(step, insideLoop);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.email || step.email.length === 0) {
      return 'Email is required';
    }
  } else if (step.stepType === 'sendToSlack') {
    // no validation needed yet?
  } else if (step.stepType === 'condition') {
    if (!step.rules || step.rules.length === 0) {
      return 'Condition must have at least one rule';
    }
    if (!step.rules.every((rule) => rule.ruleIds?.length > 0)) {
      return 'Each rule must have at least one rule id';
    }
  } else if (step.stepType === 'sendToGoogleSheet') {
    return validateSendToGoogleSheetStep(step);
  } else if (step.stepType === 'sendToWarehouse') {
    if (!step.providerId) {
      return 'Provider id is required in warehouse step';
    }
    if (!step.integrationId) {
      return 'Integration id is required in warehouse step';
    }
    if (!step.tableId) {
      return 'Table id is required in warehouse step';
    }
    if (step.useDedicatedQuery && !step.query?.length) {
      return 'Query is required if you check "Use dedicated query"';
    }
    if (
      !step.useDedicatedQuery &&
      !olderSteps.some((s) => s.stepType === 'runQuery' || s.stepType === 'preloadData')
    ) {
      return 'You must have at least one query before a warehouse step';
    }
  } else if (step.stepType === 'sendToWebhook') {
    if (!step.url || step.url.length === 0) {
      return 'Url is required in send to webhook step';
    }
    if (!isValidUrl(step.url)) {
      return 'Url is invalid in send to webhook step';
    }
  } else if (step.stepType === 'emptyStep') {
    return 'Empty step is not allowed';
  }

  return null;
}

export function validateSendToGoogleSheetStep(step: WorkflowStepSendToGoogleSheet): string | null {
  if (!step.sheetsAccount) {
    return 'Google Sheets account is required';
  }
  if (!step.spreadsheetId) {
    return 'Spreadsheet is required';
  }
  if (!step.worksheetId) {
    return 'Worksheet is required';
  }
  if (!step.spreadsheetName) {
    return 'Spreadsheet name is required';
  }
  if (!step.worksheetName) {
    return 'Worksheet name is required';
  }

  if (step.ssNameError) {
    return 'Spreadsheet name is invalid';
  }

  if (step.wsNameError) {
    return 'Worksheet name is invalid';
  }

  return null;
}

export function detectDynamicParametersInStep(
  step: WorkflowStep,
  insideLoop?: boolean,
): WorkflowStepVariable[] | null {
  if (insideLoop) {
    return null;
  }

  const { variables = [] } = step;

  const removeBrackets = (text: string) =>
    text.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) => p1.replace(/\s+/g, ''));

  if (
    step.stepType === 'runQuery' ||
    step.stepType === 'runPython' ||
    step.stepType === 'runForecasting' ||
    step.stepType === 'runMarketingMixModel'
  ) {
    let all = detectDynamicParameters(step.prompt);

    let code: string | undefined = undefined;
    if (
      step.stepType === 'runQuery' ||
      step.stepType === 'runMarketingMixModel' ||
      step.stepType === 'runForecasting'
    ) {
      code = step.query;
    } else if (step.stepType === 'runPython') {
      code = step.code;
    }

    const fromCode = detectDynamicParameters(code ?? '');
    if (fromCode) {
      if (all) {
        all.push(...fromCode);
      } else {
        all = fromCode;
      }
    }

    return (
      all?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (
    step.stepType === 'insights' ||
    step.stepType === 'genUiReport' ||
    step.stepType === 'genUiPresentation'
  ) {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'rule') {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'sendToEmail') {
    const res = detectDynamicParameters(step.email);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  }
  return null;
}

export const getDisabledSteps = ({
  stepsUntilNow,
  isFirstStep,
  sequenceId,
  currentSlackChannel,
  isGoogleSheetsConnected,
  user,
}: {
  stepsUntilNow?: WorkflowStep[];
  isFirstStep?: boolean;
  sequenceId?: string;
  currentSlackChannel?: string;
  isGoogleSheetsConnected?: boolean;
  user: Partial<User>;
}): Partial<Record<WorkflowStepTypes, string>> => {
  const disabledSteps: Partial<Record<WorkflowStepTypes, string>> = {};

  const previousStep = stepsUntilNow?.at(-1);

  if (stepsUntilNow?.some((s) => s.stepType === 'emptyStep')) {
    // a text to explain the user to first defined the empty step above to allow add new steps
    const text = 'You need to define the empty step above to add new steps';
    //set all the stepTypes to disabled with this text in a loop
    Object.keys(WorkflowStepTypesDict).forEach((stepType) => {
      disabledSteps[stepType as WorkflowStepTypes] = text;
    });
    return disabledSteps;
  }

  if (isFirstStep) {
    const text = "This step can't be added as first step";
    disabledSteps.sendToDashboard = text;
    disabledSteps.sendToEmail = text;
    disabledSteps.sendToSlack = text;
    disabledSteps.sendToGoogleSheet = text;
    disabledSteps.sendToWebhook = text;
    disabledSteps.rule = text;
    disabledSteps.sendToPushNotification = text;
    disabledSteps.sendToFeed = text;
  }

  if (!sequenceId) {
    disabledSteps.preloadRuns = 'You need to save the agent first';
  }

  if (!currentSlackChannel) {
    disabledSteps.sendToSlack = 'You need to connect your Slack channel first';
  }

  if (!isGoogleSheetsConnected) {
    disabledSteps.sendToGoogleSheet = 'You need to connect your Google Sheets first';
    disabledSteps.preloadGoogleSheet = 'You need to connect your Google Sheets first';
  }

  if (!user.expoPushToken) {
    disabledSteps.sendToPushNotification = 'You need to connect to Push Notification first';
  }

  // return true if previousStep is not runQuery and not tool with toolToUse TextToSQL
  if (previousStep?.stepType !== 'runQuery' && previousStep?.stepType !== 'runPython') {
    disabledSteps.loop =
      'You need to add a "Run Query" or "Run Python" step before you can add a loop';
  }

  if (previousStep?.stepType === 'rule') {
    disabledSteps.rule = 'You can\'t add more than one "Rule" or "Condition" in a row';
    disabledSteps.condition = 'You can\'t add more than one "Rule" or "Condition" in a row';
  }

  if (previousStep?.stepType === 'condition') {
    disabledSteps.condition = 'You can\'t add more than one "Condition" or "Rule" in a row';
    disabledSteps.rule = 'You can\'t add more than one "Condition" or "Rule" in a row';
  }

  if (previousStep?.stepType === 'sendToDashboard') {
    disabledSteps.sendToDashboard = 'You can\'t add more than one "Send to Dashboard" in a row';
  }

  if (previousStep?.stepType === 'sendToEmail') {
    disabledSteps.sendToEmail = 'You can\'t add more than one "Send to Email" in a row';
  }

  if (previousStep?.stepType === 'sendToSlack') {
    disabledSteps.sendToSlack = 'You can\'t add more than one "Send to Slack" in a row';
  }

  if (previousStep?.stepType === 'sendToPushNotification') {
    disabledSteps.sendToPushNotification =
      'You can\'t add more than one "Send to Push Notification" in a row';
  }

  if (previousStep?.stepType === 'sendToGoogleSheet') {
    disabledSteps.sendToGoogleSheet =
      'You can\'t add more than one "Send to Google Sheet" in a row';
  }

  if (previousStep?.stepType === 'sendToWebhook') {
    disabledSteps.sendToWebhook = 'You can\'t add more than one "Send to Webhook" in a row';
  }

  if (previousStep?.stepType === 'sendToFeed') {
    disabledSteps.sendToFeed = 'You can\'t add more than one "Send to Feed" in a row';
  }

  if (
    previousStep?.stepType !== 'insights' &&
    previousStep?.stepType !== 'genUiReport' &&
    previousStep?.stepType !== 'genUiPresentation'
  ) {
    disabledSteps.structuredInsights =
      'You can add structured insights step only after an "Insights" step';
  }

  return disabledSteps;
};

export function createNewStep(step: Partial<WorkflowStep>, userEmail?: string, userId?: string) {
  let newStep: WorkflowStep;
  const id = uuidV4();

  const { stepType } = step;

  const baseStep: WorkflowStepBase = {
    createdAt: step.createdAt ?? new Date().toISOString(),
    id: step.id ?? id,
    title: step.title ?? '',
    variables: [],
    runInParallel: step.runInParallel ?? false,
  };

  if (!stepType) {
    throw new Error('Step type is required');
  }

  if (
    stepType === 'runQuery' ||
    stepType === 'runForecasting' ||
    stepType === 'runMarketingMixModel'
  ) {
    newStep = {
      ...baseStep,
      prompt: '',
      query: '',
      builder: null,
      stepType,
    };
  } else if (stepType === 'runPython') {
    newStep = {
      ...baseStep,
      stepType,
      code: '',
      prompt: '',
    };
  } else if (stepType === 'search') {
    newStep = {
      ...baseStep,
      stepType,
      prompt: '',
      searchSource: ['webSearch'],
    };
  } else if (stepType === 'preloadData') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
      isStandardDashboard: false,
      date: DatePickerTimePeriods.TODAY,
      previousDate: 'none',
      filters: null,
      attributionOptions: null,
    };
  } else if (stepType === 'preloadRuns') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      runIds: [],
    };
  } else if (stepType === 'subSequence') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      sequenceIds: [],
    };
  } else if (stepType === 'loop') {
    newStep = {
      ...baseStep,
      stepType: stepType,
    };
  } else if (stepType === 'rule') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
    };
  } else if (stepType === 'condition') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      rules: [],
    };
  } else if (stepType === 'insights') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      model: DEFAULT_MODEL,
    };
  } else if (stepType === 'genUiReport') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      runIdWithGenUi: null,
      customComponents: null,
      model: DEFAULT_MODEL,
    };
  } else if (stepType === 'genUiPresentation') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      model: DEFAULT_MODEL,
      runIdWithGenUi: null,
    };
  } else if (stepType === 'sendToEmail') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      email: userEmail ?? '',
      formats: [],
    };
  } else if (stepType === 'sendToSlack') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      formats: [],
    };
  } else if (stepType === 'sendToDashboard') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
    };
  } else if (stepType === 'sendToGoogleSheet') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      spreadsheetId: '',
      spreadsheetName: '',
      ssNameError: false,
      worksheetId: '',
      worksheetName: '',
      wsNameError: false,
      sheetsAccount: '',
    };
  } else if (stepType === 'sendToWarehouse') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      providerId: '',
      integrationId: '',
      tableId: '',
      useDedicatedQuery: false,
      query: '',
      queryParams: {},
    };
  } else if (stepType === 'sendToWebhook') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      url: '',
      headers: [],
    };
  } else if (stepType === 'preloadGoogleSheet') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      spreadsheetId: '',
      spreadsheetName: '',
      worksheetId: '',
      worksheetName: '',
      sheetsAccount: '',
    };
  } else if (stepType === 'preloadCSV') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      url: '',
      fileName: '',
      destFileName: '',
    };
  } else if (stepType === 'vision') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      uploadedFiles: [],
    };
  } else if (stepType === 'structuredInsights') {
    newStep = {
      ...baseStep,
      stepType: stepType,
    };
  } else if (stepType === 'sendToPushNotification') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      userId: userId ?? '',
      text: '',
    };
  } else if (stepType === 'sendToFeed') {
    newStep = {
      ...baseStep,
      stepType: stepType,
    };
  } else if (stepType === 'emptyStep') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      category: step.category ?? 'get-data',
    };
  } else {
    // This will cause TypeScript to error if any stepType is not handled
    const _exhaustiveCheck: never = stepType;
    return null;
  }
  return newStep;
}

export function editStep(stepToUpdate: WorkflowStep, newStep: WorkflowStep) {
  const varsInStep = detectDynamicParametersInStep(newStep, false);
  const oldVariables = (stepToUpdate.variables || []).filter((v) =>
    varsInStep?.some((v2) => v2.key === v.key),
  );
  const newVariables = newStep.variables || [];
  const variables = [
    ...oldVariables.map((v) => ({
      ...v,
      value: newVariables.find((v2) => v2.key === v.key)?.value ?? v.value,
    })),
    ...newVariables.filter((v) => !oldVariables.some((v2) => v2.key === v.key)),
  ];

  let uniqueVariables = variables.filter(
    (v, i, self) => i === self.findIndex((v2) => v2.key === v.key),
  );

  if (!varsInStep) {
    uniqueVariables = [];
  }

  stepToUpdate = {
    ...stepToUpdate,
    title: newStep.title ?? stepToUpdate.title ?? '',
    variables: uniqueVariables || [],
    runInParallel: newStep.runInParallel ?? stepToUpdate.runInParallel ?? false,
  };
  if (
    newStep.stepType === 'runQuery' ||
    newStep.stepType === 'runForecasting' ||
    newStep.stepType === 'runMarketingMixModel'
  ) {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      prompt: newStep.prompt ?? '',
      query: newStep.query ?? '',
      builder: newStep.builder ?? null,
    };
  } else if (newStep.stepType === 'runPython') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      code: newStep.code ?? '',
      prompt: newStep.prompt ?? '',
    };
  } else if (newStep.stepType === 'search') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      prompt: newStep.prompt ?? '',
      searchSource: newStep.searchSource ?? [],
    };
  } else if (newStep.stepType === 'insights') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      text: newStep.text ?? '',
      model: newStep.model ?? DEFAULT_MODEL,
    };
  } else if (newStep.stepType === 'genUiReport') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      text: newStep.text ?? '',
      runIdWithGenUi: newStep.runIdWithGenUi ?? null,
      customComponents: newStep.customComponents ?? null,
      model: newStep.model ?? DEFAULT_MODEL,
    };
  } else if (newStep.stepType === 'genUiPresentation') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      text: newStep.text ?? '',
      model: newStep.model ?? DEFAULT_MODEL,
      runIdWithGenUi: newStep.runIdWithGenUi ?? null,
    };
  } else if (newStep.stepType === 'vision') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      text: newStep.text ?? '',
      uploadedFiles: newStep.uploadedFiles ?? [],
    };
  } else if (newStep.stepType === 'rule') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      text: newStep.text ?? '',
    };
  } else if (newStep.stepType === 'preloadData') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      dashboardId: newStep.dashboardId ?? '',
      isStandardDashboard: newStep.isStandardDashboard,
      date: newStep.date ?? DatePickerTimePeriods.TODAY,
      previousDate: newStep.previousDate ?? 'none',
      filters: newStep.filters || null,
      attributionOptions: newStep.attributionOptions || null,
    };
  } else if (newStep.stepType === 'condition') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      rules: newStep.rules ?? [],
    };
  } else if (newStep.stepType === 'subSequence') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      sequenceIds: newStep.sequenceIds ?? [],
    };
  } else if (newStep.stepType === 'loop') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
    };
  } else if (newStep.stepType === 'sendToDashboard') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      dashboardId: newStep.dashboardId ?? '',
    };
  } else if (newStep.stepType === 'sendToEmail') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      email: newStep.email ?? '',
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToSlack') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToGoogleSheet') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      spreadsheetId: newStep.spreadsheetId ?? '',
      spreadsheetName: newStep.spreadsheetName ?? '',
      ssNameError: newStep.ssNameError,
      worksheetId: newStep.worksheetId ?? '',
      worksheetName: newStep.worksheetName ?? '',
      wsNameError: newStep.wsNameError,
      sheetsAccount: newStep.sheetsAccount ?? '',
    };
  } else if (newStep.stepType === 'sendToWarehouse') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      providerId: newStep.providerId ?? '',
      integrationId: newStep.integrationId ?? '',
      tableId: newStep.tableId ?? '',
      useDedicatedQuery: !!newStep.useDedicatedQuery,
      query: newStep.query ?? '',
      queryParams: newStep.queryParams || {},
    };
  } else if (newStep.stepType === 'sendToWebhook') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      url: newStep.url || '',
      headers: newStep.headers || [],
    };
  } else if (newStep.stepType === 'preloadRuns') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      runIds: newStep.runIds ?? [],
    };
  } else if (newStep.stepType === 'preloadGoogleSheet') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      spreadsheetId: newStep.spreadsheetId ?? '',
      spreadsheetName: newStep.spreadsheetName ?? '',
      worksheetId: newStep.worksheetId ?? '',
      worksheetName: newStep.worksheetName ?? '',
      sheetsAccount: newStep.sheetsAccount ?? '',
    };
  } else if (newStep.stepType === 'preloadCSV') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      url: newStep.url ?? '',
      fileName: newStep.fileName ?? '',
      destFileName: newStep.destFileName ?? '',
    };
  } else if (newStep.stepType === 'structuredInsights') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
    };
  } else if (newStep.stepType === 'sendToPushNotification') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      userId: newStep.userId ?? '',
      text: newStep.text ?? '',
    };
  } else if (newStep.stepType === 'sendToFeed') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
    };
  } else if (newStep.stepType === 'emptyStep') {
    return {
      ...stepToUpdate,
      stepType: newStep.stepType,
      category: newStep.category ?? 'get-data',
    };
  }

  // This will cause TypeScript to error if any stepType is not handled
  const _exhaustiveCheck: never = newStep;
  return stepToUpdate;
}

export function getParentRuleId(stepId: string, type: 'passed' | 'failed') {
  return `${stepId}_${type}`;
}

export function cutWorkflowResponse(result: WorkflowResponse | null): WorkflowResponse | null {
  if (!result) {
    return null;
  }
  if (
    result.stepType !== 'runQuery' &&
    result.stepType !== 'runForecasting' &&
    result.stepType !== 'runMarketingMixModel'
  ) {
    return result;
  }
  if (!result.res) {
    return result;
  }

  return {
    ...result,
    res: {
      ...result.res,
      data: takeSampleOfData(result.res.data, MAX_ITEMS_PER_PAGE),
      dataType: result.res.dataType,
    } as any,
  };
}

export async function resumeSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/resume`, {
    shopId,
    userId,
  });
}

export async function triggerSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/execute`, {
    shopId,
    userId,
  });
}

const formatLong = 'MMM D, YYYY h:mm A';
const formatShort = 'MMM D, h:mm A';
const milliSecondsInDay = 86400000;

export const formatSequenceItemDate = (
  date: firebase.firestore.Timestamp | null,
  isSmall?: boolean,
) => {
  if (date instanceof Timestamp) {
    const dateAsMoment = moment(date.toDate());
    const diff = moment().diff(dateAsMoment);
    if (diff < milliSecondsInDay) {
      return moment.duration(moment().diff(dateAsMoment)).humanize() + ' ago';
    }
    return dateAsMoment.format(isSmall ? formatShort : formatLong);
  }

  return '';
};

export async function runWorkflow(params: RunSequenceRequest) {
  try {
    const response = await axiosInstance.post<{ workflowId: string }>(
      '/v2/sequences/workflows/run',
      params,
    );
    return response.data;
  } catch (error: any) {
    const errorMessage = error.message || 'Error running sequence';
    console.error(errorMessage);
    throw errorMessage;
  }
}

export async function convertSequenceToGlobal(
  c: WillyDataSequence,
  currentShopId: string,
  dialect: Dialect,
) {
  const newSequenceTemplate: CreateNewSequenceProps = {
    shopId: currentShopId!,
    userId: c.user,
    userName: c.userName ?? '',
    dialect: c.dialect ?? dialect,
    messages: c.steps || [],
    schedule: c.schedule || null,
    baseMainElement: {
      id: uuidV4(),
      type: 'sequence' as WillyElementType,
      canEdit: false,
      isGlobal: true,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      name: c.name ?? '',
      description: c.description ?? '',
      emoji: c.emoji ?? (emptyEmoji as WillyEmoji),
      roles: c.roles ?? [],
      isBeta: c.isBeta ?? false,
      isHide: c.isHide ?? false,
      image: c.image,
      category: c.category ?? '',
      dialect: c.dialect ?? dialect ?? 'clickhouse',
      providers: c.providers ?? [],
      providersBlockingCombination: c.providersBlockingCombination ?? 'NONE',
      packages: c.packages ?? [],
      defaultPackages: c.defaultPackages ?? [],
      msps: c.msps ?? [],
    },
  };

  const { error, success, message } = await createNewSequence(newSequenceTemplate);

  return {
    error,
    success,
    message,
  };
}

export async function generatePdpContent(agentId, noPdp) {
  if (noPdp) {
    const { data } = await axiosInstance.post('/v2/willy/generate-global-agent-pdp', {
      agentId,
    });
  }
  return;
}

export function filterStepsWithDataOutput(steps: WorkflowStep[]) {
  return steps.filter(
    (s) =>
      s.stepType === 'runQuery' ||
      s.stepType === 'runPython' ||
      s.stepType === 'runForecasting' ||
      s.stepType === 'runMarketingMixModel' ||
      s.stepType === 'preloadCSV' ||
      s.stepType === 'preloadGoogleSheet',
  );
}

export const markFeedItemAsRead = async (sequenceId: string) => {
  const ref = _db().collection('moby_feed');
  await ref.doc(sequenceId).set({ read: true }, { merge: true });
};

export function extractDataFromResponse(
  res: WorkflowResponse | null,
  index?: number,
): DataWithName[] {
  if (!res) {
    return [];
  }
  let suffix = index ? `_${index}` : '';
  if (
    res.stepType !== 'runQuery' &&
    res.stepType !== 'runForecasting' &&
    res.stepType !== 'runMarketingMixModel' &&
    res.stepType !== 'preloadData' &&
    res.stepType !== 'runPython' &&
    res.stepType !== 'preloadCSV' &&
    res.stepType !== 'preloadGoogleSheet'
  ) {
    return [];
  }

  if (res.stepType === 'preloadData') {
    const currentData = res.data?.map((x, i) => {
      return {
        data: simplifyParsedData(convertDataToJson(x.data || [])),
        name: `step_${index}_current_${i}`,
        error: x.error || null,
        queryId: x.queryId || '',
        isPrevious: false,
      };
    });

    const prevData = res.prevData?.map<DataWithName>((x, i) => {
      return {
        data: simplifyParsedData(convertDataToJson(x.data || [])),
        name: `step_${index}_prev_${i}`,
        error: x.error || null,
        queryId: x.queryId || '',
        isPrevious: true,
      };
    });

    return [...(currentData || []), ...(prevData || [])];
  } else if (res.stepType === 'preloadCSV' || res.stepType === 'preloadGoogleSheet') {
    return [
      {
        data: simplifyParsedData(convertDataToJson(res.res?.data || [])),
        name: `step_${index}`,
        error: res.res?.error || null,
        queryId: res.res?.queryId || '',
        isPrevious: false,
      },
    ];
  } else if (
    res.stepType === 'runQuery' ||
    res.stepType === 'runForecasting' ||
    res.stepType === 'runMarketingMixModel'
  ) {
    return [
      {
        data: simplifyParsedData(convertDataToJson(res.res?.data || [])),
        name: `step_${index}`,
        error: res.res?.error || null,
        queryId: res.res?.queryId || '',
        isPrevious: false,
      },
    ];
  } else if (res.stepType === 'runPython') {
    return [
      {
        data: simplifyParsedData(convertDataToJson(res.res?.data || [])),
        name: `step_${index}`,
        error: res.res?.error || null,
        queryId: '',
        isPrevious: false,
      },
    ];
  }

  return [];
}
