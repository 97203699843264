import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Icon } from '@tw/ui-components';
import { NavSection } from '../types';
import { IconComponent } from '../components';
import { META_ENRICHMENT_ROUTES } from '@tw/shared-types/module/frontend-config';

export const ENRICHMENT_SECTION: NavSection = {
  title: 'Data Enrichment',
  icon: ({ selected }) => (
    //TODO: Add the correct icon
    <IconComponent
      selected={selected}
      icon={<Icon name="apps" color={selected ? 'one.5' : 'gray.5'} size={24} />}
    />
  ),
  routes: [
    {
      url: '/sonar-send',
      ...META_ENRICHMENT_ROUTES['/sonar-send'],
      label: 'Sonar Send',
      shortLabel: 'Sonar Send',
      header: () => <></>,
      rawIconName: 'sonar-send-2',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="sonar-send-2"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 18 : 22}
            />
          }
        />
      ),
    },
    {
      url: '/sonar-optimize',
      ...META_ENRICHMENT_ROUTES['/sonar-optimize'],
      label: 'Sonar Optimize',
      shortLabel: 'Sonar Optimize',
      dependsOnFFSystem: FeatureFlag.PIXEL_CAPI_FF,
      header: () => <></>,
      rawIconName: 'sonar-optimize-2',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="sonar-optimize-2"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 18 : 22}
            />
          }
        />
      ),
    },
  ],
};
