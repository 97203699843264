import './CreativeChart.scss';

import BaseChart from 'components/library/BaseChart/BaseChart';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import DropDown from 'components/ltv/DropDown';
import {
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import { CHART_COLORS } from 'constants/general';
import { metrics as METRICS } from 'constants/metrics/metrics';
import { cloneDeep } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { type RootState } from 'reducers/RootType';
import { ChartData, SelectableCreative } from 'types/creativeCockpit';
import { formatNumber } from 'utils/formatNumber';
import { Checkbox } from '@shopify/polaris';
import { CreativesCockpitContext } from '../context';
import CreativeThumbnail from '../CreativeThumbnail/CreativeThumbnail';
import {
  TW_PROD_ANALYTICS_PRIMARY_METRIC,
  TW_PROD_ANALYTICS_SECONDARY_METRIC,
} from 'constants/productAnalytics';
import { useShowTheRightAxis } from 'utils/useShowTheRightAxis';
import { SettingsMinor } from '@shopify/polaris-icons';
import { columnsToAddOnlyInProductAnalytics } from '../CreativeTable/columns';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { BaseLegend } from 'components/library/BaseChart/BaseLegend';
import TWImage from 'components/library/TWImage/TWImage';
import { Icon } from '@tw/ui-components';
import { NewLoader } from 'components/loaders/NewLoader';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../../$stores/$shop';
import { Text, ActionIcon, Menu } from '@tw/ui-components';
import { chartOpenChanged } from 'ducks/creativesCockpit/creativesCockpit';
import CustomLineTooltip from 'components/library/BaseChart/ChartTooltip';
import ChartSettings from 'components/library/BaseChart/ChartSettings';
import {
  CHART_OPEN,
  clearSelectedProducts,
  toggleActiveProduct,
  toggleSelectedProduct,
} from 'ducks/productAnalytics';

interface CreativeChartProps {}

const CreativeChart: FC<CreativeChartProps> = () => {
  const {
    serviceId,
    selectedCreatives,
    segments,
    toggleCreativeActive,
    toggleSegmentActive,
    type,
    loadingCreatives,
    chartMode,
    setChartMode,
    chartData,
    setChartData,
    primaryMetric,
    setPrimaryMetric,
    secondaryMetric,
    setSecondaryMetric,
    isShareMode,
    toggleCreativeSelection,
  } = useContext(CreativesCockpitContext);
  const isSmall = useIsSmall();

  const PAGE_NAME = type === 'product' ? 'Product Analytics' : 'Creative Cockpit';
  const PRIMARY_METRIC =
    type === 'product' ? TW_PROD_ANALYTICS_PRIMARY_METRIC : TW_CREATIVE_TRENDS_PRIMARY_METRIC;
  const SECONDARY_METRIC =
    type === 'product' ? TW_PROD_ANALYTICS_SECONDARY_METRIC : TW_CREATIVE_TRENDS_SECONDARY_METRIC;
  const currency = useStoreValue($currency);
  const metrics = Object.values(METRICS).filter((metric) => {
    return (
      (metric.showInServices?.includes(serviceId!) ||
        !metric.showInServices ||
        (columnsToAddOnlyInProductAnalytics.includes(metric.key) && type === 'product')) &&
      (metric.showInCreativeTable?.includes(type!) || metric.showInCreativeTable?.includes('all'))
    );
  });

  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [linesData, setLinesData] = useState<any[]>();
  const [barsData, setBarsData] = useState<any[]>();
  const [showRightAxis, setShowRightAxis] = useState(true);
  const dispatch = useDispatch();

  const useRightAxis = useShowTheRightAxis([primaryMetric, secondaryMetric]);

  useEffect(() => {
    if (chartData === 'creative') {
      setShowRightAxis(useRightAxis);
    } else {
      setShowRightAxis(false);
    }
  }, [useRightAxis, chartData]);

  const setChartGrouping = useCallback(
    (grouping: ChartData) => {
      setChartData?.(grouping);
    },
    [setChartData],
  );

  const handlePrimaryMetricChange = useCallback(
    (metric: string) => {
      setPrimaryMetric(metric);
      localStorage.setItem(PRIMARY_METRIC, metric);
    },
    [PRIMARY_METRIC, setPrimaryMetric],
  );

  const handleSecondaryMetricChange = useCallback(
    (metric: string) => {
      setSecondaryMetric(metric);
      localStorage.setItem(SECONDARY_METRIC, metric);
    },
    [SECONDARY_METRIC, setSecondaryMetric],
  );

  const lineHovered = useCallback(
    (creative: SelectableCreative) => {
      if (creative.active) {
        return;
      }
      setHoveredItems((items) => [...items, creative.id]);
    },
    [toggleCreativeActive],
  );

  const lineLeft = useCallback(
    (creative: SelectableCreative) => {
      const copy = cloneDeep(hoveredItems);
      const index = copy.findIndex((item) => creative.id === item);
      if (index === -1) {
        return;
      }
      copy.splice(index, 1);
      setHoveredItems(copy);
    },
    [hoveredItems, toggleCreativeActive],
  );

  useEffect(() => {
    let linesData: any[];
    let barsData: any[];
    if (chartData === 'creative') {
      linesData = selectedCreatives?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d?.date }))!;
      selectedCreatives?.forEach((creative) => {
        creative.metricsBreakdown?.forEach((bd) => {
          let date = linesData?.find((date) => date.date === bd.date);
          if (!date) {
            date = { date: bd.date };
            linesData?.push(date);
          }
          date[creative.id] = { ...bd.metrics, id: creative.id, color: creative.color };
        });
      });

      barsData =
        selectedCreatives?.map((creative) => ({
          [primaryMetric]: creative.metrics?.[primaryMetric],
          [secondaryMetric]: creative.metrics?.[secondaryMetric],
          fill: creative.color,
          opacity: creative.active ? 1 : 0.1,
        })) || [];
    } else {
      linesData = segments?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d.date }))!;
      segments?.forEach((segment) => {
        segment.metricsBreakdown.forEach((bd) => {
          let date = linesData?.find((date) => date.date === bd.date);
          if (!date) {
            date = { date: bd.date };
            linesData?.push(date);
          }
          date[segment.id] = { ...bd.metrics, id: segment.id, color: segment.color };
        });
      });
      barsData =
        segments?.map((creative) => ({
          [primaryMetric]: creative.metrics?.[primaryMetric],
          [secondaryMetric]: creative.metrics?.[secondaryMetric],
          fill: creative.color,
          opacity: creative.active ? 1 : 0.1,
        })) || [];
    }
    setLinesData(linesData);
    setBarsData(barsData);
  }, [chartData, selectedCreatives, primaryMetric, secondaryMetric, segments]);

  const getMetricLabel = useCallback(
    (metricKey: string) => {
      const metric = metrics.find((m) => m.key === metricKey);
      return metric?.label || metricKey;
    },
    [metrics],
  );

  return (
    <div className="blue-chart-wrapper">
      <div className="blue-chart" data-testid="CreativeChart">
        {loadingCreatives && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <NewLoader />
            </div>
          </div>
        )}
        <div className="blue-chart-top-section flex justify-between items-center px-6 py-3">
          <Text color="gray.7" weight={500} size="lg">
            Trends
          </Text>

          <div className="flex items-center gap-5">
            {type !== 'product' && (
              <DropDown
                showFilter
                value={chartData}
                handleSelect={setChartGrouping}
                options={[
                  { label: 'Creative', value: 'creative' },
                  { label: 'Segment', value: 'segment' },
                ]}
                size="xs"
              />
            )}
            <DropDown
              showFilter
              value={primaryMetric}
              handleSelect={handlePrimaryMetricChange}
              options={[
                { label: 'None', value: 'none' },
                ...metrics.map((m) => ({ label: m?.label, value: m?.key })),
              ]}
              size="xs"
            />
            <DropDown
              showFilter
              value={secondaryMetric === 'none' ? 'none' : secondaryMetric}
              handleSelect={handleSecondaryMetricChange}
              options={[
                { label: 'None', value: 'none' },
                ...metrics.map((m) => ({ label: m?.label, value: m?.key })),
              ]}
              size="xs"
            />

            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />

            <Menu>
              <Menu.Target>
                <div>
                  <ActionIcon
                    size="md"
                    radius="sm"
                    variant="activator"
                    icon={chartMode === 'line' ? 'line-chart' : 'bar-chart'}
                  />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => setChartMode?.('line')}>
                  <Icon name="line-chart" size={14} /> Line
                </Menu.Item>
                <Menu.Item onClick={() => setChartMode?.('bar')}>
                  <Icon name="bar-chart" size={14} /> Bar
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />

            <ChartSettings
              showRightAxis={showRightAxis}
              setShowRightAxis={setShowRightAxis}
              onlyRightAxis={true}
            />

            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />

            <div className="cursor-pointer" style={{ paddingRight: '24px' }}>
              <ActionIcon
                size="md"
                radius="sm"
                variant="activator"
                icon="close"
                onClick={() => {
                  if (type === 'product') {
                    selectedCreatives?.forEach((creative) => {
                      if (creative.selected) {
                        dispatch<any>(toggleSelectedProduct(creative));
                      }
                      if (creative.active) {
                        dispatch(toggleActiveProduct(creative.id));
                      }
                    });
                    dispatch(clearSelectedProducts());
                    dispatch({ type: CHART_OPEN, payload: false });
                  } else {
                    selectedCreatives?.forEach((creative) => {
                      toggleCreativeActive?.(creative);
                      toggleCreativeSelection?.(creative);
                    });
                    dispatch(chartOpenChanged(false));
                  }
                }}
              />
            </div>
          </div>
        </div>
        {chartMode === 'line' && (
          <BaseChart
            data={linesData}
            showTooltip={true}
            ChartType={LineChart}
            margin={{ left: 10, right: 10 }}
            leftYAxisLabel={getMetricLabel(primaryMetric)}
            rightYAxisLabel={showRightAxis ? getMetricLabel(secondaryMetric) : ''}
            xAxis={[
              {
                tickFormatter: (value: string) => {
                  if (!moment(value).isValid() || !value) {
                    return '';
                  }
                  if (value.includes('T')) {
                    return moment(value).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dataKey: 'date',
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
              {
                yAxisId: showRightAxis ? 'right' : 'left',
                orientation: showRightAxis ? 'right' : 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
            ]}
          >
            {(chartData === 'creative' ? selectedCreatives : segments)?.map((creative) => {
              return (
                <Fragment key={creative.id}>
                  {primaryMetric !== 'none' && (
                    <Line
                      yAxisId={'left'}
                      type="monotone"
                      name={getMetricLabel(primaryMetric)}
                      dataKey={(creatives) => {
                        return creatives[creative.id]?.[primaryMetric];
                      }}
                      stroke={creative.color}
                      strokeWidth={3}
                      strokeOpacity={creative.active ? 1 : 0}
                      dot={false}
                      activeDot={{
                        onClick: () => toggleCreativeActive?.(creative),
                        style: {
                          cursor: 'pointer',
                          fill: creative.active ? 'white' : 'transparent',
                          opacity: creative.active ? 1 : 0,
                        },
                      }}
                      onMouseOver={() => lineHovered(creative)}
                      onMouseLeave={() => lineLeft(creative)}
                      onClick={() => toggleCreativeActive?.(creative)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  {secondaryMetric !== 'none' && (
                    <Line
                      yAxisId={showRightAxis ? 'right' : 'left'}
                      type="monotone"
                      name={getMetricLabel(secondaryMetric)}
                      dataKey={(creatives) => {
                        return creatives[creative.id]?.[secondaryMetric];
                      }}
                      dot={false}
                      activeDot={{
                        style: { display: 'none' },
                        strokeOpacity: 0,
                      }}
                      stroke={creative.color}
                      strokeWidth={3}
                      strokeDasharray="3,3"
                      strokeOpacity={creative.active ? 1 : 0}
                    />
                  )}

                  <ChartTooltip
                    content={
                      <CustomLineTooltip
                        data={chartData === 'creative' ? selectedCreatives || [] : segments || []}
                        primaryMetric={primaryMetric}
                        secondaryMetric={secondaryMetric}
                        showImages={false}
                      />
                    }
                    cursor={{ fill: 'none' }}
                    labelStyle={{ fontWeight: 'bold' }}
                    contentStyle={{ fontSize: '10px' }}
                    formatter={(value: number, metricLabel: string) => {
                      const metric = Object.values(METRICS)?.find(
                        (m) => m?.label === metricLabel,
                      )?.key;
                      if (!metric) {
                        return value;
                      }
                      if (!isNaN(value)) {
                        return formatNumber(value, {
                          style: METRICS[metric]?.format || 'decimal',
                          currency,
                          minimumFractionDigits: METRICS[metric]?.toFixed,
                          maximumFractionDigits: METRICS[metric]?.toFixed,
                        });
                      }
                      return value;
                    }}
                    labelFormatter={(value) => {
                      if (moment(value).isValid()) {
                        value = '' + value;
                        return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                      }
                      return value;
                    }}
                  />
                </Fragment>
              );
            })}
          </BaseChart>
        )}
        {chartMode === 'bar' && (
          <>
            <BaseChart
              data={barsData}
              showTooltip={true}
              margin={{ left: 10, right: 10 }}
              ChartType={BarChart}
              leftYAxisLabel={primaryMetric}
              rightYAxisLabel={showRightAxis ? secondaryMetric : ''}
              xAxis={[
                {
                  dataKey: 'date',
                  tickFormatter: (value) => {
                    return !!value && moment(value).isValid() ? moment(value).format('MMM D') : '';
                  },
                },
              ]}
              yAxis={[
                {
                  yAxisId: 'left',
                  tickFormatter: (value, index) => {
                    if (index % 2 !== 0) {
                      return '';
                    }
                    return +value < 1000 ? value : +value / 1000 + 'K';
                  },
                },
                {
                  yAxisId: showRightAxis ? 'right' : 'left',
                  orientation: showRightAxis ? 'right' : 'left',
                  tickFormatter: (value, index) => {
                    if (index % 2 !== 0) {
                      return '';
                    }
                    return +value < 1000 ? value : +value / 1000 + 'K';
                  },
                },
              ]}
            >
              <defs>
                {CHART_COLORS.map((color, index) => (
                  <linearGradient
                    key={'color-' + color + '-' + index}
                    id={`color-creative-${index}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0" stopColor={color} stopOpacity={1} />
                    <stop offset="1" stopColor={color} stopOpacity={0} />
                  </linearGradient>
                ))}
              </defs>
              <Bar barSize={22} yAxisId={'left'} dataKey={primaryMetric} name={primaryMetric}></Bar>
              <Bar
                barSize={22}
                yAxisId={showRightAxis ? 'right' : 'left'}
                dataKey={secondaryMetric}
                name={secondaryMetric}
              >
                {(chartData === 'creative' ? selectedCreatives : segments)?.map((entry, index) => (
                  <Cell
                    key={`cell-gradient-${index}-${secondaryMetric}`}
                    fill={`url(#color-creative-${index % CHART_COLORS.length})`}
                    opacity={entry.active ? 1 : 0.1}
                  />
                ))}
              </Bar>
              <ChartTooltip
                cursor={{ fill: 'none' }}
                labelStyle={{ fontWeight: 'bold' }}
                contentStyle={{ fontSize: '10px' }}
                formatter={(value: number, metricLabel: string) => {
                  const metric = Object.values(METRICS)?.find((m) => m?.label === metricLabel)?.key;
                  if (!metric) {
                    return value;
                  }
                  if (!isNaN(value)) {
                    return formatNumber(value, {
                      style: METRICS[metric]?.format || 'decimal',
                      currency,
                      minimumFractionDigits: METRICS[metric]?.toFixed,
                      maximumFractionDigits: METRICS[metric]?.toFixed,
                    });
                  }
                  return value;
                }}
                labelFormatter={(value) => {
                  if (moment(value).isValid()) {
                    value = '' + value;
                    return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                  }
                  return value;
                }}
              />
            </BaseChart>
          </>
        )}
        <div
          className="flex overflow-auto gap-4 p-[var(--padding)]"
          style={{
            paddingInline: chartMode === 'bar' && !isSmall ? '3.75rem 2.5rem' : '',
            justifyContent: 'flex-start',
            transition: 'all 0.3s ease',
          }}
        >
          {(chartData === 'creative' ? selectedCreatives : segments)?.map((creative) => (
            <BaseLegend
              key={`legend-${creative.id}`}
              color={creative.color}
              tooltipText={
                creative.assetType === 'copy'
                  ? creative.body
                  : creative.name || creative.segmentDescription || creative.segmentTitle
              }
              onClick={() =>
                creative.assetType
                  ? toggleCreativeActive?.(creative)
                  : toggleSegmentActive?.(creative)
              }
              active={creative.active}
              label={!creative.assetType ? creative.segmentTitle : undefined}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreativeChart;
