import { Link, NavLink, useLocation } from 'react-router-dom';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { closeChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { Text } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { UnreadAgentMessages } from 'components/Nav/components/navs/AlanNav/UnreadAgentMessages';

type NavItem = (typeof WILLY_NAV_ITEMS)[0];
type WillyNavItemProps = { activeTab: string; item: NavItem; unread: number };

export const WillyNavItem: React.FC<WillyNavItemProps> = ({ activeTab, item, unread }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAgentSupported = useStoreValue($isAgentSupported);
  const linkAction = () => {
    closeChatSelectorDrawer();
  };
  return (
    <NavLink
      replace={false}
      to={`${item.link}?${searchParams.toString()}`}
      onClick={() => linkAction()}
      className={
        'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
        'render-item flex rounded-md items-center ' +
        'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 ' +
        (item.id === activeTab ? 'bg-[var(--gray-light-mode-300)]' : '')
      }
    >
      <div className="flex items-center gap-2 pl-4 py-1">
        <Text span fz="sm" c="gray.8" fw={500}>
          {item.label}
        </Text>
        {item.id === 'chat' && isAgentSupported && (
          <span className="ml-auto">
            <UnreadAgentMessages />
          </span>
        )}
        <div className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]" />
      </div>
    </NavLink>
  );
};
