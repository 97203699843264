import { Flex, Menu, Text, Icon, Button } from '@tw/ui-components';
import { CurrencyButton } from 'components/CurrencyButton';
import { RoundingOptions, updateDefaultRoundingOption } from 'ducks/summary';
import { useAppDispatch } from 'index';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

export const CurrencySection: FC = () => {
  const dispatch = useAppDispatch();
  const defaultRoundingOption: RoundingOptions | null = useSelector(
    (state: RootState) => state.defaultRoundingOption,
  );

  const onCurrencyDisplayChange = (value: string) => {
    const newValue = value === '' ? null : value;
    dispatch(updateDefaultRoundingOption(newValue));
  };

  return (
    <>
      <Menu.Item>
        <CurrencyButton
          isShopContext={true}
          activator={(currency) => (
            <Flex align="center" justify="space-between">
              <Text size="sm" weight={500} color="named2.0">
                Currency: {currency}
              </Text>
              <Icon name="chevron-right-minor" size={12} color="named.8" />
            </Flex>
          )}
          dropdownPosition={'right-start'}
          dropDownOffset={16}
          withinPortal={false}
        />
      </Menu.Item>
      <Menu.Item>
        <Flex direction="column" gap="sm">
          <Text size="sm" weight={500} color="named2.0">
            Currency Display
          </Text>
          <Button.Group>
            {[
              { label: '0', value: RoundingOptions.ROUND },
              { label: '.0', value: RoundingOptions.ONE_DECIMAL },
              { label: '.00', value: RoundingOptions.TWO_DECIMALS },
              { label: 'Clear', value: '' },
            ].map((item) => {
              const value = defaultRoundingOption || '';
              const selected = item.value === value;
              const color = selected ? 'named.7' : undefined;
              const variant = selected ? 'primary' : 'activator';
              return (
                <Button
                  bg={color}
                  variant={variant}
                  key={item.value}
                  focusNone
                  onClick={() => onCurrencyDisplayChange(item.value)}
                >
                  {item.label}
                </Button>
              );
            })}
          </Button.Group>
        </Flex>
      </Menu.Item>
    </>
  );
};
