import SummaryDatePicker from 'components/SummaryDatePicker';
import { chartOpenChanged, changeAttributionFreeSearch } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { servicesWithEnabledAdSegmentation } from 'utils/selectors';
import { AllServicesIds } from 'types/attribution';
import { ALL_SOURCES_ID } from 'constants/types';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import { RulesEnginePopup } from 'components/RulesEngine/RulesEnginePopup';
import allServices from 'constants/services';
import { useCallback, useMemo, useState } from 'react';
import AttributionSettings from 'components/attribution-new/AttributionSettings';
import ReconnectBanner from '../ReconnectFacebook/ReconnectBanner';
import { useDarkMode } from 'dark-mode-control';
import _db from 'utils/DB';
import moment from '@tw/moment-cached/module/timezone';
import { getSourcesList } from 'utils/attributions';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { SourceTypesWithExtra } from 'types/services';
import { Flex, Icon, TextInput, Text, Button, IconName } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { MetricsCurrentlyFiltered } from 'components/MetricsCurrentlyFiltered';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { AttributionFiltersDropdown } from 'components/attribution-new/AttributionFiltersDropdown';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { WillyMainChatRef } from 'components/Willy/types/willyTypes';
import { ChatWithQueryButton } from '../../Willy/ChatWithQueryButton';
import { AttributionHeaderColumnSelector } from './AttributionHeaderColumnSelector';
import { AttributionSelectChannel } from './AttributionSelectChannel';
import { snakeCase } from 'lodash';

const emptyArray = [];

export type AttributionHeaderProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
};

const AttributionHeader: React.FC<AttributionHeaderProps> = ({
  chatOpen,
  setChatOpen,
  mainChatRef,
}) => {
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const darkMode = useDarkMode();

  const enabledSegments = useSelector(servicesWithEnabledAdSegmentation);
  const hasPixelInstalled = useSelector((state: any) => state.hasPixelInstalled);
  const isFacebookConnected = useSelector((state: RootState) => state.isFacebookConnected);
  const enableRulesEngine = useSelector((state: RootState) => state.shop?.enableRulesEngine);
  const timezone = useSelector((state: RootState) => state.shopTimezone);
  const { chartOpen, freeSearch, useNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const sourcesList = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArray;
    }
    return getSourcesList(sourceCategory || 'all');
  }, [sourceCategory]);

  const headerLabel = useMemo(() => {
    switch (sourceCategory) {
      case 'all':
        return 'Attribution';
      case 'ads':
        return 'Ads';
      case 'email':
        return 'Email / SMS';
      case 'social':
        return 'Organic';
      default:
        return '';
    }
  }, [sourceCategory]);

  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const serviceOptions = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArray;
    }

    return [
      { label: 'All', value: ALL_SOURCES_ID, id: 'all', icon: 'all-campaigns' as IconName },
      ...sourcesList?.map((s) => {
        const source = allServices[s];
        return {
          label: source?.title!,
          value: source?.id!,
          id: snakeCase('attribution_header_' + source?.title!),
          icon: source?.smallIcon as IconName,
        };
      }),
    ];
  }, [sourceCategory, sourcesList]);

  const shouldShowAdsSegmentIndicator = useMemo(() => {
    return (
      enabledSegments.includes(activeSource) ||
      (activeSource === ALL_SOURCES_ID && sourcesList?.some((s) => enabledSegments.includes(s)))
    );
  }, [enabledSegments, activeSource, sourcesList]);

  const toggleAttributionSettingsOpen = useCallback(() => {
    setAttributionSettingsOpen((o) => !o);
  }, []);

  const activeOption = useMemo(() => {
    if (!activeSource || sourceCategory !== 'all') {
      return 'All';
    }
    return serviceOptions.find((s) => s.value === activeSource)?.label || 'All';
  }, [activeSource, sourceCategory, serviceOptions]);

  const dropdownsGroup = useMemo(
    () => (
      <Flex gap="sm" align="center">
        {!(isThreePointOh && isSmall) && sourceCategory !== 'all' && (
          <AttributionSelectChannel
            activator={(label: string) => {
              const selectedItem = serviceOptions.find((item) => item.label === label);
              return (
                <Button
                  size={isThreePointOh ? 'xs' : 'sm'}
                  variant="activator"
                  rightSection="caret-down"
                  fullWidth
                >
                  <Flex align="center" gap="sm">
                    {selectedItem?.icon && <Icon name={selectedItem.icon} size={16} />}
                    {label}
                  </Flex>
                </Button>
              );
            }}
            serviceOptions={serviceOptions}
          />
        )}
        {!isSmall && <AttributionDateModelPicker />}
        {!isSmall && (
          <AttributionModelPicker
            type={sourceCategory as SourceTypesWithExtra}
            sourceId={activeSource as AllServicesIds | 'tw_referrer'}
          />
        )}
      </Flex>
    ),
    [activeSource, isSmall, isThreePointOh, sourceCategory, serviceOptions],
  );

  const chatWithQuerySection = useMemo(
    () => (
      <>
        <div className="flex-shrink-0">
          <ChatWithQueryButton
            chatOpen={chatOpen}
            setChatOpen={setChatOpen}
            mainChatRef={mainChatRef}
            onlyIcon
            source="attribution"
            tooltip="Chat with Attribution"
          />
        </div>
        {!isSmall && <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />}
      </>
    ),
    [chatOpen, isSmall, mainChatRef, setChatOpen],
  );

  return (
    <div
      className={`flex items-center justify-between gap-4 ${
        isThreePointOh ? 'w-full flex-row-reverse' : 'flex-wrap'
      }`}
    >
      {!isFacebookConnected &&
        hasPixelInstalled &&
        activeSource === allServices['facebook-ads']!.id && <ReconnectBanner />}

      <Flex align="center" gap="sm" wrap={`${isThreePointOh ? 'nowrap' : 'wrap'}`}>
        {!isSmall && <MetricsCurrentlyFiltered />}

        <TextInput
          id="att-header-filter-text-field"
          type="search"
          miw={isSmall ? 140 : 150}
          w={140}
          leadingIcon={<Icon name="search-major" size={12} />}
          value={freeSearch}
          onChange={(value) => dispatch(changeAttributionFreeSearch(value))}
          placeholder={`Filter ${activeSource === 'all' ? 'sources' : 'campaigns'}`}
          size={isThreePointOh ? 'xs' : 'sm'}
        />

        {enableRulesEngine && (
          <div id="att-header-rules-engine-popup">
            <RulesEnginePopup serviceId={activeSource}></RulesEnginePopup>
          </div>
        )}

        {!isThreePointOh && dropdownsGroup}
        {isThreePointOh && !isSmall && <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />}

        <Flex align="center" gap="sm">
          {isThreePointOh && chatWithQuerySection}
          <div>{!(isThreePointOh && isSmall) && <AttributionHeaderColumnSelector />}</div>
        </Flex>

        {!(isThreePointOh && isSmall) && (
          <div id="att-header-settings">
            <AttributionSettings
              isOpen={attributionSettingsOpen}
              onOpen={toggleAttributionSettingsOpen}
              type="ads"
              hideAttributionModel={!isSmall}
              hideDateModel={!isSmall}
              hideOneDayConversionValue={
                activeSource !== 'facebook-ads' && sourceCategory !== 'ads'
              }
            />
          </div>
        )}
        {!isThreePointOh && (
          <div id="att-header-date-picker">
            <SummaryDatePicker
              showGroupByButton={false}
              showCompareButton
              earliestDateProp={useNewModels ? moment('2022-07-31').tz(timezone).toDate() : null}
              compareSeparately={isThreePointOh}
            />
          </div>
        )}
      </Flex>
      {isThreePointOh && (
        <Flex align="center" gap="md">
          {!isSmall && (
            <Flex align="center" pl="sm" gap="sm">
              <Icon name="insights" color={'gray.5'} size={18} />
              <Text color="gray.7" weight={500} size="lg">
                {headerLabel}
              </Text>
            </Flex>
          )}
          <div id="att-header-date-picker">
            <SummaryDatePicker
              showGroupByButton={false}
              showCompareButton
              earliestDateProp={useNewModels ? moment('2022-07-31').tz(timezone).toDate() : null}
              compareSeparately={isThreePointOh}
              buttonSize="xs"
            />
          </div>
          {!isSmall && <div className="h-[30px] w-[1px] bg-[#D1D4DB]" />}
          {!isSmall && dropdownsGroup}
          <AttributionFiltersDropdown />
        </Flex>
      )}
    </div>
  );
};

export default AttributionHeader;
