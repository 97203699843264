import { $currency, $currentShopId } from '$stores/$shop';
import { $defaultAiColumns, $isAgentSupported } from '$stores/willy/$sequences';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Modal, Text } from '@tw/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { ColumnName } from './types/willyTypes';
import { copyGlobalColumnToShop } from './utils/willyUtils';
import { userDb } from 'utils/DB';
import { $user } from '$stores/$user';
import { saveAttributionColumns } from 'components/headers/AttributionHeader/saveAttributionColumns';
import { $allPixelColumns } from 'components/attribution-new/columns';
import { localStoragePixelColumnsKeys } from 'constants/attribution';
import { useAppSelector } from 'reducers/RootType';
import { useNavigate } from 'react-router-dom';

type InstallDemoSequencesProps = {
  source: 'feed' | 'pixel';
  opened: boolean;
  onClose: () => void;
};

const DEMO_COLUMN_KEYS: ColumnName[] = ['ai_recommendation'];

export const InstallDemoSequences: React.FC<InstallDemoSequencesProps> = ({
  source,
  opened,
  onClose,
}) => {
  const navigate = useNavigate();
  const currentShopId = useStoreValue($currentShopId);
  const isDemoShop = useComputedValue($currentShopId, (shopId) =>
    [
      'madisonbraids.myshopify.com',
      'demo-shop.myshopify.com',
      'womensbest-uk.myshopify.com',
    ].includes(shopId ?? ''),
  );
  const allPixelColumns = useStoreValue($allPixelColumns);
  const { selectedColumns } = useAppSelector((state) => state.attribution);
  const stickyColumns = useComputedValue($allPixelColumns, (columns) =>
    columns.filter((column) => column.isFixed),
  );
  const currency = useStoreValue($currency);
  const defaultAiColumns = useStoreValue($defaultAiColumns);
  const hasAgents = useStoreValue($isAgentSupported);
  const ignoreAgentsWelcomePixel = useComputedValue(
    $user,
    (user) => !user || user.shops?.[currentShopId || '']?.ignoreAgentsWelcomePixel,
  );
  const ignoreAgentsWelcomeFeed = useComputedValue(
    $user,
    (user) => !user || user.shops?.[currentShopId || '']?.ignoreAgentsWelcomeFeed,
  );

  const [loadingInstallDemoSeq, setLoadingInstallDemoSeq] = useState(false);
  const [errorInstallDemoSeq, setErrorInstallDemoSeq] = useState<string | null>(null);

  const columnsToInstall = useMemo(() => {
    const columns = defaultAiColumns.data?.filter((column) =>
      DEMO_COLUMN_KEYS.includes(column.key),
    );
    return columns;
  }, [defaultAiColumns]);

  const autoInstallFeedSequences = useCallback(async () => {
    setLoadingInstallDemoSeq(true);
    setErrorInstallDemoSeq(null);
    try {
      if (!currentShopId) {
        return;
      }
      const { data } = await axiosInstance.post(`v2/willy/copy-demo-sequences`, {
        shopId: currentShopId,
        currency,
      });
      if (data) {
        toast.success('Auto-Install agents successfully. The agents are now running');
        await userDb().set(
          { shops: { [currentShopId]: { ignoreAgentsWelcomeFeed: true } } },
          { merge: true },
        );
        onClose();
      } else {
        setErrorInstallDemoSeq('Auto-Install agents failed');
      }
    } catch (error) {
      setErrorInstallDemoSeq(error.message ?? error ?? 'Unknown error');
    } finally {
      setLoadingInstallDemoSeq(false);
    }
  }, [currentShopId, currency, onClose]);

  const autoInstallPixelSequences = useCallback(async () => {
    if (!columnsToInstall) {
      return;
    }
    setLoadingInstallDemoSeq(true);
    setErrorInstallDemoSeq(null);
    try {
      if (!currentShopId) {
        return;
      }
      const promises = columnsToInstall.map((column) => copyGlobalColumnToShop(column));
      const data = await Promise.all(promises);
      if (data) {
        toast.success('Auto-Install agents successfully. The agents are now running');
        await userDb().set(
          { shops: { [currentShopId]: { ignoreAgentsWelcomePixel: true } } },
          { merge: true },
        );
        onClose();
      } else {
        setErrorInstallDemoSeq('Auto-Install demo workflow templates failed');
      }
      let colsToAdd = columnsToInstall
        .map((c) => allPixelColumns.find((col) => col.key === c.key) || null)
        .filter((c) => c !== null);
      colsToAdd = [...stickyColumns, ...colsToAdd, ...selectedColumns];
      colsToAdd = colsToAdd.filter(
        (c, index, self) => self.findIndex((t) => t.key === c.key) === index,
      );
      const columnIds = colsToAdd.map((x) => x.key);
      const storageKey = localStoragePixelColumnsKeys.all;
      localStorage.setItem(storageKey, JSON.stringify(columnIds));
      await saveAttributionColumns(currentShopId, colsToAdd);
      navigate({
        pathname: '/dashboards/attribution/ads/facebook-ads',
        search: window.location.search,
      });
    } catch (error) {
      setErrorInstallDemoSeq(error.message ?? error ?? 'Unknown error');
    } finally {
      setLoadingInstallDemoSeq(false);
    }
  }, [
    columnsToInstall,
    currentShopId,
    onClose,
    stickyColumns,
    allPixelColumns,
    selectedColumns,
    navigate,
  ]);

  const install = useCallback(async () => {
    if (source === 'feed') {
      await autoInstallFeedSequences();
    } else {
      await autoInstallPixelSequences();
    }
  }, [source, autoInstallFeedSequences, autoInstallPixelSequences]);

  const handleDismiss = useCallback(async () => {
    if (!currentShopId) {
      return;
    }
    if (source === 'feed') {
      await userDb().set(
        { shops: { [currentShopId]: { ignoreAgentsWelcomeFeed: true } } },
        { merge: true },
      );
    } else {
      await userDb().set(
        { shops: { [currentShopId]: { ignoreAgentsWelcomePixel: true } } },
        { merge: true },
      );
    }
    onClose();
  }, [onClose, source, currentShopId]);

  if (!isDemoShop) {
    return null;
  }

  if (!hasAgents) {
    return null;
  }

  if (source === 'feed' && ignoreAgentsWelcomeFeed) {
    return null;
  }

  if (source === 'pixel' && ignoreAgentsWelcomePixel) {
    return null;
  }

  return (
    <Modal.Root opened={opened} onClose={onClose} size="lg" centered>
      <Modal.Overlay />
      <Modal.Content p="0">
        <Modal.Header p="40px" pb="0">
          <Modal.Title>
            <Text size="26px" weight="bold">
              Introducing Moby Agents ✨
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body p="0">
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-8 p-16 pb-0">
              <Text size="md" weight={500}>
                We're giving you <span className="font-bold">500 sample credits</span> to see its
                power in action. Check it out and discover clarity like never before!
              </Text>
              <div className="flex items-center gap-4">
                <div>
                  <Button onClick={install} loading={loadingInstallDemoSeq}>
                    Get Started
                  </Button>
                </div>
                <div>
                  <Button variant="white" onClick={handleDismiss}>
                    Don't show this again
                  </Button>
                </div>
              </div>
              {errorInstallDemoSeq && (
                <pre className="text-red-200">
                  <Text color="red.9">{errorInstallDemoSeq}</Text>
                </pre>
              )}
            </div>
            <div className="flex justify-center w-full">
              <img
                className="w-full object-cover ml-24"
                src={
                  source === 'pixel'
                    ? 'https://storage.googleapis.com/public-assets-shofifi/agents-library/wolcome-pixel-agents.png'
                    : 'https://storage.googleapis.com/public-assets-shofifi/agents-library/welcome-feed-agents.png'
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
