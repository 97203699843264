import { useStoreValue } from '@tw/snipestate';
import {
  Flex,
  Text,
  Image,
  Collapse,
  TextInput,
  Button,
  Anchor,
  isDefined,
} from '@tw/ui-components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BillingIntervalSwitcher, ContractTypeAndBillingInterval } from './BillingIntervalSwitcher';
import { subscriptionPlan } from '@tw/types/module/services/subscription-manager';
import PriceDisplay, { calculatePriceWithCoupon } from 'components/UpgradePlan/PriceDisplay';
import { ActivePMSection } from '../ActivePMSection';
import formatPrice from 'utils/formatPrice';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { $isFreeShop, $shopSubscription } from '$stores/willy/$subscription';
import { AddonsMetaData } from '../Addons/AddonsMetaData';
import { QuantityInput } from '../Addons/QuantityInput';
import { AddonMetaData, SelectedAddon } from '../Addons/AddonTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import Stripe from 'stripe';
import { useAppDispatch } from 'index';

type SubscriptionDetailsProps = {
  plan?: subscriptionPlan;
  addons: SelectedAddon[];
  setAddons: (addons: SelectedAddon[]) => void;
  contractTypes: ContractTypeAndBillingInterval[];
  selectedType: ContractTypeAndBillingInterval;
  setSelectedType?: (selectedType: ContractTypeAndBillingInterval) => void;
  newPromotionCodeId: string | undefined;
  setNewPromotionCodeId: (id: string | undefined) => void;
  banner?: JSX.Element;
  isManageAddon?: boolean;
  onDiscount?: (discount: number) => void;
  setTotalAmount?: (totalAmount: number) => void;
  setSkipAddonsStep?: (skipAddonsStep: boolean) => void;
  taxAmount?: number;
  setTaxAmount?: (taxAmount: number) => void;
  paymentMethodId?: string;
  setPaymentMethodIdLocal?: (paymentMethodId: string) => void;
};

type PlanOrAddon = {
  product_id: string;
  price: number;
  quantity: number;
  coupon?: any;
};

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({
  plan,
  addons,
  setAddons,
  contractTypes,
  selectedType,
  setSelectedType,
  newPromotionCodeId,
  setNewPromotionCodeId,
  banner,
  isManageAddon,
  onDiscount,
  setTotalAmount,
  setSkipAddonsStep,
  taxAmount,
  setTaxAmount,
  paymentMethodId,
  setPaymentMethodIdLocal,
}) => {
  const [address, setAddress] = useState<{
    postal_code?: string;
    country?: string;
    city?: string;
    line1?: string;
    line2?: string;
  }>({});
  const { coupon, promotion_code } = useStoreValue($shopSubscription) || {};
  const [changeCoupon, setChangeCoupon] = useState(false);
  const [newPromotionCode, setNewPromotionCode] = useState('');
  const [newCoupon, setNewCoupon] = useState<any>(null);
  const subscription = useStoreValue($shopSubscription);
  const isOnlyAddon = useMemo(() => !plan && addons.length === 1, [plan, addons]);
  const currentShop = useSelector((state: RootState) => state.currentShop);
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState<
    Stripe.PaymentMethod | undefined
  >(undefined);
  const couponInUse = useMemo(() => {
    return newPromotionCodeId ? newCoupon : coupon;
  }, [coupon, newCoupon, newPromotionCodeId]);
  const isFreeShop = useStoreValue($isFreeShop);

  const discountedProducts: Set<string> | null = useMemo(
    () => (Array.isArray(couponInUse?.applies_to) ? new Set<string>(couponInUse.applies_to) : null),
    [couponInUse],
  );

  const handleNewPromotionCodeChange = useCallback((newValue: string) => {
    setNewPromotionCode(newValue);
  }, []);

  const isDiscounted = useCallback(
    (prodId: string = '') => {
      return (
        (discountedProducts === null && couponInUse) ||
        (discountedProducts !== null && discountedProducts.has(prodId))
      );
    },
    [discountedProducts, couponInUse],
  );

  const planAndAddons = useMemo(() => {
    const items: PlanOrAddon[] = [];

    if (plan) {
      items.push({
        product_id: plan.product_id,
        price: Number(plan.price),
        quantity: 1,
        coupon: couponInUse,
      });
    }

    addons.forEach((addon) => {
      items.push({
        product_id: addon.addon.product_id,
        price: Number(addon.addon.price),
        quantity: addon.quantity,
        coupon: couponInUse,
      });
    });

    return items;
  }, [plan, addons, couponInUse]);

  const resolvedAddress = useMemo(() => {
    return Object.keys(address).length > 0
      ? address
      : customerPaymentMethod?.billing_details?.address || {};
  }, [address, customerPaymentMethod]);

  const fetchTaxAmount = useCallback(async () => {
    const lineItems = planAndAddons.map((item) => ({
      product_id: item.product_id,
      amount: Math.round(
        calculatePriceWithCoupon(item.coupon, +item.price * (item.quantity || 1), item.product_id),
      ),
    }));

    const requestData = {
      lineItems,
      customerAddress: resolvedAddress,
    };

    try {
      const response = await axiosInstance.post(
        '/v2/subscription-manager/taxes/getTaxAmountForTransaction',
        requestData,
      );
      setTaxAmount?.(response.data.taxAmountToCollect || 0);
    } catch (error) {
      console.error('Error fetching tax amount:', error);
    }
  }, [planAndAddons, resolvedAddress, setTaxAmount]);

  useEffect(() => {
    if (selectedType && Object.keys(resolvedAddress).length > 0) {
      fetchTaxAmount();
    }
  }, [selectedType, resolvedAddress, fetchTaxAmount]);

  useEffect(() => {
    if (!isFreeShop && currentShop.customerId) {
      const fetchCustomerPaymentMethod = async () => {
        const url = `v2/subscription-manager/payments/customer/${currentShop.customerId}`;
        const { data } = await axiosInstance.get<Stripe.PaymentMethod[]>(url);
        if (data?.length) {
          setCustomerPaymentMethod(data[0]);
          setPaymentMethodIdLocal?.(data[0].id);
        }
      };
      fetchCustomerPaymentMethod();
    }
  }, [isFreeShop, currentShop, setPaymentMethodIdLocal]);

  const calculatePriceAfterDiscount = useCallback(
    (price: number, product_id: string = '') => {
      if (!couponInUse || !isDiscounted(product_id)) return price;

      const { percent_off, amount_off } = couponInUse;
      if (isDefined(percent_off)) return price * ((100 - percent_off) / 100);
      if (isDefined(amount_off)) return price - amount_off;

      return price;
    },
    [couponInUse, isDiscounted],
  );

  const tryNewPromotionCode = useCallback(async () => {
    if (newPromotionCodeId) {
      setNewPromotionCodeId(undefined);
      return;
    }
    try {
      const res = await axiosInstance.get(
        `/v2/subscription-manager/promotions/code/${newPromotionCode}`,
      );
      if (!res.data.length) {
        throw new Error('No promotion code found');
      }
      setNewPromotionCodeId(res.data[0]?.id);
      setNewCoupon({
        ...res.data[0]?.coupon,
        applies_to: res.data[0]?.coupon.applies_to?.products,
      });
    } catch (err) {
      toast.error(`Something went wrong: ${err.message}`);
      console.log(err);
    }
  }, [newPromotionCode, newPromotionCodeId, setNewPromotionCodeId]);

  const totalPrice = useMemo(() => {
    let total = 0;
    if (plan) {
      total += +plan.price;
    }
    if (addons.length) {
      total += addons.reduce((acc, addon) => {
        return acc + +addon.addon.price * addon.quantity;
      }, 0);
    }
    return total;
  }, [plan, addons]);

  const totalPriceAfterDiscount = useMemo(() => {
    let total = 0;
    if (plan) {
      const { price, product_id } = plan;
      total += calculatePriceAfterDiscount(+price, product_id);
    }
    if (addons.length) {
      total += addons.reduce((acc, addon) => {
        return (
          acc +
          calculatePriceAfterDiscount(+addon.addon.price * addon.quantity, addon.addon.product_id)
        );
      }, 0);
    }
    if (
      (isFreeShop || subscription?.status === 'canceled' || subscription?.features?.length === 0) &&
      taxAmount
    ) {
      total += taxAmount;
    }
    const finalTotal = +total.toFixed(0);

    if (setTotalAmount) {
      setTotalAmount(finalTotal);
    }

    return finalTotal;
  }, [
    plan,
    addons,
    setTotalAmount,
    calculatePriceAfterDiscount,
    subscription?.status,
    taxAmount,
    isFreeShop,
    subscription?.features?.length,
  ]);

  useEffect(() => {
    const discountValue = totalPrice - totalPriceAfterDiscount;
    if (onDiscount) {
      onDiscount(discountValue);
    }
  }, [totalPrice, totalPriceAfterDiscount, onDiscount]);
  const QuantityInputElement = (addon: SelectedAddon, metaData?: AddonMetaData) => (
    <QuantityInput
      unitLabel={metaData?.unitLabel}
      value={addon.quantity}
      onChange={(value) => {
        const newAddons = addons.map((a) => {
          if (a.addon.product_id === addon.addon.product_id) {
            return { addon: addon.addon, quantity: value };
          }
          return addon;
        });
        setAddons([...newAddons]);
      }}
    />
  );
  return (
    <Flex direction="column" gap="lg" pb="xl">
      {!isOnlyAddon && setSelectedType && contractTypes.length === 2 && (
        <BillingIntervalSwitcher
          contractTypes={contractTypes}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      )}
      <Text color="gray.8" size="lg" weight={600}>
        Order Summary
      </Text>
      {addons.length > 0 && !isOnlyAddon && (
        <Text size="sm" color="gray.5" weight={500} tt="uppercase">
          Plan
        </Text>
      )}
      {plan && (
        <Flex gap="sm" justify="space-between" align="center">
          <Text size="lg" color="gray.9" weight={500}>
            {plan.product_name}
          </Text>
          <PriceDisplay
            price={+plan.price}
            insteadOf={+plan.price}
            selected={false}
            earlyBird={plan.early_birds}
            billingInterval={plan.billing_interval ?? 'month'}
            intervalCount={plan.interval_count}
          />
        </Flex>
      )}
      {addons.map((addon) => {
        const {
          product_id,
          product_name,
          price,
          billing_interval,
          interval_count,
          early_birds,
          upgradeData,
        } = addon.addon;
        const addonMetaData = AddonsMetaData[product_id];
        return (
          <Flex direction="column" gap="lg" key={product_id}>
            {/* {!isOnlyAddon && <div className="w-full h-[1px] bg-[var(--mantine-color-gray-2)]" />} */}
            {!isOnlyAddon && (
              <Text size="sm" color="gray.5" weight={500} tt="uppercase">
                Add Ons
              </Text>
            )}
            <Flex gap="sm" justify="space-between" align="center">
              <Flex gap="sm" align="center">
                {addonMetaData?.icon && isOnlyAddon && addonMetaData.icon}
                <Text size="md" color="gray.9" weight={500}>
                  {product_name}
                </Text>
                {!isOnlyAddon &&
                  addonMetaData?.isQuantity &&
                  QuantityInputElement(addon, addonMetaData)}
              </Flex>
              <PriceDisplay
                price={+price}
                insteadOf={+price}
                selected={false}
                earlyBird={early_birds}
                billingInterval={addonMetaData?.unitLabel ?? billing_interval ?? 'month'}
                intervalCount={interval_count}
              />
            </Flex>
            {addonMetaData?.isQuantity && isOnlyAddon && (
              <div>
                <Flex justify="space-between" align="center">
                  {!isManageAddon && (
                    <Text size="md" color="gray.9" weight={500}>
                      Choose number of {addonMetaData.unitLabel}s
                    </Text>
                  )}
                  {QuantityInputElement(addon, addonMetaData)}
                </Flex>
              </div>
            )}
          </Flex>
        );
      })}
      {taxAmount !== undefined && taxAmount > 0 && (
        <Flex gap="sm" justify="space-between" align="center">
          <Text size="lg" color="gray.9" weight={500}>
            Estimated Tax
          </Text>
          <PriceDisplay
            price={+taxAmount}
            insteadOf={+taxAmount}
            selected={false}
            earlyBird={false}
            billingInterval={plan?.billing_interval ?? 'month'}
            intervalCount={plan?.interval_count ?? 1}
          />
        </Flex>
      )}

      {couponInUse && (
        <Flex align="center" justify="space-between">
          <Flex align="center" gap="xs">
            <Text color="gray.9" weight={500}>
              Discount
              <Text as="span" inherit color="gray.5">
                ({newPromotionCodeId ? newPromotionCode : promotion_code ?? couponInUse.name})
              </Text>
            </Text>
            <Anchor
              onClick={() => {
                setChangeCoupon((prev) => !prev);
              }}
              underline="never"
              fw={600}
            >
              Change Coupon
            </Anchor>
          </Flex>
          <Text color="green.5" weight={600}>
            -${formatPrice(totalPrice - totalPriceAfterDiscount, 2)}
          </Text>
        </Flex>
      )}
      <Collapse in={changeCoupon || !couponInUse} id="change-coupon-collapse">
        <Flex align="center" gap="xs">
          <TextInput
            placeholder="Enter a promotion code"
            value={newPromotionCode}
            onChange={handleNewPromotionCodeChange}
          />
          <Button onClick={tryNewPromotionCode} disabled={!newPromotionCode.length}>
            {newPromotionCodeId ? 'Remove' : 'Apply'}
          </Button>
        </Flex>
      </Collapse>
      {(coupon?.duration_in_months || coupon?.duration === 'once') && (
        <Text color="gray.5" size="sm" weight={500}>
          * Coupon valid for {coupon?.duration_in_months || 1} months from date of application.
        </Text>
      )}
      <div className="w-full h-[1px] bg-[var(--mantine-color-gray-2)]" />
      <Flex gap="sm" justify="space-between" align="center">
        <Text size="xl" color="gray.9" weight={600}>
          Total
          {isManageAddon && isOnlyAddon && (
            <Text as="span" size="sm" color="gray.6">
              {` (${addons[0].quantity} ${AddonsMetaData[addons[0].addon.product_id]?.unitLabel}s)`}
            </Text>
          )}
        </Text>
        <Text size="xl" color="gray.9" weight={600}>
          ${formatPrice(totalPriceAfterDiscount, 2)}
        </Text>
      </Flex>
      {isOnlyAddon && !isManageAddon && (
        <Text color="gray.5" size="sm" weight={500} pb="xl">
          *This is the amount that will be added to your {selectedType?.contractType} bill moving
          forward
        </Text>
      )}
      {banner}
      <Text color="gray.9" size="xl" weight={600} pt="xl">
        Payment method
      </Text>
      <ActivePMSection
        wrapperClass="!p-0"
        plan={plan}
        addons={addons}
        couponInUse={couponInUse}
        setSkipAddonsStep={setSkipAddonsStep}
        paymentMethodId={paymentMethodId}
        setPaymentMethodIdLocal={setPaymentMethodIdLocal}
        setAddress={setAddress}
      />
    </Flex>
  );
};
