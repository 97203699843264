import { $activeAccounts, $currency, $currentShopId, $industry, $timezone } from '$stores/$shop';
import {
  $isAdminClaim,
  $isShopAdmin,
  $isTwGlobalDashboardCreatorClaim,
  $userId,
} from '$stores/$user';
import { $derived, $effect, $mutableDerived, $observer, $store } from '@tw/snipestate';
import {
  Column,
  ColumnName,
  Dialect,
  RunSequenceRequest,
  SequenceProgressEvent,
  WillyDataSequence,
  WillyElementType,
  AgentEntity,
} from 'components/Willy/types/willyTypes';
import _db, { firestoreRef, toArray } from 'utils/DB';
import { $ffStore } from '../../feature-flag-system';
import { $shopWithSensory } from '../$shopWithSensory';
import { $globalDashboardsStatistics } from './$globalDashboardsStatistics';
import { services, ServicesIds } from '@tw/types/module/services';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { isEqual } from 'lodash';
import axiosInstance from 'utils/axiosInstance';
import { runWorkflow } from 'components/Willy/utils/sequences';
import { v4 as uuidV4 } from 'uuid';
import { $socket } from '$stores/$socket';
import { AnalyticsObjectType } from '@tw/types/module/types';

const $globalSequencesSnapshot = $observer(
  { data: [] as WillyDataSequence[], loading: false, error: null as string | null },
  (get, set) => {
    if (!get($userId)) return;

    set({ ...get(), loading: true });
    return firestoreRef()
      .collection('global_data_sequences')
      .onSnapshot((querySnapshot) =>
        set({ ...get(), loading: false, data: toArray(querySnapshot) }),
      );
  },
);

const $privateGlobalSequences = $observer(
  { data: [] as WillyDataSequence[], loading: false, error: null as string | null },
  (get, set) => {
    const snapshot = get($globalSequencesSnapshot);
    if (snapshot.loading) {
      set({ ...get(), loading: true });
      return;
    }

    const ffComputer = get($ffStore);
    if (!ffComputer.isReady) return;

    const shopWithSensory = get($shopWithSensory);
    const globalDashboardsStatistics = get($globalDashboardsStatistics);
    const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
    const isAdminClaim = get($isAdminClaim);
    const { allowList } = ffComputer.getConfigById(FeatureFlag.TEMPLATES_FF);
    const allowedSeqIds = new Set(allowList);

    const data = snapshot.data
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((sequence) => {
        let isProviderLocked = false;
        const { providers, providersBlockingCombination } = sequence;
        if (!!providers?.length) {
          const providersIsConnected = providers.map(
            (x) => services[x]?.getIsConnected?.(shopWithSensory) ?? true,
          );

          if (providersBlockingCombination === 'NONE') {
            isProviderLocked = false;
          } else if (providersBlockingCombination === 'OR') {
            isProviderLocked = providersIsConnected.every((x) => !x);
          } else {
            isProviderLocked = providersIsConnected.includes(false);
          }
        }

        const pkgMap = ffComputer.ffPackagesConfig;

        const packages: FeatureFlagConfigKey[] = [];
        if (pkgMap) {
          for (let pkgName in pkgMap) {
            const _pkgName = pkgName as FeatureFlagConfigKey;
            const val = pkgMap?.[_pkgName]?.[FeatureFlag.TEMPLATES_FF]?.value;
            if (Array.isArray(val) && (val as any[]).includes(sequence.id)) packages.push(_pkgName);
          }
        }

        const defaultPackages: FeatureFlagConfigKey[] = [];
        if (pkgMap) {
          for (let pkgName in pkgMap) {
            const _pkgName = pkgName as FeatureFlagConfigKey;
            const val = pkgMap?.[_pkgName]?.[FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF]?.value;
            if (Array.isArray(val) && (val as any[]).includes(sequence.id))
              defaultPackages.push(_pkgName);
          }
        }

        return {
          ...sequence,
          renameMode: false,
          actionsMenuOpen: false,
          type: 'sequence',
          isGlobal: true,
          installCount: globalDashboardsStatistics[sequence.id]?.installed ?? 0,
          packages,
          defaultPackages,
          canEdit: isTwGlobalDashboardCreatorClaim,
          isLocked: !!(allowedSeqIds.size && !allowedSeqIds.has(sequence.id)),
          isProviderLocked,
        } satisfies WillyDataSequence;
      })
      .filter(({ isBeta }) => (isAdminClaim ? true : !isBeta))
      .filter(({ isHide }) => !isHide)
      .filter((x) => !x.deleted);

    set({ ...get(), loading: false, data });
  },
);

export const $globalSequences = $derived((get) => get($privateGlobalSequences).data);

export const $loadingGlobalSequences = $derived((get) => get($privateGlobalSequences).loading);

export const $shopSequenceSnapshot = $observer(
  { data: [] as WillyDataSequence[], loading: false, error: null as string | null },
  (get, set) => {
    const shopId = get($currentShopId);
    if (!shopId || !get($userId)) {
      return;
    }

    set({ ...get(), loading: true });
    return _db(shopId)
      .collection('data_sequences')
      .onSnapshot((querySnapshot) => {
        let data: WillyDataSequence[] = toArray(querySnapshot);
        set({ ...get(), data, loading: false, error: null });
      });
  },
);

export const $shopSequencesRaw = $observer<WillyDataSequence[]>([], (get, set) => {
  if (!get($userId)) return;

  const shopId = get($currentShopId);
  if (!shopId) return set([]);
  const shopSequenceSnapshot = get($shopSequenceSnapshot);
  const globalSequences = get($globalSequences);
  const isUserTwAdmin = get($isAdminClaim);
  const isShopAdmin = get($isShopAdmin);
  const shopSequences = shopSequenceSnapshot.data
    .filter((x) => x.name)
    .filter((x) => !x.deleted)
    .map((seq) => {
      let isLocked = false;
      let isProviderLocked = false;

      if (
        seq.globalDashboardId &&
        globalSequences.map((x) => x.id).includes(seq.globalDashboardId)
      ) {
        const globalSeq = globalSequences.find((d) => d.id === seq.globalDashboardId);
        if (globalSeq) {
          isLocked = globalSeq.isLocked ?? false;
          isProviderLocked = globalSeq.isProviderLocked ?? false;
        }
      }

      return {
        ...seq,
        renameMode: false,
        actionsMenuOpen: false,
        isLocked,
        isProviderLocked,
        canEdit: isUserTwAdmin || isShopAdmin, //TODO @Yitzchak Sviridyuk permission
        isGlobal: false,
        type: 'sequence' as WillyElementType,
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  if (isEqual(shopSequences, get())) return;
  set(shopSequences);
});

export const $loadingShopSequences = $derived((get) => get($shopSequenceSnapshot).loading);

export const $shopSequences = $mutableDerived<WillyDataSequence[]>((get) => {
  const shopSequencesRaw = get($shopSequencesRaw);
  return shopSequencesRaw; // 🧐
});

export const $sequencesUnreadReportsPerDashboard = $observer<Record<string, number>>(
  {},
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return firestoreRef()
      .collectionGroup('workflow_feed')
      .where('shopId', '==', shopId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.reduce(
          (acc, doc) => {
            const { read } = doc.data();
            if (read) {
              return acc;
            }
            const dashboardId = doc.ref.parent.parent?.id;
            if (!dashboardId) return acc;
            acc[dashboardId] = (acc[dashboardId] || 0) + 1;
            return acc;
          },
          {} as Record<string, number>,
        );
        set(data);
      });
  },
);

export const $globalAndShopSequences = $mutableDerived<WillyDataSequence[]>((get) => {
  const shopSequences = get($shopSequences);
  const globalSequences = get($globalSequences);

  // return all of the 2 lists above, but if the id exists in both lists, return the shop sequence
  // but if one of the shop's sequences has "globalDashboardId", remove the global with this globalDashboardId as an id
  return [...globalSequences, ...shopSequences].filter((seq, index, self) => {
    if (seq.globalDashboardId) {
      return !globalSequences.find((g) => g.id === seq.globalDashboardId);
    }
    return index === self.findIndex((t) => t.id === seq.id);
  });
});

export const $defaultAiColumns = $derived(async (get): Promise<Column<ColumnName>[]> => {
  const userId = get($userId);
  if (!userId) {
    return [];
  }
  const globalColumns = await firestoreRef().collection('ai_columns').get();
  return toArray(globalColumns);
});

export const $shopAiColumns = $mutableDerived(async (get): Promise<Column<ColumnName>[]> => {
  const shopId = get($currentShopId);
  const userId = get($userId);
  if (!shopId || !userId) {
    return [];
  }
  const columns = await _db(shopId).collection('ai_columns').get();
  return toArray(columns);
});

export const $mergedAiColumns = $derived<{
  columns: Column<ColumnName>[];
  loading: boolean;
  error?: unknown;
}>((get) => {
  const defaultAiColumns = get($defaultAiColumns);
  const shopAiColumns = get($shopAiColumns);
  return {
    columns:
      defaultAiColumns.data?.map((column) => {
        const shopColumn = shopAiColumns.data?.find((c) => c.key === column.key);
        return { ...column, ...shopColumn };
      }) ?? [],
    loading: defaultAiColumns.pending || shopAiColumns.pending,
    error: defaultAiColumns.error || shopAiColumns.error,
  };
});

export async function updateShopColumn(
  shopId: string,
  columnId: string,
  column: Partial<Column<ColumnName>>,
) {
  await _db(shopId).collection('ai_columns').doc(columnId).set(column, { merge: true });
}

export const $isAgentSupported = $derived((get) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return false;
  const support = ffComputer.getConfigById(FeatureFlag.AGENT_SUPPORT_FF);
  return !!support.result;
});

export const $isAgentBuilderSupported = $derived((get) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return false;
  const supportAgents = ffComputer.getConfigById(FeatureFlag.AGENT_SUPPORT_FF);
  const supportDataWarehouseSync = ffComputer.getConfigById(FeatureFlag.WAREHOUSE_SYNC_OUT_FF);
  return !!supportAgents.result || !!supportDataWarehouseSync.result;
});

type SequenceId = string;
type EntityId = string;

export const $aiColumnsLoading = $store<Partial<Record<ColumnName, Record<SequenceId, boolean>>>>(
  {},
);
export const $aiColumnsActiveWorkflows = $store<Partial<Record<ColumnName, string[]>>>({});
export const $aiColumnsCancelledWorkflows = $store<Partial<Record<ColumnName, boolean>>>({});
export const $updatedAiRows = $store<Record<EntityId, Record<ColumnName, boolean>>>({});
export const $selectedAiColumn = $store<ColumnName | null>(null);
export const $selectedAiCell = $store<{
  id: string;
  entity: AgentEntity | null;
  column: ColumnName;
  serviceId?: ServicesIds | 'all';
  value: {
    data: string;
    reason: string;
    createdAt: string;
    rawDataPath: string;
  };
} | null>(null);

export async function cancelRunningWorkflow(column: ColumnName, shopId: string) {
  const workflowIds = $aiColumnsActiveWorkflows.get()[column];
  if (!workflowIds?.length) {
    return;
  }
  const promises = workflowIds.map(async (workflowId) => {
    await axiosInstance.post('/v2/sequences/workflows/cancel', {
      shopId,
      workflowId,
    });
    $aiColumnsCancelledWorkflows.set((old) => ({
      ...old,
      [column]: true,
    }));
  });
  try {
    await Promise.all(promises);

    $aiColumnsActiveWorkflows.set((old) => ({
      ...old,
      [column]: [],
    }));
  } catch (error) {
    console.error(error);
  }
}

export async function runAiColumnWorkflow(column: Column<ColumnName>) {
  if (!column.sequences?.length) {
    return;
  }
  const shopId = $currentShopId.get();
  const activeAccounts = $activeAccounts.get();
  const currency = $currency.get();
  const shopTimezone = $timezone.get();
  const industry = $industry.get();
  if (!shopId || !activeAccounts || !currency || !shopTimezone || !industry) {
    return;
  }
  const sequences = $shopSequences
    .get()
    .filter((sequence) => column.sequences?.some((s) => s.sequenceId === sequence.id));
  if (!sequences.length) {
    return;
  }
  const promises = sequences.map(async (sequence) => {
    const newRunId = uuidV4();
    const requestParams: RunSequenceRequest = {
      sequenceId: sequence.id,
      shopId,
      runId: newRunId,
      trigger: 'manual',
      depth: 0,
      currency,
      timezone: shopTimezone,
      asDraft: false,
    };
    $aiColumnsCancelledWorkflows.set((old) => ({
      ...old,
      [column.key]: false,
    }));

    const response = runWorkflow(requestParams);
    const res = await response;
    return res.workflowId;
  });
  const workflowIds = await Promise.all(promises);
  $aiColumnsActiveWorkflows.set((old) => ({
    ...old,
    [column.key]: workflowIds,
  }));
}

$effect((_, get) => {
  const currentShopId = get($currentShopId);
  const socket = get($socket);

  const func = async (msg) => {
    if (msg.eventType !== 'workflow-progress' || msg.account !== currentShopId) {
      return;
    }

    await updateAgentColumn(msg.data);
  };

  if (!socket) {
    return;
  }

  const listener = socket.on('message', func);

  return () => {
    listener.off('message', func);
  };
});

export async function updateAgentColumn(msg: SequenceProgressEvent) {
  const { sequenceId: seqId, type, text, runId: id, workflowId } = msg;
  const currentShopId = $currentShopId.get();
  const column = $shopAiColumns
    .get()
    .data?.find((c) => c.sequences?.some((s) => s.sequenceId === seqId));
  if (!column || !currentShopId) {
    return;
  }

  if ($aiColumnsCancelledWorkflows.get()[column.key]) {
    return;
  }

  if (type === 'sequence-started' || type === 'step-started' || type === 'step-progress') {
    $aiColumnsActiveWorkflows.set((old) => ({
      ...old,
      [column.key]: [...new Set([...(old[column.key] || []), workflowId])],
    }));
    $aiColumnsLoading.set((old) => ({
      ...old,
      [column.key]: {
        ...old[column.key],
        [seqId]: true,
      },
    }));
  } else if (type === 'sequence-done' || type === 'sequence-error') {
    $aiColumnsLoading.set((old) => ({
      ...old,
      [column.key]: {
        ...old[column.key],
        [seqId]: false,
      },
    }));
    $updatedAiRows.set((old) => {
      const updated = Object.entries(old).reduce((acc, [campaignId, columns]) => {
        return {
          ...acc,
          [campaignId]: {
            ...columns,
            [column.key]: true,
          },
        };
      }, {});

      return updated;
    });
  }

  if (type === 'sequence-started') {
    $updatedAiRows.set((old) => {
      const updated = Object.entries(old).reduce((acc, [campaignId, columns]) => {
        return {
          ...acc,
          [campaignId]: {
            ...columns,
            [column.key]: false,
          },
        };
      }, {});

      return updated;
    });
    $aiColumnsCancelledWorkflows.set((old) => ({
      ...old,
      [column.key]: false,
    }));
  } else if (type === 'sequence-error') {
    $shopAiColumns.set((old) => {
      return {
        ...old,
        data: old.data?.map((c) => {
          if (c.sequences?.some((s) => s.sequenceId === seqId)) {
            return { ...c, error: text };
          }
          return c;
        }),
      };
    });
    await updateShopColumn(currentShopId, column.key, {
      error: text,
    });
  } else if (type === 'sequence-done') {
    $shopAiColumns.set((old) => {
      return {
        ...old,
        data: old.data?.map((c) => {
          if (c.sequences?.some((s) => s.sequenceId === seqId)) {
            return { ...c, error: null };
          }
          return c;
        }),
      };
    });
    await updateShopColumn(currentShopId, column.key, {
      error: null,
    });
  }
}

export function getEntityByAnalyticsEntity(entity?: AnalyticsObjectType): AgentEntity | null {
  if (!entity) {
    return null;
  }
  switch (entity) {
    case 'campaign':
      return 'campaign';
    case 'adset':
      return 'adset';
    case 'ad':
      return 'ad';
    default:
      return null;
  }
}
