import { AttributionData } from 'types/attribution';
import axiosInstance from './axiosInstance';
import { UpdateEntityParamsFromClient } from '@tw/types/module/pixel';
import { Providers } from 'types/services';

type UpdateEntityStatusParams = {
  attribution: AttributionData;
  shopId: string;
  providerList: Providers;
  newStatus: string;
  oldStatus: string;
};

export async function updateEntityStatus(args: UpdateEntityStatusParams) {
  const { attribution, providerList, newStatus, oldStatus, shopId } = args;
  let updateURI = '';
  const [serviceId, props] =
    Object.entries(providerList).find(([id, v]) => id === attribution.serviceId) || [];
  if (serviceId && props?.isSensory) {
    updateURI = `/v2/integration/integrations/update-entity`;
  } else if (attribution.serviceId === 'facebook-ads') {
    updateURI = '/v2/facebook-ads/update-ad';
  } else {
    updateURI = `/v2/${attribution.serviceId}/update-entity`;
  }
  const { data } = await axiosInstance.post<UpdateEntityParamsFromClient, any>(updateURI, {
    shopId,
    accountId: attribution.accountId,
    entity: attribution.entity,
    id: attribution.id,
    field: 'status',
    value: newStatus,
    oldValue: oldStatus,
    integrationId: attribution?.integrationId,
  });

  return data;
}
