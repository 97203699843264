import { ServicesIds } from '@tw/types/module/services';
import { Icon, IconProps, Container } from '@tw/ui-components';

export type FullProvidersIds =
  | ServicesIds
  | 'taboola'
  | 'adroll'
  | 'outbrain'
  | 'sms_bump'
  | 'attentive'
  | 'omnisend'
  | 'postscript';

export const PROVIDER_ICON_MAP: { [key in FullProvidersIds]?: IconProps['name'] | undefined } = {
  pixel: 'pixel',
  influencers: 'affluencer',
  shopify: 'shopify',
  gorgias: 'gorgias',
  enquirelabs: 'fairing',
  kno: 'kno',
  recharge: 'recharge',
  benchmarks: 'marketing',
  amazon: 'amazon',
  stripe: 'stripe',
  mountain: 'mountain',
  criteo: 'criteo',
  shipstation: 'shipstation',
  'facebook-ads': 'facebook-circle',
  'google-ads': 'google-ads',
  'tiktok-ads': 'tiktok',
  'snapchat-ads': 'snapchat-circle',
  'pinterest-ads': 'pinterest',
  'shopify-segment': 'shopify',
  'google-analytics': 'google-analytics',
  'triple-whale': 'triple-whale-logo',
  'twitter-ads': 'twitter',
  bing: 'microsoft',
  hubspot: 'hubspot',
  'slack-reporting': 'slack',
  'google-sheets': 'google-sheets',
  taboola: 'taboola',
  adroll: 'adroll',
  outbrain: 'outbrain',
  shipbob: 'shipbob',
  smsbump: 'smsbump',
  sms_bump: 'smsbump',
  intercom: 'intercom',
  attentive: 'attentive',
  omnisend: 'omnisend',
  postscript: 'postscript',
  skio: undefined,
  gcp: undefined,
  posthog: undefined,
  klaviyo: 'klaviyo',
};

export const ProviderIcon: React.FC<{ provider: ServicesIds; size?: number }> = ({
  provider,
  size = 20,
}) => {
  const icon = PROVIDER_ICON_MAP[provider];
  return icon ? (
    <Icon name={icon} size={size} />
  ) : (
    // in case there is no icon for the provider, we will show the first letter of the provider name
    <Container
      w={size}
      h={size}
      mah={size}
      maw={size}
      p={0}
      bg="#9CA3AF"
      style={{
        borderRadius: '50%',
        textTransform: 'capitalize',
        color: 'white',
        textAlign: 'center',
      }}
    >
      {provider[0]}
    </Container>
  );
};
