import { Button } from '@tw/ui-components';
import {
  stepActionType,
  WorkflowStepBase,
  WorkflowStepGenUiPresentation,
  WorkflowStepGenUiReport,
  WorkflowStepInsights,
} from '../types/willyTypes';
import { useEffect, useMemo, useState } from 'react';
import { FlowStepWithText } from './FlowStepWithText';
import { Editor } from '@monaco-editor/react';
import { getRunMessages } from './hooks/useSequenceMessages';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';

type FlowInsightsStepProps = {
  step: (WorkflowStepInsights | WorkflowStepGenUiReport | WorkflowStepGenUiPresentation) &
    WorkflowStepBase;
  toolConfig?: stepActionType;
  depth: number;
  stepChange: (
    newPrompt: (WorkflowStepInsights | WorkflowStepGenUiReport | WorkflowStepGenUiPresentation) &
      WorkflowStepBase,
  ) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
  workflowId?: string;
};

export const FlowInsightsStep: React.FC<FlowInsightsStepProps> = ({
  step,
  toolConfig,
  stepChange,
  depth,
  readOnly,
  setIsPristine,
  workflowId,
}) => {
  const currentShopId = useStoreValue($currentShopId);
  const [mode, setMode] = useState<'edit' | 'view' | 'genUi'>('edit');
  const [genUi, setGenUi] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (step.stepType === 'insights') {
        setGenUi('');
        return;
      }
      if (!step.runIdWithGenUi || !currentShopId || !workflowId) {
        setGenUi('');
        return;
      }
      setError(null);
      setLoading(true);
      try {
        const filteredHistory = await getRunMessages(
          workflowId,
          step.runIdWithGenUi,
          currentShopId,
        );
        const thisInsights = filteredHistory.find((h) => h.stepId === step.id);
        if (!thisInsights) {
          return;
        }

        if (
          thisInsights.stepType !== 'genUiReport' &&
          thisInsights.stepType !== 'genUiPresentation'
        ) {
          return;
        }

        const withoutBackticks = thisInsights.text.replace(/```jsx/g, '').replace(/```/g, '');

        setGenUi(withoutBackticks);
      } catch (error: any) {
        setError(error.message || 'Error loading generative UI code');
      } finally {
        setLoading(false);
      }
    })();
  }, [currentShopId, mode, workflowId, step]);

  const tabs = useMemo(() => {
    const tabs = [
      { label: 'Edit', value: 'edit' },
      { label: 'View', value: 'view' },
    ];
    if (step.stepType !== 'insights' && step.runIdWithGenUi) {
      tabs.push({ label: 'Gen-UI code', value: 'genUi' });
    }
    return tabs;
  }, [step]);

  return (
    <div className="flex flex-col gap-4">
      {!readOnly && (
        <div>
          <Button.Group>
            {tabs.map((item) => (
              <Button
                key={item.value}
                variant={'activator'}
                bg={mode === item.value ? 'gray.2' : undefined}
                onClick={() => {
                  setMode(item.value as 'edit' | 'view' | 'genUi');
                }}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
        </div>
      )}
      {mode === 'genUi' && (
        <div>
          <Editor
            language="javascript"
            height="400px"
            className="rounded overflow-hidden"
            defaultLanguage="javascript"
            theme="light"
            value={loading ? 'Loading...' : error ? error : genUi}
            options={{
              readOnly: true,
              automaticLayout: true,
              minimap: {
                enabled: false,
              },
            }}
            onMount={(editor, monaco) => {}}
          />
        </div>
      )}
      {(mode === 'edit' || mode === 'view') && (
        <FlowStepWithText
          readOnly={readOnly}
          text={step.text || ''}
          textChange={(text) => stepChange({ ...step, text })}
          variableChange={(variable) => {
            const variables = step.variables || [];
            const variableIndex = variables.findIndex((v) => v.key === variable.key);
            if (variableIndex !== -1) {
              variables[variableIndex] = variable;
            } else {
              variables.push(variable);
            }
            stepChange({
              ...step,
              variables,
            });
          }}
          toolConfig={toolConfig}
          setIsPristine={setIsPristine}
          variables={step.variables || []}
          depth={depth}
          editMode={mode === 'edit'}
          withToolbar={!readOnly}
          allowComments
        />
      )}
    </div>
  );
};
