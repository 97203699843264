import './unpaidModal.scss';

import { ActivePMSection } from 'components/UserProfileManagment/Shop/ActivePMSection';
import { loadStripeConfig } from 'ducks/signupJourney';
import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripePromise } from 'routes/auth/SignUp/components/useStripePromise';

import { Button, Flex, Modal, Text } from '@tw/ui-components';
import { Elements } from '@stripe/react-stripe-js';

import { goToPodsView } from 'utils/goToPodsView';
import { useStoreValue } from '@tw/snipestate';
import {
  $unpaidModal,
  closeUnpaidModal,
  openUnpaidModal,
} from '$stores/willy/$upgradePopupManager';
import { $shopSubscription } from '$stores/willy/$subscription';
import { useAppSelector } from '../../../reducers/RootType';
import { closeRedirectNotice } from '../../../ducks/shopIntegrations';
import { updateCurrentShopId } from 'ducks/auth';
import axiosInstance from 'utils/axiosInstance';
import { sleep } from 'utils/sleep';
import { analyticsEvents, foundersDashActions, genericEventLogger } from 'utils/dataLayer';
import { toast } from 'react-toastify';
import { RootState } from '../../../reducers/RootType';

const UnpaidModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = window.location.pathname;
  const unpaidModal = useStoreValue($unpaidModal);
  const [lastInvoiceDate, setLastInvoiceDate] = useState('');
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [isContinue, setIsContinue] = useState<Boolean>(false);
  const { stripePromise } = useStripePromise();
  const subscription = useStoreValue($shopSubscription);
  const isSubscriptionLoading = useAppSelector((state) => state.isSubscriptionLoading);
  const currentShopId = useAppSelector((state: RootState) => state.currentShopId);

  useEffect(() => {
    dispatch(loadStripeConfig());
  }, [dispatch]);

  useEffect(() => {
    if (location === '/store-settings/plans') {
      closeUnpaidModal();
      return;
    }

    let status: 'unpaid' | 'incomplete' | 'past_due' | 'cancelled' | null = null;

    if (subscription?.status === 'unpaid' || subscription?.status === 'past_due') {
      status = subscription.status;
    } else if (subscription?.features?.length === 0) {
      status = 'cancelled';
    }

    if (status) {
      openUnpaidModal(status);
    } else {
      closeUnpaidModal();
    }

    if (subscription?.email) setBillingEmail(subscription.email);
    if (subscription?.last_invoice_date)
      setLastInvoiceDate(moment(subscription.last_invoice_date).format('LL'));
  }, [subscription, location]);

  if (location === '/store-settings/plans' || isSubscriptionLoading || !subscription) {
    return null;
  }

  const changePaymentMethod = () => {
    setIsContinue(true);
  };

  const backToPodsView = () => {
    if (subscription?.status === 'unpaid' || subscription?.features?.length === 0)
      goToPodsView(navigate, dispatch);
    closeUnpaidModal();
  };

  const handleGoToPlans = () => {
    if (subscription?.status === 'unpaid' || subscription?.features?.length === 0) {
      navigate('/store-settings/plans');
      closeUnpaidModal();
    }
  };

  const handleStartFreePlan = async () => {
    if (currentShopId) {
      closeUnpaidModal();

      try {
        const url = `/v2/subscription-manager/subscriptions/shop/make-free/${currentShopId}`;
        const response = await axiosInstance.post<any, { data: boolean }, any>(url, {
          _3_0: 2,
        });

        if (response) {
          dispatch(updateCurrentShopId(currentShopId));
          await sleep(1000);

          genericEventLogger(analyticsEvents.FOUNDERS_DASH, {
            action: foundersDashActions.CREATE_FREE_SHOP,
          });

          window.location.assign('/welcome');
        } else {
          toast('Error while trying to mark the shop as a free shop', { type: 'error' });
        }
      } catch (e) {
        toast('Error while trying to mark the shop as a free shop', { type: 'error' });
        console.error(e);
      }
    }
  };

  return (
    <>
      <Modal.Root
        closeOnClickOutside={false}
        centered
        opened={unpaidModal.opened}
        onClose={subscription?.features?.length === 0 ? handleGoToPlans : backToPodsView}
      >
        <Modal.Overlay opacity={0.7} />
        {unpaidModal.source === 'cancelled' && <Modal.Overlay opacity={0.9} />}
        {unpaidModal.source === 'cancelled' && <Modal.Overlay opacity={0.9} />}
        <Modal.Content className="!flex flex-col">
          <Modal.Header>
            <Modal.Title>
              <Text c="gray.8" fw={600} fz={20}>
                {unpaidModal.source === 'cancelled'
                  ? 'Shop Without Subscription'
                  : unpaidModal.source === 'unpaid'
                    ? 'Reactivate Your Shop'
                    : 'Activate Your Shop'}
              </Text>
            </Modal.Title>
            {unpaidModal.source !== 'cancelled' && <Modal.CloseButton />}
          </Modal.Header>
          <Modal.Body p={14} pb="lg" h={'100%'} w={'600px'}>
            <div className="flex  h-full">
              {unpaidModal.source === 'cancelled' && (
                <div className={'flex flex-1 flex-col justify-between'}>
                  <h4 style={{ marginBottom: '20px' }}>
                    Your shop doesn't have an active subscription. To reactivate your shop, please
                    create a new subscription.
                  </h4>
                </div>
              )}

              {unpaidModal.source === 'incomplete' && (
                <div>
                  <h4 style={{ marginBottom: '20px' }}>
                    Update the credit card with which you are paying for this store's subscriptions.
                    Invoices will continue to be sent to the billing user on file. Your new payment
                    method will be charged immediately for the issued invoice, and your billing
                    cycle will be reset.
                  </h4>
                </div>
              )}

              {unpaidModal.source !== 'incomplete' && unpaidModal.source !== 'cancelled' && (
                <div className="add_user_popup" style={{ textAlign: 'center' }}>
                  {!isContinue && (
                    <div>
                      <h4 style={{ paddingTop: '1.5rem' }}>{`Your invoice which was issued on ${
                        lastInvoiceDate || '?'
                      } has not been paid.
                We have not been able to charge your credit card since then.`}</h4>
                      <h4>{`Please enter a new payment method to access your shop.`}</h4>
                      <h4
                        style={{ marginBottom: '20px', marginTop: '1rem' }}
                      >{`Your account is associated with the billing email address of: ${
                        billingEmail || ''
                      }`}</h4>
                    </div>
                  )}
                  {isContinue && (
                    <div>
                      <h4 style={{ marginBottom: '20px' }}>
                        Update the credit card with which you are paying for this store's
                        subscriptions. Invoices will continue to be sent to the billing user on
                        file. Your new payment method will be charged immediately for the issued
                        invoice, and your billing cycle will be reset.
                      </h4>
                      <div className="change-payment">
                        {stripePromise && (
                          <Elements stripe={stripePromise}>
                            <ActivePMSection isUnpaid={true} />
                          </Elements>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
          {!isContinue && (
            <Modal.Footer>
              <Flex justify="right" px={'md'}>
                {unpaidModal.source === 'cancelled' && (
                  <Flex gap="md">
                    <Button onClick={handleGoToPlans}>Go to Plans</Button>
                    <Button variant="secondary" onClick={handleStartFreePlan}>
                      Start Free Plan
                    </Button>
                  </Flex>
                )}
                {unpaidModal.source === 'incomplete' && (
                  <div className="change-payment">
                    {stripePromise && (
                      <Elements stripe={stripePromise}>
                        <ActivePMSection isUnpaid={true} />
                      </Elements>
                    )}
                  </div>
                )}

                {unpaidModal.source !== 'incomplete' && unpaidModal.source !== 'cancelled' && (
                  <div className={'flex gap-4'}>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        backToPodsView();
                      }}
                    >
                      Back to Pods View
                    </Button>
                    <Button
                      onClick={() => {
                        changePaymentMethod();
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </Flex>
            </Modal.Footer>
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default UnpaidModal;
