export const notificationColors = {
  blue: {
    background: 'var(--primary-600-Brand, #0C70F2)',
    color: 'white',
  },
  blueWithRadius: {
    borderRadius: '6px',
    background: 'var(--primary-600-Brand, #0C70F2)',
    color: 'white',
  },
  white: {
    borderRadius: '6px',
    border: '1px solid var(--primary-200, #BAD6FB)',
    background: 'var(--primary-50, #F5F9FF)',
    color: 'var(--primary-800, #0A4DA6)',
  },
  yellow: {
    borderRadius: '6px',
    border: '1px solid var(--yellow-200, #FDE68A)',
    background: 'var(--yellow-50, #FFFBEB)',
    color: 'var(--yellow-700, #B45309)',
  },
};

export const notificationStyles = {
  SPECIAL_OFFER: notificationColors.blue,
  RECOMMENDATION: notificationColors.blue,
  SUGGEST_FEATURE: notificationColors.white,
  SUGGEST_UPGRADE: notificationColors.white,
  SUGGEST_INTEGRATION: notificationColors.white,
  INTEGRATION_ALERT: notificationColors.yellow,
  TRACKING_ALERT: notificationColors.yellow,
  PERFORMANCE_ALERT: notificationColors.yellow,
  TRIAL_COUNTDOWN: notificationColors.blue,
};
