import './billing.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import Stripe from 'stripe';
import axiosInstance from 'utils/axiosInstance';
import { Icon } from '@shopify/polaris';
import { Anchor, Button, Flex, Text } from '@tw/ui-components';
import ChangePaymentMethodModal from './changePaymentMethodModal';
import { BankMajor } from '@shopify/polaris-icons';
import { useStripePromise } from 'routes/auth/SignUp/components/useStripePromise';
import { Elements } from '@stripe/react-stripe-js';
import { subscriptionPlan } from '@tw/types/module/services/subscription-manager';
import { SelectedAddon } from './Addons/AddonTypes';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '$stores/willy/$subscription';
import { setPaymentMethod } from 'ducks/signupJourney';

type AppProps = {
  isUnpaid?: boolean;
  wrapperClass?: string;
  updateButtonPlain?: boolean;
  plan?: subscriptionPlan;
  addons?: SelectedAddon[];
  couponInUse?: any;
  setSkipAddonsStep?: (skipAddonsStep: boolean) => void;
  paymentMethodId?: string;
  setPaymentMethodIdLocal?: (paymentMethodId: string) => void;
  setAddress?: (address: {
    postal_code?: string;
    country?: string;
    city?: string;
    line1?: string;
    line2?: string;
  }) => void;
};

export const ActivePMSection: React.FC<AppProps> = ({
  isUnpaid,
  wrapperClass,
  updateButtonPlain,
  plan,
  addons,
  couponInUse,
  setSkipAddonsStep,
  paymentMethodId,
  setPaymentMethodIdLocal,
  setAddress,
}) => {
  const dispatch = useDispatch();
  const { stripePromise } = useStripePromise();
  const [customerPaymentsMethod, setCustomerPaymentsMethod] = useState<
    Stripe.PaymentMethod | undefined
  >(undefined);
  const currentShop = useSelector((state: RootState) => state.currentShop);
  const subscription = useSelector((state: RootState) => state.subscription);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any>('');
  const isFreeShop = useStoreValue($isFreeShop);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const createSetUpIntent = async () => {
      const { data } = await axiosInstance.post(
        'v2/subscription-manager/subscriptions/create-setupIntent',
      );
      setOptions({ clientSecret: data });
    };
    createSetUpIntent();
  }, [isUpdateModalOpen]);

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        setLoading(true);
        if (
          (isFreeShop ||
            subscription?.status === 'canceled' ||
            subscription?.features?.length === 0) &&
          paymentMethodId
        ) {
          const url = `v2/subscription-manager/payments/paymentMethod/${paymentMethodId}`;
          const response = await axiosInstance.get<Stripe.PaymentMethod>(url);
          if (response.status < 400 && response.data) {
            setCustomerPaymentsMethod(response.data);
            dispatch(setPaymentMethod(response.data));
          }
        } else if (!isFreeShop && currentShop?.payment_method) {
          const url = `v2/subscription-manager/payments/paymentMethod/${currentShop.payment_method}`;
          const response = await axiosInstance.get<Stripe.PaymentMethod>(url);
          if (response.status < 400 && response.data) {
            setCustomerPaymentsMethod(response.data);
            dispatch(setPaymentMethod(response.data));
          }
        } else if (!isFreeShop && currentShop.customerId) {
          const url = `v2/subscription-manager/payments/customer/${currentShop.customerId}`;
          const { data } = await axiosInstance.get<Stripe.PaymentMethod[]>(url);
          if (data?.length) {
            setCustomerPaymentsMethod(data[0]);
            dispatch(setPaymentMethod(data[0]));
          }
        }
      } catch (error) {
        console.error(`Error fetching payment method:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentMethod();
  }, [
    paymentMethodId,
    isFreeShop,
    currentShop,
    dispatch,
    subscription?.status,
    subscription?.features,
  ]);

  return (
    <div className={`billing-settings ${wrapperClass}`}>
      {loading ? (
        <div className="flex-container middle center">
          <Text>Loading payment method...</Text>
        </div>
      ) : customerPaymentsMethod ? (
        <div className={'flex-container middle card-select-settings'}>
          <div className={'flex-1'}>
            <div className={'flex-container middle'}>
              {customerPaymentsMethod.card && (
                <Flex gap="md">
                  <img
                    className={'card-icon'}
                    src={`/signupJourney/cardIcons/${customerPaymentsMethod.card?.brand}.svg`}
                    alt=""
                  />
                  <Text color="gray.9" weight={500}>
                    {` **** ${customerPaymentsMethod.card?.last4}`}
                  </Text>
                  <Text color="gray.9" weight={500}>
                    {`EXP. ${customerPaymentsMethod.card?.exp_month}/ ${customerPaymentsMethod.card?.exp_year}`}
                  </Text>
                </Flex>
              )}
              {customerPaymentsMethod.us_bank_account && (
                <Flex gap="md">
                  <div className={'card-icon'}>
                    <Icon source={BankMajor} color="subdued" />
                  </div>
                  <Text color="gray.9" weight={500}>
                    {` **** ${customerPaymentsMethod.us_bank_account?.last4}`}
                  </Text>
                  <Text color="gray.9" weight={500}>
                    {`${customerPaymentsMethod.us_bank_account?.bank_name}`}
                  </Text>
                </Flex>
              )}
            </div>
          </div>
          <Anchor
            onClick={() => {
              setIsUpdateModalOpen(true);
            }}
            underline="never"
            fw={600}
          >
            Change
          </Anchor>
        </div>
      ) : (
        <Button
          onClick={() => {
            setIsUpdateModalOpen(true);
          }}
          fullWidth
          variant="primary"
        >
          Add Payment Method
        </Button>
      )}
      {isUpdateModalOpen && (
        <>
          {stripePromise && Object.keys(options).length && (
            <Elements stripe={stripePromise} options={options}>
              <ChangePaymentMethodModal
                setIsModalOpen={setIsUpdateModalOpen}
                isOpen={isUpdateModalOpen}
                isUnpaid={isUnpaid}
                plan={plan}
                addons={addons}
                couponInUse={couponInUse}
                setSkipAddonsStep={setSkipAddonsStep}
                paymentMethodId={paymentMethodId}
                setPaymentMethodIdLocal={setPaymentMethodIdLocal}
                setAddress={setAddress}
              />
            </Elements>
          )}
        </>
      )}
    </div>
  );
};
