import { Anchor, Icon, Loader, Tabs, Text } from '@tw/ui-components';
import { WillySearchInput } from '../WillySearchInput';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import useDebounce from 'utils/useDebounce';
import { AgentLibraryCard } from './AgentLibraryCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgentCollection, Column, ColumnName, WillyDataSequence } from '../types/willyTypes';
import { ColumnLibraryCard } from './ColumnLibraryCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { Checkout } from './Checkout';
import { sequencesActions, genericEventLogger, analyticsEvents } from 'utils/dataLayer';

const tabs = [
  { id: 'agents', label: 'Agents' },
  { id: 'ai_columns', label: 'Column Agents' },
];

export type AgentCheckoutItem = { id: string; type: 'agent' | 'column' };

export const AgentLibraryCollection: React.FC<{ collection: AgentCollection }> = ({
  collection,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const currentShopId = useStoreValue($currentShopId);

  const activeTab: string | null = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    return tab || 'agents';
  }, [search]);

  const currentTab = useMemo(() => tabs.find((t) => t.id === activeTab) || tabs[0], [activeTab]);

  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchPrompt, 1000);

  const [aiColumns, setAiColumns] = useState<Column<ColumnName>[]>([]);
  const [agents, setAgents] = useState<WillyDataSequence[]>([]);
  const [agentSections, setAgentSections] = useState<
    {
      id: string;
      title: string;
      subtitle?: string;
      agents: WillyDataSequence[];
      hasMore: boolean;
      loading?: boolean;
    }[]
  >([]);
  const [columnSections, setColumnSections] = useState<
    {
      id: string;
      title: string;
      subtitle?: string;
      agents: Column<ColumnName>[];
      hasMore: boolean;
      loading?: boolean;
    }[]
  >([]);
  const [totalAiColumns, setTotalAiColumns] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);

  const [aiColumnsPage, setAiColumnsPage] = useState(1);
  const [agentsPage, setAgentsPage] = useState(1);

  const [loadingMoreAiColumns, setLoadingMoreAiColumns] = useState(true);
  const [loadingMoreAgents, setLoadingMoreAgents] = useState(true);

  const [loadingAgentSections, setLoadingAgentSections] = useState(true);
  const [loadingColumnSections, setLoadingColumnSections] = useState(true);

  const [agentsAddedToCart, setAgentsAddedToCart] = useState<AgentCheckoutItem[]>([]);

  const [openCheckout, setOpenCheckout] = useState(false);

  const getPaginatedAgents = useCallback(
    async (page, searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingMoreAgents(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-paginated-agents`,
          {
            collection: collection.id,
            filter: searchTerm,
            page: page,
            shopId: currentShopId,
          },
        );

        setAgents((prev) => (page === 1 ? data.agents : [...prev, ...data.agents]));
        setTotalAgents(data.totalAgents);
        setAgentsPage(page + 1);
        setLoadingMoreAgents(false);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setLoadingMoreAgents(false);
      }
    },
    [collection.id, currentShopId],
  );

  const getPaginatedAiColumns = useCallback(
    async (page, searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingMoreAiColumns(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-paginated-columns`,
          {
            collection: collection.id,
            filter: searchTerm,
            page: page,
            shopId: currentShopId,
          },
        );

        setAiColumns((prev) => (page === 1 ? data.agents : [...prev, ...data.agents]));
        setTotalAiColumns(data.totalAgents);
        setAiColumnsPage(page + 1);
        setLoadingMoreAiColumns(false);
      } catch (error) {
        console.error('Error fetching AI columns:', error);
        setLoadingMoreAiColumns(false);
      }
    },
    [collection.id, currentShopId],
  );

  const getAgentSections = useCallback(
    async (searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingAgentSections(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-agents-in-sections`,
          {
            collection: collection.id,
            filter: searchTerm,
            shopId: currentShopId,
          },
        );

        setAgentSections(data.sections);
        setLoadingAgentSections(false);
      } catch (error) {
        console.error('Error fetching agent sections:', error);
        setLoadingAgentSections(false);
      }
    },
    [collection.id, currentShopId],
  );

  const getColumnSections = useCallback(
    async (searchTerm = '') => {
      if (!currentShopId) return;

      try {
        setLoadingColumnSections(true);
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-columns-in-sections`,
          {
            collection: collection.id,
            filter: searchTerm,
            shopId: currentShopId,
          },
        );

        setColumnSections(data.sections);
        setLoadingColumnSections(false);
      } catch (error) {
        console.error('Error fetching column sections:', error);
        setLoadingColumnSections(false);
      }
    },
    [collection.id, currentShopId],
  );

  const paginateAgentSection = useCallback(
    async (section) => {
      if (!currentShopId) return;

      try {
        setLoadingAgentSections(true);
        setAgentSections((prev) => {
          const newSections = [...prev];
          const index = newSections.findIndex((s) => s.id === section);
          newSections[index] = {
            ...newSections[index],
            loading: true,
          };
          return newSections;
        });
        const lastId = agentSections.find((s) => s.id === section)?.agents.slice(-1)[0].id;
        const { data } = await axiosInstance.post(
          `/v2/sequences/collections/get-paginated-agents-in-section`,
          {
            collection: collection.id,
            filter: debouncedSearchTerm,
            section: section,
            lastId: lastId,
            shopId: currentShopId,
          },
        );

        setAgentSections((prev) => {
          const newSections = [...prev];
          const index = newSections.findIndex((s) => s.id === section);
          newSections[index] = {
            ...newSections[index],
            agents: [...newSections[index].agents, ...data.agents],
            hasMore: data.hasMore,
            loading: false,
          };
          return newSections;
        });
        setLoadingAgentSections(false);
      } catch (error) {
        console.error('Error fetching agent sections:', error);
        setLoadingAgentSections(false);
      }
    },
    [agentSections, collection.id, currentShopId, debouncedSearchTerm],
  );

  useEffect(() => {
    genericEventLogger(analyticsEvents.SEQUENCES, {
      action: sequencesActions.VIEW_AGENT_COLLECTION,
      collection_id: collection.id,
      collection_name: collection.name,
    });
  }, [collection.id, collection.name]);

  useEffect(() => {
    getAgentSections('');
    getPaginatedAgents(1, '');
    getColumnSections('');
    getPaginatedAiColumns(1, '');
  }, [getPaginatedAgents, getPaginatedAiColumns, getAgentSections, getColumnSections]);

  useEffect(() => {
    getAgentSections(debouncedSearchTerm);
    getPaginatedAgents(1, debouncedSearchTerm);
    setAgentsPage(1);
  }, [debouncedSearchTerm, getPaginatedAgents, getAgentSections]);

  useEffect(() => {
    getColumnSections(debouncedSearchTerm);
    getPaginatedAiColumns(1, debouncedSearchTerm);
    setAiColumnsPage(1);
  }, [debouncedSearchTerm, getPaginatedAiColumns, getColumnSections]);

  const loadNextPage = useCallback(async () => {
    if (!currentShopId) return;

    if (activeTab === 'agents') {
      if (loadingMoreAgents) return; // Prevent duplicate calls
      try {
        await getPaginatedAgents(agentsPage, debouncedSearchTerm);
      } catch (error) {
        console.error('Error loading more agents:', error);
      }
    } else if (activeTab === 'ai-columns') {
      if (loadingMoreAiColumns) return; // Prevent duplicate calls
      try {
        await getPaginatedAiColumns(aiColumnsPage, debouncedSearchTerm);
      } catch (error) {
        console.error('Error loading more AI columns:', error);
      }
    }
  }, [
    currentShopId,
    activeTab,
    loadingMoreAgents,
    agentsPage,
    aiColumnsPage,
    debouncedSearchTerm,
    getPaginatedAgents,
    getPaginatedAiColumns,
    loadingMoreAiColumns,
  ]);

  const onSearchChange = (v) => {
    setSearchPrompt(v);
  };

  const toggleItemInCart = (id, type) => {
    setAgentsAddedToCart((prev) => {
      const exists = prev.some((item) => item.id === id && item.type === type);
      return exists
        ? prev.filter((item) => item.id !== id || item.type !== type)
        : [...prev, { id, type }];
    });
  };

  const bgUrl = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${collection.color}_collection_header.png`;

  return (
    <div className="flex flex-col">
      <div className="w-full md:w-[10%] mx-4 md:mx-12 mt-8 md:mt-12">
        <div
          className="back-button flex align-items-center gap-5 cursor-pointer"
          onClick={() => {
            openCheckout ? setOpenCheckout(false) : navigate(`/chat/agents/collections`);
          }}
        >
          <Icon name={'chevron-left-minor'} color="one.5" size={14} />
          <Text color="one.6" fz={14} fw={500}>
            Back
          </Text>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className={`mx-auto p-4 md:my-12 md:px-0 md:mx-0 pt-0 h-full flex flex-col gap-[35px] w-full md:max-w-[85%]`}
        >
          {!openCheckout ? (
            <>
              <div
                className={`p-[25px]  mt-8 md:mt-0  w-full rounded-xl flex items-center gap-2 justify-between  bg-center bg-cover bg-no-repeat`}
                style={{ backgroundImage: `url(${bgUrl})` }}
              >
                <div className="flex flex-col w-full md:w-[55%] justify-between gap-7 h-full">
                  <Text color="white" fw={600} fz={24}>
                    {collection.tagline}
                  </Text>
                  <Text color="white" fz={14}>
                    {collection.subTagline}
                  </Text>
                  {/* <div>
                <Button variant="white" size="sm">
                  Get full collection
                </Button>
              </div> */}
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <Tabs
                  value={activeTab}
                  onChange={(tab) => {
                    navigate(
                      {
                        pathname: `/chat/agents/collections/${collection.id}`,
                        search: `?tab=${tab}`,
                      },
                      { replace: true },
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  <Tabs.List>
                    {tabs.map((tab) => {
                      return (
                        <Tabs.Tab key={tab.id} value={tab.id}>
                          <Text
                            color={currentTab.id === tab.id ? 'one.6' : 'gray.5'}
                            fw={500}
                            fz={14}
                          >
                            {tab.label}
                          </Text>
                        </Tabs.Tab>
                      );
                    })}
                  </Tabs.List>
                </Tabs>
                {agentsAddedToCart.length == 0 ? (
                  <Text fw={600} fz="sm" color="gray.5">
                    Calculate Agents Costs ({agentsAddedToCart.length})
                  </Text>
                ) : (
                  <Anchor fw={600} fz="sm" onClick={() => setOpenCheckout(true)} disabled={true}>
                    Calculate Agents Costs ({agentsAddedToCart.length})
                  </Anchor>
                )}
              </div>
              <WillySearchInput
                value={searchPrompt}
                onChange={(e) => onSearchChange(e)}
                placeholder="Search"
                className="!p-0 overflow-visible w-full"
              />
              {activeTab === 'ai_columns' ? (
                <Text>
                  Column agents can be added to your pixel table to facilitate quick and informed
                  decision making.
                </Text>
              ) : (
                <Text>
                  Agents can be accessed throughout Triple Whale and are specially designed for
                  complex analysis.
                </Text>
              )}
              {activeTab === 'ai_columns' ? (
                <div className={`flex flex-col gap-10 ${!!columnSections.length ? 'pb-20' : ''}`}>
                  {columnSections.map((section) => {
                    return (
                      <Fragment key={section.id}>
                        {!!section.agents.length && (
                          <div className="flex flex-col">
                            <Text fz={22} fw={600}>
                              {section.title}
                            </Text>
                            <Text fz={18}>{section.subtitle}</Text>
                            <div className="flex flex-col md:grid grid-cols-2 gap-6 pt-10">
                              {section.agents?.map((column) => {
                                return (
                                  <Fragment key={column.key}>
                                    <ColumnLibraryCard
                                      column={column}
                                      // onAddToCart={(id) => toggleItemInCart(id, 'column')}
                                      // itemInCart={agentsAddedToCart.some(
                                      //   (item) => item.id === column.key && item.type === 'column',
                                      // )}
                                    />
                                  </Fragment>
                                );
                              })}
                              {section.loading && <Loader size="sm" />}
                              {section.hasMore && !section.loading && (
                                <Anchor onClick={() => paginateAgentSection(section.id)} disabled>
                                  See More
                                </Anchor>
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              ) : (
                <div className={`flex flex-col gap-10 ${agentSections.length ? 'pb-20' : ''}`}>
                  {agentSections.map((section) => {
                    return (
                      <Fragment key={section.id}>
                        {!!section.agents.length && (
                          <div className="flex flex-col">
                            <Text fz={22} fw={600}>
                              {section.title}
                            </Text>
                            <Text fz={18}>{section.subtitle}</Text>
                            <div className="flex flex-col md:grid grid-cols-2 gap-6 pt-10">
                              {section.agents?.map((agent) => {
                                return (
                                  <Fragment key={agent.id}>
                                    <AgentLibraryCard
                                      agent={agent}
                                      onAddToCart={(id) => toggleItemInCart(id, 'agent')}
                                      itemInCart={agentsAddedToCart.some(
                                        (item) => item.id === agent.id && item.type === 'agent',
                                      )}
                                    />
                                  </Fragment>
                                );
                              })}
                              {section.loading && <Loader size="sm" />}
                              {section.hasMore && !section.loading && (
                                <Anchor onClick={() => paginateAgentSection(section.id)} disabled>
                                  See More
                                </Anchor>
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <InfiniteScroll
                scrollableTarget="scroll-container"
                scrollThreshold={0.8}
                dataLength={activeTab === 'agents' ? agents.length : aiColumns.length}
                hasMore={
                  activeTab === 'agents'
                    ? agents.length < totalAgents
                    : aiColumns.length < totalAiColumns
                }
                next={() => {
                  loadNextPage();
                }}
                loader={
                  <div className="flex items-center justify-center h-12 py-20">
                    <Loader size="sm" />
                  </div>
                }
              >
                <div className="flex flex-col md:grid grid-cols-2 gap-6">
                  {activeTab === 'ai_columns' && (
                    <>
                      {aiColumns.map((column) => {
                        return (
                          <Fragment key={column.key}>
                            <ColumnLibraryCard
                              column={column}
                              // onAddToCart={(id) => toggleItemInCart(id, 'column')}
                              // itemInCart={agentsAddedToCart.some(
                              //   (item) => item.id === column.key && item.type === 'column',
                              // )}
                            />
                          </Fragment>
                        );
                      })}
                      {aiColumns.length === 0 && (
                        <>
                          {loadingMoreAiColumns ? (
                            <div className="flex items-center justify-center h-12 py-20">
                              <Loader size="sm" />
                            </div>
                          ) : (
                            <Text>No Agents</Text>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {activeTab === 'agents' && (
                    <>
                      {agents.map((agent) => {
                        return (
                          <Fragment key={agent.id}>
                            <AgentLibraryCard
                              agent={agent}
                              onAddToCart={(id) => toggleItemInCart(id, 'agent')}
                              itemInCart={agentsAddedToCart.some(
                                (item) => item.id === agent.id && item.type === 'agent',
                              )}
                            />
                          </Fragment>
                        );
                      })}
                      {agents.length === 0 && (
                        <>
                          {loadingMoreAgents ? (
                            <div className="flex items-center justify-center h-12 py-20">
                              <Loader size="sm" />
                            </div>
                          ) : (
                            <Text>No Agents</Text>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <Checkout agentIds={agentsAddedToCart} />
          )}
        </div>
      </div>
    </div>
  );
};
