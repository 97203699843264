import { Grid } from '@tw/ui-components';
import { FC, useMemo } from 'react';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { ChooseTableSection } from '../WillyWidgetEditor/TableBuilder/ChooseTableSection';
import { useStoreValue } from '@tw/snipestate';
import { $tables } from '$stores/willy/$tables';
import { generateShortDescription } from '../WillyWidgetEditor/TableBuilder/ColumnTooltip';
import { WillyMetric } from '../types/willyTypes';
import { createWillyMetricFromColumn } from '../utils/willyUtils';
import { AddMetricsSections } from '../WillyWidgetEditor/TableBuilder/AddMetricsSections';
import { AddFiltersSection } from '../WillyWidgetEditor/TableBuilder/AddFiltersSection';

type FlowStepWithTableBuilderProps = {
  builder: BuilderTable | null;
  setBuilder: (builder: BuilderTable) => void;
};

const defaultBuilder: BuilderTable = {
  table: '',
  columns: [],
  filters: [],
  queryId: '',
};

export const FlowStepWithTableBuilder: FC<FlowStepWithTableBuilderProps> = ({
  builder,
  setBuilder,
}) => {
  const finalBuilder = useMemo(() => {
    if (!builder) {
      return defaultBuilder;
    }
    return builder;
  }, [builder]);

  const tables = useStoreValue($tables);

  const currentTable = useMemo(() => {
    return tables.find((t) => t.id === finalBuilder?.table);
  }, [tables, finalBuilder]);

  const columns = useMemo(() => {
    return currentTable?.columns ?? [];
  }, [currentTable]);

  const columnsAsMetrics: WillyMetric[] = useMemo(() => {
    const cols = columns
      .map((c) => {
        return createWillyMetricFromColumn(c, currentTable);
      })
      .map((m) => ({
        ...m,
        active: finalBuilder?.columns.some((b) => b.columnId === m.key),
      }));

    return cols;
  }, [finalBuilder?.columns, columns, currentTable]);

  if (!finalBuilder) {
    return null;
  }

  return (
    <Grid gutter="lg" pt="md">
      <Grid.Col span={4} pt="md">
        <ChooseTableSection
          value={finalBuilder?.table}
          data={tables.map((t) => ({
            label: t.name,
            value: t.id,
            description: generateShortDescription(t.description),
            readmeUrl: t.readmeUrl,
          }))}
          onChange={(val) => {
            if (!val) {
              return;
            }
            setBuilder({
              ...finalBuilder,
              table: val,
              filters: [],
              columns: [],
            });
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <AddMetricsSections
          disabled={!finalBuilder.table}
          builder={finalBuilder}
          setBuilder={setBuilder}
          columnsAsMetrics={columnsAsMetrics}
          columns={columns}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <AddFiltersSection
          builder={finalBuilder}
          setBuilder={setBuilder}
          disabled={!finalBuilder.table}
          columnsAsMetrics={columnsAsMetrics}
          columns={columns}
          currentTable={currentTable}
        />
      </Grid.Col>
    </Grid>
  );
};
