import { Flex, Text } from '@tw/ui-components';

import { stepActionType, WorkflowStepVariable } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $shopUsers } from '$stores/$users';
import { useMemo } from 'react';
import { FlowStepWithText } from './FlowStepWithText';

type FlowSendToPushNotificationProps = {
  readOnly: boolean;
  text: string;
  variables: WorkflowStepVariable[];
  userId: string;
  textChange: (text: string) => void;
  variablesChange: (variables: WorkflowStepVariable[]) => void;
  toolConfig?: stepActionType;
  depth: number;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowSendToPushNotification: React.FC<FlowSendToPushNotificationProps> = (props) => {
  const {
    readOnly,
    text,
    variables,
    textChange,
    variablesChange,
    userId,
    toolConfig,
    depth,
    setIsPristine,
  } = props;
  const { data: shopUsers } = useStoreValue($shopUsers);
  const shopUsersMap = useMemo(() => {
    return shopUsers?.reduce(
      (acc, user) => {
        acc[user.id] = user.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [shopUsers]);

  const user = shopUsersMap?.[userId] || '';
  return (
    <Flex direction="column" gap="sm">
      <Text>Send output as push notifiction {user ? `to ${user}` : ''}</Text>
      <FlowStepWithText
        readOnly={readOnly}
        text={text}
        textChange={textChange}
        variableChange={(variable) => {
          const variableIndex = variables.findIndex((v) => v.key === variable.key);
          if (variableIndex !== -1) {
            variables[variableIndex] = variable;
          } else {
            variables.push(variable);
          }
          variablesChange(variables);
        }}
        toolConfig={toolConfig}
        setIsPristine={setIsPristine}
        variables={variables}
        depth={depth}
        editMode={true}
        withToolbar={false}
        allowComments
      />
    </Flex>
  );
};
