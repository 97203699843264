import { FC, useMemo, useState } from 'react';
import { Flex, Icon, Select, Text } from '@tw/ui-components';
import {
  shelfCategoryType,
  stepActionType,
  WorkflowStep,
  WorkflowStepTypes,
} from '../types/willyTypes';
import { WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';

type SelectStepTypeProps = {
  step: WorkflowStep;
  stepChange: (step: WorkflowStep) => void;
  toolConfig?: stepActionType;
  categoryConfig?: shelfCategoryType;
  disabledSteps: Partial<Record<WorkflowStepTypes, string>>;
  disabled?: boolean;
};

export const SelectStepType: FC<SelectStepTypeProps> = ({
  step,
  stepChange,
  toolConfig,
  categoryConfig,
  disabledSteps,
  disabled,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const options = useMemo(() => {
    return WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => step.category === categoryConfig?.id).map(
      (step) => ({
        value: step.type,
        label: `${step.name} ${step.isDefault ? '(Default)' : ''}`,
        disabled: !!disabledSteps[step.type],
      }),
    );
  }, [categoryConfig?.id, disabledSteps]);

  return isOpened ? (
    <Select
      disabled={disabled}
      w="100%"
      value={step.stepType}
      dropdownOpened={isOpened}
      onChange={(value) => {
        if (value) {
          setIsOpened(false);
          stepChange({
            ...step,
            stepType: value as any,
          });
        }
      }}
      data={options}
      onClick={() => {
        setIsOpened((prev) => !prev);
      }}
      comboboxProps={{ withinPortal: false }}
    />
  ) : (
    <Flex
      bg={`${categoryConfig?.color ?? 'one'}.${['one', 'two'].includes(categoryConfig?.color || '') ? '1' : '2'}`}
      borderRadius="8px"
      cursor="pointer"
      h={36}
      onClick={() => {
        if (disabled) {
          return;
        }
        setIsOpened(true);
      }}
      gap="sm"
      align="center"
      justify="center"
      w="100%"
    >
      <Text color="gray.8" weight={500} size="sm">
        {toolConfig?.name} {toolConfig?.isDefault ? '(Default)' : ''}
      </Text>
      <Icon name="chevron-down" size={12} />
    </Flex>
  );
};
