import React, { FC, useCallback, useState } from 'react';
import { Banner, Button, Modal, Tooltip } from '@shopify/polaris';
import { Anchor } from '@tw/ui-components';
import { toast } from 'react-toastify';

export const ShopifyActivationPopup: FC<{
  isOpen: boolean;
  onClose: () => void;
  toggleStatus: () => Promise<void>;
  isInstalled: boolean;
}> = ({ isOpen, onClose, toggleStatus, isInstalled }) => {
  const [isActivateButtonLoading, setIsActivateButtonLoading] = useState<boolean>(false);

  const toggleSurveyActivation = useCallback(async () => {
    setIsActivateButtonLoading(true);
    try {
      await toggleStatus();
      onClose();
      toast.success(`survey ${isInstalled ? 'deactivated' : 'activated'} successfully`);
    } catch (error) {
      toast.error(`Failed to ${isInstalled ? 'Deactivate' : 'Activate'} Survey`);
    } finally {
      setIsActivateButtonLoading(false);
    }
  }, [isInstalled, toggleStatus]);
  return (
    <Modal
      title="Install Post Purchase Survey"
      open={isOpen}
      onClose={onClose}
      primaryAction={{
        content: 'Close',
        onAction: () => onClose(),
      }}
    >
      <Modal.Section>
        {'1. Install the survey in your store. You can find installation methods '}
        <Anchor
          as="a"
          target="_blank"
          fw={600}
          underline="always"
          href="https://kb.triplewhale.com/en/articles/6486354-post-purchase-survey-for-shopify-stores#h_2d647fe586"
        >
          here
        </Anchor>
      </Modal.Section>
      <Modal.Section>
        <p className="pb-2">
          2. IMPORTANT: After installing and editing your survey, you must click the "Activate
          Survey" button below to enable the survey to display.
        </p>
        <br />
        <Button loading={isActivateButtonLoading} onClick={toggleSurveyActivation} primary>
          {isInstalled ? 'Deativate' : 'Activate'} Survey
        </Button>
      </Modal.Section>
    </Modal>
  );
};
