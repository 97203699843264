import { Skeleton, Text } from '@tw/ui-components';
import { WillyUserAvatar } from './WillyUserAvatar';
import { useMemo } from 'react';
import { $shopUsers } from '$stores/$users';
import { useStoreValue } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type UserAndDateProps = {
  user?: string;
  loadingUser?: boolean;
  date: string | null | undefined;
  forceSmallVersion?: boolean;
  showAvatar?: boolean;
};
export const UserAndDate: React.FC<UserAndDateProps> = ({
  user,
  loadingUser,
  date,
  forceSmallVersion,
  showAvatar,
}) => {
  const isSmall = useIsSmall();
  const useSmallVersion = forceSmallVersion || isSmall;

  return (
    <div className={`flex items-center ${useSmallVersion ? 'gap-2' : 'gap-4'}`}>
      {showAvatar && (
        <div className={`${useSmallVersion ? 'hidden' : 'block'}`}>
          <WillyUserAvatar userId={user} userName={user} size="small" round={true} />
        </div>
      )}
      <div className="flex flex-col gap-0 leading-snug">
        <span className="line-clamp-1 text-[12px] no-underline text-black overflow-hidden">
          {loadingUser ? <Skeleton width="100px" /> : user || 'Unknown user'}
        </span>
        {!!date && (
          <span className="line-clamp-2 overflow-hidden text-[12px] text-gray-500 whitespace-nowrap">
            {date}
          </span>
        )}
      </div>
    </div>
  );
};
