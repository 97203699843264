export enum EntityType {
  Pod = 'pod',
  Store = 'store',
}

export enum Role {
  Owner = 'owner',
  User = 'user',
  Admin = 'admin',
}
