import {
  type AnalyticsObjectType,
  type CalculatedMetrics,
  type ExpandedCalculatedPixelMetrics,
  type RawMetrics,
  type RawPixelMetrics,
} from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import {
  type AttributionBreakdown,
  type AttributionData,
  type AttributionMainResponse,
  type AttributionRealtimeMessageData,
  type AttributionStatsRequest,
} from 'types/attribution';
import { ALL_SOURCES_ID } from 'constants/types';
import axiosInstance from './axiosInstance';
import allServices from 'constants/services';
import moment from '@tw/moment-cached/module/timezone';
import { SourceTypesWithExtra } from 'types/services';

export const mapEntityToBreakdown: Partial<Record<AnalyticsObjectType, AttributionBreakdown>> = {
  campaign: 'campaignId',
  channel: 'source',
  adset: 'adsetId',
  ad: 'adId',
};

// type ExportFormat = {
//   Day: string;
//   Channel: string;
//   Campaign: string;
//   Adset: string;
//   Ad: string;
// };

// export const exportAttributionToCsv = async (attributions: AttributionData[]) => {
//   const allDataToExport: AttributionData[] = [];
// };

export function safeDivide(numerator: number, denominator: number) {
  if (!numerator || !denominator) {
    return 0;
  }
  if (!isFinite(numerator) || !isFinite(denominator)) {
    return 0;
  }
  return numerator / denominator || 0;
}

export function addCalculatedStats(
  rawStats: RawMetrics & RawPixelMetrics,
): Partial<AttributionData> {
  const {
    spend,
    pixelConversionValue,
    pixelCogs,
    clicks,
    impressions,
    conversionValue,
    pixelNcPurchases,
    pixelNcConversionValue,
    pixelPurchases,
    purchases,
    pixelVisitors,
    pixelUniqueVisitors,
    pixelUniqueAtc,
    pixelEmailSignup,
    pixelNewVisitors,
  } = rawStats;
  const calculatedPixelMetrics: ExpandedCalculatedPixelMetrics = {
    pixelRoas: safeDivide(pixelConversionValue, spend!),
    pixelNcRoas: safeDivide(pixelNcConversionValue, spend!),
    pixelCpa: safeDivide(spend!, pixelPurchases),
    pixelAov: safeDivide(pixelConversionValue, pixelPurchases),
    pixelNcAov: safeDivide(pixelNcConversionValue, pixelNcPurchases),
    pixelNcCpa: safeDivide(spend!, pixelNcPurchases),
    pixelProfit: safeDivide((pixelConversionValue || 0) - (pixelCogs || 0) - (spend || 0), 1),
    pixelConversionRate: safeDivide(pixelPurchases, pixelVisitors),
    pixelNcConversionRate: safeDivide(pixelNcPurchases, pixelNewVisitors),
    pixelCostPerAtc: safeDivide(spend!, pixelUniqueAtc),
    pixelCostPerEmailSignup: safeDivide(spend!, pixelEmailSignup),
    pixelCostPerNewVisitor: safeDivide(spend!, pixelNewVisitors),
    pixelCostPerVisitor: safeDivide(spend!, pixelUniqueVisitors),
    pixelEmailSignupRate: safeDivide(pixelEmailSignup, pixelUniqueVisitors),
    pixelCvDelta: safeDivide((pixelConversionValue || 0) - (conversionValue || 0), 1),
    pixelNewVisitorPerc: safeDivide(pixelNewVisitors, pixelUniqueVisitors),
    pixelNcPurchasesPerc: safeDivide(pixelNcPurchases, pixelPurchases),
  };
  const calculatedMetrics: CalculatedMetrics = {
    roas: safeDivide(conversionValue!, spend!),
    aov: safeDivide(conversionValue!, purchases!),
    cpa: safeDivide(spend!, purchases!),
    cpc: safeDivide(spend!, clicks!),
    cpm: safeDivide(spend!, impressions!),
    ctr: safeDivide(clicks!, impressions!),
  };
  const stats: Partial<AttributionData> = {
    ...rawStats,
    ...calculatedMetrics,
    ...calculatedPixelMetrics,
  };
  return stats;
}

export function addRealtimeData(
  existingEntity: AttributionData,
  realtimeEntity: AttributionRealtimeMessageData,
): AttributionData {
  let newCustomerValues: Partial<AttributionData> = {};
  if (realtimeEntity.isNewCustomer) {
    newCustomerValues = {
      pixelNcPurchases: existingEntity.pixelNcPurchases + 1,
      pixelNcConversionValue: existingEntity.pixelNcConversionValue + realtimeEntity.totalPrice,
      pixelNcCogs: existingEntity.pixelNcCogs + realtimeEntity.cogs,
    };
  }

  const rawStats: AttributionData = {
    ...existingEntity,
    pixelConversionValue: existingEntity.pixelConversionValue + realtimeEntity.totalPrice,
    pixelPurchases: existingEntity.pixelPurchases + 1,
    pixelCogs: existingEntity.pixelCogs + realtimeEntity.cogs,
    ...newCustomerValues,
    shouldHighlight: true,
  };
  return addCalculatedStats(rawStats) as AttributionData;
}

export const getAttributionData = async (
  params: AttributionStatsRequest & { signal?: AbortSignal },
  compare = false,
) => {
  try {
    const { signal, ...requestParams } = params;

    const statsResponse = axiosInstance.post<
      any,
      { data: AttributionMainResponse },
      AttributionStatsRequest
    >(`/v2/attribution/get-all-stats${compare ? '?compare' : ''}`, requestParams, { signal });

    return statsResponse;
  } catch (error) {
    console.error('Error fetching attribution data:', error);
    throw error; // Rethrow to allow proper handling of AbortError
  }
};

export const getCampaignsExperimentsMetrics = async (params) => {
  try {
    if (params.sources?.length !== 1) return [];

    const { data } = await axiosInstance.post(`/v2/attribution/get-campaign-experiment-metrics`, {
      shopId: params.shopDomain,
      services: params.sources,
    });
    return data;
  } catch (error) {
    console.error('Error fetching attribution data:', error);
    throw new Error('Failed to fetch attribution data');
  }
};

export const getParams = (
  attributionState,
  campaignId?: string,
  adsetId?: string,
  parentValues?: any,
) => {
  const { attributionParams, sourceCategory, activeSource, sourcesList } = attributionState;
  if (!attributionParams) {
    return;
  }

  const { startDate, endDate } = attributionParams;
  const start = moment(startDate);
  const end = moment(endDate);
  const isOneDay = moment(start).isSame(moment(end), 'day');

  const params: AttributionStatsRequest = {
    ...(attributionParams as AttributionStatsRequest),
    breakdown:
      (sourceCategory === 'all' && activeSource === ALL_SOURCES_ID) ||
      activeSource === ALL_SOURCES_ID
        ? 'source'
        : 'campaignId',
    attributionFilters: [],
  };

  if (sourceCategory !== 'all' && activeSource === ALL_SOURCES_ID) {
    params.sources = sourcesList as ServicesIds[];
  } else if (activeSource !== ALL_SOURCES_ID) {
    params.sources = [activeSource];
  }

  if (campaignId) {
    params.attributionFilters.push({ key: 'campaignId', value: campaignId });
    params.breakdown = 'adsetId';
  }
  if (adsetId) {
    params.attributionFilters.push({ key: 'adsetId', value: adsetId });
    params.breakdown = 'adId';
  }

  if (params.model === 'ppsViews') {
    params.parentValues = parentValues;
  }

  return {
    period: {
      startDate,
      endDate,
      start,
      end,
      isOneDay,
    },
    params,
  };
};

const emptyArr = [];

export const getSourcesList = (sourceCategory: SourceTypesWithExtra) => {
  if (sourceCategory === 'all') {
    return emptyArr;
  }

  return Object.values(allServices)
    .filter((s) => s.type === sourceCategory && s.serviceId !== 'organic_and_social')
    .map((s) => s.id);
};
