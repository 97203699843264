import { MobileDrawer, Switch } from '@tw/ui-components';
import { chatActions } from 'utils/dataLayer';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $chatSelectorDrawer, closeChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useLocation } from 'react-router-dom';
import { $mainChatStore, setDeepSearch } from '$stores/willy/$mainChat';
import { analyticsEvents } from 'utils/dataLayer/constants';
import { genericEventLogger } from 'utils/dataLayer';
import { Drawer, Text } from '@tw/ui-components';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { WillyNavItem } from './WillyNavItem';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { $unreadMobyFeedItemCount } from '$stores/willy/$mobyFeed';

export const MobileDrawerWillyChat: React.FC<{ activeSequenceId: string | undefined }> = ({
  activeSequenceId,
}) => {
  const { pathname } = useLocation();
  const drawerOpen = useComputedValue($chatSelectorDrawer, (r) => r.opened);
  const deepSearch = useComputedValue($mainChatStore, (r) => r.deepSearch);
  const isAgentSupported = useStoreValue($isAgentSupported);

  const unread = useStoreValue($unreadMobyFeedItemCount);

  const updateDeepSearch = (value) => {
    setDeepSearch(value);
    genericEventLogger(analyticsEvents.CHAT, {
      action: chatActions.TOGGLE_DEEP_SEARCH,
      model: value,
    });
  };

  const currentPage =
    WILLY_NAV_ITEMS.find((item) => {
      return pathname.includes(item.link);
    }) || WILLY_NAV_ITEMS[0];

  const activeNavItem = currentPage.id;

  return (
    <MobileDrawer
      size="600px"
      shadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
      closeOnClickOutside
      opened={drawerOpen}
      withCloseButton={false}
      onClose={() => closeChatSelectorDrawer()}
      title="Moby"
    >
      <div className="flex flex-col justify-between h-[calc(600px-81px)]">
        <div className="p-3 gap-4 flex flex-col">
          {WILLY_NAV_ITEMS.filter((item) => !item.hideFromSidePanel).map((item) => (
            <WillyNavItem key={item.id} item={item} activeTab={activeNavItem} unread={unread} />
          ))}
        </div>
        {/* <div className="mx-[-16px]">
          <WillySidePanelWorkflows
            chatWithSequenceId={activeSequenceId ?? null}
            currentPage={activeNavItem}
          />
        </div> */}
        <div>
          <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border pb-5 mx-[-16px]" />
          {isAgentSupported && (
            <div className="p-3 gap-4 flex flex-col">
              <Switch
                size="sm"
                label={
                  <Text span fz="sm" c="gray.8" fw={500}>
                    Deep Dive
                  </Text>
                }
                onChange={(e) => updateDeepSearch(e.target.checked)}
                checked={deepSearch}
              />
            </div>
          )}
        </div>
      </div>
    </MobileDrawer>
  );
};
