import { FC, useCallback } from 'react';
import {
  shelfCategories,
  WorkflowEmptyStep,
  WorkflowStep,
  WorkflowStepBase,
} from '../types/willyTypes';
import { StepSelector } from './StepSelector';
import { WILLY_SEQUENCE_STEP_CATEGORIES, WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { getDisabledSteps } from '../utils/sequences';

type FlowEmptyStepProps = {
  step: WorkflowEmptyStep & WorkflowStepBase;
  sequenceId?: string;
  stepsUntilNow?: WorkflowStep[];
  depth?: number;
  stepChange: (step: WorkflowStep) => void;
};

export const FlowEmptyStep: FC<FlowEmptyStepProps> = ({
  step,
  stepsUntilNow,
  sequenceId,
  depth,
  stepChange,
}) => {
  const { category: categoryId } = step;
  const currentSlackChannel = useSelector((state: RootState) => state.currentSlackChannel);
  const isGoogleSheetsConnected = useSelector((state: RootState) => state.isGoogleSheetsConnected);
  const user = useSelector((state: RootState) => state.user);
  const isFirstStep = depth === 0 && stepsUntilNow?.length === 0;

  const disabledSteps = getDisabledSteps({
    stepsUntilNow,
    sequenceId,
    currentSlackChannel,
    isGoogleSheetsConnected,
    user,
    isFirstStep,
  });

  const category = WILLY_SEQUENCE_STEP_CATEGORIES.find((cat) => cat.id === categoryId);

  const categorySequenceSteps = useCallback((categoryId: shelfCategories) => {
    return WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => step.category === categoryId);
  }, []);

  return (
    <div className="grid grid-cols-12 gap-8 w-full">
      {categorySequenceSteps(categoryId).map((action) => {
        const shouldBeDisabled = Boolean(disabledSteps[action.type]);
        return (
          <div className="col-span-6" key={action.type}>
            <StepSelector
              action={action}
              shouldBeDisabled={shouldBeDisabled}
              disabledReason={disabledSteps[action.type]}
              stepChange={stepChange}
              step={step}
              color={category?.color}
            />
          </div>
        );
      })}
    </div>
  );
};
