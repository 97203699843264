import { useState, useCallback } from 'react';
import { ThumbsUpMinor, ThumbsDownMinor } from '@shopify/polaris-icons';
import { $currentShopId } from '$stores/$shop';
import { $user } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import axiosInstance from 'utils/axiosInstance';
import { DialectWithBoth, WorkflowResponse, WorkflowStep } from '../types/willyTypes';

const buttonSize = 24;

export const SequenceStepFeedback = ({
  sequenceId,
  currentRunId,
  step,
  dialect,
  generatedAnswer,
}: {
  sequenceId: string;
  currentRunId?: string;
  step: WorkflowStep;
  dialect: DialectWithBoth;
  generatedAnswer?: WorkflowResponse | null;
}) => {
  const user = useStoreValue($user) || '';
  const shopId = useStoreValue($currentShopId) || '';
  const [feedback, setFeedback] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFeedback = useCallback(
    async (value: boolean) => {
      if (loading || value === feedback) return;

      try {
        setLoading(true);

        await axiosInstance.post(`/v2/sequences/send-workflow-step-feedback`, {
          sequenceId,
          currentRunId,
          userId: user?.uid,
          userEmail: user?.email,
          shopId,
          step,
          generatedAnswer,
          dialect,
          feedback: value ? 'good' : 'bad',
        });

        setFeedback(value);
      } catch (error) {
        console.error('Error sending feedback', error);
      } finally {
        setLoading(false);
      }
    },
    [
      currentRunId,
      dialect,
      feedback,
      generatedAnswer,
      loading,
      sequenceId,
      shopId,
      step,
      user?.email,
      user?.uid,
    ],
  );

  return (
    <div className="flex gap-2 ml-2 items-center">
      <div className="flex items-center cursor-pointer">
        <ThumbsUpMinor
          onClick={() => handleFeedback(true)}
          fill={feedback === true ? 'green' : 'gray'}
          width={buttonSize}
          height={buttonSize}
        />
      </div>
      <div className="flex items-center cursor-pointer">
        <ThumbsDownMinor
          onClick={() => handleFeedback(false)}
          fill={feedback === false ? 'red' : 'gray'}
          width={buttonSize}
          height={buttonSize}
        />
      </div>
    </div>
  );
};
