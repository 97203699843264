import { Button, SortDirection, Spinner } from '@shopify/polaris';
import { ChevronDownMinor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';
import DropDownActionList from 'components/DropDownActionList';
import TWTable from 'components/library/TWTable/TWTable';
import {
  ATTRIBUTION_SUMMARY_ADD_SOURCE,
  ATTRIBUTION_SUMMARY_EXTRA_SOURCES,
  ATTRIBUTION_SUMMARY_REMOVE_SOURCE,
} from 'ducks/attribution/constants';

import { SummaryContext } from 'ducks/summary';
import { useAppDispatch } from 'index';
import { orderBy } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import {
  AttributionData,
  AttributionMainResponse,
  AttributionStatsRequest,
} from 'types/attribution';
import { ExtraServicesIds } from 'types/services';
import Cookies from 'utils/Cookies';
import axiosInstance from 'utils/axiosInstance';
import { useAttributionParams } from 'utils/useAttributionParam';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import { InstallPixelBanner } from 'components/pixel/InstallPixelBanner';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { PpsBanner } from 'components/attribution-new/PpsBanner';
import moment from 'moment-timezone';
import { InPageNotificationBanner } from 'components/bannerNotifications/inPageNotificationBanner';

const defaultSources: (ServicesIds | ExtraServicesIds | 'bing')[] = [
  'facebook-ads',
  'google-ads',
  'tiktok-ads',
  'pinterest-ads',
  'snapchat-ads',
  'klaviyo',
  'tw_referrer',
  'attentive',
  'postscript',
  'bing',
];

export const SummaryPixelWidget: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const currency = useStoreValue($currency);
  const extraSourcesForSummaryPage = useSelector(
    (state: RootState) => state.attribution.extraSourcesForSummaryPage,
  );
  const [closedPpsBanner, setClosedPpsBanner] = useState(false);

  const extraAndDefaultSources = useMemo(() => {
    return extraSourcesForSummaryPage.concat(defaultSources);
  }, [extraSourcesForSummaryPage]);

  const attributionParams = useAttributionParams();

  const { pixelColumns } = useContext(SummaryContext);

  const [data, setData] = useState<AttributionData[]>([]);
  const [orderedData, setOrderedData] = useState<AttributionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState('pixelConversionValue');
  const [sortDirection, setSortDirection] = useState<SortDirection>('descending');
  const [allSources, setAllSources] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const ffComputer = useFeatureFlagComputer();

  const moreSources = useMemo(
    () => allSources.filter((x) => !defaultSources.includes(x as any)),
    [allSources],
  );

  const { attributionModel } = useSelector((state: RootState) => state.attribution);

  const noSessionsYesterday = useSelector((state: RootState) => state.noSessionsYesterday);
  const { hasPpsData, missingDates } = useSelector((state: RootState) => state.ppsStatus);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  useEffect(() => {
    async function func() {
      if (!attributionParams) {
        return;
      }

      setLoading(true);

      if (attributionModel === 'ppsViews') {
        if (!mainDatePickerSelectionRange) {
          return null;
        }

        const { start, end } = mainDatePickerSelectionRange || {};
        setClosedPpsBanner(false);
        const missingDatesBetweenStartAndEnd = missingDates?.some((date) => {
          return moment(date).isBetween(start, end, 'day', '[]');
        });
        if (missingDatesBetweenStartAndEnd || !hasPpsData) {
          setLoading(false);
          setData([]);
          return;
        }
      }

      const { data } = await axiosInstance.post<
        any,
        { data: AttributionMainResponse },
        AttributionStatsRequest
      >(`/v2/attribution/get-all-stats`, {
        ...(attributionParams as AttributionStatsRequest),
        breakdown: 'source',
        //attributionWindow: 'lifetime',
      });

      const a: AttributionData[] = data?.data?.stats || [];
      setAllSources(a.map((d) => d.id as string).filter(Boolean));
      const b = a
        .filter((x) => extraAndDefaultSources.includes(x.id as ServicesIds))
        .map((d) => {
          // remove klaviyo conversionValue
          if (d.id === 'klaviyo') {
            return {
              ...d,
              conversionValue: 0,
            };
          }
          return d;
        });
      setData(b);
      setLoading(false);
    }

    func();
  }, [attributionParams, extraAndDefaultSources]);

  useEffect(() => {
    const ordered = orderBy(
      data,
      (data) => {
        if (typeof data[sortBy] === 'undefined') {
          if (sortBy === 'name') {
            return 'id';
          }
          return 0;
        }
        return data[sortBy];
      },
      sortDirection === 'ascending' ? 'asc' : 'desc',
    );
    setOrderedData(ordered);
  }, [data, sortBy, sortDirection]);

  const dropDownActionListItems = useMemo(() => {
    return moreSources.map((source) => ({
      content: source,
      active: extraAndDefaultSources.includes(source),
      onAction: () => {
        if (extraAndDefaultSources.includes(source)) {
          const sourcesFromLocalStorage = JSON.parse(
            Cookies.get(ATTRIBUTION_SUMMARY_EXTRA_SOURCES) || '[]',
          );
          sourcesFromLocalStorage.splice(sourcesFromLocalStorage.indexOf(source), 1);
          localStorage.setItem(
            ATTRIBUTION_SUMMARY_EXTRA_SOURCES,
            JSON.stringify(sourcesFromLocalStorage),
          );
          dispatch({
            type: ATTRIBUTION_SUMMARY_REMOVE_SOURCE,
            source: source,
          });
        } else {
          const sourcesFromLocalStorage = JSON.parse(
            Cookies.get(ATTRIBUTION_SUMMARY_EXTRA_SOURCES) || '[]',
          );
          sourcesFromLocalStorage.push(source);
          localStorage.setItem(
            ATTRIBUTION_SUMMARY_EXTRA_SOURCES,
            JSON.stringify(sourcesFromLocalStorage),
          );
          dispatch({
            type: ATTRIBUTION_SUMMARY_ADD_SOURCE,
            source: source,
          });
        }
      },
    }));
  }, [moreSources, dispatch, extraAndDefaultSources]);

  return loading ? (
    <div className="flex w-full h-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      {/* {noSessionsYesterday && <InstallPixelBanner />} */}
      <InPageNotificationBanner componentKey="summary-pixel-widget" />

      <PpsBanner
        attributionModel={attributionModel}
        closedPpsBanner={closedPpsBanner}
        onClose={() => setClosedPpsBanner(true)}
      />
      <TWTable
        stickyColumnIndex={1}
        data={orderedData}
        columns={(
          pixelColumns?.filter((col) => col.key !== 'showInGraph' && col.key !== 'status') || []
        ).map((c) => ({
          ...c,
          // Heading: () => <span className="flex-auto text-left">{c.name}</span>,
        }))}
        sortBy={sortBy}
        sortDirection={sortDirection}
        padding="0"
        onSort={(columnIndex, direction) => {
          const col = pixelColumns![columnIndex + 1];
          setSortBy(col.key);
          setSortDirection(direction);
        }}
        metadata={{
          currency,
          filteredRows: data,
          sourceCategory: 'ads',
          activeModel: attributionModel,
          sourceId: 'all',
          setData,
          navigate,
          location,
          featureFlagComputer: ffComputer,
        }}
        totalsName={{
          plural: (
            <div className="p-4">
              {moreSources?.length > 0 && (
                <DropDownActionList
                  hint="Add more sources to the table"
                  items={dropDownActionListItems}
                  activator={
                    <Button plain icon={ChevronDownMinor}>
                      Add more sources
                    </Button>
                  }
                />
              )}
            </div>
          ),
          singular: 'Pixel',
        }}
      />
    </div>
  );
};
