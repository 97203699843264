import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $userId } from '$stores/$user';
import { $shopUsers } from '$stores/$users';
import { MobyFeedItem, RunDoc, WillyDataSequence } from './types/willyTypes';
import { WorkflowWithRun } from './types/willyTypes';
import { $currentShopId } from '$stores/$shop';
import _db, { firestoreRef, toArray } from 'utils/DB';
import firebase from 'firebase/compat/app';
import { SequenceFeedList } from './SequenceFeedList';
import { computeFeatureFlags } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { $newlyRunWorkflows } from '$stores/willy/$newlyRunAgents';

const filterOptions = [
  { value: 'all', label: 'All Agents' },
  { value: 'user', label: 'My Agents' },
];

type SequencesAllListProps = {
  onRunItem: (sequenceId: string) => void;
  isBetaChat?: boolean;
};

export const SequencesAllList: React.FC<SequencesAllListProps> = ({ onRunItem, isBetaChat }) => {
  const userId = useStoreValue($userId);
  const { data: shopUsers } = useStoreValue($shopUsers);
  const shopUsersMap = useMemo(() => {
    return shopUsers?.reduce(
      (acc, user) => {
        acc[user.id] = user.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [shopUsers]);

  const defaultFilter = filterOptions[0].value;

  const currentShopId = useStoreValue($currentShopId);
  const newlyRun = useStoreValue($newlyRunWorkflows);
  const [workflows, setWorkflows] = useState<WorkflowWithRun[]>([]);
  const [newlyRunWorkflows, setNewlyRunWorkflows] = useState<WorkflowWithRun[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastWorkflow, setLastWorkflow] = useState<firebase.firestore.Timestamp | null>(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const [feedItems, setFeedItems] = useState<MobyFeedItem[]>([]);
  // const [filter, setFilter] = useState(defaultFilter);

  const workflowsForFeed = useMemo(() => {
    if (newlyRunWorkflows.length) {
      const remainingWorkflows = workflows.filter(
        (workflow) => !newlyRunWorkflows.some((newWorkflow) => newWorkflow.id === workflow.id),
      );
      return [...newlyRunWorkflows, ...remainingWorkflows];
    }
    return workflows;
  }, [workflows, newlyRunWorkflows]);

  // const debouncedFreeSearch = useDebounce(freeSearch, 500);

  useEffect(() => {
    const fetchNewlyRunWorkflows = async () => {
      // Map over each workflow id to fetch the related data
      const workflows = await Promise.all(
        newlyRun.map(async (workflowId) => {
          const moby_feed_item = _db(currentShopId).collection('moby_feed').doc(workflowId);
          const sequenceDocRef = _db(currentShopId).collection('data_sequences').doc(workflowId);
          // Optionally fetch the sequence document data if needed
          const sequenceDoc = await sequenceDocRef.get();
          const mobyFeedDoc = await moby_feed_item.get();
          const mobyFeedData = mobyFeedDoc.data() as MobyFeedItem;
          // Fetch the latest run for this workflow
          const runQuerySnapshot = await sequenceDocRef
            .collection('runs')
            .orderBy('finishedAt', 'desc')
            .limit(1)
            .get();
          const runsArray = toArray(runQuerySnapshot) as RunDoc[];
          const lastRun: RunDoc = {
            ...runsArray?.[0],
            userId: shopUsersMap?.[workflowId] || '',
          };

          return {
            id: sequenceDoc.id,
            // spread the sequence document data if needed
            ...sequenceDoc.data(),
            run: lastRun,
            read: mobyFeedData.read,
          } as WorkflowWithRun;
        }),
      );
      setNewlyRunWorkflows(workflows);
    };

    if (newlyRun.length && currentShopId) {
      fetchNewlyRunWorkflows();
    }
  }, [newlyRun, currentShopId, shopUsersMap]);

  const runItem = useCallback(
    (sequenceId: string) => {
      onRunItem(sequenceId);
      if (newlyRunWorkflows.some((workflow) => workflow.id === sequenceId)) {
        setNewlyRunWorkflows((prev) => {
          const newWorkflows = prev.map((workflow) => {
            if (workflow.id === sequenceId) {
              return {
                ...workflow,
                running: true,
              };
            }
            return workflow;
          });
          return newWorkflows;
        });
      } else {
        setWorkflows((prev) => {
          const newWorkflows = prev.map((workflow) => {
            if (workflow.id === sequenceId) {
              return {
                ...workflow,
                running: true,
              };
            }
            return workflow;
          });
          return newWorkflows;
        });
      }
    },
    [onRunItem, newlyRunWorkflows],
  );

  const fetchWorkflows = useCallback(
    async (startAfter?: firebase.firestore.Timestamp | null) => {
      if (!currentShopId) {
        return;
      }

      setLoading(true);

      let query = _db(currentShopId).collection('moby_feed').orderBy('lastRunAt', 'desc').limit(20);

      if (startAfter) {
        query = query.startAfter(startAfter);
      }

      const feedItems = await query.get();
      setHasMoreItems(!feedItems.empty && feedItems.size === 20);
      const toArrayFeedItems = toArray(feedItems) as MobyFeedItem[];

      const runsPromises = toArrayFeedItems.map(async (feedItem) => {
        if (!feedItem.sequenceId) {
          return;
        }
        const sequence = await _db(currentShopId)
          .collection('data_sequences')
          .doc(feedItem.sequenceId)
          .get();
        if (!sequence.exists) {
          return;
        }
        const workflow = sequence.data();
        if (!workflow) {
          return;
        }
        const runs = await _db(currentShopId)
          .collection('data_sequences')
          .doc(workflow.id)
          .collection('runs')
          .orderBy('finishedAt', 'desc')
          .limit(1)
          .get();

        const runsArray = toArray(runs) as RunDoc[];
        const lastRun: RunDoc = {
          ...runsArray?.[0],
          userId: shopUsersMap?.[workflow.user] || '',
        };
        workflow.run = lastRun;
        workflow.read = feedItem.read;
        return workflow;
      });
      const runs: WorkflowWithRun[] = (await Promise.all(runsPromises)).filter(
        (workflow): workflow is WorkflowWithRun => workflow !== undefined,
      );
      setWorkflows((prev) => {
        if (startAfter) {
          return [...prev, ...runs];
        }
        return runs;
      });
      setLastWorkflow(runs[runs.length - 1]?.lastRunAt || null);
      setLoading(false);
    },
    [currentShopId, shopUsersMap],
  );

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const markAsRead = useCallback(
    (sequenceId: string) => {
      if (newlyRunWorkflows.some((workflow) => workflow.id === sequenceId)) {
        setNewlyRunWorkflows((prev) => {
          const newWorkflows: WorkflowWithRun[] = [...prev];
          const workflowIndex = newWorkflows.findIndex((workflow) => workflow.id === sequenceId);
          newWorkflows[workflowIndex] = {
            ...newWorkflows[workflowIndex],
            read: true,
          };
          return newWorkflows;
        });
      } else {
        setWorkflows((prev) => {
          const newWorkflows: WorkflowWithRun[] = [...prev];
          const workflowIndex = newWorkflows.findIndex((workflow) => workflow.id === sequenceId);
          newWorkflows[workflowIndex] = {
            ...newWorkflows[workflowIndex],
            read: true,
          };
          return newWorkflows;
        });
      }
    },
    [newlyRunWorkflows, setNewlyRunWorkflows, setWorkflows],
  );

  const dismissFromFeed = useCallback(
    async (sequenceId: string) => {
      setWorkflows((prev) => {
        return prev.filter((workflow) => workflow.id !== sequenceId);
      });

      const sequenceDoc = _db(currentShopId).collection('data_sequences').doc(sequenceId);
      const sequenceDocData = await sequenceDoc.get();
      const sequenceData = sequenceDocData.data() as WillyDataSequence;
      if (!sequenceData) {
        return;
      }

      const { steps } = sequenceData;

      const stepsToKeep = steps.filter((step) => step.stepType !== 'sendToFeed');

      const batch = firestoreRef().batch();

      batch.update(_db(currentShopId).collection('data_sequences').doc(sequenceId), {
        steps: stepsToKeep,
        lastRunAt: null,
      });

      batch.delete(_db(currentShopId).collection('moby_feed').doc(sequenceId));

      await batch.commit();
    },
    [currentShopId, setWorkflows],
  );

  return (
    <div className="h-full overflow-auto">
      <SequenceFeedList
        workflows={workflowsForFeed}
        loading={loading}
        fetchWorkflows={fetchWorkflows}
        lastWorkflow={lastWorkflow}
        hasMoreItems={hasMoreItems}
        onRunItem={runItem}
        markAsRead={markAsRead}
        dismissFromFeed={dismissFromFeed}
        isBetaChat={isBetaChat}
      />
    </div>
  );
};

export const SequenceFeed = computeFeatureFlags(
  [FeatureFlag.AGENT_SUPPORT_FF],
  SequencesAllList,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="Moby Agents"
      description="Supercharge your workflow and get back precious time to focus on what matters most with Moby Agents"
      featureFlags={[FeatureFlag.AGENT_SUPPORT_FF]}
    />
  ),
);
