import { Button, Menu } from '@tw/ui-components';
import { useComputedValue } from '@tw/snipestate';
import { $mainChatStore, setActivePage } from '$stores/willy/$mainChat';

export const landingPages = ['Feed', 'Questions', 'Beta'];
export type LandingPage = (typeof landingPages)[number];

export const WillyMainChatLandingPageSelector: React.FC = () => {
  const activePage = useComputedValue($mainChatStore, (x) => x.activePage);

  return (
    <Menu withArrow shadow="md" closeOnItemClick={true}>
      <Menu.Target>
        <Button variant="white" size="xs" tt="capitalize">
          Page: {activePage}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {landingPages.map((page) => (
          <Menu.Item
            key={page}
            onClick={() => {
              setActivePage(page);
            }}
            bg={activePage === page ? 'named2.4' : undefined}
          >
            <div className="flex items-center gap-2">
              <div className="flex-auto">{page}</div>
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
