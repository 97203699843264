import { WillyDataSequence, WorkflowStep, WorkflowStepVariable } from './types/willyTypes';

import { ActionIcon, Button, Modal, Text } from '@tw/ui-components';
import { WorkflowWithRun } from './types/willyTypes';
import { v4 as uuidV4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@tw/ui-components';
import { FlowSendToEmailStep } from './sequenceBuilder/FlowSendToEmailStep';
import { noop } from 'lodash';
import { useStoreValue } from '@tw/snipestate';
import { $user } from '$stores/$user';
import { FlowSendToPushNotification } from './sequenceBuilder/FlowSendToPushNotification';
import { simpleEditSequence } from './utils/sequences';

type SubscribeAgentModalProps = {
  item?: WorkflowWithRun;
  opened: boolean;
  onClose: () => void;
};

export const SubscribeAgentModal: React.FC<SubscribeAgentModalProps> = ({
  item,
  opened,
  onClose,
}) => {
  const [itemToUpdate, setItemToUpdate] = useState<WillyDataSequence | null>(item || null);
  const [mode, setMode] = useState<'email' | 'push' | null>(null);
  const user = useStoreValue($user);
  const [email, setEmail] = useState('');
  const [formats, setFormats] = useState<string[]>([]);
  const [pushText, setPushText] = useState('');
  const [pushVariables, setPushVariables] = useState<WorkflowStepVariable[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const updateAgent = useCallback(
    async (mode: 'email' | 'push') => {
      if (!itemToUpdate) {
        return;
      }

      try {
        setIsLoading(true);

        const { run, ...itemWithoutRun } = itemToUpdate as WorkflowWithRun;

        if (mode === 'email') {
          const newStep: WorkflowStep = {
            id: uuidV4(),
            stepType: 'sendToEmail',
            title: "Subscribe to agent's updates",
            email: email,
            formats: formats,
            createdAt: new Date().toISOString(),
          };

          let newAgent: WillyDataSequence = {
            ...itemWithoutRun,
            steps: [...itemToUpdate.steps, newStep],
          };

          setItemToUpdate(newAgent);
          await simpleEditSequence(newAgent);
        } else if (mode === 'push') {
          const newStep: WorkflowStep = {
            id: uuidV4(),
            stepType: 'sendToPushNotification',
            text: pushText,
            userId: user?.uid || '',
            title: "Subscribe to agent's updates",
            createdAt: new Date().toISOString(),
            variables: pushVariables,
          };

          const newAgent: WillyDataSequence = {
            ...itemToUpdate,
            steps: [...itemToUpdate.steps, newStep],
          };

          setItemToUpdate(newAgent);
          await simpleEditSequence(newAgent);
        }
        setShowSuccess(true);
        setMode(null);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [itemToUpdate, email, formats, pushText, user?.uid, pushVariables],
  );

  useEffect(() => {
    if (!user || !!email) {
      return;
    }

    setEmail(user.email || '');
  }, [user, email]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={!!mode && <ActionIcon icon="chevron-left-minor" onClick={() => setMode(null)} />}
    >
      <div className="flex flex-col gap-4">
        <Text size="lg" weight={500}>
          Get updates from "{itemToUpdate?.name}" every run
        </Text>
        {mode === null && !showSuccess && (
          <div className="flex flex-col gap-4">
            <Button
              variant="white"
              onClick={() => {
                setMode('email');
              }}
              leftSection={<Icon name="mail" />}
              size="xl"
              iconSize={32}
            >
              By email
            </Button>
            <Button
              variant="white"
              onClick={() => {
                setMode('push');
              }}
              leftSection={<Icon name="phone-1" />}
              size="xl"
              iconSize={32}
            >
              By push notification
            </Button>
          </div>
        )}
        {mode === 'email' && (
          <div className="flex flex-col gap-4">
            <FlowSendToEmailStep
              email={email}
              setIsPristine={noop}
              setEmail={setEmail}
              setFormats={setFormats}
              readOnly={false}
              formats={formats}
            />
            <Button loading={isLoading} onClick={async () => await updateAgent('email')}>
              Subscribe
            </Button>
          </div>
        )}
        {mode === 'push' && (
          <div className="flex flex-col gap-4">
            <FlowSendToPushNotification
              depth={0}
              text={pushText}
              variables={pushVariables}
              readOnly={false}
              textChange={setPushText}
              variablesChange={setPushVariables}
              setIsPristine={noop}
              userId={user?.uid || ''}
            />
            <Button loading={isLoading} onClick={async () => await updateAgent('push')}>
              Subscribe
            </Button>
          </div>
        )}
        {showSuccess && (
          <div className="flex flex-col gap-4">
            <Text>Successfully subscribed</Text>
            <Button onClick={() => setShowSuccess(false)}>Close</Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
