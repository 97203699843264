import { useMemo } from 'react';
import { CustomMetricsType } from 'ducks/customMetrics';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useSelector } from 'react-redux';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import { ALL_SOURCES_ID } from 'constants/types';
import { PixelColumn } from 'types/attribution';
import useAllPixelColumns from 'components/attribution-new/columns';
import { $pixelColumnsForCustomEvents } from 'pages/Attribution/selectPixelColumns';
import { useStoreValue } from '@tw/snipestate';

export const useMetricsForCustomMetricBuilder = (customMetricsType: CustomMetricsType) => {
  const attributionActiveSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const customMetricsColumns: PixelColumn[] = useSelector(
    selectCustomMetricsColumns(customMetricsType),
  );

  const allPixelColumns = useAllPixelColumns();
  const pixelColumnsForCustomEvents = useStoreValue($pixelColumnsForCustomEvents);
  const columns = useMemo(() => {
    const allColumns = allPixelColumns.filter((col) => {
      const isAvailableForSourceAndCategory =
        !col.showInSources ||
        col.showInSources.includes(attributionActiveSource) ||
        (!col.excludeFromAllLevel &&
          (attributionActiveSource === ALL_SOURCES_ID || sourceCategory === 'all'));

      return isAvailableForSourceAndCategory;
    });

    if (customMetricsType === CustomMetricsType.Attribution) {
      return [...allColumns, ...pixelColumnsForCustomEvents];
    }

    return allColumns;
  }, [
    allPixelColumns,
    customMetricsType,
    attributionActiveSource,
    sourceCategory,
    pixelColumnsForCustomEvents,
  ]);

  return [columns, customMetricsColumns];
};
