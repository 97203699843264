import { $refreshable, $store } from '@tw/snipestate';
import { WorkflowIntegrationStatus } from '@tw/types/module/sensory';
import { ServicesIds } from '@tw/types/module/services';
import { ShopIntegrationStatusEnum } from '@tw/types/module/types/ShopProviders';
import { $userId } from './$user';
import { $currentShopId } from './$shop';
import axiosInstance from '../utils/axiosInstance';
import { subscriptionPlan } from '@tw/types/module/services/subscription-manager';

export type WorkflowOldIntegrations = {
  status: ShopIntegrationStatusEnum;
  workflowStatus: WorkflowIntegrationStatus[];
};

export const $oldIntegrationsWorkflowStatus = $refreshable(
  async (get): Promise<Record<ServicesIds, WorkflowOldIntegrations>> => {
    const empty = {} as Record<ServicesIds, WorkflowOldIntegrations>;
    const uid = get($userId);
    const shopId = get($currentShopId);
    if (!uid || !shopId) return empty;
    try {
      const url = `/v2/job-manager/jobs?jobType=initial&status=in_progress&shopDomain=${shopId}`;
      const { data } = await axiosInstance.get(url);

      if (!data || !Array.isArray(data)) {
        return empty;
      }
      const workflowWay: Record<ServicesIds, WorkflowOldIntegrations> = data.reduce(
        (acc, job) => {
          const workflowOldIntegrations: WorkflowOldIntegrations = {
            status: ShopIntegrationStatusEnum.backfill,
            workflowStatus: [
              {
                workflowId: job.id,
                assetType: 'metrics',
                total: job.total,
                currentIndex: job.currentIndex,
                status: 'running',
                integrationStatus: 'backfill',
              },
            ],
          };
          acc[job.serviceId] = workflowOldIntegrations;
          return acc;
        },
        {} as Record<ServicesIds, WorkflowOldIntegrations>,
      );
      return workflowWay;
    } catch (e) {
      return empty;
    }
  },
);
