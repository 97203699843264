import {
  ActionIcon,
  Combobox,
  ComboboxProps,
  extractCSSColor,
  Flex,
  Icon,
  TextInput,
  useCombobox,
} from '@tw/ui-components';
import { FC, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { ColumnTooltip } from './ColumnTooltip';
import { analyticsEvents, genericEventLogger, metricBuilderActions } from 'utils/dataLayer';
import { useDelayedDisclosure } from 'hooks/useDelayedDisclosure';

type SelectWithSearchInputProps = {
  value?: string[] | string;
  data: {
    label: string;
    value: string;
    originalLabel?: string;
    description?: string;
    readmeUrl?: string;
  }[];
  onChange: (value: string) => void;
  activator?: (onClick: () => void) => React.ReactNode;
  width?: 'target' | React.CSSProperties['width'];
  opened?: boolean;
  closeOnSelect?: boolean;
  tableName?: string;
} & ComboboxProps;

export const SelectWithSearchInput: FC<SelectWithSearchInputProps> = ({
  value,
  data,
  onChange,
  activator,
  width = 'max-content',
  opened,
  closeOnSelect = true,
  tableName,
  ...rest
}) => {
  const combobox = useCombobox({
    defaultOpened: opened,
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
  });
  const [search, setSearch] = useState('');

  const options = useMemo(
    () =>
      data
        .filter(
          (item) =>
            item.label.toLowerCase().includes(search.toLowerCase()) ||
            item.originalLabel?.toLowerCase().includes(search.toLowerCase()) ||
            item.value.toLowerCase().includes(search.toLowerCase()),
        )
        .map((item) => (
          <Option
            item={item}
            value={value}
            key={item.value}
            name={tableName ? item.value : undefined}
            tableName={tableName ?? item.label}
          />
        )),
    [data, search, value, tableName],
  );

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(value) => {
        onChange(value);
        if (closeOnSelect) combobox.toggleDropdown();
      }}
      styles={{ options: { maxHeight: 400, overflowY: 'scroll' } }}
      width={width}
      shadow="md"
      {...rest}
      // withinPortal={false}
    >
      <Combobox.Target>
        {activator ? (
          activator(combobox.toggleDropdown)
        ) : (
          <TextInput
            value={data.find((v) => v.value === value)?.label ?? ''}
            onClick={() => combobox.toggleDropdown()}
            fw={500}
            trailingIcon={
              <ActionIcon
                variant="transparent"
                icon="chevron-down"
                iconSize={10}
                onClick={() => combobox.toggleDropdown()}
              />
            }
          />
        )}
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          styles={{
            input: {
              backgroundColor: extractCSSColor('white'),
              borderBottom: `0.5px solid ${extractCSSColor('gray.2')}`,
              color: extractCSSColor('gray.8'),
            },
          }}
          leftSection={<Icon name="search-major" size={14} />}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search"
        />
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

type OptionProps = {
  item: { label: string; value: string; description?: string; readmeUrl?: string };
  value?: string[] | string;
  name?: string;
  tableName?: string;
};

const Option: FC<OptionProps> = ({ item, value, name, tableName }) => {
  const {
    opened: tooltipOpened,
    open,
    close,
  } = useDelayedDisclosure({
    secondsToWait: 0.15,
    waitBeforeClose: true,
    waitBeforeOpen: true,
  });

  return (
    <Combobox.Option
      value={item.value}
      key={item.value}
      bg={item.value === value ? 'gray.2' : undefined}
      className="font-medium text-[var(--mantine-color-gray-8)] hover:bg-[var(--mantine-color-gray-1)]"
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <ColumnTooltip
        tooltipOpened={tooltipOpened}
        title={item.label}
        subTitle={item.value}
        description={item.description || item.label}
        readmeUrl={item.readmeUrl}
        tracking={() => {
          genericEventLogger(analyticsEvents.METRIC_BUILDER, {
            action: metricBuilderActions.GO_TO_DATA_DICTIONARY,
            table_name: tableName ?? item.label,
            metric_name: name,
            url: item.readmeUrl,
          });
        }}
      >
        <Flex align="center" justify="space-between" gap="sm">
          {item.label}
          {isArray(value) && value.includes(item.value) && <Icon name="check-thin" size={16} />}
        </Flex>
      </ColumnTooltip>
    </Combobox.Option>
  );
};
