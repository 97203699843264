import { logoutOnPress, updateCurrentShopId } from 'ducks/auth';
import React from 'react';
import {
  analyticsEvents,
  errorBoundaryLogger,
  genericEventLogger,
  errorBoundaryActions,
} from 'utils/dataLayer';
import { useAppDispatch } from 'index';
import { Button } from '@tw/ui-components';
import { $userEmail } from '../../$stores/$user';

export class ErrorBoundary extends React.Component<
  {
    children: React.ReactNode;
  },
  {
    hasError: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  override componentDidCatch(error, info) {
    const userEmail = $userEmail.get();

    if (userEmail !== 'willyreports@triplewhale.com') {
      errorBoundaryLogger(analyticsEvents.ERROR_BOUNDARY, {
        action: errorBoundaryActions.BOUNDARY_HIT,
        error: error,
        info: info,
        errorString: error.toString(),
      });
    }
  }

  override render() {
    if (this.state.hasError) {
      return (
        <div id="page" className="h-full">
          <div
            className="flex flex-wrap items-center justify-center h-full gap-20"
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            }}
          >
            <div
              style={{
                width: '400px',
                textAlign: 'center',
                lineHeight: 'normal',
                color: '#1A5378',
              }}
              className="flex flex-col items-center gap-10"
            >
              <p style={{ fontWeight: 'bold', fontSize: '50px' }}>Something went wrong!</p>
              <p style={{ color: '#1A5378', textAlign: 'center', lineHeight: 'normal' }}>
                That's not supposed to happen. We've alerted our engineers about this. Please log in
                and try again.
              </p>
              <div className="flex flex-row gap-4 items-center">
                <ReloadButton />
              </div>
            </div>
            <div style={{ width: '400px' }}>
              <img width="260rem" src="/errorwhale.png" />
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const ReloadButton = () => {
  const onclick = () => {
    genericEventLogger(analyticsEvents.ERROR_BOUNDARY_RELOAD_BUTTON);
    window.location.reload();
  };

  return <Button onClick={onclick}>Reload</Button>;
};

const LoginButton = () => {
  const dispatch = useAppDispatch();

  const resetAndReload = () => {
    genericEventLogger(analyticsEvents.ERROR_BOUNDARY_RESET_BUTTON);

    // We want to clear potentially problematic keys here
    //reset the shop id
    updateCurrentShopId(null);

    //dispatch logout event
    dispatch(logoutOnPress());
  };

  return <Button onClick={resetAndReload}>Log In</Button>;
};
