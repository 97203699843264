import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Text } from '@tw/ui-components';
import { useShowNotificationBanner } from './hooks/useShowNotificationBanner';
import { notificationStyles } from './notificationStyles';
import { NotificationType } from './types';
import { useBannerTracking } from './utils/tracking';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $currentShopId } from '$stores/$shop';
import axiosInstance from 'utils/axiosInstance';

export const InPageNotificationBanner = ({ componentKey }: { componentKey?: string }) => {
  const { shouldShowBanner, inPageBanner, removeInPageBanner } = useShowNotificationBanner();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAlertType = inPageBanner?.notification_type === NotificationType.ALERT || false;
  const isSmall = useIsSmall();

  const { handleClick } = useBannerTracking(inPageBanner, true, shouldShowBanner);
  const bannerArray = Array.isArray(inPageBanner)
    ? inPageBanner
    : Object.values(inPageBanner || {});

  const bannerForCurrentPlacement = bannerArray.find((banner: any) => {
    const { placement_url } = banner;

    if (componentKey && Array.isArray(placement_url)) {
      return placement_url.includes(componentKey);
    }
    if (componentKey && typeof placement_url === 'string') {
      return placement_url === componentKey;
    }

    if (Array.isArray(placement_url)) {
      return placement_url.some((route: string) => pathname.includes(route));
    }
    return pathname.includes(placement_url);
  });

  const isPlacementMatch = Boolean(bannerForCurrentPlacement);
  if (!shouldShowBanner || !inPageBanner || !isPlacementMatch) {
    return null;
  }

  const shopId = $currentShopId.get();

  const handleClose = async () => {
    try {
      await axiosInstance.post(
        `/v2/banner/banner-management/close-notification/${shopId}/${bannerForCurrentPlacement.notification_id}`,
      );
      removeInPageBanner(bannerForCurrentPlacement.notification_id);
    } catch (error) {
      console.error('Failed to record notification closure:', error);
      removeInPageBanner(bannerForCurrentPlacement.notification_id);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: isSmall ? '125px' : '80px',
        margin: '0 auto',
        marginBottom: isSmall ? '24px' : '0',
        borderRadius: '8px',
        ...notificationStyles[bannerForCurrentPlacement.notification_type],
      }}
      className="w-full shadow-md"
    >
      <div className={`relative ${isSmall ? 'p-4' : 'p-6'}`}>
        <div className="flex items-start gap-5">
          {isAlertType && (
            <div className="mt-[2px]">
              <Icon name="exclamation" color="yellow.4" size={20} />
            </div>
          )}
          <div className={`flex flex-col flex-grow ${isSmall ? 'h-full justify-between' : ''}`}>
            <Text
              c={(() => {
                const style = notificationStyles[bannerForCurrentPlacement.notification_type];
                const color = style.headerColor || style.color;
                return color.replace('primary.', 'one.');
              })()}
              fz={14}
              fw={500}
            >
              {bannerForCurrentPlacement.header_text}
            </Text>
            <Text
              c={(() => {
                const style = notificationStyles[bannerForCurrentPlacement.notification_type];
                return style.color.replace('primary.', 'one.');
              })()}
              fz={13}
            >
              {bannerForCurrentPlacement.body_text}
              {!isSmall && !bannerForCurrentPlacement.body_text.match(/[.!?]$/) && '.'}{' '}
              {!isSmall && bannerForCurrentPlacement.placement_url && (
                <span
                  onClick={() => {
                    handleClick();
                    if (bannerForCurrentPlacement.click_target_url) {
                      navigate(bannerForCurrentPlacement.click_target_url);
                    }
                  }}
                  className="underline cursor-pointer font-medium"
                  style={{
                    color: notificationStyles[bannerForCurrentPlacement.notification_type].color,
                    fontSize: '13px',
                  }}
                >
                  {bannerForCurrentPlacement.cta}
                </span>
              )}
            </Text>

            {isSmall && bannerForCurrentPlacement.placement_url && (
              <span
                onClick={() => {
                  handleClick();
                  if (bannerForCurrentPlacement.click_target_url) {
                    navigate(bannerForCurrentPlacement.click_target_url);
                  }
                }}
                className="underline cursor-pointer"
                style={{
                  color: notificationStyles[bannerForCurrentPlacement.notification_type].color,
                  fontSize: '13px',
                }}
              >
                {bannerForCurrentPlacement.cta}
              </span>
            )}
          </div>
        </div>

        <div
          onClick={handleClose}
          className={`cursor-pointer absolute ${isSmall ? 'top-4 right-4' : 'top-4 right-4'}`}
        >
          <Icon
            name="close"
            color={(() => {
              const style = notificationStyles[bannerForCurrentPlacement.notification_type];
              return style.headerColor || style.color;
            })()}
            size={12}
          />
        </div>
      </div>
    </div>
  );
};
