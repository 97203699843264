import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { $derived, $observer } from '@tw/snipestate';
import { MobyFeedItem } from 'components/Willy/types/willyTypes';
import _db, { toArray } from 'utils/DB';

export const $mobyFeedSnapshot = $observer(
  { data: [] as MobyFeedItem[], loading: false, error: null as string | null },
  (get, set) => {
    const shopId = get($currentShopId);
    if (!shopId || !get($userId)) {
      return;
    }

    set({ ...get(), loading: true });
    return _db(shopId)
      .collection('moby_feed')
      .orderBy('lastRunAt', 'desc')
      .onSnapshot((querySnapshot) => {
        let data: MobyFeedItem[] = toArray(querySnapshot);
        set({ ...get(), data, loading: false, error: null });
      });
  },
);

export const $loadingMobyFeed = $derived((get) => get($mobyFeedSnapshot).loading);

export const $mobyFeed = $observer<MobyFeedItem[]>([], (get, set) => {
  if (!get($userId)) return;
  const shopId = get($currentShopId);
  if (!shopId) return set([]);
  const mobyFeedSnapshot = get($mobyFeedSnapshot);
  set(mobyFeedSnapshot.data);
});

export const $unreadMobyFeedItemCount = $derived((get) => {
  const allMobyFeedItems = get($mobyFeed);
  return allMobyFeedItems.filter((item) => !item.read).length;
});

export const $hasMobyFeedPopulated = $derived((get) => {
  const allMobyFeedItems = get($mobyFeed);
  return !!allMobyFeedItems.length;
});
