import { $globalSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { Checkbox, Icon, Modal, Select, Switch, Text, TextInput } from '@tw/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AgentCollection, AgentTopic } from './types/willyTypes';
import firebase from 'firebase/compat/app';
import { firestoreRef, toArray } from 'utils/DB';
import { Button } from '@shopify/polaris';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';

export const EditGlobalAgentAdminDetailsModal = ({ sequenceId, opened, onClose }) => {
  const globalSequences = useStoreValue($globalSequences);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);

  const sequence = useMemo(() => {
    return globalSequences.find((seq) => seq.id === sequenceId);
  }, [sequenceId, globalSequences]);

  const [allAgentCollections, setAllAgentCollections] = useState<AgentCollection[]>([]);
  const [allAgentTopics, setAllAgentTopics] = useState<AgentTopic[]>([]);

  const [sequenceName, setSequnceName] = useState('');
  const [isBeta, setIsBeta] = useState(false);
  const [section, setSection] = useState<string | null>(null);
  const [collections, setCollections] = useState<string[]>([]);
  const [tempAddCollection, setTempAddCollection] = useState<string | null>(null);

  useEffect(() => {
    if (!!opened) {
      (async () => {
        const collectionsSnap = await firebase.firestore().collection('agent_collections').get();
        const colletions = toArray(collectionsSnap) as AgentCollection[];
        setAllAgentCollections(colletions);
      })();
      (async () => {
        const topicsSnap = await firebase.firestore().collection('agent_topics').get();
        const topics = toArray(topicsSnap) as AgentTopic[];
        setAllAgentTopics(topics);
      })();
    }
  }, [opened]);

  useEffect(() => {
    if (sequence) {
      setSequnceName(sequence.name);
      setIsBeta(sequence.isBeta ?? false);
      setSection(allAgentTopics.find((topic) => topic.id === sequence.topic)?.id ?? null);
      const inCollections = allAgentCollections.map((c) => {
        return c.templateIds.includes(sequence.id);
      });
      setCollections(allAgentCollections.filter((c, i) => inCollections[i]).map((c) => c.id));
    }
  }, [sequence, allAgentCollections, allAgentTopics]);

  const onRemoveFromCollection = (collectionId) => {
    setCollections(collections.filter((c) => c !== collectionId));
  };

  const onSave = useCallback(async () => {
    if (!isTwGlobalDashboardCreatorClaim) {
      return;
    }
    // save
    await firestoreRef().collection('global_data_sequences').doc(sequenceId).set(
      {
        name: sequenceName,
        isBeta,
        topic: section,
      },
      { merge: true },
    );

    // Get current state of all collections that need updating
    const collectionsToUpdate = new Set([...collections]);
    const currentCollections = allAgentCollections
      .filter((c) => c.templateIds.includes(sequenceId))
      .map((c) => c.id);
    currentCollections.forEach((id) => collectionsToUpdate.add(id));

    await Promise.all(
      allAgentCollections.map(async (collection) => {
        const { id, templateIds } = collection;
        //if it was added
        if (collections.includes(id) && !templateIds.includes(sequenceId)) {
          return await firestoreRef()
            .collection('agent_collections')
            .doc(id)
            .set(
              {
                templateIds: [...templateIds, sequenceId],
              },
              { merge: true },
            );
          //if it was removed
        } else if (templateIds.includes(sequenceId) && !collections.includes(id)) {
          const newTemplateIds = templateIds.filter((t) => t !== sequenceId);
          return await firestoreRef().collection('agent_collections').doc(id).set(
            {
              templateIds: newTemplateIds,
            },
            { merge: true },
          );
        }
        return;
      }),
    );
    onClose();
  }, [sequenceId, sequenceName, isBeta, section, collections, allAgentCollections, onClose]);

  return (
    <Modal opened={opened} onClose={onClose} title="Edit Admin Details">
      <div className="flex flex-col gap-8">
        <TextInput value={sequenceName} onChange={(v) => setSequnceName(v)} label="Name" />
        <div className="flex flex-col gap-3">
          <Text fw={600}>Collections</Text>
          {collections.map((collection) => {
            const collectionObj = allAgentCollections.find((c) => c.id === collection);
            const collectionTopics = collectionObj?.templateTopics?.map((t) =>
              allAgentTopics.find((at) => at.id === t.id),
            );
            const collectionSection = collectionTopics?.find((t) => t?.id === section)?.id ?? '';
            return (
              <div
                key={collection}
                className="rounded border border-solid border-gray-400 flex flex-col "
              >
                <div className="bg-gray-200 border-0 border-bottom-1 border-solid border-gray-400 p-4 flex justify-between items-center">
                  <Text fw={600}>{collectionObj?.name}</Text>
                  <div
                    className="cursor-pointer"
                    onClick={() => onRemoveFromCollection(collection)}
                  >
                    <Icon name="delete" size={16} color="red.5" />
                  </div>
                </div>
                <div className="p-6">
                  <Text fz={12}>Section</Text>
                  <Select
                    value={collectionSection}
                    data={[
                      { value: '', label: '(Not in a section)' },
                      ...(collectionTopics?.map((t) => ({
                        label: t?.title ?? '',
                        value: t?.id ?? '',
                      })) ?? []),
                    ]}
                    onChange={(v) => setSection(v)}
                  />
                </div>
              </div>
            );
          })}
          <div className="flex items-center gap-2">
            <Select
              value={tempAddCollection}
              data={allAgentCollections.map((c) => ({
                label: c.name,
                value: c.id,
                disabled: collections.includes(c.id),
              }))}
              onChange={(v) => setTempAddCollection(v)}
            />
            <Button
              onClick={() => {
                if (tempAddCollection) {
                  setCollections([...collections, tempAddCollection]);
                  setTempAddCollection(null);
                }
              }}
            >
              Add
            </Button>
          </div>
        </div>
        <Checkbox checked={isBeta} label="Is Beta" />
        <Button onClick={() => onSave()}>Save</Button>
      </div>
    </Modal>
  );
};
