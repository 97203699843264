import React, { useCallback, useEffect, useState } from 'react';
import { AttributionData } from 'types/attribution';
import {
  Button,
  Modal,
  NumberInput,
  Flex,
  Switch,
  Card,
  Text,
  Loader,
  Container,
  Anchor,
  Select,
  TextInput,
  confirm,
  MultiSelect,
} from '@tw/ui-components';
import { $currentShopId, $shop } from '$stores/$shop';
import axiosInstance from 'utils/axiosInstance';
import { Banner, BannerStatus, Tooltip } from '@shopify/polaris';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { setConfirmModal } from './StatusChangeConfirmationModal';
import { useStoreValue } from '@tw/snipestate';

const HOLDBACK_AUDIENCE_PCT_OPTIONS = [0.05, 0.1, 0.15];
const RESULTS_TEXTS = [
  {
    name: 'Optimize for Speed',
    description: 'Faster results with higher revenue impact',
  },
  {
    name: 'Balanced Approach',
    description: 'Moderate speed and revenue impact',
  },
  {
    name: 'Minimize Revenue Impact',
    description: 'Longer test with smaller holdout group',
  },
];

const defaults = {
  effectSizeMin: 0.1,
  effectSizeMax: 0.1,
};

type ExperimentButtonProps = {
  item: AttributionData;
  campaignsList: { value: string; label: string }[];
  setData?: React.Dispatch<React.SetStateAction<AttributionData[]>>;
  sourceId: string;
};
const today = new Date();
const startDate =
  today.getFullYear() +
  '-' +
  String(today.getMonth() + 1).padStart(2, '0') +
  '-' +
  String(today.getDate()).padStart(2, '0');

export const ExperimentButton: React.FC<ExperimentButtonProps> = ({
  item,
  campaignsList,
  setData,
  sourceId,
}) => {
  const shopId = $currentShopId.get();
  const shop = useStoreValue($shop);
  const accounts = shop?.facebookAccounts;
  const accountsList =
    accounts?.map((account) => ({
      label: `${account.name} (${account.id})` || '',
      value: account.id || '',
    })) || [];
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const isExperimentEnabled =
    (sourceId === 'all' && item.serviceId === 'facebook-ads') ||
    (item.experimentData?.is_experiment_enabled && item.status === 'ACTIVE');
  const defaultName =
    sourceId !== 'all'
      ? `[TW Conversion Lift] ${startDate} - ${item?.name || `Unnamed Study`}`
      : `[TW Conversion Lift] ${startDate} - ${accounts?.[0]?.id} Meta Account Study`;
  const endDate = moment.utc(item?.ad_study_end_date).tz(shopTimezone);
  const now = moment().utc().tz(shopTimezone);
  const [hasExperiment, setHasExperiment] = useState(
    !!item?.ad_study_name && endDate.isValid() && endDate.isAfter(now),
  );
  const facebookAdsScopes = useSelector((state: any) => state.facebookAdsScopes);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incrementalPowerAnalysisResult, setIncrementalPowerAnalysisResult] = useState<any[]>([]);
  const [selectedResultIndex, setSlectedResultIndex] = useState<number>(0);
  const [selectedExperiment, setSelectedExperiment] = useState<any>();
  const [manualConfiguration, setManualConfiguration] = useState<boolean>(false);
  const [pixels, setPixels] = useState<Record<string, string>[]>([]);
  const [experimentResult, setExperimentResult] = useState({
    message: '',
    success: '',
    onlyMessage: false,
  });
  const [selectedPixel, setSelectedPixel] = useState<string>('');
  const [campaignIds, setCampaignIds] = useState<string[]>([item.id || '']);
  const [accountId, setAccountId] = useState<string>(accounts?.[0]?.id || '');
  const [adStudyName, setAdStudyName] = useState<string>(defaultName);
  const [availableCampaigns, setAvailableCampaigns] = useState(campaignsList || []);
  const [tooltipContent, setTooltipContent] = useState('');

  const runIncrementalPowerAnalysis = useCallback(async () => {
    setLoading(true);
    const body = {
      shopId: shopId,
      campaignId: item.id,
      holdbackAudiencePct: HOLDBACK_AUDIENCE_PCT_OPTIONS,
      effectSizeMin: defaults.effectSizeMin,
      effectSizeMax: defaults.effectSizeMax,
    };

    const url = 'v2/facebook-ads/run-incremental-power-analysis';
    const { data } = await axiosInstance.post(url, body);

    const enriechedData = data
      .sort((a, b) => a.duration - b.duration)
      .map((result, index) => ({
        ...result,
        ...RESULTS_TEXTS[index],
      }));

    setIncrementalPowerAnalysisResult(enriechedData);
    setLoading(false);
    setSlectedResultIndex(0);
  }, [item.id, shopId]);

  const createExperiment = useCallback(async () => {
    const params = {
      ...selectedExperiment,
      name: adStudyName,
      pixelId: selectedPixel,
      shopId: shopId,
      campaignIds: campaignIds,
      accountId: item.accountId || accountId,
      sourceId,
    };

    setLoading(true);
    const result = await axiosInstance.post('v2/facebook-ads/ad-studies/create', params);
    if (result?.data?.error) {
      setExperimentResult({
        success: 'critical',
        message:
          result.data.error?.response?.error_user_msg ||
          result.data.error?.message ||
          'There was a problem creating the Ad Study',
        onlyMessage: false,
      });
      setLoading(false);
      return;
    }
    const studyResult = result.data?.study || result.data?.studies[0];
    setLoading(false);
    setExperimentResult({
      success: 'success',
      message: 'Experiment created successfully',
      onlyMessage: false,
    });
    setData?.((old) => {
      return old.map((x) => ({
        ...x,
        ad_study_start_date: campaignIds.includes(String(x.id))
          ? new Date().toISOString()
          : x?.ad_study_start_date,
        ad_study_end_date: campaignIds.includes(String(x.id))
          ? new Date(new Date().setDate(new Date().getDate() + params.duration)).toISOString()
          : x?.ad_study_end_date,
        ad_study_name: campaignIds.includes(String(x.id)) ? studyResult?.name : x?.ad_study_name,
        ad_study_id: campaignIds.includes(String(x.id)) ? studyResult.id : x?.ad_study_id,
      }));
    });
    setHasExperiment(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  }, [
    selectedExperiment,
    selectedPixel,
    shopId,
    item.id,
    item.accountId,
    adStudyName,
    campaignIds,
    accountId,
    setData,
  ]);

  const stopExperiment = useCallback(async () => {
    const account_id = item.accountId || item.ad_study_integration_id;
    if (!item.ad_study_id || !account_id) {
      return;
    }
    const params = {
      shopId: shopId,
      adStudyId: item?.ad_study_id,
      objectiveId: item?.ad_study_objective_id,
      cellId: item?.ad_study_cell_id,
      accountId: account_id,
    };
    setIsOpen(true);
    setLoading(true);
    const result = await axiosInstance.post('v2/facebook-ads/ad-studies/update', params);
    if (result?.data?.error) {
      setExperimentResult({
        success: 'critical',
        message:
          result.data.error?.response?.error_user_msg ||
          result.data.error?.message ||
          'There was a problem updating the Ad Study',
        onlyMessage: true,
      });
      setLoading(false);
      return;
    }
    setData?.((old) => {
      return old.map((x) => ({
        ...x,
        ad_study_end_date: x.id === item.id ? new Date().toISOString() : x.ad_study_end_date,
      }));
    });

    setExperimentResult({
      success: 'success',
      message: 'Experiment updated successfully',
      onlyMessage: true,
    });
    setHasExperiment(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  }, [shopId, item, setData]);

  const toggleExperiment = useCallback(
    async (value: boolean) => {
      if (value === true) {
        setIsOpen(true);
      } else {
        const approved = await confirm({
          title: (
            <>
              <b>End Conversion Lift Study</b>
            </>
          ),
          message: (
            <>
              <p>Are you sure you want to end this Conversion Lift study?</p>
              <p>Once ended, you won’t be able to restart or collect additional data.</p>
              <p>Ensure you’ve reviewed the results and gathered the insights you need.</p>
            </>
          ),
          confirmText: 'End Study',
          cancelText: 'Cancel',
        });
        if (approved) {
          stopExperiment();
        }
      }
    },
    [stopExperiment],
  );

  useEffect(() => {
    if (isOpen) {
      runIncrementalPowerAnalysis();
    }
  }, [isOpen, runIncrementalPowerAnalysis]);

  useEffect(() => {
    setSelectedExperiment(incrementalPowerAnalysisResult[selectedResultIndex]);
  }, [incrementalPowerAnalysisResult, selectedResultIndex]);

  useEffect(() => {
    const fetchPixels = async (entityId) => {
      if (isOpen && pixels.length < 1) {
        try {
          const { data } = await axiosInstance.get(
            `v2/facebook-ads/fetch/adspixels?shopDomain=${shopId}&entityId=${entityId}`,
          );
          setPixels(data.result);
          setSelectedPixel(data?.result?.[0].id);
        } catch (error) {
          console.error('Error fetching pixels:', error);
        }
      }
    };
    if (sourceId === 'all') {
      fetchPixels(accountId);
    } else {
      fetchPixels(item.accountId);
    }
  }, [isOpen, pixels, shopId, item, accountId]);

  useEffect(() => {
    if (campaignsList && campaignsList.length > 0) {
      setAvailableCampaigns(campaignsList);
    }
  }, [campaignsList]);

  useEffect(() => {
    if (item?.id) {
      setCampaignIds([item.id || '']);
    }
  }, [item]);

  useEffect(() => {
    if (!facebookAdsScopes?.includes?.('business_management')) {
      setTooltipContent(
        'Need to allow permission for facebook business manager to launch experiments from Triple Whale',
      );
      return;
    }
    if (sourceId === 'all' && !accountId) {
      setTooltipContent('Please select an account to run the test');
    } else if (!campaignIds.length) {
      setTooltipContent('Please select a campaign to run the test');
    } else {
      setTooltipContent('');
    }
  }, [campaignIds, sourceId, accountId, facebookAdsScopes]);

  const handlePercentageChange = useCallback((key, val) => {
    const newValue = Number(val) / 100;
    setSelectedExperiment((prev) => {
      const newValues = {
        ...prev,
        [key]: Number(newValue.toFixed(2)),
      };

      if (key === 'holdout') {
        newValues.treatment = Number((1 - newValue).toFixed(2));
      } else if (key === 'treatment') {
        newValues.holdout = 1 - Number((1 - newValue).toFixed(2));
      }

      return { ...newValues };
    });
  }, []);

  if (!hasExperiment && !isExperimentEnabled) return;

  return (
    <div>
      <Switch
        variant="short"
        checked={hasExperiment}
        disabled={!isExperimentEnabled}
        onChange={(e) => toggleExperiment(e.currentTarget.checked)}
      />
      {experimentResult.onlyMessage ? (
        <Modal
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          title="Conversion Lift Test Configuration"
          size="md"
        >
          <Modal.Body>
            {experimentResult.message && (
              <Banner
                title={experimentResult.message}
                status={experimentResult.success as BannerStatus}
              />
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          title="Conversion Lift Test Configuration"
          size="md"
        >
          <Modal.Body>
            {experimentResult.message && (
              <Banner
                title={experimentResult.message}
                status={experimentResult.success as BannerStatus}
              />
            )}
            {incrementalPowerAnalysisResult.length < 1 ? (
              <Flex align="center" justify="center">
                <Loader />
              </Flex>
            ) : (
              // results
              <Flex gap="xs" direction="column">
                <Flex gap="xl" mb="lg" direction="column">
                  <TextInput
                    label={
                      <>
                        Ad Study Name
                        <Text size="xs" color="gray.5">
                          Enter a descriptive name for your ad study
                        </Text>
                      </>
                    }
                    value={adStudyName}
                    onChange={(val) => setAdStudyName(val)}
                  />
                  {sourceId === 'all' ? (
                    <Select
                      label={
                        <>
                          Select Ad Study Meta Account
                          <Text size="xs" color="gray.5">
                            Select the Meta Ad Account that you want to measure
                          </Text>
                        </>
                      }
                      data={accountsList}
                      value={accountId}
                      onChange={(value) => {
                        setPixels([]);
                        setAdStudyName(
                          `[TW Conversion Lift] ${startDate} - ${value} Meta Account Study`,
                        );
                        setAccountId(value || '');
                      }}
                    />
                  ) : (
                    <MultiSelect
                      required
                      label={
                        <>
                          Select Ad Study Campaign(s)
                          <Text size="xs" color="gray.5">
                            Select the campaigns that you want to measure
                          </Text>
                        </>
                      }
                      value={campaignIds}
                      data={availableCampaigns}
                      onChange={(value) => {
                        setCampaignIds(value);
                      }}
                    />
                  )}
                  {!pixels.length && <Loader size="sm" />}
                  {pixels.length > 0 && (
                    <>
                      <Select
                        label={
                          <>
                            Select Pixel
                            <Text size="xs" color="gray.5">
                              Select the pixel that aligns with the primary conversion event you
                              want to measure
                            </Text>
                          </>
                        }
                        data={pixels.map((pixel) => ({
                          label: `${pixel.name} (${pixel.id})`,
                          value: pixel.id,
                        }))}
                        value={selectedPixel || ''}
                        onChange={(val) => setSelectedPixel(val || '')}
                      />
                    </>
                  )}
                </Flex>
                <Flex gap="xs" direction="column" cursor="pointer">
                  {incrementalPowerAnalysisResult.map((result, index) => (
                    <Card
                      p="xs"
                      onClick={() => setSlectedResultIndex(index)}
                      key={index}
                      w="100%"
                      shadow="xs"
                      borderColor="one.3"
                      bg={index === selectedResultIndex ? 'two.0' : 'white'}
                      withBorder={index === selectedResultIndex}
                    >
                      <Text fw="bolder">{result.name}</Text>
                      <Text color="gray.5">{result.description}</Text>
                      <Text color="gray.5">
                        {result.holdout * 100}% holdout • {result.duration} days
                      </Text>
                    </Card>
                  ))}
                </Flex>
                {selectedExperiment && (
                  <Flex direction="column" mt="md" gap="xs">
                    <NumberInput
                      label="Holdout Group"
                      description="Percentage of audience that won't see your campaign"
                      disabled={!manualConfiguration}
                      min={0}
                      max={100}
                      value={Number(selectedExperiment.holdout) * 100}
                      onChange={(val) => handlePercentageChange('holdout', val)}
                      rightSection={<>%</>}
                    />
                    <NumberInput
                      label="Test Duration"
                      description="Time period to run the test with reduced campaign reach"
                      disabled={!manualConfiguration}
                      value={selectedExperiment.duration}
                      onChange={(val) =>
                        setSelectedExperiment({ ...selectedExperiment, duration: val })
                      }
                    />
                    {/* <NumberInput
                    label="treatment pct"
                    disabled={!manualConfiguration}
                    min={0}
                    max={100}
                    value={Number(selectedExperiment.treatment) * 100}
                    onChange={(val) => handlePercentageChange('treatment', val)}
                    rightSection={<>%</>}
                  /> */}
                    <Container m={0} p={0}>
                      <Switch
                        label="Manual Configuration"
                        checked={manualConfiguration}
                        onChange={(e) => setManualConfiguration(e.currentTarget.checked)}
                      />
                      {manualConfiguration && (
                        <Text size="sm" color="yellow.4">
                          Custom settings may affect test reliability
                        </Text>
                      )}
                    </Container>
                  </Flex>
                )}
              </Flex>
            )}
          </Modal.Body>

          <Modal.Footer border>
            <Flex direction="column" gap="xs" align="center">
              {selectedExperiment &&
                (tooltipContent ? (
                  <div style={{ width: '100%' }}>
                    <Tooltip content={tooltipContent}>
                      <span>
                        <Button
                          variant="primary"
                          loading={loading}
                          onClick={createExperiment}
                          disabled
                          fullWidth
                        >
                          Run Test
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  <Button variant="primary" loading={loading} onClick={createExperiment} fullWidth>
                    Run Test
                  </Button>
                ))}
              <Anchor href="https://www.facebook.com/business/help/221353413010930?id=547299432790676">
                Learn more about Conversion Lift Tests on Meta
              </Anchor>
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
