import React, { useEffect, useState } from 'react';
import { Checkbox, RadioButton } from '@shopify/polaris';
import { Button } from '@tw/ui-components';
import { useSelector } from 'react-redux';
import axiosInstance from '../../utils/axiosInstance';
import { fetchShopPixelSetup } from '../../ducks/pixel';
import { useAppDispatch } from '../../index';
import { salesPlatformDisplayName } from '@tw/types/src/services/general/SalesPlatform';

export const PixelInstallationStoreSetup: React.FC<{}> = ({}) => {
  const shop_setup = useSelector((state: any) => state.shopPixelSetup);
  const [siteConfig, setSiteConfig] = useState<any>(shop_setup);
  const shopRef = useSelector((state: any) => state.currentShopId);
  const msp = useSelector((state: any) => state.msp);
  const [isEditMode, setIsEditMode] = useState(true);
  const dispatch = useAppDispatch();
  const regularSetup = !['stripe', 'custom-msp', 'salesforce-cc'].includes(msp);

  useEffect(() => {
    const newValue = { ...shop_setup };
    setSiteConfig(newValue);
  }, [shop_setup]);

  function updateSiteConfig(key: string, value: boolean) {
    const newValue = { ...siteConfig };
    newValue[key] = value;
    setSiteConfig(newValue);
  }

  async function saveSiteConfig() {
    await axiosInstance.post('v2/pixel/save-shop-pixel-setup', {
      shopDomain: shopRef,
      siteConfig: toLower(siteConfig),
    });
    dispatch(fetchShopPixelSetup());
    setIsEditMode(false);
  }

  function toLower(siteConfig: any) {
    const obj = {};
    Object.keys(siteConfig).forEach((k) => (obj[k.toLowerCase()] = siteConfig[k]));
    return obj;
  }

  function getSiteConfigDescription(args) {
    return args.filter((a) => a).join(', ');
  }

  return (
    <>
      <div id="pixel-installation-store-setup">
        {isEditMode ? (
          <>
            <div className="content flex flex-col w-full h-full">
              <p className="text-[18px] bolder mb-[15px]">Store Setup</p>
              <p className="text-[16px] bolder mb-[5px]">Shop Implementation</p>
              {regularSetup && (
                <RadioButton
                  label={`Hosted by ${salesPlatformDisplayName[msp]} / Standard`}
                  checked={siteConfig['shopify']}
                  onChange={(e) => updateSiteConfig('shopify', e)}
                />
              )}
              <RadioButton
                label={`${salesPlatformDisplayName[msp]} Headless`}
                checked={siteConfig['shopify'] == false}
                onChange={(e) => updateSiteConfig('shopify', !e)}
              />
              <p className="text-[16px] bolder mt-[20px] mb-[5px]">Checkout</p>
              {regularSetup && (
                <>
                  <Checkbox
                    label={`Standard ${salesPlatformDisplayName[msp]} Checkout`}
                    checked={siteConfig['shopify_checkout']}
                    onChange={(e) => updateSiteConfig('shopify_checkout', e)}
                  />
                  <Checkbox
                    label="Third-Party Checkout"
                    checked={siteConfig['third_party_checkout']}
                    onChange={(e) => updateSiteConfig('third_party_checkout', e)}
                  />
                </>
              )}
              <Checkbox
                label="Custom Checkout"
                checked={siteConfig['custom_checkout']}
                onChange={(e) => updateSiteConfig('custom_checkout', e)}
              />
              <p className="text-[16px] bolder mt-[20px] mb-[5px]">
                Do you have any third-party landing pages or other pages hosted on a sub-domain or
                different domain?
              </p>
              <RadioButton
                label="Yes"
                checked={siteConfig['additional_pages']}
                onChange={(e) => updateSiteConfig('additional_pages', e)}
              />
              <RadioButton
                label="No"
                checked={siteConfig['additional_pages'] == false}
                onChange={(e) => updateSiteConfig('additional_pages', !e)}
              />
              <div className="mt-[20px]">
                <Button w={'150px'} onClick={() => saveSiteConfig()}>
                  Save Setup
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="content flex flex-col w-full h-full">
              <div className="flex space-between">
                <div className="bolder text-[18px]">Here is what you told us about your shop</div>
                <Button onClick={() => setIsEditMode(true)}>Update my shop details</Button>
              </div>

              <div className="text-[14px] description mb-[20px]">
                <b>Shop setup:</b>{' '}
                {getSiteConfigDescription([
                  siteConfig['shopify'] ? `Standard ${salesPlatformDisplayName[msp]}` : '',
                  siteConfig['shopify'] == false ? `${salesPlatformDisplayName[msp]} Headless` : '',
                ])}
              </div>
              <div className="text-[14px] description mb-[20px]">
                <b>Checkout:</b>{' '}
                {getSiteConfigDescription([
                  siteConfig['shopify_checkout']
                    ? `Standard ${salesPlatformDisplayName[msp]} Checkout`
                    : '',
                  siteConfig['third_party_checkout'] ? 'Third-party Checkout' : '',
                  siteConfig['custom_checkout'] ? 'Custom Checkout' : '',
                ])}
              </div>
              <div className="text-[14px] description mb-[20px]">
                <b>Third-Party Landing Page?</b> {siteConfig['additional_pages'] && 'Yes'}
                {siteConfig['additional_pages'] == false && 'No'}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
