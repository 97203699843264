import copyToClipboard from 'utils/copyToClipboard';
import axiosInstance from '../../utils/axiosInstance';
import { Button, Icon } from '@tw/ui-components';
import React from 'react';
import Prism from 'prismjs';

export const getSnippetCode = async () => {
  return (await axiosInstance.get('v2/pixel/get-pixel-script-str')).data;
};

export const PixelSnippet = ({ snippetCode, lang, overflow = true }) => {
  const copySuccess = (snippet) => {
    copyToClipboard(snippet);
  };
  const code = snippetCode?.startsWith('\n') ? snippetCode : `\n${snippetCode}`;

  const codeHtml = Prism.highlight(
    code,
    lang == 'html' ? Prism.languages.html : Prism.languages.javascript,
    lang,
  );
  return (
    <>
      <div className="pixel-snippet" style={{ position: 'relative' }}>
        <div className="top-[3px] right-[0] text-right h-[15px] pt-[3px] pr-[3px] absolute ">
          <Button onClick={() => copySuccess(snippetCode)}>
            <Icon name="copy-2"></Icon>
          </Button>
        </div>
        <pre style={{ overflow: overflow ? 'scroll' : 'auto' }}>
          <code className="language-html" dangerouslySetInnerHTML={{ __html: codeHtml }}></code>
        </pre>
        <style>
          {`
            .pixel-snippet button.mantine-Button-root {
              width: 18px !important;
              height: 18px !important;
              background: white !important;
              color: black !important;
              padding: 1px !important;
              &:focus-visible,&:focus {
                outline: none !important;
                box-shadow: none !important;
                padding: 0 !important;
              }
              svg {
                  color: black !important;
                  fill: #000000 !important;
              }
            }
            .pixel-snippet code[class*="language-"], pre[class*="language-"] {
              color: black;
              font-family: monospace;
              text-shadow: none;
              background-color: #ededed;
            }
            .pixel-snippet pre {
              background: #ededed !important;
            }
        `}
        </style>
      </div>
    </>
  );
};
