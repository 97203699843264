import moment from '@tw/moment-cached/module/timezone';
import { INIT_SHOP } from 'ducks/constants';
import { $activeAccounts, $currentShopId } from '$stores/$shop';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import axiosInstance from 'utils/axiosInstance';
import { SignUpStep } from '../routes/auth/SignUp/signUpStep.enum';
import { subscriptionRevenue } from '@tw/types/module/services/subscription-manager';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useStoreValue } from '@tw/snipestate';
import { $currentRevenue, $isFreeShop } from '$stores/willy/$subscription';
import { openBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { AFFLUENCER } from 'constants/types';
import { setUpgradeClickSource } from './signupJourney';
import { UpgradeClickedSource } from 'components/Willy/types/willyTypes';
const analytics = firebase.analytics;

export const BETA_END = '2021-06-23';

export const APP_INVITES_START = '2021-11-02';

export const FREE_TRIAL_INDICATOR_ON_PRESS = 'FREE_TRIAL_INDICATOR_ON_PRESS';
export const freeTrialIndicatorOnPress = () => {
  return (dispatch) => {
    dispatch({
      type: FREE_TRIAL_INDICATOR_ON_PRESS,
    });
  };
};

export const SUBSCRIPTION_MODAL_ON_CLOSE = 'SUBSCRIPTION_MODAL_ON_CLOSE';
export const subscriptionModalOnClose = () => ({
  type: SUBSCRIPTION_MODAL_ON_CLOSE,
});

export const SUBSCRIPTION_PRODUCTS_LOADED = 'SUBSCRIPTION_PRODUCTS_LOADED';
export const subscriptionProductsLoaded = (products) => {
  return {
    type: SUBSCRIPTION_PRODUCTS_LOADED,
    products,
  };
};

export const REDIRECTING_TO_PAYMENT = 'REDIRECTING_TO_PAYMENT';
export const SUBSCRIPTION_PAYMENT_ON_ACTION = 'SUBSCRIPTION_PAYMENT_ON_ACTION';
export const SUBSCRIPTION_PAYMENT_ON_SERVICE_ERROR = 'SUBSCRIPTION_PAYMENT_ON_SERVICE_ERROR';
export const START_SUBSCRIPTION = 'START_SUBSCRIPTION';

export const SALES_TIER_DETECTED = 'SALES_TIER_DETECTED';
export const salesTierDetected = (totalSales) => {
  return {
    type: SALES_TIER_DETECTED,
    totalSales,
  };
};

//TODO create reducer and pull is duplicate

const ORDER_SUMMARY_DONE = 'ORDER_SUMMARY_DONE';

export const orderSummaryDone = () => (dispatch) => {
  return dispatch({
    type: ORDER_SUMMARY_DONE,
  });
};

export const SIGNUP_FLOW_START = 'SIGNUP_FLOW_START';
export const signupFlowStarted = () => ({
  type: SIGNUP_FLOW_START,
});

export const GET_REVENUES = 'GET_REVENUES';
export const getRevenues = () => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/revenues`;
      const { data } = await axiosInstance.get(url);
      return dispatch({
        type: GET_REVENUES,
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const OPEN_UPGRADE_PLAN_MODAL = 'OPEN_UPGRADE_PLAN_MODAL';
export const CLOSE_UPGRADE_PLAN_MODAL = 'CLOSE_UPGRADE_PLAN_MODAL';

export const upgradePlanClicked =
  (navigate: ReturnType<typeof useNavigate>, source?: UpgradeClickedSource) => async (dispatch) => {
    const currentShopId = $currentShopId.get();
    const searchLocation = new URLSearchParams(location.search);
    const path = location.pathname;
    const shopId = searchLocation.get('shopId');
    const internalShop = currentShopId || shopId;
    const isFreeShop = $isFreeShop.get();
    const currentRevenue: number = $currentRevenue.get();

    const getShopRevenueId = async () => {
      let shopRevenueId = 0;
      if (currentRevenue) {
        const { data } = await axiosInstance.post(
          `v2/subscription-manager/revenues/getTierByAnnualRevenue`,
          { currentRevenue, shopId: internalShop },
        );
        shopRevenueId = +data;
      }
      return { shopRevenueId, currentRevenue };
    };

    if (internalShop) {
      let { shopRevenueId, currentRevenue } = await getShopRevenueId();
      // if (currentRevenue === undefined) {
      //   toast.info('We are still calculating your revenue. Please try again in a few minutes.', {
      //     position: 'top-center',
      //     autoClose: false,
      //   });
      // } else
      if (isFreeShop && (currentRevenue < 1000000 || !currentRevenue)) {
        dispatch(setUpgradeClickSource(path));
        navigate('/store-settings/plans');
      } else {
        openBookDemoModal(source ?? 'pricing');
        await axiosInstance.post('/v2/bi/hubspot-update', {
          source: 'UPDATE_COMPANY',
          shopDomain: currentShopId,
          demo_source: location.pathname.slice(1),
        });
      }
    }
  };

export const upgradePlanClosed = () => async (dispatch) => {
  return dispatch({
    type: CLOSE_UPGRADE_PLAN_MODAL,
  });
};

const upgradePlanModalOpen = (state = { isModalOpen: false, productId: undefined }, action) => {
  switch (action.type) {
    case OPEN_UPGRADE_PLAN_MODAL:
      return { isModalOpen: true, productId: action.payload };
    case CLOSE_UPGRADE_PLAN_MODAL:
      return { isModalOpen: false };
    default:
      return state;
  }
};

export const GET_SUB_INVOICES = 'GET_SUB_INVOICES';
export const getInvoicesForSubscription = (subId) => {
  return async (dispatch) => {
    try {
      const url = `v2/subscription-manager/invoices/get-invoices/${subId}`;
      const data = await axiosInstance.get(url);
      return dispatch({
        type: GET_SUB_INVOICES,
        data: data?.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const dayOfTrial = (state = 0, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var today = moment();
      var { createdAt } = action;
      var createdAtMoment = createdAt?.toDate ? moment(createdAt.toDate()) : moment();
      var diff = today.diff(createdAtMoment, 'days');
      return diff;
    default:
      return state;
  }
};

const totalSalesTier = (state = 0, action) => {
  switch (action.type) {
    case SALES_TIER_DETECTED:
      return action.totalSales;
    default:
      return state;
  }
};

const subscriptionProducts = (state = [], action) => {
  switch (action.type) {
    case SUBSCRIPTION_PRODUCTS_LOADED:
      return action.products;
    default:
      return state;
  }
};

const redirectingToPayment = (state = false, action) => {
  switch (action.type) {
    case REDIRECTING_TO_PAYMENT:
      return true;
    default:
      return state;
  }
};

const userForceEndFreeTrial = (state = false, action) => {
  switch (action.type) {
    case FREE_TRIAL_INDICATOR_ON_PRESS:
      return true;
    case SUBSCRIPTION_MODAL_ON_CLOSE:
      return false;
    default:
      return state;
  }
};

const isBetaUser = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      return createdAt?.toDate
        ? moment(createdAt.toDate()).isBefore(moment(BETA_END).endOf('day'))
        : false;
    default:
      return state;
  }
};

const hasAuto7daysFreeTrial = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      return createdAt?.toDate
        ? moment(createdAt.toDate()).isBefore(moment(APP_INVITES_START).endOf('day'))
        : false;
    default:
      return state;
  }
};

const freeTrialDays = (state = 0, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { freeTrialDays } = action;
      return freeTrialDays || 0;
    default:
      return state;
  }
};

const shopifyAccessToken = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.shopifyAccessToken || state;
    default:
      return state;
  }
};

type RevenueAction = { type: string; data?: subscriptionRevenue[] };
const revenues = (state = new Array<subscriptionRevenue>(), action: RevenueAction) => {
  switch (action.type) {
    case GET_REVENUES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

const stripeInvoices = (state = [], action) => {
  switch (action.type) {
    case GET_SUB_INVOICES: {
      return action.data || state;
    }
    default: {
      return state;
    }
  }
};

export const GET_SHOP_REVENUE_TIER = 'GET_SHOP_REVENUE_TIER';
export const getRevenueIdForShop = (revenue) => async (dispatch) => {
  try {
    if (revenue) {
      const { data } = await axiosInstance.post(
        `v2/subscription-manager/revenues/getTierByAnnualRevenue`,
        { revenue, shopId: $currentShopId.get() },
      );
      return dispatch({
        type: GET_SHOP_REVENUE_TIER,
        payload: data,
      });
    } else {
      return dispatch({
        type: GET_SHOP_REVENUE_TIER,
        payload: 0,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const shopRevenueTier = (state = 0, action) => {
  switch (action.type) {
    case GET_SHOP_REVENUE_TIER:
      return action.payload ?? state;
    default: {
      return state;
    }
  }
};

export const getSubscriptions = async (
  dateModel,
  attributionModel,
  source,
  type,
  id,
  unmatchedIds,
  start,
  end,
  name,
  attributionWindow,
  useNewModels,
  integrationId,
  campaignIds,
  adsetId,
  esKey = '',
) => {
  const attributionFilters: any = [];

  const handleSingleValue = (value) => {
    // `(not-set)` should be simple string
    return value?.length === 1 ? value[0] : value;
  };

  // push current entity filter
  let entityId;
  if (source === AFFLUENCER) {
    entityId = name || id;
  } else {
    entityId = type === 'campaignId' && esKey ? esKey : id;
  }
  const filterValue = [...(entityId ? [entityId] : []), ...(unmatchedIds || [])];
  attributionFilters.push({ key: type, value: handleSingleValue(filterValue) });

  //  push parent entities filter
  if (campaignIds.length && type !== 'campaignId') {
    attributionFilters.push({ key: 'campaignId', value: handleSingleValue(campaignIds) });
  }
  if (adsetId.length && type !== 'adsetId') {
    attributionFilters.push({ key: 'adsetId', value: handleSingleValue(adsetId) });
  }
  const params: any = {
    shopDomain: $currentShopId.get(),
    additionalShopIds: $activeAccounts.get() || [],
    integrationId,
    model: attributionModel,
    attributionFilters,
    startDate: start,
    endDate: end,
    isClickDate: dateModel === 'clickDate',
    source: source === 'tw_referrer' ? 'organic_and_social' : source,
    attributionWindow,
    useNewModels,
  };

  let response;
  ({ data: response } = await axiosInstance.post(
    `v2/attribution/get-attributed-subscriptions`,
    params,
  ));

  const subscriptions =
    response?.subscriptions?.sort((a, b) => (a.eventDate < b.eventDate ? 1 : -1)) || [];
  return subscriptions;
};

export const OPEN_UPGRADE_PIXEL_MODAL = 'OPEN_UPGRADE_PIXEL_MODAL';
export const CLOSE_UPGRADE_PIXEL_MODAL = 'CLOSE_UPGRADE_PIXEL_MODAL';

export const upgradePixelClosed = () => async (dispatch) => {
  return dispatch({
    type: CLOSE_UPGRADE_PIXEL_MODAL,
  });
};

export const upgradePixelModalOpened = (source) => async (dispatch) => {
  return dispatch({
    type: OPEN_UPGRADE_PIXEL_MODAL,
    payload: source,
  });
};

const upgradePixelModalOpen = (state = { isModalOpen: false, source: undefined }, action) => {
  switch (action.type) {
    case OPEN_UPGRADE_PIXEL_MODAL:
      return { isModalOpen: true, source: action.payload };
    case CLOSE_UPGRADE_PIXEL_MODAL:
      return { isModalOpen: false };
    default:
      return state;
  }
};

export const reducers = {
  dayOfTrial,
  totalSalesTier,
  subscriptionProducts,
  redirectingToPayment,
  userForceEndFreeTrial,
  isBetaUser,
  hasAuto7daysFreeTrial,
  freeTrialDays,
  shopifyAccessToken,
  stripeInvoices,
  revenues,
  upgradePlanModalOpen,
  shopRevenueTier,
  upgradePixelModalOpen,
};
