import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { capitalize } from 'lodash';
import { AttributionData } from 'types/attribution';
import { AttributionTableMetadata } from 'types/attribution';
import { AttributionPageContext } from 'constants/attribution';
import {
  genericEventLogger,
  analyticsEvents,
  attributionActions,
  attributionMessages,
} from 'utils/dataLayer';
import UpdatableColumn from './updatableColumn';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';
import { providers } from 'ducks/shopIntegrations';
import allServices from 'constants/services';
import { UpdateEntityParamsFromClient } from '@tw/types/module/pixel';

type UpdatableBudgetProps = {
  attribution: AttributionData;
  metadata?: AttributionTableMetadata;
};

const UpdatableBudget: React.FC<UpdatableBudgetProps> = ({ attribution, metadata }) => {
  const isReadOnly = !allServices[attribution.serviceId!]?.updatableFields?.['budget'];

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopCurrency = useStoreValue($shopCurrency);
  const accCurrency = useSelector((state: RootState) => state.facebookAdsAccounts[0]?.currency);
  const { setData } = useContext(AttributionPageContext);
  const budgetType = attribution.budgetType;
  const active = attribution.status === 'ACTIVE';
  const value = attribution['budget'] || 0;
  const { hasUpdateScope, updateAdsAllowed, setIntegrationsPopUpOpened } = metadata || {};
  const providerList = useSelector(providers);

  const tooltipText = updateAdsAllowed
    ? 'Edit Budget'
    : 'Contact the Customer Experience team to enable this feature for your account';

  const updateBudget = async (newBudget: number, oldBudget: number) => {
    if (!hasUpdateScope) {
      if (setIntegrationsPopUpOpened) {
        setIntegrationsPopUpOpened(true);
      }
      return { message: 'More permissions are needed', success: false };
    }
    let message = '',
      success = false;
    try {
      const [serviceId, props] =
        Object.entries(providerList).find(([id, v]) => id === attribution.serviceId) || [];
      let updateURI = '';
      if (serviceId && props?.isSensory) {
        updateURI = `/v2/integration/integrations/update-entity`;
      } else {
        updateURI =
          attribution.serviceId === 'facebook-ads'
            ? '/v2/facebook-ads/update-ad'
            : `/v2/${attribution.serviceId}/update-entity`;
      }
      const { data } = await axiosInstance.post(updateURI, {
        shopId: currentShopId,
        accountId: attribution.accountId,
        entity: attribution.entity,
        id: attribution.id,
        field: `${budgetType}_budget`,
        value: attribution.serviceId === 'facebook-ads' ? newBudget * 100 : newBudget,
        oldValue: attribution.serviceId === 'facebook-ads' ? oldBudget * 100 : oldBudget,
        integrationId: attribution?.integrationId,
      } as UpdateEntityParamsFromClient);

      if (data.error) {
        message = data.error;
        genericEventLogger(analyticsEvents.ATTRIBUTION, {
          action: attributionActions.SAVE_BUDGET_ERROR,
          message: attributionMessages.SAVE_BUDGET_ERROR,
          error: message,
          shop: currentShopId,
          id: attribution.id,
          campaignId: attribution.campaignId,
          campaignName: attribution.campaignName,
          adsetId: attribution.adsetId,
          adsetName: attribution.adsetName,
        });
      } else {
        message = attributionMessages.SAVE_BUDGET_SUCCESS;
        success = true;
        setData((old) => {
          return old.map((x) => ({
            ...x,
            budget: x.id === attribution.id ? newBudget : x.budget,
            adsets: x.adsets?.map((set) => ({
              ...set,
              budget: set.id === attribution.id ? newBudget : set.budget,
            })),
          }));
        });
        genericEventLogger(analyticsEvents.ATTRIBUTION, {
          action: attributionActions.SAVE_BUDGET_SUCCESS,
          shop: currentShopId,
          id: attribution.id,
          campaignId: attribution.campaignId,
          campaignName: attribution.campaignName,
          adsetId: attribution.adsetId,
          adsetName: attribution.adsetName,
          message,
          newBudget,
        });
      }
    } catch (e) {
      message = e?.message || e?.data?.errorMessage;
      genericEventLogger(analyticsEvents.ATTRIBUTION, {
        action: attributionActions.SAVE_BUDGET_ERROR,
        message: attributionMessages.SAVE_BUDGET_ERROR,
        shop: currentShopId,
        id: attribution.id,
        campaignId: attribution.campaignId,
        campaignName: attribution.campaignName,
        adsetId: attribution.adsetId,
        adsetName: attribution.adsetName,
        error: message,
      });
    }
    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: attributionActions.SAVE_BUDGET,
      shop: currentShopId,
      id: attribution.id,
      campaignId: attribution.campaignId,
      campaignName: attribution.campaignName,
      adsetId: attribution.adsetId,
      adsetName: attribution.adsetName,
      message,
      success,
      newBudget,
    });
    return { message, success };
  };

  const formatNoBudgetText = useMemo(() => {
    if (attribution.entity === 'ad') {
      return '';
    }
    const budgetLevel = attribution['budgetLevel'];
    if (!budgetLevel) {
      return '-';
    }
    return `${capitalize(budgetLevel)} Budget`;
  }, [attribution]);

  if (!active) {
    return (
      <p className={`text-secondary-text flex items-center gap-2 pl-4 pt-3 justify-center`}>-</p>
    );
  }

  return (
    <>
      <UpdatableColumn
        update={updateBudget}
        tooltipText={tooltipText}
        permitted={!!updateAdsAllowed}
        noValueText={formatNoBudgetText}
        value={value}
        canEdit={!!value && active}
        isReadOnly={isReadOnly}
        active={active}
        type={capitalize(budgetType)}
        currency={accCurrency || shopCurrency}
      />
    </>
  );
};

export default UpdatableBudget;
