import {
  subscription,
  subscriptionPlan,
  updateSubscriptionRequest,
} from '@tw/types/module/services/subscription-manager';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { SelectedAddon } from '../Addons/AddonTypes';
import { $availablePlans, $shopSubscription } from '$stores/willy/$subscription';
import { initFeatureFlagComputer } from 'feature-flag-system';
import { SUPPORTED_BILLING_GENERATIONS, DEFAULT_BILLING_GENERATION, isProd } from '@tw/constants';
import { $bannerState } from 'components/bannerNotifications/hooks/useShowNotificationBanner';

export const updateSubscription = async (
  subscription: subscription,
  selectedProduct: subscriptionPlan,
  selectedAddons: SelectedAddon[],
  body: updateSubscriptionRequest,
) => {
  if (!selectedProduct) {
    toast.error('No product selected. please select a product');
    return;
  }
  try {
    const subscribePrices = selectedAddons.reduce(
      (acc, addon) => {
        if (
          !subscription.items?.find(
            (item) =>
              item.price_id === addon.addon.price_id &&
              !item.plan_preview_start_date &&
              !item.plan_preview_end_date,
          )
        ) {
          const subscribeAddon = { price: addon.addon.price_id, quantity: addon.quantity };
          acc.push(subscribeAddon);
        }
        return acc;
      },
      [] as Array<{ price: string; quantity: number }>,
    );

    if (
      !subscription.items?.find(
        (item) =>
          item.price_id === selectedProduct.price_id &&
          !item.plan_preview_start_date &&
          !item.plan_preview_end_date,
      )
    ) {
      subscribePrices.push({ price: selectedProduct.price_id, quantity: 1 });
    }
    const unsubscribePrices = subscription.items
      ?.filter((item) => {
        if (
          !subscribePrices.find(({ price }) => price === item.price_id)
          // item.price_id !== selectedProduct.price_id &&
          //!selectedAddons.find((s) => s.addon.price_id === item.price_id)
        )
          return item.subscription_item;
      })
      ?.map((item) => item.subscription_item);

    const changedQuantityAddons = subscription.items?.reduce(
      (acc, item) => {
        const subscribedAddon = selectedAddons.find(
          (addon) => addon.addon.price_id === item.price_id,
        );
        if (subscribedAddon && item.quantity !== subscribedAddon.quantity) {
          acc.push({ subItemId: item.subscription_item, quantity: subscribedAddon.quantity });
        }
        return acc;
      },
      [] as Array<{ subItemId: string; quantity: number }>,
    );

    // Check if contract type is changing and include it in the request body if it is
    if (subscription.contract_type !== selectedProduct.contract_type) {
      body.contractType = selectedProduct.contract_type;
    }

    body.subscribe = subscribePrices;
    body.unsubscribe = unsubscribePrices ?? [];
    body.change = changedQuantityAddons;
    body.upgradeSource = 'store-settings/plans';
    if (
      subscription?.generation &&
      !SUPPORTED_BILLING_GENERATIONS.includes(subscription?.generation)
    ) {
      body.generation = DEFAULT_BILLING_GENERATION;
    }

    const { data: newSubscription } = await axiosInstance.patch(
      '/v2/subscription-manager/subscriptions/' + subscription.subscription,
      body,
    );
    $shopSubscription.set(newSubscription);
    $availablePlans.refresh();
    $bannerState.set({
      globalBanner: null,
      inPageBanner: null,
      hasFetched: true,
      isGlobalBannerVisible: true,
      needsRefetch: false,
    });
    await initFeatureFlagComputer(subscription.shop, []);
    toast.success('The subscription has been updated successfully');
  } catch (err) {
    toast.error(`Something went wrong - ${err.data?.message}`);
    console.log(err);
  }
};

export const addAddonToSubscription = async (
  subscription: subscription,
  addon: subscriptionPlan,
  quantity?: number,
  newPromotionCodeId?: string,
) => {
  try {
    const body: updateSubscriptionRequest = {
      subscribe: [],
      unsubscribe: [],
    };
    if (newPromotionCodeId) {
      body.promotionCodeId = newPromotionCodeId;
    }
    if (quantity && addon.upgradeData?.current_quantity) {
      const subItemId = subscription.items?.find(
        (item) => item.product_id === addon.product_id,
      )?.subscription_item;
      if (subItemId) {
        body.change = [{ subItemId, quantity: quantity }];
      }
    } else {
      body.subscribe = quantity ? [{ price: addon.price_id, quantity }] : [addon.price_id];
    }
    body.upgradeSource = 'store-settings/plans';

    const { data: newSubscription } = await axiosInstance.patch(
      '/v2/subscription-manager/subscriptions/' + subscription.subscription,
      body,
    );
    $shopSubscription.set(newSubscription);
    $availablePlans.refresh();
    await initFeatureFlagComputer(subscription.shop, []);
    toast.success('The subscription has been updated successfully');
  } catch (err) {
    toast.error(`Something went wrong - ${err.data?.message}`);
    console.log(err);
  }
};
