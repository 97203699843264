import React, { useCallback, useState } from 'react';
import { AttributionData } from 'types/attribution';
import { MetricsKeys } from 'types/metrics';
import { formatNumber } from 'utils/formatNumber';
import { metrics } from 'constants/metrics/metrics';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import Conversions from './Conversions';

type ConversionsButtonProps = {
  item: AttributionData;
  metricKey: string;
  format: 'decimal' | 'currency';
  showConversionsButton?: boolean;
  conversionName: string;
};

export const ConversionsButton: React.FC<ConversionsButtonProps> = ({
  item,
  metricKey,
  showConversionsButton: showConversionsButton,
  format,
  conversionName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currency = useStoreValue($currency);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const value = formatNumber((item[metricKey] ?? item.metrics?.[metricKey]) || 0, {
    style: format,
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  return (
    <div className="roas-table-cell">
      <span
        key={`conversions-button-${item.id}`}
        className={`${item.shouldHighlight ? 'highlight-realtime-attribution' : ''} ${
          showConversionsButton ? 'cursor-pointer text-blue-700' : ''
        }`}
        onClick={() => {
          if (showConversionsButton) {
            toggleIsOpen();
          }
        }}
      >
        {value}
      </span>

      {isOpen && (
        <Conversions
          item={item}
          onClose={toggleIsOpen}
          metricKey={metricKey}
          conversionName={conversionName}
        />
      )}
    </div>
  );
};
