import { createSelector } from 'reselect';
import { CustomMetricsType } from 'ducks/customMetrics';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import { selectConnectedServices } from 'utils/selectors';
import { $allPixelColumns, customEventsColumns } from 'components/attribution-new/columns';
import { $derived } from '@tw/snipestate';
import { $redux } from '$stores/$redux';
import { RootState } from 'reducers/RootType';

export const selectPixelColumnsForCustomMetrics = createSelector(
  [selectCustomMetricsColumns(CustomMetricsType.Attribution)],
  (columns) => columns,
);

const selectCustomAttributionMetricsColumns = selectCustomMetricsColumns(
  CustomMetricsType.Attribution,
);

const $pixelColumnsForCustomMetrics = $derived((get) => {
  const reduxState = get($redux);
  return !reduxState ? null : selectCustomAttributionMetricsColumns(reduxState);
});

export const selectPixelColumnsForCustomEvents = createSelector(
  [(state: RootState) => state.customEventsSettings],
  (customEventsSettings: any) => {
    const events = customEventsSettings?.filter((e) => !!e.customConversion);
    return customEventsColumns(events);
  },
);

export const $pixelColumnsForCustomEvents = $derived((get) => {
  const reduxState = get($redux);
  return !reduxState ? null : selectPixelColumnsForCustomEvents(reduxState);
});

export const $connectedServices = $derived((get) => {
  const reduxState = get($redux);
  return !reduxState ? null : selectConnectedServices(reduxState);
});

export const $allMetrics = $derived((get) => {
  const connectedServices = get($connectedServices);
  const allPixelColumns = get($allPixelColumns);

  return allPixelColumns.filter((metric: any) => {
    if (!metric.dependOnActiveIntegrations) {
      return true;
    }
    return metric.dependOnActiveIntegrations.some(
      (service: string) => connectedServices?.[service],
    );
  });
});

export const $allPixelColumnsIncludingCustom = $derived((get) => {
  const pixelColumnsForCustomMetrics = get($pixelColumnsForCustomMetrics);
  const pixelColumnsForCustomEvents = get($pixelColumnsForCustomEvents);
  return get($allMetrics).concat(pixelColumnsForCustomMetrics).concat(pixelColumnsForCustomEvents);
});
