import { Button, Checkbox, Icon, Modal, Select, Text } from '@tw/ui-components';
import {
  WorkflowStepGenUiCustomComponentsDict,
  WorkflowStepGenUiPresentation,
  WorkflowStepGenUiReport,
  WorkflowStepInsights,
} from '../types/willyTypes';
import { $isAdminClaim } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { toast } from 'react-toastify';

type InsightsOutputSettingsModalProps = {
  opened: boolean;
  onClose: () => void;
  step: WorkflowStepInsights | WorkflowStepGenUiReport | WorkflowStepGenUiPresentation;
  stepChange: (
    step: WorkflowStepInsights | WorkflowStepGenUiReport | WorkflowStepGenUiPresentation,
  ) => void;
  readOnly: boolean;
};

export const InsightsOutputSettingsModal: React.FC<InsightsOutputSettingsModalProps> = ({
  opened,
  onClose,
  step,
  stepChange,
  readOnly,
}) => {
  console.log('step', step);
  const isAdmin = useStoreValue($isAdminClaim);

  return (
    <Modal opened={opened} onClose={onClose} title="Output Settings">
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <Select
            data={[
              { label: 'Auto', value: '' },
              { label: 'o3 Mini', value: 'o3-mini' },
              {
                label: 'TW Model',
                value: 'ft:gpt-4o-mini-2024-07-18:triple-whale:agents-0115:AqAu4HZj',
              },
              { label: 'GPT o1-pro (slow)', value: 'o1-pro' },
              { label: 'GPT 4o', value: 'gpt-4o-2024-11-20' },
              { label: 'ChatGPT 4o', value: 'chatgpt-4o-latest' },
              { label: 'GPT 4.5', value: 'gpt-4.5-preview' },
              { label: 'Gemini 1.5 Pro', value: 'gemini-1.5-pro' },
              { label: 'Gemini 2.5 Pro Exp', value: 'gemini-2.5-pro-exp-03-25' },
              ...(isAdmin
                ? [
                    { label: 'Cerebras', value: 'llama-3.3-70b' },
                    { label: 'Gemini 2.0 Pro Exp', value: 'gemini-2.0-pro-exp-02-05' },
                    { label: 'Gemini 2.0 Flash Lite', value: 'gemini-2.0-flash-lite-001' },
                    { label: 'Gemini 2.0 Flash', value: 'gemini-2.0-flash-001' },
                    { label: 'Claude 3.7 Sonnet', value: 'claude-3-7-sonnet-20250219' },
                    {
                      label: 'DeepSeek Reasoner',
                      value: 'deepseek-reasoner',
                    },
                    {
                      label: 'DeepSeek Chat',
                      value: 'deepseek-chat',
                    },
                  ]
                : []),
            ]}
            label={'Model'}
            disabled={readOnly}
            value={step.model}
            onChange={(value) => {
              stepChange({
                ...step,
                model: value,
              });
            }}
          />
          {step.stepType === 'genUiReport' && (
            <div className="flex flex-col gap-4">
              <Text size="sm" fw="bold">
                Select Custom Components to include in your report
              </Text>
              {Object.values(WorkflowStepGenUiCustomComponentsDict).map((component) => (
                <Checkbox
                  key={component.value}
                  label={component.label}
                  checked={!!(step.customComponents ?? []).includes(component.value)}
                  onChange={(value) =>
                    stepChange({
                      ...step,
                      customComponents: [
                        ...(step.customComponents ?? []).filter(
                          (customComponent) => customComponent !== component.value,
                        ),
                        ...(value ? [component.value] : []),
                      ],
                    })
                  }
                />
              ))}
            </div>
          )}
          {(step.stepType === 'genUiReport' || step.stepType === 'genUiPresentation') && (
            <div>
              <Button
                leftSection={<Icon color="red.7" name="delete" />}
                variant="danger"
                onClick={() => {
                  stepChange({ ...step, runIdWithGenUi: null });
                  toast.success('Generative UI Cache Deleted');
                }}
              >
                Delete Generative UI Cache
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
