import {
  ActionIcon,
  Flex,
  IconName,
  Popover,
  TextInput,
  Tooltip,
  Tabs,
  Icon,
  Size,
} from '@tw/ui-components';
import { useMemo, useState } from 'react';
import { icons } from '@tw/ui-components';
import { isDefined } from 'utils/isDefined';
import EmojiPicker from 'emoji-picker-react';
import { WillyEmoji } from './types/emojiTypes';
import { visibleLength } from './utils/willyUtils';

type IconPickerProps = {
  selectedIcon: WillyEmoji | null;
  onChange: (icon: WillyEmoji) => void;
  activatorSize?: Size;
  activator?: React.ReactNode;
};

export const IconPicker: React.FC<IconPickerProps> = ({
  selectedIcon,
  onChange,
  activatorSize,
  activator,
}) => {
  const [searchIcon, setSearchIcon] = useState('');
  const [activeTab, setActiveTab] = useState('Icons');
  const [open, setOpen] = useState(false);

  const activatorEl = useMemo(() => {
    if (activator) {
      return (
        <div className="cursor-pointer" onClick={() => setOpen(!open)}>
          {activator}
        </div>
      );
    }

    if (!selectedIcon) {
      return (
        <ActionIcon
          variant="default"
          icon={<Icon name="plus" size={14} />}
          size={activatorSize}
          onClick={() => setOpen(!open)}
        />
      );
    }

    return (
      <ActionIcon
        variant="default"
        icon={
          visibleLength(selectedIcon) > 1 ? (
            <Icon name={selectedIcon as IconName} size={14} />
          ) : (
            <>{selectedIcon}</>
          )
        }
        onClick={() => setOpen(!open)}
        size={activatorSize}
      />
    );
  }, [activator, activatorSize, open, selectedIcon]);

  const tabs = ['Icons', 'Emojis'];

  const handleIconChange = (icon: WillyEmoji) => {
    onChange(icon);
    setOpen(false);
  };

  return (
    <Popover opened={open} trapFocus width={400} shadow="md" onChange={setOpen}>
      <Popover.Target>{activatorEl}</Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" gap="md">
          <Tabs
            fullWidth
            value={activeTab}
            onChange={(e) => {
              if (!isDefined(e)) return;
              setActiveTab(e);
            }}
          >
            <Tabs.List className="flex-nowrap justify-between flex">
              {tabs.map((tab) => (
                <Tabs.Tab key={tab} value={tab} className="dark:text-white hover:dark:text-black">
                  {tab}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
          {activeTab === 'Icons' && (
            <Flex direction="column" gap="md" mah={350}>
              <TextInput
                value={searchIcon}
                onChange={setSearchIcon}
                placeholder="Filter..."
                leadingIcon={'search-major'}
              />
              <Flex wrap="wrap" justify="space-between" align="center" overflow="auto">
                {icons
                  .filter((x) => !searchIcon.toLowerCase() || x.toLowerCase().includes(searchIcon))
                  .map((icon) => (
                    <Tooltip label={icon} key={icon}>
                      <ActionIcon
                        size="xl"
                        radius="sm"
                        variant={selectedIcon === icon ? 'gradient' : 'subtle'}
                        icon={icon}
                        iconSize={20}
                        onClick={() => {
                          handleIconChange(icon);
                        }}
                      />
                    </Tooltip>
                  ))}
              </Flex>
            </Flex>
          )}
          {activeTab === 'Emojis' && (
            <EmojiPicker
              width={'100%'}
              onEmojiClick={(e) => {
                handleIconChange(e.emoji as WillyEmoji);
              }}
              height={350}
              previewConfig={{ showPreview: false }}
            />
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
