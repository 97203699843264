import { SalesPlatform } from '@tw/types/module/services';
import { SALES_PLATFORMS } from 'constants/SalesPlatform';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

export const SalesPlatformName: React.FC<{
  mspName?: SalesPlatform;
}> = (props) => {
  const name: SalesPlatform = useSelector((state: RootState) => props.mspName || state.msp);
  return <>{SALES_PLATFORMS[name].title}</>;
};
