import { Link, useNavigate } from 'react-router-dom';
import { $shopAiColumns, $shopSequences, $selectedAiCell } from '$stores/willy/$sequences';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { Button, Modal, Text, Icon, IconName, Flex, ActionIcon } from '@tw/ui-components';
import { useSequenceMessages } from 'components/Willy/sequenceBuilder/hooks/useSequenceMessages';
import { WillyMessageTemplate } from 'components/Willy/WillyMessageTemplate';
import { WillySimpleText } from 'components/Willy/WillySimpleText';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { visibleLength } from 'components/Willy/utils/willyUtils';
import { Dialect, WillyDataSequence } from 'components/Willy/types/willyTypes';
import {
  getMessagesFromWorkflowResponse,
  extractDataFromResponse,
} from 'components/Willy/utils/sequences';
import { $currentShopId } from '$stores/$shop';

type AiCellModalProps = {};

export const AiCellModal: React.FC<AiCellModalProps> = () => {
  const navigate = useNavigate();

  const [selectedAiCell, setSelectedAiCell] = useWritableStore($selectedAiCell);
  const { data, reason, createdAt, rawDataPath } = selectedAiCell?.value || {};
  const shopColumns = useStoreValue($shopAiColumns);
  const shopSequences = useStoreValue($shopSequences);
  const currentShopId = useStoreValue($currentShopId);

  const [showMessages, setShowMessages] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentHeight, setCurrentHeight] = useState('0px');
  const [sequence, setSequence] = useState<WillyDataSequence | null>(null);
  const [runId, setRunId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const runId = rawDataPath?.split('/').slice(0, -1).pop();
    if (!runId) {
      return;
    }
    setRunId(runId);
  }, [rawDataPath]);

  const column = useMemo(() => {
    return shopColumns.data?.find((x) => x.key === selectedAiCell?.column);
  }, [shopColumns.data, selectedAiCell?.column]);

  useEffect(() => {
    const serviceId = selectedAiCell?.serviceId;
    if (!serviceId) {
      return;
    }
    const sequenceIds = column?.sequences?.filter(
      (s) => s.serviceId === serviceId || s.serviceId === 'all',
    );
    if (!sequenceIds?.length) {
      return;
    }
    // There can be only one sequence per entity per service
    const sequenceId = sequenceIds?.find((s) => s.entity === selectedAiCell?.entity);
    const seq = shopSequences.find((x) => sequenceId?.sequenceId === x.id);
    if (!seq) {
      return;
    }
    setSequence(seq);
  }, [shopSequences, column, selectedAiCell?.serviceId, selectedAiCell?.entity]);

  const emoji = useMemo(() => {
    return column?.possibleValues?.find((x) => x.value === data)?.icon;
  }, [column, data]);

  const { loadingRunMessages, lastRun, rawRunHistory } = useSequenceMessages({
    activeWorkflowId: sequence?.id,
    activeWorkflowRunId: runId,
    isSequenceViewer: false,
  });

  const messagesFromThisIteration = useMemo(() => {
    const filteredHistory = rawRunHistory.filter((x) => {
      const fullPath = `${currentShopId}/${rawDataPath}/`;
      return (x as any).filename.includes(fullPath);
    });

    return filteredHistory;
  }, [rawRunHistory, currentShopId, rawDataPath]);

  const messagesToShow = useMemo(() => {
    const messages = messagesFromThisIteration.flatMap((x) => {
      return getMessagesFromWorkflowResponse(x, sequence?.dialect as Dialect);
    });
    const toolMessages = messages.filter((x) => x.role !== 'assistant');

    return toolMessages;
  }, [messagesFromThisIteration, sequence?.dialect]);

  const genUiReason = useMemo(() => {
    const lastInsights = messagesFromThisIteration.findLast(
      (x) =>
        x.stepType === 'insights' ||
        x.stepType === 'genUiReport' ||
        x.stepType === 'genUiPresentation',
    );
    if (!lastInsights) {
      return null;
    }
    return lastInsights.text;
  }, [messagesFromThisIteration]);

  const dataForGenUi = useMemo(() => {
    return Object.values(messagesFromThisIteration)
      .filter((x) => x !== null)
      .flatMap((x, index) => extractDataFromResponse(x, index));
  }, [messagesFromThisIteration]);

  const closeModal = useCallback(() => {
    setSelectedAiCell(null);
  }, [setSelectedAiCell]);

  useEffect(() => {
    if (contentRef.current) {
      setCurrentHeight(showMessages ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [showMessages, messagesToShow]);

  if (!selectedAiCell) {
    return null;
  }

  return (
    <Modal
      opened={!!selectedAiCell}
      onClose={closeModal}
      size="lg"
      title={
        <>
          {!!createdAt && (
            <Text size="sm" color="gray.5">
              Updated at: {createdAt} (UTC)
            </Text>
          )}
        </>
      }
    >
      <div>
        {!sequence && (
          <Text>
            No agent found for this column. Please create or attach an agent for this column to see
            its output.
          </Text>
        )}
        {sequence && (
          <>
            <Flex justify="space-between" align="center">
              <div>
                <div className="flex flex-col gap-2">
                  <Text fw={700} size="20px">
                    {data}
                    {!!emoji && (
                      <span className="ml-2">
                        {visibleLength(emoji) > 1 ? (
                          <Icon name={emoji as IconName} size={14} />
                        ) : (
                          emoji
                        )}
                      </span>
                    )}
                  </Text>
                </div>
              </div>
              <Link
                to={{
                  pathname: `/workflows/create/${sequence?.id}`,
                  search: new URLSearchParams(window.location.search).toString() + '&tab=chat',
                }}
                target="_blank"
              >
                <ActionIcon icon="edit" iconSize={14} />
              </Link>
            </Flex>

            <div className="mt-8 flex flex-col gap-4">
              <Text>Here are the insights leading to this value:</Text>
              <WillySimpleText text={genUiReason || reason || ''} dataForGenUi={dataForGenUi} />
            </div>

            <div
              className={`gap-4 flex justify-center sticky top-20 bg-white z-10 ${showMessages ? 'py-8' : 'pt-8'}`}
            >
              <Button
                onClick={() => {
                  setShowMessages((x) => !x);

                  if (!showMessages) {
                    genericEventLogger(analyticsEvents.SEQUENCES, {
                      action: sequencesActions.VIEW_COLUMN_AGENT_OUTPUT,
                      column_id: column?.key,
                      column_name: column?.label,
                      column_category: column?.category,
                      column_value: data,
                    });
                  }
                }}
                loading={loadingRunMessages}
              >
                {`${showMessages ? 'Hide' : 'Show'} Agent Output`}
              </Button>
            </div>

            {showMessages && (
              <div
                ref={contentRef}
                style={{
                  maxHeight: currentHeight,
                  // overflow: 'hidden',
                  transition: 'max-height 300ms ease-in-out 100ms',
                }}
              >
                {!loadingRunMessages && (
                  <div className="flex flex-col gap-4">
                    {messagesToShow.map((msg) => (
                      <WillyMessageTemplate
                        key={msg.id}
                        message={msg}
                        canEdit={false}
                        userName={lastRun?.userId || ''}
                        conversationUser={lastRun?.userId || ''}
                        conversationMessages={messagesToShow}
                        hideDetails
                        showToolResults
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
