import {
  TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
  TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY,
  TW_LS_ATTRIBUTION_WRAP_TABLE_LINES,
} from 'constants/attribution';
import {
  changeIncludeCustomAdSpend,
  changeExcludeTimeBreakdown,
  chartOpenChanged,
  showActiveCampaignsOnly,
  wrapTableLines,
} from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionWindowArr } from 'constants/types';
import AttributionCsvExport from './AttributionCsvExport';
import AttributionWindowPicker from './AttributionWindowPicker';
import React, { useMemo } from 'react';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { Checkbox, Flex, Icon, Menu, Text } from '@tw/ui-components';
import { $dialect, $isAdminClaim } from '$stores/$user';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { InfoPopover } from 'components/Willy/WillyWidgetEditor/InfoPopover';
import { AttributionSubscriptionFilter } from './AttributionSubscriptionFilter';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { AttributionHeaderColumnSelector } from 'components/headers/AttributionHeader/AttributionHeaderColumnSelector';

export const AttributionWindowItems = AttributionWindowArr.map((item) => ({
  label: item === 'lifetime' ? 'Lifetime' : item === '1' ? '1 day' : item + ' days',
  value: item,
}));

export declare type TagsOptions =
  | 'oneTime'
  | 'subscriptionFirstOrder'
  | 'subscriptionRecurringOrder';

export const SubscriptionItems: { value: TagsOptions; label: string }[] = [
  { value: 'subscriptionRecurringOrder', label: 'Subscription Recurring Order' },
  { value: 'subscriptionFirstOrder', label: 'Subscription First Order' },
  { value: 'oneTime', label: 'Non-Subscription Purchases' },
];

type AttributionMoreSettingsProps = {
  hideExportCsv?: boolean;
  hideWrapLines?: boolean;
  hideOceanMode?: boolean;
  hideCustomAdSpend?: boolean;
  hideNexus?: boolean;
};

export const SubscriptionDefinition = {
  subscriptionRecurringOrder:
    'Recurring subscription purchases (based on order tags) will be included in your attribution table.',
  subscriptionFirstOrder:
    'First-time subscription purchases (based on order tags) will be included in your attribution table.',
  oneTime:
    'Non-subscription purchases (purchases that do not include subscription order tags) will be included in your attribution table.',
};

const AttributionMoreSettings: React.FC<AttributionMoreSettingsProps> = ({
  hideExportCsv,
  hideWrapLines,
  hideOceanMode,
  hideCustomAdSpend,
  hideNexus,
}) => {
  const {
    wrapTableLinesMode,
    showOnlyActiveCampaigns,
    includeCustomAdSpend,
    excludeTimeBreakdown,
    dateModel,
  } = useSelector((state: RootState) => state.attribution);
  const sourceCategory = useAttributionActivePage();
  const activeSource = useAttributionActiveSource();
  const isSmall = useIsSmall();
  const isAdminClaim = useStoreValue($isAdminClaim);
  const dialect = useStoreValue($dialect);
  const { chartOpen } = useSelector((state: RootState) => state.attribution);
  const activeAppVersion = useStoreValue($activeAppVersion);

  const isThreePointOh = useMemo(() => activeAppVersion === '3.0', [activeAppVersion]);

  const dispatch = useAppDispatch();

  const isAdsSpecificPage = sourceCategory === 'ads' && activeSource !== 'all';
  const isEmailsSpecificPage = sourceCategory === 'email' && activeSource !== 'all';

  return (
    <Flex direction="column">
      {!isThreePointOh && (
        <Menu.Item>
          <AttributionWindowPicker theme="light" withinPortal={false} />
        </Menu.Item>
      )}
      {!isThreePointOh && (
        <Menu.Item>
          <AttributionSubscriptionFilter />
        </Menu.Item>
      )}
      {isThreePointOh && isSmall && (
        <>
          <Menu.Item
            onClick={() => dispatch(chartOpenChanged(!chartOpen))}
            leftSection={<Icon name="line-chart" />}
          >
            <Text size="sm" weight={500} color="named2.0" pl={'2px'}>
              {chartOpen ? 'Hide' : 'Show'} Graph
            </Text>
          </Menu.Item>
          <AttributionHeaderColumnSelector
            customActivator={(onClick) => (
              <Menu.Item onClick={onClick} leftSection={<Icon name="columns-3-major" />}>
                <Text size="sm" weight={500} color="named2.0" pl={'2px'}>
                  Select Columns
                </Text>
              </Menu.Item>
            )}
          />
          <Menu.Divider />
          <Menu.Label tt="uppercase" fw={500} color="gray.5">
            settings
          </Menu.Label>
        </>
      )}

      {/* <Stack.Item>
          <DropDown
            hint={
              attributionModel !== 'ppsViews'
                ? 'Look back window is only available for Total Impact model'
                : undefined
            }
            disabled={attributionModel !== 'ppsViews'}
            options={AttributionPpsLookBackWindowArr.map((item) => ({
              label: item,
              value: item,
            }))}
            value={ppsLookBackWindow}
            handleSelect={(value) => {
              dispatch(changePpsLookBackWindow(value));
              localStorage.setItem(TW_LS_PPS_LOOK_BACK_WINDOW, JSON.stringify(value));
            }}
            title="Look back window:"
          />
        </Stack.Item> */}
      {!hideWrapLines && (
        <Menu.Item>
          <Checkbox
            label={
              <Text size="sm" weight={500} color="named2.0">
                Wrap Column Text
              </Text>
            }
            checked={wrapTableLinesMode}
            onChange={(checked) => {
              dispatch(wrapTableLines(checked));
              localStorage.setItem(TW_LS_ATTRIBUTION_WRAP_TABLE_LINES, JSON.stringify(checked));
            }}
          />
        </Menu.Item>
      )}
      {(isAdsSpecificPage || isEmailsSpecificPage) && (
        <Menu.Item>
          <Flex align="center" gap="md" justify="space-between">
            <Checkbox
              label={
                <Text size="sm" weight={500} color="named2.0">
                  Show only active campaigns
                </Text>
              }
              checked={showOnlyActiveCampaigns}
              onChange={(checked) => {
                dispatch(showActiveCampaignsOnly(checked));
                localStorage.setItem(
                  TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY,
                  JSON.stringify(checked),
                );
              }}
            />
            <InfoPopover description="Leaving this unchecked will display all campaigns with pixel events" />
          </Flex>
        </Menu.Item>
      )}
      {!hideCustomAdSpend && (
        <Menu.Item>
          <Flex align="center" gap="md" justify="space-between">
            <Checkbox
              label={
                <Text size="sm" weight={500} color="named2.0">
                  Include custom ad spend
                </Text>
              }
              //description="Include custom spend on source level"
              checked={includeCustomAdSpend}
              onChange={(checked) => {
                dispatch(changeIncludeCustomAdSpend(checked));
                localStorage.setItem(
                  TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
                  JSON.stringify(checked),
                );
              }}
            />
            <InfoPopover description="Include custom ad spend in the spend cell" />
          </Flex>
        </Menu.Item>
      )}
      {!hideExportCsv && (
        <Menu.Item>
          <AttributionCsvExport />
        </Menu.Item>
      )}
      {isAdminClaim && (
        <Menu.Item>
          <Flex align="center" gap="md" justify="space-between">
            <Checkbox
              label={
                <Text size="sm" weight={500} color="named2.0">
                  Exclude time breakdown (only admins see this)
                </Text>
              }
              checked={excludeTimeBreakdown}
              onChange={(checked) => {
                dispatch(changeExcludeTimeBreakdown(checked));
              }}
            />
            <InfoPopover description="Exclude time breakdown in attribution data to improve performance" />
          </Flex>
        </Menu.Item>
      )}
    </Flex>
  );
};

export default AttributionMoreSettings;
