import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AxisDomain,
  CodeInterpreterResponse,
  WidgetQuery,
  GridColumnOptions,
  GridOptions,
  Message,
  MessageData,
  MessageTypes,
  NlqResponse,
  TileModes,
  WillyMetric,
  WillyParameter,
  WillyWidgetElement,
  WillyChartLayout,
} from './types/willyTypes';
import { createWillyMetricsFromRawData } from './utils/willyUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { DEFAULT_AXIS_DOMAIN, DEFAULT_DIALECT, MAX_ITEMS_PER_PAGE } from './constants';
import { cohortColor } from 'constants/general';
import { WillyWidget } from './WillyWidget';
import { useWillyTitle } from './hooks/useWillyTitle';
import _db from 'utils/DB';
import { useWillySocket } from './WillySocket';
import { WillyEditMetric } from './WillyEditMetric';
import { useDefaultType } from './hooks/useDefaultType';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import { noop } from 'lodash';
import { Text } from '@tw/ui-components';

const DUMMY_DATA = { data: [], dataColumns: { x: [], y: [] } };

type WillyMessageWidgetProps = {
  data?: MessageData;
  prevData?: MessageData | null;
  codeResult?: CodeInterpreterResponse;
  message: Message;
  chatSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  context?: 'dashboard' | 'chat';
  builderData?: any;
  setBuilderData?: (data: any) => void;
};

export const WillyMessageWidget: React.FC<WillyMessageWidgetProps> = ({
  data,
  prevData,
  codeResult,
  message,
  chatSourceIds,
  context,
  builderData,
  setBuilderData,
}) => {
  const isInitRef = useRef(true);

  const currency = useStoreValue($currency);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [codeResultData, setCodeResultData] = useState<NlqResponse>();
  const [loading, setLoading] = useState(true); // true by default to avoid loading delay, talk to me if you want to change this
  const [metrics, setMetrics] = useState<WillyMetric[]>([]);
  const [parameters, setParameters] = useState<WillyParameter[]>([]);
  const [builderSetup, setBuilderSetup] = useState<BuilderTable>();
  const [mode, setMode] = useState<WillyWidgetElement['mode']>('sql');

  const defaultType = useDefaultType(
    codeResultData?.data || data?.data,
    codeResultData?.dataColumns || data?.dataColumns,
    message.originalQuestion,
    data?.dataType,
    data?.visualizationType,
  );
  const [type, setType] = useState<MessageTypes>(defaultType || 'table');
  const [wrapText, setWrapText] = useState(false);
  const [stacked, setStacked] = useState(false);
  const [chartLayout, setChartLayout] = useState<WillyChartLayout>('horizontal');
  const [grid, setGrid] = useState('flex' as GridOptions);
  const [gridColumns, setGridColumns] = useState(2 as GridColumnOptions);
  const [twoColumnMobile, setTwoColumnMobile] = useState(false);
  const [tileMode, setTileMode] = useState('tile' as TileModes);
  const [skinny, setSkinny] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState<AxisDomain>(DEFAULT_AXIS_DOMAIN);
  const [allowDataOverflow, setAllowDataOverflow] = useState(false);
  const [dimension, setDimension] = useState('');
  const [incrementedStacked, setIncrementedStacked] = useState(false);
  const [hasConditionalFormatting, setHasConditionalFormatting] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(parameters?.length > 0);
  const [conditionalFormattingColor, setConditionalFormattingColor] = useState(cohortColor);
  const [breakdownMode, setBreakdownMode] = useState(false);
  const [queries, setQueries] = useState<WidgetQuery[]>([]);
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
    open: boolean;
    queryId?: string;
    metricId?: string;
  }>({ open: false });

  const willySocket = useWillySocket();

  const mainQuery = useMemo(() => {
    return queries?.find((q) => !!q.query);
  }, [queries]);

  useEffect(() => {
    if (codeResultData?.parameters) {
      setParameters(codeResultData.parameters);
    } else if (data?.parameters) {
      setParameters(data.parameters);
    }
  }, [codeResultData, data?.parameters]);

  useEffect(() => {
    setType((old) => defaultType || old);
  }, [defaultType]);

  useEffect(() => {
    if (codeResult?.queries) {
      setQueries(
        codeResult.queries.map((q) => {
          return {
            id: q.id,
            query: q.query,
            question: q.file_name,
            metricsKeys: Object.keys(q.data?.[0] || {}),
          };
        }),
      );
    } else if (data?.queryId && data.query && data.question) {
      setQueries([
        {
          id: data.queryId,
          query: data.query,
          question: data.question,
        },
      ]);
    } else if (data?.queries && data?.queries?.length > 0) {
      setQueries([
        {
          id: data?.queries[0].id,
          query: data?.queries[0].query,
          question:
            'file_name' in data?.queries[0]
              ? data?.queries[0].file_name
              : data?.queries[0].question,
        },
      ]);
    }
  }, [codeResult?.queries, data?.queryId, data?.query, data?.question, data?.queries]);

  const messageMainQueryId = useMemo(() => {
    return mainQuery?.id;
  }, [mainQuery]);

  const initialRawData = useMemo(() => {
    if (!data) {
      return;
    }
    const rawData: NlqResponse = {
      data: data.data || [],
      dataColumns: data.dataColumns || { x: [], y: [] },
      question: data.question,
      serviceIds: data.serviceIds,
      generatedQuery: mainQuery?.query!,
      queryId: mainQuery?.id!,
      verified: message.verified,
      queries: queries,
      conversationId: message.conversationId,
      error: message.error,
      dataType: data.dataType,
      twTotalCount: data.twTotalCount,
    };
    return rawData;
  }, [
    queries,
    data,
    mainQuery?.id,
    mainQuery?.query,
    message.conversationId,
    message.error,
    message.verified,
  ]);

  const initialPreviousPeriodRawData = useMemo(() => {
    if (!prevData) {
      return DUMMY_DATA;
    }
    const prevRawData: NlqResponse = {
      data: prevData.data || [],
      dataColumns: prevData.dataColumns || { x: [], y: [] },
      question: prevData.question,
      serviceIds: prevData.serviceIds,
      generatedQuery: mainQuery?.query!,
      queryId: mainQuery?.id!,
      verified: message.verified,
      queries: queries,
      conversationId: message.conversationId,
      error: message.error,
      dataType: prevData.dataType,
      twTotalCount: prevData.twTotalCount,
    };
    return prevRawData;
  }, [
    queries,
    prevData,
    mainQuery?.id,
    mainQuery?.query,
    message.conversationId,
    message.error,
    message.verified,
  ]);

  useEffect(() => {
    if (initialRawData && initialRawData?.data?.[0]?.value?.length >= 28) {
      setSkinny(true);
    }
  }, [initialRawData]);

  const updateMetrics = useCallback(async (id: string, data: WillyMetric[]) => {
    setMetrics(data);
  }, []);

  const onMessageDone = useCallback(
    async (msg: string) => {
      const conversationId = message.conversationId;
      if (!conversationId) {
        return;
      }
      const allMessages = await _db().collection('conversations').doc(conversationId).get();
      const history: any[] = allMessages.data()?.history || [];
      const thisMessage = history.find((m: any) => m.messageId === message.id);
      if (!thisMessage) {
        return;
      }

      const newHistory = history.map((x) => {
        if (x.messageId === message.id) {
          return {
            ...x,
            title: msg,
          };
        }
        return x;
      });

      await _db().collection('conversations').doc(conversationId).set(
        {
          history: newHistory,
        },
        {
          merge: true,
        },
      );
    },
    [message.conversationId, message.id],
  );

  const { title, setTitle } = useWillyTitle(messageMainQueryId, onMessageDone);

  const suggestTitle = useCallback(async () => {
    if (message.title && !title) {
      setTitle(message.title);
      return;
    }
    if (
      message.loading ||
      !message.question ||
      !currentShopId ||
      !messageMainQueryId ||
      title ||
      !isInitRef.current
    ) {
      return;
    }

    isInitRef.current = false;

    willySocket.socket.emit('suggest-title', {
      generatedQuery: mainQuery?.query!,
      question: message.question,
      shopId: currentShopId,
      mode: 'title',
      queryId: messageMainQueryId,
    });
  }, [
    currentShopId,
    messageMainQueryId,
    title,
    willySocket,
    message.title,
    setTitle,
    message.loading,
    message.question,
    mainQuery,
  ]);

  // Save builder data for WillyBuilder to consume
  useEffect(() => {
    if (!messageMainQueryId) {
      return;
    }

    setBuilderData?.({
      ...builderData,
      [messageMainQueryId]: {
        ...(builderData?.[messageMainQueryId] ?? {}),
        ...{
          title,
          type,
          parameters,
          queries,
          metrics,
          grid,
          gridColumns,
          twoColumnMobile,
          tileMode,
          skinny,
          yAxisDomain,
          allowDataOverflow,
          dimension,
          conditionalFormattingColor,
          builderSetup,
          filtersOpen,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowDataOverflow,
    builderSetup,
    conditionalFormattingColor,
    currentShopId,
    dimension,
    filtersOpen,
    grid,
    gridColumns,
    message.loading,
    message.question,
    messageMainQueryId,
    metrics,
    parameters,
    queries,
    skinny,
    tileMode,
    title,
    twoColumnMobile,
    type,
    yAxisDomain,
  ]);

  useEffect(() => {
    suggestTitle();
  }, [suggestTitle]);

  useEffect(() => {
    if (data?.metrics) {
      setMetrics(data.metrics);
    }
  }, [data?.metrics]);

  useEffect(() => {
    const filesData = codeResult?.data;
    if (!filesData) {
      setLoading(false);
      return;
    }
    setCodeResultData({ ...filesData, queryId: messageMainQueryId });

    const metrics = createWillyMetricsFromRawData({
      data: filesData.data,
      sqlQuery: filesData.generatedQuery || '',
      initialMetrics: [],
      servicesIds: [],
      dataType: 'nlq',
    });
    setMetrics(metrics);
    setLoading(false);
  }, [codeResult?.data, messageMainQueryId]);

  return (
    <div className="chatWidget flex flex-col gap-4">
      <WillyWidget
        permission={{ providers: [] }}
        permissionChanged={noop}
        currency={currency}
        context={context ?? 'chat'}
        titleChanged={setTitle}
        typeChanged={setType}
        question={message.question}
        queryId={messageMainQueryId}
        title={title}
        type={type}
        stacked={stacked}
        codeResult={codeResult}
        dataType={data?.dataType}
        parameters={parameters}
        parametersChanged={setParameters}
        incrementedStacked={incrementedStacked}
        stackedChanged={setStacked}
        incrementedStackedChanged={setIncrementedStacked}
        metrics={metrics || []}
        metricsChanged={updateMetrics}
        queries={queries}
        queriesChanged={async (queries) => {
          setQueries(queries);
        }}
        initialRawData={codeResultData || initialRawData || DUMMY_DATA}
        initialCodeInterpreterData={codeResult?.queries}
        initialPreviousPeriodRawData={initialPreviousPeriodRawData}
        loadInitialData={loading}
        wrapText={wrapText}
        setWrapText={setWrapText}
        setEditMetricModalOpen={setEditMetricModalOpen}
        grid={grid}
        setGrid={setGrid}
        gridColumns={gridColumns}
        setGridColumns={setGridColumns}
        twoColumnMobile={twoColumnMobile}
        setTwoColumnMobile={setTwoColumnMobile}
        tileMode={tileMode}
        setTileMode={setTileMode}
        skinny={skinny}
        setSkinny={setSkinny}
        yAxisDomain={yAxisDomain}
        setYAxisDomain={setYAxisDomain}
        allowDataOverflow={allowDataOverflow}
        setAllowDataOverflow={setAllowDataOverflow}
        dimension={dimension}
        setDimension={setDimension}
        CDPSegmentId={message.CDPSegmentId}
        globalConditionalFormattingColor={conditionalFormattingColor}
        hasGlobalConditionalFormatting={hasConditionalFormatting}
        setHasGlobalConditionalFormatting={setHasConditionalFormatting}
        setGlobalConditionalFormattingColor={setConditionalFormattingColor}
        breakdownMode={breakdownMode}
        breakdownModeChanged={async (val) => {
          setBreakdownMode(val);
        }}
        dialect={message.dialect || DEFAULT_DIALECT}
        contextSourceIds={chatSourceIds}
        mode={mode}
        builderSetup={builderSetup}
        builderSetupChanged={async (builderSetup) => {
          setBuilderSetup(builderSetup);
        }}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        paginationType="server"
        isSyncCharts={false}
        chartLayout={chartLayout}
        setChartLayout={setChartLayout}
      />

      {type === 'chart' &&
        initialRawData?.twTotalCount &&
        initialRawData?.twTotalCount > MAX_ITEMS_PER_PAGE && (
          <Text size="sm" color="gray.7" weight={500}>
            * Only showing the first {MAX_ITEMS_PER_PAGE} rows
          </Text>
        )}

      <WillyEditMetric
        open={editMetricModalOpen.open}
        metric={metrics?.find((m) => m?.key === editMetricModalOpen.metricId) ?? null}
        availableMetrics={metrics}
        parameters={parameters}
        onClose={() => setEditMetricModalOpen({ open: false })}
        onSaved={async (metric) => {
          setMetrics((old) => {
            return old.map((m) => {
              if (m.key === editMetricModalOpen.metricId) {
                return {
                  ...m,
                  ...metric,
                };
              }
              return m;
            });
          });
          setEditMetricModalOpen({ open: false });
        }}
        onRemoved={async (metricToRemove) => {
          setMetrics((old) => {
            return old.filter((m) => m.key !== metricToRemove.key);
          });
          setEditMetricModalOpen({ open: false });
        }}
      />
    </div>
  );
};
