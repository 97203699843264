import { useCallback, useEffect, useMemo, useState } from 'react';
import { useComputedValue } from '@tw/snipestate';
import { Anchor, Button, Icon, Modal, Popover, Text } from '@tw/ui-components';
import { $chatsToWatch } from '$stores/willy/$chatsToWatch';
import { Conversation, WillyToolName } from './types/willyTypes';
import { getToolsUsedFromConversationId } from './utils/willyUtils';
import { WillySimpleText } from './WillySimpleText';
import { willyToolMap } from './constants';

type ChatSourcesUsedProps = {
  conversationId: string;
  conversation?: Conversation;
};

export type ToolUsed = {
  tool: WillyToolName;
  sources: string[];
  name: string;
};

export const ChatSourcesUsed = ({ conversationId, conversation }: ChatSourcesUsedProps) => {
  const [toolsUsed, setToolsUsed] = useState<ToolUsed[]>([]);
  const [selectedTool, setSelectedTool] = useState<ToolUsed | null>(null);
  const chat = useComputedValue($chatsToWatch, (chats) =>
    chats.find((a) => a.conversationId === conversationId),
  );

  const chatIsFinished = useMemo(() => {
    return (!!conversation && !!conversation.deepSearch) || (!!chat && !!chat.endTime);
  }, [chat, conversation]);

  const getToolsUsed = useCallback(async () => {
    const tools = await getToolsUsedFromConversationId(conversationId);
    setToolsUsed(
      tools.filter((tool) => tool.tool !== 'StepsPlanner' && tool.tool !== 'FinalReport'),
    );
  }, [conversationId]);

  useEffect(() => {
    if (chatIsFinished) {
      getToolsUsed();
    }
  }, [chatIsFinished, getToolsUsed]);

  return chatIsFinished && !!toolsUsed.length ? (
    <div>
      <Popover withinPortal={true}>
        <Popover.Target>
          <Button variant="white" size="xs" tt="capitalize">
            {toolsUsed.length} Tools Used
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <div className="flex flex-col gap-6 w-[250px]">
            {toolsUsed.map((tool, i) => {
              const { icon, color } = willyToolMap[tool.tool] || willyToolMap['TextToSQL'];
              return (
                <div key={tool.name + i} className="flex items-center gap-2">
                  <div className="flex items-center">
                    {icon && <Icon name={icon} color={color} />}
                  </div>
                  {tool.tool === 'TextToSQL' && (
                    <Text fz={12} fw={500} truncate>
                      Ran SQL
                      <span
                        className="cursor-pointer text-blue-500"
                        onClick={() => setSelectedTool(tool)}
                      >
                        {' '}
                        query
                      </span>
                      {!!tool.name ? ` for ${tool.name}` : ''}
                    </Text>
                  )}
                  {tool.tool === 'Searching' && (
                    <Text fz={12} fw={500} truncate>
                      Read{' '}
                      <span
                        className="cursor-pointer text-blue-500"
                        onClick={() => window.open(tool.sources[0])}
                      >
                        {tool.sources[0]}
                      </span>
                    </Text>
                  )}
                  {tool.tool === 'Forecasting' && (
                    <Text fz={12} fw={500} truncate>
                      Forecasted for {tool.name}
                    </Text>
                  )}
                  {tool.tool === 'TextToPython' && (
                    <Text fz={12} fw={500} truncate>
                      Analyzed Data for {tool.name}
                    </Text>
                  )}
                  {tool.tool === 'MarketingMixModel' && (
                    <Text fz={12} fw={500} truncate>
                      Asked the MMM
                    </Text>
                  )}
                  {tool.tool === 'GenerateInsights' && (
                    <Text fz={12} fw={500} truncate>
                      Generated Insights
                    </Text>
                  )}
                  {tool.tool === 'PreloadDashboardData' && (
                    <Text fz={12} fw={500} truncate>
                      Preloaded dashboard data
                    </Text>
                  )}
                  {tool.tool === 'Vision' && (
                    <Text fz={12} fw={500} truncate>
                      Vision
                    </Text>
                  )}
                  {tool.tool === 'StepsPlanner' && (
                    <Text fz={12} fw={500} truncate>
                      Steps Planner
                    </Text>
                  )}
                  {tool.tool === 'FinalReport' && (
                    <Text fz={12} fw={500} truncate>
                      Final Report
                    </Text>
                  )}
                </div>
              );
            })}
          </div>
        </Popover.Dropdown>
      </Popover>
      <Modal.Root zIndex={3000} opened={!!selectedTool} onClose={() => setSelectedTool(null)}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Text lineClamp={2} fw={600} fz={16} maw={400}>
                {selectedTool?.name}
              </Text>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {' '}
            {selectedTool?.tool === 'TextToSQL' && (
              <>
                {selectedTool.sources.map((source, i) => (
                  <div key={i} className="overflow-x-auto">
                    <WillySimpleText text={`\`\`\`sql\n${source}\n`} />
                  </div>
                ))}
              </>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  ) : null;
};
