import { ActionIcon, Button, Icon, Text } from '@tw/ui-components';
import { useNavigate } from 'react-router-dom';

import { WillyDataSequence, WillyGlobalWorfklowColumnsAdminFields } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { openUpgradeAgentsPopup } from '../$upgradeAgentsModal';
import { useEffect } from 'react';
import { SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';
import { AgentUsageCalculator } from 'pages/Usage/AgentUsageCalculator';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { AgentEntity } from '../types/willyTypes';

type TemplatePageProps = {
  id: string;
  name: string;
  description: string;
  themeColor: WillyDataSequence['themeColor'];
  iconSrc?: string;
  tagline: WillyGlobalWorfklowColumnsAdminFields['tagline'];
  aboutPoints: WillyGlobalWorfklowColumnsAdminFields['aboutPoints'];
  whyInfo: WillyGlobalWorfklowColumnsAdminFields['whyInfo'];
  howInfo: WillyGlobalWorfklowColumnsAdminFields['howInfo'];
  entity?: AgentEntity;
  category: WillyGlobalWorfklowColumnsAdminFields['category'];
  relatedTemplatesCards: React.ReactNode[];
  onCopyLink: () => void;
  loadingTemplate: boolean;
  onCopyToShop: () => void;
  onPreviewInBuilder: () => void;
  exists: boolean;
  needsUpdate: boolean;
  onUpdate: () => void;
  agent?: WillyDataSequence | null;
};

export function TemplatePage(props: TemplatePageProps) {
  const {
    id,
    name,
    description,
    themeColor,
    iconSrc,
    tagline,
    aboutPoints,
    whyInfo,
    howInfo,
    entity,
    category,
    relatedTemplatesCards,
    onCopyLink,
    loadingTemplate,
    onCopyToShop,
    exists,
    needsUpdate,
    onUpdate,
    onPreviewInBuilder,
    agent,
  } = props;
  const navigate = useNavigate();

  const scrollToTop = () => {
    const container = document.getElementById('top-of-page');
    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [name]);

  const isAgentSupported = useStoreValue($isAgentSupported);
  const headerBg = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${themeColor}_theme_top.png`;
  const iconBg = `https://storage.googleapis.com/public-assets-shofifi/agents-library/${themeColor}_icon_bg.png`;

  const onAddAgent = () => {
    if (!isAgentSupported) {
      openUpgradeAgentsPopup(`You're one step away from adding ${name} agent!`, 'add_agent');
    } else {
      onCopyToShop();
      genericEventLogger(analyticsEvents.SEQUENCES, {
        action: sequencesActions.ADD_AGENT,
        source: 'pdp',
        sequence_id: id,
        sequence_name: name,
        sequence_category: category,
        sequence_type: 'global',
      });
    }
  };

  return (
    <div className=" overflow-auto h-full" id="top-of-page">
      <div className=" w-full bg-white z-10">
        <div className="flex flex-col">
          <div className="w-full md:w-[10%] py-5 md:py-0  mx-4 md:mx-12 mt-8 md:mt-12">
            <div
              className="back-button flex align-items-center gap-5 cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Icon name={'chevron-left-minor'} color="one.5" size={14} />
              <Text color="one.6" fz={14} fw={500}>
                Back
              </Text>
            </div>
          </div>
          <div className="flex w-full md:max-w-[90%] m-auto justify-center">
            <div
              className={`mx-auto p-4 md:my-12 md:px-0 md:mx-0 pt-0 h-full overflow-auto flex flex-col gap-[35px] w-full md:max-w-[85%]`}
            >
              <div className="relative  w-full flex flex-col border border-solid border-gray-200 rounded-lg shadow-sm bg-white hover:shadow-md transition duration-300 ease-in-out overflow-hidden">
                <div className="h-[150px] relative">
                  <div
                    className="h-full bg-cover"
                    style={{ backgroundImage: `url(${headerBg})` }}
                  />
                </div>
                <div className="flex flex-col p-[35px] gap-6 justify-between">
                  {loadingTemplate ? (
                    <div className="flex flex-col  gap-4">
                      <SkeletonDisplayText />
                      <SkeletonBodyText />
                    </div>
                  ) : (
                    <div className="flex flex-col  gap-4">
                      <Text fz={22} fw={600}>
                        {name}
                      </Text>
                      <Text fz={16} fw={400} color="gray.6">
                        By Triple Whale
                      </Text>
                      <Text fz={16} fw={400}>
                        {tagline}
                      </Text>
                    </div>
                  )}
                  <div className="flex gap-5 ">
                    {!exists && <Button onClick={() => onAddAgent()}>Add Agent</Button>}
                    {exists && needsUpdate && (
                      <Button onClick={() => onUpdate()}>Update Agent</Button>
                    )}
                    {exists && !needsUpdate && <Button disabled>Added</Button>}
                    <Button variant="activator" onClick={onPreviewInBuilder}>
                      Preview
                    </Button>
                    <ActionIcon
                      icon="link"
                      variant="activator"
                      size="lg"
                      onClick={() => onCopyLink()}
                    />
                  </div>
                </div>
              </div>
              <div className=" px-[35px] py-[30px] relative  w-full flex flex-col gap-[30px] border border-solid border-gray-200 rounded-lg shadow-sm bg-white hover:shadow-md transition duration-300 ease-in-out overflow-hidden">
                <div className="flex flex-col  gap-7 ">
                  <Text fz={22} fw={600}>
                    About
                  </Text>
                  {loadingTemplate ? (
                    <SkeletonBodyText />
                  ) : (
                    <>
                      <Text fz={16} fw={400}>
                        {description}
                      </Text>
                      {aboutPoints?.map((aboutPoint, i) => {
                        return (
                          <div className="flex gap-7 items-center" key={i}>
                            <Icon name="checkmark" size={14} color="green.4" />
                            <Text fz={16} fw={500}>
                              {aboutPoint}
                            </Text>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <div className="h-[1px] w-full bg-gray-200 mt-[10px]" />
                </div>
                {!!whyInfo?.length && (
                  <div className="flex flex-col  gap-7  ">
                    <Text fz={22} fw={600}>
                      Why Use This Agent
                    </Text>
                    {loadingTemplate ? (
                      <SkeletonBodyText />
                    ) : (
                      <>
                        <div className="flex flex-col gap-7">
                          {whyInfo?.map((why, i) => {
                            return (
                              <div
                                className="flex flex-col gap-1 bg-blue-50 p-[20px] rounded"
                                key={i}
                              >
                                <Text fz={18} fw={600}>
                                  {why.title}
                                </Text>
                                <Text fz={16} fw={400}>
                                  {why.description}
                                </Text>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    <div className="h-[1px] w-full bg-gray-200 mt-[10px]" />
                  </div>
                )}
                {!!howInfo?.length && (
                  <div className="flex flex-col gap-7">
                    <Text fz={22} fw={600}>
                      How It Works
                    </Text>
                    {loadingTemplate ? (
                      <SkeletonBodyText />
                    ) : (
                      <>
                        {howInfo?.map((how, i) => {
                          return (
                            <div className="flex gap-5" key={i}>
                              <div className="bg-[#F5FCFF] flex items-center justify-center min-w-[36px] min-h-[36px] max-h-[36px] rounded">
                                <Text color="two.6" fw={500} fz={14} lineClamp={1} p={0}>
                                  {i + 1}
                                </Text>
                              </div>
                              <Text>
                                <span className="font-medium">{how.title}: </span>
                                {how.description}
                              </Text>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                <div className="flex flex-col gap-7">
                  <Text fz={22} fw={600}>
                    What does this agent cost?
                  </Text>
                  <AgentUsageCalculator sequenceId={id} entity={entity} agent={agent} />
                </div>
                {!!relatedTemplatesCards.length && (
                  <div className="flex flex-col gap-7">
                    <Text fz={22} fw={600}>
                      Related Agents
                    </Text>
                    <div className="flex flex-col md:flex-row gap-5">
                      {relatedTemplatesCards.map((templateCard, i) => {
                        return (
                          <div key={i} className="flex-1">
                            {templateCard}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
