/**
 * This is new hook that will handle the socket connection for the sequence flow
 * I should replace useSequenceSocket with this in the future
 */

import { useEffect, useCallback, useMemo } from 'react';
import {
  ChatGenUiStreamDone,
  ChatGenUiStreamEvent,
  EditsGenuiStreamEvent,
  GenUiEditDiffsType,
  InsightsSteamEvent,
} from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { GenUiChatMessage } from '../sequenceBuilder/FullAnswerModal';
import { getSocket } from '../WillySocket';
import { diff_match_patch } from 'diff-match-patch';

type ChatGenUiStreamSocketProps = {
  conversationId: string;
  setMessages: React.Dispatch<React.SetStateAction<GenUiChatMessage[]>>;
};

export const useChatGenUiStreamSocket = (props: ChatGenUiStreamSocketProps) => {
  const { conversationId, setMessages } = props;

  const willySocket = useMemo(() => getSocket(), []);

  const onNewStreamDelta = useCallback(
    (data: ChatGenUiStreamEvent) => {
      const {
        messageId: steamMessageId,
        delta,
        conversationId: streamConversationId,
        section,
        replaceOutput,
      } = data;
      if (!conversationId) {
        return;
      }

      if (conversationId !== streamConversationId) {
        return;
      }

      setMessages((messages) => {
        if (messages.some((m) => m.id === steamMessageId)) {
          return messages.map((m) => {
            if (m.id === steamMessageId) {
              if (section === 'assistant_response') {
                const rawText = (m.text ?? '') + delta;
                return {
                  ...m,
                  text: rawText,
                  loading: false,
                  streamingAssistantResponse: true,
                  streamingOutput: true,
                };
              } else if (section === 'output') {
                const originalOutput = m.output ?? '';
                const rawOutput = replaceOutput ? delta : originalOutput + delta;
                const dmp = new diff_match_patch();
                // Compute the difference between current and new output
                const diffs: GenUiEditDiffsType = dmp.diff_main(originalOutput, rawOutput);
                dmp.diff_cleanupSemantic(diffs);

                return {
                  ...m,
                  output: rawOutput,
                  loading: false,
                  streamingOutput: true,
                  streamingAssistantResponse: false,
                  diffs: diffs,
                  lastUpdateTime: Date.now(),
                };
              }
            }
            return m;
          });
        } else {
          return [
            ...messages,
            {
              id: steamMessageId,
              role: 'assistant',
              text: delta,
              conversationId,
            },
          ];
        }
      });
    },
    [conversationId, setMessages],
  );

  const onStreamIsFinished = useCallback(
    (data: ChatGenUiStreamDone) => {
      const {
        messageId: steamMessageId,
        conversationId: streamConversationId,
        output,
        assistant_response,
      } = data;
      if (!conversationId) {
        return;
      }

      if (conversationId !== streamConversationId) {
        return;
      }
      setMessages((messages) => {
        if (messages.some((m) => m.id === steamMessageId)) {
          return messages.map((m) => {
            if (m.id === steamMessageId) {
              return {
                ...m,
                text: assistant_response,
                output: output,
                loading: false,
                streamingAssistantResponse: false,
                streamingOutput: false,
              };
            }
            return m;
          });
        } else {
          return [
            ...messages,
            {
              id: steamMessageId,
              role: 'assistant',
              text: assistant_response,
              output: output,
              loading: false,
              streamingAssistantResponse: false,
              streamingOutput: false,
              conversationId,
            },
          ];
        }
      });
    },
    [conversationId, setMessages],
  );

  const onEditsStream = useCallback(
    (data: EditsGenuiStreamEvent) => {
      console.log('edits stream coming through');
      const { messageId: streamMessageId, conversationId: streamConversationId, newOutput } = data;
      if (!conversationId) {
        return;
      }

      if (conversationId !== streamConversationId) {
        return;
      }

      setMessages((messages) => {
        const messageIndex = messages.findIndex((m) => m.id === streamMessageId);
        if (messageIndex === -1) return messages;

        const currentMessage = messages[messageIndex];
        const currentOutput = currentMessage.output || '';

        // If output is exactly the same, do nothing
        if (currentOutput === newOutput) {
          return messages;
        }
        // Initialize diff_match_patch
        const dmp = new diff_match_patch();
        // Compute the difference between current and new output
        const diffs: GenUiEditDiffsType = dmp.diff_main(currentOutput, newOutput);
        dmp.diff_cleanupSemantic(diffs);
        const updatedMessage = {
          ...currentMessage,
          output: newOutput,
          loading: false,
          streamingOutput: true,
          streamingAssistantResponse: false,
          // Store the diffs for animation purposes
          diffs: diffs,
          // Track last update time for animation timing
          lastUpdateTime: Date.now(),
        };
        const newMessages = [...messages];
        newMessages[messageIndex] = updatedMessage;

        return newMessages;
      });
    },
    [conversationId, setMessages],
  );

  useEffect(() => {
    const streamSocket = willySocket?.on('genui-chat-stream', onNewStreamDelta);
    const done = willySocket?.on('edited-genui', onStreamIsFinished);

    const editsStreamSocket = willySocket?.on('genui-edits-stream', onEditsStream);

    return () => {
      streamSocket?.off('genui-chat-stream', onNewStreamDelta);
      done?.off('edited-genui', onStreamIsFinished);
      editsStreamSocket?.off('genui-edits-stream', onEditsStream);
    };
  }, [willySocket, onNewStreamDelta, onStreamIsFinished, onEditsStream]);
};
