import { toArray, pixelAggregatedJourneyDb, getShopData } from 'utils/DB';
import db from '../utils/DB';
import { INIT_SHOP } from 'ducks/constants';
import { Reducer } from 'redux';
import { map } from 'lodash';
import axiosInstance from '../utils/axiosInstance';
import { CapiSettings, CapiSources } from '@tw/types';

const hasPixelInstalled: Reducer<boolean | null> = (state = null, action) => {
  switch (action.type) {
    case INIT_SHOP:
      //approved by Chezi
      //switched from  return !!action.pixel to return true because since we launched web analytics all shops get pixel - so always will be true.
      return true;
    default:
      return state;
  }
};

export const getUtmReport = () => {
  return async (dispatch) => {
    const data = (await getShopData()) as any;
    if (data?.utm_counter) {
      const totalUtm = data.utm_counter?.fb?.total + data?.utm_counter?.ga?.total || 1;
      const properUtm = data.utm_counter?.fb?.properlySet + data?.utm_counter?.ga?.properlySet || 0;
      dispatch({
        type: UPDATE_UTM_STATUS_VAL,
        value: properUtm / totalUtm,
      });
    }
  };
};

export const unsubscribeJourneys = {
  func: null,
};
export const getPixelAggregatedJourneys = () => {
  return async (dispatch, getState) => {
    // if (!moment().tz()) return;
    const { mainDatePickerSelectionRange } = getState();
    let { start, end } = mainDatePickerSelectionRange;
    if (!start.tz()) return;
    const format = 'YYYY-MM-DDTHH:mm:ss';
    start = start.format(format);
    end = end.format(format);
    const shopDomain = getState().currentShopId;
    dispatch(loadingPixelAggregatedJourneys());
    const ref = pixelAggregatedJourneyDb(shopDomain)
      .where('start', '>=', start)
      .where('start', '<=', end);

    const journey = await ref.get();
    dispatch(pixelAggregatedJourneysReceived(toArray(journey)));

    // unsubscribeJourneys.func && unsubscribeJourneys.func();
    // unsubscribeJourneys.func = ref.onSnapshot((data) => {
    //   let journey = toArray(data);
    //   dispatch(pixelAggregatedJourneysReceived(journey));
    // });
  };
};

export const UPDATE_PIXEL_SETTINGS = 'UPDATE_PIXEL_SETTINGS';
export const UPDATE_CAPI_SETTINGS = 'UPDATE_CAPI_SETTINGS';
export const SET_GOOGLE_ACCOUNT_DETAILS = 'SET_GOOGLE_ACCOUNT_DETAILS';
export const updatePixelSettings = (pixel_settings) => {
  return async (dispatch) => {
    await db().set({ pixel_settings }, { merge: true });
    return dispatch({
      type: UPDATE_PIXEL_SETTINGS,
      payload: pixel_settings,
    });
  };
};

export const UPDATE_SHOP_PIXEL_SETUP = 'UPDATE_SHOP_PIXEL_SETUP';
export const fetchShopPixelSetup = () => {
  return async (dispatch) => {
    const res = await axiosInstance.get('v2/pixel/get-shop-pixel-setup');
    return dispatch({
      type: UPDATE_SHOP_PIXEL_SETUP,
      payload: res.data,
    });
  };
};

export const PIXEL_STATS_RECEIVED = 'PIXEL_STATS_RECEIVED';

export const PIXEL_STATS_LOADING = 'PIXEL_STATS_LOADING';
export const loadingPixelStats = () => ({
  type: PIXEL_STATS_LOADING,
});

export const LOADING_PIXEL_AGGREGATED_JOURNEYS = 'LOADING_PIXEL_AGGREGATED_JOURNEYS';
export const loadingPixelAggregatedJourneys = () => ({
  type: LOADING_PIXEL_AGGREGATED_JOURNEYS,
});

export const PIXEL_AGGREGATED_JOURNEYS_RECEIVED = 'PIXEL_AGGREGATED_JOURNEYS_RECEIVED';
export const pixelAggregatedJourneysReceived = (journey) => ({
  type: PIXEL_AGGREGATED_JOURNEYS_RECEIVED,
  pixelAggregatedJourneys: journey.map((x) => x.aggregatedJourneys),
});

export const getPixelInstallStatus = () => {
  return (dispatch, getState) => {
    dispatch(pixelInstallStatusLoading());
    return db()
      .collection('pixel_install_status')
      .doc('pixel_install_status')
      .get()
      .then((doc) => {
        dispatch(receivePixelInstallStatus(doc.data()));
      });
  };
};

export const LOADING_PIXEL_INSTALL_STATUS = 'LOADING_PIXEL_INSTALL_STATUS';
export const pixelInstallStatusLoading = () => ({
  type: LOADING_PIXEL_INSTALL_STATUS,
});

export const RECEIVE_PIXEL_INSTALL_STATUS = 'RECEIVE_PIXEL_INSTALL_STATUS';
export const receivePixelInstallStatus = (pixelInstallStatus) => ({
  type: RECEIVE_PIXEL_INSTALL_STATUS,
  pixelInstallStatus,
});

export const UPDATE_UTM_STATUS = 'UPDATE_UTM_STATUS';
export const UPDATE_UTM_STATUS_VAL = 'UPDATE_UTM_STATUS_VAL';
export const updateUtmStatus = (serviceUtmStatus: {
  service: string;
  status?: boolean;
  total?: number;
  covered?: number;
  unCovered?: number;
}) => ({
  type: UPDATE_UTM_STATUS,
  utmStatus: serviceUtmStatus,
});

export const UPDATE_PIXEL_INSTALL_STATUS = 'UPDATE_PIXEL_INSTALL_STATUS';
export const updatePixelInstallStatus = (pixelInstallStatus?) => {
  return async (dispatch) => {
    if (!pixelInstallStatus) {
      pixelInstallStatus = await db()
        .collection('pixel_install_status')
        .doc('pixel_install_status')
        .get()
        .then((doc) => doc.data());
    }
    return dispatch({
      type: UPDATE_PIXEL_INSTALL_STATUS,
      payload: pixelInstallStatus,
    });
  };
};

const pixelAggregatedJourneys = (state = [], action) => {
  switch (action.type) {
    case PIXEL_AGGREGATED_JOURNEYS_RECEIVED:
      return action.pixelAggregatedJourneys;
    default:
      return state;
  }
};

const pixelAggregatedJourneyLoading = (state = true, action) => {
  switch (action.type) {
    case LOADING_PIXEL_AGGREGATED_JOURNEYS:
      return true;
    case PIXEL_AGGREGATED_JOURNEYS_RECEIVED:
      return false;
    default:
      return state;
  }
};

const pixelSettings = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.pixel_settings || state;
    case UPDATE_PIXEL_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const UPDATE_CUSTOM_EVENTS_SETTINGS = 'UPDATE_CUSTOM_EVENTS_SETTINGS';
const customEventsSettings: Reducer<any[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.pixel_settings?.customEventsConf || state;
    case UPDATE_CUSTOM_EVENTS_SETTINGS:
      return [...action.payload];
    default:
      return state;
  }
};

export const setCustomEventsSettings = (payload: any[]) => {
  return { type: UPDATE_CUSTOM_EVENTS_SETTINGS, payload };
};

const loadingCapiSettings = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return false;
    default:
      return state;
  }
};

const capiSettings = (state = { googleAccountDetails: { actions: [] } }, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.capi_settings || state;
    case UPDATE_CAPI_SETTINGS:
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          ...action.payload.settings,
        },
      };
    case 'SET_CAPI_SOURCE_WIZARD_STEP':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          activeStep: action.payload.step,
        },
      };
    case 'SET_CAPI_SOURCE_STATUS':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          isEnabled: action.payload.status,
        },
      };
    case 'SET_CAPI_SOURCE_ISSUE':
      return {
        ...state,
        [action.payload.source]: {
          ...state[action.payload.source],
          having_auth_issue: action.payload.having_auth_issue,
        },
      };
    case SET_GOOGLE_ACCOUNT_DETAILS:
      return {
        ...state,
        googleAccountDetails: action.payload,
      };
    default:
      return state;
  }
};

const shopPixelSetup = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.pixel_settings?.shop_setup || state;
    case UPDATE_SHOP_PIXEL_SETUP:
      return action.payload || state;
    default:
      return state;
  }
};

const pixelInstallStatus = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PIXEL_INSTALL_STATUS:
      return action.pixelInstallStatus || {};
    // case INIT_SHOP:
    //   return action.pixel_install_status || state;
    case UPDATE_PIXEL_INSTALL_STATUS:
      return action.payload || state;
    default:
      return state;
  }
};

const loadingPixelInstallStatus = (state = true, action) => {
  switch (action.type) {
    case LOADING_PIXEL_INSTALL_STATUS:
      return true;
    case RECEIVE_PIXEL_INSTALL_STATUS:
      return false;
    default:
      return state;
  }
};

const utmStatus: Reducer<{ service: string; status: boolean; total: number; covered: number }[]> = (
  state = [],
  action,
) => {
  switch (action.type) {
    case INIT_SHOP:
      state =
        map(action.utm_counter, ({ properlySet, total }, service) => {
          properlySet ??= state.find((x) => x.service === service)?.covered ?? 0;
          total ??= state.find((x) => x.service === service)?.total ?? 0;
          return { service, status: properlySet === total, total: total, covered: properlySet };
        }) || [];
      return state;
    case UPDATE_UTM_STATUS:
      state = state.map((x) => {
        if (x.service === action.utmStatus.service) {
          const covered = action.utmStatus.unCovered
            ? x.covered - action.utmStatus.unCovered
            : action.utmStatus.status
              ? x.total
              : x.covered;
          return { ...x, covered, ...action.utmStatus };
        }
        return x;
      });
      return state;
    default:
      return state;
  }
};

const utmStatusVal = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_UTM_STATUS_VAL:
      return action.value;
    default:
      return state;
  }
};

export const setCapiSourceStatus = (payload: { source: CapiSources; status: boolean }) => {
  return { type: 'SET_CAPI_SOURCE_STATUS', payload };
};

export const setCapiSourceIssue = (payload: { source: CapiSources; having_auth_issue: string }) => {
  return { type: 'SET_CAPI_SOURCE_ISSUE', payload };
};

export const getGoogleAccountDetails = () => {
  return async (dispatch, getState) => {
    const { capiSettings } = getState();
    if (capiSettings?.google?.accountId) {
      const accountDetails = await axiosInstance.post('v2/capi/getGoogleAccountDetails', {
        id: capiSettings.google.accountId,
      });
      dispatch(
        setGoogleAccountDetails({
          actions: accountDetails.data.actions.filter((a) => a),
          trackingSettings: accountDetails.data.trackingSettings,
        }),
      );
    }
  };
};

export const setGoogleAccountDetails = (payload: {
  actions: any[];
  trackingSettings: {
    accepted_customer_data_terms: boolean;
    enhanced_conversions_for_leads_enabled: boolean;
  };
}) => {
  return { type: SET_GOOGLE_ACCOUNT_DETAILS, payload };
};

export const setCapiSourceSettings = <S extends CapiSources>(payload: {
  source: S;
  settings: Partial<Omit<CapiSettings[S], 'events'>>;
}) => {
  return { type: UPDATE_CAPI_SETTINGS, payload };
};

const noSessionsYesterday: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.noSessionsYesterday ?? state;
    default:
      return state;
  }
};

export const reducers = {
  hasPixelInstalled,
  pixelAggregatedJourneys,
  pixelAggregatedJourneyLoading,
  pixelSettings,
  customEventsSettings,
  capiSettings,
  loadingCapiSettings,
  shopPixelSetup,
  pixelInstallStatus,
  loadingPixelInstallStatus,
  utmStatus,
  utmStatusVal,
  noSessionsYesterday,
};
