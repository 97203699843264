import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Text, Icon, Menu } from '@tw/ui-components';
import { IconName } from '@tw/ui-components/module/types';
import { WillyPrompt } from './types/willyTypes';
import { $userDisplayName } from '$stores/$user';
import { $mainChatStore } from '$stores/willy/$mainChat';
import { SequencesAllList } from './SequencesAllList';
import { SequencesList } from './SequenceListNew';
import { ChatHistory } from './ChatHistory';
import { WillyChatLP } from './WillyChatLP';
interface WillyBetaChatLPProps {
  onRunItem: (sequenceId: string) => void;
  chatInput: React.ReactNode;
  generatedPrompts: WillyPrompt[];
  onSuggestionClick: (suggestion?: string) => void;
  loadingGeneratedPrompts?: boolean;
}

export const WillyBetaChatLP: React.FC<WillyBetaChatLPProps> = ({
  chatInput,
  onRunItem,
  generatedPrompts,
  onSuggestionClick,
  loadingGeneratedPrompts,
}) => {
  const navigate = useNavigate();
  const userName = useStoreValue($userDisplayName);
  const deepSearch = useComputedValue($mainChatStore, (r) => r.deepSearch);

  const pageOptions = useMemo(
    () => [
      {
        title: 'Feed',
        component: <SequencesAllList onRunItem={onRunItem} isBetaChat={true} />,
      },
      {
        title: 'Chat History',
        component: (
          <div className="h-full overflow-auto">
            <div className="w-full max-w-[800px] m-auto">
              <ChatHistory isBetaChat={true} />
            </div>
          </div>
        ),
      },
      {
        title: 'Shop Agents',
        component: (
          <div className="h-full overflow-auto">
            <div className="w-full max-w-[800px] m-auto">
              <SequencesList isBetaChat={true} />
            </div>
          </div>
        ),
      },
      {
        title: 'Chat Questions',
        component: (
          <div className="h-full overflow-auto">
            <WillyChatLP
              isBetaChat={true}
              onSuggestionClick={onSuggestionClick}
              generatedPrompts={generatedPrompts}
              loadingGeneratedPrompts={loadingGeneratedPrompts}
            />
          </div>
        ),
      },
      {
        title: 'Deep Dive Prompts',
        component: (
          <WillyChatLP
            isBetaChat={true}
            isDeepSearch={true}
            onSuggestionClick={onSuggestionClick}
            generatedPrompts={generatedPrompts}
            loadingGeneratedPrompts={loadingGeneratedPrompts}
          />
        ),
      },
    ],
    [onRunItem, onSuggestionClick, generatedPrompts, loadingGeneratedPrompts],
  );

  const [newAgentOptionsOpen, setNewAgentOptionsOpen] = useState(false);
  const [pageOptionsOpen, setPageOptionsOpen] = useState(false);
  const [activePage, setActivePage] = useState(deepSearch ? pageOptions[4] : pageOptions[0]);

  const greeting = useMemo(() => {
    const now = new Date();
    const hour = now.getHours();
    if (hour < 12) return 'Good Morning';
    if (hour < 18) return 'Good Afternoon';
    return 'Good Evening';
  }, []);

  const isHistory = useMemo(() => {
    return activePage.title === 'Chat History';
  }, [activePage]);

  useEffect(() => {
    if (deepSearch) {
      setActivePage(pageOptions[4]);
    } else if (isHistory) {
      setActivePage(pageOptions[1]);
    } else {
      setActivePage(pageOptions[0]);
    }
  }, [deepSearch, isHistory, pageOptions]);

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="p-4 flex flex-col gap-8 sm:max-w-[800px] m-auto w-full">
        <div className="flex gap-4 items-center justify-between xl:pt-14">
          <Text size="35px" fw={500} my={6.5}>
            {greeting}, {userName}!
          </Text>
          <div className="flex gap-4 items-start">
            <Button
              variant={isHistory ? 'primary' : 'white'}
              size="xs"
              px="xs"
              onClick={() => {
                if (isHistory) {
                  setActivePage(pageOptions[0]);
                } else {
                  setActivePage(pageOptions[1]);
                }
              }}
            >
              <Icon name="rewind-clock" color={isHistory ? 'white' : 'gray.5'} />
            </Button>
            <Menu shadow="md" opened={newAgentOptionsOpen} onChange={setNewAgentOptionsOpen}>
              <Menu.Target>
                <Button variant="white" size="xs" px="xs">
                  <Icon name="chat" color="gray.5" />
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    navigate({
                      pathname: '/chat',
                    });
                  }}
                >
                  New Chat
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    navigate({
                      pathname: '/chat/agents',
                    });
                  }}
                >
                  Agent Template
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    navigate({ pathname: '/workflows/create' });
                  }}
                >
                  Empty Agent
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col gap-6.5">{chatInput}</div>
        <Menu
          shadow="md"
          opened={pageOptionsOpen}
          onChange={setPageOptionsOpen}
          position="bottom-start"
        >
          <Menu.Target>
            <div className="mt-6.5 w-full sm:max-w-[800px] m-auto flex gap-4 items-center cursor-pointer">
              <h2 className="text-4xl font-semibold text-black">{activePage.title}</h2>
              <Icon
                name={(pageOptionsOpen ? 'caret-up-bold' : 'chevron-down') as IconName}
                size={14}
              />
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            {pageOptions.map((page) => (
              <Menu.Item key={page.title} onClick={() => setActivePage(page)}>
                {page.title}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </div>
      <div className="flex-1 min-h-0 overflow-hidden">{activePage.component}</div>
    </div>
  );
};
