import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { ActionIcon, useSelectByWindowResize } from '@tw/ui-components';
import { Allotment, AllotmentHandle } from 'allotment';
import { useDarkMode } from 'dark-mode-control';
import { useAppSelector } from 'reducers/RootType';
import { MENU_SIZE } from './constants';
import { TabDescriptor, WillyPanelTabs } from './WillyPanelTabs';
import { useAppDispatch } from 'index';
import { changeWillySideMenuWidth } from 'ducks/willy';
import { userDb } from 'utils/DB';
import QueryString from 'qs';
import { isEqual } from 'lodash';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useForceRerender } from 'hooks/useForceRerender';
import { usePrevious } from 'utils/usePrevious';
import { useJustMounted } from 'hooks/useJustMounted';
import { useGlobalBannerHeight, useShowGlobalBanner } from 'hooks/useGlobalBanner';
import { useShowFreeTrialBanner } from './UpgradePlan/FreeTrialBanner';

type WillyPageWrapperProps = {
  className?: string;
  tabs?: TabDescriptor[];
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
  onLayoutChange?: () => void;
  sidePanelContent?: React.ReactNode;
  mainContent: React.ReactNode;
  hideSidePanel?: boolean;
  /** Arrow to toggle wide/collapsed states of side panel */
  showResizer?: boolean;
  defaultSize?: number;
};

export const WillyPageWrapper: React.FC<WillyPageWrapperProps> = ({
  className = '',
  tabs,
  activeTab,
  sidePanelContent,
  mainContent,
  setActiveTab,
  onLayoutChange,
  hideSidePanel,
  showResizer = true,
  defaultSize,
}) => {
  const { willySideMenuWidth, mobileSidePanelOpen } = useAppSelector((state) => state.willy);
  const allotmentRef = useRef<AllotmentHandle>(null);
  const snapped = useRef<'close' | 'open' | 'not-snapped'>('not-snapped');
  const dragging = useRef(false);
  const justMounted = useJustMounted();
  const currentDraggedWidth = useRef(willySideMenuWidth);
  const [sidePanelKey, forceRerenderSidePanel] = useForceRerender();
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const prevSmall = usePrevious(isSmall);
  const windowWidth = useSelectByWindowResize(({ width }) => width);
  const doDarkMode = useDarkMode();
  const { search } = useLocation();
  const navigate = useNavigate();

  const sidePanelMobilePreferredWidth = !isSmall
    ? undefined
    : mobileSidePanelOpen
      ? windowWidth
      : 0;

  const snapSidePanelAndGetWidth = useCallback(
    (preferredSize: number = currentDraggedWidth.current || 380) => {
      const toOpen = (() => {
        if (snapped.current === 'open') return false;
        if (snapped.current === 'close') return true;
        // if sidebar wasn't snapped, close if not fully closed.  If fully closed, open.
        return currentDraggedWidth.current === 0;
      })();

      const newWidth = toOpen ? preferredSize : 0;
      snapped.current = toOpen ? 'open' : 'close';

      dispatch(changeWillySideMenuWidth(newWidth));
      // in general, the above dispatch would be enough to rerender the component
      // with the updated width, but when the new width is 0, there's an issue.
      // Need to force rerender here to fix for that case.
      if (toOpen) forceRerenderSidePanel();

      allotmentRef.current?.resize([newWidth, window.innerWidth - newWidth]);

      return newWidth;
    },
    [dispatch, forceRerenderSidePanel],
  );

  const defaultSizes = useMemo(() => {
    if (!sidePanelContent) return [100];
    if (isSmall) return [windowWidth, 0];
    const sideMenuWidth = defaultSize ?? willySideMenuWidth;
    const percentage = (sideMenuWidth / windowWidth) * 100;
    return [percentage, 100 - percentage];
  }, [isSmall, windowWidth, defaultSize, willySideMenuWidth, sidePanelContent]);

  useEffect(() => {
    if (isSmall === prevSmall || isSmall) return;
    snapped.current = 'not-snapped';
  }, [isSmall, prevSmall]);

  // add css style to the html and body elements only in willy pages
  useEffect(() => {
    document.documentElement.style.overscrollBehaviorX = 'contain';
    document.body.style.overscrollBehaviorX = 'contain';

    return () => {
      document.documentElement.style.overscrollBehaviorX = '';
      document.body.style.overscrollBehaviorX = '';
    };
  }, []);

  useEffect(() => {
    const tabId = new URLSearchParams(search).get('tab-id');
    if (tabs?.some((tab) => tab.value === tabId)) {
      setActiveTab?.(tabId!);
    }
  }, [search, setActiveTab, tabs]);

  const setActiveTabInternal = (tab: string) => {
    const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const query = {
      ...prevQuery,
    };
    query['tab-id'] = tab;
    if (!isEqual(prevQuery, query)) {
      navigate({
        pathname: location.pathname,
        search: QueryString.stringify(query),
      });
    }
    setActiveTab?.(tab);
  };

  return (
    <Allotment
      snap
      ref={allotmentRef}
      defaultSizes={defaultSizes}
      className={`h-full ${doDarkMode ? 'dark' : ''} ${className}`}
      onDragStart={() => {
        if (hideSidePanel) return;
        dragging.current = true;
        snapped.current = 'not-snapped';
      }}
      onChange={([willySideMenuWidth]) => {
        if (hideSidePanel || justMounted || !dragging.current) return;
        currentDraggedWidth.current = willySideMenuWidth;
      }}
      onDragEnd={([willySideMenuWidth]) => {
        if (hideSidePanel) return;
        dragging.current = false;
        dispatch(changeWillySideMenuWidth(willySideMenuWidth));
        userDb().set({ willySideMenuWidth }, { merge: true });
      }}
    >
      {!hideSidePanel && (
        <Allotment.Pane
          key={sidePanelKey}
          snap
          className="transition-[width] duration-300 ease-in-out sm:transition-none"
          preferredSize={willySideMenuWidth}
          minSize={isSmall ? sidePanelMobilePreferredWidth : 0}
          maxSize={isSmall ? sidePanelMobilePreferredWidth : windowWidth}
        >
          {!isSmall && showResizer && (
            <NavResizer
              callback={() => {
                const newWidth = snapSidePanelAndGetWidth();
                userDb().set({ willySideMenuWidth: newWidth }, { merge: true });
              }}
            />
          )}
          <Allotment vertical onChange={onLayoutChange}>
            {tabs?.length && (
              <Allotment.Pane
                className="flex items-end"
                maxSize={MENU_SIZE - 1}
                minSize={MENU_SIZE - 1}
              >
                <WillyPanelTabs tabs={tabs} onTabChange={setActiveTabInternal} value={activeTab} />
              </Allotment.Pane>
            )}
            <Allotment.Pane
              key="side-panel"
              className={`side-panel !overflow-y-auto ${doDarkMode ? 'tw-nice-dark ' : ''}`}
            >
              {sidePanelContent}
            </Allotment.Pane>
          </Allotment>
        </Allotment.Pane>
      )}
      <Allotment.Pane
        className="transition-[left] duration-300 ease-in-out sm:transition-none -mt-[1px]"
        maxSize={windowWidth}
      >
        {mainContent}
      </Allotment.Pane>
    </Allotment>
  );
};

const NavResizer = ({ callback }) => {
  const globalBannerHeight = useGlobalBannerHeight();
  const isNavWide = useAppSelector((s) => !!s.willy.willySideMenuWidth);
  const hasGlobalBanner = useShowGlobalBanner();
  const topOffset = hasGlobalBanner ? globalBannerHeight * 2 : 55;

  return (
    <div className="absolute top-0 right-0 h-full w-1">
      <div
        style={{ top: topOffset + 'px ' }}
        className={`rounded-full bg-white w-[20px] h-[20px] flex justify-center
        items-center shadow-xl fixed -translate-x-[7px] z-[110]`}
      >
        <ActionIcon
          size="sm"
          radius="round"
          variant="activator"
          icon={isNavWide ? 'chevron-left-minor' : 'chevron-right-minor'}
          iconSize={12}
          onClick={callback}
        />
      </div>
    </div>
  );
};
