import { FilterProperty, InsightsFilter } from '@tw/types/module/services/insights';
import { Button, Flex, Modal, ActionIcon, Text, Size } from '@tw/ui-components';
import { useState } from 'react';
import { CustomFilterQueryBuilder } from './CustomFilterQueryBuilder';
import { SetFilterNameModal } from './SetFilterNameModal';

export type EditCustomFilterModalProps = {
  availableProps: FilterProperty[];
  onClose: () => void;
  onSave: (filter: Partial<InsightsFilter>) => void;
  onApply?: (filterQuery: InsightsFilter['query']) => void;
  onSaveAndApply?: (filter: Partial<InsightsFilter>) => void;
  filter: Partial<InsightsFilter>;
};
export const EditCustomFilterModal: React.FC<EditCustomFilterModalProps> = ({
  availableProps,
  filter,
  onClose,
  onSave,
  onApply,
  onSaveAndApply,
}) => {
  const [builderKey, setBuilderKey] = useState(0);
  const [filterQuery, setFilterQuery] = useState<InsightsFilter['query']>(filter?.query || []);
  const [isSaveModalOpened, setIsSaveModalOpened] = useState<boolean>(false);
  const [action, setAction] = useState<'onSave' | 'onSaveAndApply' | undefined>(undefined);

  const actionFunc = action === 'onSave' ? onSave : onSaveAndApply;

  const handleSave = () => {
    if (filter.name) {
      onSave({ ...filter, query: filterQuery });
    } else {
      setAction('onSave');
      setIsSaveModalOpened(true);
    }
  };

  const handleSaveAndApply = () => {
    if (filter.name) {
      onSaveAndApply?.({ ...filter, query: filterQuery });
    } else {
      setAction('onSaveAndApply');
      setIsSaveModalOpened(true);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        opened={true}
        onClose={onClose}
        withCloseButton={false}
        headerBorder
        padding={0}
      >
        <Modal.Header px="lg" display="flex">
          <Text>{filter?.name || 'Quick Filter'}</Text>
          <ActionIcon
            variant="transparent"
            icon="close"
            size={14 as Size}
            onClick={onClose}
            ml="auto"
          />
        </Modal.Header>
        <Modal.Body p={0} mb="lg">
          <CustomFilterQueryBuilder
            key={builderKey}
            query={filterQuery}
            onChange={setFilterQuery}
            availableProperties={availableProps}
          />
        </Modal.Body>
        <Modal.Footer border>
          <Flex justify="end" align="center" gap="md" px="lg">
            <Button
              size="sm"
              variant="activatorWithHover"
              mr="auto"
              onClick={() => {
                setFilterQuery([]);
                setBuilderKey((prev) => prev + 1);
              }}
            >
              Reset
            </Button>
            <Button
              disabled={!filterQuery.length}
              variant="secondary"
              size="sm"
              onClick={handleSave}
            >
              Save
            </Button>
            {onApply && (
              <Button disabled={!filterQuery.length} size="sm" onClick={() => onApply(filterQuery)}>
                Apply
              </Button>
            )}
            {onSaveAndApply && (
              <Button disabled={!filterQuery.length} size="sm" onClick={handleSaveAndApply}>
                Save & Apply
              </Button>
            )}
          </Flex>
        </Modal.Footer>
      </Modal>
      {isSaveModalOpened && (
        <SetFilterNameModal
          filter={filter}
          onClose={() => setIsSaveModalOpened(false)}
          onSave={(name) => {
            actionFunc?.({ name, query: filterQuery });
            setIsSaveModalOpened(false);
          }}
        />
      )}
    </>
  );
};
