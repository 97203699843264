import SinglePixel from 'components/pixel/SinglePixel';
import { DisplayText, Modal } from '@shopify/polaris';
import OrderLowResolutionMark from '../pixel/OrderLowResolutionMark';
import { Text } from '@tw/ui-components';

export const CustomerJourney = ({
  loading,
  order,
  subscription = undefined,
  journey,
  isOpen,
  campaign,
  onClose,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      loading={loading}
      iFrameName="pixel-modal"
      title={
        !loading && (
          <>
            <div className="pixel-modal-title">
              <div className="pixel-title">
                <DisplayText size="large">
                  {order?.name}
                  <OrderLowResolutionMark order={order} journey={journey}></OrderLowResolutionMark>
                </DisplayText>
              </div>
            </div>
            <div className="mt-5">
              <Text size="xs">{order?.id}</Text>
            </div>
          </>
        )
      }
    >
      <div>
        <SinglePixel
          loading={loading}
          order={order}
          subscription={subscription}
          journey={journey}
        />
      </div>
    </Modal>
  );
};
