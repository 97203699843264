import { useCallback } from 'react';
import { DashboardsDropDown } from '../DashboardsDropDown';

type FlowSendToDashboardStepProps = {
  onSave: (dashboardId: string) => void;
  dashboardId: string;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowSendToDashboardStep = ({
  onSave,
  dashboardId,
  readOnly,
  setIsPristine,
}: FlowSendToDashboardStepProps) => {
  const onSelect = useCallback(
    (dashboardId: string) => {
      setIsPristine(false);
      onSave(dashboardId);
    },
    [onSave, setIsPristine],
  );
  return (
    <div>
      <DashboardsDropDown
        onSelect={onSelect}
        selected={dashboardId}
        dashboardTypes="shopAndGlobal"
        disabled={readOnly}
      />
    </div>
  );
};
