import { AttributionPageContext } from 'constants/attribution';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';
import { Text, Link, Icon } from '@shopify/polaris';
import { mapEntityToBreakdown } from 'utils/attributions';
import TWTable from 'components/library/TWTable/TWTable';
import { LoadingOverlay, Modal } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import { getConversions } from 'ducks/conversions';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useCallback } from 'react';
import {
  CustomerProfileModal,
  CustomerProfileModalProps,
} from 'components/Insights/CustomerProfile/Activities/CustomerProfileModal';
import { CustomerJourney } from 'components/attribution/components';
import { $currentShopId } from '../../$stores/$shop';
import { getJourney } from 'components/attribution/utils';

type ConversionsProps = {
  item: AttributionData;
  onClose: () => void;
  metricKey?: string;
  conversionName: string;
};

const Conversions: React.FC<ConversionsProps> = ({
  onClose,
  item,
  conversionName,
  metricKey = '',
}) => {
  const [conversion, setConversion] = useState<any>(undefined);
  const [conversions, setConversions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [conversionId, setConversionId] = useState(null);
  const [loadingPixel, setLoadingPixel] = useState(false);
  const [journeyOpen, setJourneyOpen] = useState(false);
  // const [customerProfileProps, setCustomerProfileProps] = useState<CustomerProfileModalProps>();
  const [customerId, setCustomerId] = useState(null);
  const [journey, setJourney] = useState([]);

  const { shouldNotBeSeen: notSeeOrderIdAndCustomerName } = useFeatureFlag(
    FeatureFlag.PIXEL_PURCHASE_POPUP_FF,
  );

  const openCustomerJourney = useCallback(
    (tripleId, conversion) => {
      if (conversionId === conversion.conversionId) {
        // setCustomerProfileProps(undefined);
        setJourneyOpen(false);
        setConversionId(null);
        setConversion(undefined);
      } else {
        setConversion(conversion);
        // setCustomerProfileProps({
        //   shopifyCustomerId: conversion.customerId.toString(),
        //   customerName: conversion.customerId,
        //   startDate: conversion.eventDate,
        //   customerCreatedDate: undefined,
        // });
        setConversionId(conversion.conversionId);
        setJourneyOpen(true);
      }
    },
    [conversionId, conversions],
  );

  let { sourceId } = useContext(AttributionPageContext);

  if (!sourceId && item.serviceId) {
    sourceId = item.serviceId;
  }

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currency = useStoreValue($currency);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const { id: integrationId } =
    sensoryIntegrations.filter((integration) => integration.provider_id === 'recharge')?.[0] ?? '';
  const { dateModel, attributionModel, attributionWindow, useNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );

  const type = mapEntityToBreakdown[item.entity!];
  const source = item.entity === 'channel' ? item.id : sourceId;

  const _onClose = () => {
    onClose();
  };

  const {
    id,
    unmatchedIds,
    unmatchedAdsets,
    unmatchedCampaigns,
    esKey,
    name,
    campaignId,
    adsetId,
  } = item;

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      if (!mainDatePickerSelectionRange) return;
      const { start, end } = mainDatePickerSelectionRange;
      const results = await getConversions(
        dateModel,
        attributionModel,
        source,
        type,
        id,
        unmatchedIds,
        start.format(),
        end.format(),
        attributionWindow,
        useNewModels,
        integrationId,
        [...(campaignId ? [campaignId] : []), ...(unmatchedCampaigns || [])],
        [...(adsetId ? [adsetId] : []), ...(unmatchedAdsets || [])],
        esKey || '',
        metricKey,
      );
      setConversions(results);
      setLoading(false);
    };
    func();
  }, [
    attributionModel,
    dateModel,
    id,
    mainDatePickerSelectionRange,
    sourceId,
    type,
    source,
    attributionWindow,
    name,
    useNewModels,
    integrationId,
    unmatchedIds,
    campaignId,
    adsetId,
    esKey,
    metricKey,
    unmatchedCampaigns,
    unmatchedAdsets,
  ]);

  useEffect(() => {
    async function fetchData() {
      if (conversionId) {
        setLoadingPixel(true);
        const order = conversions.find((x) => x.conversionId === conversionId);
        const shopId = order.integration_id || $currentShopId.get();
        const journeyPromise = getJourney(shopId, order, useNewModels);
        const [journey] = await Promise.all([journeyPromise]);
        setJourney(journey);
        setConversion({ ...conversion, shopId });
        // setCustomerProfileProps((prev = {}) => ({
        //   ...prev,
        //   customerCreatedDate: subscription?.customer?.created_at,
        // }));
        setLoadingPixel(false);
      } else {
        setJourney([]);
      }
    }
    fetchData();
  }, [conversionId, conversions, useNewModels]);

  const totalMrrValue = useMemo(() => {
    return conversions.reduce((acc, o) => acc + (Number(o.mrr) || 0), 0);
  }, [conversions]);
  const totalArrValue = useMemo(() => {
    return conversions.reduce((acc, o) => acc + (Number(o.arr) || 0), 0);
  }, [conversions]);

  return (
    <>
      <Modal
        lockScroll={false}
        opened={true}
        onClose={_onClose}
        size="xl"
        title={
          <>
            <p>{item.name}</p>
            <p style={{ fontSize: '10px', color: 'var(--p-text-subdued)' }}>
              {!loading && `(${conversions.length} ${conversionName} conversions)`}
            </p>
          </>
        }
      >
        <LoadingOverlay
          visible={loading}
          zIndex={10000}
          overlayProps={{ blur: '2px', radius: 'sm', bg: 'named.0' }}
        />
        {!loading && (
          <div>
            <TWTable
              id="subscriptions-table"
              height="500px"
              stickyHeader
              data={conversions}
              columns={[
                {
                  key: 'conversions',
                  dataType: 'text',
                  name: 'Conversions',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Conversions
                    </Text>
                  ),
                  Value: (row) => {
                    return <p className="flex font-semibold">{row.conversionId}</p>;
                  },
                },
                {
                  key: 'date',
                  dataType: 'text',
                  name: 'Date',
                  sortable: false,
                  Heading: () => (
                    <div className="w-[170px]">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Date
                      </Text>
                    </div>
                  ),
                  Value: (row) => {
                    return row.eventDate;
                  },
                },
                {
                  key: 'customer',
                  dataType: 'text',
                  name: 'Customer',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Customer
                    </Text>
                  ),
                  Value: (row) => {
                    const { tripleId, email } = row;
                    const linkText = notSeeOrderIdAndCustomerName
                      ? 'View customer journey'
                      : email ?? tripleId;

                    const val =
                      tripleId !== 'fake' ? (
                        <Link
                          external
                          removeUnderline
                          key={tripleId}
                          onClick={() => openCustomerJourney(tripleId, row)}
                        >
                          <p className="flex font-semibold w-fit">
                            {linkText}
                            {tripleId && <Icon color="interactive" source={ExternalSmallMinor} />}
                          </p>
                        </Link>
                      ) : (
                        <p className="flex font-semibold">{linkText}</p>
                      );
                    return <div className="w-fit">{val}</div>;
                  },
                },
                {
                  key: 'value',
                  dataType: 'text',
                  name: 'Value',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Value
                    </Text>
                  ),
                  Value: (row) => {
                    const { value } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(value, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                },
              ]}
            />
          </div>
        )}
      </Modal>
      {
        <CustomerJourney
          loading={loadingPixel}
          order={undefined}
          subscription={undefined}
          journey={journey}
          isOpen={journeyOpen}
          campaign={item}
          onClose={() => setJourneyOpen(false)}
        />
      }
    </>
  );
};

export default Conversions;
