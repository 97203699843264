import { IconName } from '@tw/ui-components';
import { Column, ColumnName } from '../types/willyTypes';
import { useNavigate } from 'react-router-dom';
import { TemplateLibraryCard } from './TemplateLibraryCard';
import { useAiColumn } from './useAiColumn';
import { $selectedAiColumn } from '$stores/willy/$sequences';

export const ColumnLibraryCard: React.FC<{
  column: Column<ColumnName>;
  onAddToCart?: (id) => void;
  itemInCart?: boolean;
}> = ({ column, onAddToCart, itemInCart }) => {
  const navigate = useNavigate();

  const { existInShop, onCopy, copyLoading, needsUpdate, onUpdate } = useAiColumn({ column });
  const agentColor = column?.themeColor ?? 'blue';
  const icon = (column?.icon as IconName) ?? 'rocket';

  return (
    <TemplateLibraryCard
      exists={!!existInShop}
      themeColor={agentColor}
      onCopy={onCopy}
      cardKey={column?.key}
      isLocked={false}
      onPreviewUrl={`/chat/agents/ai-columns/${column?.key}`}
      name={column?.label ?? ''}
      description={column?.description ?? ''}
      category={column?.category ?? ''}
      providers={[]}
      needsUpdate={needsUpdate}
      copyLoading={copyLoading}
      onUpdate={onUpdate}
      iconSrc={
        !!column.iconSrc
          ? `https://storage.googleapis.com/file-hosting-bucket-shofifi/global-agent-image/${column.key}.png`
          : undefined
      }
      onAddToCart={() => onAddToCart?.(column.key)}
      itemInCart={itemInCart}
    />
  );
};
