import amplitude from 'amplitude-js';
import BaseChart from 'components/library/BaseChart/BaseChart';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import TWImage from 'components/library/TWImage/TWImage';
import DropDown from 'components/ltv/DropDown';
import {
  ATTRIBUTION_COLORS,
  AttributionPageContext,
  ChartData,
  ChartMode,
} from 'constants/attribution';
import {
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import allServices from 'constants/services';
import { cloneDeep, sortBy } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';

import { Checkbox } from '@shopify/polaris';

import { metrics } from '../../constants/metrics/metrics';
import { SettingsMinor } from '@shopify/polaris-icons';
import { useShowTheRightAxis } from 'utils/useShowTheRightAxis';
import { AFFLUENCER } from 'constants/types';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { BaseLegend } from 'components/library/BaseChart/BaseLegend';
import { NewLoader } from 'components/loaders/NewLoader';
import { Flex, Text, Icon, Switch, ActionIcon } from '@tw/ui-components';
import { chartOpenChanged } from 'ducks/attribution/actions';
import BaseTooltip from 'components/library/BaseChart/ChartTooltip';
import CustomLineTooltip from 'components/library/BaseChart/ChartTooltip';
import {
  selectPixelColumnsForCustomEvents,
  selectPixelColumnsForCustomMetrics,
} from 'pages/Attribution/selectPixelColumns';
import ChartSettings from 'components/library/BaseChart/ChartSettings';

type MetricsKeys = keyof typeof metrics | 'none';

interface AttributionChartProps {}

const AttributionChart: FC<AttributionChartProps> = () => {
  const {
    selectedAttributions,
    loadingAttributionComparisons,
    loading,
    currency,
    setSelectedAttributions,
    sourceId,
    dateToCompare,
  } = useContext(AttributionPageContext);

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const [lineData, setLineData] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);
  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>('metrics');
  const [chartMode, setChartMode] = useState<ChartMode>('line');
  const [allCurrentPeriod, setAllCurrentPeriod] = useState('');
  const [allPreviousPeriod, setAllPreviousPeriod] = useState('');
  const [showRightAxis, setShowRightAxis] = useState(true);
  const dispatch = useDispatch();
  const [showDebugTooltip, setShowDebugTooltip] = useState(false);

  const defaultPrimaryMetric =
    (localStorage.getItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC) as MetricsKeys) ||
    ('spend' as MetricsKeys);

  const defaultSecondaryMetric =
    (localStorage.getItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC) as MetricsKeys) ||
    ('pixelConversionValue' as MetricsKeys);

  const [primaryMetric, setPrimaryMetric] = useState<MetricsKeys>(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState<MetricsKeys>(defaultSecondaryMetric);

  // TODO: maybe we want to add all pixel metrics here
  const pixelColumnsForCustomMetrics = useSelector(selectPixelColumnsForCustomMetrics);
  const pixelColumnsForCustomEvents = useSelector(selectPixelColumnsForCustomEvents);

  const useRightAxis = useShowTheRightAxis([
    primaryMetric,
    secondaryMetric === 'none' ? primaryMetric : secondaryMetric,
  ] as (keyof typeof metrics)[]);
  const isSmall = useIsSmall();

  useEffect(() => {
    if (chartData === 'metrics') {
      setShowRightAxis(useRightAxis);
    } else {
      setShowRightAxis(false);
    }
  }, [useRightAxis, chartData]);

  const toggleActive = useCallback(
    (item: AttributionData) => {
      setSelectedAttributions!((oldCampaigns) => {
        return oldCampaigns.map((campaign) => ({
          ...campaign,
          active: campaign.id === item.id ? !campaign.active : campaign.active,
          adsets: campaign.adsets?.map((adset) => ({
            ...adset,
            active: adset.id === item.id ? !adset.active : adset.active,
            ads: adset.ads?.map((ad) => ({
              ...ad,
              active: ad.id === item.id ? !ad.active : ad.active,
            })),
          })),
        }));
      });
    },
    [setSelectedAttributions],
  );

  const lineHovered = useCallback(
    (item: AttributionData) => {
      if (item.active) {
        return;
      }
      setHoveredItems((items) => [...items, item!.id!]);
      toggleActive?.(item);
    },
    [toggleActive],
  );

  const lineLeft = useCallback(
    (attribution: AttributionData) => {
      const copy = cloneDeep(hoveredItems);
      const index = copy.findIndex((item) => attribution.id === item);
      if (index === -1) {
        return;
      }
      toggleActive?.(attribution);
      copy.splice(index, 1);
      setHoveredItems(copy);
    },
    [hoveredItems, toggleActive],
  );

  const handlePrimaryMetricChange = useCallback((metric: MetricsKeys) => {
    amplitude
      .getInstance()
      .logEvent('Attribution: Chart Metric Changed', { yAxis: 'left', metric: metric });
    setPrimaryMetric(metric);
    localStorage.setItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC, metric);
  }, []);

  const handleSecondaryMetricChange = useCallback((metric: MetricsKeys) => {
    setSecondaryMetric(metric);
    amplitude
      .getInstance()
      .logEvent('Attribution: Chart Metric Changed', { yAxis: 'right', metric: metric });
    localStorage.setItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC, metric);
  }, []);

  const getData = useCallback(() => {
    if (!mainDatePickerSelectionRange) {
      return;
    }

    let linesData: any = [];
    let barsData: any = [];

    if (chartData === 'metrics') {
      linesData =
        selectedAttributions?.[0]?.metricsBreakdown?.map?.((d) => ({
          date: d.date,
        })) || [];
      selectedAttributions?.forEach((item) => {
        item.metricsBreakdown?.forEach((bd) => {
          let date = linesData?.find((date) => date.date === bd.date);
          if (!date) {
            date = { date: bd.date };
            linesData?.push(date);
          }
          date[item.id!] = {
            ...bd.metrics,
            id: item.id,
            color: item.color,
          };
        });
      });

      selectedAttributions!.forEach((data) => {
        barsData.push({
          [primaryMetric]: data[primaryMetric] ? Number(data[primaryMetric]!)?.toFixed(2) || 0 : 0,
          [secondaryMetric]:
            (data[secondaryMetric] ? Number(data[secondaryMetric])?.toFixed(2) : 0) || 0,
          fill: data.color,
          opacity: data.active ? 1 : 0.1,
        });
      });

      const bbb = selectedAttributions.map((data) => ({
        [primaryMetric]: Number(data[primaryMetric] || 0)?.toFixed(2),
        [secondaryMetric]: Number(data[secondaryMetric] || 0)?.toFixed(2),
        fill: data.color,
        opacity: data.active ? 1 : 0.1,
      }));

      linesData = sortBy(linesData, 'date');
      setLineData(linesData);
      setBarData(bbb);
    } else {
      linesData = selectedAttributions?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d.date }))!;
      selectedAttributions?.forEach((item) => {
        item.metricsBreakdown?.forEach((bd, i) => {
          let date = linesData?.find((date) => date.date === bd.date);
          const prevMetrics = item.comparisons?.metricsBreakdown?.[i]?.metrics || {};
          const prevDate = item.comparisons?.metricsBreakdown?.[i]?.date;
          if (!date) {
            date = { date: bd.date };
            linesData.push(date);
          }
          date[item.id!] = {
            ...bd.metrics,
            id: item.id,
            color: item.color,
            prevPeriod: prevMetrics,
            currDate: date.date,
            prevDate,
          };
        });
      });

      const barsData = selectedAttributions.map((data) => {
        const currentPeriod = `${mainDatePickerSelectionRange.start.format(
          'll',
        )} - ${mainDatePickerSelectionRange.end.format('ll')}`;
        const previousPeriod = `${moment(dateToCompare.start).format('ll')} - ${moment(
          dateToCompare.end,
        ).format('ll')}`;

        setAllCurrentPeriod(currentPeriod);
        setAllPreviousPeriod(previousPeriod);

        return {
          [primaryMetric]: Number(data[primaryMetric] || 0)?.toFixed(2),
          prevPeriod: {
            [primaryMetric]: Number(data.comparisons?.[primaryMetric] || 0)?.toFixed(2),
          },
          fill: data.color,
          opacity: data.active ? 1 : 0.1,
          id: data.id,
        };
      });

      setLineData(linesData);
      setBarData(barsData);
    }
  }, [
    primaryMetric,
    secondaryMetric,
    selectedAttributions,
    chartData,
    mainDatePickerSelectionRange,
    dateToCompare,
  ]);

  const getDropdownOptions = useCallback(() => {
    const metricOptions = Object.values(metrics).filter(
      (m) => !m.hideInPixel && m.key !== 'adsInventory',
    );
    const customMetrics = [...pixelColumnsForCustomMetrics, ...pixelColumnsForCustomEvents].map(
      (m) => ({
        value: m.key,
        label: m.name,
      }),
    );

    return [
      { label: 'None', value: 'none' },
      ...metricOptions.map((value) => ({
        label: value.label,
        value: value.key,
      })),
      ...customMetrics,
    ];
  }, [pixelColumnsForCustomEvents, pixelColumnsForCustomMetrics]);

  const tickFormatter = useCallback(
    (value: number, index: number, metric: MetricsKeys) => {
      if (index % 2 !== 0) {
        return '';
      }
      const { format, toFixed, minimumFractionDigits } = metrics[metric] || {};
      return formatNumber(value, {
        style: format || 'decimal',
        currency,
        maximumFractionDigits: toFixed ?? 2,
        minimumFractionDigits: minimumFractionDigits ?? 0,
        notation: 'compact',
      });
    },
    [currency],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="blue-chart-wrapper">
      <div className="blue-chart">
        {((loading && chartData === 'metrics') ||
          (loadingAttributionComparisons && chartData === 'periods')) && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <NewLoader />
            </div>
          </div>
        )}
        <div
          className="blue-chart-top-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
          }}
        >
          <span
            style={{
              marginLeft: '24px',
              fontSize: '18px',
              fontWeight: 500,
            }}
          >
            Traffic Sources
          </span>
          {/* <DropDown
              value={chartData}
              handleSelect={setChartData}
              options={[
                { label: 'Metrics', value: 'metrics' },
                { label: 'Periods', value: 'periods' },
              ]}
            /> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <DropDown
              showFilter
              value={primaryMetric}
              handleSelect={handlePrimaryMetricChange}
              options={getDropdownOptions()}
              size="xs"
            />
            {chartData === 'metrics' && (
              <DropDown
                showFilter
                value={secondaryMetric}
                handleSelect={handleSecondaryMetricChange}
                options={getDropdownOptions()}
                size="xs"
              />
            )}
            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />
            <DropDown
              value={chartMode}
              handleSelect={(value) => {
                setChartMode(value as ChartMode);
                amplitude
                  .getInstance()
                  .logEvent('Attribution: Chart Type Changed', { chartType: value });
              }}
              options={[
                {
                  label: 'Line',
                  value: 'line',
                  icon: 'line-chart',
                },
                {
                  label: 'Bar',
                  value: 'bar',
                  icon: 'bar-chart',
                },
              ]}
              size="xs"
              customActivator={
                <ActionIcon
                  size="md"
                  radius="sm"
                  variant="activator"
                  icon={chartMode === 'line' ? 'line-chart' : 'bar-chart'}
                />
              }
            />
            <ChartSettings
              showRightAxis={showRightAxis}
              setShowRightAxis={setShowRightAxis}
              chartData={chartData}
              setChartData={setChartData}
            />
            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />
            <div className="cursor-pointer" style={{ paddingRight: '24px' }}>
              <ActionIcon
                size="md"
                radius="sm"
                variant="activator"
                icon="close"
                iconColor="gray.5"
                onClick={() => {
                  setSelectedAttributions?.([]);
                  dispatch(chartOpenChanged(false));
                }}
              />
            </div>
          </div>
        </div>
        {chartMode === 'line' && (
          <BaseChart
            data={lineData}
            showTooltip={true}
            ChartType={LineChart}
            margin={{ left: 10, right: showRightAxis ? 10 : 10 }} // Ensures equal margins
            leftYAxisLabel={metrics[primaryMetric]?.label || primaryMetric}
            rightYAxisLabel={
              showRightAxis ? metrics[secondaryMetric]?.label || secondaryMetric : ''
            }
            xAxisLabel="Date"
            xAxis={[
              {
                tickFormatter: (value: string) => {
                  if (!value || !moment(value).isValid()) {
                    return '';
                  }
                  if (value.includes('T')) {
                    return moment(value).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dataKey: 'date',
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, primaryMetric);
                },
              },
              {
                yAxisId: showRightAxis ? 'right' : 'left',
                orientation: showRightAxis ? 'right' : 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, secondaryMetric);
                },
              },
            ]}
          >
            {selectedAttributions &&
              selectedAttributions.map((sel) => {
                return (
                  <Fragment key={sel.id}>
                    <Line
                      yAxisId={'left'}
                      type="monotone"
                      name={metrics[primaryMetric]?.label}
                      dataKey={(dataPoint) => {
                        return dataPoint[sel.id!] && dataPoint[sel.id!][primaryMetric]
                          ? dataPoint[sel.id!][primaryMetric]
                          : 0;
                      }}
                      stroke={sel.color}
                      strokeWidth={3}
                      strokeOpacity={sel.active ? 1 : 0}
                      dot={false}
                      activeDot={{
                        onClick: () => toggleActive?.(sel),
                        style: {
                          cursor: 'pointer',
                          fill: sel.active ? 'white' : 'transparent',
                          opacity: sel.active ? 1 : 0,
                        },
                      }}
                      onMouseOver={() => lineHovered(sel)}
                      onMouseLeave={() => lineLeft(sel)}
                      onClick={() => toggleActive?.(sel)}
                      style={{ cursor: 'pointer' }}
                    />
                    {chartData === 'metrics' && secondaryMetric !== 'none' && (
                      <Line
                        yAxisId={showRightAxis ? 'right' : 'left'}
                        type="monotone"
                        name={metrics[secondaryMetric]?.label}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] && dataPoint[sel.id!][secondaryMetric]
                            ? dataPoint[sel.id!][secondaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          style: { display: 'none' },
                          strokeOpacity: 0,
                        }}
                        strokeDasharray="3,3"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {chartData === 'periods' && (
                      <Line
                        yAxisId={showRightAxis ? 'right' : 'left'}
                        type="monotone"
                        name={'Previous Period'}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] &&
                            dataPoint[sel.id!]?.prevPeriod?.[primaryMetric]
                            ? dataPoint[sel.id!]?.prevPeriod?.[primaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          style: { display: 'none' },
                          strokeOpacity: 0,
                        }}
                        strokeDasharray="3,3"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <ChartTooltip
                      content={
                        <CustomLineTooltip
                          data={selectedAttributions}
                          primaryMetric={primaryMetric}
                          secondaryMetric={secondaryMetric}
                          chartData={chartData}
                          showImages={true}
                        />
                      }
                      cursor={{ fill: 'none' }}
                      labelStyle={{ fontWeight: 'bold' }}
                      contentStyle={{ fontSize: '10px' }}
                      formatter={(value: number, metricLabel: string) => {
                        const metric = Object.values(metrics).find(
                          (m) => m.label === metricLabel,
                        )?.key;
                        if (!metric) {
                          return value;
                        }
                        if (!isNaN(value)) {
                          return value;
                        }
                        return value;
                      }}
                      labelFormatter={(value) => {
                        if (value && moment(value).isValid()) {
                          value = '' + value;
                          return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                        }
                        return value;
                      }}
                    />
                  </Fragment>
                );
              })}
          </BaseChart>
        )}

        {chartMode === 'bar' && (
          <BaseChart
            data={barData}
            showTooltip={true}
            margin={{ left: 20, right: showRightAxis ? 20 : 20 }} // Ensures equal margins
            ChartType={BarChart}
            leftYAxisLabel={metrics[primaryMetric]?.label || primaryMetric}
            rightYAxisLabel={
              showRightAxis ? metrics[secondaryMetric]?.label || secondaryMetric : ''
            }
            xAxisLabel="Date"
            xAxis={[
              {
                dataKey: 'date',
                tickFormatter: (value) => {
                  return !!value && moment(value).isValid() ? moment(value).format('MMM D') : '';
                },
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, primaryMetric);
                },
              },
              {
                yAxisId: showRightAxis ? 'right' : 'left',
                orientation: showRightAxis ? 'right' : 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, secondaryMetric);
                },
              },
            ]}
          >
            <defs>
              {ATTRIBUTION_COLORS.map((color, index) => (
                <linearGradient
                  key={'color-' + index + '-' + color}
                  id={`color-creative-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0" stopColor={color} stopOpacity={1} />
                  <stop offset="1" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <Bar
              barSize={22}
              yAxisId={'left'}
              dataKey={primaryMetric}
              name={
                (chartData === 'metrics' ? metrics[primaryMetric]?.label : allCurrentPeriod) ||
                primaryMetric ||
                ''
              }
            ></Bar>
            <Bar
              barSize={22}
              yAxisId={showRightAxis ? 'right' : 'left'}
              dataKey={(dataPoint) => {
                return chartData === 'metrics'
                  ? dataPoint[secondaryMetric]
                  : dataPoint.prevPeriod?.[primaryMetric];
              }}
              name={
                (chartData === 'metrics' ? metrics[secondaryMetric]?.label : allPreviousPeriod) ||
                secondaryMetric ||
                ''
              }
            >
              {selectedAttributions?.map((entry, index) => (
                <Cell
                  key={`cell-gradient-${entry.accountId}-${index}`}
                  fill={`url(#color-creative-${index % ATTRIBUTION_COLORS.length})`}
                  opacity={entry.active ? 1 : 0.1}
                />
              ))}
            </Bar>
            <ChartTooltip
              cursor={{ fill: 'none' }}
              label={metrics[primaryMetric]?.label || primaryMetric || ''}
              labelStyle={{ fontWeight: 'bold' }}
              labelFormatter={(label) => {
                if (chartData === 'periods') {
                  return metrics[primaryMetric]?.label || primaryMetric || '';
                }
                return label;
              }}
              contentStyle={{ fontSize: '10px' }}
              formatter={(value: number, metricLabel: string, payload) => {
                // if (chartData === 'comparison') {
                //   console.log({ payload });
                //   return payload.payload?.prevDate;
                // }
                const metric = Object.values(metrics).find((m) => m.label === metricLabel)?.key;
                if (!metric) {
                  return value;
                }
                if (!isNaN(value)) {
                  return formatNumber(value, {
                    style: metrics[metric]?.format || 'decimal',
                    currency,
                    minimumFractionDigits: metrics[metric]?.toFixed,
                    maximumFractionDigits: metrics[metric]?.toFixed,
                  });
                }
                return value;
              }}
              // labelFormatter={(value) => {
              //   if (moment(value).isValid()) {
              //     value = '' + value;
              //     return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
              //   }
              //   return value;
              // }}
            />
          </BaseChart>
        )}
        <div
          className="flex overflow-auto gap-4 p-[var(--padding)]"
          style={{
            paddingInline: chartMode === 'bar' && !isSmall ? '3.75rem 2.5rem' : '',
            // justifyContent: chartMode === 'bar' ? 'space-around' : 'flex-end',
            justifyContent: 'flex-start',

            transition: 'all 0.3s ease',
          }}
        >
          {selectedAttributions?.map((dataObj) => (
            <BaseLegend
              color={dataObj.color ?? '#000'}
              key={`legend-${dataObj.id}`}
              tooltipText={dataObj.name || allServices[dataObj.id!]?.title}
              label={dataObj.entity}
              onClick={sourceId !== 'all' ? () => toggleActive?.(dataObj) : undefined}
              active={dataObj.active}
              circle
              content={
                dataObj.imageUrl ? (
                  <TWImage
                    src={dataObj.imageUrl}
                    className="w-full h-full object-cover rounded-md"
                    wrapperClass="w-2.5 h-2.5"
                  />
                ) : sourceId === 'all' ? (
                  <div style={{ marginLeft: '-4px' }}>
                    {allServices[dataObj.id!]?.icon?.({ small: false })}
                  </div>
                ) : (
                  <div style={{ marginLeft: '-4px' }}>
                    {allServices[sourceId]?.icon?.({ small: false })}
                  </div>
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttributionChart;
