import { Flex, Text } from '@tw/ui-components';
import { AddMetricPopover } from './TableBuilderTab';
import { BuilderBadge } from './BuilderBadge';
import { FC, useMemo } from 'react';
import { BqColumn, BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { WillyMetric } from 'components/Willy/types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $tables } from '$stores/willy/$tables';

type AddMetricsSectionsProps = {
  builder: BuilderTable;
  setBuilder: (builder: BuilderTable) => void;
  columnsAsMetrics: WillyMetric[];
  columns: BqColumn[];
  addColumnToMetrics?: (columnId: string) => void;
  removeColumnFromMetrics?: (columnId: string) => void;
  onHideMetricChanged?: (columnId: string) => void;
  disabled?: boolean;
};

export const AddMetricsSections: FC<AddMetricsSectionsProps> = ({
  builder,
  setBuilder,
  columnsAsMetrics,
  columns,
  addColumnToMetrics,
  removeColumnFromMetrics,
  onHideMetricChanged,
  disabled,
}) => {
  const tables = useStoreValue($tables);

  const currentTable = useMemo(() => {
    return tables.find((t) => t.id === builder?.table);
  }, [tables, builder]);

  const addEventDateIfNoAnyDimension = () => {
    try {
      const apiColumns = builder?.columns
        .map((c) => {
          return columns.find((col) => col.id === c.columnId);
        })
        .filter((c) => c !== undefined) as BqColumn[];
      if (apiColumns.every((c) => c.type === 'numeric' || c.type === 'formula')) {
        builder?.columns.push({
          columnId: 'event_date',
          aggFunction: 'SUM',
        });
        addColumnToMetrics?.('event_date');
      }
      return builder?.columns;
    } catch (e) {
      console.error(e);
      return builder?.columns;
    }
  };

  return (
    <Flex direction="column" gap="sm">
      <Flex justify="space-between" align="center">
        <Text weight={500} color="gray.8" size="sm">
          Add Metrics
        </Text>
        <AddMetricPopover
          disabled={disabled}
          metrics={columnsAsMetrics.filter((m) => !m.isDimension)}
          onAdd={(metric) => {
            metric.active
              ? addColumnToMetrics?.(metric.key)
              : removeColumnFromMetrics?.(metric.key);
            setBuilder({
              ...builder!,
              columns: metric.active
                ? [...addEventDateIfNoAnyDimension(), { columnId: metric.key, aggFunction: 'SUM' }]
                : builder!.columns.filter((c) => c.columnId !== metric.key),
            });
          }}
          readmeUrl={currentTable?.readmeUrl}
          tableName={currentTable?.name}
          offset={10}
        />
      </Flex>
      {columnsAsMetrics
        .filter((m) => m.active && !m.isDimension)
        .map((m) => (
          <BuilderBadge
            key={`metric-${m.key}`}
            text={m.name}
            type="metric"
            onRemove={() => {
              m.active = false;
              setBuilder({
                ...builder!,
                columns: builder!.columns.filter((c) => c.columnId !== m.key),
              });
              removeColumnFromMetrics?.(m.key);
            }}
            onHideChange={() => onHideMetricChanged?.(m.key)}
            subText={m.key}
            description={m.description}
            readmeUrl={m.readmeUrl}
          />
        ))}
      {builder.columns.length > 0 && (
        <Flex justify="space-between" align="center">
          <Text weight={500} color="gray.6" tt="uppercase" size="xs">
            By Dimension
          </Text>
          <AddMetricPopover
            metrics={columnsAsMetrics.filter((m) => m.isDimension)}
            disabled={disabled}
            onAdd={(metric) => {
              metric.active
                ? addColumnToMetrics?.(metric.key)
                : removeColumnFromMetrics?.(metric.key);
              setBuilder({
                ...builder!,
                columns: metric.active
                  ? [...builder.columns, { columnId: metric.key, aggFunction: 'SUM' }]
                  : builder!.columns.filter((c) => c.columnId !== metric.key),
              });
            }}
            readmeUrl={currentTable?.readmeUrl}
            tableName={currentTable?.name}
            offset={10}
          />
        </Flex>
      )}
      {columnsAsMetrics.filter((m) => m.active && m.isDimension).length > 0
        ? columnsAsMetrics
            .filter((m) => m.active && m.isDimension)
            .map((m) => (
              <BuilderBadge
                key={`metric-dimension-${m.key}`}
                text={m.name}
                type="dimension"
                onRemove={() => {
                  m.active = false;
                  setBuilder({
                    ...builder!,
                    columns: builder!.columns.filter((c) => c.columnId !== m.key),
                  });
                  removeColumnFromMetrics?.(m.key);
                }}
                onHideChange={() => onHideMetricChanged?.(m.key)}
                subText={m.key}
                description={m.description}
                readmeUrl={m.readmeUrl}
              />
            ))
        : builder?.columns.length > 0 && (
            <Text color="gray.4" size="xs" weight={500}>
              Example: by week, by country
            </Text>
          )}
    </Flex>
  );
};
