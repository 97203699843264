import { Text } from '@tw/ui-components';
import { stepActionType } from '../types/willyTypes';

type FlowStructuredInsightsStepProps = {
  toolConfig?: stepActionType;
  depth: number;
};

export const FlowStructuredInsightsStep: React.FC<FlowStructuredInsightsStepProps> = ({
  toolConfig,
  depth,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <Text>{toolConfig?.tooltip}</Text>
      <Text>This step must come right after an insights step.</Text>
    </div>
  );
};
