import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useNavigate } from 'react-router-dom';
import CircleLockIcon from './CircleLockIcon';
import { genericEventLogger, analyticsEvents, upgradeActions } from 'utils/dataLayer';
import { OPEN_UPGRADE_PLAN_MODAL, upgradePlanClicked } from '../../../ducks/subscription';
import { useAppDispatch } from '../../../index';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isDefined } from 'utils/isDefined';
import { userDb } from 'utils/DB';
import Cookies from 'utils/Cookies';
import { subscriptionItem } from '@tw/types/module/services/subscription-manager';
import { useStoreValue } from '@tw/snipestate';
import { $currentPackagePlanPreview, $isFreeShop } from '../../../$stores/willy/$subscription';
import { $currentShopId } from '$stores/$shop';
import { $activeAppVersion } from '$stores/nav-config-stores';

const freePlanText =
  'You’re currently on the free plan of Triple Whale Summary, which is limited to the last 12 months of data.';
const upgradeText =
  'to unlock unlimited history, every integration, dozens more metrics, and more.';
const planPreviewUpgradeText = 'to retain access to all of your current features.';
const planPreviewText = (item: subscriptionItem) => {
  if (!item.plan_preview_end_date) return '';
  return (
    <div>
      <p>
        You are currently previewing the <strong>{item?.product_name}</strong> plan. Your preview
        will expire on{' '}
        <strong>
          {new Date(item?.plan_preview_end_date).toLocaleDateString('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
          })}
        </strong>
        .
      </p>
    </div>
  );
};

interface IUpgradeBar {
  feature: FeatureFlag;
}

const UpgradeBar: FC<IUpgradeBar> = ({ feature }) => {
  const appVersion = useStoreValue($activeAppVersion);
  const dispatch = useAppDispatch();
  const [isDismissed, setIsDismissed] = useState(false);
  const currentShopId = useStoreValue($currentShopId);
  const isFreeShop = useStoreValue($isFreeShop);
  const currentPackagePlanPreview = useStoreValue($currentPackagePlanPreview);

  const navigate = useNavigate();

  const upgrade = useCallback(() => {
    if (isFreeShop) {
      dispatch(upgradePlanClicked(navigate));
      genericEventLogger(analyticsEvents.UPGRADE, {
        action: upgradeActions.UPGRADE_CLICKED,
        feature,
        upgrade_path: 'top_banner',
        isFoundersDash: isFreeShop,
      });
    } else if (!!currentPackagePlanPreview) {
      dispatch({
        type: OPEN_UPGRADE_PLAN_MODAL,
        payload: currentPackagePlanPreview?.product_id,
      });
    }
  }, [feature, navigate, isFreeShop, currentPackagePlanPreview, dispatch]);

  const dismiss = useCallback(() => {
    setIfDismissedUpgradeBar(currentShopId!, true);
    setIsDismissed(true);
  }, [currentShopId]);

  useEffect(() => {
    getIfDismissedUpgradeBar(currentShopId!).then((val) => setIsDismissed(val));
  }, [currentShopId]);

  if (appVersion !== '2.0') return null;

  return (
    <React.Fragment>
      {(isFreeShop || !!currentPackagePlanPreview) && (
        <React.Fragment>
          <div
            className={`bg-[#1877F2] py-[16px] px-[10px] lg:px-[46px] flex sticky top-0 z-10 items-center transition-height duration-200
        ${isDismissed ? 'h-[0px] overflow-hidden !py-[0px]' : ''}
      `}
          >
            <CircleLockIcon borderColor="none" />
            <div className="ml-[8px] lg:ml-[30px] mr-auto pr-[10px]">
              <p className="text-white text-[14px] font-medium lg:block hidden">
                {!!currentPackagePlanPreview
                  ? planPreviewText(currentPackagePlanPreview)
                  : freePlanText}
              </p>
              <p className="text-white text-[14px]">
                <span className="hidden lg:inline">Upgrade today</span>
                <span className="lg:hidden underline cursor-pointer" onClick={upgrade}>
                  Upgrade today
                </span>
                <span className="ml-[4px]">
                  {!!currentPackagePlanPreview ? planPreviewUpgradeText : upgradeText}
                </span>
              </p>
            </div>
            <button
              className="hidden lg:block ml-[auto] bg-white text-[#374151] border border-solid border-[#D1D5DB] font-medium text-[12px] outline-none px-[18px] py-[10px] rounded-[4px] cursor-pointer"
              onClick={upgrade}
            >
              Upgrade
            </button>
            <CloseIcon
              className="fill-white ml-[15px] cursor-pointer shrink-0"
              width={15}
              onClick={dismiss}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UpgradeBar;

const UPGRADE_BAR_DISMISSED = 'upgradeBarDismissed';
const getIfDismissedUpgradeBar = async (currentShopId: string): Promise<boolean> => {
  const uiStr = Cookies.get('ui');
  const uiObj = !uiStr ? null : JSON.parse(uiStr) || null;
  let dismissed: boolean | undefined = uiObj?.[UPGRADE_BAR_DISMISSED];

  if (isDefined(dismissed)) return dismissed;

  if (currentShopId) {
    const userData = (await userDb().get()).data();
    dismissed = userData?.shops?.[currentShopId]?.ui?.[UPGRADE_BAR_DISMISSED];
    if (isDefined(dismissed)) return dismissed;
  }

  return false;
};

const setIfDismissedUpgradeBar = (currentShopId: string, value: boolean): void => {
  const uiStr = Cookies.get('ui');
  const uiObj = uiStr ? JSON.parse(uiStr) : {};

  Cookies.set(
    'ui',
    JSON.stringify({
      ...uiObj,
      [UPGRADE_BAR_DISMISSED]: value,
    }),
  );

  userDb().set(
    { shops: { [currentShopId]: { ui: { [UPGRADE_BAR_DISMISSED]: value } } } },
    { merge: true },
  );
};
