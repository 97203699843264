import { AttributionPageContext } from 'constants/attribution';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';
import { Text, Link, Icon } from '@shopify/polaris';
import { mapEntityToBreakdown } from 'utils/attributions';
import TWTable from 'components/library/TWTable/TWTable';
import { LoadingOverlay, Modal } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import { getSubscriptions } from 'ducks/subscription';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useCallback } from 'react';
import {
  CustomerProfileModal,
  CustomerProfileModalProps,
} from 'components/Insights/CustomerProfile/Activities/CustomerProfileModal';
import { CustomerJourney } from 'components/attribution/components';
import { $currentShopId } from '../../$stores/$shop';
import { getJourney } from 'components/attribution/utils';

type SubscriptionsProps = {
  item: AttributionData;
  onClose: () => void;
  metricKey?: string;
};

const Subscriptions: React.FC<SubscriptionsProps> = ({ onClose, item, metricKey = '' }) => {
  const [subscription, setSubscription] = useState<any>(undefined);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loadingPixel, setLoadingPixel] = useState(false);
  const [journeyOpen, setJourneyOpen] = useState(false);
  const [customerProfileProps, setCustomerProfileProps] = useState<CustomerProfileModalProps>();
  const [customerId, setCustomerId] = useState(null);
  const [journey, setJourney] = useState([]);

  const { shouldNotBeSeen: notSeeOrderIdAndCustomerName } = useFeatureFlag(
    FeatureFlag.PIXEL_PURCHASE_POPUP_FF,
  );

  const openCustomerJourney = useCallback(
    (customerId, subscription) => {
      if (subscriptionId === subscription.subscriptionId) {
        setCustomerProfileProps(undefined);
        setJourneyOpen(false);
        setSubscriptionId(null);
        setSubscription(undefined);
      } else {
        setSubscription(subscription);
        setCustomerProfileProps({
          shopifyCustomerId: subscription.customerId.toString(),
          customerName: subscription.customerId,
          startDate: subscription.eventDate,
          customerCreatedDate: undefined,
        });
        setSubscriptionId(subscription.subscriptionId);
        setJourneyOpen(true);
      }
    },
    [customerId, subscriptionId],
  );

  let { sourceId } = useContext(AttributionPageContext);

  if (!sourceId && item.serviceId) {
    sourceId = item.serviceId;
  }

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currency = useStoreValue($currency);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const { id: integrationId } =
    sensoryIntegrations.filter((integration) => integration.provider_id === 'recharge')?.[0] ?? '';
  const { dateModel, attributionModel, attributionWindow, useNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );

  const type = mapEntityToBreakdown[item.entity!];
  const source = item.entity === 'channel' ? item.id : sourceId;

  const _onClose = () => {
    onClose();
  };

  const {
    id,
    unmatchedIds,
    unmatchedAdsets,
    unmatchedCampaigns,
    esKey,
    name,
    campaignId,
    adsetId,
  } = item;

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      if (!mainDatePickerSelectionRange) return;
      const { start, end } = mainDatePickerSelectionRange;
      const results = await getSubscriptions(
        dateModel,
        attributionModel,
        source,
        type,
        id,
        unmatchedIds,
        start.format(),
        end.format(),
        name,
        attributionWindow,
        useNewModels,
        integrationId,
        [...(campaignId ? [campaignId] : []), ...(unmatchedCampaigns || [])],
        [...(adsetId ? [adsetId] : []), ...(unmatchedAdsets || [])],
        esKey || '',
      );
      setSubscriptions(results);
      setLoading(false);
    };
    func();
  }, [
    attributionModel,
    dateModel,
    id,
    mainDatePickerSelectionRange,
    sourceId,
    type,
    source,
    attributionWindow,
    name,
    useNewModels,
    integrationId,
    unmatchedIds,
    campaignId,
    adsetId,
    esKey,
    metricKey,
    unmatchedCampaigns,
    unmatchedAdsets,
  ]);

  useEffect(() => {
    async function fetchData() {
      if (subscriptionId) {
        setLoadingPixel(true);
        const order = subscriptions.find((x) => x.subscriptionId === subscriptionId);
        const shopId = order.integration_id || $currentShopId.get();
        const journeyPromise = getJourney(shopId, order, useNewModels);
        const [journey] = await Promise.all([journeyPromise]);
        setJourney(journey);
        setSubscription({ ...subscription, shopId });
        setCustomerProfileProps((prev = {}) => ({
          ...prev,
          customerCreatedDate: subscription?.customer?.created_at,
        }));
        setLoadingPixel(false);
      } else {
        setJourney([]);
      }
    }
    fetchData();
  }, [subscriptionId, subscriptions, useNewModels]);

  const totalMrrValue = useMemo(() => {
    return subscriptions.reduce((acc, o) => acc + (Number(o.mrr) || 0), 0);
  }, [subscriptions]);
  const totalArrValue = useMemo(() => {
    return subscriptions.reduce((acc, o) => acc + (Number(o.arr) || 0), 0);
  }, [subscriptions]);

  return (
    <>
      <Modal
        lockScroll={false}
        opened={true}
        onClose={_onClose}
        size="xl"
        title={
          <>
            <p>{item.name}</p>
            <p style={{ fontSize: '10px', color: 'var(--p-text-subdued)' }}>
              {!loading && `(${subscriptions.length} subscriptions)`}
            </p>
          </>
        }
      >
        <LoadingOverlay
          visible={loading}
          zIndex={10000}
          overlayProps={{ blur: '2px', radius: 'sm', bg: 'named.0' }}
        />
        {!loading && (
          <div>
            <TWTable
              id="subscriptions-table"
              height="500px"
              stickyHeader
              data={subscriptions}
              columns={[
                {
                  key: 'subscription',
                  dataType: 'text',
                  name: 'Subscription',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Subscription
                    </Text>
                  ),
                  Value: (row) => {
                    return (
                      <Link
                        external
                        removeUnderline
                        key={row.subscriptionId}
                        url={`https://dashboard.stripe.com/subscriptions/${row.subscriptionId}`}
                      >
                        <p className="flex font-semibold">
                          {row.subscriptionId}
                          {row.subscriptionId && (
                            <Icon color="interactive" source={ExternalSmallMinor} />
                          )}
                        </p>
                      </Link>
                    );
                  },
                },
                {
                  key: 'date',
                  dataType: 'text',
                  name: 'Date',
                  sortable: false,
                  Heading: () => (
                    <div className="w-[170px]">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Date
                      </Text>
                    </div>
                  ),
                  Value: (row) => {
                    return row.eventDate;
                  },
                },
                {
                  key: 'customer',
                  dataType: 'text',
                  name: 'Customer',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Customer
                    </Text>
                  ),
                  Value: (row) => {
                    const { customerId } = row;
                    const linkText = notSeeOrderIdAndCustomerName
                      ? 'View customer journey'
                      : customerId;

                    const val =
                      customerId !== 'fake' ? (
                        <Link
                          external
                          removeUnderline
                          key={customerId}
                          onClick={() => openCustomerJourney(customerId, row)}
                        >
                          <p className="flex font-semibold">
                            {linkText}
                            {customerId && <Icon color="interactive" source={ExternalSmallMinor} />}
                          </p>
                        </Link>
                      ) : (
                        <p className="flex font-semibold">{linkText}</p>
                      );

                    return <div className="w-fit">{val}</div>;
                  },
                },
                {
                  key: 'mrr',
                  dataType: 'text',
                  name: 'MRR',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      MRR
                    </Text>
                  ),
                  Value: (row) => {
                    const { mrr } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(mrr, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                  Total: () => {
                    return formatNumber(totalMrrValue, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    });
                  },
                },
                {
                  key: 'arr',
                  dataType: 'text',
                  name: 'ARR',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      ARR
                    </Text>
                  ),
                  Value: (row) => {
                    const { arr } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(arr, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                  Total: () => {
                    return formatNumber(totalArrValue, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    });
                  },
                },
                {
                  key: 'status',
                  dataType: 'text',
                  name: 'Status',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Status
                    </Text>
                  ),
                  Value: (row) => {
                    const { status } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {status}
                      </Text>
                    );
                  },
                },
              ]}
            />
          </div>
        )}
      </Modal>
      {
        <CustomerJourney
          loading={loadingPixel}
          order={undefined}
          subscription={subscription}
          journey={journey}
          isOpen={journeyOpen}
          campaign={item}
          onClose={() => setJourneyOpen(false)}
        />
      }
    </>
  );
};

export default Subscriptions;
