import { useState, useCallback } from 'react';
import { Text } from '@tw/ui-components';
import { WorkflowStep, WorkflowStepPreloadData } from '../types/willyTypes';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import { AttributionHeaderColumnSelector } from 'components/headers/AttributionHeader/AttributionHeaderColumnSelector';
import { AllServicesIds, PixelColumnsKeys } from 'types/attribution';
import { SourceTypesWithExtra } from 'types/services';
import { AttributionFiltersDropdown } from 'components/attribution-new/AttributionFiltersDropdown';
import { AttributionStatsRequest } from 'types/attribution';
import AttributionWindowPicker from 'components/attribution-new/AttributionWindowPicker';

export const PixelMenu = ({
  step,
  stepChange,
}: {
  step: WorkflowStepPreloadData & WorkflowStep;
  stepChange: (step: WorkflowStepPreloadData & WorkflowStep) => void;
}) => {
  const [localAttributionOptions, setLocalAttributionOptions] = useState<{
    params: Partial<AttributionStatsRequest>;
    columns: PixelColumnsKeys[];
  }>({
    params: {
      dateModel: step.attributionOptions?.params?.dateModel ?? 'eventDate',
      model: step.attributionOptions?.params?.model ?? 'lastPlatformClick-v2',
      filters: step.attributionOptions?.params?.filters ?? ({} as any),
      attributionWindow: step.attributionOptions?.params?.attributionWindow ?? '1',
    },
    columns: step.attributionOptions?.columns ?? [],
  });

  const updateAttributionOptions = useCallback(
    (options) => {
      setLocalAttributionOptions(options);
      stepChange({
        ...step,
        attributionOptions: {
          ...localAttributionOptions,
          ...options,
          columns: options.columns ?? localAttributionOptions.columns,
        },
      });
    },
    [localAttributionOptions, step, stepChange],
  );

  return (
    <div className="flex flex-col gap-6.5">
      <div className="flex gap-6.5">
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Date Model
          </Text>
          <AttributionDateModelPicker
            sentDateModel={localAttributionOptions.params.dateModel}
            onDateModelChange={(dateModel) => {
              updateAttributionOptions({
                ...localAttributionOptions,
                params: { ...localAttributionOptions.params, dateModel },
              });
            }}
            sentAttributionModel={localAttributionOptions.params.model}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Attribution Model
          </Text>
          <AttributionModelPicker
            maxHeight="50%"
            zIndex={100000}
            type={'all' as SourceTypesWithExtra}
            sourceId={'all' as AllServicesIds}
            sentModel={localAttributionOptions.params.model}
            onModelChange={(model) => {
              updateAttributionOptions({
                ...localAttributionOptions,
                params: { ...localAttributionOptions.params, model },
              });
            }}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Attribution Window
          </Text>
          <AttributionWindowPicker
            withinPortal={true}
            sentModel={localAttributionOptions.params.model}
            sentWindow={localAttributionOptions.params.attributionWindow}
            onWindowChange={(window) => {
              updateAttributionOptions({
                ...localAttributionOptions,
                params: { ...localAttributionOptions.params, attributionWindow: window },
              });
            }}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Text fw={500} size="sm">
            Columns
          </Text>
          <AttributionHeaderColumnSelector
            sentColumns={localAttributionOptions.columns}
            onColumnsChange={(columns) => {
              updateAttributionOptions({ ...localAttributionOptions, columns });
            }}
          />
        </div>
      </div>
    </div>
  );
};
