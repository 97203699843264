import { Button, Modal } from '@tw/ui-components';
import { FreeQuery } from 'pages/FreeQuery/FreeQuery';
import { useEffect, useState } from 'react';

type FlowStepWithFullEditorProps = {
  opened: boolean;
  query: string;
  params: Record<string, string>;
  language: 'sql' | 'python';
  readOnly: boolean;
  onClose: () => void;
  setQuery: (query: string) => void;
  setParams: (params: Record<string, string>) => void;
  onSave: (query: string) => void;
};

export const FlowStepWithFullEditor: React.FC<FlowStepWithFullEditorProps> = ({
  opened,
  onClose,
  query,
  setQuery,
  params,
  setParams,
  language,
  readOnly,
  onSave,
}) => {
  const [text, setText] = useState(query);

  useEffect(() => {
    setText(query);
  }, [query]);

  return (
    <Modal.Root
      size="xl"
      centered
      fullScreen
      opened={opened}
      onClose={onClose}
      closeOnEscape={false}
    >
      <Modal.Overlay />
      <Modal.Content className="!flex flex-col w-full h-full">
        <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
        <Modal.Body className="flex flex-auto">
          <div className="flex w-full">
            <div className="w-full flex flex-col flex-auto">
              <FreeQuery
                initialQuery={text}
                queryChanged={setText}
                language={language}
                queryVars={Object.keys(params)}
                queryVarsValues={Object.entries(params).reduce((acc, [key, value]) => {
                  return {
                    ...acc,
                    value,
                  };
                }, {})}
                queryVarsValuesChanged={setParams}
                readOnly={readOnly}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-2 items-center px-4">
            <Button
              variant="white"
              onClick={() => {
                setText(query);
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setQuery(text);
                setParams(params);
                onSave(text);
                onClose();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
