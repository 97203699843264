import { $chatsToWatch } from '$stores/willy/$chatsToWatch';
import { useComputedValue } from '@tw/snipestate';
import { Text } from '@tw/ui-components';
import { useEffect, useMemo, useState } from 'react';

export const ChatTimer = ({ conversationId }) => {
  const chat = useComputedValue($chatsToWatch, (chats) =>
    chats.find((a) => a.conversationId === conversationId),
  );

  const currentTime = useCurrentTime();

  // Calculate elapsed time: if the chat has ended, use the endTime; otherwise, use the current time.
  const elapsed = useMemo(() => {
    return !!chat
      ? chat.endTime
        ? chat.endTime - chat.startTime
        : currentTime - chat.startTime
      : 0;
  }, [chat, currentTime]);

  return (
    <>
      {!!chat ? (
        <div className="flex items-center gap-2 justify-center font-mono px-10">
          <Text size="sm" fw={400} color={'gray.4'}>
            {formatTime(elapsed)}
          </Text>
        </div>
      ) : null}
    </>
  );
};

const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return currentTime;
};

const formatTime = (ms: number): string => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const pad = (num: number) => String(num).padStart(2, '0');
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};
