import {
  hidePrompt,
  importCostsOnClose,
  importCostsOnPress,
  setImportCostsDone,
  setImportCostsLoading,
  showPrompt,
} from 'ducks/actions';
import { setOnboardingTaskDone } from 'ducks/shop';
import { useAppDispatch } from 'index';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import CSVExport from 'utils/CSVExport';
import CSVImport from 'utils/CSVImport';

import { DataTable, DropZone } from '@shopify/polaris';
import { CURRENCIES } from '@tw/constants';
import { useStoreValue } from '@tw/snipestate';
import { TASK_STATUS } from '@tw/types/module/services/subscription-manager';
import { Button, Flex, Grid, Modal, Text } from '@tw/ui-components';

import { $shopCurrency } from '../$stores/$shop';
import importCogs from '../icons/importBatchCogs.png';
import importPGC from '../icons/importPGC.png';

const costs = ['shippingCosts', 'cogs', 'paymentGateways'] as const;

export type CostTypes = (typeof costs)[number];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: CostTypes;
}

const typesDict: {
  [key in CostTypes | any]: {
    name: string;
    title: string;
    dataType: string;
    img: string;
    url: string;
  };
} = {
  shippingCosts: {
    name: 'Shipping',
    url: `/v2/summary-page/import-shipping-by-order-id`,
    title: 'Import Shipping Costs By Order ID CSV',
    dataType: 'shipping_costs',
    img: './shippingCSVByOrder.png',
  },
  cogs: {
    name: 'COGs',
    url: `/v2/summary-page/batch-update-product-costs`,
    title: 'Import COGS CSV',
    dataType: 'cogs',
    img: importCogs,
  },
  paymentGateways: {
    name: 'Gateways Costs',
    url: `/v2/shopify/batch-import-costs`,
    title: 'Import Payment Gateway Costs CSV',
    dataType: 'payment_gateway_costs',
    img: importPGC,
  },
};

export const ImportCostsModal: FC<Props> = ({ isOpen, onClose, type }) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const importCostsModalIsOpen = useSelector((state: RootState) => state.importCostsModalIsOpen);
  const currency = useStoreValue($shopCurrency);
  const costSettingsCompletion = useSelector((state: RootState) => state.costSettingsCompletion);
  const msp = useSelector((state: RootState) => state.msp);

  const saveCosts = async (costs) => {
    try {
      dispatch(setImportCostsLoading());
      const url = typesDict[type].url;
      const res = await axiosInstance.post(url, {
        attributes: {
          shopId: currentShopId,
          dataType: typesDict[type].dataType,
          msp,
        },
        data: { costs },
      });
      dispatch(setImportCostsDone());
      const { updated, notUpdated } = res?.data?.results;
      if (updated > 0) {
        if (notUpdated > 0)
          toast.info(`File successfully imported! ${updated} updated, ${notUpdated} failed`);
        else toast.success(`File successfully imported! ${updated} updated`);
        const { tasks } = costSettingsCompletion;
        if (
          tasks.find((task) => task.name === typesDict[type].name)?.status !== TASK_STATUS.COMPLETE
        )
          dispatch(setOnboardingTaskDone(currentShopId, type));
      } else {
        toast.error(`Error! File import failed`);
      }
    } catch (e) {
      toast.error('Error! File import failed');
    }
  };
  return (
    <Modal opened={isOpen} onClose={onClose} title={typesDict[type].title}>
      <Flex direction="column">
        <div>
          <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
          <div className="pb-5">
            <Text weight={600}>1. Download Template</Text>
          </div>
          <Button
            onClick={() =>
              type === 'cogs'
                ? CSVExport(
                    [{ product_id: '', variant_id: '', product_cost: '', handling_fees: '' }],
                    'product_costs',
                    false,
                  )
                : type === 'shippingCosts'
                  ? CSVExport([{ order_id: '', shipping_cost: '' }], 'shipping_by_order_id', false)
                  : CSVExport(
                      [{ payment_gateway: '', cost: '', fee: '' }],
                      'payment_gateways',
                      false,
                    )
            }
          >
            Export CSV File
          </Button>
        </div>
        <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
        <div>
          <div className="pb-5">
            <Text weight={600}>2. Edit Costs in the file</Text>
          </div>

          <img
            className={`${type === 'cogs' ? 'w-full' : ''}`}
            src={typesDict[type].img}
            alt="CSV Format Instructions"
          />

          {type === 'paymentGateways' && (
            <div className="pt-5">
              <Text size="xs">
                The payment gateway name must match the exact name in your orders.
              </Text>
            </div>
          )}
        </div>
        <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
        <div>
          <div className="pb-5">
            <Text weight={600}>3. Upload modified file </Text>
            <Text size="xs" pl={5}>
              * Max file size 15000 rows
            </Text>
          </div>
          <Button onClick={() => dispatch(importCostsOnPress())}>Import CSV File</Button>
        </div>
      </Flex>
      <div className="pointer-events-none opacity-0" style={{ height: '0px' }}>
        <DropZone
          openFileDialog={importCostsModalIsOpen}
          onDrop={(files) => {
            var reader = new FileReader();
            reader.onload = async function (e) {
              var importedData = CSVImport(reader.result);
              if (importedData.length > 15000) {
                toast.error('Error! Max file size 15000 rows');
                dispatch(importCostsOnClose());
                onClose();
                return;
              }
              dispatch(
                showPrompt({
                  title: typesDict[type].title,
                  children:
                    importedData?.length > 0 && type === 'shippingCosts' ? (
                      <DataTable
                        columnContentTypes={['text', 'text']}
                        headings={['Order ID', 'Shipping Cost']}
                        rows={importedData?.map(({ order_id, shipping_cost }) => [
                          <div style={{ width: '100px' }}>{order_id}</div>,
                          <div style={{ width: '100px' }}>
                            {isNaN(+shipping_cost)
                              ? `Invalid cost`
                              : `${CURRENCIES[currency]}${shipping_cost}`}
                          </div>,
                        ])}
                      />
                    ) : importedData?.length > 0 && type === 'cogs' ? (
                      <DataTable
                        columnContentTypes={['text', 'text', 'text', 'text']}
                        headings={['Product ID', 'Variant ID', 'Product Cost', 'Handling Fee']}
                        rows={importedData?.map(
                          ({ product_id, variant_id, product_cost, handling_fees }) => [
                            <div style={{ width: '100px' }}>{product_id}</div>,
                            <div style={{ width: '100px' }}>{variant_id}</div>,
                            <div style={{ width: '100px' }}>
                              {isNaN(+product_cost)
                                ? `Invalid cost`
                                : `${CURRENCIES[currency]}${product_cost}`}
                            </div>,
                            <div style={{ width: '100px' }}>
                              {isNaN(+handling_fees)
                                ? `Invalid fee`
                                : `${CURRENCIES[currency]}${handling_fees}`}
                            </div>,
                          ],
                        )}
                      />
                    ) : importedData?.length > 0 && type === 'paymentGateways' ? (
                      <DataTable
                        columnContentTypes={['text', 'text', 'text']}
                        headings={['Payment Gateway', 'Cost', 'Fee']}
                        rows={importedData?.map(({ payment_gateway, cost, fee }) => [
                          <div style={{ width: '100px' }}>{payment_gateway}</div>,
                          <div style={{ width: '100px' }}>
                            {isNaN(+cost) ? `Invalid cost` : `${cost}%`}
                          </div>,
                          <div style={{ width: '100px' }}>
                            {isNaN(+fee) ? `Invalid fee` : `${CURRENCIES[currency]}${fee}`}
                          </div>,
                        ])}
                      />
                    ) : (
                      <div>No data imported</div>
                    ),
                  primaryAction: {
                    content: 'Import',
                    onAction: () => {
                      saveCosts(importedData);
                      dispatch(hidePrompt());
                      dispatch(importCostsOnClose());
                      onClose();
                    },
                    disabled: importedData?.length === 0,
                  },
                }),
              );
              // toast
            };
            reader.readAsText(files[0]);
          }}
          onFileDialogClose={() => dispatch(importCostsOnClose())}
        ></DropZone>
      </div>
    </Modal>
  );
};
