import { ServicesIds } from '@tw/types/module/services';
import { AnalyticsObjectType } from '@tw/types/module/types';
import { Button, confirm, Icon } from '@tw/ui-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataWithName } from '../Willy/types/willyTypes';
import { dateRangePickerString } from './components/DateRangePicker';
import { useWillySocket } from 'components/Willy/WillySocket';
import axiosInstance from 'utils/axiosInstance';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId, $timezone } from '$stores/$shop';
import moment from 'moment-timezone';
import { ToggleStatusEmitterString } from './components/ToggleStatusEmitterString';
import { GenUiActionWithAttribution } from './types';
import { ActionsDrawer } from './ActionsDrawer';
import { AttributionData } from 'types/attribution';
import { getDataForClient } from './getDataForClient';
import { $dialect } from '$stores/$user';
import { TZ } from '@tw/constants';
import { applyActions } from './utils';
import { toast } from 'react-toastify';
import { useCanUpdateAd } from 'hooks/useCanUpdateAd';
import { useSelector } from 'react-redux';
import { providers } from 'ducks/shopIntegrations';

type DynamicReactRendererProps = {
  componentCode: string;
  componentData?: DataWithName[];
  // Optional parameters for saving GenUI output
  sequenceId?: string;
  runId?: string;
  stepId?: string;
  updateToFixedGenUi?: (output: string) => void;
};

// Helper function to extract imports from a specific library
function extractLibraryImports(
  code: string,
  libraryName: string,
  handleAliases: boolean = false,
): string {
  const importRegex = new RegExp(`import\\s+{([^}]+)}\\s+from\\s+['"]${libraryName}['"]`);
  const importMatch = code.match(importRegex);

  if (!importMatch) return '';

  const withFixedAliases = importMatch[1]
    .split(',')
    .map((c) => {
      if (!handleAliases) return c.trim();

      // Handle "Component as AliasName" pattern
      const parts = c.trim().split(' as ');
      if (parts.length > 1) {
        return `${parts[0].trim()}: ${parts[1].trim()}`;
      }
      return c.trim();
    })
    .join(', ');
  return withFixedAliases;
}

export const DynamicReactRenderer: React.FC<DynamicReactRendererProps> = ({
  componentCode,
  componentData,
  sequenceId,
  runId,
  stepId,
  updateToFixedGenUi,
}) => {
  // console.log('Component code:', componentCode);
  // console.log('componentData', componentData);
  const [error, setError] = useState<string | null>(null);
  const [errorId, setErrorId] = useState<string | null>(null);
  const [fixedComponentCode, setFixedComponentCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showFixErrors, setShowFixErrors] = useState(false);
  const [showRuntimeErrors, setShowRuntimeErrors] = useState(false);
  const [runtimeError, setRuntimeError] = useState<string | null>(null);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [actionsInModal, setActionsInModal] = useState<GenUiActionWithAttribution[]>([]);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Track if iframe has been initialized with the current component code
  const [isIframeInitialized, setIsIframeInitialized] = useState(false);
  const lastComponentCode = useRef<string | null>(null);
  const messageHandlerRef = useRef<any>(null);
  const [isValidated, setIsValidated] = useState(true);
  const [loadingFixingErrors, setLoadingFixingErrors] = useState(false);
  const [loadingActions, setLoadingActions] = useState<Record<string, boolean>>({});
  const [errorActions, setErrorActions] = useState<Record<string, string | null>>({});
  const shopId = useStoreValue($currentShopId);
  const providerList = useSelector(providers);
  const { socket } = useWillySocket();
  const timezone = useStoreValue($timezone) || TZ;
  const dialect = useStoreValue($dialect);
  const { canUpdateAdForAllServices } = useCanUpdateAd(null);

  useEffect(() => {
    socket.on('component-render-error-fixed', async (data) => {
      if (data.errorId === errorId) {
        console.log('component-render-error-fixed', 'DynamicReactRenderer', data);
        setError(null);
        // console.log('Fixed component code:', data.fixedComponent);
        setShowFixErrors(false);
        setFixedComponentCode(data.fixedComponent);
        setLoadingFixingErrors(false);
        setIsValidated(true);

        // Save the fixed component code if we have all required parameters
        if (data.fixedComponent && sequenceId && shopId && runId && stepId) {
          try {
            let newOutput = data.fixedComponent;
            if (!newOutput.startsWith('```')) {
              newOutput = `\`\`\`jsx\n${newOutput}\`\`\``;
            }
            updateToFixedGenUi?.(newOutput);
            await axiosInstance.post('/v2/sequences/save-new-genui-output', {
              sequenceId,
              shopId,
              runId,
              stepId,
              newOutput,
            });
            console.log('Successfully saved fixed GenUI component');
          } catch (error) {
            console.error('Failed to save fixed GenUI component:', error);
          }
        }
      }
    });

    return () => {
      // Use a more specific handler removal to avoid removing other handlers
      socket.off('component-render-error-fixed');
    };
  }, [socket, errorId, sequenceId, shopId, runId, stepId, updateToFixedGenUi]);

  const loadData = useCallback(
    async (props: { id: string; entity: AnalyticsObjectType; channel: ServicesIds }) => {
      const { id, entity, channel } = props;
      try {
        setLoadingActions((old) => ({ ...old, [id]: true }));
        setErrorActions((old) => ({ ...old, [id]: null }));
        if (!shopId) {
          setErrorActions((old) => ({ ...old, [id]: 'Shop ID is not set' }));
          return null;
        }

        if (entity !== 'campaign' && entity !== 'adset' && entity !== 'ad') {
          setErrorActions((old) => ({ ...old, [id]: 'Invalid entity' }));
          return null;
        }

        const query = `--sql
        SELECT 
          channel as serviceId, 
          account_id as accountId, 
          ${entity}_id as id, 
          ${entity}_name as name, 
          ${entity}_status as status,
          '${entity}' as entity
        FROM ads_table
        WHERE ${entity}_id = '${id}'
        AND channel = '${channel}'
        and event_date = yesterday()
        limit 1
      `;
        const data = await getDataForClient({
          query,
          shopId,
          currency: 'USD',
          timezone,
          dialect,
        });
        const asAttribution: Partial<AttributionData> = data.rows[0];
        return asAttribution;
      } catch (error: any) {
        setErrorActions((old) => ({ ...old, [id]: error.message || 'Unknown error' }));
        return null;
      } finally {
        setLoadingActions((old) => ({ ...old, [id]: false }));
      }
    },
    [shopId, timezone, dialect],
  );

  // Initialize iframe with component code
  useEffect(() => {
    let currentComponentCode = fixedComponentCode || componentCode;
    currentComponentCode = currentComponentCode.replaceAll('text-white', '');
    currentComponentCode = currentComponentCode.replaceAll(`'$' +`, 'String.fromCharCode(36) +');
    if (!currentComponentCode || !iframeRef.current) return;

    const iframe = iframeRef.current;
    const handleMessage = async (event) => {
      // log the time of the event
      const format = 'YYYY-MM-DD HH:mm:ss.SSS';
      console.log('start', moment().format(format));
      switch (event.data.type) {
        case 'IFRAME_TEST_READY':
          if (!iframe.contentWindow) return;
          console.log('IFRAME_TEST_READY', moment().format(format));
          // When iframe signals it's ready, send the component code
          iframe.contentWindow.postMessage({ type: 'RENDER_TEST_COMPONENT' }, '*');
          break;
        case 'IFRAME_READY':
          if (!iframe.contentWindow) return;
          console.log('IFRAME_READY', moment().format(format));
          iframe.contentWindow.postMessage({ type: 'RENDER_ACTUAL_COMPONENT' }, '*');
          break;
        case 'TEST_SUCCESS':
          setIsValidated(true);
          break;
        case 'RENDER_SUCCESS':
          console.log('RENDER_SUCCESS', moment().format(format));
          // console.log('in text/javascript RENDER_SUCCESS');
          setLoading(false);
          setIsIframeInitialized(true);
          break;
        case 'DATA_UPDATE_SUCCESS':
          // Data update completed successfully
          console.log('Component data updated successfully');
          break;
        case 'OPEN_ACTION_MODAL':
          const { data, action } = event.data;
          const { id, channel, entity, oldStatus, newStatus } = data;
          const servicePermissions = canUpdateAdForAllServices.find((s) => s.serviceId === channel);
          if (!servicePermissions?.hasUpdateScope || !servicePermissions.updateAdsAllowed) {
            toast.error("You don't have permission to update this channel");
            return;
          }
          setActionsDrawerOpen(true);
          if (actionsInModal.find((a) => a.genUiAction.id === id)) {
            return;
          }
          setActionsInModal((old) => {
            return [
              ...old,
              {
                genUiAction: { action, id, channel, entity, oldStatus, newStatus },
                attribution: {},
              },
            ];
          });
          const attribution = await loadData({ id, entity, channel });
          if (!attribution) {
            return;
          }
          setActionsInModal((old) => {
            return old.map((item) => {
              if (item.genUiAction.id === id) {
                return {
                  ...item,
                  genUiAction: { action, id, channel, entity, oldStatus, newStatus },
                  attribution: attribution!,
                };
              }
              return item;
            });
          });
          break;
        case 'RENDER_ERROR':
        case 'DATA_UPDATE_ERROR':
        case 'TEST_ERROR':
          // console.log('RENDER_ERROR', event.data);
          // setError('Fixing errors...');
          // setLoading(false);
          // Generate a unique ID for this error
          const errorId = `error-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          setErrorId(errorId);
          // Since we don't have the required parameters for edit-genui in this component,
          // we need to create a custom event for component rendering error
          console.log('trying to fix with anthropic editor');
          socket.emit('component-render-error', {
            componentCode: currentComponentCode,
            componentData: componentData || [],
            error: event.data.error,
            errorDetails: event.data.details || '',
            errorId,
          });

          break;
        case 'RUNTIME_ERROR':
          console.log('RUNTIME_ERROR', event.data.error);
          setShowRuntimeErrors(true);
          setRuntimeError(event.data.error);
          break;
      }
    };

    // Store the message handler reference for cleanup
    messageHandlerRef.current = handleMessage;
    window.addEventListener('message', handleMessage);
    // If component code hasn't changed and iframe is already initialized,
    // we only need to update the data, not reinitialize the iframe
    if (
      lastComponentCode.current === currentComponentCode &&
      isIframeInitialized &&
      iframeRef.current.contentWindow
    ) {
      // Send updated data to iframe
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'UPDATE_COMPONENT_DATA',
          componentData: componentData || [],
        },
        '*',
      );
      // Clean up event listener
      return () => {
        if (messageHandlerRef.current) {
          window.removeEventListener('message', messageHandlerRef.current);
        }
      };
    }

    // Store the component code for future comparisons
    lastComponentCode.current = currentComponentCode;

    // Reset state for new component initialization
    setError(null);
    setLoading(true);
    setIsIframeInitialized(false);

    // set showFixErrors to true after 2 seconds
    setTimeout(() => {
      setShowFixErrors(true);
    }, 3000);

    try {
      // Extract imports from various libraries
      const rechartsComponents =
        extractLibraryImports(currentComponentCode, 'recharts', true) || 'no_recharts';

      const lucideComponents =
        extractLibraryImports(currentComponentCode, 'lucide-react', true) || 'no_lucide';

      // Remove all import and export statements
      const processedCode = currentComponentCode
        .replace(/import\s+.*?;/gs, '')
        .replace(/export\s+(?:default\s+)?/g, '');

      // Get component name
      const componentName = getDefaultExportName(currentComponentCode);

      const babelCode = `
      const {
        useState,
        useEffect,
        useMemo,
        useCallback,
        useRef
      } = window.React;
      
      // Make Recharts components available
      const { 
          ${rechartsComponents}
      } = window.Recharts;
      
      // Make Lucide React components available
      const LucideReact = window.LucideReact.default || {};
      const {
        ${lucideComponents}
      } = LucideReact;
    
      ${dateRangePickerString}

      ${ToggleStatusEmitterString}
      
      ${processedCode}
        
      // Store the component class/function
      Component = ${componentName};
      
      // Initialize component data
      currentComponentData = ${JSON.stringify(componentData || [])};
      
      // Create root if not already created
      if (!root) {
        root = ReactDOM.createRoot(document.getElementById('component-root'));
      }
      
      // Render the component
      root.render(<Component componentData={currentComponentData} />);
      `;

      const sandboxHtml = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Sandboxed Component</title>
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/react.development.js"></script>
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/react-dom.development.js"></script>
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/prop-types.min.js"></script>

        <script>
            // Ensure React is available globally for Lucide
            window.React = React;
            // Make PropTypes available to React
            window.React.PropTypes = window.PropTypes;
        </script>
        
        <!-- Recharts and its dependencies -->
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/Recharts.js"></script>
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/babel.min.js"></script>
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/lodash.min.js"></script>
        
        <!-- Lucide React -->
        <script src="https://cdn-app.triplewhale.com/gen-ui-assets-d880/lucide-react.umd.js"></script>

        <!-- Tailwind CSS -->
        <link href="https://cdn-app.triplewhale.com/gen-ui-assets-d880/tailwind.min.css" rel="stylesheet">
        
        <script>
          // Ensure styles and charts libraries are accessible
          window.onerror = function(message, source, lineno, colno, error) {
            console.log('onerror', message, source, lineno, colno, error);
            if (error) {
              window.parent.postMessage({
                type: 'RUNTIME_ERROR',
                error: error.toString()
              }, '*');
            }
          }
        </script>
        
        <style>
          body {
            margin: 0;
            padding: 0;
            background: transparent;
          }
        </style>
      </head>
      <body>
        <div id="component-root"></div>
        {{scriptTag}}
      </body>
      </html>
    `;

      const actualBabel = `
        <script type="text/babel">
          // console.log('in text/babel')
          // Store references to root and component for state persistence
          let root = null;
          let Component = null;
          let currentComponentData = [];

          // Handle messages from parent frame
          window.addEventListener('message', (event) => {
            // Initialize component
            if (event.data.type === 'RENDER_ACTUAL_COMPONENT') {
              // console.log('in text/babel event.data.type === RENDER_ACTUAL_COMPONENT')
              try {
                ${babelCode}
                // Signal successful render
                window.parent.postMessage({ type: 'RENDER_SUCCESS' }, '*');
              } catch (error) {
                // console.log('in text/babel catch')
                // console.log('in text/babel catch error', error)
                // Report errors back to parent with more context
                window.parent.postMessage({ 
                  type: 'RENDER_ERROR', 
                  error: error.toString()
                }, '*');
              }
            }
            // Update data without re-initializing the component
            else if (event.data.type === 'UPDATE_COMPONENT_DATA') {
              try {
                // Update the component data
                currentComponentData = event.data.componentData || [];
                
                // Only re-render if we have a component and root
                if (Component && root) {
                  // Re-render with new data
                  root.render(<Component componentData={currentComponentData} />);
                  window.parent.postMessage({ type: 'DATA_UPDATE_SUCCESS' }, '*');
                }
              } catch (error) {
                window.parent.postMessage({ 
                  type: 'DATA_UPDATE_ERROR', 
                  error: error.toString()
                }, '*');
              }
            }
          });
          window.parent.postMessage({ type: 'IFRAME_READY' }, '*');
          
        </script>
    `;
      const babelTest = `
    <script type="text/javascript">
          const babelCode = \`${babelCode.replace(/`/g, '\\`').replace(/\$/g, '\\$')}\`;
  

          // Store references globally
          let root = null;
          let Component = null;
          let currentComponentData = [];

          // Handle messages from parent frame
          // console.log('in text/javascript')
          window.addEventListener('message', (event) => {
            // console.log('in text/javascript event')
            if (event.data.type === 'RENDER_TEST_COMPONENT') {
              try {
                console.log('in text/javascript try')
                // Transpile the Babel code
                const { code } = Babel.transform(babelCode, {
                  presets: ['react', 'es2015'],
                });

                // Execute the transpiled code
                eval(code);

                // Signal successful render
                window.parent.postMessage({ type: 'TEST_SUCCESS' }, '*');
              } catch (error) {
                // console.log('in text/javascript catch')
                // console.log('in text/javascript catch error', error)
                // Catch both transpilation and runtime errors
                window.parent.postMessage({
                  type: 'TEST_ERROR',
                  error: error.toString(),
                  details: error.stack || 'No stack trace available',
                }, '*');
              }
            }
          });
          // Signal that the iframe is ready
          window.parent.postMessage({ type: 'IFRAME_TEST_READY' }, '*');
          // console.log('in text/javascript end')
        </script>
    `;
      // Set up iframe content
      const iframe = iframeRef.current;
      const scriptTag = isValidated ? actualBabel : babelTest;
      iframe.srcdoc = sandboxHtml.replace('{{scriptTag}}', scriptTag);

      // Clean up event listener
      return () => {
        if (messageHandlerRef.current) {
          window.removeEventListener('message', messageHandlerRef.current);
        }
      };
    } catch (err: any) {
      console.error('Compilation error:', err);
      setError(err.toString());
    }
  }, [
    componentCode,
    componentData,
    isIframeInitialized,
    isValidated,
    socket,
    fixedComponentCode,
    actionsInModal,
    loadData,
    canUpdateAdForAllServices,
  ]);

  // Dynamically adjust iframe height
  useEffect(() => {
    if (!iframeRef.current || loading) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const iframe = entry.target as HTMLIFrameElement;
        const height = iframe.contentDocument?.body?.scrollHeight || 100;
        if (iframeRef.current) {
          iframeRef.current.style.height = `${height}px`;
        }
      }
    });

    resizeObserver.observe(iframeRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [loading]);

  const handleFixErrors = useCallback(() => {
    setShowFixErrors(false);
    setIsValidated(false);
    setLoadingFixingErrors(true);
  }, []);

  const handleRuntimeErrors = useCallback(() => {
    if (!runtimeError) return;
    const errorId = `error-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    setErrorId(errorId);
    setShowRuntimeErrors(false);
    setLoadingFixingErrors(true);
    socket.emit('component-render-error', {
      componentCode: componentCode,
      componentData: componentData || [],
      error: runtimeError,
      errorDetails: '',
      errorId,
    });
  }, [runtimeError, componentCode, socket, componentData]);

  const onConfirm = useCallback(async () => {
    if (!shopId) {
      return;
    }

    const confirmed = await confirm({
      title: 'Confirm',
      message: 'Are you sure you want to apply these actions?',
      confirmText: 'Apply',
      cancelText: 'Cancel',
    });
    if (!confirmed) {
      return;
    }
    const batchRes = await applyActions(actionsInModal, shopId, providerList);
    let errors: Record<string, string> | null = null;

    setActionsInModal((old) => {
      const withErrors = old.filter((action) =>
        batchRes.find((res) => res.id === action.attribution.id && !res.success),
      );
      errors = withErrors.reduce(
        (acc, action) => {
          const res = batchRes.find((res) => res.id === action.attribution.id && !res.success);
          if (!res || res.success || !action.attribution.id) {
            return acc;
          }
          acc[action.attribution.id] = res.error;
          return acc;
        },
        {} as Record<string, string>,
      );
      return withErrors;
    });

    if (errors) {
      setErrorActions((old) => {
        return { ...old, ...errors };
      });
    }
  }, [actionsInModal, shopId, providerList]);

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-300 rounded text-red-900 border-solid">
        <h3 className="font-bold mb-2 flex flex-row items-center gap-3">
          <Icon name="info" color="red.9" />
          Rendering Error:
        </h3>

        <div className="whitespace-pre-wrap border-l-4 border-solid border-r-0 border-t-0 border-b-0 border-red-90 text-md font-mono text-red-900 px-4 my-6">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      {loading && !loadingFixingErrors && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75">
          <div className="text-gray-600">Loading...</div>
          {showFixErrors && <Button onClick={handleFixErrors}>Fix Errors</Button>}
        </div>
      )}
      {showRuntimeErrors && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75">
          {showFixErrors && <Button onClick={handleRuntimeErrors}>Fix Errors</Button>}
        </div>
      )}
      {loadingFixingErrors && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75">
          <div className="text-gray-600">Fixing errors...</div>
        </div>
      )}
      <iframe
        ref={iframeRef}
        className="w-full border-0"
        // make it responsive without the height
        style={{ minHeight: '300px', maxWidth: '100%', overflow: 'hidden' }}
        sandbox="allow-scripts allow-same-origin"
        title="Dynamic Component"
      />

      <ActionsDrawer
        opened={actionsDrawerOpen}
        onClose={() => setActionsDrawerOpen(false)}
        actions={actionsInModal}
        onDismiss={(ids: string[]) => {
          setActionsInModal((oldActions) =>
            oldActions.filter((action) => !ids.includes(action.genUiAction.id)),
          );
        }}
        errorActions={errorActions}
        loadingActions={loadingActions}
        onConfirm={onConfirm}
      />
    </div>
  );
};

// Helper function to find the default export name
function getDefaultExportName(code: string): string {
  try {
    const exportDefaultFunctionMatch = code.match(/function\s+(\w+)\s*\(\s*\{?\s*\w*\s*\}?\s*\)/);
    if (exportDefaultFunctionMatch && exportDefaultFunctionMatch[1]) {
      return exportDefaultFunctionMatch[1];
    }

    // Try to find explicit default export
    const defaultExportMatch = code.match(/export\s+default\s+(\w+)/);
    if (defaultExportMatch && defaultExportMatch[1]) {
      return defaultExportMatch[1];
    }

    // If no default export found, look for the last declared component
    const constComponentMatch = code.match(
      /const\s+(\w+)\s*=\s*(?:\(\)\s*=>|function\s*\(|\(.*?\)\s*=>|\(.*?\)\s*{)/g,
    );
    const funcComponentMatch = code.match(/function\s+(\w+)\s*\(/g);

    const componentMatch = [...(constComponentMatch || []), ...(funcComponentMatch || [])];

    if (!componentMatch || componentMatch.length === 0) {
      throw new Error('Could not find component name to export');
    }

    const lastComponent = componentMatch[componentMatch.length - 1];
    const nameMatch = lastComponent.startsWith('const')
      ? lastComponent.match(/const\s+(\w+)/)
      : lastComponent.match(/function\s+(\w+)/);

    if (!nameMatch || !nameMatch[1]) {
      throw new Error('Could not extract component name from declaration');
    }

    return nameMatch[1];
  } catch (e) {
    // Default to DynamicComponent if we can't find a name
    console.error('Error finding component name:', e);
    return 'DynamicComponent';
  }
}
