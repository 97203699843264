import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { $combineShopAndCustomViewDashboards } from '$stores/willy/$customViews';
import { $globalAndShopSequences, $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { Button, Loader, MultiSelect, Select, Text } from '@tw/ui-components';
import { Interval, INTERVAL_OPTIONS } from 'components/Willy/constants';
import {
  DayOfWeek,
  DayOfWeekArr,
  WillyDashboardElement,
  WillyDataSequence,
  AgentEntity,
  StepWsStatus,
  WorkflowWsStatus,
} from 'components/Willy/types/willyTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { $activeAccounts, $currentShopId } from '$stores/$shop';
import { calculateEntityCount } from './entityCountCalculator';
import axiosInstance from 'utils/axiosInstance';
import { useSequenceFlowSocket } from 'components/Willy/sequenceBuilder/useSequenceFlowSocket';
import { useAppSelector } from 'reducers/RootType';
import { v4 as uuidv4 } from 'uuid';
type AgentUsageCalculatorProps = {
  sequenceId: string;
  entity?: AgentEntity;
  agent?: WillyDataSequence | null;
};

export const AgentUsageCalculator: React.FC<AgentUsageCalculatorProps> = (props) => {
  const { sequenceId, entity, agent } = props;

  const globalSequences = useStoreValue($globalAndShopSequences);
  const shopSequences = useStoreValue($shopSequences);
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);
  const shopId = useStoreValue($currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const [wsSteps, setWsSteps] = useState<Record<string, StepWsStatus>>({});
  const [wsWorkflows, setWsWorkflows] = useState<Record<string, WorkflowWsStatus>>({});
  const [mainWorkflowStopped, setMainWorkflowStopped] = useState(false);
  const [runId, setRunId] = useState<string>('');
  const currentShopId = useAppSelector((state) => state.currentShopId);
  useSequenceFlowSocket({
    sequenceId: sequenceId,
    runId,
    setWsWorkflows,
    setWsSteps,
    setMainWorkflowStopped,
    shopId: currentShopId,
  });
  const [loadingCount, setLoadingCount] = useState(false);
  const [loadingestimateCredits, setLoadingestimateCredits] = useState(false);
  const [iterations, setIterations] = useState(1);
  const [schedule, setSchedule] = useState<{
    interval: Interval | null;
    days?: DayOfWeek[];
    hours?: number[];
  }>({
    interval: '1d',
    days: [],
    hours: [],
  });

  const lastRun = useMemo(() => {
    const outputResponse =
      wsWorkflows && wsWorkflows[sequenceId]?.status === 'done' && wsWorkflows[sequenceId];

    if (outputResponse) {
      return outputResponse;
    }

    return null;
  }, [sequenceId, wsWorkflows]);
  const globalAndShopSequences = useMemo(() => {
    return [...globalSequences, ...shopSequences];
  }, [globalSequences, shopSequences]);

  const sequence = useMemo(() => {
    return globalAndShopSequences.find((s) => s.id === sequenceId);
  }, [globalAndShopSequences, sequenceId]);

  const providers = agent?.providers;

  const fetchEntityCount = useCallback(async () => {
    setLoadingCount(true);
    try {
      return await calculateEntityCount(sequence, shopId, activeAccounts, entity, providers);
    } finally {
      setLoadingCount(false);
    }
  }, [sequence, shopId, activeAccounts, entity, providers]);

  const totalUsage = useMemo(() => {
    const allDashboards = [...dashboards, ...shopAndViewDashboards];

    if (!sequence) {
      return 0;
    }

    if (loadingCount) {
      return 0;
    }

    return calculateAgentUsage(
      sequence,
      allDashboards,
      globalAndShopSequences,
      iterations,
      schedule,
    );
  }, [
    dashboards,
    shopAndViewDashboards,
    sequence,
    globalAndShopSequences,
    iterations,
    schedule,
    loadingCount,
  ]);

  const creditsPerMonth = useMemo(() => {
    if (!lastRun) {
      return 0;
    }
    const creditsPerRun = lastRun.totalCredits;
    if (!creditsPerRun) {
      return 0;
    }
    setLoadingestimateCredits(false);
    return calculateCreditsPerRun(creditsPerRun, schedule);
  }, [schedule, lastRun]);

  useEffect(() => {
    (async () => {
      const count = await fetchEntityCount();
      setIterations(count);
    })();
  }, [fetchEntityCount]);

  const estimateCredits = useCallback(async () => {
    setLoadingestimateCredits(true);
    const runId = uuidv4();
    setRunId(runId);
    try {
      const estimatedCostsResponse = await axiosInstance.post(
        '/v2/sequences/workflows/estimate-costs',
        {
          sequenceId,
          entity,
          providers,
          shopId,
          runId,
        },
      );
    } catch (error) {
      setLoadingestimateCredits(false);
      console.error('error estimating costs', error);
    }
  }, [sequenceId, entity, providers, shopId]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <Text fz={14} fw={600}>
          If you run this agent
        </Text>
        <Select
          label="Every"
          data={[
            {
              label: '-',
              value: '',
            },
            ...Object.values(INTERVAL_OPTIONS).map((option) => ({
              label: option.label,
              value: option.value,
            })),
          ]}
          onChange={(value) => {
            setSchedule({
              interval: value as Interval,
              days: [],
              hours: [],
            });
          }}
          value={schedule.interval}
        />
        <div className="flex items-start gap-2">
          <div className="flex flex-col basis-1/2 flex-shrink-0">
            <Text fz={14} fw={600}>
              or every day
            </Text>
            <MultiSelect
              data={DayOfWeekArr.map((day) => ({
                label: capitalize(day),
                value: day,
              }))}
              value={schedule.days}
              onChange={(value) => {
                setSchedule({
                  ...schedule,
                  days: value as DayOfWeek[],
                  interval: null,
                });
              }}
            />
          </div>

          <div className="flex flex-col basis-1/2 flex-shrink-0">
            <Text fz={14} fw={600}>
              At
            </Text>
            <MultiSelect
              disabled={!!schedule.interval}
              data={Array.from({ length: 24 }, (_, i) => {
                return {
                  label: i.toString().padStart(2, '0') + ':00',
                  value: i.toString(),
                };
              })}
              value={schedule.hours?.map((h) => h.toString())}
              onChange={(value) => {
                setSchedule({ ...schedule, hours: value.map((v) => parseInt(v)) });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-1 font-bold text-3xl">
        <Button onClick={estimateCredits} loading={loadingestimateCredits}>
          Estimate credits
        </Button>

        {!loadingestimateCredits && lastRun?.totalCredits && (
          <>
            <div className="flex items-center gap-1">
              <span>~</span>
              <span>
                {lastRun?.totalCredits?.toLocaleString(undefined, {
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
              <span> / run</span>
            </div>

            <div className="ml-8 flex items-center gap-1">
              <span>
                {creditsPerMonth.toLocaleString(undefined, {
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
              <span> / month</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// fieldByEntity function moved to entityCountCalculator.ts

export function getCostPerRun(queriesPerMonth: number) {
  const basePrice = 0.1; // cost per query when run only once per month
  const minPrice = 0.01; // cost per query at maximum frequency (once every 15 minutes)
  const minQueries = 1;
  const maxQueries = 2880; // 4 queries per hour * 24 hours * 30 days

  if (queriesPerMonth < minQueries) queriesPerMonth = minQueries;
  if (queriesPerMonth > maxQueries) queriesPerMonth = maxQueries;

  const normalized =
    (Math.log(queriesPerMonth) - Math.log(minQueries)) /
    (Math.log(maxQueries) - Math.log(minQueries));

  const cost = basePrice - normalized * (basePrice - minPrice);
  return cost;
}

type Schedule = {
  interval: Interval | null;
  days?: DayOfWeek[];
  hours?: number[];
};

function getRunsPerMonth(schedule: Schedule) {
  if (!schedule) {
    return 0;
  }

  const interval = schedule.interval;
  const days = schedule.days?.length;
  const hours = schedule.hours?.length;

  if (interval) {
    const intervalData = INTERVAL_OPTIONS[interval];
    return (intervalData.runsPerDay || 1) * 30;
  } else if (days && hours) {
    const runsPerDay = days * hours;
    const runsPerMonth = runsPerDay * 4.3;
    return runsPerMonth;
  }

  return 0;
}
export function calculateAgentUsage(
  sequence: WillyDataSequence,
  dashboards: WillyDashboardElement[],
  sequences: WillyDataSequence[],
  iterations: number,
  schedule: Schedule,
): number {
  const { steps } = sequence;

  const totalTokens = steps.reduce((acc, step) => {
    if (
      step.stepType === 'runQuery' ||
      step.stepType === 'runForecasting' ||
      step.stepType === 'runMarketingMixModel'
    ) {
      return acc + 1;
    } else if (step.stepType === 'preloadData') {
      const { dashboardId, previousDate, isStandardDashboard } = step;
      if (isStandardDashboard) {
        return acc;
      }
      const loadForEachQuery = !previousDate || previousDate === 'none' ? 1 : 2;
      const dashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);
      if (!dashboard) {
        return acc;
      }
      return dashboard.widgetIds.length * loadForEachQuery + acc;
    } else if (step.stepType === 'condition') {
      const { rules } = step;
      const fromRules = rules.reduce((acc, rule) => {
        return acc + (rule.ruleIds.length || 0);
      }, 0);
      return fromRules + acc;
    } else if (step.stepType === 'subSequence') {
      const { sequenceIds } = step;
      if (!sequenceIds) {
        return acc;
      }
      const fromSubSequences = sequenceIds.reduce((acc, sequenceId) => {
        const subSequence = sequences.find((sequence) => sequence.id === sequenceId);
        if (!subSequence) {
          return acc;
        }
        const fromSubSequence = calculateAgentUsage(
          subSequence,
          dashboards,
          sequences,
          iterations,
          schedule,
        );
        return fromSubSequence + acc;
      }, 0);
      return fromSubSequences + acc;
    }
    return acc;
  }, 0);

  const perMonth = getRunsPerMonth(schedule);
  const costPerQuery = getCostPerRun(perMonth);

  return totalTokens * iterations * perMonth * costPerQuery;
}

export function calculateCreditsPerRun(credits: number, schedule: Schedule) {
  const runsPerMonth = getRunsPerMonth(schedule);
  return credits * runsPerMonth;
}
