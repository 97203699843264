import { FC, useCallback, useContext } from 'react';
import moment from '@tw/moment-cached/module/timezone';
import { Text } from '@tw/ui-components';
import { TooltipProps } from 'recharts';
import { AttributionData } from 'types/attribution';
import { AttributionPageContext, ChartData } from 'constants/attribution';
import allServices from 'constants/services';
import TWImage from 'components/library/TWImage/TWImage';
import { formatNumber } from 'utils/formatNumber';
import { metrics } from '../../../constants/metrics/metrics';
import CreativeThumbnail from 'components/CreativesCockpit/CreativeThumbnail/CreativeThumbnail';

interface CustomLineTooltipProps extends TooltipProps<any, any> {
  data: AttributionData[] | any[];
  primaryMetric: string;
  secondaryMetric: string;
  chartData?: ChartData;
  showImages?: boolean;
}

const CustomLineTooltip: FC<CustomLineTooltipProps> = (props) => {
  const {
    active,
    payload,
    label,
    data,
    primaryMetric,
    secondaryMetric,
    chartData = 'metrics',
    showImages = false,
  } = props;

  let currency = '';
  let sourceId = 'all';

  try {
    const context = useContext(AttributionPageContext);
    currency = context?.currency || '';
    sourceId = context?.sourceId || 'all';
  } catch (e) {}

  const labelFormatter = useCallback((value) => {
    if (value && moment(value).isValid()) {
      return (
        <Text span fw={600} fz={12}>
          {moment(value).format('YYYY-MM-DD, LT')}
        </Text>
      );
    }
    return value;
  }, []);

  if (!active) {
    return null;
  }

  const allItemsInChart: any[] = Object.values(payload?.[0]?.payload || {});
  if (!allItemsInChart?.length) {
    return null;
  }

  return (
    <div className="pointer-events-none">
      <div
        className="pointer-events-auto rounded-lg"
        style={{
          border: '1px solid var(--mantine-color-gray-2)',
          background: 'var(--mantine-color-named-2)',
          boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
          padding: '20px 0 20px 20px',
          borderRadius: '8px',
        }}
      >
        <div className="text-xs border-b border-[var(--mantine-color-gray-2)] text-gray-600 sticky top-0 bg-[var(--mantine-color-named-2)] mb-[20px]">
          {labelFormatter(label)}
        </div>

        <div
          className={`max-h-[340px] ${allItemsInChart.length > 4 ? 'overflow-y-auto pr-4' : 'pr-4'}`}
        >
          <div className="list-none p-0 m-0 flex flex-col gap-2">
            {allItemsInChart.map((c, i) => {
              const item = data?.find((el) => el?.id === c?.id);
              if (!item || !c.id) return null;

              const isCreativeData =
                item.hasOwnProperty('assetType') || item.hasOwnProperty('segmentTitle');

              return (
                <div key={c.id}>
                  <div
                    className="flex items-center gap-2"
                    style={{ fontSize: '12px', fontWeight: 500 }}
                  >
                    {showImages && isCreativeData ? (
                      <>
                        {item.assetType ? (
                          <CreativeThumbnail
                            wrapperStyle={{ width: '30px', height: '30px' }}
                            creative={item}
                            onClick={() => {}}
                            creativeBodyIconColor="#333"
                          />
                        ) : (
                          <div
                            style={{ backgroundColor: item.color }}
                            className="w-14 h-14 rounded flex items-center justify-center text-sm"
                          >
                            <Text span fz={12} fw={500} maw={167} tt="capitalize" truncate>
                              {item.segmentTitle}
                            </Text>
                          </div>
                        )}
                      </>
                    ) : showImages && !isCreativeData ? (
                      <>
                        {item.imageUrl ? (
                          <TWImage
                            src={item.imageUrl}
                            className="w-full h-full object-cover rounded-md cursor-pointer ml-4"
                            wrapperClass="w-12 h-12 flex items-center justify-center"
                            onClick={() => {}}
                          />
                        ) : sourceId === 'all' ? (
                          <div style={{ marginLeft: '-2.5px' }}>
                            {allServices[item.id!]?.icon?.({ small: false })}
                          </div>
                        ) : (
                          <div style={{ marginLeft: '-2.5px' }}>
                            {allServices[sourceId]?.icon?.({ small: false })}
                          </div>
                        )}
                      </>
                    ) : null}

                    <Text
                      span
                      fz={12}
                      fw={500}
                      maw={100}
                      truncate
                      tt="capitalize"
                      ml={showImages ? undefined : 0}
                    >
                      {isCreativeData
                        ? item.name || item.segmentTitle
                        : item.name || allServices[item.id!]?.title}
                    </Text>
                  </div>

                  <div
                    className={`flex flex-col ${!item.active && 'opacity-50'}`}
                    style={{ marginTop: '12px' }}
                  >
                    <div className="flex gap-3 items-center">
                      <div
                        className="grid grid-cols-1 grid-rows-1 border border-solid rounded-full h-4 w-4 box-content"
                        style={{
                          borderColor: item.color || c.color,
                          backgroundColor: item.color || c.color,
                        }}
                      />
                      <Text span fz={12} fw={500} maw={100} truncate>
                        {isCreativeData
                          ? metrics[primaryMetric]?.label || primaryMetric
                          : chartData === 'metrics'
                            ? metrics[primaryMetric]?.label || primaryMetric
                            : moment(c.currDate).format('ll')}
                        :
                      </Text>
                      <Text span fz={12} fw={500}>
                        {formatNumber(c[primaryMetric], {
                          currency,
                          style: metrics[primaryMetric]?.format || 'decimal',
                          minimumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                          maximumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                        })}
                      </Text>
                    </div>

                    <div className="flex gap-3 items-center" style={{ marginTop: '12px' }}>
                      <div
                        className="grid grid-cols-2 grid-rows-2 border border-solid rounded-full overflow-hidden"
                        style={{ borderColor: item.color || c.color }}
                      >
                        <div
                          className="w-2 h-2"
                          style={{ backgroundColor: item.color || c.color }}
                        ></div>
                        <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                        <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                        <div
                          className="w-2 h-2"
                          style={{ backgroundColor: item.color || c.color }}
                        ></div>
                      </div>
                      <Text span fz={12} fw={500} maw={100} truncate>
                        {isCreativeData
                          ? metrics[secondaryMetric]?.label || secondaryMetric
                          : chartData === 'metrics'
                            ? metrics[secondaryMetric]?.label || secondaryMetric
                            : moment(c.prevDate).format('ll')}
                        :
                      </Text>
                      <Text span fz={12} fw={500}>
                        {isCreativeData
                          ? formatNumber(c[secondaryMetric], {
                              currency,
                              style: metrics[secondaryMetric]?.format || 'decimal',
                              minimumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                              maximumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                            })
                          : chartData === 'metrics'
                            ? formatNumber(c[secondaryMetric], {
                                currency,
                                style: metrics[secondaryMetric]?.format || 'decimal',
                                minimumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                                maximumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                              })
                            : formatNumber(c.prevPeriod[primaryMetric], {
                                currency,
                                style: metrics[primaryMetric]?.format || 'decimal',
                                minimumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                                maximumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                              })}
                      </Text>
                    </div>
                  </div>

                  {i < allItemsInChart.length - 1 && (
                    <div
                      style={{
                        borderBottom: '1px solid var(--mantine-color-gray-1)',
                        marginTop: '8px',
                        marginBottom: '8px',
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLineTooltip;
