import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Column, ColumnName } from '../types/willyTypes';
import { toast } from 'react-toastify';
import copyToClipboard from 'utils/copyToClipboard';
import { TemplatePage } from './TemplatePage';
import { ColumnLibraryCard } from './ColumnLibraryCard';
import { useAiColumn } from './useAiColumn';
import axiosInstance from 'utils/axiosInstance';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { $selectedAiColumn } from '$stores/willy/$sequences';

export const AiTemplate = () => {
  const { templateId } = useParams();
  const currentShopId = useStoreValue($currentShopId);
  const navigate = useNavigate();
  const [agent, setAgent] = useState<Column<ColumnName> | null>(null);
  const { existInShop, onCopy, copyLoading, needsUpdate, onUpdate } = useAiColumn({
    column: agent,
  });
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [relatedAiColumns, setRelatedAiColumns] = useState<Column<ColumnName>[]>([]);
  const redirectNoTemplate = useCallback(() => {
    navigate('/chat/agents/collections');
  }, [navigate]);
  const fetchAgent = useCallback(async () => {
    if (!templateId) {
      return redirectNoTemplate();
    }
    setLoadingAgent(true);
    try {
      const { data } = await axiosInstance.post(`/v2/sequences/workflows/get-agent-template`, {
        shopId: currentShopId,
        agentId: templateId,
        type: 'column',
      });
      setAgent(data.agent as Column<ColumnName>);
      setRelatedAiColumns(data.relatedAgents);
      setLoadingAgent(false);
    } catch {
      return redirectNoTemplate();
    }
  }, [templateId, redirectNoTemplate, currentShopId]);

  useEffect(() => {
    fetchAgent();
  }, [fetchAgent]);

  const copyLink = () => {
    const origin = window.location.origin;
    const link = `${origin}/chat/agents/ai-columns/${templateId}`;
    copyToClipboard(link, true);
    toast.success('Link copied to clipboard');
  };

  const copyToShop = useCallback(async () => {
    if (!agent) {
      return;
    }

    toast.success(`Agent added to your shop`);
  }, [agent]);

  const relatedTemplateCards = useMemo(() => {
    return relatedAiColumns.map((column) => {
      return <ColumnLibraryCard column={column} />;
    });
  }, [relatedAiColumns]);
  return (
    <TemplatePage
      id={templateId ?? ''}
      name={agent?.label ?? ''}
      description={agent?.description ?? ''}
      themeColor={agent?.themeColor ?? 'blue'}
      iconSrc={
        !!agent?.iconSrc
          ? `https://storage.googleapis.com/file-hosting-bucket-shofifi/global-agent-image/${agent.key}.png`
          : undefined
      }
      tagline={agent?.tagline ?? ''}
      aboutPoints={agent?.aboutPoints ?? []}
      howInfo={agent?.howInfo ?? []}
      whyInfo={agent?.whyInfo ?? []}
      category={agent?.category}
      loadingTemplate={loadingAgent}
      relatedTemplatesCards={relatedTemplateCards}
      onCopyLink={copyLink}
      onCopyToShop={copyToShop}
      exists={!!existInShop}
      needsUpdate={needsUpdate}
      onUpdate={onUpdate}
      onPreviewInBuilder={() => {
        if (agent?.key) {
          $selectedAiColumn.set(agent.key);
        }
      }}
    />
  );
};
