import {
  Badge,
  Button,
  Checkbox,
  List,
  Menu,
  Modal,
  Popover,
  TextInput,
  MultiSelect,
  Flex,
} from '@tw/ui-components';

import { ActionIcon } from '@tw/ui-components';

import { useState } from 'react';
import {
  shelfCategoryType,
  StepWsStatus,
  WorkflowStep,
  WorkflowStepBase,
  WorkflowStepGenUiPresentation,
  WorkflowStepGenUiReport,
  WorkflowStepInsights,
} from '../types/willyTypes';
import { stepActionType, SearchSource } from '../types/willyTypes';
import { Icon, Loader, Text, Tooltip } from '@tw/ui-components';
import { detectDynamicParametersInStep, getDisabledSteps } from '../utils/sequences';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import { DatePickerSimple } from 'pages/FreeQuery/DatePickerSimple';
import { uniqBy } from 'lodash';
import { InsightsOutputSettingsModal } from './InsightsOutputSettingsModal';
import { SelectStepType } from './SelectStepType';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

type SequenceFlowStepHeaderProps = {
  step: WorkflowStep;
  isRunning: boolean;
  isDone: boolean;
  categoryConfig?: shelfCategoryType;
  toolConfig?: stepActionType;
  error?: string;
  stepRunInfo: StepWsStatus | null;
  loadingAnswers: boolean;
  stepNumber: number;
  readOnly: boolean;
  stepChange: (prompt: WorkflowStep) => void;
  deleteStep: (id: string) => void;
  viewOnly?: boolean;
  stepsUntilNow?: WorkflowStep[];
  depth?: number;
  sequenceId?: string;
};

export const SequenceFlowStepHeader: React.FC<SequenceFlowStepHeaderProps> = ({
  step,
  isRunning,
  isDone,
  categoryConfig,
  toolConfig,
  stepRunInfo,
  loadingAnswers,
  stepNumber,
  readOnly,
  stepChange,
  deleteStep,
  viewOnly,
  stepsUntilNow,
  depth,
  sequenceId,
}) => {
  const [editTitle, setEditTitle] = useState(false);
  const [variablesModalOpened, setVariablesModalOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [outputSettingsModalOpened, setOutputSettingsModalOpened] = useState(false);

  //find disabled steps
  const currentSlackChannel = useSelector((state: RootState) => state.currentSlackChannel);
  const isGoogleSheetsConnected = useSelector((state: RootState) => state.isGoogleSheetsConnected);
  const user = useSelector((state: RootState) => state.user);
  const isFirstStep = depth === 0 && stepsUntilNow?.length === 0;

  const disabledSteps = getDisabledSteps({
    stepsUntilNow,
    sequenceId,
    currentSlackChannel,
    isGoogleSheetsConnected,
    user,
    isFirstStep,
  });
  //----

  const hasVariablesInStep = detectDynamicParametersInStep(step);

  const isError = stepRunInfo?.status === 'error';
  const isSkipped = stepRunInfo?.status === 'skipped';
  const isAwaiting =
    stepRunInfo?.status !== 'done' &&
    stepRunInfo?.status !== 'error' &&
    stepRunInfo?.status !== 'running' &&
    stepRunInfo?.status !== 'skipped' &&
    loadingAnswers;
  const error = stepRunInfo?.error;

  const isParallelDisabled =
    readOnly ||
    step.stepType === 'subSequence' ||
    step.stepType === 'rule' ||
    step.stepType === 'condition' ||
    step.stepType === 'loop' ||
    step.stepType === 'structuredInsights';

  return (
    <div
      className={`group flex flex-col p-6 rounded rounded-b-none items-center gap-7 justify-between cursor-pointer `}
      style={{
        backgroundColor: `var(--mantine-color-${categoryConfig?.color}-${['one', 'two'].includes(categoryConfig?.color || '') ? '0' : '1'})`,
      }}
    >
      <Flex align="center" gap="xs" justify="space-between" w="100%">
        <div className="flex gap-3 items-center overflow-hidden">
          {isRunning && <Loader size="xs" />}
          {!isRunning && isDone && !error && <Icon name="check-thin" color="green.4" size={16} />}
          {!!error && isError && (
            <Tooltip label={<div className="max-w-[400px] whitespace-pre-wrap">{error}</div>}>
              <div className="flex">
                <Icon name="info" color="red.4" size={16} />
              </div>
            </Tooltip>
          )}
          {isSkipped && <Icon name="circle-x" color="gray.4" size={16} />}
          {isAwaiting && <Icon name="clock" color="gray.4" size={16} />}
          <Flex
            bg={`${categoryConfig ? categoryConfig?.color : 'one'}.${['one', 'two'].includes(categoryConfig?.color || '') ? '1' : '2'}`}
            w={42}
            h={42}
            borderRadius="8px"
            align="center"
            justify="center"
          >
            <Icon
              name={categoryConfig?.icon || 'triple-whale-rounded-logo'}
              color={`${categoryConfig ? categoryConfig?.color : 'one'}.5`}
              size={24}
            />
          </Flex>
          <Tooltip label={step.title?.trim()}>
            <Text fw={500}>
              <div className="flex items-center gap-2">
                <span>{stepNumber + 1}.</span>
                <span
                  className={`input-text line-clamp-1 ${editTitle ? 'outline-none cursor-text' : 'group-hover:underline'}`}
                  ref={(el) => {
                    if (!el) return;
                    if (editTitle) {
                      const range = document.createRange();
                      range.selectNodeContents(el);
                      const selection = window.getSelection();
                      selection?.removeAllRanges();
                      selection?.addRange(range);

                      el.focus();
                    } else {
                      el.blur();
                    }
                  }}
                  suppressContentEditableWarning
                  contentEditable={editTitle}
                  onBlur={(e) => {
                    setEditTitle(false);
                    stepChange({
                      ...step,
                      title: e.currentTarget.innerText,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.currentTarget.blur();
                    }
                  }}
                  onClick={() => {
                    if (!viewOnly && !readOnly) setEditTitle(true);
                  }}
                >
                  {step.title?.trim() || categoryConfig?.name}
                </span>
              </div>
            </Text>
          </Tooltip>
        </div>
        {!viewOnly && (
          <div className="flex gap-3 items-center opacity-0 transition-opacity group-hover:opacity-100">
            <Menu closeOnItemClick={false} opened={menuOpened} onClose={() => setMenuOpened(false)}>
              <Menu.Target>
                <ActionIcon icon="three-dots" iconSize={16} onClick={() => setMenuOpened(true)} />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item>
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={step.runInParallel}
                      disabled={isParallelDisabled}
                      onChange={(checked) => {
                        stepChange({
                          ...step,
                          runInParallel: checked,
                        });
                      }}
                      label="Run in parallel"
                    />
                    {isParallelDisabled && (
                      <Tooltip label={`${toolConfig?.name} can't run in parallel`}>
                        <Icon name="info" color="gray.4" size={16} />
                      </Tooltip>
                    )}
                  </div>
                </Menu.Item>
                {((!!step.variables && step.variables.length > 0) || hasVariablesInStep) && (
                  <Menu.Item
                    onClick={() => {
                      setVariablesModalOpened(true);
                      setMenuOpened(false);
                    }}
                  >
                    Variables
                  </Menu.Item>
                )}
                {isAnyInsightsStep(step) && (
                  <Menu.Item
                    onClick={() => {
                      setOutputSettingsModalOpened(true);
                      setMenuOpened(false);
                    }}
                  >
                    Output Settings
                  </Menu.Item>
                )}
                {step.stepType === 'search' && (
                  <Menu.Item>
                    <MultiSelect
                      data={[
                        { label: 'Knowledge Base', value: 'knowledgeBase' },
                        { label: 'Blog', value: 'blog' },
                        { label: 'Readme', value: 'readme' },
                        { label: 'Triple Whale Website', value: 'webSiteTW' },
                        { label: 'Web Search', value: 'webSearch' },
                      ]}
                      label="Search sources"
                      disabled={readOnly}
                      value={
                        typeof step.searchSource === 'string'
                          ? [step.searchSource]
                          : step.searchSource
                            ? [...step.searchSource]
                            : []
                      }
                      onChange={(values) => {
                        // keep menu opened after selection
                        setMenuOpened(true);
                        stepChange({
                          ...step,
                          searchSource: values as SearchSource,
                        });
                      }}
                    />
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
            <div className="w-[1px] h-[20px] bg-[var(--mantine-color-gray-3)]" />
            {!readOnly && (
              <ActionIcon icon="delete" iconSize={20} onClick={() => deleteStep(step.id)} />
            )}
          </div>
        )}

        {!!outputSettingsModalOpened && isAnyInsightsStep(step) && (
          <InsightsOutputSettingsModal
            opened={outputSettingsModalOpened}
            onClose={() => setOutputSettingsModalOpened(false)}
            step={step}
            stepChange={
              stepChange as (
                step:
                  | WorkflowStepInsights
                  | WorkflowStepGenUiReport
                  | WorkflowStepGenUiPresentation,
              ) => void
            }
            readOnly={readOnly}
          />
        )}
      </Flex>
      {step.stepType !== 'emptyStep' && (
        <SelectStepType
          step={step}
          stepChange={stepChange}
          toolConfig={toolConfig}
          categoryConfig={categoryConfig}
          disabledSteps={disabledSteps}
          disabled={readOnly}
        />
      )}
      <Modal.Root
        size="md"
        centered
        opened={variablesModalOpened}
        onClose={() => setVariablesModalOpened(false)}
        style={{
          overflow: 'visible !important',
        }}
      >
        <Modal.Overlay />
        <Modal.Content w={'400px'} className="!flex flex-col h-full !overflow-y-visible">
          <Modal.Header>
            <Modal.Title>Variables</Modal.Title>
            <Button variant="white" onClick={() => setVariablesModalOpened(false)}>
              Close
            </Button>
          </Modal.Header>
          <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
          <Modal.Body className="flex flex-auto overflow-visible !p-6.5">
            <VariablesList step={step} stepChange={stepChange} />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

type VariablesListProps = {
  step: WorkflowStep;
  stepChange: (step: WorkflowStep) => void;
};

const VariablesList: React.FC<VariablesListProps> = ({ step, stepChange }) => {
  const variables = step.variables;
  let dynamicParams = detectDynamicParametersInStep(step);
  dynamicParams = uniqBy(dynamicParams, 'key');

  const [datePickerOpened, setDatePickerOpened] = useState<
    Record<'start_date' | 'end_date' | 'event_date', boolean>
  >({
    start_date: false,
    end_date: false,
    event_date: false,
  });

  if (!dynamicParams) {
    return null;
  }

  return (
    <List spacing="xs" size="sm" center>
      {dynamicParams.map((param) => {
        const isEventDate = param.key === 'event_date';
        const isDate =
          param.key === 'start_date' ||
          param.key === 'end_date' ||
          param.key === 'prev_start_date' ||
          param.key === 'prev_end_date' ||
          isEventDate;
        const variable = variables?.find((v) => v.key === param.key);

        const isVariableDefined = !!variable && variable.value.trim() !== '';
        return (
          <List.Item
            key={param.key}
            display="flex"
            dir="column"
            icon={
              <Badge
                circle
                variant="filled"
                size="xs"
                color={isVariableDefined ? 'green.4' : 'red.4'}
              />
            }
          >
            <div className="flex items-center gap-2">
              <Text color={isVariableDefined ? 'green.4' : 'red.4'}>{param.key}</Text>

              {isDate && (
                <Popover
                  withinPortal={false}
                  opened={datePickerOpened[param.key]}
                  onClose={() => setDatePickerOpened({ ...datePickerOpened, [param.key]: false })}
                >
                  <Popover.Target>
                    <div>
                      <Button
                        variant="activator"
                        onClick={() =>
                          setDatePickerOpened({
                            ...datePickerOpened,
                            [param.key]: !datePickerOpened[param.key],
                          })
                        }
                      >
                        {variable?.value || 'Select date'}
                      </Button>
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown maw="300px" p="xs">
                    <div className="flex flex-col gap-4 max-h-100 overflow-y-auto">
                      {isEventDate && (
                        <DateOptionsList
                          selectedOption={variable?.value as string}
                          onOptionSelect={(option) => {
                            stepChange({
                              ...step,
                              variables: [{ key: param.key, value: option }],
                            });
                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            });
                          }}
                        />
                      )}

                      {!isEventDate && (
                        <DatePickerSimple
                          selected={
                            Array.isArray(variable?.value)
                              ? variable?.value
                              : variable?.value.split(',')
                          }
                          onChange={(value) => {
                            stepChange({
                              ...step,
                              variables: [{ key: param.key, value: value.start }],
                            });

                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            });
                          }}
                          onClose={() =>
                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            })
                          }
                        />
                      )}
                    </div>
                  </Popover.Dropdown>
                </Popover>
              )}
              {!isDate && (
                <TextInput
                  value={variable?.value || ''}
                  onChange={(value) => {
                    stepChange({
                      ...step,
                      variables: [{ key: param.key, value }],
                    });
                  }}
                  placeholder="Enter value"
                />
              )}
              {isDate && (
                <Tooltip label="This variable will be overridden by the date picker in the UI if it is exists">
                  <Icon name="info" color="gray.4" size={16} />
                </Tooltip>
              )}
            </div>
          </List.Item>
        );
      })}
    </List>
  );
};

function isAnyInsightsStep(
  step: WorkflowStep,
): step is (WorkflowStepInsights | WorkflowStepGenUiReport | WorkflowStepGenUiPresentation) &
  WorkflowStepBase {
  return (
    step.stepType === 'insights' ||
    step.stepType === 'genUiReport' ||
    step.stepType === 'genUiPresentation'
  );
}
