import { Modal } from '@shopify/polaris';
import { Button } from 'components/BaseComponents/Button';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  IN_APP_CONTEXT_BANNERS_DATA,
  setInAppContextBanner,
  getInAppContextBanner,
} from 'utils/InAppContextBanners';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type InAppContextBannerProps = {
  page: string;
  allowDismiss?: boolean;
  featureFlag?: FeatureFlag;
  className?: string;
  knowledgeBaseLink?: string;
};

export const InAppContextBanner = ({
  page = 'summary',
  allowDismiss = true,
  featureFlag,
  className = '',
}: InAppContextBannerProps) => {
  const {
    title,
    description,
    lightBackground,
    mobileBackground,
    desktopBackground,
    howItWorksLink,
    padding,
    demoLink,
    knowledgeBaseLink,
  } = IN_APP_CONTEXT_BANNERS_DATA[page] || {};
  const isSmall = useIsSmall();
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const { shouldNotBeSeen: isLocked } = useFeatureFlag(featureFlag);

  const [displayBanner, setDisplayBanner] = useState(isLocked);
  const [modalOpen, setModalOpen] = useState(false);

  const getBackground = useMemo(() => {
    if ((isSmall || isFromMobileApp) && mobileBackground) {
      return `#072b4d center / cover no-repeat url(${mobileBackground})`;
    } else if (desktopBackground) {
      return `#072b4d center / cover no-repeat url(${desktopBackground})`;
    } else {
      return lightBackground ? `lightgray` : `darkgray`;
    }
  }, [isSmall, isFromMobileApp, mobileBackground, desktopBackground, lightBackground]);

  const [background, setBackground] = useState(getBackground);

  useEffect(() => {
    setBackground(getBackground);
  }, [isFromMobileApp, getBackground]);

  useEffect(() => {
    if (isLocked || !currentShopId) return;

    getInAppContextBanner(page, currentShopId)
      .then((isShown) => setDisplayBanner(isLocked || isShown))
      .catch((err) => console.error(err));
  }, [isLocked, currentShopId, page]);

  const dismiss = useCallback(() => {
    //Closed banners should be dismissed until the user’s next log in.
    setDisplayBanner(false);
    setInAppContextBanner(page, false, currentShopId);
  }, [page, currentShopId]);

  if (Object.prototype.hasOwnProperty.call(IN_APP_CONTEXT_BANNERS_DATA, page) === false)
    return null;

  if (!displayBanner) {
    return null;
  }

  const roundedCorners = {
    ...(isSmall || isFromMobileApp ? {} : { borderRadius: '12px' }),
  };

  return <></>;

  // return (
  //   <>
  //     <div
  //       className={`${marginBottom} ${className} p-14 relative in-app-context-banner`}
  //       style={{ background: background, ...roundedCorners, minHeight: '240px' }}
  //     >
  //       {allowDismiss && (
  //         <div className="absolute top-4 right-4">
  //           <CloseIcon
  //             className={`${
  //               lightBackground ? 'fill-black' : 'fill-white'
  //             } ml-[15px] cursor-pointer`}
  //             width={20}
  //             onClick={dismiss}
  //           />
  //         </div>
  //       )}

  //       <div
  //         className="flex items-center justify-center gap-4 flex-col"
  //         style={{
  //           color: lightBackground ? 'black' : 'white',
  //         }}
  //       >
  //         <h1 style={{ fontSize: '30px', fontWeight: 'bold', lineHeight: '44px' }}>{title}</h1>
  //         <div className="text-center max-w-lg mb-2">
  //           <p style={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}>{description}</p>
  //         </div>
  //         <div
  //           className={`flex items-center justify-center gap-4`}
  //           style={isSmall || isFromMobileApp ? { width: '100%' } : {}}
  //         >
  //           {howItWorksLink && (
  //             <Button icon={PlayCircleMajor} primary onClick={() => setModalOpen(true)}>
  //               How It Works
  //             </Button>
  //           )}
  //           {/* {demoLink && <Button onClick={() => startTour()}>Take a Tour</Button>} */}
  //           {knowledgeBaseLink && (
  //             <span className="tw-nice-dark">
  //               <Button
  //                 onClick={() => {
  //                   window.open(knowledgeBaseLink);
  //                 }}
  //               >
  //                 Knowledge Base
  //               </Button>
  //             </span>
  //           )}
  //         </div>
  //         {isSmall || isFromMobileApp ? <div className="py-20" /> : null}
  //       </div>
  //     </div>
  //     <Modal
  //       titleHidden
  //       large
  //       title={`How It Works: ${title}`}
  //       open={modalOpen}
  //       onClose={() => setModalOpen(false)}
  //     >
  //       <Modal.Section>
  //         <div
  //           className="flex items-center"
  //           style={{
  //             minHeight: '200px',
  //             paddingTop: '28px',
  //             paddingBottom: isSmall || isFromMobileApp ? '60px' : '0px', //padding for intercom button
  //           }}
  //         >
  //           <LoomVideo loomEmbed={howItWorksLink} mobile={isSmall || isFromMobileApp} />
  //         </div>
  //       </Modal.Section>
  //     </Modal>
  //     {padding > 0 && <div className={`p-${padding}`} />}
  //   </>
  // );
};

const LoomVideo = ({ loomEmbed, mobile }) => {
  return (
    <div
      className="w-full h-full"
      dangerouslySetInnerHTML={{
        __html: `<iframe width="100%" height="${
          mobile ? 345 : 600
        }" src="${loomEmbed}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`,
      }}
    />
  );
};

export default LoomVideo;
