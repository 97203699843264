import { useCallback, useEffect, useState } from 'react';
import { ColumnName, SequenceProgressEvent } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $socket } from '$stores/$socket';
import { AttributionData } from 'types/attribution';
import { $updatedAiRows } from '$stores/willy/$sequences';
import { ServicesIds } from '@tw/types/module/services';

type UseColumnsSocketProps = {
  shopId: string;
  sequenceId: string;
};

export function useColumnsSocket({ shopId, sequenceId }: UseColumnsSocketProps) {
  const socket = useStoreValue($socket);
  const [columns, setColumns] = useState<Record<string, AttributionData & { column: ColumnName }>>(
    {},
  );

  const onSequenceProgress = useCallback(
    (data: SequenceProgressEvent) => {
      if (data.type !== 'sendToDatabase') {
        return;
      }

      if (data.sequenceId !== sequenceId) {
        return;
      }

      const newColumns: Record<string, AttributionData & { column: ColumnName }> =
        data.data.columns.reduce(
          (acc, column) => {
            acc[column.id] = {
              id: column.id,
              entity: column.entity || undefined,
              serviceId: column.provider_id as ServicesIds,
              column: column.metric,
              [column.metric]: {
                reason: column.reason,
                value: column.value,
                created_at: column.created_at,
                raw_data_path: column.raw_data_path,
              },
            } as unknown as AttributionData & { column: ColumnName };
            return acc;
          },
          {} as Record<string, AttributionData & { column: ColumnName }>,
        );

      setColumns((prev) => ({
        ...prev,
        ...newColumns,
      }));

      $updatedAiRows.set((prev) => ({
        ...prev,
        ...Object.values(newColumns).reduce((acc, attribution) => {
          if (!attribution.id) {
            return acc;
          }
          if (!acc[attribution.id]) {
            acc[attribution.id] = {};
          }
          acc[attribution.id] = {
            ...acc[attribution.id],
            [attribution.column]: true,
          };
          return acc;
        }, {}),
      }));
    },
    [sequenceId],
  );

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'workflow-progress' || msg.account !== shopId) {
        return;
      }
      onSequenceProgress(msg.data);
    };

    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [shopId, socket, onSequenceProgress]);

  return { columns };
}
