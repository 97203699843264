export const WILLY_NAV_ITEMS: {
  id: string;
  label: string;
  link: string;
  withHeader: boolean;
  isWorkflowPage?: boolean;
  hideFromSidePanel?: boolean;
}[] = [
  {
    id: 'chat',
    label: 'Chat',
    link: '/chat/shop',
    withHeader: false,
  },
  {
    id: 'history',
    label: 'Chat History',
    link: '/chat/history',
    withHeader: false,
  },
  {
    id: 'agents_library',
    label: 'Agent Templates',
    link: '/chat/agents/collections',
    withHeader: false,
  },
  {
    id: 'agents_library',
    label: 'Agent Templates',
    link: '/chat/agents/collections/:id',
    withHeader: false,
    hideFromSidePanel: true,
  },
  {
    id: 'agent_template',
    label: 'Agent Template',
    link: '/chat/agents/templates',
    withHeader: false,
    hideFromSidePanel: true,
  },
  {
    id: 'agent_template',
    label: 'Agent Template',
    link: '/chat/agents/templates/:id',
    withHeader: false,
    hideFromSidePanel: true,
  },
  {
    id: 'aicolumn_template',
    label: 'AI Column Template',
    link: '/chat/agents/ai-columns',
    withHeader: false,
    hideFromSidePanel: true,
  },
  {
    id: 'aicolumn_template',
    label: 'AI Column Template',
    link: '/chat/agents/ai-columns/:id',
    withHeader: false,
    hideFromSidePanel: true,
  },
  {
    id: 'agents',
    label: "Shop's Agents",
    link: '/workflows',
    withHeader: true,
    isWorkflowPage: true,
  },
  {
    id: 'chat_with_sequence_run',
    label: 'Chat With Sequence Run',
    link: '/chat/sequenceRun',
    withHeader: false,
    hideFromSidePanel: true,
  },
];
