import { $currency, $timezone } from '$stores/$shop';
import { $user } from '$stores/$user';
import axiosInstance from 'utils/axiosInstance';
import moment from 'moment-timezone';

type GetDataForClientParams = {
  query: string;
  shopId: string;
  additionalShopIds?: string[];
  dialect: string;
  startDate?: string;
  endDate?: string;
  customDateClause?: string;
  currency?: string;
  timezone?: string;
};

export async function getDataForClient(params: GetDataForClientParams) {
  const { query, shopId, additionalShopIds = [] } = params;
  let { startDate, endDate, customDateClause, currency, timezone } = params;
  if (!startDate) {
    startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  }
  if (!endDate) {
    endDate = moment().format('YYYY-MM-DD');
  }
  if (!customDateClause) {
    customDateClause = `event_date BETWEEN '${startDate}' AND '${endDate}'`;
  }
  if (!currency) {
    currency = $currency.get();
  }
  if (!timezone) {
    timezone = $timezone.get();
  }
  const userId = $user.get()?.uid;

  const { data } = await axiosInstance.post('/v2/willy/get-data-for-client', {
    query,
    shopId,
    additionalShopIds,
    dialect: 'clickhouse',
    userId,
    startDate,
    endDate,
    customDateClause,
    currency,
    timezone,
  });
  return data;
}
