import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/RootType';

/**
 * @description Automatically opens popup when user comes to app through notification.
 */
export const useHandleOpenFromNotification = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const isOpenedFromAppNotification = useAppSelector(
    (state) => state.mobileApp.isOpenedFromAppNotification,
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (!isOpenedFromAppNotification) return;

    // search.delete('openParams');
    navigate({ pathname: window.location.pathname, search: search.toString() }, { replace: true });
  }, [isOpenedFromAppNotification, navigate, location.search]);
};
