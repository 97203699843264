import { useMemo, useState } from 'react';
import { TextInput, Checkbox, MultiSelect, Flex, Button } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $shopUsers } from '$stores/$users';

type FlowSendToEmailStepProps = {
  email: string;
  setEmail: (email: string) => void;
  formats: string[];
  setFormats: (formats: string[]) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowSendToEmailStep = ({
  email,
  setEmail,
  formats = [],
  setFormats,
  readOnly,
  setIsPristine,
}: FlowSendToEmailStepProps) => {
  const formatOptions = ['PDF', 'JSON', 'CSV'];
  const [addAttachment, setAddAttachment] = useState(formats?.length > 0);
  const [newEmail, setNewEmail] = useState('');
  const shopUsers = useStoreValue($shopUsers);

  const shopEmails = useMemo(
    () =>
      shopUsers.data?.map((user) => ({
        label: user.email,
        value: user.email,
      })),
    [shopUsers.data],
  );

  const toggleSetAddAttachment = () => {
    setIsPristine(false);
    setAddAttachment(!addAttachment);
    if (!addAttachment) {
      setFormats(['PDF']);
    } else {
      setFormats([]);
    }
  };

  return (
    <Flex direction="column" gap="lg">
      <Flex direction="row" gap="sm">
        <TextInput
          placeholder="Add Email Address"
          value={newEmail}
          onChange={(email) => {
            setIsPristine(false);
            setNewEmail(email);
          }}
          disabled={readOnly}
        />
        <Button
          variant="activator"
          onClick={() => {
            setIsPristine(false);
            setEmail([newEmail, ...email.split(',')].join(','));
            setNewEmail('');
          }}
        >
          Add
        </Button>
      </Flex>
      <MultiSelect
        searchable
        label="Or choose one of the shop's users"
        onChange={(emails) => {
          setIsPristine(false);
          setEmail([...new Set(emails)].join(','));
        }}
        disabled={readOnly}
        data={shopEmails}
        value={email.split(',').filter(Boolean)}
      />
      <Flex direction="column" gap="sm">
        <Checkbox
          label="Add Attachment"
          checked={addAttachment}
          onChange={toggleSetAddAttachment}
          disabled={readOnly}
        />
        {addAttachment && (
          <MultiSelect
            value={formats}
            onChange={(formats) => {
              setIsPristine(false);
              setFormats(formats);
            }}
            disabled={readOnly}
            data={formatOptions}
          />
        )}
      </Flex>
    </Flex>
  );
};
