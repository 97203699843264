import { useComputedValue, useStoreValue, $store } from '@tw/snipestate';
import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useEffect, useState } from 'react';
import { $currentShopId } from '$stores/$shop';
import { NotificationType } from '../types';
import axiosInstance from 'utils/axiosInstance';
import { getAuth } from 'firebase/auth';

export interface NotificationData {
  _id: string;
  notification_id: string;
  header_text: string;
  body_text: string;
  cta: string;
  placement_url?: string;
  click_target_modal?: string;
  click_target_url?: string;
  notification_type: NotificationType;
  email_send: boolean;
  offer_credit_id?: string | null;
  score: number;
  active_status: string;
  start_date: string;
  end_date?: string | null;
}

// Add a type for the inPageBanner structure
export interface InPageBannerMap {
  [placement: string]: NotificationData;
}

export const $bannerState = $store({
  globalBanner: null as NotificationData | null,
  inPageBanner: null as NotificationData | InPageBannerMap | null,
  hasFetched: false,
  needsRefetch: true,
  isGlobalBannerVisible: true,
});

export const setGlobalBannerVisible = (visible: boolean) => {
  $bannerState.set((state) => ({
    ...state,
    isGlobalBannerVisible: visible,
  }));
};

export const triggerBannerRefetch = () => {
  if (!$bannerState.get().needsRefetch) {
    $bannerState.set((state) => ({
      ...state,
      needsRefetch: true,
    }));
  }
};

const fetchTracker = {
  inProgress: false,
  isFetching: false,
  completedShops: new Set<string>(),
};

export function useShowNotificationBanner() {
  const r = useComputedValue($activeRoute, (r) => r.activeRoute);
  const appVersion = useStoreValue($activeAppVersion);
  const shopId = useStoreValue($currentShopId);
  const bannerState = useStoreValue($bannerState);
  const [isVisible, setIsVisible] = useState(true);
  const needsRefetch = useStoreValue($bannerState).needsRefetch;

  /**
   * Removes an in-page banner by its notification_id
   * @param notificationId - The notification_id of the banner to remove
   */
  const removeInPageBanner = (notificationId: string) => {
    $bannerState.set((state) => {
      // Check if inPageBanner is an object (multiple placements) or a single banner
      if (state.inPageBanner) {
        // If inPageBanner is an object with multiple placements
        if (typeof state.inPageBanner === 'object' && !('notification_id' in state.inPageBanner)) {
          // Create a new object without the banner with the matching notification_id
          const updatedInPageBanner = { ...state.inPageBanner } as InPageBannerMap;
          let bannerRemoved = false;

          // Iterate through all placements and remove the banner if found
          Object.keys(updatedInPageBanner).forEach((placement) => {
            if (updatedInPageBanner[placement]?.notification_id === notificationId) {
              delete updatedInPageBanner[placement];
              bannerRemoved = true;
            }
          });

          // If a banner was removed, update the state
          if (bannerRemoved) {
            return {
              ...state,
              inPageBanner:
                Object.keys(updatedInPageBanner).length > 0 ? updatedInPageBanner : null,
            };
          }
        }
        // If inPageBanner is a single banner object
        else if (state.inPageBanner.notification_id === notificationId) {
          return {
            ...state,
            inPageBanner: null,
          };
        }
      }
      return state;
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchBanner = async () => {
      if (!shopId || fetchTracker.inProgress || !needsRefetch || fetchTracker.isFetching) {
        return;
      }

      fetchTracker.isFetching = true;

      const auth = await getAuth();
      if (!auth?.currentUser?.uid) {
        fetchTracker.isFetching = false;
        return;
      }

      try {
        fetchTracker.inProgress = true;

        const response = await axiosInstance.get(
          `/v2/banner/banner-management/get-shop-banner/${shopId}`,
        );

        if (!isMounted) return;

        const { banners } = response.data;
        const { globalBanner, inPageBanner } = banners || {};

        $bannerState.set((state) => ({
          ...state,
          globalBanner: globalBanner || null,
          inPageBanner: inPageBanner || null,
          hasFetched: true,
          needsRefetch: false,
          isGlobalBannerVisible: true,
        }));

        fetchTracker.completedShops.add(shopId);
      } catch (err) {
        console.error('Banner fetch error:', {
          error: err.message,
          shopId,
        });
      } finally {
        fetchTracker.inProgress = false;
        fetchTracker.isFetching = false;
      }
    };
    fetchBanner();

    return () => {
      isMounted = false;
    };
  }, [shopId, needsRefetch]);

  const shouldShowBanner = !!(
    (bannerState.globalBanner || bannerState.inPageBanner) &&
    (r?.isWillyPage || r?.isHybridPage) &&
    !r?.isShoplessPage &&
    !r?.isSettingsPage &&
    appVersion === '3.0'
  );

  return {
    shouldShowBanner,
    globalBanner: bannerState.globalBanner,
    inPageBanner: bannerState.inPageBanner,
    isVisible,
    setIsVisible,
    isGlobalBannerVisible: bannerState.isGlobalBannerVisible,
    removeInPageBanner,
  };
}
