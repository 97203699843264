import 'components/CreativesCockpit/CreativeChart/CreativeChart.scss';
import amplitude from 'amplitude-js';

import BaseChart from 'components/library/BaseChart/BaseChart';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import TWImage from 'components/library/TWImage/TWImage';
import DropDown from 'components/ltv/DropDown';
import {
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import { cloneDeep } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { ChartData, ChartMode, INFLUENCERS_COLORS, SelectableInfluencer } from 'types/Influencers';
import { formatNumber } from 'utils/formatNumber';

import { Spinner } from '@shopify/polaris';

import { metrics } from '../../../constants/metrics/metrics';
import { InfluencersContext } from '../context';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { BaseLegend } from 'components/library/BaseChart/BaseLegend';
import { ActionIcon } from '@tw/ui-components';
import { useDispatch } from 'react-redux';
import { chartOpenChanged } from 'ducks/attribution/actions';
import { AffluencerHubChartOpenChanged } from 'ducks/affluencerHub/actions';
import CustomLineTooltip from 'components/library/BaseChart/ChartTooltip';

interface InfluencersChartProps {}

const InfluencersChart: FC<InfluencersChartProps> = () => {
  const isSmall = useIsSmall();
  const { selectedInfluencers, loading, currency, toggleActive, influencersDispatch } =
    useContext(InfluencersContext);
  const [lineData, setLineData] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);
  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>('influencer');
  const [chartMode, setChartMode] = useState<ChartMode>('line');
  const dispatch = useDispatch();

  const defaultPrimaryMetric = 'pixelPurchases'; //localStorage.getItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC) || 'spend';
  const defaultSecondaryMetric = 'pixelConversionValue';
  //localStorage.getItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC) || 'roas';

  const [primaryMetric, setPrimaryMetric] = useState(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState(defaultSecondaryMetric);

  const lineHovered = useCallback(
    (influencer: SelectableInfluencer) => {
      if (influencer.active) {
        return;
      }
      setHoveredItems((items: any) => [...items, influencer!.id]);
      toggleActive?.(influencer);
    },
    [toggleActive],
  );

  const lineLeft = useCallback(
    (influencer: SelectableInfluencer) => {
      const copy = cloneDeep(hoveredItems);
      const index = copy.findIndex((item) => influencer.id === item);
      if (index === -1) {
        return;
      }
      toggleActive?.(influencer);
      copy.splice(index, 1);
      setHoveredItems(copy);
    },
    [hoveredItems, toggleActive],
  );

  const handlePrimaryMetricChange = useCallback((metric: string) => {
    amplitude
      .getInstance()
      .logEvent('Creative Cockpit: Chart Metric Changed', { yAxis: 'right', metric: metric });
    setPrimaryMetric(metric);
    localStorage.setItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC, metric);
  }, []);

  const handleSecondaryMetricChange = useCallback((metric: string) => {
    amplitude
      .getInstance()
      .logEvent('Creative Cockpit: Chart Metric Changed', { yAxis: 'right', metric: metric });
    setSecondaryMetric(metric);
    localStorage.setItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC, metric);
  }, []);

  const getData = useCallback(() => {
    let linesData: any = [];
    let barsData: any = [];

    linesData = selectedInfluencers?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d.date }))!;
    selectedInfluencers?.forEach((influencer) => {
      influencer.metricsBreakdown?.forEach((bd) => {
        let date = linesData.find((date) => date.date === bd.date);
        if (!date) {
          date = { date: bd.date };
          linesData.push(date);
        }
        date[influencer.id!] = {
          ...bd.metrics,
          id: influencer.id,
          color: influencer.color,
        };
      });
    });

    selectedInfluencers!.forEach((influencer) => {
      barsData.push({
        [primaryMetric]: Number(influencer?.[primaryMetric]).toFixed(2) || 0,
        [secondaryMetric]: Number(influencer?.[secondaryMetric]).toFixed(2) || 0,
        fill: influencer.color,
        opacity: influencer.active ? 1 : 0.1,
      });
    });

    setLineData(linesData);
    setBarData(barsData);
  }, [primaryMetric, secondaryMetric, selectedInfluencers]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getDropdownOptions = () => {
    const metricOptions = Object.values(metrics).filter((m) => m.showInInfluencersHub);
    return [
      { label: 'None', value: 'none' },
      ...metricOptions.map((value) => ({
        label: value.label,
        value: value.key,
      })),
    ];
  };

  const handleClose = () => {
    // Unselect all influencers using influencersDispatch
    selectedInfluencers?.forEach((influencer) => {
      influencersDispatch?.({ type: 'toggle_select', influencer });
    });
    dispatch(AffluencerHubChartOpenChanged(false));
  };

  return (
    <div className="blue-chart-wrapper">
      <div className="blue-chart">
        {loading && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <Spinner size="large" />
            </div>
          </div>
        )}
        <div
          className="blue-chart-top-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
          }}
        >
          <span
            style={{
              marginLeft: '24px',
              fontSize: '18px',
              fontWeight: 500,
            }}
          >
            Trends
          </span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <DropDown
              showFilter
              value={primaryMetric === 'none' ? 'none' : metrics[primaryMetric].key}
              handleSelect={handlePrimaryMetricChange}
              options={getDropdownOptions()}
              size="xs"
            />
            <DropDown
              showFilter
              value={secondaryMetric === 'none' ? 'none' : metrics[secondaryMetric].key}
              handleSelect={handleSecondaryMetricChange}
              options={getDropdownOptions()}
              size="xs"
            />
            <div style={{ width: '1px', height: '25px', background: '#D1D4DB' }} />
            <DropDown
              value={chartMode}
              handleSelect={(value) => {
                setChartMode(value as ChartMode);
                amplitude
                  .getInstance()
                  .logEvent('Creativexxxxxpit: Chart Type Changed', { chartType: value });
              }}
              options={[
                {
                  label: 'Line',
                  value: 'line',
                  icon: 'line-chart',
                },
                {
                  label: 'Bar',
                  value: 'bar',
                  icon: 'bar-chart',
                },
              ]}
              customActivator={
                <ActionIcon
                  size="md"
                  radius="sm"
                  variant="activator"
                  icon={chartMode === 'line' ? 'line-chart' : 'bar-chart'}
                />
              }
            />
            <div className="cursor-pointer" style={{ paddingRight: '24px' }}>
              <ActionIcon
                size="md"
                radius="sm"
                variant="activator"
                icon="close"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
        {chartMode === 'line' && (
          <BaseChart
            data={lineData}
            showTooltip={true}
            ChartType={LineChart}
            margin={{ left: 10, right: 0 }}
            leftYAxisLabel={metrics[primaryMetric]?.label || primaryMetric}
            rightYAxisLabel={metrics[secondaryMetric]?.label || secondaryMetric}
            xAxis={[
              {
                tickFormatter: (value: string) => {
                  if (!moment(value).isValid() || !value) {
                    return '';
                  }
                  if (value.includes('T')) {
                    return moment(value).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dataKey: 'date',
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
              {
                yAxisId: 'right',
                orientation: 'right',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
            ]}
          >
            {selectedInfluencers &&
              selectedInfluencers.map((sel) => {
                const primaryLabel =
                  primaryMetric !== 'none' && metrics[primaryMetric]
                    ? metrics[primaryMetric].label
                    : 'None';
                const secondaryLabel =
                  secondaryMetric !== 'none' && metrics[secondaryMetric]
                    ? metrics[secondaryMetric].label
                    : 'None';

                return (
                  <Fragment key={sel.id}>
                    {secondaryMetric !== 'none' && (
                      <Line
                        yAxisId={'left'}
                        type="monotone"
                        name={primaryLabel}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] && dataPoint[sel.id!][primaryMetric]
                            ? dataPoint[sel.id!][primaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          onClick: () => toggleActive?.(sel),
                          style: {
                            cursor: 'pointer',
                            fill: sel.active ? 'white' : 'transparent',
                            opacity: sel.active ? 1 : 0,
                          },
                        }}
                        onMouseOver={() => lineHovered(sel)}
                        onMouseLeave={() => lineLeft(sel)}
                        onClick={() => toggleActive?.(sel)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {secondaryMetric !== 'none' && (
                      <Line
                        yAxisId={'left'}
                        type="monotone"
                        name={secondaryLabel}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] && dataPoint[sel.id!][secondaryMetric]
                            ? dataPoint[sel.id!][secondaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          style: { display: 'none' },
                          strokeOpacity: 0,
                        }}
                        strokeDasharray="3,3"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <ChartTooltip
                      content={
                        <CustomLineTooltip
                          data={selectedInfluencers}
                          primaryMetric={primaryMetric}
                          secondaryMetric={secondaryMetric}
                          showImages={false}
                        />
                      }
                      cursor={{ fill: 'none' }}
                      labelStyle={{ fontWeight: 'bold' }}
                      contentStyle={{ fontSize: '10px' }}
                      formatter={(value: number, metricLabel: string) => {
                        const metric = Object.values(metrics).find(
                          (m) => m.label === metricLabel,
                        )?.key;
                        if (!metric) {
                          return value;
                        }
                        if (!isNaN(value)) {
                          return value;
                        }
                        return value;
                      }}
                      labelFormatter={(value) => {
                        if (moment(value).isValid()) {
                          value = '' + value;
                          return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                        }
                        return value;
                      }}
                    />
                  </Fragment>
                );
              })}
          </BaseChart>
        )}
        {chartMode === 'bar' && (
          <BaseChart
            data={barData}
            showTooltip={true}
            margin={{ left: 10, right: 0 }}
            ChartType={BarChart}
            leftYAxisLabel={metrics[primaryMetric]?.label || primaryMetric}
            rightYAxisLabel={metrics[secondaryMetric]?.label || secondaryMetric}
            xAxis={[
              {
                dataKey: 'date',
                tickFormatter: (value) => {
                  return !!value && moment(value).isValid() ? moment(value).format('MMM D') : '';
                },
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
              {
                yAxisId: 'right',
                orientation: 'right',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
            ]}
          >
            <defs>
              {INFLUENCERS_COLORS.map((color, index) => (
                <linearGradient
                  key={'color-' + color + '-' + index}
                  id={`color-creative-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0" stopColor={color} stopOpacity={1} />
                  <stop offset="1" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <Bar
              barSize={22}
              yAxisId={'left'}
              dataKey={primaryMetric}
              name={(metrics[primaryMetric]?.label as string) || ''}
            ></Bar>
            <Bar
              barSize={22}
              yAxisId={'right'}
              dataKey={secondaryMetric}
              name={(metrics[secondaryMetric]?.label as string) || ''}
            >
              {(chartData === 'influencer' ? selectedInfluencers : [])?.map((entry, index) => (
                <Cell
                  key={`cell-gradient-${secondaryMetric}-${index}`}
                  fill={`url(#color-creative-${index % INFLUENCERS_COLORS.length})`}
                  opacity={entry.active ? 1 : 0.1}
                />
              ))}
            </Bar>
            <ChartTooltip
              cursor={{ fill: 'none' }}
              // content={<></>}
              labelStyle={{ fontWeight: 'bold' }}
              contentStyle={{ fontSize: '10px' }}
              formatter={(value: number, metricLabel: string) => {
                const metric = Object.values(metrics).find((m) => m.label === metricLabel)?.key;
                if (!metric) {
                  return value;
                }
                if (!isNaN(value)) {
                  return formatNumber(value, {
                    style: metrics[metric]?.format || 'decimal',
                    currency,
                    minimumFractionDigits: metrics[metric]?.toFixed,
                    maximumFractionDigits: metrics[metric]?.toFixed,
                  });
                }
                return value;
              }}
              labelFormatter={(value) => {
                if (moment(value).isValid()) {
                  value = '' + value;
                  return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                }
                return value;
              }}
            />
          </BaseChart>
        )}
        <div
          className="flex overflow-auto gap-4 p-[var(--padding)]"
          style={{
            paddingInline: chartMode === 'bar' && !isSmall ? '3.75rem 2.5rem' : '',
            justifyContent: 'flex-start',
            transition: 'all 0.3s ease',
          }}
        >
          {(chartData === 'influencer' ? selectedInfluencers : [])?.map((dataObj) => (
            <BaseLegend
              key={dataObj.id}
              color={dataObj.color ?? '#000'}
              tooltipText={dataObj.name}
              active={dataObj.active}
              onClick={() => toggleActive?.(dataObj)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfluencersChart;
