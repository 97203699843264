import { $activeAppVersion } from '$stores/nav-config-stores';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Navbar } from '@tw/ui-components';
import { $appTransition } from 'Router/Router';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { useGlobalBannerHeight, useShowGlobalBanner } from 'hooks/useGlobalBanner';

export const DesktopNavWrapper = ({ children }) => {
  const isV3 = useStoreValue($activeAppVersion) === '3.0';
  const _transition = useComputedValue($appTransition, (t) => `width ${t}ms ease`);
  const transition = isV3 ? undefined : _transition;
  const globalBannerHeight = useGlobalBannerHeight();
  const hasGlobalBanner = useShowGlobalBanner();

  return (
    <Navbar
      bg="named.2"
      borderRight={TOP_BAR_BORDER_VAR}
      transition={transition}
      top={hasGlobalBanner ? globalBannerHeight : 0}
    >
      {children}
    </Navbar>
  );
};
