import { changeSelectedColumns } from 'ducks/attribution/actions';
import store from 'index';
import { PixelColumn } from 'types/attribution';
import { userDb } from 'utils/DB';

export async function saveAttributionColumns(shopId: string, columns: PixelColumn[]) {
  store.dispatch(changeSelectedColumns(columns));
  await userDb().set(
    {
      shops: {
        [shopId]: {
          ui: {
            attribution: {
              selectedColumns: columns.map((c) => c.key),
            },
          },
        },
      },
    },
    {
      merge: true,
    },
  );
}
