import { $store, $derived } from '@tw/snipestate';
import { SQLModel, LLMModel, DeepSearchModel } from 'components/Willy/constants';

export const landingPages = ['Feed', 'Questions', 'Beta'];
export type LandingPage = (typeof landingPages)[number];

type MainChatStore = {
  sqlGeneratingModel: SQLModel;
  customSqlModel: string;
  modelToolsName: LLMModel;
  customModelToolsName: string;
  deepSearchModel: DeepSearchModel;
  customDeepSearchModel: string;
  deepSearch: boolean;
  activePage: LandingPage;
  buildMode: boolean;
};

export const $mainChatStore = $store<MainChatStore>({
  sqlGeneratingModel: 'default',
  customSqlModel: '',
  modelToolsName: 'default',
  customModelToolsName: '',
  deepSearchModel: 'default',
  customDeepSearchModel: '',
  deepSearch: false,
  activePage: landingPages[0],
  buildMode: false,
});

export const setSqlGeneratingModel = (sqlGeneratingModel: SQLModel) => {
  $mainChatStore.set((x) => ({ ...x, sqlGeneratingModel }));
};

export const setCustomSqlModel = (customSqlModel: string) => {
  $mainChatStore.set((x) => ({ ...x, customSqlModel }));
};

export const setModelToolsName = (modelToolsName: LLMModel) => {
  $mainChatStore.set((x) => ({ ...x, modelToolsName }));
};

export const setCustomModelToolsName = (customModelToolsName: string) => {
  $mainChatStore.set((x) => ({ ...x, customModelToolsName }));
};

export const setDeepSearchModel = (deepSearchModel: DeepSearchModel) => {
  $mainChatStore.set((x) => ({ ...x, deepSearchModel }));
};

export const setCustomDeepSearchModel = (customDeepSearchModel: string) => {
  $mainChatStore.set((x) => ({ ...x, customDeepSearchModel }));
};

export const setDeepSearch = (deepSearch: boolean) => {
  $mainChatStore.set((x) => ({ ...x, deepSearch, buildMode: false }));
};

export const setBuildMode = (buildMode: boolean) => {
  $mainChatStore.set((x) => ({ ...x, buildMode, deepSearch: false }));
};

export const setActivePage = (activePage: LandingPage) => {
  $mainChatStore.set((x) => ({ ...x, activePage }));
};
