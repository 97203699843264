import { useEffect, useState, useRef } from 'react';

import { useCallback } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import moment from 'moment-timezone';
import { convertDataToJson, simplifyParsedData } from '../utils/willyUtils';
import { DataWithName, NlqResponse } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $dialect } from '$stores/$user';

type UseGenUiDateRangeProps = {
  initialData: DataWithName[] | null;
};

export const useGenUiDateRange = (props: UseGenUiDateRangeProps) => {
  const { initialData } = props;
  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);

  const [dataToUse, setDataToUse] = useState<DataWithName[]>(initialData || []);
  const [isLoadingSavedQuery, setIsLoadingSavedQuery] = useState<boolean>(false);

  const changeDataToUse = useCallback(
    async (
      startDate: moment.Moment,
      endDate: moment.Moment,
      queries: string[],
      isPrevious: boolean = false,
    ) => {
      if (!currentShopId) {
        return;
      }

      setIsLoadingSavedQuery(true);

      try {
        const promises = queries.map(async (q) => {
          const { data } = await axiosInstance.post<NlqResponse>('/v2/willy/load-saved-query', {
            queryId: q,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            shopId: currentShopId,
            dialect,
            queryParams: {
              start_date: startDate.format('YYYY-MM-DD'),
              end_date: endDate.format('YYYY-MM-DD'),
            },
          });

          return data;
        });

        const nlqResponses = await Promise.all(promises);

        const newDataToUse = nlqResponses.map((x, index) => {
          const asJson = convertDataToJson(x.data);
          const simplified = simplifyParsedData(asJson);
          return {
            data: simplified,
            error: x.error || null,
            queryId: x.queryId || '',
          };
        });

        setDataToUse((oldDataToUse) => {
          // find queryId in oldDataToUse and replace with newDataToUse
          const mergedData = oldDataToUse.map((x) => {
            const newData = newDataToUse.find(
              (y) => y.queryId === x.queryId && !!isPrevious === !!x.isPrevious,
            );
            if (newData) {
              return {
                ...x,
                data: newData.data,
                error: newData.error,
              };
            }
            return x;
          });
          return mergedData;
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSavedQuery(false);
      }
    },
    [currentShopId, dialect],
  );

  useEffect(() => {
    // if (!initialData) {
    //   return;
    // }

    setDataToUse(initialData || []);
  }, [initialData]);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'CHANGE_DATE_RANGE') {
        const { startDate, endDate, queryId, prevStartDate, prevEndDate, isPrevious } =
          event.data.data;
        if (isPrevious) {
          changeDataToUse(moment(prevStartDate), moment(prevEndDate), [queryId], isPrevious);
        } else {
          changeDataToUse(moment(startDate), moment(endDate), [queryId], isPrevious);
        }
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [changeDataToUse]);

  return { dataToUse, isLoadingSavedQuery };
};
