import { useAppSelector } from '../../reducers/RootType';
import { useEffect, useMemo, useState } from 'react';
import { urlParams } from '../../config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logoutOnPress, signInWithCustomToken, updateCurrentShopId } from '../../ducks/auth';
import axiosInstance from '../../utils/axiosInstance';
import firebase from 'firebase/compat/app';
import { toast } from 'react-toastify';
import { appDetailsType } from '../OrcaApp';
import { getProviders } from '../../ducks/sensory';
import { useAppDispatch } from '../../index';
import { sleep } from '../../utils/sleep';
import { killIntercom } from '../../utils/intercom';
import { jwtDecode, JwtPayload } from 'jwt-decode'; // Needed to decode token user ID

export const useOrcaPage = () => {
  const providersConfig = useAppSelector((state) => state.sensory.providers);
  const [appDetails, setAppDetails] = useState<appDetailsType | null>(null);
  const [errorLoadingApp, setErrorLoadingApp] = useState<string>('');
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [accountId, setAccountId] = useState<string | null>(urlParams.get('account-id'));
  const [appId, setAppId] = useState<string | null>(urlParams.get('app-id'));
  const user = useAppSelector((state) => state.user);
  const isLoggedIn = useAppSelector((state) => state.isLoggedIn);
  const loadingAuth = useAppSelector((state) => state.loading);
  const [token, setToken] = useState<string | null>(urlParams.get('token'));
  const dispatch = useAppDispatch();
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    console.log('useOrcaPage mounted');
    setTimeout(() => {
      killIntercom();
      setTimeout(() => {
        killIntercom();
      }, 500);
    }, 500);
  }, []);

  useEffect(() => {
    if (!urlParams.get('shop-id') && accountId) {
      updateCurrentShopId(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (!accountId) {
      const acc = localStorage.getItem('account-id');
      if (acc) {
        setAccountId(acc);
      }
    } else {
      localStorage.setItem('account-id', accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (!appId) {
      const ap = localStorage.getItem('app-id');
      if (ap) {
        setAppId(ap);
      }
    } else {
      localStorage.setItem('app-id', appId);
    }
  }, [appId]);

  // Function to extract user ID from the token
  const getUserIdFromToken = (token: string | null) => {
    try {
      if (!token) return null;
      const decoded = jwtDecode<{ uid: string } & JwtPayload>(token);
      return decoded?.uid;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  // Handle authentication with token
  useEffect(() => {
    (async () => {
      if (loadingAuth || !accountId || !appId || loadingSignIn || isLogout) return;

      if (!token) {
        setErrorLoadingApp('Token is missing');
        return;
      }

      const currentUser = firebase.auth().currentUser;
      const tokenUserId = getUserIdFromToken(token);

      if (currentUser && tokenUserId && currentUser.uid === tokenUserId) {
        return;
      }

      // If the token user ID is different from the current logged-in user, force logout
      if (currentUser && tokenUserId && currentUser.uid !== tokenUserId) {
        console.log('User mismatch detected. Logging out before switching users...');
        await firebase.auth().signOut();
        setIsLogout(true);
        location.reload();
        return;
      }

      // Proceed with sign-in
      try {
        setLoadingSignIn(true);
        console.log('Signing in with new token:', token);
        await signInWithCustomToken(token);
        console.log('Successfully signed in with new token');
      } catch (error) {
        console.error('Error during sign-in:', error);
        toast.error(`Authentication failed: ${error.message}`);
        setErrorLoadingApp(error.message);
      }
      setLoadingSignIn(false);
    })();
  }, [token, accountId, appId, loadingAuth, isLoggedIn, loadingSignIn, isLogout]);

  // Load app details AFTER successful login
  useEffect(() => {
    (async () => {
      if (!user.uid || !appId || !accountId || isLogout) return;

      const currentUser = firebase.auth().currentUser;
      const tokenUserId = getUserIdFromToken(token);

      if (currentUser?.uid !== tokenUserId) return;

      try {
        console.log('Fetching app details for user:', user.uid);

        const data = (
          await axiosInstance.post(`/v2/orcabase/client-app/get-app-info`, {
            appId,
            accountId,
          })
        ).data;

        setAppDetails(data);
      } catch (e) {
        setErrorLoadingApp(e.data);
      }

      try {
        await dispatch(getProviders(false));
      } catch (e) {
        await sleep(1000);
        if (token) {
          location.reload();
        } else {
          setErrorLoadingApp(`Token is missing`);
          setLoadingSignIn(false);
        }
      }
    })();
  }, [appId, accountId, user.uid, dispatch, token, isLogout]);

  const loadingPage = useMemo(
    () => providersConfig.length === 0 || !appDetails || loadingAuth || loadingSignIn,
    [providersConfig, appDetails, loadingAuth, loadingSignIn],
  );

  console.log({ loadingPage, appDetails, errorLoadingApp, appId, accountId, providersConfig });

  return { loadingPage, appDetails, errorLoadingApp, appId, accountId };
};
