import { useEffect, useMemo, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WorkflowWithRun } from './types/willyTypes';
import firebase from 'firebase/compat/app';
import { Loader, Skeleton } from '@tw/ui-components';
import { SequenceListCard } from './SequenceListCard';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { UserBasicSafeInfo, useUsersByIds } from 'hooks/useUsersByIds';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { SubscribeAgentModal } from './SubscribeAgentModal';
import { InstallDemoSequences } from './InstallDemoSequences';

type SequenceFeedListProps = {
  workflows: WorkflowWithRun[];
  loading: boolean;
  fetchWorkflows: (startAfter?: firebase.firestore.Timestamp | null) => Promise<void>;
  lastWorkflow: firebase.firestore.Timestamp | null;
  hasMoreItems: boolean;
  freeSearch?: string;
  source?: string;
  customOnClick?: (item: WorkflowWithRun) => void;
  fromDashboard?: boolean;
  onRunItem?: (sequenceId: string) => void;
  markAsRead?: (sequenceId: string) => void;
  dismissFromFeed: (sequenceId: string) => void;
  isBetaChat?: boolean;
};

export const SequenceFeedList: React.FC<SequenceFeedListProps> = ({
  workflows,
  loading,
  fetchWorkflows,
  lastWorkflow,
  hasMoreItems,
  freeSearch,
  source,
  customOnClick,
  fromDashboard,
  onRunItem,
  markAsRead,
  dismissFromFeed,
  isBetaChat,
}) => {
  const [showInstallDemoSequences, setShowInstallDemoSequences] = useState(true);
  const [shareAgentInfo, setShareAgentInfo] = useState<{
    item: WorkflowWithRun;
    user?: UserBasicSafeInfo;
  } | null>(null);

  const userIds = useMemo(() => {
    const ids = workflows.map((w) => w.user).filter((id) => !!id);
    const uniqueIds = [...new Set(ids)];
    return uniqueIds;
  }, [workflows]);

  const stableUserIds = useDeepCompareMemoize(userIds);

  const { users, loading: loadingUsers } = useUsersByIds(stableUserIds);
  const userMap = new Map(users.map((user) => [user.id, user]));

  useEffect(() => {
    genericEventLogger(analyticsEvents.SEQUENCES, {
      action: sequencesActions.VIEW_FEED,
    });
  }, []);

  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full">
        {workflows.length === 0 && !loading && <div className="p-4">No agents found</div>}
        {!isBetaChat && (
          <div
            className={`${isBetaChat ? 'mt-6.5 mb-2' : 'my-6.5'} pl-4 w-full sm:max-w-[800px] m-auto`}
          >
            <h2 className="text-4xl font-semibold text-black">See what's new in your store ✨</h2>
          </div>
        )}
        <div className="flex-1 overflow-auto">
          <div id="feed-scroll" className="h-full overflow-y-auto">
            <InfiniteScroll
              dataLength={workflows.length}
              scrollableTarget="feed-scroll"
              scrollThreshold={0.8}
              next={() => {
                if (hasMoreItems) {
                  fetchWorkflows(lastWorkflow);
                }
              }}
              hasMore={hasMoreItems}
              loader={
                <div className="flex items-center justify-center h-12">
                  <Loader size="sm" />
                </div>
              }
              endMessage={
                <div className="flex flex-col items-center justify-center my-6.5">
                  <p className="no-more-orders-msg">
                    {workflows?.length ? (
                      loading || workflows.length === 0 ? (
                        <div className="flex items-center justify-center h-12">
                          <Loader size="sm" />
                        </div>
                      ) : (
                        '🐳'
                      )
                    ) : freeSearch?.length ? (
                      'No agents found'
                    ) : (
                      'Feed Empty'
                    )}
                  </p>
                </div>
              }
            >
              <div className="flex flex-col gap-6.5 p-4 sm:pb-6.5 w-full sm:max-w-[800px] m-auto">
                {workflows.map((item, i) => {
                  return (
                    <SequenceListCard
                      item={item}
                      user={userMap.get(item.user)}
                      loadingUsers={loadingUsers}
                      index={i}
                      key={item.id + i}
                      onSubscribeClicked={() => {
                        setShareAgentInfo({ item, user: userMap.get(item.user) });
                      }}
                      onItemClick={() => {
                        customOnClick?.(item);
                        genericEventLogger(analyticsEvents.SEQUENCES, {
                          action: sequencesActions.VIEW_FEED_ARTICLE,
                          sequence_id: item.id,
                          sequence_name: item.name,
                          sequence_type: item.globalDashboardId ? 'global' : 'custom',
                          source: source ?? 'feed',
                        });
                      }}
                      fromDashboard={fromDashboard}
                      fromFeed={true}
                      read={!!item?.read}
                      onRunItem={onRunItem}
                      markAsRead={markAsRead}
                      dismissFromFeed={dismissFromFeed}
                    />
                  );
                })}
              </div>
              {loading && (
                <div className="flex flex-col gap-6.5 p-4 sm:pb-6.5 w-full sm:max-w-[800px] m-auto">
                  {Array.from({ length: 6 }).map((_, i) => (
                    <Skeleton key={i} height={128} width="100%" />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {!!shareAgentInfo && (
        <SubscribeAgentModal
          opened={!!shareAgentInfo && !!shareAgentInfo.item}
          onClose={() => setShareAgentInfo(null)}
          item={shareAgentInfo?.item}
        />
      )}
      <InstallDemoSequences
        source="feed"
        opened={showInstallDemoSequences}
        onClose={() => {
          setShowInstallDemoSequences(false);
        }}
      />
    </div>
  );
};
