import SummaryDatePicker from 'components/SummaryDatePicker';
import { DurationFiltersHeader } from 'components/headers/DurationFiltersHeader';
import { ProductAnalyticsHeader } from 'components/headers/ProductAnalyticsHeader';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';
import { META_OPS_ROUTES } from '@tw/shared-types/module/frontend-config/constants';

export const OPS_SECTION: NavSection = {
  title: 'Ops',
  icon: (props) => <NavSectionIcon src="ops" {...props} />,
  routes: [
    // {
    //   url: '/forecast',
    //   isHybridPage: true,
    //   dependsOnFFSystemCallback(ffComputer) {
    //     const { shouldNotBeSeen } = ffComputer.getConfigById(FeatureFlag.FORECASTING_MENU_FF);
    //     return !shouldNotBeSeen;
    //   },
    //   label: 'Planner',
    //   labs: true,
    //   lastUpdate: () => {
    //     const InnerComponent = () => {
    //       const lastUpdate = useAppSelector((state) => state.forecasting.forecastingLastUpdated);

    //       return (
    //         <span className="inline-block mt-[2px] mb-[-2px]">
    //           <Tooltip
    //             content={
    //               <div>
    //                 <div className="text-secondary-text text-[10px]">
    //                   {lastUpdate?.revenueActuals
    //                     ? `Revenue Actuals: ${moment(lastUpdate?.revenueActuals)
    //                         .local()
    //                         .calendar()}`
    //                     : 'Loading'}
    //                 </div>
    //                 <div className="text-secondary-text text-[10px]">
    //                   {lastUpdate?.revenueForecast
    //                     ? `Revenue Forecast: ${moment(lastUpdate?.revenueForecast)
    //                         .local()
    //                         .calendar()}`
    //                     : 'Loading'}
    //                 </div>
    //                 <div className="text-secondary-text text-[10px]">
    //                   {lastUpdate?.inventoryActuals
    //                     ? `Inventory Actuals: ${moment(lastUpdate?.inventoryActuals)
    //                         .local()
    //                         .calendar()}`
    //                     : 'Loading'}
    //                 </div>
    //                 <div className="text-secondary-text text-[10px]">
    //                   {lastUpdate?.inventoryForecast
    //                     ? `Inventory Forecast: ${moment(lastUpdate?.inventoryForecast)
    //                         .local()
    //                         .calendar()}`
    //                     : 'Loading'}
    //                 </div>
    //               </div>
    //             }
    //           >
    //             <span>
    //               <InfoMinor className="w-5 flex items-center fill-gray-600 dark:fill-gray-300" />
    //             </span>
    //           </Tooltip>
    //         </span>
    //       );
    //     };
    //     return <InnerComponent />;
    //   },
    //   hidden: false,
    //   header: () => <ForecastingHeader />,
    // },
    {
      url: '/product-analytics',
      label: 'Product Analytics',
      ...META_OPS_ROUTES['/product-analytics'],
      header: () => <ProductAnalyticsHeader />,
    },

    {
      url: '/ltv/customer-journey',
      label: 'Product Journey',
      ...META_OPS_ROUTES['/ltv/customer-journey'],
      header: () => <DurationFiltersHeader />,
    },
    {
      url: '/insights/cart-analysis',
      label: 'Cart Analysis',
      ...META_OPS_ROUTES['/insights/cart-analysis'],
      header: () => <SummaryDatePicker showGroupByButton={false} />,
    },
  ],
};
