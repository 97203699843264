import { useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  $selectedAiCell,
  $aiColumnsLoading,
  getEntityByAnalyticsEntity,
  $updatedAiRows,
} from '$stores/willy/$sequences';
import { ColumnName } from './Willy/types/willyTypes';
import { Column } from './Willy/types/willyTypes';
import { AttributionData } from 'types/attribution';
import { isRetentionPeriodExpired } from './Willy/utils/agenticColumns';
import { colors, IconName } from '@tw/ui-components';
import { Icon } from '@tw/ui-components';
import { formatNumber } from 'utils/formatNumber';
import { Tooltip } from '@shopify/polaris';
import { useEffect, useState, useMemo } from 'react';
import { useColumnsSocket } from './Willy/sequenceBuilder/useColumnsSocket';
import { $currentShopId } from '$stores/$shop';
import { analyticsEvents, genericEventLogger, sequencesActions } from 'utils/dataLayer';
import { visibleLength } from './Willy/utils/willyUtils';

type AiColumnCellProps = {
  column: Column<ColumnName>;
  item: Partial<AttributionData>;
};

export const AiColumnCell: React.FC<AiColumnCellProps> = ({ column, item }) => {
  const [_, setSelectedAiCell] = useWritableStore($selectedAiCell);
  const [loadingColumns] = useWritableStore($aiColumnsLoading);
  const currentShopId = useStoreValue($currentShopId);
  const updatedAiRows = useStoreValue($updatedAiRows);

  const [itemData, setItemData] = useState<Partial<AttributionData>>(item);
  const [hasCellAnimation, setHasCellAnimation] = useState(false);

  const sequenceIds = useMemo(() => {
    return column.sequences
      ?.filter(
        (s) =>
          (s.serviceId === itemData.serviceId || s.serviceId === 'all') &&
          (s.entity === getEntityByAnalyticsEntity(itemData.entity) || s.entity === 'all'),
      )
      ?.map((s) => s.sequenceId);
  }, [column, itemData]);

  const cellGotValue = !!itemData.id && updatedAiRows[itemData.id]?.[column.key];
  const isLoading =
    !!sequenceIds?.length && loadingColumns?.[column.key]?.[sequenceIds?.[0]] && !cellGotValue;

  const { columns } = useColumnsSocket({
    shopId: currentShopId || '',
    sequenceId: sequenceIds?.[0] || '',
  });

  useEffect(() => {
    if (cellGotValue && !hasCellAnimation) {
      setHasCellAnimation(true);
      // Reset animation flag after animation completes
      const timer = setTimeout(() => setHasCellAnimation(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [cellGotValue, hasCellAnimation]);

  useEffect(() => {
    setItemData(item);
  }, [item]);

  useEffect(() => {
    // on columns change, update the data in the relevant rows
    if (!Object.keys(columns).length) {
      return;
    }

    const valueOfThisItem = Object.values(columns).find((col) => {
      return col.id === itemData.id && col.entity === itemData.entity;
    });

    if (valueOfThisItem) {
      setItemData((oldData) => {
        const entity = oldData.entity;

        const campaignExists = Object.values(columns).find(
          (col) => col.id === oldData.id && col.entity === entity,
        );
        if (campaignExists) {
          return {
            ...oldData,
            ...columns[oldData.id!],
          };
        }
        return {
          ...oldData,
          adsets: oldData.adsets?.map((adset) => {
            const adsetExists = Object.values(columns).find(
              (col) => col.id === adset.id && col.entity === entity,
            );
            if (adsetExists) {
              return {
                ...adset,
                ...columns[adset.id!],
              };
            }
            return {
              ...adset,
              ads: adset.ads?.map((ad) => {
                const adExists = Object.values(columns).find(
                  (col) => col.id === ad.id && col.entity === entity,
                );
                if (adExists) {
                  return {
                    ...ad,
                    ...columns[ad.id!],
                  };
                }
                return ad;
              }),
            };
          }),
        };
      });
    }
  }, [columns, itemData, column.key]);

  if (!itemData) {
    return null;
  }

  const value = itemData[column.key]?.value;

  const reason = itemData[column.key]?.reason;
  const createdAt = itemData[column.key]?.created_at;
  const rawDataPath = itemData[column.key]?.raw_data_path;
  const hasValue = value !== null && value !== undefined;
  const retentionPeriodExpired = isRetentionPeriodExpired(column, createdAt);
  const isNumber = typeof value === 'number' && !isNaN(value);
  const color = column.possibleValues?.find((x) => x.value === value)?.color;
  const icon = column.possibleValues?.find((x) => x.value === value)?.icon;
  const isEmoji = !!icon && visibleLength(icon) > 1;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full px-4">
        <div
          className="font-bold text-transparent"
          style={{
            background: 'linear-gradient(to right, #0C70F2, silver, #0C70F2)',
            backgroundSize: '200% auto',
            backgroundPosition: '0% center',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            animation: 'gradient-flow 1s linear infinite',
          }}
        >
          AI is thinking...
        </div>
      </div>
    );
  }

  if (!hasValue || retentionPeriodExpired) {
    return (
      <div className="flex items-center justify-center px-4 h-full attribution-table-campaign-row">
        -
      </div>
    );
  }

  return (
    <div
      className={`px-4 flex items-center justify-center h-full gap-4 ${hasCellAnimation ? 'animate-pulse-in-gold-once' : ''}`}
      style={{ color: color || colors.one[5] }}
    >
      <Tooltip content={createdAt ? `Updated at: ${createdAt}` : ''} dismissOnMouseOut>
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={() => {
            if (!itemData?.id || !itemData?.entity) {
              return;
            }

            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.VIEW_COLUMN_AGENT_DATA,
              column_id: column.key,
              column_name: column.label,
              column_category: column.category,
              column_value: value,
            });

            setSelectedAiCell?.({
              id: itemData.id,
              entity: getEntityByAnalyticsEntity(itemData.entity),
              column: column.key,
              serviceId: itemData.serviceId,
              value: {
                data: value,
                reason: reason,
                createdAt: createdAt,
                rawDataPath: rawDataPath,
              },
            });
          }}
        >
          <div className="flex flex-col gap-2">
            {isNumber &&
              formatNumber(Number(value) || 0, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            {!isNumber && value}
          </div>
          {!!icon && isEmoji && <Icon name={icon as IconName} size={14} />}
          {!!icon && !isEmoji && <div className="text-md">{icon}</div>}
        </div>
      </Tooltip>
    </div>
  );
};
