import React, { useCallback, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import AttributionAllRoas from './AttributionAllRoas';
import { MetricsKeys } from 'types/metrics';
import { formatNumber } from 'utils/formatNumber';
import { metrics } from 'constants/metrics/metrics';
import AttributionOrders from './AttributionOrders';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';

type OrdersButtonProps = {
  item: AttributionData;
  metricKey: MetricsKeys;
  showOrdersButton?: boolean;
  showRoasButton?: boolean;
};

export const OrdersButton: React.FC<OrdersButtonProps> = ({
  item,
  metricKey,
  showOrdersButton,
  showRoasButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRoasOpen, setIsRoasOpen] = useState(false);
  const [loop, setLoop] = useState(false);
  const [play, setPlay] = useState(true);
  const [animationData, setAnimationData] = useState('/lotties/roas-cube-regular.json');
  const currency = useStoreValue($currency);

  const mouseEnter = useCallback(() => {
    setPlay(true);
    setLoop(true);
    setAnimationData('/lotties/roas-cube-hover.json');
  }, []);

  const mouseLeave = useCallback(() => {
    setPlay(false);
    setLoop(false);
    setAnimationData('/lotties/roas-cube-regular.json');
  }, []);

  const toggleRoasIsOpen = useCallback(() => {
    setIsRoasOpen((open) => !open);
  }, []);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const metric = metrics[metricKey]!;
  const value = formatNumber((item[metricKey] ?? item.metrics?.[metricKey]) || 0, {
    style: metric.format,
    currency,
    minimumFractionDigits:
      typeof metric.minimumFractionDigits !== 'undefined'
        ? metric.minimumFractionDigits
        : metric.toFixed,
    maximumFractionDigits: metric.toFixed,
  });

  return (
    <div className="roas-table-cell">
      {showRoasButton && (
        <div
          className="roas-lottie"
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          onClick={toggleRoasIsOpen}
        >
          <div className="roas-lottie-inner"></div>
          <Lottie
            loop={loop}
            play={play}
            path={animationData}
            style={{ width: 30, height: 30 }}
            rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
          />
        </div>
      )}
      <span
        key={`orders-button-${item.id}`}
        className={`${item.shouldHighlight ? 'highlight-realtime-attribution' : ''} ${
          showOrdersButton ? 'cursor-pointer text-[var(--mantine-color-one-5)]' : ''
        }`}
        onClick={() => {
          if (showOrdersButton) {
            toggleIsOpen();
          }
        }}
      >
        {value}
      </span>

      {isOpen && <AttributionOrders item={item} onClose={toggleIsOpen} metricKey={metricKey} />}
      {isRoasOpen && (
        <AttributionAllRoas item={item} onClose={toggleRoasIsOpen} metricKey={metricKey} />
      )}
    </div>
  );
};
