import React, { useCallback, useEffect, useMemo, useRef, useState, Suspense } from 'react';
import { chain } from 'lodash';
import { useAppSelector } from 'reducers/RootType';
import { WillyChart } from './WillyChart';
import { WillyTable } from './table/WillyTable';
import { WillySingleValue } from './WillySingleValue';
import {
  ChatSources,
  EditMetricModalPayload,
  WidgetQuery,
  MessageTypes,
  NlqResponse,
  WillyMetric,
  WillyParameter,
  GridOptions,
  GridColumnOptions,
  TileModes,
  AxisDomain,
  WidgetPermissions,
  CodeInterpreterResponse,
  FileWithData,
  NlqResponseDataType,
  WillyDashboardElement,
  CodeExecutionResponse,
  Dialect,
  RawNlqData,
  WillyWidgetElement,
  LoadDataProps,
  LoadPreviousPeriodDataProps,
  PaginationType,
  WillySort,
  WidgetChartLabel,
  WillyChartLayout,
  ChartType,
} from './types/willyTypes';
import { WillySimpleText } from './WillySimpleText';
import { getSocket, openAiStopSequence } from './WillySocket';
import _db from 'utils/DB';
import { WillyWidgetHeader } from './WidgetHeader/WillyWidgetHeader';
import { WillyJson } from './WillyJson';
import {
  breakDownRowDataWithoutTotal,
  cleanQuestion,
  convertDataToJson,
  createWillyMetricsFromRawData,
  emptyArray,
  executeRce,
  fetchQueryBuilderData,
  fetchWidgetQueries,
  keyIsEntityId,
  keyIsEntityName,
  keyIsService,
  moveTotalToEnd,
  parseCodeSnippet,
  reorderPreviousDataByKeys,
  totalRowData,
} from './utils/willyUtils';
import { WillyDynamicFields } from './WillyDynamicFields';
import { WillySingleValueChart } from './WillySingleValueChart';
import lazyWithRetries from 'utils/lazyWithRetries';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { Flex, Text } from '@tw/ui-components';
import { ServicesIds } from '@tw/types/module/services';
import { useFeatureFlagValue } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import LockedFeatureIndicator from '../library/LockedFeatures/LockedFeatureIndicator';
import { WillyWidgetEditor } from './WillyWidgetEditor/WillyWidgetEditor';
import { $currentDateRange, $granularity, $prevDateRange } from '../../$stores/willy/$dateRange';
import { $activeAccounts } from '../../$stores/$shop';
import { WillyEmptyMarkup } from './WillyEmptyMarkup';
import { WillyMetricCustomComponent, isCustomeComponentMetric } from './WillyMetricCustomComponent';
import { useStoreValue } from '@tw/snipestate';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { MAX_ITEMS_PER_PAGE } from './constants';
import { LabelPosition } from 'recharts/types/component/Label';
import { WillyCards } from './WillyCard';
import moment from 'moment-timezone';
import { WillySankey } from './WillySankey';
import {
  FilterProperty,
  InsightsFilter,
  InsightsFilterQuery,
} from '@tw/types/module/services/insights';
import { WillyCustomFilters } from './WillyCustomFilters/WillyCustomFilters';
import { $tables } from '$stores/willy/$tables';

const WillyMap = lazyWithRetries(() => import('./WillyMap'));

const DATE_FORMAT = 'YYYY-MM-DD';

export type WillyWidgetProps = {
  context: ChatSources;
  queryId?: string;
  withoutMainQuery?: boolean;
  dashboard?: WillyDashboardElement;
  isGlobalDashboard?: boolean;
  isCustomView?: boolean;
  type: MessageTypes;
  metrics: WillyMetric[];
  parameters?: WillyParameter[];
  queries?: WidgetQuery[];
  stacked: boolean;
  permission: WidgetPermissions;
  incrementedStacked: boolean;
  title: string;
  wrapText?: boolean;
  initialRawData?: NlqResponse;
  initialCodeInterpreterData?: FileWithData[];
  initialError?: Record<string, string>;
  initialWarning?: Record<string, string>;
  verified?: boolean;
  loadInitialData?: boolean;
  setWrapText: (v: boolean) => void;
  parametersChanged?: (v: WillyParameter[]) => void;
  stackedChanged: (v: boolean) => void;
  permissionChanged: (v: ServicesIds[]) => void;
  incrementedStackedChanged: (v: boolean) => void;
  builderSetupChanged: (v: BuilderTable) => Promise<void>;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  typeChanged: (v: MessageTypes) => void;
  titleChanged: (v: string) => void;
  queriesChanged: (queries: WidgetQuery[]) => Promise<void>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  isDynamic?: boolean;
  question?: string;
  currency: string;
  shouldReplaceTablesWithNlq?: boolean;
  suppressMetricClick?: boolean;
  grid: GridOptions;
  setGrid: (v: GridOptions) => void;
  gridColumns: GridColumnOptions;
  setGridColumns: (v: GridColumnOptions) => void;
  twoColumnMobile: boolean;
  setTwoColumnMobile: (v: boolean) => void;
  tileMode: TileModes;
  setTileMode: (v: TileModes) => void;
  skinny: boolean;
  setSkinny: (v: boolean) => void;
  yAxisDomain: AxisDomain;
  setYAxisDomain: (domain: AxisDomain) => void;
  allowDataOverflow: boolean;
  setAllowDataOverflow: (v: boolean) => void;
  dimension: string;
  setDimension: (v: string) => void;
  secondaryToolbarOpen?: boolean;
  queryVariablesForPopup?: Record<string, any>;
  errorMetricsData?: Record<string, string>;
  loadingMetricsData?: Record<string, { current: boolean; previous: boolean }>;
  pinnedSection?: boolean;
  pinnedMetricKeys?: string[];
  setHidePinnedSection?: (v: boolean) => void;
  CDPSegmentId?: string;
  hasGlobalConditionalFormatting: boolean;
  globalConditionalFormattingColor: string;
  setHasGlobalConditionalFormatting: (v: boolean) => void;
  setGlobalConditionalFormattingColor: (v: string) => void;
  breakdownMode?: boolean;
  breakdownModeChanged: (breakdown: boolean) => Promise<void>;
  isDnd?: boolean;
  editLayout?: boolean;
  inWidgetEditor?: boolean;
  codeResult?: CodeInterpreterResponse;
  codeResultChanged?: (v: CodeExecutionResponse) => Promise<void>;
  initialShowPreviousPeriod?: boolean;
  initialPreviousPeriodRawData?: NlqResponse;
  loadInitialPreviousPeriodData?: boolean;
  updateDashWidgetData?: (id: string, data: NlqResponse) => void;
  dataType?: NlqResponseDataType;
  functionName?: string;
  openChatWithQuery?: (widgetSourceId?: string) => void;
  openSqlWithWidget?: (widgetSourceId?: string) => void;
  historicalQueryIds?: string[];
  dialect: Dialect;
  contextSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  mode?: WillyWidgetElement['mode'];
  builderSetup?: BuilderTable;
  filtersOpen: boolean;
  setFiltersOpen: (v: boolean) => void;
  inMobileDrawer?: boolean;
  paginationType?: PaginationType;
  chartLabel?: WidgetChartLabel;
  setChartLabel?: (v: WidgetChartLabel) => void;
  rightYAxisLabel?: string;
  setRightYAxisLabel?: (value: string) => void;
  leftYAxisLabel?: string;
  setLeftYAxisLabel?: (value: string) => void;
  xAxisLabel?: string;
  setXAxisLabel?: (value: string) => void;
  isSyncCharts: boolean;
  chartLayout?: WillyChartLayout;
  setChartLayout?: (layout: WillyChartLayout) => void;
  showQuickEditing?: boolean;
  setShowQuickEditing?: (v: boolean) => void;
  onResizeReorder?: () => void;
  customFilters?: InsightsFilterQuery[];
  eventLoggerProps?: object;
  forcedChartType?: ChartType;
  viewOnly?: boolean;
};

export const WillyWidget: React.FC<WillyWidgetProps> = React.memo(
  ({
    context,
    queryId,
    functionName,
    withoutMainQuery,
    isDynamic,
    shouldReplaceTablesWithNlq,
    type,
    title,
    metrics,
    queries,
    stacked = false,
    verified = false,
    isGlobalDashboard = false,
    isCustomView = false,
    incrementedStacked = false,
    question = '',
    initialRawData,
    initialCodeInterpreterData,
    initialError,
    initialWarning,
    parameters,
    wrapText,
    currency,
    suppressMetricClick,
    stackedChanged,
    permissionChanged,
    parametersChanged,
    incrementedStackedChanged,
    builderSetupChanged,
    metricsChanged,
    typeChanged,
    titleChanged,
    queriesChanged,
    setEditMetricModalOpen,
    setWrapText,
    dashboard,
    loadInitialData,
    grid = 'flex',
    setGrid = () => {},
    gridColumns = 2,
    setGridColumns = () => {},
    twoColumnMobile = false,
    setTwoColumnMobile = () => {},
    tileMode = 'tile',
    setTileMode = () => {},
    skinny = false,
    setSkinny = () => {},
    yAxisDomain,
    setYAxisDomain,
    allowDataOverflow,
    setAllowDataOverflow,
    dimension,
    permission,
    setDimension,
    secondaryToolbarOpen = false,
    queryVariablesForPopup,
    errorMetricsData,
    loadingMetricsData,
    pinnedSection,
    pinnedMetricKeys,
    setHidePinnedSection,
    CDPSegmentId,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    setHasGlobalConditionalFormatting,
    setGlobalConditionalFormattingColor,
    breakdownMode,
    breakdownModeChanged,
    isDnd = false,
    editLayout = false,
    inWidgetEditor,
    codeResult,
    codeResultChanged,
    updateDashWidgetData,
    initialShowPreviousPeriod,
    initialPreviousPeriodRawData,
    loadInitialPreviousPeriodData,
    dataType,
    openChatWithQuery,
    openSqlWithWidget,
    historicalQueryIds,
    dialect,
    contextSourceIds,
    mode,
    builderSetup,
    filtersOpen,
    setFiltersOpen,
    inMobileDrawer,
    paginationType = 'server',
    chartLabel,
    setChartLabel,
    leftYAxisLabel,
    rightYAxisLabel,
    xAxisLabel,
    isSyncCharts,
    setLeftYAxisLabel,
    setRightYAxisLabel,
    setXAxisLabel,
    chartLayout,
    setChartLayout,
    showQuickEditing,
    setShowQuickEditing,
    onResizeReorder,
    eventLoggerProps,
    forcedChartType,
    viewOnly,
  }) => {
    const generateInsightsStartedRef = useRef(false);
    const widgetContainerRef = useRef<HTMLDivElement>(null);
    const clickedOnPage1 = useRef(false);
    const currentShopId = useAppSelector((state) => state.currentShopId);
    const blockedIntegrations = useFeatureFlagValue(FeatureFlag.LIMIT_INTEGRATIONS_FF, 'blockList');
    const prevDateRange = useStoreValue($prevDateRange);
    const currentDateRange = useStoreValue($currentDateRange);
    const activeAccounts = useStoreValue($activeAccounts);
    const granularity = useStoreValue($granularity);
    const allTables = useStoreValue($tables);

    const [rawData, setRawData] = useState<NlqResponse>();
    const [textData, setTextData] = useState<string>('');
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState<{ column: string; sort: WillySort | null }>();
    const [previousPeriodRawData, setPreviousPeriodRawData] = useState<NlqResponse>();
    const [loading, setLoading] = useState(!!loadInitialData);
    const [loadingPreviousPeriod, setLoadingPreviousPeriod] = useState(false);
    const [loadingText, setLoadingText] = useState(false);
    const [defaultMetrics, setDefaultMetrics] = useState<WillyMetric[]>(metrics || []);
    const [showPreviousPeriod, setShowPreviousPeriod] = useState(false);
    const [singleValuePopupOpened, setSingleValuePopupOpened] = useState(false);
    const [activeMetric, setActiveMetric] = useState<WillyMetric>();
    const [activeMetricData, setActiveMetricData] = useState<Record<string, any>>();
    const [errorInQuery, setErrorInQuery] = useState<Record<string, string>>(initialError || {});
    const [warningInQuery, setWarningInQuery] = useState<Record<string, string>>(
      initialWarning || {},
    );
    const [codeInterpreterInputData, setCodeInterpreterInputData] = useState<string>();
    const [widgetEditorOpen, setWidgetEditorOpen] = useState(false);
    const [metricLibraryOpened, setMetricLibraryOpened] = useState(false);
    const [activeMetricCustomComponent, setActiveMetricCustomComponent] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<number>();
    const [customFilters, setCustomFilters] = useState<Partial<InsightsFilter>[]>();

    const memoizedParameters = useDeepCompareMemoize(parameters || emptyArray<WillyParameter>());
    const memoizedMetrics = useDeepCompareMemoize(defaultMetrics || emptyArray<WillyMetric>());

    useEffect(() => {
      if (orderBy) {
        return;
      }
      const sort = defaultMetrics.find((m) => !!m.sort);
      if (sort) {
        setOrderBy({ column: sort.key, sort: sort.sort || null });
      }
    }, [defaultMetrics, orderBy]);

    const canEdit = useMemo(
      () => (context === 'dashboard' ? !!dashboard?.canEdit : true),
      [context, dashboard?.canEdit],
    );

    const defaultOrderBy = useMemo(() => {
      const m = memoizedMetrics.find((m) => m.sort);
      if (!m) {
        return null;
      }
      const column = m.key;
      const sort = m.sort ?? null;
      return { sort, column };
    }, [memoizedMetrics]);

    const rawNlqData = useMemo(() => {
      const withTotalAtTheEnd = moveTotalToEnd(rawData?.data || emptyArray<RawNlqData[number]>());
      return withTotalAtTheEnd;
    }, [rawData?.data]);

    const willySocket = useMemo(() => getSocket(), []);

    const isBlocked = useMemo(() => {
      if (!blockedIntegrations.length) {
        return false;
      }
      return permission.providers.some((x) => blockedIntegrations.includes(x));
    }, [blockedIntegrations, permission]);

    const reorderedPreviousPeriodData = useMemo(() => {
      if (!prevDateRange) {
        return undefined;
      }
      if (!previousPeriodRawData?.data) {
        return previousPeriodRawData;
      }
      if (!rawData?.dataColumns?.x) {
        return previousPeriodRawData;
      }

      const reorderedData = reorderPreviousDataByKeys(
        rawNlqData,
        previousPeriodRawData.data,
        rawData.dataColumns.x,
      );

      return {
        ...previousPeriodRawData,
        data: reorderedData,
      };
    }, [previousPeriodRawData, rawNlqData, rawData?.dataColumns?.x, prevDateRange]);

    const previousPeriodRawNlqData = useMemo(() => {
      return reorderedPreviousPeriodData?.data || emptyArray<RawNlqData[number]>();
    }, [reorderedPreviousPeriodData?.data]);

    const memoizedQueries = useDeepCompareMemoize(queries);

    const queriesToFetch = useMemo(() => {
      let queries = (memoizedQueries || []).map((x) => x.id);
      if (withoutMainQuery) {
        queries = queries.filter((x) => x !== queryId);
      } else {
        queries = queries.concat([queryId || '']);
      }

      return queries;
    }, [memoizedQueries, queryId, withoutMainQuery]);

    const mainQuery = useMemo(() => {
      const q = queries?.find((x) => x.id === queryId);
      return q?.query;
    }, [queries, queryId]);

    const memoizedQueriesToFetch = useDeepCompareMemoize(queriesToFetch);

    const rawDataColumns = useMemo(() => {
      return rawData?.dataColumns || { x: [], y: [] };
    }, [rawData?.dataColumns]);

    const totalValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }

      const data = rawNlqData;

      if (!data) {
        return undefined;
      }
      return totalRowData(data, activeMetric.key);
    }, [rawNlqData, activeMetric?.key]);

    const breakdownValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = rawNlqData;
      if (!data) {
        return undefined;
      }
      return breakDownRowDataWithoutTotal(data, activeMetric.key);
    }, [rawNlqData, activeMetric?.key]);

    const previousPeriodTotalValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = reorderedPreviousPeriodData?.data;

      if (!data) {
        return undefined;
      }
      return totalRowData(data, activeMetric.key);
    }, [reorderedPreviousPeriodData?.data, activeMetric?.key]);

    const previousPeriodBreakDownValue = useMemo(() => {
      if (!activeMetric?.key) {
        return undefined;
      }
      const data = reorderedPreviousPeriodData?.data;

      if (!data) {
        return undefined;
      }

      return breakDownRowDataWithoutTotal(data, activeMetric.key);
    }, [reorderedPreviousPeriodData?.data, activeMetric?.key]);

    const widgetMainResource = useMemo(() => {
      if (defaultMetrics?.find(({ key }) => key === 'customer_id')) {
        return 'customers';
      }
      return 'unknown';
    }, [defaultMetrics]);

    const dataHasBreakdown = useMemo(() => {
      if (!defaultMetrics.length) {
        return false;
      }

      const hasProvider = defaultMetrics.some((m) => keyIsService(m.key));
      const hasEntityId = defaultMetrics.some((m) => keyIsEntityId(m.key));
      const hasEntityName = defaultMetrics.some((m) => keyIsEntityName(undefined, m.key));

      return hasProvider && hasEntityId && hasEntityName;
    }, [defaultMetrics]);

    const convertRawDataToJson = useCallback(
      ({
        responses,
        limit = Infinity,
        keepOriginalQuestion = true,
      }: {
        responses: NlqResponse[];
        limit?: number;
        keepOriginalQuestion?: boolean;
      }) => {
        const asObject = responses.map((response, i) => {
          return {
            file_name: keepOriginalQuestion
              ? response.question
              : `${cleanQuestion(response.question!)}_${i}.json`,
            data: convertDataToJson(response.data).slice(0, limit),
          };
        }, {});

        const stringifiedData = JSON.stringify(asObject, null, 2);

        return stringifiedData;
      },
      [],
    );

    const onMetricsChanged = useCallback(
      async (id: string, metrics: WillyMetric[]) => {
        setDefaultMetrics(metrics);
        await metricsChanged(id, metrics);
      },
      [metricsChanged],
    );

    const closeSingleValuePopup = useCallback(() => {
      setSingleValuePopupOpened(false);
    }, []);

    const metricClicked = useCallback(
      (metric: WillyMetric, metricValues?: Record<string, any>) => {
        if (suppressMetricClick) {
          return;
        }
        if (isCustomeComponentMetric(metric.functionName)) {
          setActiveMetricCustomComponent(
            <WillyMetricCustomComponent
              data={metricValues || {}}
              customComponent={metric.functionName}
              onClose={() => setActiveMetricCustomComponent(null)}
            />,
          );
          return;
        }

        setActiveMetric(metric);
        setSingleValuePopupOpened(true);

        setActiveMetricData(metricValues);
      },
      [suppressMetricClick],
    );

    useEffect(() => {
      const activeDefaultMetric = defaultMetrics?.find((m) => m.key === activeMetric?.key);
      if (activeDefaultMetric) {
        setActiveMetric(activeDefaultMetric);
      }
    }, [defaultMetrics, activeMetric?.key]);

    useEffect(() => {
      let defaultMetrics: WillyMetric[] = metrics;

      const hasNoDataFromBackend = !rawNlqData?.length;

      if (hasNoDataFromBackend) {
        defaultMetrics = metrics;
      } else {
        const calculated = createWillyMetricsFromRawData({
          data: rawNlqData,
          sqlQuery: mainQuery || '',
          initialMetrics: metrics,
          servicesIds: rawData?.serviceIds || [],
          dataType: dataType,
          visualizationType: rawData?.visualizationType,
        });
        defaultMetrics = calculated;
      }
      setDefaultMetrics(defaultMetrics);
    }, [rawNlqData, mainQuery, rawData?.serviceIds, metrics, dataType, rawData?.visualizationType]);

    useEffect(() => {
      if (initialRawData) {
        setRawData(initialRawData);
        setTotalCount(initialRawData?.twTotalCount);
        updateDashWidgetData?.(queryId!, initialRawData);
      }
    }, [initialRawData, queryId, updateDashWidgetData]);

    useEffect(() => {
      if (initialError && Object.keys(initialError).length) {
        setErrorInQuery(initialError);
      }
    }, [initialError]);

    useEffect(() => {
      if (initialWarning) {
        setWarningInQuery(initialWarning);
      }
    }, [initialWarning]);

    useEffect(() => {
      setShowPreviousPeriod(initialShowPreviousPeriod ?? false);
    }, [initialShowPreviousPeriod]);

    useEffect(() => {
      if (!initialCodeInterpreterData) {
        return;
      }
      const dataWithoutQuery = initialCodeInterpreterData.map((d) => {
        return {
          file_name: d.file_name,
          data: d.data,
        };
      });
      const stringifiedData = JSON.stringify(dataWithoutQuery, null, 2);
      setCodeInterpreterInputData(stringifiedData);
    }, [initialCodeInterpreterData]);

    const deselectedMetrics = useMemo(() => {
      if (!defaultMetrics) {
        return [];
      }
      return defaultMetrics
        .filter((m) => m.active === false)
        .filter((m) => !m.isDimension)
        .map((m) => m.key)
        .sort();
    }, [defaultMetrics]);

    const memoizedDeselectedMetrics = useDeepCompareMemoize(deselectedMetrics);

    const currentIsToday = useMemo(() => {
      return (
        currentDateRange?.start.isSame(moment(), 'day') &&
        currentDateRange?.end.isSame(moment(), 'day')
      );
    }, [currentDateRange]);

    const pageSize = useMemo(() => {
      if (type === 'table') {
        return MAX_ITEMS_PER_PAGE;
      } else if (type === 'card') {
        return 12;
      }
      return undefined;
    }, [type]);

    const loadData = useCallback(
      async (props: LoadDataProps) => {
        const { abortSignal, forceReload = false, parameters, pageToFetch = 1 } = props;
        if (initialRawData && !forceReload) {
          return;
        }
        if (loadInitialData) {
          return;
        }
        if (!currentDateRange) {
          return;
        }

        if (!activeAccounts) {
          return;
        }
        // if changing other parameters like datepicker should reset page to 1
        if (pageToFetch === 1) {
          setPage(pageToFetch);
        }
        setLoading(true);

        generateInsightsStartedRef.current = false;
        setTextData('');

        let { start, end } = currentDateRange;
        try {
          let nlqResponses: NlqResponse[] = [];
          if (mode === 'builder' && builderSetup) {
            const shouldAddDate = !builderSetup.filters.some(
              (f) => f.column === 'event_date' && f.value,
            );
            const builderData: BuilderTable = {
              ...builderSetup,
              filters: [
                ...builderSetup.filters.filter((f) => f.visible || f.visibleInDashboard),
                ...(shouldAddDate
                  ? [
                      {
                        column: 'event_date',
                        operator: 'between',
                        value: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
                      } as any,
                    ]
                  : []),
              ],
              customFilters: customFilters?.map((f) => f.query as InsightsFilterQuery),
            };
            let res = await fetchQueryBuilderData(
              {
                ...builderData,
                page: pageToFetch,
                perPage: pageSize,
                orderBy: orderBy?.column
                  ? [
                      {
                        columnId: orderBy.column ?? defaultOrderBy?.column,
                        direction: orderBy.sort === 'desc' ? 'DESC' : 'ASC',
                      },
                    ]
                  : [],
              },
              {
                shopId: currentShopId,
                additionalShopIds: activeAccounts,
              },
            );
            res = {
              ...res,
              queries: [
                {
                  id: queryId || '',
                  query: res.generatedQuery || '',
                  question: question,
                },
              ],
            };
            nlqResponses = [res];
          } else {
            nlqResponses = await fetchWidgetQueries({
              abortSignal,
              queryIds: memoizedQueriesToFetch,
              functionName,
              parameters: (parameters || memoizedParameters).filter((p) => !p.isQueryParameter),
              shopId: currentShopId,
              additionalShopIds: activeAccounts,
              start,
              end,
              currency,
              isDynamic,
              shouldReplaceTablesWithNlq,
              queryVars: queryVariablesForPopup,
              granularity,
              deselectedMetrics: memoizedDeselectedMetrics,
              queryParameters: memoizedParameters,
              dataType,
              page: pageToFetch,
              pageSize,
              orderBy: orderBy?.column ?? defaultOrderBy?.column,
              orderDirection: orderBy?.sort ?? defaultOrderBy?.sort ?? null,
              dashboardId: dashboard?.id,
              dashboardName: dashboard?.name,
            });
          }

          const errors = nlqResponses.filter((x) => !!x.error);
          const warnings = nlqResponses.filter((x) => !!x.warning);

          if (errors?.length > 0) {
            const newErrors = errors.reduce((acc, e) => {
              if (!e.queryId) {
                return acc;
              }
              return {
                ...acc,
                [e.queryId]: e.error,
              };
            }, {});
            setErrorInQuery((prev) => {
              return {
                ...prev,
                ...newErrors,
              };
            });
          } else {
            setErrorInQuery((prev) => {
              return {
                ...prev,
                [queryId || '']: '',
              };
            });
          }

          if (warnings?.length > 0) {
            const newWarnings = warnings.reduce((acc, w) => {
              if (!w.queryId) {
                return acc;
              }
              return {
                ...acc,
                [w.queryId]: w.warning,
              };
            }, {});
            setWarningInQuery((prev) => {
              return {
                ...prev,
                ...newWarnings,
              };
            });
          } else {
            setWarningInQuery((prev) => {
              return {
                ...prev,
                [queryId || '']: '',
              };
            });
          }

          if (codeResult?.pythonCode) {
            const stringifiedData = convertRawDataToJson({ responses: nlqResponses });
            const stringifiedSampleData = convertRawDataToJson({
              responses: nlqResponses,
              limit: 30,
              keepOriginalQuestion: false,
            });
            setCodeInterpreterInputData(stringifiedSampleData);

            const codeRes = await executeRce(
              abortSignal,
              parseCodeSnippet(codeResult.pythonCode),
              stringifiedData,
            );

            codeResultChanged?.(codeRes);

            const { filesOutput, executionError, output, dataFiles } = codeRes;
            if (executionError) {
              setErrorInQuery((prev) => {
                return {
                  ...prev,
                  [queryId || '']: executionError,
                };
              });
            }

            setRawData(filesOutput);
            setTotalCount(filesOutput?.twTotalCount);
            if (filesOutput) {
              updateDashWidgetData?.(queryId!, filesOutput);
            }
          } else {
            setRawData(nlqResponses[0]);
            setTotalCount(nlqResponses[0]?.twTotalCount);
            updateDashWidgetData?.(queryId!, nlqResponses[0]);
          }
          setLoading(false);
        } catch (error: any) {
          console.error('error', error);
          if (error.code !== 'ERR_CANCELED') {
            setLoading(false);
            updateDashWidgetData?.(queryId!, {
              question: question,
              error,
              data: [],
              dataColumns: { x: [], y: [] },
            });
            setErrorInQuery((prev) => {
              return {
                ...prev,
                [queryId || '']:
                  'We met an error while fetching the data, please contact support team.',
              };
            });
          }
        }
      },
      [
        memoizedQueriesToFetch,
        functionName,
        shouldReplaceTablesWithNlq,
        currency,
        currentShopId,
        queryId,
        currentDateRange,
        isDynamic,
        initialRawData,
        loadInitialData,
        memoizedParameters,
        activeAccounts,
        queryVariablesForPopup,
        mode,
        builderSetup,
        memoizedDeselectedMetrics,
        codeResult?.pythonCode,
        dataType,
        question,
        granularity,
        codeResultChanged,
        convertRawDataToJson,
        updateDashWidgetData,
        defaultOrderBy?.column,
        defaultOrderBy?.sort,
        orderBy,
        pageSize,
        customFilters,
        dashboard?.id,
        dashboard?.name,
      ],
    );

    const handlePageChange = useCallback((page: number) => {
      if (page === 1) {
        clickedOnPage1.current = true;
      }
      setPage(page);
    }, []);

    useEffect(() => {
      setLoading(!!loadInitialData);
    }, [loadInitialData]);

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      loadData({ abortSignal: signal });

      return () => {
        controller.abort();
      };
    }, [loadData]);

    useEffect(() => {
      if (initialPreviousPeriodRawData) {
        setPreviousPeriodRawData(initialPreviousPeriodRawData);
      }
    }, [initialPreviousPeriodRawData]);

    const loadPreviousPeriodData = useCallback(
      async (props: LoadPreviousPeriodDataProps) => {
        const { abortSignal, qId, forceReload = false, parameters, pageToFetch = 1 } = props;
        if (loadInitialData) {
          return;
        }
        if (initialRawData && !forceReload) {
          return;
        }
        if (initialPreviousPeriodRawData || loadInitialPreviousPeriodData) {
          return;
        }
        if (!queryId) {
          return;
        }
        if (!currentShopId) {
          return;
        }
        if (!isDynamic) {
          return;
        }
        if (!activeAccounts) {
          return;
        }

        const { start, end } = prevDateRange ?? {};

        if (!end || !start) {
          return;
        }

        setLoadingPreviousPeriod(true);
        let queriesToFetch = memoizedQueriesToFetch;
        if (qId) {
          queriesToFetch = [...queriesToFetch, qId];
        }

        let nlqResponses: NlqResponse[] = [];

        if (mode === 'builder' && builderSetup) {
          const shouldAddDate = !builderSetup.filters.some(
            (f) => f.column === 'event_date' && f.value,
          );

          const builderData: BuilderTable = {
            ...builderSetup,
            filters: [
              ...builderSetup.filters,
              ...(shouldAddDate
                ? [
                    {
                      column: 'event_date',
                      operator: 'between',
                      value: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
                    } as any,
                  ]
                : []),
            ],
            customFilters: customFilters?.map((f) => f.query as InsightsFilterQuery),
          };
          const res = await fetchQueryBuilderData(builderData, {
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
          });
          nlqResponses = [res];
        } else {
          nlqResponses = await fetchWidgetQueries({
            abortSignal,
            queryIds: queriesToFetch,
            parameters: (parameters || memoizedParameters).filter((p) => !p.isQueryParameter),
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
            start,
            end,
            currency,
            isDynamic,
            isPreviousPeriod: true,
            shouldReplaceTablesWithNlq,
            queryVars: queryVariablesForPopup,
            granularity,
            deselectedMetrics: memoizedDeselectedMetrics,
            // metrics: memoizedSelectedMetrics,
            // selectedCustomMetrics: memoizedSelectedCustomMetrics,
            queryParameters: memoizedParameters,
            dataType,
            page: pageToFetch,
            pageSize,
            orderBy: orderBy?.column ?? defaultOrderBy?.column,
            orderDirection: orderBy?.sort ?? defaultOrderBy?.sort ?? null,
            currentIsToday: currentIsToday,
            dashboardId: dashboard?.id,
            dashboardName: dashboard?.name,
          });
        }

        setLoadingPreviousPeriod(false);

        if (codeResult?.pythonCode) {
          const stringifiedData = convertRawDataToJson({ responses: nlqResponses });
          const stringifiedSampleData = convertRawDataToJson({
            responses: nlqResponses,
            limit: 30,
            keepOriginalQuestion: false,
          });
          setCodeInterpreterInputData(stringifiedSampleData);

          const codeRes = await executeRce(
            abortSignal,
            parseCodeSnippet(codeResult.pythonCode),
            stringifiedData,
          );

          const { filesOutput } = codeRes;
          setPreviousPeriodRawData(filesOutput);
          return nlqResponses[0];
        } else {
          setPreviousPeriodRawData(nlqResponses[0]);
          return nlqResponses[0];
        }
      },
      [
        memoizedQueriesToFetch,
        shouldReplaceTablesWithNlq,
        currency,
        initialRawData,
        memoizedParameters,
        currentShopId,
        prevDateRange,
        queryId,
        isDynamic,
        activeAccounts,
        loadInitialData,
        queryVariablesForPopup,
        mode,
        builderSetup,
        memoizedDeselectedMetrics,
        initialPreviousPeriodRawData,
        loadInitialPreviousPeriodData,
        dataType,
        codeResult?.pythonCode,
        granularity,
        convertRawDataToJson,
        orderBy,
        defaultOrderBy?.column,
        defaultOrderBy?.sort,
        currentIsToday,
        pageSize,
        customFilters,
        dashboard?.id,
        dashboard?.name,
      ],
    );

    useEffect(() => {
      if (page === 1 && !clickedOnPage1.current) {
        return;
      }
      // reset the flag
      clickedOnPage1.current = false;
      const controller = new AbortController();
      const prevController = new AbortController();
      const signal = controller.signal;
      const prevSignal = prevController.signal;
      loadData({ abortSignal: signal, pageToFetch: page, forceReload: true });
      loadPreviousPeriodData({ abortSignal: prevSignal, pageToFetch: page, forceReload: true });
      return () => {
        controller.abort();
        prevController.abort();
      };
    }, [page, loadData, loadPreviousPeriodData]);

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      loadPreviousPeriodData({ abortSignal: signal });

      return () => {
        controller.abort();
      };
    }, [loadPreviousPeriodData]);

    useEffect(() => {
      const acceptInsights = (msg: any) => {
        const { shopId, messageId, text } = msg;
        if (text === openAiStopSequence || messageId !== queryId || shopId !== currentShopId) {
          return;
        }

        setLoadingText(false);

        setTextData((oldText) => {
          if (!oldText) {
            return text;
          }
          return oldText + text;
        });
      };

      const socketIo = willySocket?.on('insight', acceptInsights);
      return () => {
        socketIo?.off('insight', acceptInsights);
      };
    }, [willySocket, currentShopId, queryId]);

    const containerClasses = useMemo(() => {
      const classes = [
        `flex flex-col flex-auto h-full max-w-full group dark:bg-transparent bg-white`,
      ];

      if (context !== 'chat') {
        classes.push('flex-shrink-0');
      }

      if (!loading) {
        classes.push('loaded');
      }

      if (type === 'table') {
        if (context !== 'chat') {
          classes.push('overflow-auto w-full h-auto');
        } else {
          classes.push('overflow-hidden w-full h-full');
        }
      } else {
        classes.push('overflow-auto');
      }

      if (context === 'summary') {
        if (type === 'pie') {
          classes.push('!h-[300px]');
        } else if (type == 'chart') {
          classes.push('!h-[400px]');
        } else {
          classes.push('!rounded shadow overflow-hidden');
        }
      } else if (context !== 'editor') {
        classes.push('sm:rounded shadow');
      }

      if (isDnd) {
        classes.push('!overflow-hidden');
      }

      return classes.join(' ');
    }, [context, isDnd, loading, type]);

    const visibleParameters = useMemo(() => {
      if (builderSetup) {
        return builderSetup.filters;
      }
      return memoizedParameters;
    }, [memoizedParameters, builderSetup]);

    const availableCustomFilters: FilterProperty[] = useMemo(() => {
      if (!builderSetup) return [];

      return chain(allTables)
        .filter((t) => [builderSetup.table].includes(t.id))
        .flatMap((t) => t['customFiltersProperties'] ?? []) //TODO: add to type
        .uniq()
        .value();
    }, [builderSetup, allTables]);

    return (
      <div ref={widgetContainerRef} className={containerClasses}>
        <div
          className={`pb-0 transition-[padding] min-h-full shrink-0 ${type === 'table' ? 'h-full' : ''}`}
        >
          <div
            className={`
                w-full sticky top-0 flex flex-col justify-center z-[110] overflow-auto 
                flex-shrink-0 min-w-[150px] px-4 
                border-0 border-solid border-b-[1px] border-black/10 bg-white
              `}
          >
            <WillyWidgetHeader
              title={title}
              loading={loading}
              loadingPreviousPeriod={loadingPreviousPeriod}
              type={type}
              currency={currency}
              queryId={queryId}
              dashboard={dashboard}
              withoutMainQuery={withoutMainQuery}
              isGlobalDashboard={isGlobalDashboard}
              isCustomView={isCustomView}
              serviceIds={rawData?.serviceIds}
              parameters={parameters}
              isDynamic={isDynamic}
              rawData={rawData!}
              titleChanged={titleChanged}
              queriesChanged={queriesChanged}
              typeChanged={typeChanged}
              metrics={defaultMetrics}
              queries={queries}
              stacked={stacked}
              stackedChanged={stackedChanged}
              incrementedStacked={incrementedStacked}
              permissionChanged={permissionChanged}
              metricsChanged={onMetricsChanged}
              incrementedStackedChanged={incrementedStackedChanged}
              wrapText={wrapText}
              context={context}
              grid={grid}
              setGrid={setGrid}
              twoColumnMobile={twoColumnMobile}
              setTwoColumnMobile={setTwoColumnMobile}
              gridColumns={gridColumns}
              setGridColumns={setGridColumns}
              skinny={skinny}
              setSkinny={setSkinny}
              yAxisDomain={yAxisDomain}
              setYAxisDomain={setYAxisDomain}
              allowDataOverflow={allowDataOverflow}
              setAllowDataOverflow={setAllowDataOverflow}
              dimension={dimension}
              secondaryToolbarOpen={secondaryToolbarOpen}
              permission={permission}
              pinnedSection={pinnedSection}
              setHidePinnedSection={setHidePinnedSection}
              errorInQuery={errorInQuery}
              warningInQuery={warningInQuery}
              widgetMainResource={widgetMainResource}
              CDPSegmentId={CDPSegmentId}
              hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
              setHasGlobalConditionalFormatting={setHasGlobalConditionalFormatting}
              globalConditionalFormattingColor={globalConditionalFormattingColor}
              setGlobalConditionalFormattingColor={setGlobalConditionalFormattingColor}
              breakdownMode={breakdownMode}
              isDnd={isDnd}
              editLayout={editLayout}
              setWidgetEditorOpen={setWidgetEditorOpen}
              inWidgetEditor={inWidgetEditor}
              codeResult={codeResult}
              codeInterpreterInputData={codeInterpreterInputData}
              codeResultChanged={codeResultChanged}
              dataType={dataType}
              historicalQueryIds={historicalQueryIds}
              dialect={dialect}
              contextSourceIds={contextSourceIds}
              showQuickEditing={showQuickEditing}
              setShowQuickEditing={setShowQuickEditing}
              mode={mode}
              showFilters={filtersOpen}
              setShowFilters={(show) => setFiltersOpen(show)}
              inMobileDrawer={inMobileDrawer}
              chartLayout={chartLayout}
              setChartLayout={setChartLayout}
              orderBy={orderBy}
              onResizeReorder={onResizeReorder}
              customFiltersAvailable={availableCustomFilters.length > 0}
              appliedCustomFilters={customFilters?.length}
              viewOnly={viewOnly}
            />
          </div>

          {pinnedSection && !pinnedMetricKeys?.length && (
            <Flex w="100%" p="xl" direction="column" gap="xl" align="center" justify="center">
              <Text align="center" fw="bold">
                You can pin metrics from every section in this board. Click on the metric to pin it.
              </Text>
            </Flex>
          )}
          {!isBlocked && (
            <>
              {filtersOpen && (
                <div className="border-0 border-solid border-b-[1px] border-black/10 bg-white h-28 items-center flex ml-[10px]">
                  {!inWidgetEditor && availableCustomFilters.length > 0 && (
                    <WillyCustomFilters
                      availableFilterProps={availableCustomFilters}
                      level="widget"
                      appliedFilters={customFilters ?? []}
                      onFiltersChanged={setCustomFilters}
                      eventLoggerProps={eventLoggerProps}
                    />
                  )}
                  <WillyDynamicFields
                    parameters={parameters || emptyArray<WillyParameter>()}
                    hideDateFields={isDynamic}
                    visibleParameters={visibleParameters.map((p) => p.column)}
                    query={rawData?.generatedQuery}
                    parametersChanged={async (params) => {
                      if (parametersChanged) {
                        await parametersChanged(params);
                        if (initialRawData) {
                          const controller = new AbortController();
                          const promises = [
                            loadData({
                              abortSignal: controller.signal,
                              forceReload: true,
                              parameters: params,
                            }),
                            loadPreviousPeriodData({
                              abortSignal: controller.signal,
                              forceReload: true,
                              parameters: params,
                            }),
                          ];
                          await Promise.all(promises);
                        }
                      }
                    }}
                  />
                </div>
              )}
              <div
                className={`w-[100vw] sm:w-auto ${type === 'tile' ? '' : 'translate-x-0'} ${
                  context === 'summary' && type === 'chart'
                    ? isDnd
                      ? '!min-h-[350px]'
                      : '!min-h-[300px]'
                    : ''
                } ${tileMode === 'table' ? '!block' : ''} ${
                  type === 'table'
                    ? filtersOpen
                      ? 'h-[calc(100%-114px)]'
                      : 'h-[calc(100%-43px)]'
                    : type === 'chart' || type === 'funnel'
                      ? filtersOpen
                        ? 'h-[calc(100%-111px)]'
                        : 'h-[calc(100%-43px)]'
                      : type === 'pie'
                        ? `h-[300px] ${isDnd ? '' : 'h-300'}`
                        : filtersOpen
                          ? 'h-[calc(100%-111px)]'
                          : 'h-[calc(100%-43px)]'
                }`}
              >
                <>
                  {!defaultMetrics?.length && withoutMainQuery ? (
                    <WillyEmptyMarkup
                      onClickNoCode={() => {
                        setMetricLibraryOpened(true);
                      }}
                      onClickChat={() => openChatWithQuery?.(queryId!)}
                      onClickSql={() => openSqlWithWidget?.(queryId!)}
                      widgetId={queryId}
                      dashboard={dashboard}
                    />
                  ) : type === 'chart' || type === 'pie' || type === 'funnel' ? (
                    <WillyChart
                      queryId={queryId!}
                      rawData={rawNlqData}
                      errorInQuery={errorInQuery}
                      previousPeriodData={previousPeriodRawNlqData}
                      dataColumns={rawDataColumns}
                      serviceIds={rawData?.serviceIds}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      context={context}
                      currency={currency}
                      metrics={defaultMetrics}
                      parameters={parameters ?? emptyArray()}
                      incrementedStacked={incrementedStacked}
                      stacked={stacked}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      type={type}
                      showPreviousPeriod={showPreviousPeriod}
                      skinny={skinny}
                      yAxisDomain={yAxisDomain}
                      allowDataOverflow={allowDataOverflow}
                      dimension={dimension}
                      dashboard={dashboard}
                      allowLegendDnd={!!inWidgetEditor}
                      showQuickEdit={showQuickEditing || inWidgetEditor}
                      chartLabel={chartLabel}
                      rightYAxisLabel={rightYAxisLabel}
                      setRightYAxisLabel={setRightYAxisLabel}
                      leftYAxisLabel={leftYAxisLabel}
                      setLeftYAxisLabel={setLeftYAxisLabel}
                      xAxisLabel={xAxisLabel}
                      onMetricClicked={metricClicked}
                      isSyncCharts={isSyncCharts}
                      chartLayout={chartLayout}
                      forcedChartType={forcedChartType}
                    />
                  ) : type === 'table' ? (
                    <WillyTable
                      rawData={rawNlqData}
                      dataColumns={rawDataColumns}
                      errorInQuery={errorInQuery}
                      queryId={queryId!}
                      currency={currency}
                      query={rawData?.generatedQuery}
                      previousPeriodData={previousPeriodRawNlqData}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      context={context}
                      metrics={defaultMetrics}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      wrapText={wrapText}
                      onMetricClicked={metricClicked}
                      hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
                      globalConditionalFormattingColor={globalConditionalFormattingColor}
                      breakdownMode={breakdownMode}
                      widgetDialect={dialect}
                      parameters={memoizedParameters}
                      page={page}
                      setPage={handlePageChange}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                      totalCount={totalCount || rawNlqData?.[0]?.value?.length}
                    />
                  ) : type === 'summaryBox' || type === 'tile' ? (
                    <WillySingleValue
                      data={rawNlqData}
                      previousPeriodData={previousPeriodRawNlqData}
                      errorMetricsData={errorMetricsData}
                      errorInQuery={errorInQuery}
                      loadingMetricsData={loadingMetricsData}
                      queryId={queryId!}
                      currency={currency}
                      serviceIds={rawData?.serviceIds}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      metrics={defaultMetrics}
                      metricsChanged={onMetricsChanged}
                      setEditMetricModalOpen={setEditMetricModalOpen}
                      isEditEnable={canEdit}
                      onMetricClicked={metricClicked}
                      grid={grid}
                      twoColumnMobile={twoColumnMobile}
                      gridColumns={gridColumns}
                      tileMode={tileMode}
                      dashboardId={dashboard?.id}
                      pinnedMetricKeys={pinnedMetricKeys}
                      isPinnable={dashboard?.isCustomView}
                      context={context}
                    />
                  ) : type === 'text' ? (
                    <div className="p-2 md:p-4">
                      <WillySimpleText
                        text={textData!}
                        loading={loading}
                        loadingText={loadingText}
                      />
                    </div>
                  ) : type === 'map' ? (
                    <Suspense>
                      <WillyMap
                        resData={rawData!}
                        showAsWidget
                        question={rawData?.question}
                        loading={loading}
                        metrics={defaultMetrics}
                      />
                    </Suspense>
                  ) : type === 'json' ? (
                    <WillyJson nlqData={rawData!} />
                  ) : type === 'card' ? (
                    <WillyCards
                      currency={currency}
                      metrics={defaultMetrics}
                      rawData={rawNlqData!}
                      previousPeriodRawData={previousPeriodRawNlqData}
                      loading={loading}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      totalCount={totalCount || rawNlqData?.[0]?.value?.length}
                      page={page}
                      setPage={setPage}
                      isDnd={isDnd}
                    />
                  ) : type === 'sankey' ? (
                    <WillySankey data={rawNlqData} metrics={metrics} />
                  ) : null}

                  {singleValuePopupOpened && !!queryId && (
                    <WillySingleValueChart
                      onClose={closeSingleValuePopup}
                      queryId={queryId}
                      metric={activeMetric!}
                      metrics={defaultMetrics}
                      isDynamic={!!isDynamic}
                      metricsChanged={onMetricsChanged}
                      totalValue={totalValue}
                      breakDownValue={breakdownValue}
                      previousPeriodTotalValue={previousPeriodTotalValue}
                      previousPeriodBreakDownValue={previousPeriodBreakDownValue}
                      loadingPreviousPeriod={loadingPreviousPeriod}
                      currency={currency}
                      metricData={activeMetricData}
                      type={type}
                      isSyncCharts={isSyncCharts}
                    />
                  )}

                  {activeMetricCustomComponent}
                </>
              </div>
            </>
          )}
          {isBlocked && (
            <Flex w={'100%'} h={'100%'} justify={'center'} align={'center'}>
              <LockedFeatureIndicator
                featureFlag={FeatureFlag.LIMIT_INTEGRATIONS_FF}
                forceShow
                useDefaultText
                layout="vertical"
                upgradeButton={true}
                border={true}
              />
            </Flex>
          )}
        </div>

        <WillyWidgetEditor
          open={widgetEditorOpen}
          setOpen={setWidgetEditorOpen}
          permission={permission}
          permissionChanged={permissionChanged}
          isDynamic={isDynamic}
          //ref={ref}
          currency={currency}
          context={context}
          titleChanged={titleChanged}
          typeChanged={typeChanged}
          question={question}
          queryId={queryId}
          title={title}
          type={type}
          stacked={stacked}
          parameters={memoizedParameters}
          parametersChanged={parametersChanged}
          incrementedStacked={incrementedStacked}
          stackedChanged={stackedChanged}
          incrementedStackedChanged={incrementedStackedChanged}
          metrics={defaultMetrics}
          metricsChanged={onMetricsChanged}
          queries={queries}
          initialRawData={rawData}
          wrapText={wrapText}
          setWrapText={setWrapText}
          dashboard={dashboard}
          //setEditMetricModalOpen={setEditMetricModalOpen}
          // autoHeight={true}
          grid={grid}
          setGrid={setGrid}
          gridColumns={gridColumns}
          setGridColumns={setGridColumns}
          twoColumnMobile={twoColumnMobile}
          setTwoColumnMobile={setTwoColumnMobile}
          tileMode={tileMode}
          setTileMode={setTileMode}
          skinny={skinny}
          setSkinny={setSkinny}
          yAxisDomain={yAxisDomain}
          setYAxisDomain={setYAxisDomain}
          allowDataOverflow={allowDataOverflow}
          setAllowDataOverflow={setAllowDataOverflow}
          dimension={dimension}
          setDimension={setDimension}
          CDPSegmentId={CDPSegmentId}
          globalConditionalFormattingColor={globalConditionalFormattingColor}
          hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
          setHasGlobalConditionalFormatting={setHasGlobalConditionalFormatting}
          setGlobalConditionalFormattingColor={setGlobalConditionalFormattingColor}
          breakdownMode={breakdownMode}
          breakdownModeChanged={breakdownModeChanged}
          queriesChanged={queriesChanged}
          dataHasBreakdown={dataHasBreakdown}
          showPreviousPeriod={showPreviousPeriod}
          setShowPreviousPeriod={setShowPreviousPeriod}
          initialPreviousPeriodRawData={reorderedPreviousPeriodData}
          loadInitialPreviousPeriodData={loadingPreviousPeriod}
          dialect={dialect}
          builderSetupChanged={builderSetupChanged}
          mode={mode}
          builderSetup={builderSetup}
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
          chartLabel={chartLabel}
          setChartLabel={setChartLabel}
          rightYAxisLabel={rightYAxisLabel}
          setRightYAxisLabel={setRightYAxisLabel}
          leftYAxisLabel={leftYAxisLabel}
          setLeftYAxisLabel={setLeftYAxisLabel}
          xAxisLabel={xAxisLabel}
          setXAxisLabel={setXAxisLabel}
          isSyncCharts={isSyncCharts}
          chartLayout={chartLayout}
          setChartLayout={setChartLayout}
        />
      </div>
    );
  },
);
