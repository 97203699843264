import { NavSection } from './types';
import { SUMMARY_SECTION } from './configs/summary';
import { PIXEL_SECTION } from './configs/pixel';
import { RETENTION_SECTION } from './configs/retention';
import { OPS_SECTION } from './configs/ops';
import { WILLY_BUILD_SECTION, WILLY_SECTION } from './configs/willy';
import { STORE_SETTINGS_SECTION } from './configs/store-settings';
import { HIDDEN_SECTION } from './configs/hidden';
import { APPS_SECTION } from './configs/apps';
import { ENRICHMENT_SECTION } from './configs/enrichment';

export const V2_SECTIONS = [
  SUMMARY_SECTION,
  PIXEL_SECTION,
  RETENTION_SECTION,
  OPS_SECTION,
  HIDDEN_SECTION,
  STORE_SETTINGS_SECTION,
  {
    ...WILLY_SECTION,
    routes: WILLY_SECTION.routes.map((x) => ({ ...x, hidden: true })),
  },
  {
    ...WILLY_BUILD_SECTION,
    routes: WILLY_BUILD_SECTION.routes.map((x) => ({ ...x, hidden: true })),
  },
] as const satisfies NavSection[];

export const V3_SECTIONS = [
  WILLY_SECTION,
  WILLY_BUILD_SECTION,
  APPS_SECTION,
  ENRICHMENT_SECTION,
  STORE_SETTINGS_SECTION,
  HIDDEN_SECTION,
] as const satisfies NavSection[];
