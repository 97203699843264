import { updateEntityStatus } from 'utils/updateEntityStatus';
import { GenUiActionWithAttribution } from './types';
import { AttributionData } from 'types/attribution';
import { Providers } from 'types/services';

type ApplyActionResult =
  | {
      success: true;
      id: string;
    }
  | {
      success: false;
      error: string;
      id: string;
    };

export async function applyActions(
  actions: GenUiActionWithAttribution[],
  shopId: string,
  providerList: Providers,
): Promise<ApplyActionResult[]> {
  const actionPromises = actions.map(async (action) => {
    const isOutdated = isActionOutdated(action);
    if (isOutdated) {
      return { success: false, error: 'Action is outdated', id: action.attribution.id };
    }
    const { genUiAction, attribution } = action;
    const { id } = attribution;
    if (genUiAction.action === 'TOGGLE_STATUS') {
      const res = await applyToggleStatus(action, shopId, providerList);
      if (res.error) {
        return { success: false, error: res.error, id };
      }
      return { success: true, id };
    } else if (genUiAction.action === 'CHANGE_BUDGET') {
      const res = await applyChangeBudget(action);
      if (res.error) {
        return { success: false, error: res.error, id };
      }
      return { success: true, id };
    } else if (genUiAction.action === 'CHANGE_BID') {
      const res = await applyChangeBid(action);
      if (res.error) {
        return { success: false, error: res.error, id };
      }
      return { success: true, id };
    }
    return { success: false, error: 'Invalid action', id };
  });
  const actionResults = await Promise.all(actionPromises);
  return actionResults as ApplyActionResult[];
}

async function applyToggleStatus(
  action: GenUiActionWithAttribution,
  shopId: string,
  providerList: Providers,
) {
  const { attribution, genUiAction } = action;
  if (genUiAction.action !== 'TOGGLE_STATUS') {
    return { error: 'Invalid action' };
  }
  return await updateEntityStatus({
    attribution: attribution as AttributionData,
    providerList,
    newStatus: genUiAction.newStatus ? 'ACTIVE' : 'PAUSED',
    oldStatus: genUiAction.oldStatus ? 'PAUSED' : 'ACTIVE',
    shopId,
  });
}

async function applyChangeBudget(action: GenUiActionWithAttribution) {
  const { attribution } = action;
  return { error: 'Update budget not implemented yet' };
}

async function applyChangeBid(action: GenUiActionWithAttribution) {
  const { attribution } = action;
  return { error: 'Update bid not implemented yet' };
}

export function isActionOutdated(item: GenUiActionWithAttribution) {
  const { status, budget, id, bidAmount } = item.attribution;
  if (!item.attribution) {
    return true;
  }
  const { action } = item.genUiAction;
  if (action === 'TOGGLE_STATUS') {
    const { oldStatus, id: idFromGenUi } = item.genUiAction;
    return (
      ((status === 'ACTIVE' && oldStatus === false) ||
        (status !== 'ACTIVE' && oldStatus === true)) &&
      id === idFromGenUi
    );
  } else if (action === 'CHANGE_BUDGET') {
    const { oldBudget, id: idFromGenUi } = item.genUiAction;
    return budget !== oldBudget && id === idFromGenUi;
  } else if (action === 'CHANGE_BID') {
    const { oldBidAmount, id: idFromGenUi } = item.genUiAction;
    return bidAmount !== oldBidAmount && id === idFromGenUi;
  }
  return false;
}
