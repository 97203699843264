import { $store } from '@tw/snipestate';

type agentToWatch = { sequenceId: string; source: string };

export const $agentsToWatch = $store<agentToWatch[]>([]);

export const addAgentToWatch = (agent: string, source: string) => {
  $agentsToWatch.set((prev) => [...prev, { sequenceId: agent, source }]);
};
export const removeAgentToWatch = (agent: string) => {
  $agentsToWatch.set((prev) => prev.filter((a) => a.sequenceId !== agent));
};
export const clearAgentsToWatch = () => {
  $agentsToWatch.set([]);
};
