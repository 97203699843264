import { Icon } from '@tw/ui-components';
import { NavSection } from '../types';
import { IconComponent } from '../components';
import CDPHeader from 'components/headers/CDPHeader';
import ActivityPortalHeader from 'components/headers/ActivityPortalHeader';
import { useAppSelector } from 'reducers/RootType';
import moment from '@tw/moment-cached';
import { DatePickerHeader } from 'components/headers/DatePickerHeader';
import PostPurchaseSurveyHeader from 'components/headers/PostPurchaseSurveyHeader';
import { META_APPS_ROUTES } from '@tw/shared-types/module/frontend-config/constants';
import { $msp } from '$stores/$shop';

export const APPS_SECTION: NavSection = {
  title: 'Apps',
  icon: ({ selected }) => (
    <IconComponent
      selected={selected}
      icon={<Icon name="apps" color={selected ? 'one.5' : 'gray.5'} size={24} />}
    />
  ),
  routes: [
    {
      url: '/activity-feed',
      label: 'Activity Feed',
      shortLabel: 'Activity',
      ...META_APPS_ROUTES['/activity-feed'],
      get hidden() {
        return $msp.get() !== 'shopify';
      },
      rawIconName: 'attribution2',
      header: () => <ActivityPortalHeader />,
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="attribution2"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 18 : 22}
            />
          }
        />
      ),
    },
    {
      url: '/cdp',
      label: 'Customer Segmentation',
      shortLabel: 'Segments',
      ...META_APPS_ROUTES['/cdp'],
      rawIconName: 'segmentation',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={
            <Icon
              name="segmentation"
              color={selected ? 'one.5' : 'gray.5'}
              size={mobile ? 18 : 22}
            />
          }
        />
      ),
      header: () => <CDPHeader />,
    },
    {
      url: '/post-purchase-survey/report',
      label: 'Post-Purchase Survey',
      shortLabel: 'PPS',
      ...META_APPS_ROUTES['/post-purchase-survey/report'],
      rawIconName: 'pen',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={<Icon name="pen" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 22} />}
        />
      ),
      header: () => <PostPurchaseSurveyHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useAppSelector((state) => state.postPurchaseSurvey.lastUpdate);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/mmm-system',
      label: 'Marketing Mix Modeling',
      shortLabel: 'MMM',
      ...META_APPS_ROUTES['/mmm-system'],
      rawIconName: 'mmm',
      icon: ({ selected, mobile }) => (
        <IconComponent
          mobile={mobile}
          selected={selected}
          icon={<Icon name="mmm" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 22} />}
        />
      ),
      header: () => <></>,
    },
    {
      url: '/store-data',
      label: 'Store Data',
      ...META_APPS_ROUTES['/store-data'],
      hidden: true,
      header: () => <DatePickerHeader />,
    },
    // {
    //   url: '/sonar',
    //   label: 'Sonar Settings',
    //   isHybridPage: true,
    //   header: () => <></>,
    //   rawIconName: 'settings',
    //   icon: ({ selected, mobile }) => (
    //     <IconComponent
    //       mobile={mobile}
    //       selected={selected}
    //       icon={
    //         <Icon name="settings" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 22} />
    //       }
    //     />
    //   ),
    // },
  ],
};

// export const APPS_SECTION: NavSection = {
//   title: 'Apps',
//   icon: ({ selected }) => (
//     <IconComponent
//       selected={selected}
//       icon={<Icon name="apps" color={selected ? 'one.5' : 'gray.5'} size={24} />}
//     />
//   ),
//   routes: [
//     {
//       isHybridPage: true,
//       rawIconName: 'activity-feed',
//       url: '/activity-feed',
//       label: 'Activity Feed',
//       dependsOnFFSystem: FeatureFlag.LIGHTHOUSE_FF,
//       header: () => <ActivityPortalHeader />,
//     },
//     {
//       isHybridPage: true,
//       rawIconName: 'scdp',
//       url: '/cdp',
//       label: 'SCDP',
//       dependsOnFFSystem: FeatureFlag.SCDP_PAGE_FF,
//       header: () => <CDPHeader />,
//     },
//     {
//       isHybridPage: true,
//       rawIconName: 'planner',
//       url: '/forecast',
//       dependsOnFFSystemCallback(ffComputer) {
//         const { shouldNotBeSeen } = ffComputer.getConfigById(FeatureFlag.FORECASTING_MENU_FF);
//         return !shouldNotBeSeen;
//       },
//       label: 'Planner',
//       labs: true,
//       lastUpdate: () => {
//         const InnerComponent = () => {
//           const lastUpdate = useAppSelector((state) => state.forecasting.forecastingLastUpdated);

//           return (
//             <span className="inline-block mt-[2px] mb-[-2px]">
//               <Tooltip
//                 content={
//                   <div>
//                     <div className="text-secondary-text text-[10px]">
//                       {lastUpdate?.revenueActuals
//                         ? `Revenue Actuals: ${moment(lastUpdate?.revenueActuals)
//                             .local()
//                             .calendar()}`
//                         : 'Loading'}
//                     </div>
//                     <div className="text-secondary-text text-[10px]">
//                       {lastUpdate?.revenueForecast
//                         ? `Revenue Forecast: ${moment(lastUpdate?.revenueForecast)
//                             .local()
//                             .calendar()}`
//                         : 'Loading'}
//                     </div>
//                     <div className="text-secondary-text text-[10px]">
//                       {lastUpdate?.inventoryActuals
//                         ? `Inventory Actuals: ${moment(lastUpdate?.inventoryActuals)
//                             .local()
//                             .calendar()}`
//                         : 'Loading'}
//                     </div>
//                     <div className="text-secondary-text text-[10px]">
//                       {lastUpdate?.inventoryForecast
//                         ? `Inventory Forecast: ${moment(lastUpdate?.inventoryForecast)
//                             .local()
//                             .calendar()}`
//                         : 'Loading'}
//                     </div>
//                   </div>
//                 }
//               >
//                 <span>
//                   <InfoMinor className="w-5 flex items-center fill-gray-600 dark:fill-gray-300" />
//                 </span>
//               </Tooltip>
//             </span>
//           );
//         };
//         return <InnerComponent />;
//       },
//       header: () => <ForecastingHeader />,
//     },
//     {
//       isHybridPage: true,
//       rawIconName: 'pps',
//       url: '/post-purchase-survey/report',
//       label: 'Post-Purchase Survey',
//       header: () => <DatePickerHeader showGroupByButton={false} />,
//       lastUpdate: () => {
//         const InnerComponent = () => {
//           const lastUpdate = useAppSelector((state) => state.postPurchaseSurvey.lastUpdate);
//           return <> {moment(lastUpdate).local().format('LT')} </>;
//         };
//         return <InnerComponent />;
//       },
//     },
//     {
//       isHybridPage: true,
//       url: '/post-purchase-survey/builder',
//       label: 'Post-Purchase Survey',
//       rawIconName: 'pps',
//       header: () => <></>,
//       lastUpdate: () => {
//         const InnerComponent = () => {
//           const lastUpdate = useAppSelector((state) => state.postPurchaseSurvey.lastUpdate);
//           return <> {moment(lastUpdate).local().format('LT')} </>;
//         };
//         return <InnerComponent />;
//       },
//       hidden: true,
//     },
//     {
//       isHybridPage: true,
//       rawIconName: 'flash',
//       url: '/attribution/affluencer-hub',
//       dependsOnFFSystem: FeatureFlag.PIXEL_AFFLUENCER_HUB_FF,
//       label: 'Affluencer Hub',
//       header: () => <AffluencerHubHeader />,
//     },
//   ],
// };
