import { AttributionDateModels, AttributionModels } from '@tw/types';
import DropDown from 'components/ltv/DropDown';
import {
  PIXEL_ATTRIBUTION_DATE_MODELS,
  TW_LS_ATTRIBUTION_DATE_MODEL_KEY,
} from 'constants/attribution';
import { changeDateModel } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { type RootState, useAppSelector } from 'reducers/RootType';
import { useDarkMode } from 'dark-mode-control';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

type AttributionModelPickerProps = {
  theme?: 'light' | 'dark' | 'none';
  customActivator?: (label: string | React.ReactNode) => React.ReactNode;
  sentDateModel?: AttributionDateModels;
  onDateModelChange?: (dateModel: AttributionDateModels) => void;
  sentAttributionModel?: AttributionModels;
};

const AttributionDateModelPicker: React.FC<AttributionModelPickerProps> = ({
  theme,
  customActivator,
  sentDateModel,
  onDateModelChange,
  sentAttributionModel,
}) => {
  const dispatch = useAppDispatch();

  const { dateModel, attributionModel } = useSelector((state: RootState) => state.attribution);
  const doDarkMode = useDarkMode();

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const handleDateModelChange = useCallback(
    (model: AttributionDateModels) => {
      if (onDateModelChange) {
        onDateModelChange(model);
      } else {
        localStorage.setItem(TW_LS_ATTRIBUTION_DATE_MODEL_KEY, model);
        dispatch(changeDateModel(model));
      }
    },
    [dispatch, onDateModelChange],
  );

  return (
    <DropDown
      disabled={(sentAttributionModel || attributionModel) === 'ppsViews'}
      theme={theme ? theme : doDarkMode ? 'dark' : 'none'}
      hint={
        (sentAttributionModel || attributionModel) === 'ppsViews'
          ? 'Date model is not available for this attribution model'
          : ''
      }
      id="att-header-date-model-picker"
      options={PIXEL_ATTRIBUTION_DATE_MODELS}
      value={sentDateModel || dateModel}
      handleSelect={handleDateModelChange}
      size={isThreePointOh ? 'xs' : 'sm'}
      customActivator={customActivator?.(sentDateModel || dateModel)}
    />
  );
};

export default AttributionDateModelPicker;
