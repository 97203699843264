import { Flex, Icon, IconName, Menu, Text } from '@tw/ui-components';
import { ALL_SOURCES_ID } from 'constants/types';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AllServicesIds } from 'types/attribution';
import { getSourcesList } from 'utils/attributions';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';

const emptyArray = [];

type AttributionSelectChannelProps = {
  activator?: (label: string) => React.ReactNode;
  withinPortal?: boolean;
  dropdownPosition?: any;
  dropDownOffset?: number;
  serviceOptions?: { label: string; value: string; id: string; icon?: IconName }[];
};

export const AttributionSelectChannel: FC<AttributionSelectChannelProps> = ({
  activator,
  withinPortal = true,
  dropDownOffset,
  dropdownPosition,
  serviceOptions,
}) => {
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const navigate = useNavigate();
  const location = useLocation();

  const disabled = sourceCategory === 'all';

  const sourcesList = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArray;
    }
    return getSourcesList(sourceCategory || 'all');
  }, [sourceCategory]);

  const handleChannelChange = useCallback(
    (id: AllServicesIds) => {
      navigate({
        pathname: `${
          location.pathname.includes('dashboards') ? '/dashboards' : ''
        }/attribution/${sourceCategory}/${id}`,
        search: location.search,
      });
    },
    [location, navigate, sourceCategory],
  );

  const activeOption = useMemo(
    () => serviceOptions?.find((s) => s.value === activeSource)?.label || 'All',
    [activeSource, serviceOptions],
  );

  return (
    <Menu
      withinPortal={withinPortal}
      position={dropdownPosition}
      offset={dropDownOffset}
      id="att-header-channel-dropdown"
      disabled={disabled}
    >
      <Menu.Target>
        {activator ? (
          <div>{activator(activeOption)}</div>
        ) : (
          <Flex align="center" gap="md" justify="space-between">
            <Text size="sm" weight={500} color="named2.0">
              Channel: {activeOption}
            </Text>
            <Icon name="chevron-right-minor" size={12} color={disabled ? 'named2.3' : 'named.8'} />
          </Flex>
        )}
      </Menu.Target>
      <Menu.Dropdown>
        {serviceOptions?.map((item) => (
          <Menu.Item
            key={item.value}
            bg={activeSource === item.value ? 'named.1' : undefined}
            onClick={() => handleChannelChange(item.value as AllServicesIds)}
          >
            <Flex align="center" gap="sm">
              <Icon name={item.icon || 'attribution'} size={16} />
              {item.label}
            </Flex>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
