/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { Icon, Stack, Button } from '@shopify/polaris';
import { DragHandleMinor, EditMinor, PinMinor } from '@shopify/polaris-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { snakeCase } from 'lodash';
import { useAppDispatch } from 'index';
import { ConnectedListItem } from './library/TWTable/types';
import { Text } from '@tw/ui-components';
//import "@shopify/polaris/styles.css";

type ListItemProps = {
  id: string;
  index: number;
  title: string | React.FC<any>;
  subTitle?: React.ReactNode;
  hasLeftButton: boolean | undefined;
  leftButtonIcon: JSX.Element;
  leftButtonClick: (...props: any) => void;
  onEdit?: (...props: any) => void;
  disabled?: boolean;
  pinned?: boolean;
  icons: React.FC<any>[];
};
const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    id,
    index,
    title,
    subTitle,
    hasLeftButton,
    leftButtonIcon,
    leftButtonClick,
    onEdit,
    disabled,
    pinned,
    icons,
  } = props;
  const dispatch = useAppDispatch();
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={pinned}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={
              snapshot.isDragging
                ? { background: '#eeeedd', ...provided.draggableProps.style }
                : provided.draggableProps.style
            }
          >
            <div className="flex items-center gap-3 justify-between w-full border-0 border-b-[1px] border-solid border-gray-200 py-4">
              <div className="flex items-center gap-3">
                {hasLeftButton && (
                  <>
                    {!pinned && (
                      <Button
                        plain
                        disabled={pinned}
                        size="large"
                        icon={leftButtonIcon}
                        onClick={leftButtonClick}
                        destructive={true}
                      />
                    )}
                    {pinned && <PinMinor className="flex items-center w-6" />}
                  </>
                )}
                <div className="w-[24px] flex items-center overflow-hidden">
                  {(icons || []).map((I, i) => (
                    <Fragment key={`section_icon_${i}`}>
                      <I key={i} />
                    </Fragment>
                  ))}
                </div>
                <Text fw={500}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    id={typeof title === 'string' ? snakeCase(title) : undefined}
                  >
                    {typeof title === 'string' ? title : title?.({})}
                    {subTitle}
                  </div>
                </Text>
                {!!onEdit && (
                  <EditMinor
                    width={12}
                    height={12}
                    className="fill-logo cursor-pointer"
                    onClick={() => dispatch(onEdit())}
                  />
                )}
              </div>
              <div {...provided.dragHandleProps} className={pinned ? 'cursor-not-allowed' : ''}>
                <Icon source={DragHandleMinor} color={pinned ? 'subdued' : 'primary'} />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

type ReorderListProps = {
  items: ConnectedListItem[];
  orderOnChange: (...any: any) => void;
  itemHasLeftButton?: boolean;
  itemLeftButtonIcon?: any;
  itemLeftButtonClick?: (item: ConnectedListItem) => void;
  currentSectionId?: string;
};

const ReorderListNew: React.FC<ReorderListProps> = ({
  items,
  orderOnChange,
  itemHasLeftButton,
  itemLeftButtonIcon,
  itemLeftButtonClick,
  currentSectionId,
}) => {
  const handleDragEnd = ({ source, destination }) => {
    const oldItems = items;
    const newItems = oldItems.slice(); // Duplicate
    const [temp] = newItems.splice(source.index, 1);
    newItems.splice(destination?.index || 0, 0, temp);
    if (currentSectionId) {
      orderOnChange(currentSectionId, newItems);
    } else {
      orderOnChange(newItems);
    }
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd as any}>
      <Droppable droppableId="root">
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <ListItem
                  key={item.id}
                  id={item.id}
                  index={index}
                  title={item.title as any}
                  subTitle={item.subTitle}
                  hasLeftButton={itemHasLeftButton}
                  leftButtonIcon={itemLeftButtonIcon}
                  leftButtonClick={() => {
                    itemLeftButtonClick?.(item);
                  }}
                  onEdit={item.onEdit}
                  disabled={item.disabled || item.pinned}
                  pinned={item.pinned}
                  icons={item.icons ?? []}
                />
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderListNew;
