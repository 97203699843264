import { useNavigate, useParams } from 'react-router-dom';
import { analyticsEvents } from 'utils/dataLayer/constants';

import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useLocation } from 'react-router-dom';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { genericEventLogger, chatActions } from 'utils/dataLayer';
import { $mainChatStore, setDeepSearch, setBuildMode } from '$stores/willy/$mainChat';
import { ActionIcon, Text, Tooltip } from '@tw/ui-components';
import { Flex, Switch } from '@tw/ui-components';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { WillyNavItem } from './WillyNavItem';
import { $isAgentSupported } from '$stores/willy/$sequences';
import { $unreadMobyFeedItemCount } from '$stores/willy/$mobyFeed';

type WillySidePanelProps = {
  currentPage?: string;
  clearConversation: () => void;
  conversationId?: string;
  currentAnalyticsEvent: string;
  inTooltip?: boolean;
};

export const WillySidePanel: React.FC<WillySidePanelProps> = ({
  currentPage = '',
  clearConversation,
  conversationId,
  currentAnalyticsEvent,
  inTooltip,
}) => {
  const isSmall = useIsSmall();
  const { search } = useLocation();
  const navigate = useNavigate();
  const isAgentSupported = useStoreValue($isAgentSupported);
  const unreadAgentCount = useStoreValue($unreadMobyFeedItemCount);
  const deepSearch = useComputedValue($mainChatStore, (r) => r.deepSearch);
  const buildMode = useComputedValue($mainChatStore, (r) => r.buildMode);

  const activeNavItem = currentPage;

  const updateBuildMode = (value) => {
    if (!!setBuildMode) {
      const newMode = value ? 'build' : 'analyze';
      setBuildMode(value);
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.TOGGLE_CHAT_BUILD_MODE,
        buildMode: newMode,
      });
    }
  };

  const updateDeepSearch = (value) => {
    setDeepSearch(value);
    genericEventLogger(analyticsEvents.CHAT, {
      action: chatActions.TOGGLE_DEEP_SEARCH,
      model: value,
    });
  };

  return (
    <div className="flex flex-col h-full willy-side-panel">
      <div className="bg-white sticky top-0 z-10 ">
        {!isSmall && (
          <>
            <Flex justify="space-between" align="center" p="md" h={65}>
              <Text fz="xl" fw="500">
                Moby
              </Text>
              <Tooltip lightTooltip={true} label="Start new chat">
                <ActionIcon
                  icon="chat"
                  display="block"
                  iconColor="gray.5"
                  iconSize={20}
                  onClick={() => {
                    clearConversation();
                    const params = new URLSearchParams(search);
                    params.delete('conversationId');
                    params.delete('sequenceId');
                    params.delete('runId');
                    params.delete('tab');
                    params.delete('prompt');
                    navigate({
                      pathname: '/chat',
                      search: params.toString(),
                      hash: '',
                    });
                    if (conversationId) {
                      genericEventLogger(currentAnalyticsEvent, {
                        action: chatActions.NEW_CONVERSATION,
                        conversationId,
                      });
                    }
                  }}
                />
              </Tooltip>
            </Flex>

            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </>
        )}
      </div>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col gap-20">
          <div className="p-4 gap-4 flex flex-col">
            {WILLY_NAV_ITEMS.filter((item) => !item.hideFromSidePanel).map((item) => (
              <WillyNavItem
                key={item.id}
                item={item}
                activeTab={activeNavItem}
                unread={unreadAgentCount}
              />
            ))}
          </div>
          {/* <WillySidePanelWorkflows
            chatWithSequenceId={params?.workflowId ?? null}
            inTooltip={inTooltip}
            currentPage={currentPage}
          /> */}
        </div>
        {!inTooltip && (
          <div>
            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
            <div className="p-4 gap-4 flex flex-col">
              {!isSmall && (
                <Tooltip
                  label={
                    <div className="flex flex-col gap-1 p-3">
                      <Text fw={500}>Build Mode</Text>
                      <Text fz={14}>
                        Build Mode enables you to generate dashboards from your conversations with
                        just one click.
                      </Text>
                    </div>
                  }
                  lightTooltip={true}
                  multiline={true}
                  w={300}
                >
                  <span>
                    <Switch
                      size="sm"
                      label={
                        <Text span fz="sm" c="gray.8" fw={500}>
                          Build Mode
                        </Text>
                      }
                      onChange={(e) => updateBuildMode(e.target.checked)}
                      checked={buildMode}
                    />
                  </span>
                </Tooltip>
              )}
              {isAgentSupported && (
                <Tooltip
                  label={
                    <div className="flex flex-col gap-1 p-3">
                      <Text fw={500}>Deep Dive</Text>
                      <Text fz={14}>
                        A more advanced model that provides enhanced accuracy and deeper insights
                        for complex queries.
                      </Text>
                    </div>
                  }
                  lightTooltip={true}
                  multiline={true}
                  w={300}
                >
                  <span>
                    <Switch
                      size="sm"
                      label={
                        <Text span fz="sm" c="gray.8" fw={500}>
                          Deep Dive
                        </Text>
                      }
                      onChange={(e) => setDeepSearch(e.target.checked)}
                      checked={deepSearch}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
