/**
 * DateRangePicker component for selecting date ranges
 * @param {Object} props
 * @param {Date|string} [props.startDate] - Start date (optional, defaults to current date)
 * @param {Date|string} [props.endDate] - End date (optional, defaults to current date)
 * @param {Date|string} [props.prevStartDate] - Previous period start date (optional)
 * @param {Date|string} [props.prevEndDate] - Previous period end date (optional)
 * @param {Function} [props.onChange] - Callback when dates change
 * @param {string} [props.label="Date Range"] - Label for the date picker
 * @param {string} [props.size="small"] - Size of the date picker ("small", "medium", "large")
 */
export const dateRangePickerString = `const DateRangePicker = ({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  queryId,
  onChange,
  size = "small"
}) => {
  // Initialize with props or default to current date
  const [dateRange, setDateRange] = useState({
    start: startDate ? new Date(startDate) : new Date(),
    end: endDate ? new Date(endDate) : new Date()
  });
  
  // Initialize previous period dates if provided
  const [prevDateRange, setPrevDateRange] = useState(
    prevStartDate && prevEndDate 
    ? {
        start: new Date(prevStartDate),
        end: new Date(prevEndDate)
      }
    : null
  );
  
  const [open, setOpen] = useState(false);
  const [hoverDate, setHoverDate] = useState(null);
  const [selectingStart, setSelectingStart] = useState(true);
  const [tempStart, setTempStart] = useState(null);
  const [activeCalendar, setActiveCalendar] = useState('current'); // 'current' or 'previous'
  
  // Styles for the component
  const styles = {
    container: {
      position: 'relative',
      display: 'inline-block'
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: size === 'small' ? '8px 12px' : '10px 16px',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      backgroundColor: '#fff',
      fontSize: size === 'small' ? '14px' : '16px',
      cursor: 'pointer',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      transition: 'all 0.2s ease',
      fontFamily: 'sans-serif'
    },
    buttonHover: {
      boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
      borderColor: '#bdbdbd'
    },
    icon: {
      marginRight: '8px',
      color: '#5b5b5b'
    },
    popup: {
      position: 'absolute',
      zIndex: 1000,
      top: '100%',
      left: 0,
      marginTop: '8px',
      padding: '16px',
      backgroundColor: '#fff',
      borderRadius: '4px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      width: '320px',
      display: open ? 'block' : 'none'
    },
    calendar: {
      width: '100%',
      marginBottom: '12px'
    },
    calendarHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '12px'
    },
    calendarGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '2px'
    },
    dayOfWeek: {
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 500,
      color: '#757575',
      padding: '4px 0',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: '4px'
    },
    calendarDay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px',
      cursor: 'pointer',
      borderRadius: '4px',
      fontSize: '14px',
      userSelect: 'none',
    },
    calendarDayDisabled: {
      color: '#bdbdbd',
      cursor: 'default'
    },
    calendarDayInRange: {
      backgroundColor: '#e3f2fd',
    },
    calendarDaySelected: {
      backgroundColor: '#2196f3',
      color: 'white',
      fontWeight: 500
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      marginTop: '16px'
    },
    applyButton: {
      padding: '6px 16px',
      backgroundColor: '#2196f3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '14px'
    },
    cancelButton: {
      padding: '6px 16px',
      backgroundColor: 'transparent',
      color: '#757575',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: 500,
      fontSize: '14px'
    },
    presetButton: {
      display: 'block',
      width: '100%',
      textAlign: 'left',
      padding: '8px 12px',
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '4px',
      fontSize: '14px',
      color: '#424242',
      transition: 'background-color 0.2s ease'
    },
    presetButtonHover: {
      backgroundColor: '#f5f5f5'
    },
    presets: {
      marginBottom: '16px'
    },
    monthSelector: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    monthButton: {
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: '4px 8px',
      borderRadius: '4px',
      color: '#757575',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    instruction: {
      fontSize: '12px',
      color: '#757575',
      marginBottom: '8px',
      textAlign: 'center'
    }
  };
  
  // Function to format date for display
  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };
  
  // Handle date changes
  const handleDateChange = (newRange, isPrevious = false) => {
    if (isPrevious) {
      setPrevDateRange(newRange);
    } else {
      setDateRange(newRange);
    }
    
    // Pass the dates back to parent via onChange callback
    if (onChange) {
      onChange({
        startDate: isPrevious ? dateRange.start : newRange.start,
        endDate: isPrevious ? dateRange.end : newRange.end,
        ...(isPrevious 
          ? { prevStartDate: newRange.start, prevEndDate: newRange.end }
          : prevDateRange 
            ? { prevStartDate: prevDateRange.start, prevEndDate: prevDateRange.end }
            : {}
        )
      });
    }
    
    // Also send a message to parent frame
    window.parent.postMessage({
      type: 'CHANGE_DATE_RANGE',
      data: {
        startDate: isPrevious ? dateRange.start : newRange.start,
        endDate: isPrevious ? dateRange.end : newRange.end,
        ...(isPrevious 
          ? { prevStartDate: newRange.start, prevEndDate: newRange.end }
          : prevDateRange 
            ? { prevStartDate: prevDateRange.start, prevEndDate: prevDateRange.end }
            : {}
        ),
        queryId: queryId,
        isPrevious: isPrevious
      }
    }, '*');
  };
  
  // Date presets
  const presets = [
    { 
      label: 'Today',
      getRange: () => {
        const today = new Date();
        return {
          start: today,
          end: today
        };
      }
    },
    {
      label: 'Yesterday',
      getRange: () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return {
          start: yesterday,
          end: yesterday
        };
      }
    },
    {
      label: 'Last 7 days',
      getRange: () => {
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - 6);
        return { start, end };
      }
    },
    {
      label: 'Last 30 days',
      getRange: () => {
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - 29);
        return { start, end };
      }
    },
    {
      label: 'This month',
      getRange: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), 1);
        const end = new Date();
        return { start, end };
      }
    },
    {
      label: 'Last month',
      getRange: () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const end = new Date(now.getFullYear(), now.getMonth(), 0);
        return { start, end };
      }
    }
  ];
  
  // Handle applying the date range
  const handleApply = () => {
    if (activeCalendar === 'current') {
      handleDateChange(dateRange, false);
    } else if (activeCalendar === 'previous') {
      handleDateChange(prevDateRange, true);
    }
    setOpen(false);
  };
  
  // Apply a preset
  const applyPreset = (preset) => {
    const newRange = preset.getRange();
    
    if (activeCalendar === 'current') {
      setDateRange(newRange);
      handleDateChange(newRange, false);
    } else if (activeCalendar === 'previous' && prevDateRange) {
      setPrevDateRange(newRange);
      handleDateChange(newRange, true);
    }
    
    setOpen(false);
  };
  
  // Calendar rendering
  const [currentMonth, setCurrentMonth] = useState(
    new Date(dateRange.start.getFullYear(), dateRange.start.getMonth(), 1)
  );
  
  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };
  
  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };
  
  const handleDayClick = (day) => {
    const selectedDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    
    if (selectingStart) {
      // Start selecting range
      setTempStart(selectedDate);
      setSelectingStart(false);
    } else {
      // Complete the range
      let start = tempStart;
      let end = selectedDate;
      
      // Ensure start is before end
      if (start > end) {
        [start, end] = [end, start];
      }
      
      if (activeCalendar === 'current') {
        setDateRange({ start, end });
      } else if (activeCalendar === 'previous' && prevDateRange) {
        setPrevDateRange({ start, end });
      }
      
      setSelectingStart(true);
      setTempStart(null);
    }
  };
  
  const isDateSelected = (day) => {
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    
    // Check if it's the start or end date based on active calendar
    if (activeCalendar === 'current') {
      return (
        (dateRange.start && date.toDateString() === dateRange.start.toDateString()) ||
        (dateRange.end && date.toDateString() === dateRange.end.toDateString()) ||
        (tempStart && date.toDateString() === tempStart.toDateString())
      );
    } else if (activeCalendar === 'previous' && prevDateRange) {
      return (
        (prevDateRange.start && date.toDateString() === prevDateRange.start.toDateString()) ||
        (prevDateRange.end && date.toDateString() === prevDateRange.end.toDateString()) ||
        (tempStart && date.toDateString() === tempStart.toDateString())
      );
    }
    return false;
  };
  
  const isDateInRange = (day) => {
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    
    if (tempStart && !selectingStart && hoverDate) {
      // When selecting the second date, use hover date for preview
      let rangeStart = tempStart;
      let rangeEnd = hoverDate;
      
      if (rangeStart > rangeEnd) {
        [rangeStart, rangeEnd] = [rangeEnd, rangeStart];
      }
      
      return date > rangeStart && date < rangeEnd;
    }
    
    // Check based on active calendar
    if (activeCalendar === 'current') {
      return date > dateRange.start && date < dateRange.end;
    } else if (activeCalendar === 'previous' && prevDateRange) {
      return date > prevDateRange.start && date < prevDateRange.end;
    }
    
    return false;
  };
  
  const renderDaysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    
    const days = [];
    
    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(
        <div key={\`empty-\${i}\`} style={styles.calendarDay}></div>
      );
    }
    
    // Add the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const now = new Date();
      const isDisabled = date > now; // Disable future dates
      
      let dayStyle = { ...styles.calendarDay };
      
      if (isDisabled) {
        dayStyle = { ...dayStyle, ...styles.calendarDayDisabled };
      } else {
        if (isDateSelected(day)) {
          dayStyle = { ...dayStyle, ...styles.calendarDaySelected };
        } else if (isDateInRange(day)) {
          dayStyle = { ...dayStyle, ...styles.calendarDayInRange };
        }
      }
      
      days.push(
        <div
          key={day}
          style={dayStyle}
          onClick={() => !isDisabled && handleDayClick(day)}
          onMouseEnter={() => 
            !isDisabled && !selectingStart && 
            setHoverDate(new Date(year, month, day))
          }
        >
          {day}
        </div>
      );
    }
    
    return days;
  };
  
  const goToPreviousMonth = () => {
    setCurrentMonth(new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    ));
  };
  
  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );
    const now = new Date();
    
    // Only allow navigating to future months up to current month
    if (nextMonth <= new Date(now.getFullYear(), now.getMonth(), 1)) {
      setCurrentMonth(nextMonth);
    }
  };
  
  // Add styles for the tabs
  const tabStyles = {
    tabContainer: {
      display: 'flex',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: '12px'
    },
    tab: {
      padding: '8px 16px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '500',
      color: '#757575',
      borderBottom: '2px solid transparent'
    },
    activeTab: {
      color: '#2196f3',
      borderBottom: '2px solid #2196f3'
    }
  };

  return (
    <div style={styles.container}>
      <div 
        style={styles.button}
        onClick={() => setOpen(!open)}
        onMouseEnter={(e) => Object.assign(e.currentTarget.style, styles.buttonHover)}
        onMouseLeave={(e) => {
          const { boxShadow, borderColor, ...rest } = styles.buttonHover;
          Object.assign(e.currentTarget.style, styles.button);
        }}
      >
        <span style={styles.icon}>📅</span>
        <span>
          {formatDate(dateRange.start)} - {formatDate(dateRange.end)}
          {prevDateRange && ' (vs. previous)'}
        </span>
      </div>
      
      <div style={styles.popup}>
        {/* Tabs for current/previous periods if previous dates are available */}
        {prevDateRange && (
          <div style={tabStyles.tabContainer}>
            <div 
              style={{
                ...tabStyles.tab,
                ...(activeCalendar === 'current' ? tabStyles.activeTab : {})
              }}
              onClick={() => setActiveCalendar('current')}
            >
              Current period
            </div>
            <div 
              style={{
                ...tabStyles.tab,
                ...(activeCalendar === 'previous' ? tabStyles.activeTab : {})
              }}
              onClick={() => setActiveCalendar('previous')}
            >
              Previous period
            </div>
          </div>
        )}
      
        <div style={styles.presets}>
          {presets.map((preset, index) => (
            <button 
              key={index}
              style={styles.presetButton}
              onClick={() => applyPreset(preset)}
              onMouseEnter={(e) => Object.assign(e.currentTarget.style, styles.presetButtonHover)}
              onMouseLeave={(e) => {
                const { backgroundColor, ...rest } = styles.presetButtonHover;
                Object.assign(e.currentTarget.style, styles.presetButton);
              }}
            >
              {preset.label}
            </button>
          ))}
        </div>
        
        <div style={styles.calendar}>
          <div style={styles.instruction}>
            {prevDateRange 
              ? \`\${activeCalendar === 'current' ? 'Current' : 'Previous'} period: \${selectingStart ? 'Select start date' : 'Select end date'}\`
              : (selectingStart ? 'Select start date' : 'Select end date')
            }
          </div>
          
          <div style={styles.calendarHeader}>
            <div style={styles.monthSelector}>
              <button 
                style={styles.monthButton}
                onClick={goToPreviousMonth}
              >
                ◀
              </button>
              <div>
                {currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
              </div>
              <button 
                style={styles.monthButton}
                onClick={goToNextMonth}
              >
                ▶
              </button>
            </div>
          </div>
          
          <div style={styles.calendarGrid}>
            {daysOfWeek.map(day => (
              <div key={day} style={styles.dayOfWeek}>{day}</div>
            ))}
            {renderDaysInMonth()}
          </div>
        </div>
        
        <div style={styles.buttonGroup}>
          <button 
            style={styles.cancelButton}
            onClick={() => {
              setOpen(false);
              setSelectingStart(true);
              setTempStart(null);
            }}
          >
            Cancel
          </button>
          <button 
            style={styles.applyButton}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
`;
